<!-- nav-b 是黑色导航，nav-w是白色导航 -->
<div class="u-no-select headerBox" id="header" *ngIf="useHeader">
  <div data-component="MNotification" id="m-notification" class="m-notification" *ngIf="showNotice">
    <div class="header-notice-close md-hidden">
      <svg style="display: inline-block;top: 50%;" *ngIf="!showMenu" (click)="closeNotice()" class="h-16 w-16 fill-current"><title>Close</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
      <svg style="display: inline-block;top: 50%;" *ngIf="showMenu"class="h-16 w-16 fill-current"><title>Close</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
    </div>
    <div class="header-notice-close lg-hidden">
      <svg style="display: inline-block;top: 50%;" (click)="closeNotice()" class="h-16 w-16 fill-current"><title>Close</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
    </div>
    <p class="a-paragraph" [innerHTML]="cms.headerTips | html"></p>
  </div>

  <div class="a-overlay js-a-overlay q-opacity-0 q-is-menu-overlay" data-component="AOverlay" [ngClass]="{'q-opacity-95 q-bg-grey-light':showSubscribeBox||showLoginRegisterBox, 'is-visible':showSubscribeBox||showLoginRegisterBox || select_country }" (click)="closeMenuBtn()"></div>
  <div *ngIf="(!hiddenLoginBlock && showLoginRegisterBox) || select_country || showSubscribeBox" data-component="OLightbox" class="o-lightbox"  style="z-index: 99" [ngClass]="{'is-small':showLoginRegisterBox,'is-select-country':select_country,'is-subscribe':showSubscribeBox,'is-open':showLoginRegisterBox||select_country||showSubscribeBox,'shippingCountry is-large':select_country}">
    <div class="lightbox-header"></div>
    <div class="lightbox-content js-content u-clearfix">
      <!-- 登录注册 开始 -->
      <sign-in *ngIf="!hiddenLoginBlock" #signIn [showLoginBox]="showLoginBox" [showRegisterBox]="showRegisterBox"  [closeLoginRegister]="closeLoginRegisterBox" (close)='closeModal($event)' (headerLink) = 'linkTo($event)' [ngClass]="{'is-hidden':showLoginRegisterBox===false}"></sign-in>
      <!-- 登录注册 结束 -->
    </div>
    <div (click)="closeLoginRegisterBox()" class="m-button-icon m-button-icon-h5 a-icon-close js-close-button" [ngClass]="{'shipping-country-button-icon':select_country}"></div>
  </div>

  <app-mini-cart #mini></app-mini-cart>

	<!-- 搜索弹层 -->
 <!-- [ngClass]="{'mt-40': showNotice}"-->
	<div class="searchBox" *ngIf="showSearchBox" >
    <div aria-hidden="true" class="pageTopLayer-bg" (click)="closeMenuBtn()"></div>
		<div class="rightSearch">
			<div class="topInput">
				<input #searchInput maxlength="500" name="search" value="" (keyup.enter)="enter('PC',searchInput.value)">
				<button (click)="closeMenuBtn()">
					<svg class="inline h-4 w-4 fill-current">
						<title>
							关闭
						</title>
						<use href="/assets/iconSprite.svg#nav-close-desktop"></use>
					</svg>
				</button>
			</div>
			<div class="keywordBox">
				<h5>推荐</h5>
				<ul>
          <li *ngFor="let _hot of hotSearchKeys;index as i">
            <a class="" (click)="enter('PC', _hot.hotword)">
              '{{_hot.hotword}}'
            </a>
          </li>
				</ul>
			</div>
			<div class="keywordBox" *ngIf="searchKeys.length > 0">
				<h5>历史搜索</h5>
				<ul>
          <li *ngFor="let _key of searchKeys;index as i">
            <a (click)="preSearch(_key)" class="" [routerLink]="['/search']" [queryParams]="{keyword: _key}">
              '{{_key}}'
            </a>
          </li>
				</ul>
			</div>
		</div>
	</div>
	<!-- //搜索弹层 -->
  <ng-container *ngIf="showMenu">
    <!--PC显示下拉菜单-->
    <div class="searchBox md-hidden">
      <div aria-hidden="true" class="pageTopLayer-bg" (click)="closeMenuBtn()"></div>
    </div>
    <div style="position: absolute" class="absolute w-full drop-menu-container md-hidden" [ngClass]="{'drop-menu-container-h1': showNotice, 'drop-menu-container-h2': !showNotice}">
      <div class="h-100 grid grid-cols-12 col-span-12 md-gap-x-20 md-px-20 grid-rows-50 ">
        <div class="grid md-gap-x-20 grid-cols-6 h-50 row-span-1 col-span-6 items-center">
          <div class="col-span-1 items-center lg-flex">
            <a class="" (click)="closeMenuBtn()">
              <svg class="w-16 h-16 inline fill-current"><title>Navigation</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
            </a>
          </div>
          <div class="col-span-1 items-center lg-flex" *ngFor="let category of categories;index as i">
            <!--选中加类nav-link-current-->
            <a class="nav-link"  (click)="clickMenu(category)"  (mouseover)="menuMouseOver(category)" [ngClass]="{'nav-link-current': currentMenuId==category['navId']}" [style]="category['parameters']['categoryColor'] | style">{{category.navName}}</a>
          </div>
        </div>

        <nav class="col-span-2 mt-8" style="grid-column-start: 1;">
          <div *ngIf="currentNav['groupedChilds'].hasOwnProperty('group_0') && currentNav['groupedChilds']['group_0'].length > 0">
            <span *ngIf="currentNav['groupedChilds']['group_0'][0]['categoryGroupTitle'] == 1;else tTitle">
              <a class="menu-title" routerLink="/{{currentNav['groupedChilds']['group_0'][0]['urlPath']}}" (click)="clickSubMenu(currentNav['groupedChilds']['group_0'][0])">{{currentNav['groupedChilds']['group_0'][0]['navName']}}</a>
            </span>
            <ul class="menu-items">
              <ng-container *ngFor="let childNav of currentNav['groupedChilds']['group_0']; index as _i">
                <ng-container *ngIf="_i==0 && childNav['categoryGroupTitle']==1;else childMenu"></ng-container>
                <ng-template #childMenu>
                  <li class="mt-8">
                    <a routerLink="/{{childNav['urlPath']}}" (click)="clickSubMenu(childNav)" [style]="childNav['parameters']['categoryColor'] | style">{{childNav['navName']}}<svg class="h-16 w-16 hidden fill-current"><title>Arrow pointing right</title><use href="/assets/iconSprite.svg#chevron-mini-right-end"></use></svg></a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </div>
        </nav>

        <ng-template #tTitle>
          <span style="margin-top: -8px; display: block;"></span>
        </ng-template>

        <nav class="col-span-2 mt-8">
          <div *ngIf="currentNav['groupedChilds'].hasOwnProperty('group_1') && currentNav['groupedChilds']['group_1'].length > 0">
            <ng-container *ngIf="currentNav['groupedChilds']['group_1'][0]['categoryGroupTitle'] == 1;else tTitle">
              <a class="menu-title" routerLink="/{{currentNav['groupedChilds']['group_1'][0]['urlPath']}}" (click)="clickSubMenu(currentNav['groupedChilds']['group_1'][0])">{{currentNav['groupedChilds']['group_1'][0]['navName']}}</a>
            </ng-container>
            <ul class="menu-items">
              <ng-container *ngFor="let childNav of currentNav['groupedChilds']['group_1']; index as _i">
                <ng-container *ngIf="_i==0 && childNav['categoryGroupTitle']==1;else childMenu1"></ng-container>
                <ng-template #childMenu1>
                  <li class="mt-8">
                    <a routerLink="/{{childNav['urlPath']}}" (click)="clickSubMenu(childNav)" [style]="childNav['parameters']['categoryColor'] | style">{{childNav['navName']}}<svg class="h-16 w-16 hidden fill-current"><title>Arrow pointing right</title><use href="/assets/iconSprite.svg#chevron-mini-right-end"></use></svg></a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </div>
        </nav>
        <nav class="col-span-2 mt-8">
          <div *ngIf="currentNav['groupedChilds'].hasOwnProperty('group_2') && currentNav['groupedChilds']['group_2'].length > 0">
            <ng-container *ngIf="currentNav['groupedChilds']['group_2'][0]['categoryGroupTitle'] == 1;else tTitle">
              <a class="menu-title" routerLink="/{{currentNav['groupedChilds']['group_2'][0]['urlPath']}}" (click)="clickSubMenu(currentNav['groupedChilds']['group_2'][0])">{{currentNav['groupedChilds']['group_2'][0]['navName']}}</a>
            </ng-container>
            <ul class="menu-items">
              <ng-container *ngFor="let childNav of currentNav['groupedChilds']['group_2']; index as _i">
                <ng-container *ngIf="_i==0 && childNav['categoryGroupTitle']==1;else childMenu2"></ng-container>
                <ng-template #childMenu2>
                  <li class="mt-8">
                    <a routerLink="/{{childNav['urlPath']}}" (click)="clickSubMenu(childNav)" [style]="childNav['parameters']['categoryColor'] | style">{{childNav['navName']}}<svg class="h-16 w-16 hidden fill-current"><title>Arrow pointing right</title><use href="/assets/iconSprite.svg#chevron-mini-right-end"></use></svg></a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </div>
        </nav>
        <div class="relative col-span-6 -mr-20 h-full row-start-1" style="grid-row: span 2 / span 2;grid-row-start: 1;grid-column-start: 7;grid-column-end: 13;">
          <ng-container *ngIf="currentNav['parameters'].hasOwnProperty('additionalDescription') && currentNav['parameters']['additionalDescription'] && currentNav['parameters']['additionalDescription']!=''">
            <div class="w-full h-full" [innerHTML]="currentNav['parameters']?.additionalDescription | html">
            </div>
          </ng-container>
         <!-- <div style="width: 100%; height: 100%;">
            <div style="display: flex; height: 100%; width: 100%; ">
              <div style="grid-row-start: 1;position: relative;height: 100%; width: 50%; aspect-ratio: 2 / 3;">
                <a href="/w-home">
                  <img alt="man wearing turtleneck standing on balcony" loading="lazy" size="100vw" style="position: absolute; height: 100%; width: 100%; inset: 0px; color: transparent; object-fit: cover; padding: 20px;" src="https://100000005-1252208446.file.myqcloud.com/images/1598328943999.jpg">
                </a>
              </div>
              <div style="grid-row-start: 1;position: relative;height: 100%;width: 50%; aspect-ratio: 2 / 3;">
                <a href="/m-home">
                  <img alt="man wearing turtleneck standing on balcony" loading="lazy" size="100vw" style="position: absolute; height: 100%; width: 100%; inset: 0px; color: transparent; object-fit: cover;" src="https://100000005-1252208446.file.myqcloud.com/images/1652076308171.jpg">
                </a>
                <div style="position: absolute; bottom: 15px; left: 20px; color: white; font-size: 13px; font-weight: 500; line-height: 18px;">
                  <span>春夏特辑 23</span>
                </div>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <!--H5显示下拉菜单-->
    <div class="lg-hidden h5-menu-container">
      <div class="grid w-full grid-cols-12 h-50 px-15 gap-x-15 md-px-20 md-gap-x-20 items-center">
        <div class="col-span-6 flex justify-start">
          <a routerLink="/{{logoPath}}" target="_self" class="acos-logo" (click)="gotoIndex()">
            <svg class="fill-current acos-logo"><title>COS Logo</title><use href="/assets/iconSprite.svg#logo"></use></svg>
          </a>
        </div>
        <div class="col-span-6 flex justify-end" >
          <svg (click)="closeMenuBtn()" class="inline h-16 w-16 fill-current"><title>Close</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
        </div>
      </div>
      <div class="overflow-y-scroll flex flex-col h-full h5-menu-block">
        <div class="w-full flex border-b border-t">
          <ng-container *ngFor="let category of categories;index as i">
            <div class="flex w-50 h5-menu-items" [ngClass]="{'h5-menu-border': i>0}">
              <div class="pl-15">
                <div class="h5-menu-group-items" [ngClass]="{'h5-menu-bold': h5currentMenuId==category['navId']}" (click)="clickDropMenu(category)">{{category.navName}}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="flex grow pt-32 flex-col">
          <section class="mt-24 h5-section" *ngIf="currentNav['groupedChilds'].hasOwnProperty('group_0') && currentNav['groupedChilds']['group_0'].length > 0">
            <div class="px-15 h5-menu-items pt-16 pb-16 border-b" *ngIf="currentNav['groupedChilds']['group_0'][0]['categoryGroupTitle'] == 1">
              {{currentNav['groupedChilds']['group_0'][0]['navName']}}
            </div>
            <ul>
              <ng-container *ngFor="let childNav of currentNav['groupedChilds']['group_0']; index as _i">
                <ng-container *ngIf="_i==0 && childNav['categoryGroupTitle']==1;else h5ChildMenu"></ng-container>
                <ng-template #h5ChildMenu>
                  <li class="h5-menu-items">
                    <a routerLink="/{{childNav['urlPath']}}" (click)="clickSubMenu(childNav)" [style]="childNav['parameters']['categoryColor'] | style" class="px-15 h5-menu-items-a h5-menu-bold">{{childNav['navName']}}</a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </section>

          <section class="mt-40 h5-section" *ngIf="currentNav['groupedChilds'].hasOwnProperty('group_1') && currentNav['groupedChilds']['group_1'].length > 0">
            <div class="px-15 h5-menu-items pb-16 border-b" *ngIf="currentNav['groupedChilds']['group_1'][0]['categoryGroupTitle'] == 1">
              {{currentNav['groupedChilds']['group_1'][0]['navName']}}
            </div>
            <ul>
              <ng-container *ngFor="let childNav of currentNav['groupedChilds']['group_1']; index as _i">
                <ng-container *ngIf="_i==0 && childNav['categoryGroupTitle']==1;else h5ChildMenu1"></ng-container>
                <ng-template #h5ChildMenu1>
                  <li class="h5-menu-items">
                    <a routerLink="/{{childNav['urlPath']}}" (click)="clickSubMenu(childNav)" [style]="childNav['parameters']['categoryColor'] | style" class="px-15 h5-menu-items-a h5-menu-bold">{{childNav['navName']}}</a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </section>

          <section class="mt-40 h5-section" *ngIf="currentNav['groupedChilds'].hasOwnProperty('group_2') && currentNav['groupedChilds']['group_2'].length > 0">
            <div class="px-15 h5-menu-items pb-16 border-b" *ngIf="currentNav['groupedChilds']['group_2'][0]['categoryGroupTitle'] == 1">
              {{currentNav['groupedChilds']['group_2'][0]['navName']}}
            </div>
            <ul>
              <ng-container *ngFor="let childNav of currentNav['groupedChilds']['group_2']; index as _i">
                <ng-container *ngIf="_i==0 && childNav['categoryGroupTitle']==1;else h5ChildMenu2"></ng-container>
                <ng-template #h5ChildMenu2>
                  <li class="h5-menu-items">
                    <a routerLink="/{{childNav['urlPath']}}" (click)="clickSubMenu(childNav)" [style]="childNav['parameters']['categoryColor'] | style" class="px-15 h5-menu-items-a h5-menu-bold">{{childNav['navName']}}</a>
                  </li>
                </ng-template>
              </ng-container>
            </ul>
          </section>

          <section class="mt-40" style="margin-bottom: 40px;">
            <div class="px-15 h5-menu-items pb-16 ">
              信息
            </div>
            <ul style="padding-bottom: 90px;">
              <li class="h5-menu-items" *ngIf="!init.isLogin()" (click)="login()">
                <a class="px-15 h5-menu-items-a h5-menu-bold">登录</a>
              </li>
              <li class="h5-menu-items" *ngIf="!init.isLogin()" (click)="register()">
                <a class="px-15 h5-menu-items-a h5-menu-bold">注册</a>
              </li>
              <li class="h5-menu-items" (click)="account()">
                <a class="px-15 h5-menu-items-a h5-menu-bold">账户</a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="grid w-full grid-cols-12 h-50 px-15 gap-x-15 md-px-20 md-gap-x-20 items-center" [ngClass]="{'header-top': !specificPage, 'nav-w nav-fixed':specificPage} ">
    <ng-container *ngIf="!showBack; else headerBackTpl;">
      <div class="mainNav md-hidden items-center lg-flex relative">
        <!--<a (click)="menuBtn()" class="menuIcon">
          <svg class="inline h-16 w-16 fill-current">
            <title>导航</title>
            <use href="/assets/iconSprite.svg#nav-navigation-desktop"></use>
          </svg>
        </a>-->
      </div>
      <div class="md-hidden items-center lg-flex" *ngFor="let category of categories;index as i">
        <!--选中加类nav-link-current-->
        <a class="nav-link nav-menu" [ngClass]="{'nav-link-current': menuId==category['navId']}" (click)="clickMenu(category)" (mouseover)="menuMouseOver(category)"  [style]="category['parameters']['categoryColor'] | style">{{category.navName}}</a>
      </div>
    </ng-container>
    <ng-template #headerBackTpl>
      <div class="mainNav md-hidden items-center lg-flex relative col-span-3">
        <a class="flex font_xs_regular" routerLink="/cart">
          <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-left"></use></svg>
          <div class="pl-10">返回购物袋</div>
        </a>
      </div>
    </ng-template>

    <div class="col-span-6 flex justify-start lg-col-start-6 lg-col-end-8 lg-justify-center">
      <a routerLink="/{{logoPath}}" target="_self" class="acos-logo" (click)="gotoIndex()">
        <svg class="fill-current acos-logo"><title>COS Logo</title><use href="/assets/iconSprite.svg#logo"></use></svg>
      </a>
    </div>

    <div class="col-span-6 flex items-center justify-end gap-24 lg-col-start-10 lg-col-end-13">
      <a (click)="search()">
        <svg class="inline h-16 w-16  transition fill-current">
          <title>搜索</title>
          <use href="/assets/iconSprite.svg#nav-search-desktop"></use>
        </svg>
      </a>
      <div class="md-hidden">
        <a (click)="account()">
          <svg class="inline h-16 w-16  transition fill-current">
            <title>账户</title>
            <use href="/assets/iconSprite.svg#nav-account-desktop"></use>
          </svg>
        </a>
      </div>
      <a (click)="favorites()" class="md-hidden">
        <ng-container *ngIf="init.isLogin() && getFavoriteModel()>0;else fOtherBlock;">
          <div class="relative text-navbar transition duration-300 opacity-100">
            <span class="icon_num">{{getFavoriteModel()}}</span>
            <svg class="inline h-16 w-16 transition fill-current">
              <title>收藏</title>
              <use href="/assets/iconSprite.svg#nav-heart-active-mobile"></use>
            </svg>
          </div>
        </ng-container>
        <ng-template #fOtherBlock>
          <svg class="inline h-16 w-16 transition fill-current">
            <title>收藏</title>
            <use href="/assets/iconSprite.svg#nav-heart-desktop"></use>
          </svg>
        </ng-template>
      </a>
      <a (click)="favorites()" class="lg-hidden">
        <svg class="inline h-16 w-16 transition fill-current">
          <title>收藏</title>
          <use href="/assets/iconSprite.svg#nav-heart-desktop"></use>
        </svg>
      </a>
      <a (click)="cart()">
        <ng-container *ngIf="init.isLogin() && getCartModel()?.total_qty_ordered>0; else cOtherBlock;">
          <div class="relative text-navbar transition duration-300 opacity-100">
            <span class="icon_num">{{getCartModel()?.total_qty_ordered}}</span>
            <svg class="inline h-16 w-16 transition fill-current">
              <title>购物车</title>
              <use href="/assets/iconSprite.svg#nav-bag-active-mobile"></use>
            </svg>
          </div>
        </ng-container>
        <ng-template #cOtherBlock>
          <svg class="inline h-16 w-16  transition fill-current">
            <title>购物车</title>
            <use href="/assets/iconSprite.svg#nav-bag-desktop"></use>
          </svg>
        </ng-template>
      </a>
      <a class="lg-hidden menuIcon" (click)="menuBtn()">
        <svg class="w-16 h-16 inline fill-current">
					<title>Navigation</title><use href="/assets/iconSprite.svg#nav-navigation-desktop"></use>
				</svg>
      </a>
    </div>
  </div>

  <!-- 隐私政策 -->
  <app-privacy-policy></app-privacy-policy>
</div>

