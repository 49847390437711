import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {InitializeService} from './initialize.service';
import {ScriptService} from './script.service';
import {isPlatformBrowser} from '@angular/common';

declare const Udeskjs: any;

@Injectable()
export class UdeskService {
  public storeId: any;
  constructor(public init: InitializeService, public script: ScriptService, @Inject(PLATFORM_ID) private platformId: Object) {
  }

  setCustomerInfo(mobile:any,level:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load('udesk').then((_data:any) => {
        const udeskObject = new Udeskjs();
        udeskObject.setCustomerInfo(mobile,level);
      }).catch(error => console.log(error));
    }
  }
}
