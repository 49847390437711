import {AfterViewInit, Component, Input, Output,OnChanges, OnInit,EventEmitter} from '@angular/core';
import {ProductService} from '../../../services/product.service';
import {SearchService} from '../../../services/search.service';
import {StorageService} from '../../../services/storage.service';
import {InitializeService} from '../../../services/initialize.service';
import { ProductModel } from '../../../models/product.model';
@Component({
  selector: 'app-product-promotion-tags',
  templateUrl: './product-promotion-tags.component.html',
  styleUrls: ['./product-promotion-tags.component.css'],
})
export class ProductPromotionTagsComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() promotionTagList: any;
  @Input() customPromotionTagList: boolean = false;
  @Input()
	productModel!: ProductModel;
  @Input() tagStyle:any;
  @Output() checkForwardPurchase = new EventEmitter<object>();
  
	tagColor : any = '#757575';
	customTagColor : any = '#757575';
  public currency:any;
  constructor(
    public productService: ProductService,
	public searchService: SearchService,
	public init: InitializeService,
	private localStorage: StorageService) {
		
  }
 
   ngOnChanges(){
 
  }
  ngOnInit() {
	this.currency = this.init.currency;
  }
  ngAfterViewInit() {
  }
  
 
  public checkGeneralPromotionTag(customerGroupIds : any) {
  	if (customerGroupIds) {
  		var customerGroupId = this.localStorage.getItem('groupId');
  		if (customerGroupId && customerGroupIds.indexOf(parseInt(customerGroupId, 0)) !== -1) {
  			return true;
  		} else {
  			return false;
  		}
  	}
  	return true;
  }
  
  //会员提前购标签是否显示
  public checkForwardPurchaseLabel(memberLevelIds : any) {
  	if (memberLevelIds) {
  		var memberLevelId = this.localStorage.getItem('memberLevelId');
  		var memberIsActive = this.localStorage.getItem('memberIsActive');
		
		this.checkForwardPurchase.emit(memberLevelIds);
  		if (memberLevelId && memberLevelIds.indexOf(parseInt(memberLevelId, 0)) !== -1 && memberIsActive === 'Active') {
  			return true;
  		} else {
  			return false;
  		}
  	}
  	return true;
  }
}
