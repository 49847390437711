import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cms-block-swiper-editor',
  template: `
    <div class="form-group" *ngFor="let slide of block.data.slides; let i = index">
      <label for="inputProductId">请填写第{{i + 1}}个轮播的HTML内容 <span class="glyphicon glyphicon-trash"
        (click)="deleteSlide(i)"></span>
          <span style="margin-left: 15px;" class="glyphicon glyphicon-check" (click)="previewSlide(i)"></span>
      </label>
      <textarea id="slides_{{i}}" class="form-control" rows="3" placeholder="请填写自定义HTML标签">
          {{block.data.slides[i]}}
      </textarea>
	  
	  <div class="checkbox">
	    <label>
	      <input [(ngModel)]="block.data.swiperstate[i]" id="swiperstate_{{i}}"  type="checkbox" (click)="updateSwipers(i)">关闭手动滑动
		</label>
	  </div>
	  
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-default" (click)="addSlide()">添加一个新的Slide</button>
    </div>
    <hr/>
    <div class="radio">
      <label>
        <input type="radio" name="direction" [(ngModel)]="block.data.config.direction" id="optionsRadios1"
               value="horizontal" checked>
        水平滚动
      </label>
    </div>
    <div class="radio">
      <label>
        <input type="radio" name="direction" [(ngModel)]="block.data.config.direction" id="optionsRadios2"
               value="vertical">
        垂直滚动
      </label>
    </div>
    <div class="form-group">
      <label for="slidesPerViewPc">每屏显示Slide的数量(PC)</label>
      <input [(ngModel)]="block.data.config.slidesPerView" type="text" class="form-control" id="slidesPerView"
             value="1">
    </div>
	
	<div class="form-group">
	  <label for="mobSlidesPerView">每屏显示Slide的数量(H5)</label>
	  <input [(ngModel)]="block.data.config.mobSlidesPerView" type="text" class="form-control" id="mobSlidesPerView"
	         value="1">
	</div>
	
	<div class="form-group">
	  <label for="initialSlide">默认显示第X帧(PC)</label>
	  <input [(ngModel)]="block.data.config.initialSlide" type="text" class="form-control" id="initialSlide" value="0">
	</div>
	
	<div class="form-group">
	  <label for="mobInitialSlide">默认显示第X帧(H5)</label>
	  <input [(ngModel)]="block.data.config.mobInitialSlide" type="text" class="form-control" id="mobInitialSlide" value="0">
	</div>
	
	<div class="form-group">
	  <label for="spaceBetween">每帧之间的距离（单位px）</label>
	  <input [(ngModel)]="block.data.config.spaceBetween" type="text" class="form-control" id="spaceBetween" value="0">
	</div>
	
	<div class="form-group">
	  <label for="mobSlidersNum">H5显示X帧</label>
	  <input [(ngModel)]="block.data.config.mobSlidersNum" type="text" class="form-control" id="mobSlidersNum" value="0">
	</div>
	

    <div class="checkbox">
      <label>
        <input [(ngModel)]="block.data.config.keyboard" type="checkbox">支持键盘控制
      </label>
    </div>
    <div class="checkbox">
      <label>
        <input [(ngModel)]="block.data.config.mousewheel" type="checkbox">支持鼠标控制
      </label>
    </div>
    <div class="checkbox">
      <label>
        <input [(ngModel)]="block.data.config.scrollbar" type="checkbox">显示 Scroll Bar
      </label>
    </div>
    <div class="checkbox">
      <label>
        <input [(ngModel)]="block.data.config.pagination" type="checkbox">显示 Pagination
      </label>
    </div>
    <div class="checkbox">
      <label>
        <input [(ngModel)]="block.data.config.navigation" type="checkbox">显示 Navigation
      </label>
    </div>
 
	
  `,
  styles: []
})
export class CmsBlockSwiperEditorComponent implements OnInit {

  @Input() block:any;

  constructor() {
  }

  ngOnInit() {
    if (!this.block.hasOwnProperty('data')) {
      this.block.data = {};
    }

    if (!this.block.data.hasOwnProperty('slides')) {
      this.block.data.slides = [''];
    }
	 
	if (!this.block.data.hasOwnProperty('swiperstate')) {
	  this.block.data.swiperstate = [''];
	}
	
	if (!this.block.data.hasOwnProperty('mobhidden')) {
	  this.block.data.mobhidden = [''];
	}
	
	
    if (!this.block.data.hasOwnProperty('config')) {
      this.block.data.config = {
        scrollbar: false,
        pagination: false,
        navigation: false,
		spaceBetween:0
      };
    }
  }

  addSlide() {
    this.block.data.slides.push('');
  }

  deleteSlide(i:number) {
    this.block.data.slides.splice(i, 1);
  }

  previewSlide( data: string ) {
    const val:any =  document.querySelector('#slides_' + data );
    this.block.data.slides[ data ] = val['value'];
  }
  
  updateSwipers(data: string){
	  const val =  document.querySelector('#swiperstate_' + data );
	  if(this.block.data.swiperstate[ data ] == true){
		 this.block.data.swiperstate[ data ] = false; 
	  }
	  
  }
}
