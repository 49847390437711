<div class="myAccount_right">
  <div *ngIf="init.is_loading;else contentBlock" style="text-align: center"> 获取中... </div>
  <ng-template #contentBlock>
    <div class="topSection clearfix">
      <h5 class="pull-left">发票详情</h5>
    </div>
    <div id="profileSummary" class="o-my-details summary">
      <div class="userInfo">
        <div class="data-sections">
          <div class="data-section phone">
            <div class="phone-label">订单编号: <a [routerLink]="['/my-account/orderdetail']" [queryParams]="{orderid: orderId}" class="a-link">{{cloudOrderResult?.tid ? cloudOrderResult?.tid : cloudOrderResult?.orderCode}}</a></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="invoiceItems.length > 0;else notContent" class="hidden-sm hidden-xs">
      <table width="100%" border="0" aria-describedby="invoice" cellspacing="0" cellpadding="0" class="orderTable" *ngFor="let item of invoiceItems">
        <tr>
          <th width="30%" scope="col"><span class="orderNumber">服务单号：</span><a class="a-link">{{item.invoiceCode}}</a></th>
          <th scope="col" style="text-align: center; padding: 0;">发票金额</th>
          <th scope="col" width="23%">发票状态</th>
          <th scope="col" width="20%">操作</th>
        </tr>
        <tr>
          <td>
            <div class="gInfo clearfix">
              <div class="pull-left textInfo">
                <strong>发票抬头</strong><br/>
                {{item.invoiceTitle}}<br/><br/>
                <p>开票时间：{{item.createTime}}</p>
              </div>
            </div>
          </td>
          <td>
            <p>{{init.currency + item.invoiceAmount}}</p>
          </td>
          <td>
            <p>{{taskStatus[item.taskStatus]}}</p>
          </td>
          <td>
            <a *ngIf="item.taskStatus == 5 && item.invoiceType == 0" (click)="saveToEail(item.taskSn)"><strong>发送至邮箱</strong></a>
            <a *ngIf="item.invoiceType == 1">退货红冲</a>
          </td>
        </tr>
      </table>
    </div>
    <ng-template #notContent>
      <div class="o-my-orders-empty">
        <p class="a-paragraph orders-empty-text" style="text-align: center">订单无发票信息</p>
      </div>
    </ng-template>
  </ng-template>
</div>
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': applyCancel}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="applyCancel" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">填写邮箱地址</h2>
      <div class="a-paragraph">
        <p><input class="a-input js-a-input" type="text"  name="hmStaffEmail" [(ngModel)]="email"></p>
        <label *ngIf="valite.email.errorTips && valite.email.is_active"  class="a-label js-a-label" data-placeholder="邮箱地址" style="display: block; text-align: left; color:#D44511">{{emailMessage}}</label>
      </div>
      <a class="a-button is-primary" (click)="confirmSaveToEail()">确认提交</a>
      <button class="a-button is-secondary js-close-button" (click)="closeOverlay()"><span>再考虑下</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>


<!-- 显示加入购物车报错：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_modal}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_modal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 显示加入购物车报错 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2" *ngIf="show_error_message">抱歉！</h2>
      <p class="a-paragraph">{{message}}</p>
    </div>
    <!-- 显示加入购物车报错 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>


