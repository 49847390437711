import {Component, Inject, OnInit, OnDestroy, ChangeDetectorRef, PLATFORM_ID} from '@angular/core';
import {InitializeService} from '../../../services/initialize.service';
import {HttpParams} from '@angular/common/http';
import {StorageService} from '../../../services/storage.service';
import {CartService} from '../../../services/cart.service';
import {Router} from '@angular/router';
import {CmsService} from '../../../services/cms.service';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {CartModel} from '../../../models/cart.model';
import {isPlatformBrowser} from '@angular/common';
import {TrackingcodeService} from '../../../services/trackingcode.service';
import {ProductService} from '../../../services/product.service';
import {Angulartics2} from 'angulartics2';
import {FavoriteService} from '../../../services/favorite.service';
import {Observable} from 'rxjs/Rx';
import {ProductModel} from '../../../models/product.model';
import {CallComponentService} from '../../../services/callcomponent.service';
import {AbTrackingService} from '../../../services/abtracking.service';
import $ from 'jquery';


@Component({
  selector: 'app-shopping-cart-item',
  templateUrl: './shopping-cart-item.component.html',
  styleUrls: ['./shopping-cart-item.component.css']
})
export class ShoppingCartItemComponent implements OnInit, OnDestroy{
  public orderResult!: CartModel;
  public cartLoading: boolean = true;
  public ProductSku: any;
  public _qty: any;
  public rmshoppingBagsId: any;
  // public shopBag;
  public integralNum: any;
  public shoppingBagsId = '';
  public disabled = false; // 控制按钮不可点击状态
  public slideIndex!: number;
  public slideGroup!: number;
  public checked!: boolean;
  public isempty = false; // 控制购物车为空的样式判断
  public allChecked!: boolean;
  public promotion: boolean= false; // 优惠券输入判断
  public signColor = false;
  public addColour = false;
  public specimen: boolean = true; //  样品
  public divId: any;
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    keyboard: false,
    navigation: true,
    pagination: false,
    scrollbar: false,
    centeredSlides: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 4,
    slidesPerGroup: 4,
  };
  public timer : any;
  public paragraph: String = '您输入的优惠券代码有误，请查正。';
  public totalBlock = false;

  public where = 'cart';
  public product_ids = [];
  public quote_id: any;

  public remindSuccess = false;
  public remindError = false;
  public remindErrorMsg: any;
  public remindBtnText = '到货通知我';
  public remindResult: any = {code: '', msg: '', data: ''};
  public arrivalNoticeConfig: any = {code: 0, data: {value: false}};
  public showArrivalNotice:boolean = false;
  public canEmptyInvaild:boolean = false;

  public isWeb = true;
  // size集合
  public productSizeArray:any = [];
  public showSizeBox = false
  public currentItemId = 0
  public currentValueId = 0;

  public delIndex = -1;
  public invalidDelIndex = -1;
  public addIndex = -1;
  public invalidAddIndex = -1;
  public removeIndex = -1;
  public invalidRemoveIndex = -1;
  public favoriteItemIds:any = [];
  public showCheckoutDetail = false;
  public recommendLists:any = [];


  //快速加购数据
  public showBox = false;
  public frontCode = '';
  public salePrice:any;
  constructor(public init: InitializeService,
              private router: Router,
              public cart: CartService,
              public cms: CmsService,
              public productService: ProductService,
              public cartService: CartService,
              private favoriteService:FavoriteService,
              private changeDetectorRef: ChangeDetectorRef,
              private tracking: TrackingcodeService,
              private abTracking: AbTrackingService,
			        private angulartics2: Angulartics2,
              @Inject(PLATFORM_ID) private platformId: Object,
              public eventSubscribeService: CallComponentService,
              private localStorage: StorageService) {
  }

  ngOnInit() {
    // 监听页面宽度
    if (isPlatformBrowser(this.platformId)) {
      Observable.fromEvent(window, 'resize').subscribe((event) => {
        // 操作
        this.isWeb = true;
        if (document.body.clientWidth < 1151) {
          this.isWeb = false;
        }
      });
    }
    /*初始化时*/
    this.quote_id = this.cart.quoteData;
    if (isPlatformBrowser(this.platformId)) {
      this.cartRequest();
      // this.productService.showArrivalNotice(this.init.merchantId,this.init.cloudStoreId).then(result => {
      //   this.arrivalNoticeConfig = result;
      //   if (this.arrivalNoticeConfig.code === 1000) {
      //     this.showArrivalNotice = this.arrivalNoticeConfig.data.value;
      //   }
      // }).catch(error => error);

      //仅登录状态查收藏
      if (this.init.isLogin()) {
        this.favoriteService.clearFavoriteResult();
        this.favoriteItems();
      }
      //监听当前页面登录后并获取用户购物车
      this.eventSubscribeService.loginAndGetCart.subscribe((value:any) => {
        if (this.init.isLogin()) {
          this.resetData(value);
          this.favoriteService.clearFavoriteResult();
          this.favoriteItems();
        }
      });
    }

    // 订阅尺码label is-disable是否显示
    this.eventSubscribeService.showSizeBoxCommon.subscribe((value:any) => {
      this.showBox = true;
      this.frontCode = value.frontCode;
	  this.salePrice = value.salePrice;
    });

  }

  favoriteItems() {
    this.favoriteService.getFavoriteIds().then((val: any) => {
      if (val.code == 1000) {
        if (val.data.length > 0) {
          val.data.forEach((item : any) => {
            this.favoriteItemIds[item.itemId + '_' + item.valueId] = item['favoritesId'];
          });
        }
      }
    });
  }

  getCartModel() {
    return this.cart.cartModel;
  }
  getFavoriteModel(){
    let num = 0;
    if(this.init.isLogin()){
      if(this.favoriteService.favoriteModel &&  this.favoriteService.favoriteModel.hasOwnProperty('valid_total')){
        num = this.favoriteService.favoriteModel['valid_total']
      }
    }
    return num;
  }
  async cartRequest() {
    const val = await this.cart.getCartData().then();
    this.resetData(val);
  }

  resetData(val: any){
    this.orderResult = val;
    let frontCodes: any = [];
    if (this.cart.cartModel.getCartItems().length === 0) {
      this.cartLoading = false;
    } else {
      // 获取详情
      this.cart.cartModel.getCartItems().forEach((_item: any) => {
        frontCodes.push(_item.skuProperties[0]['frontCode']);
        this.goodsDetail(_item);
      });

      this.cartLoading = false;
      // matomo tracking
      this.tracking.cart(this.cart.cartModel);

      //google analytics
      // 新版Ga Tracking Js
      this.gaTrackingCart(this.cart.cartModel);
    }
    this.cartInfo();
    this.recomentLists(frontCodes);
  }

  async recomentLists(frontCodes:any){
    this.recommendLists = [];
    if(this.cart.cartModel?.getInvalidItems().length > 0){
      this.cart.cartModel?.getInvalidItems().forEach((_item: any)=>{
        frontCodes.push(_item.skuProperties[0]['frontCode']);
      })
    }
    if(frontCodes.length > 0){
      const recommends = await this.productService.getCartRecommends(frontCodes.join(","));
      if(recommends['code']==1){
        if(recommends['data']['products']){
          for(let i in recommends['data']['products']){
            const _item = JSON.parse(recommends['data']['products'][i]);
            this.recommendLists.push(_item['data']);
          }
        }
      }
    }
  }

  goodsDetail(item:any){
    this.productService.getProductByItemId(item['itemId']).then( (val: any)=> {
      if (val['code'] == 1000) {
        const productModel = new ProductModel(val.data);
        productModel.getColorsCloud('');
        let tempSizeArr: any[] = [];
        // 获取尺码  品方要求不过滤无库存sku
        productModel.sku_list[item['valueId']].forEach((sku_item:any) => {
          //if(sku_item.qty > 0){
          tempSizeArr.push(sku_item);
          //}
        });
        this.productSizeArray[item['itemId']+'-'+item['valueId']]  = tempSizeArr;
        this.productSizeArray[item['itemId']+'-'+item['valueId']].sort(function(a:any, b:any) {
          return a['sortOrder'] - b['sortOrder'];
        });
      }
    });
  }

  showSize(item: any){
    if(this.currentItemId==item['itemId'] && this.currentValueId==item['valueId']){
      this.showSizeBox = !this.showSizeBox;
    } else {
      this.showSizeBox = true;
    }
    this.currentItemId = item['itemId'];
    this.currentValueId = item['valueId'];
  }

  select_Size(e: any,item: any,sizeItem: any){
    e.stopPropagation();
    if(sizeItem.qty < 1 || item['skuId'] == sizeItem['skuId']){
      return;
    }
    const currentItem = {
      'skuProperties':[item['skuProperties'][0],{'valueName': sizeItem['valueName'], 'valueId': sizeItem['valueId']}],
      'skuCode': sizeItem['skuCode'],
      'itemName': item['itemName'],
      'itemCode': item['itemCode'],
      'price': sizeItem['price'],
      'markingPrice': sizeItem['markingPrice'],
    };
    const params = {
      'sourceSkuId': item['skuId'],
      'targetSkuId': sizeItem['skuId'],
      'qty': sizeItem['qty'] <= item['qty'] ? sizeItem['qty'] : item['qty'],
    }
    this.cart.updateItemsSizeServer(params).then(_value => {
      this.showSizeBox = false;
      if(_value['code'] == 1000){
        this.cartServeData();
        // 向google推送新增购物车商品操作
        if (this.init.gaTrackingFlag && currentItem) {
          // 新版Ga Tracking Js
          this.gaTrackingAddCart(currentItem);

          // 老版Ga Tracking Js
          this.angulartics2.eventTrack.next({
            action: 'add_to_cart',
            properties: {
              gstCustom: {
                items: [{
                  id: sizeItem['skuCode'],
                  name: item['itemName'],
                  brand: 'COS',
                  price: sizeItem['markingPrice'],
                  quantity: 1,
                  currency: 'CNY'
                }],
                currency: 'CNY'
              }
            }
          });
        }
      } else {
        this.remindError = true;
        this.remindErrorMsg = _value['msg'];
      }
    })
  }

  gaTrackingCart(_cartModel: CartModel){
	  if (this.init.gaTrackingFlag ) {
		  // 向google推送删除购物车商品操作
		  const itemsArray = [];
		  if (this.cart.cartModel.items) {
			for (const i in this.cart.cartModel.items) {
				const cartitem = this.cart.cartModel.items[i];
					var frontUrlPath = cartitem.skuProperties[0].frontUrlPath;
					var product_category = this.productService.getCategoryByFrontUrl(frontUrlPath);
					itemsArray.push({
						product_action: 'checkout',
						product_id: cartitem.itemCode,//款号
						product_article_id: cartitem.skuProperties[0].frontCode,//CC
						product_variant_id: cartitem.skuCode,//SKU
						product_name: cartitem.itemName,
						product_brand: 'COS',
						product_category:product_category,
						product_color:cartitem.skuProperties[0].valueName,
						product_price:cartitem.price,
						product_price_original:cartitem.markingPrice,
						product_price_type: cartitem.price < cartitem.markingPrice ? 'sale' : 'full price',
						product_size: cartitem.skuProperties[1].valueName,
						product_size_code: cartitem.skuProperties[1].valueId,
						product_quantity:cartitem.qty
					});
			}
		  }
		  this.tracking.gaTrackingCart(itemsArray);
	  }
  }
  cartInfo() {
    const that = this;
    if ((this.cart.cartModel.itemCodes && this.cart.cartModel.itemCodes != '') || this.cart.cartModel.getInvalidItems().length > 0) {
      that.isempty = false;
    } else {
      that.isempty = true;
    }
  }

  /*将商品移到已保存或者移除*/
  settleLater(item_key: string, check: boolean) {
    this.checked = check;
    const params = new HttpParams()
      .set('action', 'change_checkout_items')
      .set('item_key', item_key);
    this.cart.settleLaterServer(params).then(_model => {
      this.cartServeData();
      if (this.cart.cartModel.checkedItems().length > 0) {
        this.checked = true;
      } else  {
        if (this.cart.cartModel.uncheckedItems().length > 0) {
          this.checked = false;
        }
      }
      // this.orderResult = val;
    });
  }

  emptyInvaild(){
    this.canEmptyInvaild = true;
  }

  emptyInvaildConfirm(){
    this.cart.emptyInvaildItems().then((_val) => {
      this.cart.getCartData().then(_resp => {
        this.cartInfo();
      });
      this.timer = setTimeout(() => {
        this.canEmptyInvaild = false;
      }, 600);
    });
  }

  delItem(i:any){
    this.delIndex = i;
    this.addIndex = -1;
    this.removeIndex = -1;
  }

  delInvalidItem(i:any){
    this.invalidDelIndex = i;
    this.invalidRemoveIndex = -1;
    this.invalidAddIndex = -1;
  }

  //取消删除商品弹层
  cancelDel(){
    this.promotion = false;
    this.delIndex = -1;
    this.addIndex = -1;
    this.removeIndex = -1;
    this.invalidDelIndex = -1;
    this.invalidRemoveIndex = -1;
    this.invalidAddIndex = -1;
  }

  delConfirm(skuid: any,is_invalid=false) {
    let currentItem: any = {};
    if(!is_invalid){
      currentItem = this.cart.cartModel.getItemDataBySku(skuid);
    } else {
      currentItem = this.cart.cartModel.getInvalidItemDataBySku(skuid);
    }
    this.cart.removeItemServer(skuid).then((_val) => {
    }).then(() => {
      this.cancelDel();
      this.cart.getCartData().then(_resp => {
        if (this.cart.cartModel.getCartItems().length < 1 && this.cart.cartModel.getInvalidItems().length == 0) {
          this.isempty = true;
          return false;
        } else {
          this.isempty = false;
		      return;
        }
      });
      // 向google推送删除购物车商品操作
      if (this.init.gaTrackingFlag && currentItem) {
        // 新版Ga Tracking Js
        this.gaTrackingRemoveCart(currentItem,0);

        // 老版Ga Tracking Js
        this.angulartics2.eventTrack.next({
          action: 'remove_from_cart',
          properties: {
            gstCustom: {
              items: [{
                id: currentItem['skuCode'],
                name: currentItem['itemName'],
                brand: 'COS',
                price: currentItem['markingPrice'],
                quantity: 1,
                currency: 'CNY'
              }],
              currency: 'CNY'
            }
          }
        });
      }
    });
  }

	quickAddFavorite(data:any){
		let frontCode = data.itemCode;
		let currentItem: any = {};
		currentItem = data.item;
		this.favoriteService.addItemFavorite(frontCode,currentItem['itemId'],currentItem['valueId']).then( (val:any)=>{
		  if(val.code == -1002){
		  } else if(val.code === 1000){
		    const result:any = {'code':val.code,'type':'add'};
		    this.eventSubscribeService.changeFavoriteBox.next(result);
		    this.favoriteItems();
		    this.favoriteService.clearFavoriteResult();
		    this.abTracking.trackingAction('ADD_FAVORITE', currentItem['itemId'], { valueId: currentItem['valueId'], fontCode: frontCode, path: this.router.url});
		    this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage);
		    if (this.init.gaTrackingFlag) {
		      this.gaTrackingFavorite('save',data,'add_to_favourites',true);
		    }
		  }
		});
	}

	quickRemoveFavoriteItem(data:any){
		var item = data.item;
		 if(this.favoriteItemIds.hasOwnProperty(item.itemId + '_' + item.valueId)){
		   const favoritesId = this.favoriteItemIds[item.itemId + '_' + item.valueId];
		   const params = new HttpParams().set('favorites_id',favoritesId);
		   this.favoriteService.delFavoriteItem(favoritesId,params).then( val=>{
		     if(val.code == -1002){
		     } else if(val.code === 1000){
		       delete (this.favoriteItemIds[item.itemId + '_' + item.valueId]);
		       const result:any = {'code':val.code,'type':'remove'};
		       this.eventSubscribeService.changeFavoriteBox.next(result);
		       this.favoriteService.clearFavoriteResult();
		       this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage);
		       if (this.init.gaTrackingFlag) {
		         this.gaTrackingFavorite('unsave',data,'remove_from_favourites',true);
		       }
		     }
		   })
		 }
	}

  addFavorite(item: any,index: number, is_invalid = false){
    let currentItem: any = {};
    if(!is_invalid){
      currentItem = this.cart.cartModel.getItemDataBySku(item['skuId']);
    } else {
      currentItem = this.cart.cartModel.getInvalidItemDataBySku(item['skuId']);
    }
    this.favoriteService.addItemFavorite(currentItem.skuProperties[0].frontCode,item['itemId'],item['valueId']).then( (val:any)=>{
      if(val.code == -1002){
        this.promotion = true;
        this.paragraph = val.msg;
      } else if(val.code === 1000){
        const result:any = {'code':val.code,'type':'add'};
        if(!is_invalid){
          this.addIndex = index;
          if(this.removeIndex == index){
            this.removeIndex = -1;
          }
        } else {
          this.invalidAddIndex = index;
          if(this.invalidRemoveIndex == index){
            this.invalidRemoveIndex = -1;
          }
        }
        // this.eventSubscribeService.changeFavoriteBox.next(result);
        this.favoriteItems();
        this.favoriteService.clearFavoriteResult();
        this.abTracking.trackingAction('ADD_FAVORITE', item['itemId'], { valueId: item['valueId'], fontCode: currentItem.skuProperties[0].frontCode, path: this.router.url});
        this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage);
        if (this.init.gaTrackingFlag) {
          this.gaTrackingFavorite('save',currentItem,'add_to_favourites');
        }
      }
    });
  }

  removeConfirm(item: any,index: number, is_invalid = false){
    let currentItem: any = {};
    if(!is_invalid){
      currentItem = this.cart.cartModel.getItemDataBySku(item['skuId']);
    } else {
      currentItem = this.cart.cartModel.getInvalidItemDataBySku(item['skuId']);
    }
    if(this.favoriteItemIds.hasOwnProperty(item.itemId + '_' + item.valueId)){
      const favoritesId = this.favoriteItemIds[item.itemId + '_' + item.valueId];
      const params = new HttpParams().set('favorites_id',favoritesId);
      this.favoriteService.delFavoriteItem(favoritesId,params).then( val=>{
        if(val.code == -1002){
          this.promotion = true;
          this.paragraph = val.msg;
        } else if(val.code === 1000){
          // this.removeIndex = -1;
          // this.invalidRemoveIndex = -1;
          if(!is_invalid){
            this.removeIndex = index;
            if(this.addIndex == index){
              this.addIndex = -1;
            }
          } else {
            this.invalidRemoveIndex = index;
            if(this.invalidAddIndex == index){
              this.invalidAddIndex = -1;
            }
          }
          delete (this.favoriteItemIds[item.itemId + '_' + item.valueId]);
          const result:any = {'code':val.code,'type':'remove'};
          // this.eventSubscribeService.changeFavoriteBox.next(result);
          this.favoriteService.clearFavoriteResult();
          this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage);
          if (this.init.gaTrackingFlag) {
            this.gaTrackingFavorite('unsave',currentItem,'remove_from_favourites');
          }
        }
      })
    }
  }

  checkoutDetail(){
    this.showCheckoutDetail = !this.showCheckoutDetail;
  }

	 gaTrackingAddCart(currentItem:any){
		var itemsArray = [];
		var frontUrlPath = currentItem.skuProperties[0].frontUrlPath;
		var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
		var itemObject = {
				product_action: 'add',
				product_id: currentItem.itemCode,//款号
				product_article_id: currentItem.skuProperties[0].frontCode,//CC
				product_variant_id: currentItem.skuCode,//SKU
				product_name: currentItem.itemName,
				product_brand: 'COS',
				product_category:product_category,
				product_color:currentItem.skuProperties[0].valueName,
				product_price:currentItem.price,
				product_price_original:currentItem.markingPrice,
				product_price_type: currentItem.price < currentItem.markingPrice ? 'sale' : 'full price',
				product_quantity: 1,
				product_size: currentItem.skuProperties[1].valueName,
				product_size_code: currentItem.skuProperties[1].valueId,
			}
		itemsArray.push(itemObject);

		this.tracking.gaTrackingAddCart(itemsArray);
	 }

	gaTrackingRemoveCart(currentItem:any,num:number){
		var qty = num;
		if(!num){
			qty = currentItem.qty;
		}
		var itemsArray = [];
		var frontUrlPath = currentItem.skuProperties[0].frontUrlPath;
		var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
		var itemObject = {
				product_action: 'remove',
				product_id: currentItem.itemCode,//款号
				product_article_id: currentItem.skuProperties[0].frontCode,//CC
				product_variant_id: currentItem.skuCode,//SKU
				product_name: currentItem.itemName,
				product_brand: 'COS',
				product_category:product_category,
				product_color:currentItem.skuProperties[0].valueName,
				product_price:currentItem.price,
				product_price_original:currentItem.markingPrice,
				product_price_type: currentItem.price < currentItem.markingPrice ? 'sale' : 'full price',
				product_quantity: qty,
				product_size: currentItem.skuProperties[1].valueName,
				product_size_code: currentItem.skuProperties[1].valueId,
			}
		itemsArray.push(itemObject);
		this.tracking.gaTrackingRemoveCart(itemsArray);
	}

  gaTrackingFavorite(action = "",currentItem: any, eventType: string,recommend:any = false){
    var itemsArray:any = [];
	var itemObject:any;
	if(recommend){
		var item:any = currentItem.item;
		var frontUrlPath = item.frontUrlPath;
		var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
		var productSizeArray:any = item.sku_list;
		var colorId : any = item.valueId;
		var defaultSizeValue:any = productSizeArray[colorId][0].valueName;
		var defaultSkuCode:any = productSizeArray[colorId][0].skuCode;
		var defaultSkuCode:any = productSizeArray[colorId][0].skuCode;
		itemObject = {
		  product_action: action,
		  product_id: item.itemCode,//款号
		  product_article_id: currentItem.itemCode,//CC
		  product_variant_id: defaultSkuCode,//SKU
		  product_name: item.itemName,
		  product_brand: 'COS',
		  product_category:product_category,
		  product_color:item.valueName,
		  product_price:item.cloudSalePrice,
		  product_price_original:item.cloudPrice,
		  product_price_type: item.cloudSalePrice < item.cloudPrice ? 'sale' : 'full price',
		  product_quantity: 1,
		  product_size: defaultSizeValue,
		  product_size_code: defaultSkuCode,
		}
		 itemsArray.push(itemObject);
	}else{
		var frontUrlPath = currentItem.skuProperties[0].frontUrlPath;
		var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
		itemObject = {
		  product_action: action,
		  product_id: currentItem.itemCode,//款号
		  product_article_id: currentItem.skuProperties[0].frontCode,//CC
		  product_variant_id: currentItem.skuCode,//SKU
		  product_name: currentItem.itemName,
		  product_brand: 'COS',
		  product_category:product_category,
		  product_color:currentItem.skuProperties[0].valueName,
		  product_price:currentItem.price,
		  product_price_original:currentItem.markingPrice,
		  product_price_type: currentItem.price < currentItem.markingPrice ? 'sale' : 'full price',
		  product_quantity: 1,
		  product_size: currentItem.skuProperties[1].valueName,
		  product_size_code: currentItem.skuProperties[1].valueId,
		}
		 itemsArray.push(itemObject);
	}
    this.tracking.gaTrackingManageFavourites(itemsArray,eventType);
  }

  closeOverlay() {
    this.promotion = false;
    this.remindSuccess = false;
    this.remindError = false;
    this.canEmptyInvaild = false;
  }
  /*删除购物纸袋 限购50件 */
  goTocheckout() {
    if (this.cart.cartModel.total_qty_ordered > 50) {
      window.alert('最多加购50件商品！');
    }else if(this.cart.cartModel.total_qty_ordered > 0) {
      this.router.navigate(['/checkout']);
    }
  }

  /*修改购物车商品数量*/
  updateItemsQty(item_key: any, qty: number) {
    let add_qty = 0;
    if (qty >= 0) {
      qty++;
      add_qty = 1;
    }

    const currentItem:any = this.cart.cartModel.getItemDataBySku(item_key);
    this.cart.updateItemsQtyServer(item_key, add_qty).then(_value => {
      this.cartServeData();
       if (qty === 3) {
			this.addColour = true;
       }
		// 向google推送新增购物车商品操作
		if (this.init.gaTrackingFlag && currentItem) {
			// 新版Ga Tracking Js
			this.gaTrackingAddCart(currentItem);

			// 老版Ga Tracking Js
			this.angulartics2.eventTrack.next({
			  action: 'add_to_cart',
			  properties: {
				gstCustom: {
				  items: [{
					id: currentItem['skuCode'],
					name: currentItem['itemName'],
					brand: 'COS',
					price: currentItem['markingPrice'],
					quantity: 1,
					currency: 'CNY'
				  }],
				  currency: 'CNY'
				}
			  }
			});
		}
    });
    if (this.cart.cartModel.total_qty_ordered >= 50) {
      if (isPlatformBrowser(this.platformId)) {
        window.alert('最多加购50件商品！');
      }
    }
  }
  // 减少购物车商品
  delUpdateItemsQty(item_key: any, qty: number) {
    let del_qty = 0;
    if (qty >= 2) {
      qty--;
      del_qty = -1;
    }

    const currentItem:any = this.cart.cartModel.getItemDataBySku(item_key);
    this.cart.updateItemsQtyServer(item_key, del_qty).then(_value => {
      this.cartServeData();
      if (qty === 1) {
        this.signColor = true;
      }
      // 向google推送删除购物车商品操作
      if (this.init.gaTrackingFlag && currentItem) {
		  // 新版Ga Tracking Js
		  this.gaTrackingRemoveCart(currentItem,1);

		  // 老版Ga Tracking Js
		  this.angulartics2.eventTrack.next({
			action: 'remove_from_cart',
			properties: {
			  gstCustom: {
				items: [{
				  id: currentItem['skuCode'],
				  name: currentItem['itemName'],
				  brand: 'COS',
				  price: currentItem['markingPrice'],
				  quantity: 1,
				  currency: 'CNY'
				}],
				currency: 'CNY'
			  }
			}
		  });

      }
    });
  }
  /*请求serve获取购物车数据*/
  cartServeData() {
    this.cart.getCartData().then(model => {
      this.orderResult = model;
    });
  }
  /*重新load购物车数据*/
  loadCartData() {
    this.cart.getCartInfo().then(model => {
      this.orderResult = model;
    });
    return this.orderResult;
  }
  ngOnDestroy() {
    this.cart.cartLoading = true;
    this.timer = null;
  }

  checkedItem(skuId: any, isChecked: number) {
    this.cart.checkedItem(isChecked == 1 ? 0 : 1, skuId).then((res:any) => {
      if (res['code'] === 1000) {
        this.cartServeData();
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
		return;
      } else {
        this.paragraph = res['msg'];
        this.promotion = true;
        return false;
      }
    });
  }
  checkedAllItem() {
    const isChecked = this.cart.cartModel.total_has_stock_qty == this.cart.cartModel.total_has_stock_checked_qty ? 0 : 1
    this.cart.checkedAllItem(isChecked).then((res:any) => {
      if (res['code'] === 1000) {
        this.cartServeData();
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
		return;
      } else {
        this.paragraph = res['msg'];
        this.promotion = true;
        return false;
      }
    });
  }

  setArrivalRemind(itemObject : any){
    const params = {
      frontCode:itemObject.skuProperties[0].frontCode,
      frontUrl:'/pages/goods_detail?id='+itemObject.itemId+'&valueId='+itemObject.valueId+'&valueName='+itemObject.skuProperties[0].valueName,
      itemId:itemObject.itemId,
      itemCode:itemObject.itemCode,
      itemName:itemObject.itemName,
      mobile:this.localStorage.getItem('mobile'),
      storeId:this.init.officialWebsiteStoreId,
      valueId:itemObject.valueId,
      valueName:itemObject.skuProperties[0].valueName,
      nickName:this.localStorage.getItem('wxNickName'),
      unionId:this.localStorage.getItem('wxUnionId'),
      skuCode:itemObject.skuCode
    };
    this.productService.customerArrivalRemind(JSON.stringify(params)).then( val => {
      this.remindResult = val;
      if(this.remindResult.code === 200){
        this.remindSuccess = true;
      }else if(this.remindResult.code === -1002){
        if(this.remindResult.msg === 'sku already add'){
          this.remindSuccess = true;
        }else{
          this.remindError = true;
          this.remindErrorMsg = '提交失败，请重试';
        }
      }else{
        this.remindError = true;
        this.remindErrorMsg = '提交失败去，请重试';
      }
    }).catch(error=>error);
  }

  quickAdd(event:any){
    this.showBox = true;

    this.frontCode = event.frontCode;
	this.salePrice = event.salePrice;
  }
  closeLayer(){
    this.showBox = false;
  }
}
