<!--<div class="myAccount_right">
  <div class="topSection clearfix">
    <h5 class="pull-left">申请发票</h5>
  </div>
  <div class="rmaInfo">
    <ul>
      <li>订单编号: <a [routerLink]="['/my-account/orderdetail']" [queryParams]="{orderid: orderId}" class="a-link">{{cloudOrderResult?.tid ? cloudOrderResult?.tid : cloudOrderResult?.orderCode}}</a></li>
      <li>开票金额: {{init.currency + invoiceAmount}}</li>
      <li>发票类型: 增值税电子普通发票</li>
    </ul>
  </div>
  <div class="checkbox-input">
    <div class="m-checkbox u-clearfix">
      <input name="police" type="checkbox" required class="a-checkbox" id="person_invoice">
      <label class="a-label js-a-label custom-box" for="person_invoice"></label>
      <label class="a-label js-a-label" for="person_invoice">
        <span class="newsLetterText">个人/事业单位</span>
      </label>
    </div>
  </div>
  <div class="checkbox-input">
    <div class="m-checkbox u-clearfix">
      <input name="police" type="checkbox" required class="a-checkbox" id="connected_newsletter">
      <label class="a-label js-a-label custom-box" for="connected_newsletter"></label>
      <label class="a-label js-a-label" for="connected_newsletter">
        <span class="newsLetterText">企业</span>
      </label>
    </div>
  </div>
</div>-->

<div class="my-account-content myAccount_right u-clearfix ng-scope">
  <div class="topSection clearfix">
    <h5 class="pull-left">申请发票</h5>
  </div>
  <div id="profileSummary" class="o-my-details summary">
    <div class="userInfo">
      <div class="data-sections">
        <div class="data-section phone">
          <div class="phone-label">订单编号: <a [routerLink]="['/my-account/orderdetail']" [queryParams]="{orderid: orderId}" class="a-link">{{cloudOrderResult?.tid ? cloudOrderResult?.tid : cloudOrderResult?.orderCode}}</a></div>
        </div>
      </div>
      <div class="data-sections">
        <div class="data-section phone">
          <div class="phone-label">开票金额: {{init.currency + invoiceAmount}}</div>
        </div>
      </div>
      <div class="data-sections">
        <div class="data-section phone">
          <div class="phone-label">发票类型: 增值税电子普通发票</div>
        </div>
      </div>
    </div>
  </div>
  <form data-component="OMyDetails" class="o-form o-my-details" id="myProfileForm">
    <div class="userEdit">
      <div class="input-wrapper">
        <div class="checkbox-input">
          <div class="m-checkbox u-clearfix">
            <input name="personInvoice" type="checkbox" class="a-checkbox" id="person_invoice" [(ngModel)]="personInvoice" (click)="checkInvoiveType()">
            <label class="a-label js-a-label custom-box" for="person_invoice"></label>
            <label class="a-label js-a-label" for="person_invoice">
              <span class="newsLetterText">个人/事业单位</span>
            </label>
          </div>

          <div class="m-checkbox u-clearfix">
            <input name="businessInvoice" type="checkbox" class="a-checkbox" id="businessInvoice" [(ngModel)]="businessInvoice"  (click)="checkInvoiveType()">
            <label class="a-label js-a-label custom-box" for="businessInvoice"></label>
            <label class="a-label js-a-label" for="businessInvoice">
              <span class="newsLetterText">企业</span>
            </label>
          </div>
        </div>

        <div data-component="MInput" class="m-input no-description staff-card" [ngClass]="{'is-active' : valite.payerName.is_active,'is-filled has-error':valite.payerName.errorTips}">
          <label class="a-label js-a-label">发票抬头</label>
          <input class="a-input js-a-input" type="text"  name="hmStaffCardId" [(ngModel)]="invoiceVals.payerName" (keyup)="staffPayerNameKeyup()" (blur)="stafPayerName()" placeholder="发票将发送至此邮箱">
          <label *ngIf="valite.payerName.is_active && valite.payerName.errorTips" class="a-label js-a-label" data-placeholder="发票抬头">{{payerNameText}}</label>
        </div>
        <div *ngIf="businessInvoice" data-component="MInput" class="m-input no-description staff-card" [ngClass]="{'is-active' : valite.payerRegisterNo.is_active,'is-filled has-error':valite.payerRegisterNo.errorTips}">
          <label class="a-label js-a-label">税号</label>
          <input class="a-input js-a-input" type="text"  name="hmStaffCardId" [(ngModel)]="invoiceVals.payerRegisterNo" (keyup)="staffKeyup()" (blur)="stafPayerRegisterNo()">
          <label *ngIf="valite.payerRegisterNo.is_active && valite.payerRegisterNo.errorTips" class="a-label js-a-label" data-placeholder="税号">{{payerRegisterNoText}}</label>
        </div>
        <div data-component="MInput" class="m-input email-field is-filled" data-validate="email" [ngClass]="{'is-active' : valite.email.is_active,'is-filled has-error':valite.email.errorTips }">
          <label class="a-label js-a-label">邮箱地址</label>
          <input class="a-input js-a-input" type="email" name="email" [(ngModel)]="invoiceVals.email"  (blur)="emailVerify()" placeholder="开票成功后，发票将发送至此邮箱">
          <label *ngIf="valite.email.errorTips && valite.email.is_active" class="a-label js-a-label">{{emailMessage}}</label>
        </div>
      </div>
      <div class="button-container">
        <button type="submit" [disabled]="saveLock" (click)="onSubmit()" class="a-button is-primary btn-save-details" id="saveProfileDetails" data-default-state-text="保存" >
          <span id="saveButtonLabel">提交申请</span>
        </button>
      </div>
    </div>
  </form>
</div>


<!-- 显示加入购物车报错：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_modal}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_modal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 显示加入购物车报错 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2" *ngIf="show_error_message">抱歉！</h2>
      <p class="a-paragraph">{{message}}</p>
    </div>
    <!-- 显示加入购物车报错 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
