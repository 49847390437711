<div data-component="OPageContent" class="o-page-content cofs-page">
  <div class="o-forgot-password">
    <div class="forgot-password-form font_s_regular">
      <form data-component="OForm" (submit)="findPassword()" class="o-form">
        <h2 class="mobTitle font_s_regular font_small_l_bold">{{setPasswordText}}</h2>
        <div class="o-registration-form">
          <div class="m-error js-m-error is-hidden">
            <label class="a-label js-a-label has-error"></label>
          </div>
          <div class="e-mail-input">
           <!-- [ngClass]="{'is-filled has-error':mobileValid==true,'is-active':mobileActive==true }"-->
            <div data-component="MInput" class="m-input entry-item" >
              <ng-container *ngIf="mobileValid; else otherBlock;">
                <label class="a-label js-a-label mb-5 tips">{{usernameError}}</label>
              </ng-container>
              <ng-template #otherBlock>
                <label class="a-label js-a-label mb-5" *ngIf="mobileVal && mobileVal.length > 0">手机号</label>
              </ng-template>
              <input autocomplete="off" maxlength="11" class="p-0 border-none" type="tel" required placeholder="手机号" title="手机号" name="mobile" [(ngModel)]='mobileVal' (blur)="blurMobileCheck()"  (focus)="mobileActiveCheck()">
              <!--<label *ngIf="mobileValid" class="a-label js-a-label" data-placeholder="" >{{usernameError}}</label>-->
            </div>
          </div>
          <div class="e-mail-input pos_rel">
           <!-- [ngClass]="{'is-filled has-error':phoneCaptchaValid===true,'is-active':phoneCaptchaActive==true }"-->
            <div data-component="MInput" class="m-input entry-item bt-none flex flex-row" >
              <div style="width: 100%;display: flex;" [ngClass]="(phoneCaptchaValue && phoneCaptchaValue.length > 0) || phoneCaptchaValid ? 'flex-column' : '' ">
                <ng-container *ngIf="phoneCaptchaValid; else phoneCaptchaBlock;">
                  <label class="a-label js-a-label mb-5 tips">{{phoneCaptchaError}}</label>
                </ng-container>
                <ng-template #phoneCaptchaBlock>
                  <label class="a-label js-a-label mb-5" *ngIf="phoneCaptchaValue && phoneCaptchaValue.length > 0">验证码</label>
                </ng-template>
                <input autocomplete="new-captcha" name="phoneCaptcha" class="p-0 border-none" placeholder="验证码" type="text" [(ngModel)]='phoneCaptchaValue' required (blur)="blurPhoneCaptchaCheck()"  (focus)="phoneCaptchaActiveCheck()" title="验证码">
              </div>
              <div style="width: 40%;display: flex;align-items: center;">
                <button type="button" [disabled]="phoneCaptchaDisabled" (click)="openImageValid()" class="btn_code">{{sendPhoneLabel}}</button>
              </div>
             <!-- <label *ngIf="phoneCaptchaValid === true" class="a-label js-a-label" data-placeholder="">{{phoneCaptchaError}}</label>-->
            </div>
            <div class="codeBox" [ngClass]="{'is-hidden':hiddenImageValidBox}">
              <h6>验证图形码</h6>
              <div class="iptText">
                <input autocomplete="off" type="text" name ='imageValidValue' [(ngModel)]='imageValidValue'>
                <img [id]="imageValidSrc" [src]="imageValidSrc" alt="图形验证码">
                <p><a href="javascript:void(0)" (click)="createImage()">换一张</a></p>
              </div>
              <label *ngIf="imageValidError === true" class="a-label js-a-label codeError">{{imageValidErrorLabel}}</label>
              <button type="button" (click)="imageValueCheck()" class="a-button is-primary">确定</button>
              <button type="button" (click)="closeCodeBox()" class="a-button is-primary">取消</button>
            </div>
          </div>
          <div data-component="MInput" class="m-input password entry-item bt-none" [ngClass]="{'is-filled has-error':passwordValid==true,'is-active':passwordActive==true }" id="password">
         <!--   <label class="a-label js-a-label">密码</label>-->
            <ng-container *ngIf="passwordValid; else paBlock;">
              <label class="a-label js-a-label mb-5 tips">{{passwordError}}</label>
            </ng-container>
            <ng-template #paBlock>
              <label class="a-label js-a-label mb-5" *ngIf="passwordVal && passwordVal.length > 0">密码</label>
            </ng-template>
            <input autocomplete="new-password" placeholder="密码" class="p-0 border-none" type="text" onfocus="this.type='password'" required name="r_username" [(ngModel)]='passwordVal' (blur)="blurPasswordCheck()"  (focus)="passwordActiveCheck()" title="密码">
           <!-- <label *ngIf="passwordValid" class="a-label js-a-label" data-placeholder="">{{passwordError}}</label>-->
          </div>
          <div data-component="MInput" class="m-input password-repeat entry-item bt-none" [ngClass]="{'is-filled has-error':rePasswordValid==true,'is-active':rePasswordActive==true }">
            <ng-container *ngIf="rePasswordValid; else rpaBlock;">
              <label class="a-label js-a-label mb-5 tips">{{rePasswordError}}</label>
            </ng-container>
            <ng-template #rpaBlock>
              <label class="a-label js-a-label mb-5" *ngIf="rePasswordVal && rePasswordVal.length > 0">确认密码</label>
            </ng-template>
            <input autocomplete="new-password" placeholder="确认密码" class="p-0 border-none" name="rePassword" type="text" onfocus="this.type='password'" required  [(ngModel)]='rePasswordVal' (blur)="reBlurPasswordCheck()"  (focus)="rePasswordActiveCheck()" title="确认密码">
           <!-- <label *ngIf="rePasswordValid" class="a-label js-a-label" data-placeholder="">{{rePasswordError}}</label>-->
          </div>
          <button style="height: 50px;" type="submit" class="a-button is-primary" [ngClass]="checkInfoConfirm() ? '' :'confirm-button'"><span>提交</span></button>
        </div>
      </form>
    </div>
    <div class="forgot-password-image">
      <img class="a-image" src="">
    </div>
  </div>
</div>
