import {Component, OnInit, PLATFORM_ID, Inject} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import $ from 'jquery';
import {isPlatformBrowser} from '@angular/common';
import {CustomerService} from '../../services/customer.service';
@Component({
  selector: 'app-account-coupon',
  templateUrl: './account-coupon.component.html',
  styleUrls: ['./account-coupon.component.css']
})
export class AccountCouponComponent implements OnInit{
  public systermCouponArray = [];
  public customerCouponInfo: any = {code:0,data:{content:[],totalElements:0},msg:''};
  public isReceive:boolean = false;
  public hiddenDescription:boolean = true;
  public currentDate!: number;
  public loading:boolean = true;
  constructor(
    private init: InitializeService,
    private customerService: CustomerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.init.pageConfiguration('我的优惠券');
    if (isPlatformBrowser(this.platformId)) {
      this.customerCoupon();
      this.getCurrentDate();

    }
  }

  convertTime(date: any){
    date = new Date(date);
    return date.valueOf();
  }

  getCurrentDate(){
    //const formatDate = ( time: any ) => {
    //  // 格式化日期，获取今天的日期
    //  const Dates = new Date( time );
    //  const year: number = Dates.getFullYear();
    //  const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
    //  const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
    //  const hour: any = Dates.getHours();       //获取当前小时数(0-23)
    //  const minutes: any = Dates.getMinutes();     //获取当前分钟数(0-59)
    //  const seconds: any = Dates.getSeconds();
    //  return year + '-' + month + '-' + day + ' '+ hour + ':'+ minutes + ':' + seconds;
    //};
    this.currentDate =  new Date().getTime(); // 当前时间
  }

  customerCoupon(){
    this.customerService.getCrmCoupon().then(val =>{
      this.customerCouponInfo = val;
      this.loading = false;
      if(this.customerCouponInfo.code === 1000){
        if(this.customerCouponInfo.data.totalElements > 0){
          this.systermCouponArray = this.customerCouponInfo.data.content;
          this.isReceive = true;
        }
      }
    }).catch(error => error);
  }

  viewDescription(code:any) {
    if ($('#' + code + '_description').css('display') === 'block') {
      $('#' + code + '_description').css('display', 'none');
    }else{
      $('#' + code + '_description').css('display', 'block');
    }
  }

  ngOnDestroy() {
    this.loading = true;
  }
}
