import {Component, OnInit, Inject, PLATFORM_ID, ChangeDetectorRef} from '@angular/core';
import {InitializeService} from '../../../services/initialize.service';
import {AddressService} from '../../../services/address.service';
import {isPlatformBrowser} from '@angular/common';
import $ from 'jquery';
import {Router} from '@angular/router';
import {any} from 'codelyzer/util/function';
import {StorageService} from '../../../services/storage.service';


@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.css']
})
export class DeliveryAddressComponent implements OnInit {
  public addressLists = [];
  public address_id!: string;
  public formDatas: any = {
    firstname: '',
    address_mobile: '',
    province_name: '',
    city_name: '',
    area_name: '',
    province_id: 0,
    city_id: 0,
    area_id: 0,
    street: '',
    postcode: '',
  };
  public provinces:any = [];
  public cities:any = [];
  public areas:any = [];
  public nameerror:any = true; // name验证
  public mobilerror:any = true; // 手机error
  public addresserror:any = true; // 地址 error
  public phoneText:any = '';
  public isValidaRegion = false;
  public isValidaCity = false;
  public isValidaArea = false;
  public newAddress = false; // 新增地址
  public deleteopen:any = false;
  public delIndex = -1;
  public editAdd = false;
  public editIndex = -1;
  public editItem:any = {};
  public isSaveing = false;
  constructor(public init: InitializeService, private cdr: ChangeDetectorRef, public address: AddressService, private router: Router,private localStorage: StorageService, @Inject(PLATFORM_ID) private platformId: Object) {
  }
  ngOnInit() {
    this.init.pageConfiguration('管理收货地址');
    if (isPlatformBrowser(this.platformId)) {
      this.getProvinces();
      this.getAddressLists();
    }
  }

  getAddressLists(){
    this.address.getCloudCustomerAdd().then((res:any)=>{
      if(res['code'] == 1000){
        this.addressLists = res.data.content;
      }
    });
  }

  // 获取省份
  getProvinces() {
    this.address.getCloudProvince().then( val => {
      this.provinces = val;
      if (this.provinces.length === 1) {
        this.formDatas.province_id = this.provinces[0]['areaCode'];
        this.getCities(this.provinces[0]['areaCode']);
      }
    }).catch(error => error);
  }

  // 根据名称获取地区code
  getAreaCode(type: string) {
    let areaData: any = [];
    let areaCode = '';
    let areaName = '';
    switch (type) {
      case 'province':
        areaData = this.provinces;
        areaName = this.formDatas.province_name;
        break;
      case 'city':
        areaData = this.cities;
        areaName = this.formDatas.city_name;
        break;
      case 'area':
        areaData = this.areas;
        areaName = this.formDatas.area_name;
        break;
      default :
    }
    areaData.forEach( function(item:any) {
      if (item.areaName.toString() == areaName.toString()) {
        areaCode = item.areaCode;
      }
    });
    switch (type) {
      case 'province':
        this.formDatas.province_id = areaCode;
        this.formDatas.province_name = areaName;
        break;
      case 'city':
        this.formDatas.city_id = areaCode;
        this.formDatas.city_name = areaName;
        break;
      case 'area':
        this.formDatas.area_id = areaCode;
        this.formDatas.area_name = areaName;
        break;
      default :
    }
  }
  // 获取城市
  public getCities(province_id:any, edit = false) {
    if (isPlatformBrowser(this.platformId)) {
      $('.addcity option:selected').val(0).text('请选择');
      $('.addarea option:selected').val(0).text('请选择');
    }
    this.address.getCloudCitys(province_id).then(data => {
      this.cities = data;
      if (edit) {
        this.getAreaCode('city');
        this.getAreas(this.formDatas.city_id, edit);
      } else {
        if (this.cities.length === 1) {
          this.formDatas.city_id = this.cities[0]['areaCode'];
          this.getAreas(this.cities[0]['areaCode']);
        }
      }
    }).catch(error => error);
  }
  // 获取地区
  public getAreas(city_id:any, edit = false) {
    if (this.formDatas.city_id && this.formDatas.area_id) {
      if (isPlatformBrowser(this.platformId)) {
        $('.addcity option:selected').removeAttr('selected');
        $('.addarea option:selected').removeAttr('selected');
      }
    }
    this.address.getCloudArea(city_id).then(data => {
      this.areas = data;
      if (edit) {
        this.getAreaCode('area');
      } else {
        if (this.areas.length === 1) {
          this.formDatas.area_id = this.areas[0]['areaCode'];
        }
      }
    }).catch(error => error);
  }
  /*删除弹框*/
  Deleteopen(address_id: string, index: any) {
    this.deleteopen = true;
    this.address_id = address_id;
    this.delIndex = index;
  }
  /*确定删除*/
  clickDelete(address_id: string) {
    let codeResult: any = {code: '', data: {}, message: ''};
    if (this.deleteopen = true) {
      this.address.deleteCloudAddress(address_id).then((data:any) => {
        codeResult = data;
        if (codeResult.code === 1000) {
          this.addressLists.splice(this.delIndex, 1);
        } else {
          if (isPlatformBrowser(this.platformId)) {
            alert(codeResult['msg']);
          }
          return false;
        }
        return;
      });
    }
    this.deleteopen = false;
  }
  // 取消删除
  cancelDelete() {
    this.deleteopen = false;
    this.delIndex = -1;
  }
  /*firstname 输入验证*/
  validateForm() {
    if (this.formDatas.firstname === '' || this.formDatas.firstname === undefined) {
      this.nameerror = false;
      return false;
    } else {
      this.nameerror = true;
    }
    return true;
  }
  /*mobile输入验证*/
  validateMobile() {
    const myReg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
    if (this.formDatas.address_mobile === undefined || this.formDatas.address_mobile === ''){
      this.phoneText = '请输入手机号码';
      this.mobilerror = false;
      return false;
    } else {
      if (!myReg.test(this.formDatas.address_mobile)) {
        this.phoneText = '请输入有效的手机号码';
        this.mobilerror = false;
        return false;
      } else {
        this.mobilerror = true;
      }
    }
    return true;
  }
  /*省市区校验*/
   provinceCityDistrict() {
     let res = true;
     if(this.formDatas.province_id === undefined || this.formDatas.province_id === '0' || this.formDatas.province_id === 0){
       this.isValidaRegion = true;
       res = false;
     } else {
       this.isValidaRegion = false;
     }
     if(this.formDatas.city_id === undefined || this.formDatas.city_id === '0' || this.formDatas.city_id === 0){
       this.isValidaCity = true;
       res = false;
     } else {
       this.isValidaCity = false;
     }
     if(this.formDatas.area_id === undefined || this.formDatas.area_id === '0' || this.formDatas.area_id === 0){
       this.isValidaArea = true;
       res = false;
     } else {
       this.isValidaArea = false;
     }
     return res;
   }
  /*地址校验*/
  checkStreetValue() {
    if (this.formDatas.street === undefined || this.formDatas.street === '' ) {
      this.addresserror = false;
      return false;
    } else {
      if(this.address.verifyReceivingAddr(this.formDatas.street)){
        this.addresserror = true;
      }else{
        this.addresserror = false;
        return false;
      }
    }
    return true;
  }
  // 取消提交
  cancelSubmit() {
    this.newAddress = this.editAdd = false;
    this.editIndex = -1;
  }
  /*展开编辑地址*/
  editAddress(index:any, value:any) {
    this.editAdd = true;
    this.editItem = value;
    let ProvincesCode = '';
    this.provinces.forEach((provinceItem:any, _index:any) => {
      if (provinceItem.areaName === value.receiverState){
        ProvincesCode = provinceItem.areaCode;
      }
    });

    this.formDatas.firstname = value.receiverName;
    this.formDatas.address_mobile = value.receiverMobile;

    this.formDatas.province_id = ProvincesCode;
    this.formDatas.city_name = value.receiverCity;
    this.formDatas.area_name = value.receiverDistrict;
    this.getCities(this.formDatas.province_id,true);

    this.formDatas.street = value.receiverAddress;
    this.editIndex = index;
    this.newAddress = false;
  }
  /**
   * 设置默认地址
   * @param val
   * @param addressId
     */
  defaultAddress(val:any, addressId:any) {
    const adressjson = {
      receiverName: val.receiverName,
      receiverMobile: val.receiverMobile,
      receiverAddress: val.receiverAddress,
      isDefault: 1,
      receiverState: val.receiverState,
      receiverCity: val.receiverCity,
      receiverDistrict: val.receiverDistrict,
      areaCode: val.areaCode,
      receiverTown: '',
      zipCode:''
    };
    let itemResult: any = {code: 0, data: {}, msg: ''};
    this.address.editCloudAddress(adressjson,addressId).then( (val:any) =>  {
      itemResult = val;
      if (itemResult.code === 1000 ) {
        this.getAddressLists();
        this.cancelSubmit();
      } else {
		    alert(itemResult.msg);
        return false;
      }
      return;
    });
  }

  /**点击添加新地址**/
  addNewAddressBt() {
    this.formDatas.firstname = '';
    this.formDatas.address_mobile = '';
    this.formDatas.province_id = 0;
    this.formDatas.city_id = 0;
    this.formDatas.area_id = 0;
    this.formDatas.street = '';
    this.formDatas.postcode = '';
    this.editIndex = -1;
    this.newAddress = true;
    this.editAdd = false;
  }

  confirmSubmit() {
    if(this.isSaveing){
      return;
    }
    this.validateForm();
    this.validateMobile();
    this.provinceCityDistrict();
    this.checkStreetValue();
    if (this.nameerror && this.mobilerror && !this.isValidaRegion && !this.isValidaCity && !this.isValidaArea && this.addresserror){
      this.isSaveing = true;
      this.formDatas.province_name = this.address.provinceList[this.formDatas.province_id]['areaName'];
      this.formDatas.city_name = this.address.cityList[this.formDatas.city_id]['areaName'];
      this.formDatas.area_name = this.address.areaList[this.formDatas.area_id]['areaName'];
      let adressjson = {
        receiverName: this.formDatas.firstname,
        receiverMobile: this.formDatas.address_mobile,
        receiverAddress: this.formDatas.street,
        receiverState: this.formDatas.province_name,
        receiverCity: this.formDatas.city_name,
        receiverDistrict: this.formDatas.area_name,
        areaCode: this.formDatas.area_id,
        receiverTown: '',
      };
      if(this.newAddress){ // 新增
        adressjson['isDefault'] = 0;
      } else {
        adressjson['isDefault'] = this.editItem.isDefault;
        adressjson['customerId'] = this.localStorage.getItem('cloud_customer_id');
        adressjson['zipCode'] = '';
      }
      let result = null
      if(this.newAddress){
        this.address.commitCloudAddress(adressjson).then((result:any)=>{
          this.isSaveing = false;
          if (result.code == 1000) {
            this.editIndex = -1;
            this.editAdd = false;
            this.cancelSubmit();
            this.getAddressLists();
            this.cdr.detectChanges();
          } else {
            if (isPlatformBrowser(this.platformId)) {
              alert(result.msg);
            }
          }
        })
      } else {
        this.address.editCloudAddress(adressjson,this.editItem.customerAddressId).then((result:any)=>{
          this.isSaveing = false;
          if (result.code == 1000) {
            this.editIndex = -1;
            this.editAdd = false;
            this.cancelSubmit();
            this.getAddressLists();
            this.cdr.detectChanges();
          } else {
            if (isPlatformBrowser(this.platformId)) {
              alert(result.msg);
            }
          }
        })
      }
    }
  }

  OnDestroy(){
  }
}
