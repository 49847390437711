import {Component, OnInit, EventEmitter,  ChangeDetectorRef, Output,Input, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {AddressService} from '../../../services/address.service';
import {CheckoutService} from '../../../services/checkout.service';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../../services/initialize.service';
import $ from 'jquery';
import {CheckoutModel} from '../../../models/checkout.model';
import {Router} from '@angular/router';
import {StorageService} from '../../../services/storage.service';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.css']
})
export class CheckoutAddressComponent implements OnInit, OnDestroy{
  // 向外发射数据；
  public checkoutLoad: boolean = true;
  public checkoutModel!: CheckoutModel;
  public hasAddress:any = false;
  public newAddress = false; // 新增地址
  public editable: Boolean = false; //编辑地址
  public otherAddressShow = false; //显示其他地址
  public userblock:any  = 'none';
  public showBlock:any = 'none';
  public phoneBlock:any = 'none';
  public pcaBlock:any = 'none';
  public addressBlock:any = 'none';
  public phoneValue:any = '';
  public setTime:any = null;
  public provinces:any = [];
  public cities:any = [];
  public areas:any = [];
  public otherAddress:any;
  public defaultAddress:any;
  public defaultAddressId:any;
  public usernameerrorText:any;

  public isValidaRegion = false;
  public isValidaCity = false;
  public isValidaArea = false;

  public formData: any = {
    usernameVal: '',
    mobileValue: '',
    province_id: 0,
    city_id: 0,
    area_id: 0,
    streetValue: '',
    EmailValue: '',
    postalCode: '',
    province_name: '',
    city_name: '',
    area_name: '',
  };
  hideBug: boolean = true; // 新增地址的判断，判断loading加载完显示新增地址单选框
  // choseShow:any = false;
  public showSetAddress: any = {};
  public showAddress:any;
  public setAddResult: any = {code: '', message: '', data: {}};
  public shownew:any = 'none';
  public otherResult!: String[];
  @Output() grayBtn = new EventEmitter<boolean>();
  public checkoutAdd: any = [];
  public checkoutAddressLists: any = {};
  @Input() checkoutType: any;

  // 购物车中设置的地址
  public cartAddress: any = {};


  constructor(public address: AddressService,
              public checkout: CheckoutService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public init: InitializeService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private localStorage: StorageService) {

  }
  ngOnInit() {
    this.getProvinces();
    this.address.getCloudCustomerAdd().then((data:any) => {
      if (data['code'] === 1000) {
        if (data['data']['content'].length > 0) {
          data['data']['content'].forEach((item:any) => {
            if (item['isDeleted'] === 0) {
              this.checkoutAdd.push(item);
              this.checkoutAddressLists[item['customerAddressId']] = item;
              // 判断有没有‘defaultAddress’
              if (item['isDefault'] === 1 && item['isDeleted'] === 0) {
                this.defaultAddressId = item['customerAddressId'];
              }
            }
          });
          // 获取默认地址
          if (this.defaultAddressId) {
            this.defaultAddress = this.checkoutAddressLists[this.defaultAddressId];
          } else {
            if (this.checkoutAdd.length > 0) {
              this.defaultAddressId = this.checkoutAdd[0]['customerAddressId'];
              this.defaultAddress = this.checkoutAddressLists[this.defaultAddressId];
            }
          }
        }
      }
      this.CartInfo();
    });
    /*初始化判断状态*/
    this.vote(this.checkoutLoad);
  }
  public vote(bool: boolean) {
    this.grayBtn.emit(bool);
  }
  public CartInfo() {
    this.checkoutModel = new CheckoutModel({});
    let checkoutAddressPromise;
    checkoutAddressPromise =  new Promise((resolve) => {
      this.checkout.getCheckoutData(this.checkoutType).then( val => {
        resolve(val);
      }).catch((error:any) => {
        return error;
      });
    });
    checkoutAddressPromise.then((res:any) => {
      this.checkoutModel = res;
      if (!this.checkoutModel.total_qty_ordered || this.checkoutModel.total_qty_ordered <= 0) {
        this.router.navigate(['/cart']);
        return;
      }
      if (this.checkoutAddressLists.hasOwnProperty(this.checkoutModel.customerAddressId)) {
        this.showSetAddress = this.checkoutAddressLists[this.checkoutModel.customerAddressId];
        this.cartAddress = this.checkoutAddressLists[this.checkoutModel.customerAddressId];
      }
      this.otherAddress = [];
      this.otherAddress = this.checkoutAdd;
      // 加载获取购物车地址数据
      if (!this.checkoutModel.customerAddressId || !this.checkoutAddressLists.hasOwnProperty(this.checkoutModel.customerAddressId) || this.checkoutModel.customerAddressId == null) {
        // 在无配送地址，但是有默认地址的情况下set 一次地址
        if (this.checkoutAdd.length > 0 && this.defaultAddress && this.defaultAddress !== null) {
          this.cartAddress = this.checkoutAddressLists[this.defaultAddressId];
          this.setshippingAddress('default', this.defaultAddress.customerAddressId);
        } else {
          // 新用户第一次下单的时候（没有默认地址，没有购物车地址）
          this.hideBug = false; // 为false显示loading加载完显示新增地址单选框
          this.checkoutLoad = false;
          // 自动展开地址
          this.addressNew();
          this.vote(this.checkoutLoad);
        }
      } else {
        this.checkoutLoad = false;
        this.vote(this.checkoutLoad);
      }
      this.editable = false;
      this.checkout.checkoutModel = this.checkoutModel;
    }).catch(error => error);
  }

  // 获取省份
  getProvinces() {
    this.address.getCloudProvince().then( val => {
      this.provinces = val;
      if (this.provinces.length === 1) {
        this.formData.province_id = this.provinces[0]['areaCode'];
        this.getCities(this.provinces[0]['areaCode']);
      }
    }).catch(error => error);
  }
  // 获取城市
  public getCities(province_id:any, edit = false) {
    if (isPlatformBrowser(this.platformId)) {
      $('.addcity option:selected').val(0).text('请选择');
      $('.addarea option:selected').val(0).text('请选择');
    }
    this.address.getCloudCitys(province_id).then(data => {
      this.cities = data;
      if (edit) {
        this.getAreaCode('city');
        this.getAreas(this.formData.city_id);
      } else {
        if (this.cities.length === 1) {
          this.formData.city_id = this.cities[0]['areaCode'];
          this.getAreas(this.cities[0]['areaCode']);
        }
      }
    }).catch(error => error);
  }

  // 获取地区
  public getAreas(city_id:any) {
    if (this.formData.city_id && this.formData.area_id) {
      if (isPlatformBrowser(this.platformId)) {
        $('.addcity option:selected').removeAttr('selected');
        $('.addarea option:selected').removeAttr('selected');
      }
    }
    this.address.getCloudArea(city_id).then(data => {
      this.areas = data;
      if (this.areas.length === 1) {
        this.formData.area_id = this.areas[0]['areaCode'];
      }
    }).catch(error => error);
  }
  /*名字校验*/
  nameValue() {
    if (this.formData.usernameVal === undefined || this.formData.usernameVal === '') {
      this.usernameerrorText = '请输入收货人姓名';
      this.userblock = 'block';
      return false;
    } else {
      this.userblock = 'none';
    }
    return true;
  }
  // 收货人验证
  nameErrorBlur() {
    if (this.formData.usernameVal === undefined || this.formData.usernameVal === '') {
      this.usernameerrorText = '请输入收货人姓名';
      this.userblock = 'block';
      return false;
    } else {
      this.userblock = 'none';
      if (this.formData.usernameVal.length < 2) {
        this.usernameerrorText = '至少包含两个字符!';
        this.userblock = 'block';
        return false;
      } else {
        this.userblock = 'none';
      }
    }
    return true;
  }

  /*手机号校验*/
  MobileValue() {
    const myreg = /^1[3|4|5|6|7|8|9]\d{9}$/;
    if (this.formData.mobileValue === undefined || this.formData.mobileValue === '') {
      this.phoneValue = '请输入手机号';
      this.phoneBlock = 'block';
      return false;
    } else {
      this.phoneBlock = 'none';
      if (!myreg.test(this.formData.mobileValue)) {
        this.phoneValue = '请输入正确的手机号！';
        this.phoneBlock = 'block';
        return false;
      } else {
        this.phoneBlock = 'none';
      }
    }
    return true;
  }
  /*单选选择设置收货地址*/
  setshippingAddress(type:any, id:any) {
    if (type === 'other') {
      if (this.checkoutModel.customerAddressId === id) {
        this.refreshInfo();
        return;
      }
    }
    let setPromise;
    setPromise = new Promise((_resolve, _reject) => {
      this.checkout.setCustomerAddressId(id,this.checkoutType).then(val => {
        this.setAddResult = val;
        if (this.setAddResult.code !== 1000) {
          if (isPlatformBrowser(this.platformId)) {
            alert(this.setAddResult.msg);
          }
        } else {
          this.showSetAddress = this.checkoutAddressLists[id];
          this.cartAddress = this.checkoutAddressLists[id];
          this.checkoutModel.customerAddressId = id;
          this.refreshInfo();
        }
      })
    }
  );
  }

  refreshInfo() {
    this.checkoutLoad = false;
    // @ts-ignore
    this.vote(this.checkoutLoad);
    this.checkout.getCheckoutInfo(this.checkoutType).then(data => {
      this.checkoutModel = data;
    });
    this.otherAddressShow = false;
    this.cdr.markForCheck();
  }

  /*新增地址*/
  addressNew() {
    this.newAddress = true;
    this.editable = false;
    this.formData.usernameVal = undefined;
    this.formData.mobileValue = undefined;
    this.formData.province_id = 0;
    this.formData.city_id = 0;
    this.formData.area_id = 0;
    this.formData.streetValue = undefined;
  }
  /*编辑地址*/
  editaddress() {
    this.otherAddressShow = false;
    this.userblock = 'none';
    this.phoneBlock = 'none';
    this.pcaBlock = 'none';
    this.addressBlock = 'none';
    this.showBlock = 'none';
    this.editable = true;
    this.shownew = 'block';
    this.checkout.getCheckoutData(this.checkoutType).then( (_res:any) => {
      this.formData.usernameVal = this.cartAddress.receiverName;
      this.formData.mobileValue = this.cartAddress.receiverMobile;
      this.formData.province_name = this.cartAddress.receiverState;
      this.formData.city_name = this.cartAddress.receiverCity;
      this.formData.area_name = this.cartAddress.receiverDistrict;
      this.formData.area_id = this.cartAddress.areaCode;
      this.getAreaCode('province');
      this.getCities(this.formData.province_id, true);
      this.formData.streetValue = this.cartAddress.receiverAddress;
    }).catch(error => error);
  }

  /*更改地址*/
  changeAddress() {
    this.otherAddressShow = !this.otherAddressShow;
    this.newAddress = false;
    this.editable = false;
    this.userblock = 'none';
    this.phoneBlock = 'none';
    this.pcaBlock = 'none';
    this.addressBlock = 'none';
    this.showBlock = 'none';
  }
  /*取消编辑地址*/
  cancelEditAddress() {
    this.editable = false;
    this.shownew = 'none';
    this.otherAddressShow = false;
    this.formData.usernameVal = undefined;
    this.formData.mobileValue = undefined;
    this.formData.province_id = undefined;
    this.formData.city_id = undefined;
    this.formData.area_id = undefined;
    this.formData.streetValue = undefined;
    this.userblock = 'none';
    this.phoneBlock = 'none';
    this.pcaBlock = 'none';
    this.addressBlock = 'none';
    this.showBlock = 'none';
  }
  // 地区校验
  areasDet() {
    let res = true;
    if(this.formData.province_id === undefined || this.formData.province_id === '0' || this.formData.province_id === 0){
      this.isValidaRegion = true;
      res = false;
    } else {
      this.isValidaRegion = false;
    }
    if(this.formData.city_id === undefined || this.formData.city_id === '0' || this.formData.city_id === 0){
      this.isValidaCity = true;
      res = false;
    } else {
      this.isValidaCity = false;
    }
    if(this.formData.area_id === undefined || this.formData.area_id === '0' || this.formData.area_id === 0){
      this.isValidaArea = true;
      res = false;
    } else {
      this.isValidaArea = false;
    }
    return res;
  }
  /*地址校验*/
  checkStreetValue() {
    if (this.formData.streetValue === undefined || this.formData.streetValue.length === 0 ) {
      this.addressBlock = 'block';
      return false;
    } else {
		if(this.address.verifyReceivingAddr(this.formData.streetValue)){
			this.addressBlock = 'none';
		}else{
			this.addressBlock = 'block';
			return false;
		}
    }
    return true;
  }

  //提交地址
  confirmSubmit(){
    if(this.editable){ //编辑地址
      this.onEditSubmit();
    } else{
      this.onSubmit(); //新增地址
    }
  }

  /*编辑地址提交*/
  onEditSubmit() {
    if (this.nameErrorBlur() && this.MobileValue() && this.areasDet() && this.checkStreetValue()) {
	  let codeResult: any = {code: '', msg: '', data: {}};
      this.formData.province_name = this.address.provinceList[this.formData.province_id]['areaName'];
      this.formData.city_name = this.address.cityList[this.formData.city_id]['areaName'];
      this.formData.area_name = this.address.areaList[this.formData.area_id]['areaName'];
      const adressjson = {
        customerId: this.localStorage.getItem('cloud_customer_id'),
        receiverName: this.formData.usernameVal,
        receiverMobile: this.formData.mobileValue,
        receiverAddress: this.formData.streetValue,
        isDefault: 0,
        receiverState: this.formData.province_name,
        receiverCity: this.formData.city_name,
        receiverDistrict: this.formData.area_name,
        areaCode: this.formData.area_id
      };
      this.address.editCloudAddress(adressjson, this.cartAddress.customerAddressId).then( res => {
        codeResult = res;
        if (codeResult.code !== 1000) {
          if (isPlatformBrowser(this.platformId)) {
            alert(codeResult.msg);
          }
        } else {
          this.address.getCloudCustomerAdd().then((data:any) => {
            if (data['data']['content'].length > 0) {
              this.checkoutAdd = [];
              this.otherAddress = [];
              this.checkoutAddressLists = {};
              data['data']['content'].forEach((item:any) => {
                if (item['isDeleted'] === 0) {
                  this.checkoutAdd.push(item);
                  this.checkoutAddressLists[item['customerAddressId']] = item;
                }
              });
            }
            this.otherAddress = this.checkoutAdd;
            this.showSetAddress = this.checkoutAddressLists[this.cartAddress.customerAddressId];
            this.cartAddress = this.checkoutAddressLists[this.cartAddress.customerAddressId];
            this.checkout.getCheckoutData(this.checkoutType).then(cart => {
              this.checkoutModel = cart;
            });
            this.editable = false;
            this.shownew = 'none';
            this.otherAddressShow = false;
            this.cdr.markForCheck();
            this.cdr.detectChanges();
          }).catch(error => error);
        }
      }).catch(error => error);
    } else {
      this.nameErrorBlur();
      this.MobileValue();
      this.areasDet();
      this.checkStreetValue();
    }
  }
  /*新增地址提交*/
  onSubmit() {
    if (this.nameErrorBlur() && this.MobileValue() && this.areasDet() && this.checkStreetValue()) {
      let codeResult: any = {code: '', msg: '', data: {}};
      this.formData.province_name = this.address.provinceList[this.formData.province_id]['areaName'];
      this.formData.city_name = this.address.cityList[this.formData.city_id]['areaName'];
      this.formData.area_name = this.address.areaList[this.formData.area_id]['areaName'];
      const adressjson = {
        customerId: this.localStorage.getItem('cloud_customer_id'),
        receiverName: this.formData.usernameVal,
        receiverMobile: this.formData.mobileValue,
        receiverAddress: this.formData.streetValue,
        isDefault: 0,
        receiverState: this.formData.province_name,
        receiverCity: this.formData.city_name,
        receiverDistrict: this.formData.area_name,
        areaCode: this.formData.area_id
      };
      this.address.commitCloudAddress(adressjson).then( (val:any) => {
        codeResult = val;
        if (codeResult.code === 1000) {
          if (isPlatformBrowser(this.platformId)) {
            $('.shipadddress').show();
          }
          // 更新购物车收货地址ID
          this.checkout.setCustomerAddressId(val['data'].customerAddressId,this.checkoutType).then(res => {
            if (res['code'] !== 1000) {
              if (isPlatformBrowser(this.platformId)) {
                alert(res['msg']);
              }
            } else {
              this.address.getCloudCustomerAdd().then((data:any) => {
                if (data['data']['content'].length > 0) {
                  this.checkoutAdd = [];
                  this.otherAddress = [];
                  this.checkoutAddressLists = {};
                  data['data']['content'].forEach((item:any) => {
                    if (item['isDeleted'] === 0) {
                      this.checkoutAdd.push(item);
                      this.checkoutAddressLists[item['customerAddressId']] = item;
                    }
                  });
                  this.otherAddress = this.checkoutAdd;
                  this.showSetAddress = this.checkoutAddressLists[codeResult['data'].customerAddressId];
                  this.cartAddress = this.checkoutAddressLists[codeResult['data'].customerAddressId];
                  this.editable = false;
                  this.hideBug = true; // 新增地址点提交后的判断
                  this.checkoutLoad = false;
                  this.vote(this.checkoutLoad);
                  this.checkout.getCheckoutData(this.checkoutType).then(cart => {
                    this.checkoutModel = cart;
                  });
                  this.otherAddressShow = false;
                  this.newAddress = false;
                  this.cdr.markForCheck();
                }
              }).catch(error => error);
            }
          });
        } else {
          alert(codeResult.msg)
          return false;
        }
		    return;
      }).catch(error => error);
    } else {
      this.nameErrorBlur();
      this.MobileValue();
      this.areasDet();
      this.checkStreetValue();
      return true;
    }
	  return;
  }
  ngOnDestroy() {
    this.checkout.cartLoading = true;
    this.setTime = null;
  }

  getOtherAddress(address_id:any) {
    this.checkoutAdd.forEach((item:any) => {
      if (item['customerAddressId'] != address_id) {
        this.otherAddress.push(item);
      }
    });
  }

  // 根据code获取地区名称
  getAreaName(type: string) {
    let areaData: any = [];
    let areaCode = '';
    let areaName = '';
    switch (type) {
      case 'province':
        areaData = this.provinces;
        areaCode = this.formData.province_id;
        break;
      case 'city':
        areaData = this.cities;
        areaCode = this.formData.city_id;
        break;
      case 'area':
        areaData = this.areas;
        areaCode = this.formData.area_id;
        break;
      default :
    }
    areaData.forEach( function(item:any) {
      if (item.areaCode.toString() === areaCode.toString()) {
        areaName = item.areaName;
      }
    });
    switch (type) {
      case 'province':
        this.formData.province_name = areaName;
        break;
      case 'city':
        this.formData.city_name = areaName;
        break;
      case 'area':
        this.formData.area_name = areaName;
        break;
      default :
    }
  }

  // 根据名称获取地区code
  getAreaCode(type: string) {
    let areaData: any = [];
    let areaCode = '';
    let areaName = '';
    switch (type) {
      case 'province':
        areaData = this.provinces;
        areaName = this.formData.province_name;
        break;
      case 'city':
        areaData = this.cities;
        areaName = this.formData.city_name;
        break;
      case 'area':
        areaData = this.areas;
        areaName = this.formData.area_name;
        break;
      default :
    }
    areaData.forEach( function(item:any) {
      if (item.areaName.toString() == areaName.toString()) {
        areaCode = item.areaCode;
      }
    });
    switch (type) {
      case 'province':
        this.formData.province_id = areaCode;
        break;
      case 'city':
        this.formData.city_id = areaCode;
        break;
      case 'area':
        this.formData.area_id = areaCode;
        break;
      default :
    }
  }
}
