<div class="my-account-content u-clearfix ng-scope">
  <form class="o-form o-my-details" id="logoutReasonForm">
  <div class="o-my-details summary">
    <div class="o-verification-wrapper">
      <p>请选择注销原因：</p>
      <div class="o-choose-reason">
        <input type="radio"  name="reason" id="verification-1" value="需要解绑手机号码" [(ngModel)]='reasonVal'  [checked]="1" (click)="setReason('需要解绑手机号码')" />
        <label for="verification-1">需要解绑手机号码</label>
      </div>
      <div class="o-choose-reason" >
        <input type="radio" name="reason" id="verification-2" value="不想再使用此网站" [(ngModel)]='reasonVal'  [checked]="2" (click)="setReason('不想再使用此网站')"/>
        <label for="verification-2">不想再使用此网站</label>
      </div>
      <div class="o-choose-reason">
        <input type="radio"  name="reason" id="verification-3" value="安全/隐私顾虑" [(ngModel)]='reasonVal'  [checked]="3" (click)="setReason('安全/隐私顾虑')"/>
        <label for="verification-3">安全/隐私顾虑</label>
      </div>
      <div class="o-choose-reason">
        <input type="radio" name="reason" id="verification-4" value="已在使用其他账户" [(ngModel)]='reasonVal'  [checked]="4" (click)="setReason('已在使用其他账户')"/>
        <label for="verification-4">已在使用其他账户</label>
      </div>
      <div class="o-choose-reason">
        <input type="radio" name="reason" id="verification-5" value="购物遇到困难" [(ngModel)]='reasonVal'  [checked]="5" (click)="setReason('购物遇到困难')"/>
        <label for="verification-5">购物遇到困难</label>
      </div>
      <div class="o-choose-reason">
        <input type="radio"  name="reason" id="verification-6" value="客服无法及时解决问题" [(ngModel)]='reasonVal'  [checked]="6" (click)="setReason('客服无法及时解决问题')"/>
        <label for="verification-6">客服无法及时解决问题</label>
      </div>
      <div class="o-choose-reason">
        <input type="radio"  name="reason" id="verification-7" value="其他" [(ngModel)]='reasonVal'  [checked]="7" (click)="setReason('其他')"/>
        <label for="verification-7">其他</label>
      </div>
      <label [ngClass]="{'errorLabel':reasonValid}" *ngIf="reasonValid == true"  class="a-label js-a-label" data-placeholder="">{{tips}}</label>
      <button  class="a-button is-primary" (click)="submitLogout()"><span>提交</span></button>
    </div>
  </div>
  </form>
</div>
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light is-visible" *ngIf="prompt === true;" style="display:block;"></div>
<div class="o-lightbox is-small is-open o-Logout-confirm" *ngIf="prompt === true;" style="display:block;">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix confirm-content">
    <div class="header text-center">注销账户确认</div>
    <p>注销账户会清空所有信息和数据，历史交易可能产生的资金退回权益将视作自动放弃，请确认是否注销。</p>
    <button  class="a-button is-primary" type="button" (click)="cancelLogout()"><span>暂不注销</span></button>
    <button  class="a-button is-primary" type="button" (click) ="confirmLogout()"><span>确认注销</span></button>
  </div><div  class="m-button-icon a-icon-close js-close-button" (click)="cancelLogout()"></div>
</div>

