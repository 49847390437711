<div class="sidebar">
  <div class="btnBox">
    <input type="text" id="searchText" [(ngModel)]="searchInput"/>
    <button class="submit" (click)="searchProduct()">提交</button>
  </div>

  <div class="gList">
    <ul id='search' [dragula]='"nested-bag"' *ngFor="let productModel of searchProductList">
      <div class="column ng-tns-c10-0 ng-star-inserted">
        <a class="a-link no-styling ng-tns-c10-0 ng-star-inserted" target="_self">
          <div class="o-product" data-id="{{productModel.entityId}}">
            <div  class="merchClose m-button-icon a-icon-close js-close-button"></div>
            <div class="merch"></div>
            <div class="image-holder">
              <!-- 缩略图 开始 -->
              <div class="listing_thumbnail">
                <div class="add_Icon search_vm"></div>
                <div class="imgList">
                  <ul  *ngFor="let images of productModel.images; let i = index; ">
                    <li >
                      <a class="a-link"><img class="a-image is-selected" src="{{images}}$200x200" alt="图片"></a>
                      <div class="setImg selection" data-id="listing_thumbnail_{{productModel.entityId}}">
                        <a *ngIf=" i === 0 " (click)="vmImageClickSearch( productModel.entityId, images, 1, i )" class="img_1 act">主图</a>
                        <a *ngIf=" i !== 0 " (click)="vmImageClickSearch( productModel.entityId, images, 1, i )" class="img_1">主图</a>
                        <a *ngIf=" i === 1 " (click)="vmImageClickSearch( productModel.entityId, images, 2, i )" class="img_2 act">辅图</a>
                        <a *ngIf=" i !== 1 " (click)="vmImageClickSearch( productModel.entityId, images, 2, i )" class="img_2">辅图</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- 缩略图 结束 -->
              <div class="image-if-hover">
                <a routerLink="/{{categoryUrl}}/{{productModel?.url_path}}" (click)="toPdp()">
                  <div class="m-product-image pos_rel">
                    <img class="a-image default-image" [ngStyle]="{'display':!isPcFlag?'block':'none'}" [defaultImage]="'assets/images/loadImg.jpg'" [lazyLoad]="productModel.default_image_path" alt="图片" [offset]="offset"/>
                    <img class="a-image default-image" [ngStyle]="{'display':((!productModel.panorama_image||productModel.entityId!=showHoverProductId) && isPcFlag)?'block':'none'}" (mouseover)="imageMouseover(productModel.entityId)" alt="图片" [defaultImage]="'assets/images/loadImg.jpg'" [lazyLoad]="productModel.default_image_path" [offset]="offset"/>
                    <img class="a-image" [ngStyle]="{'display':((productModel.panorama_image&&productModel.entityId==showHoverProductId) && isPcFlag)?'block':'none'}" (mouseout)="imageMouseout()" src="{{productModel.panorama_image}}" alt="图片"/>
                  </div>
                </a>
              </div>
            </div>
            <div class="description">
              <div class="marker-label temp-marker" *ngIf="productModel.is_new==1">
                <label class="a-label js-a-label">NEW</label>
              </div>
              <div class="product-variants">
                <label class="a-label js-a-label">Colours (3)</label>
              </div>
              <a routerLink="/{{categoryUrl}}/{{productModel?.url_path}}" (click)="toPdp()"><label class="a-label js-a-label product-title">{{productModel.name}}</label></a>
              <div class="m-product-price">
                <a routerLink="/{{categoryUrl}}/{{productModel?.url_path}}" (click)="toPdp()">
                  <label class="a-label js-a-label is-deprecated" *ngIf="productModel.price != productModel.salePrice">{{init.currency}}{{productModel.price}}</label>
                  <label class="a-label js-a-label" [ngClass]="{'is-reduced': productModel.price != productModel.salePrice}">{{init.currency}}{{productModel.salePrice}}</label>
                </a>
              </div>
              <div data-component="MProductMarkersCofs" class="m-product-marker m-product-markers" style="display: none">
                <div class="marker-image product-marker">
                  <img class="a-image" src="" alt="图片"/>
                </div>
                <div class="marker-image promo-marker">
                  <img class="a-image" src="" alt="图片"/>
                </div>
              </div>
              <div class="marker-label temp-marker">
                <label class="a-label js-a-label"></label>
              </div>
              <div class="m-swatches sm">
                <div class="picked-color"></div>
                <div class="a-swatch js-swatch" *ngFor="let block of imageBlock">
                  <label class="a-label js-a-label">
                    <input type="radio" name="colors" value="Black"/>
                    <span>
              <div class="a-swatch-foreground"></div>
              <a routerLink="/{{categoryUrl}}/{{block.url}}" (click)="toPdp()"><img class="a-image" src="{{block.image}}" alt="图片"/></a>
              <p class="sold-out">Sold out</p>
            </span>
                  </label>
                </div>
              </div>
              <label class="a-label js-a-label product-brand">COS</label>
            </div>
            <div class="inStock">库存：{{productModel.qty}}件</div>
          </div>
        </a>
      </div>
    </ul>
  </div>
</div>
