<div class="my-account-content u-clearfix">
  <div class="edit-container font_s_regular">
    <h3 class="font_s_regular mb-20">管理收货地址</h3>
    <div class="w-full" style="margin-top: -10px;">
      <ng-container *ngFor="let val of addressLists; let i = index"  class="mt-30">
        <ng-container *ngIf="editAdd && i==editIndex; else addressBlock;">
          <div class="pt-20 pb-30" [ngClass]="{'address-border': i>0}">
            <ng-container *ngIf="!editAdd; else addressTemplate;">

            </ng-container>
          </div>
        </ng-container>
        <ng-template #addressBlock>
          <div class="pt-20 mb-30" [ngClass]="{'address-border': i>0}">
            <div class="">
              <h3 class="font_s_medium a-heading-3 heading-1">{{val?.receiverName}}</h3>
              <div class="mt-8 font-size-12"><span>手机：{{val?.receiverMobile}}</span></div>
              <div class="mt-8 font-size-12">
                <span>地址：</span>
                <span class="region">{{val?.receiverState}}</span>
                <span class="city">{{val?.receiverCity}}</span>
                <span class="area">{{val?.receiverDistrict}}</span>
                <span class="street">{{val?.receiverAddress}}</span>
              </div>
            </div>
            <div class="w-full flex flex-row justify-between mt-20">
              <div >
                <ng-container *ngIf="val.isDefault === 0">
                  <input type="radio" class="a-radio-button" id="a-address-{{val.customerAddressId}}" name="use-as-default" (click)="defaultAddress(val, val.customerAddressId)">
                  <label style="margin-top: 3px;" class="a-label js-a-label u-no-select custom-radio" for="a-address-{{val.customerAddressId}}"></label>
                  <label class="a-label js-a-label label-text ml-15" for="a-address-{{val.customerAddressId}}">设为默认地址</label>
                </ng-container>
              </div>
              <div class="flex">
                <button class="m-0 p-0 appearance-none flex items-center" (click)="editAddress(i, val)">
                  <span>编辑</span>
                  <svg class="inline h-16 w-16 fill-current" style="padding-bottom: 2px;"><use href="/assets/iconSprite.svg#chevron-mini-right"></use></svg>
                </button>
                <button class="m-0 p-0 appearance-none flex items-center ml-15" *ngIf="val.isDefault === 0" (click)="Deleteopen(val.customerAddressId, i)">
                  <span>删除</span>
                  <svg class="inline h-16 w-16 fill-current" style="padding-bottom: 2px;"><use href="/assets/iconSprite.svg#close-small"></use></svg>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="w-full o-about-you" *ngIf="!newAddress" [ngClass]="{'mt-40': addressLists.length==0}">
      <button type="submit" (click)="addNewAddressBt()" class="a-button add-billing-button">
        <span>新增地址</span>
      </button>
    </div>
    <div class="w-full" *ngIf="newAddress" [ngClass]="{'mt-40': addressLists.length==0}">
      <div class="w-full a-border p-15" [ngClass]="{'border-b-none': newAddress}">
        <h4 class="a-heading-4 font_s_medium m-0">新增地址</h4>
      </div>
      <div class="flex w-full">
        <ng-container *ngIf="!newAddress; else addressTemplate;">

        </ng-container>
      </div>
    </div>
    <!--新增修改地址 公共表单-->
    <ng-template #addressTemplate>
      <div class="a-border w-full">
        <!--这位置放地址表单-->
        <div class="o-about-you main-area new_address">
          <form>
            <div class="o-form ng-invalid" id="checkoutAddressPartForm1">
              <!-- <div class="m-error js-m-error is-hidden"><label class="a-label js-a-label has-error"></label></div>-->
              <div class="about-you-fields-wrapper">
                <div data-component="MInput" class="m-input entry-item border-b">
                  <ng-container *ngIf="!nameerror; else uBlock;">
                    <label class="a-label js-a-label mb-5 tips">请输入收货人姓名</label>
                  </ng-container>
                  <ng-template #uBlock>
                    <label class="a-label js-a-label mb-5" *ngIf="formDatas.firstname && formDatas.firstname.length > 0">收货人</label>
                  </ng-template>
                  <input class="p-0 border-none" required placeholder="收货人"  maxlength="150" name="usernameVal"  [(ngModel)]="formDatas.firstname" #formData.usernameVal="ngModel" (focus)="nameerror=true;" (blur)="validateForm()" title="收货人">
                </div>
                <div data-component="MInput" class="m-input entry-item border-b">
                  <ng-container *ngIf="!mobilerror; else pBlock;">
                    <label class="a-label js-a-label mb-5 tips">{{phoneText}}</label>
                  </ng-container>
                  <ng-template #pBlock>
                    <label class="a-label js-a-label mb-5" *ngIf="formDatas.address_mobile && formDatas.address_mobile.length > 0">手机号</label>
                  </ng-template>
                  <input placeholder="手机号" class="p-0 border-none" required maxlength="11"  name="mobileValue"   [(ngModel)]="formDatas.address_mobile" #formData.mobileValue="ngModel" (focus)="mobilerror=true;" (blur)="validateMobile()" title="手机号">
                </div>
                <div data-component="MInput" class="m_input entry-item border-b" style="padding: 0 !important;">
                 <div class="flex address_details">
                   <div class="no-select-box border-r" [ngClass]="{'select-box': isValidaRegion && formDatas.province_id==0}">
                     <label class="select-label js-a-label tips" *ngIf="isValidaRegion && formDatas.province_id==0">请选择</label>
                     <div class="m-dropdown selectBox">
                       <span class="a-icon-close"></span>
                       <select class="addregion" [(ngModel)]="formDatas.province_id" name="provinceval" (change)="getCities($event.target.value)" required >
                         <option class="a-option" value="0">省份</option>
                         <option class="a-option" *ngFor="let province of provinces" value="{{province.areaCode}}" [selected]="provinces.length === 1 ? 'selected':''">{{province.areaName}}</option>
                       </select>
                     </div>
                   </div>
                   <div class="no-select-box border-r" [ngClass]="{'select-box': isValidaCity && formDatas.city_id==0}">
                     <label class="select-label js-a-label tips" *ngIf="isValidaCity && formDatas.city_id==0">请选择</label>
                     <div class="m-dropdown selectBox">
                       <span class="a-icon-close"></span>
                       <select class="addcity" [(ngModel)]="formDatas.city_id" name="cityval" (change)="getAreas($event.target.value)" required>
                         <option class="a-option" value="0">城市</option>
                         <option *ngFor="let city of cities" value="{{city.areaCode}}" [selected]="cities.length === 1 ? 'selected':''">{{city.areaName}}</option>
                       </select>
                     </div>
                   </div>
                   <div class="no-select-box" [ngClass]="{'select-box': isValidaArea && formDatas.area_id==0}">
                     <label class="select-label js-a-label tips" *ngIf="isValidaArea && formDatas.area_id==0">请选择</label>
                     <div class="m-dropdown selectBox">
                       <select class="addarea" [(ngModel)]="formDatas.area_id" name="areaval" required>
                         <option class="a-option" value="0">区/县</option>
                         <option class="a-option" *ngFor="let area of areas" value="{{area.areaCode}}" [selected]="areas.length === 1 ? 'selected':''">{{area.areaName}}</option>
                       </select>
                     </div>
                   </div>
                 </div>
               </div>
               <div data-component="MInput" class="m-input entry-item border-b">
                  <ng-container *ngIf="!addresserror; else aBlock;">
                    <label class="a-label js-a-label mb-5 tips">请填写（街道/号）</label>
                  </ng-container>
                  <ng-template #aBlock>
                    <label class="a-label js-a-label mb-5" *ngIf="formDatas.street && formDatas.street.length > 0">地址</label>
                  </ng-template>
                  <input class="p-0 border-none" required placeholder="地址" maxlength="40"  name="streetValue" [(ngModel)]="formDatas.street" (focus)="addresserror=true" (blur)="checkStreetValue()" #formData.streetValue="ngModel" title="地址">
               </div>
              </div>
              <div class="p-20">
                <div class="privacy-policy a-link font-size-12">
                  <p class="a-paragraph privacy-policy">COS将根据<a href="./privacy-policy" target="_blank" class="a-link policiesLink">隐私政策</a>来使用您个人数据管理您的账号</p>
                </div>
                <button type="submit" (click)="confirmSubmit()" class="a-button add-billing-button mt-10">
                  <span>保存</span>
                </button>
                <button type="submit" (click)="cancelSubmit()" class="a-button add-billing-button mt-10 cancel-btn">
                  <span>取消</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
  </div>
</div>
<!-- 删除地址弹框 开始 (激活：is-open) -->
<div data-component="OLightbox" class="o-lightbox is-remove-confirm" [ngClass]="{'is-open': deleteopen}">
  <div class="lightbox-header"></div><div class="lightbox-content js-content u-clearfix">
  <div class="o-remove-confirm" >
    <h2 class="a-heading-2 font_xs_regular">你确定要删除这个地址吗？</h2>
   <!-- <p class="a-paragraph"></p>-->
    <a id="removeAddressLink" class="a-button is-primary h-50 mt-15 confirm-del" (click)="clickDelete(address_id)"><span>是的，删除</span></a>
    <a class="a-button is-primary h-50 mt-15" (click)="cancelDelete()"><span>取消</span></a>
  </div>
</div>
  <div class="m-button-icon a-icon-close js-close-button" (click)="cancelDelete()"></div>
</div>
<!-- 删除地址弹框 结束 -->
