<div class="myAccount_right">
  <div *ngIf="rmaService.loading;else contentBlock" style="text-align: center"> 获取中... </div>
  <ng-template #contentBlock>
    <div class="topSection clearfix">
      <h5 class="pull-left">退货订单详情</h5>
    </div>
    <div class="rmaInfo">
      <ul>
        <li>服务单号: {{rmaDetail.refundCode}}</li>
        <li>订单编号: <a [routerLink]="['/my-account/orderdetail']" [queryParams]="{orderid: rmaDetail?.orderId}" class="a-link">{{rmaDetail?.tid ? rmaDetail?.tid : rmaDetail?.orderCode}}</a></li>
        <li>申请时间: {{rmaDetail.createTime.substr(0,10)}}</li>
        <li>处理状态: {{rmaService.refundStateTranslateArr[rmaDetail?.status]}}</li>

        <li *ngIf="rmaDetail?.notGiftCardFee > 0 && rmaDetail?.giftCardFee > 0 && rmaDetail?.status == 'SUCCESS';else generalPay">
          退款金额:
        <div class="div-box">
          <div class="div-o">
            <span>微信</span>
            <span class="div-span">￥{{rmaDetail?.notGiftCardFee}}</span>
          </div>
          <div class="div-o">
            <span>礼品卡</span>
            <span class="div-span">￥{{rmaDetail?.giftCardFee}}</span>
          </div>
        </div>
        </li>
        <ng-template #generalPay>
          <li>退款金额:
            <span *ngIf="rmaDetail?.notGiftCardFee > 0">微信￥{{rmaDetail?.notGiftCardFee}}</span>
            <span *ngIf="rmaDetail?.giftCardFee > 0">礼品卡￥{{rmaDetail?.giftCardFee}}</span>
          </li>
        </ng-template>

        <li *ngIf="rmaDetail?.refundTime && rmaDetail?.refundTime != null">退款时间: {{rmaDetail?.refundTime}}</li>
      </ul>
    </div>
    <div class="rmaGoodsInfo">
      <h6 class="subTitle">退货商品信息</h6>
      <div class="gInfo_item clearfix"  *ngFor="let rmaItem of rmaDetail.items">
        <div class="gInfo pull-left">
          <div class="pull-left listImg">
            <a routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}" class="a-link"><img src="{{orderService.analysisProductImage(rmaItem.skuImageUrl, rmaItem.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
          </div>
          <div class="pull-left textInfo" style="width: 75%">
            <strong><a routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}" class="a-link">{{rmaItem.itemName}}</a></strong>
            <p>货号：{{rmaItem.skuCode}}</p>
            <p>颜色：{{rmaService.analysisProductColor(rmaItem.skuProperties).valueName}}</p>
            <p>尺码：{{rmaService.sizeConvert(rmaService.analysisProductSize(rmaItem.skuProperties).valueName)}}</p>
          </div>
        </div>
        <div class="rmaReturnInfo">
          <ul>
            <li>退货原因：{{rmaItem.reason}}</li>
            <li>申请数量：{{rmaItem.num}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="rmaExpressInfo" *ngIf="rmaDetail.expressName && rmaDetail.trackingNumber; else elseblock">
      <h6 class="subTitle">寄回快递信息</h6>
      <ul>
        <li>快递公司：{{rmaDetail.expressName}}</li>
        <li>快递单号：{{rmaDetail.trackingNumber}}</li>
      </ul>
    </div>
    <div class="orderTable">
      <p class="btnCont" *ngIf="(rmaDetail?.status == 'WAIT_SELLER_AGREE' || rmaDetail?.status == 'WAIT_BUYER_RETURN_GOODS') && rmaDetail?.reason !== '未发货取消订单'">{{rmaDetail?.reason}}<a (click)="rmaService.cancelRefund(rmaDetail.refundId)" href="javascript:void(0)" class="a-link-underline">取消申请</a></p>
      <ng-template #elseblock>
        <p class="btnCont" *ngIf="(!rmaDetail.expressName || !rmaDetail.trackingNumber) && rmaDetail?.status !== 'WAIT_SELLER_AGREE' && rmaDetail?.status !== 'CLOSED' && rmaDetail?.status !== 'SUCCESS' && rmaDetail?.status !== 'RETAIL_STORE_RECEIVED'"><a [routerLink]="['/my-account/rma-return']" [queryParams]="{id: rmaDetail?.refundId}" class="a-button is-primary">填写退货物流信息</a></p>
      </ng-template>
    </div>
  </ng-template>

  <div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': rmaService.applyCancel}" data-component="AOverlay" (click)="rmaService.closeOverlay()"></div>
  <div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="rmaService.applyCancel" style="text-align: center">
    <div class="lightbox-header"></div>
    <div class="lightbox-content js-content u-clearfix">
      <!-- 取消订单 开始 -->
      <div class="m-change-country-lightbox false">
        <h2 class="a-heading-2">您确认取消订单吗？</h2>
        <p class="a-paragraph">&nbsp;</p>
        <a class="a-button is-primary" (click)="confirmCancel()">确认取消</a>
        <button class="a-button is-secondary js-close-button" (click)="rmaService.closeOverlay()"><span>再考虑下</span></button>
      </div>
      <!-- 取消订单 结束 -->
    </div>
  </div>
</div>
