import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shopping-to',
  templateUrl: './shopping-to.component.html',
  styleUrls: ['./shopping-to.component.css']
})
export class ShippingToComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }

}
