import {Component, Inject, Input, OnChanges, OnDestroy, OnInit,ViewChild, Optional, PLATFORM_ID, TemplateRef,HostListener} from '@angular/core';
import {BsModalRef,BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {ScriptService} from '../../services/script.service';
import {CmsAdvancedBlockModel} from '../../models/cms.advanced.block.model';
import {isPlatformBrowser} from '@angular/common';
import {DragulaService} from '../../../server_mocks/ng2-dragula-stub/dragula.service';
import $ from 'jquery';
import {ActivatedRoute, Router} from '@angular/router';
import {CmsService} from '../../services/cms.service';
import {InitializeService} from '../../services/initialize.service';
import {CmsAdvancedPageModel} from '../../models/cms.advanced.page.model';
import {any} from 'codelyzer/util/function';
import {StorageService} from '../../services/storage.service';
import {CartService} from '../../services/cart.service';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {SignInComponent} from '../../components/common/sign-in/sign-in.component';
import {CallComponentService} from '../../services/callcomponent.service';
import {AbTrackingService} from '../../services/abtracking.service';
import {TrackingcodeService} from '../../services/trackingcode.service';


@Component({
  selector: 'app-cms-advanced',
  templateUrl: './cms-advanced.component.html',
  styleUrls: ['./cms-advanced.component.css']
})

export class CmsAdvancedComponent implements OnInit, OnDestroy, OnChanges {

  // 编辑器
  modalRef!: BsModalRef;
  modalConfig!: ModalOptions;
  ckeConfig: any;
  log = '';
  wxUserRegisterBox:boolean = false;
  @Input() page_id: any;
  @Input() is_category = false;
  public editorTypes = [
    {type: 'customHtml', name: '自定义HTML'},
    {type: 'textEditor', name: '文本编辑器'},
    {type: 'swiper', name: '轮播图'},
    {type: 'singleProduct', name: '单个商品'},
    {type: 'multiProduct', name: '多个商品'},
    {type: 'arrivalReminderProduct', name: 'cloud自定义商品'},
    {type: 'reusable', name: '可重用内容'},
    {type: 'lp', name: '自定义分类商品'},
    {type: 'cloudLp', name: 'cloud自定义分类商品'},
    {type: 'cloudTab', name: 'cloud多Tab切换'}
  ];

  public page!: CmsAdvancedPageModel;

  public blockIdx = 0;

  public editBlock = {};

  public loading = true;

  public columns = [
    {class: 'col-md-12', name: '100%'},
    {class: 'col-md-9', name: '75%'},
    {class: 'col-md-6', name: '50%'},
    {class: 'col-md-4', name: '33%'},
    {class: 'col-md-3 col-sm-6 col-xs-6', name: '25%'}
  ];

  // 删除区块用
  private pendingDeleteContainer: any;
  private pendingDeleteBlockIndex!: number;
  @ViewChild('signIn')
	childComponent!: SignInComponent;

  //abtest 状态
  private abstate: any = {
    cmsId: "home", //默认CMS页
  };
  public constructor(public cmsService: CmsService,
                     private dragulaService: DragulaService,
                     public init: InitializeService,
                     private router: Router,
                     private routerInfo: ActivatedRoute,
                     private script: ScriptService,
                     private storageService: StorageService,
					 private modalService: BsModalService,
                     private storage: StorageService,
                     private cart: CartService,
                     public eventSubscribeService: CallComponentService,
                     @Inject(PLATFORM_ID) private platformId: Object,
                     @Optional() @Inject(RESPONSE) private response: any,
                     private abTracking: AbTrackingService,
					 private tracking: TrackingcodeService,
                     private localStorage: StorageService) {
    this.modalConfig = new ModalOptions();
    this.modalConfig.backdrop = true;
    this.modalConfig.ignoreBackdropClick = true;
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId) && this.storageService.getItem( 'vmLogin' )) {
      this.script.load('ckeditor').then(_data => {
        this.ckeConfig = {
          allowedContent: true,
          extraPlugins: 'divarea'
        };
      }).catch(error => console.log(error));
    }
  }

  ngOnInit() {
    let self = this;
    if (isPlatformBrowser(this.platformId) && this.storageService.getItem( 'vmLogin' )) {
      this.dragulaService.drag.subscribe((value: any) => {
        // console.log(`drag: ${value[0]}`); // value[0] will always be bag name
        this.onDrag(value.slice(1));
      });
      this.dragulaService.drop.subscribe((value: any) => {
        console.log(this.cmsService.sharedData.containers);
        this.checkContainerEmpty();
        // console.log(`drop: ${value[0]}`);
        this.onDrop(value.slice(1));
      });
      this.dragulaService.over.subscribe((value: any) => {
        // console.log(`over: ${value[0]}`);
        this.onOver(value.slice(1));
      });
      this.dragulaService.out.subscribe((value: any) => {
        // console.log(`out: ${value[0]}`);
        this.onOut(value.slice(1));
      });
    }

    if (!this.init.isLogin()) {
      this.routerInfo.queryParams.subscribe(parmas => {
        let codeResult: any = {code: '', data: any, message: ''};
        if ((parmas.code && parmas.code !== undefined) && (parmas.state && parmas.state !== undefined)) {
          this.cmsService.jumpToWecahtLogin(parmas.code, parmas.state).then(response => {
            codeResult = response;
            if (codeResult.code === 1000) {
              if(codeResult.msg === '微信用户未绑定手机号'){
                //this.localStorage.setItem('wx_temp_userid',codeResult.data.wx_user_id);
                //this.router.navigate(['/']);
                this.wxUserRegisterBox = true;
                this.localStorage.setItem('showWxUserRegisterBox',true);

                //记录appid ，openid
                this.localStorage.setItem('tempOpenId',codeResult.data.openId);
                this.localStorage.setItem('tempAppId',codeResult.data.appId);
              }else{
                this.localStorage.setItem('jwt', codeResult.data.jwt);
                this.localStorage.setItem('cloud_jwt', codeResult.data.jwt);
                this.localStorage.setItem('cloud_customer_id', codeResult.data.customerId);
                //this.localStorage.setItem('uuid', codeResult.data.token);
                this.localStorage.setItem('loginTime', new Date().valueOf());

                // 校验是否有cartkey
                if (this.localStorage.getItem('quote_id') && this.localStorage.getItem('quote_id') != undefined && this.localStorage.getItem('quote_id') != null) { // 合并购物车
                  this.cart.mergeCart().then(_quoteIdRes => {
                    this.cart.getCartData().then( _res => {
                      // this.localStorage.removeItem('quote_id');
                    }).catch(error => error);
                  });
                } else {
                  this.cart.getQuoteId(this.localStorage.getItem('quote_id')).then(_quoteIdRes => {
                    this.cart.getCartData().then( _res => {
                      // this.localStorage.removeItem('quote_id');
                    }).catch(error => error);
                  });
                }
              }
              this.init.setLoginSession(codeResult.data.customer_id, codeResult.data.username, codeResult.data.mobile);
              this.router.navigate(['/']);
            } else {
              this.wxUserRegisterBox = true;
              this.localStorage.setItem('showWxUserRegisterBox',true);
            }
          }).catch(error => error);
        }
      });
    }
    this.cmsService.showCmsPanel = true;

    this.routerInfo.params.subscribe(
      params => {
        this.delEmpty();
        this.loading = true;
        // 记录门店名称
        const currentUrl = this.router.url;
		this.tracking.currentPagePath = currentUrl;

        if (currentUrl.indexOf('utm_source') > -1) {
          this.storage.setItem('utm_source', this.routerInfo.snapshot.queryParams['utm_source']);
        }
        if (currentUrl.indexOf('utm_medium') > -1) {
          this.storage.setItem('utm_medium', this.routerInfo.snapshot.queryParams['utm_medium']);
        }
        if (this.is_category) {
          return;
        }
        let id = params['id'];
        if (id === undefined) {
          id = 'home';
        }
        if (params['iid']) {
          id = params['iid'];
        }
        if (params['iiid']) {
          id = params['iiid'];
        }
        if (params['iiiid']) {
          id = params['iiiid'];
        }

        self.abstate.cmsId = id;
        //尝试应用abtest数据
        //TODO 此页面修改了状态变量后不好触发 重新请求cms，所以使用callback方式实现的
        // 执行浏览器端代码
        if (isPlatformBrowser(this.platformId)) {
          console.info("begin tryApplyAbConfigForPage in browser,in cms-advanced ...");
          self.abTracking.tryApplyAbConfigForPage(this, function() {
              console.info("Aft applied ab,cmsId:" + self.abstate.cmsId + ", origin id:" + id);
              if (self.abstate.cmsId != id) { //ab时cmsId变更，重新初始cms
                self.getCmsPages(self.abstate.cmsId);
              }
          });
        }

        if (this.abstate.cmsId === 'new') {
          this.loading = false;
          this.cmsService.sharedData.pageModel = new CmsAdvancedPageModel({});
          this.cmsService.sharedData.containers = [];
          this.cmsService.sharedData.showData = false;
          this.cmsService.sharedData.previewMode = false;
          this.cmsService.sharedData.isNewPage = true;
        } else {
          this.cmsService.sharedData.isNewPage = false;
          this.abstate.cmsId = this.abstate.cmsId.replace('.html', '');
          if (this.cmsService.cms_timestamp[this.abstate.cmsId]) {
            this.getCmsPages(this.abstate.cmsId);
          } else {
            this.cmsService.getCmsTimestamp().then(
              _model => {
                this.getCmsPages(this.abstate.cmsId);
              }
            );
          }
        }
      }
    );
  }

  closeModal(_event:any): void {
      this.closeOverlay();
  }


  private getCmsPages(id:any) {
    console.info("getCmsPages(), page_id:" + this.page_id + ", id:" + id);
	// 新版Ga Tracking Js
	if(id == 'home'){
		if (this.init.gaTrackingFlag) {
			this.tracking.gaTrackingStartPage();
		}
	}
    this.cmsService.getAdvancedPage(id).then(model => {
      this.loading = false;
      if (!model.hasOwnProperty('page_id')) {
        if (this.response) {
          this.response.statusCode = 404;
        }
        // console.log("getCmsPages 失败，即将跳转404页面");
        return this.router.navigate(['/404']);
      }
      this.cmsService.sharedData.pageModel = model;
      this.init.setTitle(model.title);
      if (model.title) {
        // this.breadcrumbService.addBreadcrumb(model.title);
      }
      if (this.cmsService.isEditMode() === true) {
        if (model.preview) {
          this.assignContainers(model.preview);
        } else {
          this.assignContainers(model.containers);
        }
      } else {
        this.assignContainers(model.containers);
      }
      // 页面返回顶部
      if (isPlatformBrowser(this.platformId)) {
        //$('html,body').animate({scrollTop: 0}, 500);
        // 页面返回顶部
          const cmsScrollTop = this.localStorage.getItem('cmsScrollTop_'+this.router.url);
          this.localStorage.removeItem('cmsScrollTop');
          if(cmsScrollTop > 0){
            $('html,body').animate({scrollTop: cmsScrollTop}, 500);
          }else{
            $('html,body').animate({scrollTop: 0}, 500);
          }
        }
		return;
    }).catch(error => {
      console.error( "getCmsPages方法查询cms时异常:" + JSON.stringify(error));
    });

  }

 ngOnChanges() {
   this.loading = true;
    if (!this.page_id || !this.is_category) {
      return;
    }
   this.delEmpty();
   this.cmsService.getAdvancedPage(this.page_id).then(model => {
     this.delEmpty();
     this.loading = false;
     if (!model.hasOwnProperty('page_id')) {
       if (this.response) {
         this.response.statusCode = 404;
       }
       return this.router.navigate(['/404']);
     }
     this.cmsService.sharedData.pageModel = model;
     this.assignContainers(model.containers);
     // 页面返回顶部
     if (isPlatformBrowser(this.platformId)) {
       const cmsScrollTop = this.localStorage.getItem('cmsScrollTop_'+this.router.url);
       this.localStorage.removeItem('cmsScrollTop');
       if(cmsScrollTop > 0){
         $('html,body').animate({scrollTop: cmsScrollTop}, 500);
       }else{
         $('html,body').animate({scrollTop: 0}, 500);
       }
     }
	 return;
   }).catch(error => error);
 }
   // 清空script
  public delEmpty() {
    if (isPlatformBrowser(this.platformId)) {
      const content:any = document.querySelectorAll('.cms-script');
      if (content) {
        for (let i = 0; i < content.length; i++) {
          content[i].parentNode.removeChild(content[i]);
        }
      }
    }
  }
  /**
   * 给Containers赋值
   * @param containersJson
   */
  assignContainers(containersJson:any) {
    this.cmsService.sharedData.containers = [];
    const containers = JSON.parse(containersJson);
    try {
      for (const container of containers) {
        const blocks = [];
        for (const block of container) {
          blocks.push(new CmsAdvancedBlockModel(block));
        }
        this.cmsService.sharedData.containers.push(blocks);
      }
    } catch (e) {
      console.log('CONTAINER JSON ERROR: ' + containersJson);
    }
  }

  ngOnDestroy() {
    this.cmsService.showCmsPanel = false;
  }
  /**
   * Open editor
   * @param {TemplateRef<any>} _template
   * @param {CmsAdvancedBlockModel} block
   */
  openEditor(_template: TemplateRef<any>, block: CmsAdvancedBlockModel) {
    this.editBlock = block;
    this.modalRef = this.modalService.show(_template, this.modalConfig);
  }

  onChange(_$event: any): void {
    console.log('onChange');
    // this.log += new Date() + "<br />";
  }

  /**
   * Add new block
   * @param container
   */
  addBlock(container:any) {
    const block = new CmsAdvancedBlockModel({});
    container.push(block);
    this.blockIdx += 1;
  }

  /**
   * Delete block
   * @param {TemplateRef<any>} _template
   * @param container
   * @param index
   */
  deleteBlock(_template: TemplateRef<any>, container:any, index:any) {
    this.modalRef = this.modalService.show(_template, this.modalConfig);
    this.pendingDeleteContainer = container;
    this.pendingDeleteBlockIndex = index;
  }

  /**
   * Confirm delete block
   */
  confirmDeleteBlock(): void {
    this.pendingDeleteContainer.splice(this.pendingDeleteBlockIndex, 1);
    this.checkContainerEmpty();
    this.modalRef.hide();
  }

  /**
   * Decline delete block
   */
  declineDeleteBlock(): void {
    this.modalRef.hide();
  }

  checkContainerEmpty() {
    this.cmsService.sharedData.containers.forEach((val:any, index:any) => {
      if (val.length < 1) {
        this.cmsService.sharedData.containers.splice(index, 1);
      }
    });
  }

  private hasClass(el: any, name: string): any {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  private addClass(el: any, name: string): void {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }

  private removeClass(el: any, name: string): void {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), '');
    }
  }

  private onDrop(args: any): void {
    const [e] = args;
    this.addClass(e, 'ex-moved');
  }

  private onOver(args: any): void {
    const [el] = args;
    this.addClass(el, 'ex-over');
  }

  private onOut(args: any): void {
    const [el] = args;
    this.removeClass(el, 'ex-over');
  }

  private onDrag(args: any): void {
    const [e] = args;
    this.removeClass(e, 'ex-moved');
  }

  closeOverlay(){
    this.localStorage.removeItem('showWxUserRegisterBox');
    this.localStorage.removeItem('wx_temp_userid');
    this.wxUserRegisterBox = false;
  }

@HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const elementId = targetElement.getAttribute('id');
    if (elementId && elementId==='DELIVERY-COUNTRY') {
      this.eventSubscribeService.selectCountryBox.emit(true);
    }
    const cmsScrollTop = document.documentElement.scrollTop || window.scrollY || document.body.scrollTop;
    this.localStorage.setItem('cmsScrollTop_'+this.router.url, cmsScrollTop);
  }
}
