import {Component, Input, OnInit} from '@angular/core';
import {CategoryService} from '../../../../services/category.service';
import {InitializeService} from '../../../../services/initialize.service';

@Component({
  selector: 'app-cms-block-lp',
  templateUrl: './cms-block-lp.component.html',
  styleUrls: []
})
export class CmsBlockLpComponent implements OnInit {

  @Input() block:any;
  @Input()
	editMode!: boolean;

  public productModel: any = {productList: {}};

  constructor(
    public init: InitializeService,
    public categoryService: CategoryService) {
  }

  ngOnInit() {
    if (!this.block.hasOwnProperty('data')) {
      this.block.data = {};
    }
    if (!this.block.data.hasOwnProperty('config')) {
      this.block.data.config = {
        rows: 'threeLines',
      };
    }
    if (this.block.data.hasOwnProperty('cid')) {
      if (!this.block.data.count) {
        this.block.data.count = 12;
      }
      this.getProductList();
    }
  }

  refresh() {
    this.getProductList();
  }

  getProductList() {
    this.categoryService.getLpProductListByCid(this.block.data.cid, this.block.data.count).then(data => {
      this.productModel = data;
    }).catch(error => error);
  }


}
