import {Component, Inject, OnInit, PLATFORM_ID, Renderer2} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {


  constructor(private init: InitializeService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private render: Renderer2) {
  }

  ngOnInit() {
    this.init.pageConfiguration('购物袋');
    if (isPlatformBrowser(this.platformId)) {
      const bodyDom = document.querySelector('body');
      this.render.removeClass(bodyDom, 'u-overflow-hidden');
    }
  }
}

export class ShoppingCart {
  constructor(public id: number,
              public name: string,
              public sku: string,
              public price: number,
              public link: string,
              public image: string) {

  }
}
