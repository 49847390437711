
export class CartModel {

  public quote_id!: string;
  public address: any;
  public is_change!: number;
  public store_id!: number;
  public grand_total!: number;
  public exchange_amount!: number;
  public cod_fee!: number;
  public customer_id!: number;
  public customer_dob!: string;
  public customer_email!: string;
  public shipping_address_id!: string;
  public weight!: number;
  public increment_id!: string;
  public passcode!: string;
  public discount_description!: any;
  public shipping_description!: string;
  public created_at!: string;
  public updated_at!: string;
  public cod_fee_used_points!: number;
  public checkoutItemKeys!: any;
  public unchecked_items!: any;
  public checked_items!: any;
  public cc_ids!: string;
  public customer_points!: number;
  public shoppingBags!: any;
  public checked_item_pids!: string;
  public exchanges: any;


  // cloud data
  public customerId: any;
  public items: any;
  public invalidItems:any;
  public merchantId: any;
  public orderSource: any;
  public orderType: any;
  public payType: any;
  public payment: any; // 总计
  public postFee: any; // 运费
  public storeId: any;
  public total: any;
  public itemTotal: any; // 小计
  public discountFee: any; // 优惠
  public couponFee: any; // 优惠券
  public itemCodes = '';
  public couponCode: any;
  public customerAddressId: any;


  // set data
  public total_qty_ordered!: number;
  public total_qty_checked!: number;

  public total_has_stock_qty!: number;
  public total_has_stock_checked_qty!: number;

  constructor(data: {}) {
    if (data === undefined || data === null || data === '') {return; }
    Object.assign(this, data);
  }

  checkedItems() {
    /*已选择商品*/
    this.checked_items = [];
    for (const i in this.items) {
      if (this.items[i]['isChecked'] == 1) {
        this.checked_items.push(this.items[i]);
      }
    }
    return this.checked_items;
  }

  /*获取促销优惠信息*/
  getDiscount() {
    const description = [];
    for (const n in this.discount_description) {
      if (this.discount_description.hasOwnProperty(n)) {
        description.push(this.discount_description[n]);
      }
    }
    return description;
  }

  getTotalDiscount() {
    return this.discountFee * 1 + this.couponFee * 1;
  }

  uncheckedItems() {
    /*暂不结算商品*/
    const checKorderArray = [];
    for (const i in this.unchecked_items) {
      if (this.unchecked_items.hasOwnProperty(i)) {
        checKorderArray.push(this.unchecked_items[i]);
      }
    }
    return checKorderArray;
  }
  /*获取购物车所有商品*/
  getCartItems() {
    return this.items ? this.items : [];
  }
  getExchanges() {
    const exchangesArray = [];
    for (const i in this.exchanges) {
      if (this.exchanges.hasOwnProperty(i)) {
        exchangesArray[0] = this.exchanges[i];
      }
    }
    return exchangesArray;
  }
//仅查询有效商品
  getValidItems(){
    const validItems = [];
    if(this.items){
      for (const i in this.items) {
        if(this.items[i].inValid !== 1){
          validItems.push(this.items[i]);
        }
      }
    }
    return validItems;
  }

  getInvalidItems(){
    if(this.invalidItems !== null && this.invalidItems !== undefined){
      if(this.invalidItems.length > 0){
        this.invalidItems.forEach((invalidItem:any, key:any) => {
          invalidItem['amountTotal'] = (invalidItem['markingPrice'] * invalidItem['qty']).toFixed(2)
          // 设置加购商品属性
          if (typeof invalidItem['skuProperties'] == 'string') {
            this.invalidItems[key]['skuProperties'] = JSON.parse(invalidItem['skuProperties']);
          }
          // 设置商品跳转链接
          this.invalidItems[key]['skuProperties'].forEach((pitem:any) => {
            if (pitem['isFront'] === 1) {
              this.invalidItems[key]['url_path'] = pitem['frontUrlPath'];
            }
          });
        });
      }
      return this.invalidItems ? this.invalidItems : [];
    }else{
      return [];
    }


  }
  /**
   * 重置item 数据
   */
  setItemData() {
    this.total_qty_checked  = 0;
    this.total_has_stock_qty = 0;
    this.total_has_stock_checked_qty = 0;
	
    if (this.items && this.items != null && this.items.length > 0) {
      this.total_qty_ordered = 0;
      this.items.forEach((item:any, key:any) => {
        item['amountTotal'] = (item['markingPrice'] * item['qty']).toFixed(2)
        // 设置加购件数
        this.total_qty_ordered += item['qty'];
        // 设置选择加购件数
        if (item['isChecked'] * 1 == 1) {
          this.total_qty_checked += item['qty'];
        }
        if (item['stockQty'] * 1 > 0) {
          this.total_has_stock_qty += item['qty'];
          if (item['isChecked'] * 1 == 1) {
            this.total_has_stock_checked_qty += item['qty'];
          }
        }
        // 设置加购商品属性
        if (typeof item['skuProperties'] == 'string') {
          this.items[key]['skuProperties'] = JSON.parse(item['skuProperties']);
        }
        // 设置商品跳转链接
        this.items[key]['skuProperties'].forEach((pitem:any) => {
          if (pitem['isFront'] === 1) {
            this.items[key]['url_path'] = pitem['frontUrlPath'];
          }
        });
		
        if (this.itemCodes.indexOf(item['skuProperties'][0]['frontCode']) === -1) {
          this.itemCodes += this.itemCodes != '' ?  ',' + item['skuProperties'][0]['frontCode'] : item['skuProperties'][0]['frontCode'];
        }
      });
    }
  }

  getItemDataBySku(skuid:any) {
    let item = {};
    for (const i in this.items) {
      if (this.items[i]['skuId'] === skuid) {
        item = this.items[i];
        break;
      }
    }
    return item;
  }

  getInvalidItemDataBySku(skuid:any) {
    let item = {};
    for (const i in this.invalidItems) {
      if (this.invalidItems[i]['skuId'] === skuid) {
        item = this.invalidItems[i];
        break;
      }
    }
    return item;
  }

  getSaleRule(discounts:any) {
    let saleRule = '';
    if (discounts && discounts.length > 0) {
      discounts.forEach((discountItem:any) => {
        saleRule += saleRule != '' ?  ' ' + discountItem['discountName'] : discountItem['discountName'];
      });
    }
    return saleRule;
  }
}
