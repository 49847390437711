import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {ProductService} from '../../services/product.service';
import {ProductModel} from '../../models/product.model';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit{
  public productModel!: ProductModel;
  public item_code!: string;
  public value_name!: string;
  public front_code!: string;
  public store_id: any;
  public productId: any;
  public redirect_url!: string;
  public qr_client: any;
  public customer_utm_source: any = '';
  public customer_utm_medium : any = '';
  constructor(
              private routerInfo: ActivatedRoute,
              private productService: ProductService,
              private router: Router,
              private init: InitializeService,
              private storage: StorageService) {
              }

  ngOnInit() {
    if (this.routerInfo.snapshot.queryParams['item_code'] != null) {
      this.item_code = this.routerInfo.snapshot.queryParams['item_code'];
      this.store_id = this.routerInfo.snapshot.queryParams['store_id'];
      this.value_name = this.routerInfo.snapshot.queryParams['value_name'];
      this.front_code = this.routerInfo.snapshot.queryParams['front_code'];
      this.customer_utm_source = '';
      this.customer_utm_medium = '';
      this.storage.setItem('customer_utm_source', '');
      this.storage.setItem('customer_utm_medium', '');
      if (this.routerInfo.snapshot.queryParams['utm_source'] != null) {
        this.customer_utm_source = this.routerInfo.snapshot.queryParams['utm_source'];
        this.storage.setItem('customer_utm_source', this.customer_utm_source);
        this.storage.setItem('customer_utm_source_timestamp', new Date().getTime());
      }
      if (this.routerInfo.snapshot.queryParams['utm_medium'] != null) {
        this.customer_utm_medium = this.routerInfo.snapshot.queryParams['utm_medium'];
      } else {
        this.customer_utm_medium = 'pdp';
      }
      this.storage.setItem('customer_utm_medium', this.customer_utm_medium);
      this.qr_client = 'alipay';
      // 调用服务
      this.routerInfo.params.subscribe(_params => {
        const sku = (this.front_code && this.front_code !== undefined) ? this.front_code : this.item_code;
        this.productService.getCloudProductBySku(sku).then((res: any) => {
          if (parseInt(res.code, 0) !== 1000) {
            return this.router.navigate(['/404']);
          } else {
            let urlPath = '';
            if(this.front_code === undefined){
              for (const key in res['data']['skus']) {
                for (const j in res['data']['skus'][key]['skuProperties']) {
                  if (res['data']['skus'][key].skuProperties[j].isFront === 1) {
                    if (this.value_name === res['data']['skus'][key].skuProperties[j].valueName) {
                      urlPath = res['data']['skus'][key].skuProperties[j].frontUrlPath;
                      break;
                    }
                  }
                }
              }
            }else{
              for (const key in res['data']['skus']) {
                for (const j in res['data']['skus'][key]['skuProperties']) {
                  if (res['data']['skus'][key].skuProperties[j].isFront === 1) {
                    if (this.front_code === res['data']['skus'][key].skuProperties[j].frontCode) {
                      urlPath = res['data']['skus'][key].skuProperties[j].frontUrlPath;
                      break;
                    }
                  }
                }
              }
            }

            const userAgent = navigator.userAgent;
            let qrcode_data = 'https://www.cos.cn/item/redirect?item_code=' + this.item_code + '&value_name=' + this.value_name + '&user_name=COS:PDP&front_code=' + this.front_code;
            if (this.customer_utm_source && this.customer_utm_source != '') {
              qrcode_data += '&utm_source=' + this.customer_utm_source;
            }
            const paramsJson = {'itemCode': this.item_code, 'merchantId': this.init.merchantId, 'qrUrl': qrcode_data, 'userAgent': userAgent, 'userName': 'COS:PDP', 'valueName': this.value_name}
            this.productService.setProductQrcodeLog(paramsJson).then(_result => {
              this.router.navigate([urlPath]);
            });
			return;
          }
        }).catch(error => error);
      });
    }
  }
}

