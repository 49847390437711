<div class="o-page-content cofs-page" *ngIf="paymentStatus">
  <div class="payTextPage" *ngIf="orderStep == 'normal';else presaleTpl">
    <h2>支付成功</h2>
    <p>我们将于收到订单确认后的2至3个工作日内尽快寄出商品<br/>
      您可随时在<u><a routerLink="/my-account/order">我的帐户</a></u>中跟踪订单状态</p>

      <div class="QRcode" *ngIf="showWeixinQr">
        <div class="caption">
          <img style="width: 155px;" src="https://100000005-1252208446.file.myqcloud.com/images/sunCode/2020102069204.jpg" alt="扫码关注公众号"/>
        </div>
        <p class="text_bottom">即刻扫码关注COS公众号<br/>
          加入会员享限时85折优惠</p>
      </div>
  </div>

  <ng-template #presaleTpl>
    <div class="payTextPage" *ngIf="paymentStage == 'advancePayment';else lastPayTpl">
      <h2>定金支付成功</h2>
      <p>您可随时在<u><a routerLink="/my-account/order">我的帐户</a></u>中跟踪订单状态</p>
      <p class="text_bottom">
        请及时留意短信<br/>
        获取尾款支付通知
      </p>

      <div class="QRcode" style="display: none;">
        <div class="caption">
          <img style="width: 155px;" src="https://100000005-1252208446.file.myqcloud.com/images/sunCode/2020102069204.jpg" alt="扫码关注公众号"/>
        </div>
        <p class="text_bottom">关注COS官方微信<br/>
          即时获取尾款支付通知</p>
      </div>
    </div>

    <ng-template #lastPayTpl>
      <div class="payTextPage">
        <h2>支付成功</h2>
        <p>我们将于收到订单确认后的2至3个工作日内尽快寄出商品<br/>
          您可随时在<u><a routerLink="/my-account/order">我的帐户</a></u>中跟踪订单状态</p>
      </div>
    </ng-template>

  </ng-template>
</div>
<div class="o-page-content cofs-page" *ngIf="!paymentFailure && isMobilePayment">
  <div class="payTextPage">
    <h2>未完成支付</h2>
    <p class="margin_style"><button class="a-button is-primary" routerLink="/checkout/payment/{{incrementId}}">继续支付</button><u><a routerLink="/" class="a-link">去首页</a></u></p>
  </div>
</div>
<div *ngIf="!paymentStatus && isMobilePayment && paymentFailure"> <!--/ && !isWechatBrowser && isH5wechatPayment-->
  <div class="topDiv hidden-lg">
    <p>请确认微信支付是否已完成</p>
    <button class="a-button is-secondary js-close-button" (click)="continuePayment()">支付遇到问题，重新支付</button>
    <a class="a-button is-primary" (click)="completePayment()">已完成支付</a>
    <div class="center-block"><u><a class="a-link" (click)="goHome()">去首页</a></u></div>
  </div>
  <div class="topDivBg hidden-lg"></div>
</div>
