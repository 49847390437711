import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RemoteServer} from '../config/config.remoteServer';
import {ScriptService} from './script.service';

const apiConfig = require('../config/config.api.json');

@Injectable()
export class SensorsTrackingService {
  constructor(
    private HOST: RemoteServer,
    public script: ScriptService,
    @Inject(PLATFORM_ID) private platformId: Object
    ) {
  }

  public registerSensors() {
    // console.info("registerSensors start...");
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      console.info("begin registerSensors in browser ...");
      this.script.load("sensors").then((_data) => {
        this._initSensors();
      }).catch(error => console.log(error));
    } else {
      console.info("begin registerSensors but not in browser ...");
    }
  }

  private _initSensors() {
    let host = this.HOST.BASIC_HOST;
    // const host = this.init.host;
    // console.info("sensors server url:" + host + apiConfig.trackingService + 'customer/tracking/all?product=cos');
	let __window : any = window
	var sensors = __window['sensorsDataAnalytic201505'];
    sensors.init({
      // server_url: 'https://test-syg.datasink.sensorsdata.cn/sa?token=27f1e21b78daf376&project=lixiang',
      server_url: host + apiConfig.trackingService + 'customer/tracking/all?product=cos',
      //heatmap_url神策分析中点击分析及触达分析功能代码，代码生成工具会自动生成。如果神策代码中 `sensorsdata.min.js` 版本是 1.9.1 及以上版本，这个参数必须配置，低于此版本不需要配置。
      heatmap_url: "https://cdn.jsdelivr.net/npm/sa-sdk-javascript@1.18/heatmap.min.js",
      is_track_single_page: true,
      // 使用客户端时间。埋点中会多 time、_flush_time
      use_client_time: true,
      send_type:'beacon',

      heatmap: {
        useCapture: true,
        // //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: 'not_collect',
        // //是否开启触达注意力图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: 'default',
        scroll_event_duration: 1800, //秒，预置属性停留时长 event_duration 的最大值。默认5个小时，也就是300分钟，18000秒
        // scrollmap: {},
        // 自动采集带有此属性的元素的点击事件。sdk已预设的有 data-sensors-click
        // track_attr: ['hotrep', 'anotherprop', "data-prop2"],
        collect_tags: {
          // 默认采集 a、button、input 、textarea 标签 的点击事件
          // div: true,
          // li: true,
          // img: true,
          // svg: true
          // ... 其他标签
        },
        //自定义是否收集元素的点击事情。此参数针对预置 $WebClick 事件（包括 quick('trackHeatMap') quick('trackAllHeatMap') 触发的）生效。
        collect_element: function(element_target:any){
            // 如果这个元素有属性 track-disable=true 时候，不采集。
            if(element_target.getAttribute('track-disable') === 'true'){
                console.info("collect_element... contains  trackable ");
                return false;
            }

            return true;
        },
        // 为$WebClick增加自定义属性。此参数针对预置 $WebClick 事件（包括 quick('trackHeatMap') quick('trackAllHeatMap') 触发的）生效。
        custom_property:function( element_target:any ){
          //比如您需要给有 data=test 属性的标签的点击事件增加自定义属性 name:'aa' ，则代码如下：
          // if(element_target.getAttribute('data') === 'test'){
          //     return {
          //         name:'aa'
          //     }
          // }
          var cp:any = {};
          // 将所有data- 开头的属性及值，记录到track参数中
          let attrs = element_target.attributes;
          element_target.getAttributeNames().filter((attr:any) => attr.startsWith("data-"))
          .forEach((e:any, _i:any) => {
            // console.log("" + e + "/" + i + "/" + attrs[e]);
            cp[e] = attrs[e].value;
          });
          return cp;
        },
      },
      // TODO 渠道相关，待实现。UTM业界标准参数大致为 utm_source utm_medium utm_campaign utm_content utm_term
      // source_channel: [],
      // source_type: [],
      // TODO 预置属性是否采集
      // preset_properties: {},
      show_log: true
    });
    //注册公共属性 注意：这里只能在init时一次性的取一次值，即使调用方法、storage，以后值也不变
    sensors.registerPage({
      sdk: "sensors",
      product: "cos",
      platform_type: "web",
      merchantId: '100000005',  //FIXME
      storeId: 10,              //FIXME
      // customerId: this.localStorage.getItem('customer_id', uid); //FIXME
      // current_url: location.href, //同 $url
      "scene": localStorage.getItem("efs_tracking_scene"), //为了兼容小程序scene值的后端逻辑
      // "experimentId": this.getStoragedAbId(), //稍后由abTracking获取abtest数据后通过registerPage设置
      // "versionName": this.getStoragedAbVersion(),
      "token": (localStorage.getItem('jwt') || localStorage.getItem('cloud_jwt')),// 待用户login后，再register一次
    });
    sensors.quick('autoTrack');
    let did = sensors.getPresetProperties()._distinct_id; //FIXME 临时兼容v0.1已有版本和神策sdk的unqueId
    localStorage.setItem("efs_tracking_unionId", did);
  }

  //将ab测试信息设置到 sensors 页面埋点配置中
  public setPageInfoWithAb(abid:any, abVersion:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      console.info("begin setPageInfoWithAb in browser ...");
      this.script.load("sensors").then((_data:any) => {
        this._initSensors();
		let __window : any = window
        var sensors = __window['sensorsDataAnalytic201505'];
        sensors.registerPage({
          "experimentId": abid,
          "versionName": abVersion,
        });
      }).catch(error => console.log(error));

    }
  }

  //调用 sensors 埋点接口
  public track(event:any, params:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      console.info("begin track in browser ...");
      this.script.load("sensors").then((_data:any) => {
		let __window : any = window
        var sensors = __window['sensorsDataAnalytic201505'];
        sensors.track(event, params);
      }).catch(error => console.log(error));
    } else {
      console.info("begin registerSensors but not in browser ...");
    }
  }

}
