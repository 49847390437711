<div class="grid grid-cols-12 gap-x-15 md:gap-x-5 col-span-12 mt-50 px-0 md-px-20">
  <div class="col-span-12 px-16 md-mb-0 md-px-0">
    <div class="lg-flex lg-w-full lg-flex-row -mt-navbar-height">
      <div class="mt-40 flex justify-center lg-px-16 w-full lg-w-50">
        <div class="w-full lg-w-50">
          <h1 class="mb-40 font_s_medium store-h1" data-testid="heading">门店位置</h1>
          <div class="relative w-full font_small_xs_regular">
            <div class="m-input entry-item border-b-none">
              <span>中国</span>
            </div>
          </div>
          <div class="relative w-full" >
            <div class="m-input entry-item" (click)="openCityList()">
              <div class="flex h-full w-full justify-between">
                <div class="flex flex-col justify-between">
                  <label class="text-left font-size-12">城市</label>
                  <span>{{showCityName}}</span>
                </div>
                <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-down"></use></svg>
              </div>
            </div>
            <ul class="city-container overflow-auto a-border absolute border-t-none" [ngStyle]="{'display':showCity?'block':'none'}">
              <li class="w-full" *ngFor="let city of cityList">
                <button (click)="showCityStores(city.cityId,city.name)" class="w-full px-15 py-16 text-left font_small_xs_regular" type="button">{{city.name}}</button>
              </li>
            </ul>
          </div>
          <div class="no-scrollbar mt-20 flex flex-col gap-16 overflow-y-auto hidden lg-block">
            <div class="flex flex-col gap-20 font_small_xs_regular pt-15 pb-10 store-block mb-20" *ngFor="let store of storeList">
              <h2 class="font_small_xs_regular">{{store.name}}</h2>
              <label>{{store.address.streetName1}}</label>
              <div *ngIf="store.address">
                <label class="w-full" *ngIf="store.address.streetName2">{{store.address.streetName2}}</label>
                <label class="w-full">{{store.address.postCode}} {{store.address.postalAddress}} {{store.address.state}}</label>
                <a class="w-full" [href]="'tel://'+store.phone" target="_self">{{store.phone}}</a>
              </div>
              <div *ngIf="store.openingHours">
                <label *ngFor="let openingHour of store.openingHours" class="w-full">{{openingHour.name + ' ' + openingHour.opens + ' - ' + openingHour.closes}}</label>
              </div>
              <a class="flex" (click)="gotoStore(store)">
                <svg class="inline h-16 w-16 fill-current">
                  <use href="/assets/iconSprite.svg#geotag"></use>
                </svg>
                <span class="font_xs_regular ml-5">前往门店</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="map-block w-full pb-8 pt-30 lg-sticky lg-py-0 md-mt-30" id="gaodemap-container">
      </div>
      <div class="flex justify-center lg-px-16 w-full lg-w-50 lg-hidden">
        <div class="no-scrollbar flex flex-col gap-16 overflow-y-auto w-full">
          <ng-container *ngFor="let store of storeList; let i= index;">
            <div class="flex flex-col gap-20 font_small_xs_regular pt-15 pb-10" [ngClass]="{'store-block': i>0}">
              <h2 class="font_small_xs_regular">{{store.name}}</h2>
              <label>{{store.address.streetName1}}</label>
              <div *ngIf="store.address">
                <label class="w-full" *ngIf="store.address.streetName2">{{store.address.streetName2}}</label>
                <label class="w-full">{{store.address.postCode}} {{store.address.postalAddress}} {{store.address.state}}</label>
                <a class="w-full underline-text" [href]="'tel://'+store.phone" target="_self">{{store.phone}}</a>
              </div>
              <div *ngIf="store.openingHours">
                <label *ngFor="let openingHour of store.openingHours" class="w-full">{{openingHour.name + ' ' + openingHour.opens + ' - ' + openingHour.closes}}</label>
              </div>
              <a class="flex" (click)="gotoStore(store, true)">
                <svg class="inline h-16 w-16 fill-current">
                  <use href="/assets/iconSprite.svg#geotag"></use>
                </svg>
                <span class="font_xs_regular ml-5">前往门店</span>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
