
	  <div class="desc">
	  	<div class="desc-grid">
	  		<button class="desc-grid-btn" type="button">
	  			<div class="desc-flex" (click)="change('item-details')">
	  				<h2 class="font_small_xs_regular" data-testid="heading">细节 & 描述</h2>
	  				<svg class="desc-svg"><use href="/assets/iconSprite.svg#chevron-right"></use></svg>
	  			</div>
	  		</button>
	  	</div>

	  	
	  	<div class="desc-grid">
	  		<button class="desc-grid-btn" type="button">
	  			<div class="desc-flex" (click)="change('payment-delivery')">
	  				<h2 class="font_small_xs_regular" data-testid="heading">支付 & 配送</h2>
	  				<svg class="desc-svg"><use href="/assets/iconSprite.svg#chevron-right"></use></svg>
	  			</div>
	  		</button>
	  	</div>
		<ng-container *ngIf="hasSizeGuide || !!finalSizeImageUrl">
			<div class="desc-grid">
				<button class="desc-grid-btn" type="button">
					<div class="desc-flex" (click)="change('size-guide')">
						<h2 class="font_small_xs_regular" data-testid="heading">尺码指南</h2>
						<svg class="desc-svg"><use href="/assets/iconSprite.svg#chevron-right"></use></svg>
					</div>
				</button>
			</div>
		</ng-container>
	  	
	  	<div class="desc-grid" style="display: none;">
	  		<button class="desc-grid-btn" type="button">
	  			<div class="desc-flex" (click)="change('item-qrcode')">
	  				<h2 class="font_small_xs_regular" data-testid="heading">二维码</h2>
	  				<svg class="desc-svg"><use href="/assets/iconSprite.svg#chevron-right"></use></svg>
	  			</div>
	  		</button>
	  	</div>
	  	
	  	<div class="desc-grid">
	  		<button class="desc-grid-btn" type="button">
	  			<div class="desc-flex">
	  				<svg class="desc-favorite-svg" (click)="removeFavorites(productModel.itemId,productModel.valueId)" *ngIf="favoriteItemIds[productModel.itemId+'_'+productModel.valueId]; else notin">
	  					<use href="/assets/iconSprite.svg#wishlist-active"></use>
	  				</svg>
	  				<ng-template #notin>
	  					<svg class="desc-favorite-svg" (click)="appendFavorites(productModel.ccSku,productModel.itemId,productModel.valueId)">
	  						<use href="/assets/iconSprite.svg#wishlist"></use>
	  					</svg>
	  				</ng-template>
	  				<span (click)="removeFavorites(productModel.itemId,productModel.valueId)" class="font_small_xs_regular visible-title" *ngIf="favoriteItemIds[productModel.itemId+'_'+productModel.valueId]; else notInLabel">取消收藏</span>
	  				<ng-template #notInLabel>
	  					<span (click)="appendFavorites(productModel.ccSku,productModel.itemId,productModel.valueId)" class="font_small_xs_regular visible-title add-favorite">收藏</span>
	  				</ng-template>
	  			</div>
	  		</button>
	  	</div>
	  </div>