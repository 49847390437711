<div class="my-account-content u-clearfix ng-scope">
  <div class="o-my-details summary">
    <div class="o-my-account-Logout o-my-account-success-Logout">
      <div class="warning text-center">
        <img src="../../../assets/images/Logout/icon_02.png" alt="图片" class="icon">
        <h2 class="a-paragraph">注销成功</h2>
      </div></div>
  </div>
</div>

