export const RouterLinks: any = {
  checkout: function (id:any) {
    const defineroutes:any = {
      1: '/checkout'
    };
    return (defineroutes[id] === undefined) ? defineroutes[1] : defineroutes[id];
  },
  base_url: function (id:any) {
    const defineroutes:any = {
      1: 'http://www.ev.saas.cn/',
    };
    return (defineroutes[id] === undefined) ? defineroutes[1] : defineroutes[id];
  }
};
