import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {InitializeService} from './initialize.service';
import {ScriptService} from './script.service';
import {isPlatformBrowser} from '@angular/common';
import {StorageService} from './storage.service';
 

declare const Tracking: any;

@Injectable()
export class TrackingcodeService {
  public storeId:any;
  public currentPagePath:any;
  constructor(private localStorage: StorageService,public init: InitializeService, public script: ScriptService, @Inject(PLATFORM_ID) private platformId: Object) {
  }

  /*注册*/
  register() {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.register();
      }).catch(error => console.log(error));
    }
  }

  /*首页*/
  home(trackdata:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.home(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*cart*/
  cart(trackdata:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.shoppingcart(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*checkout*/
  checkout(trackdata:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.checkout(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*complete*/
  complete(trackdata:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.ordercomplete(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*listing trackingcode*/
  listing(trackdata:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.listing(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*PDP trackingcode*/
  productview(trackdata:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.productview(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*尺码筛选点击 trackingcode*/
  clickFilterSize(trackdata:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.clickFilterSize(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*列表页快速加购tracking*/
  plpPurchase(trackdata:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.plpPurchase(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*PDP加购tracking*/
  pdpPurchase(trackdata:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.pdpPurchase(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*收藏列表页快速加购tracking*/
  favoritesPurchase(trackdata:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.favoritesPurchase(trackdata);
      }).catch(error => console.log(error));
    }
  }

  /*列表页触发轮播tracking*/
  plpCarousel(trackdata:any) {
    if (isPlatformBrowser(this.platformId)) {
      this.script.load(this.init.trackingJs).then((_data:any) => {
        const trackObject = new Tracking();
        trackObject.plpCarousel(trackdata);
      }).catch(error => console.log(error));
    }
  }
	
	/**
	 * product_list_view
	 */
	gaTrackingProductListView(productItems:any,categoryUrl:any){
		 if (isPlatformBrowser(this.platformId)) {
		   this.script.load(this.init.trackingJs).then((_data:any) => {
			var pageData = this.initGaPageData(categoryUrl);
			var customerData = this.initGaCustomer();
			
			var pageView = {
					event: 'pageview',
					page:pageData,
					customer: customerData,
			};
			
			var gstCustom = {
					event: 'product_list_view',
					page:pageData,
					customer: customerData,
					items:productItems,
					event_category:'',
					event_label:'',
					value:'',
				};
			  const trackObject = new Tracking();
			  trackObject.gaTrackingProductListView(pageView,gstCustom);
		 }); 
		}
	}
	/**
	 * product_detail_view
	 */
	gaTrackingProductDetail(productModel:any){
	  var initGaPageData = this.initGaPageData(this.currentPagePath);
	  if (isPlatformBrowser(this.platformId)) {
		this.script.load(this.init.trackingJs).then((_data:any) => {
		var customerData = this.initGaCustomer();

		var pageView = {
			event: 'pageview',
			page:initGaPageData,
			customer:customerData,
		};
		var gstCustom = {
			event:'product_detail_view',
			page:initGaPageData,
			customer:customerData,
			items: [{
				product_action: 'detail',
				product_id: productModel.itemCode,//款号
				product_article_id: productModel.ccSku,//cc
				product_name: productModel.itemName,
				product_brand: "COS",
				product_category: initGaPageData.page_category_id,
				product_color:  productModel.valueName,
				product_price: productModel.cloudSalePrice,//this.productModel.price
				product_price_original: productModel.cloudPrice,
				product_price_type: productModel.cloudSalePrice < productModel.cloudPrice ? 'sale' :'full price',
			}],
			event_category:'',
			event_label: '',
			value:''
		};
		const trackObject = new Tracking();
		trackObject.gaTrackingPdpView(pageView,gstCustom);
	  }).catch(error => console.log(error));
	}
	}
	
	/**
	 * add_to_bag
	 */
	gaTrackingAddCart(items:any){
		if (isPlatformBrowser(this.platformId)) {
			var routerUrl = this.currentPagePath;
		  this.script.load(this.init.trackingJs).then((_data:any) => {   
			  var pageData = {};
			  if(routerUrl == '/cart'){
				  pageData =  {
					page_id: 'cart',
					page_type: 'cart',
					page_category_path: 'checkout_cart',
					page_category_id: 'checkout',
					site_brand_id: 'COS',
					site_touchpoint: 'DESKTOP',
					region_market: 'CN',
					region_locale: 'zh-cn',
					region_currency: 'CNY' 
				  };
			  }else{
				   pageData = this.initGaPageData(routerUrl);
			  }
			
			var customerData = this.initGaCustomer();
			  
			var pageView = {
				event: 'pageview',
				page:pageData,
				customer:customerData, 
			};
			
			var  gstCustom = {
				event : 'add_to_bag',
				page:pageData,
				customer:customerData,
				items:items,
				event_category:'',
				event_label:'',
				value:'',
			};
			
			const trackObject = new Tracking();
			trackObject.gaTrackingAddToBag(pageView,gstCustom);
		  });
		}
	}

	/**
	 * view_cart
	 * @param {Object} items
	 */
	gaTrackingCart(items:any){
		if (isPlatformBrowser(this.platformId)) {
		  this.script.load(this.init.trackingJs).then((_data:any) => {
				var pageData =  {
				  page_id: 'cart',
				  page_type: 'cart',
				  page_category_path: 'checkout_cart',
				  page_category_id: 'checkout',
				  site_brand_id: 'COS',
				  site_touchpoint: 'DESKTOP',
				 region_market: 'CN',
				 region_locale: 'zh-cn',
				 region_currency: 'CNY' 
				};
			  
				var customerData = this.initGaCustomer();
				
				var pageView = {
					event: 'pageview',
					page: pageData,
					customer: customerData,
				};
			 var gstCustom = {
				event : 'view_cart',
				page: pageData,
				customer: customerData,
				  //(all checkout related variables)
				  // checkout_steps 
				  // To be decided when the checkout flow has been determined.
				  // • Shopping bag: 1
				  // • Checkout page: 2
				  checkout: {
					checkout_steps : 1,
				  },
				 items: items,
				 event_category:'',
				 event_label:'',
				 value:''
			 };
			 
			 const trackObject = new Tracking();
			 trackObject.gaTrackingViewCart(pageView,gstCustom);
		  });
		}
	}
	
	 /**
	  * remove_from_bag
	  * @param {Object} currentItem
	  */
	gaTrackingRemoveCart(currentItem:any){
		if (isPlatformBrowser(this.platformId)) {
		  this.script.load(this.init.trackingJs).then((_data:any) => {
			var pageData =  {
				// (all page related variables)
				page_id: 'cart',
				page_type: 'cart',
				page_category_path: 'checkout_cart',
				page_category_id: 'checkout',
				site_brand_id: 'COS',
				site_touchpoint: 'DESKTOP',
				region_market: 'CN',
				region_locale: 'zh-cn',
				region_currency: 'CNY' 
			};
			var customerData = this.initGaCustomer();
			var pageView = {
				event: 'pageview',
				page: pageData,
				customer: customerData,
			};

			 var  gstCustom = {
				event : 'remove_from_bag',
				page: pageData,
				customer: customerData,
				items:currentItem,
				event_category:'',
				event_label:'',
				value:''
			 };
			 
			 const trackObject = new Tracking();
			 trackObject.gaTrackingRemoveFromBag(pageView,gstCustom);
		  });
		}
	}
	
	/**
	 * homepage
	 */
	gaTrackingStartPage(){
		if (isPlatformBrowser(this.platformId)) {
		  this.script.load(this.init.trackingJs).then((_data:any) => {
			  var gstCustom = {
				  event : 'pageview',
				  page:{
					 page_id: 'startpage',
					 page_type: 'startpage',
					 page_category_path: 'homepage',
					 page_category_id: 'Homepage',
					 site_brand_id: 'COS',
					 site_touchpoint: 'DESKTOP',
					 region_market: 'CN',
					 region_locale: 'zh-cn',
					 region_currency: 'CNY'
					},
					event_category:'',
					event_label:'',
					value:''
			  };

			  const trackObject = new Tracking();
			  trackObject.gaTrackingStartPage(gstCustom);
			});
		}
	}
	
	/**
	 * @param {Object} checkoutItems
	 */
	gaTrackingCheckout(checkoutItems:any){
	  if (isPlatformBrowser(this.platformId)) {
		this.script.load(this.init.trackingJs).then((_data:any) => {
			var pageData =  {
				// (all page related variables)
				 page_id: 'checkout',
				 page_type: 'checkout',
				 page_category_path: 'checkout_checkout',
				 page_category_id: 'checkout',
				 site_brand_id: 'COS',
				 site_touchpoint: 'DESKTOP',
				 region_market: 'CN',
				 region_locale: 'zh-cn',
				 region_currency: 'CNY' 
			};
							  
			var customerData = this.initGaCustomer();
			var pageView = {
				event: 'pageview',
				page: pageData,
				customer: customerData,
			};
			
			 
			var gstCustom = {
				event : 'view_checkout',
				page: pageData,
				customer: customerData,
				 //(all checkout related variables)
				 // checkout_steps 
				 // To be decided when the checkout flow has been determined.
				 // • Shopping bag: 1
				 // • Checkout page: 2
				 checkout: {
					checkout_steps : 2,
					checkout_type : 'sign-in',
				 },
				items: checkoutItems,
				event_category:'',
				event_label:'',
				value:''
			};
			
			const trackObject = new Tracking();
			trackObject.gaTrackingViewCheckout(pageView,gstCustom);
		});
	  }
	}
	// 向google推送支付成功订单信息
	gaTrackingConfirmOrder(itemsArray:any,order:any){
		if (isPlatformBrowser(this.platformId)) {
		  this.script.load(this.init.trackingJs).then((_data:any) => {
			  var pageData =  {
				 // (all page related variables)
				 page_id: 'order confirmation',
				 page_type: 'checkout',
				 page_category_path: 'checkout_order',
				 page_category_id: 'purchase',
				 site_brand_id: 'COS',
				 site_touchpoint: 'DESKTOP',
				 region_market: 'CN',
				 region_locale: 'zh-cn',
				 region_currency: 'CNY' 
			  };
							  
			  var customerData = this.initGaCustomer();
			  var pageView = {
				event: 'pageview',
				page: pageData,
				customer: customerData,
			  };
			  
			  
			var gstCustom = {
				event : 'purchase',
				page: pageData,
				customer: customerData,
				 checkout: {
					checkout_type : 'sign-in',
				 }, 
				order:order,
				items: itemsArray,
				event_category:'',
				event_label:'',
				value:''
			};
			
			const trackObject = new Tracking();
			trackObject.gaTrackingPurchase(pageView,gstCustom);
		  });
		}
	}
	
	/**
	 * search result
	 * @param {Object} total
	 * @param {Object} keyword
	 */
	gaTrackingInternalSearch(keyword:any,total:any){
		if (isPlatformBrowser(this.platformId)) {
		  this.script.load(this.init.trackingJs).then((_data:any) => {
			 var customerData = this.initGaCustomer();
			 var  gstCustom = {
				 event : 'pageview',
				 page: {
					 // (all page related variables)
					 page_id: 'search result',
					 page_type: 'search',
					 page_category_path: 'search',
					 page_category_id: 'search',
					 site_brand_id: 'COS',
					 site_touchpoint: 'DESKTOP',
					 region_market: 'CN',
					 region_locale: 'zh-cn',
					 region_currency: 'CNY'
				  },
				 customer: customerData,
				 search: {
					search_term : keyword,
					search_result : total,
					search_type : "TEXT SEARCH"
				 },
				 event_category:'',
				 event_label:'',
				 value:''
			 };
			 
			 const trackObject = new Tracking();
			 trackObject.gaTrackingInternalSearch(gstCustom);
		  });
		}
	}
	
	gaTrackingFavorite(favoriteItems:any){
		if (isPlatformBrowser(this.platformId)) {
		  this.script.load(this.init.trackingJs).then((_data:any) => {
			  var customerData = this.initGaCustomer();
				var pageData =  {
					   // (all page related variables)
						page_id: 'my favourites',
						page_type: 'favourites',
						page_category_path: 'favourites',
						page_category_id: 'favourites',
						site_brand_id: 'COS',
						site_touchpoint: 'DESKTOP',
						region_market: 'CN',
						region_locale: 'zh-cn',
						region_currency: 'CNY' 
				   };
					 
			  var gstCustom = {
					event : 'pageview',
					page:pageData,
					customer:customerData,
					items: favoriteItems,
					event_category:'',
					event_label: '',
					value:''
			  };

			  var pageView = {
			  	event: 'pageview',
			  	page:pageData,
			  	customer:customerData, 
			  };
				
			  const trackObject = new Tracking();
			  trackObject.gaTrackingFavorite(pageView,gstCustom);
		  });
		}		
	}
	
	
	/**
	 * 组装pageview
	 */
	public initGaPageData(routerUrl:any){
			var urlString = routerUrl;
			var page_category_path = '';
			var urlArr = [];
			var page_type = '';
			//首页
			if(urlString == '/'){
				page_category_path = 'home';
				page_type = 'home';
			}else{
				//列表页&商品详情页&.html
				if(urlString.indexOf('.html') !== -1){
					urlArr = urlString.split('/').splice(1);
					if(urlArr.length <= 1){
						page_category_path = urlArr[0].split('.html')[0];
						page_type = page_category_path;
					}else{
						urlArr.pop();	
						for(var i=0;i<urlArr.length;i++){
							if(i==0){
								page_category_path +=urlArr[i];
							}else{
								page_category_path +='_'+urlArr[i];
							}
						}	
						var arrLen = urlArr.length;
						page_type = urlArr[arrLen-1];
					}

				}else{
					//自定义页面
					urlArr = urlString.split('/');
					page_category_path = urlArr[1].replaceAll('-','_');
					page_type = page_category_path;
				}	
			}
		  
			var pageData =  {
				// (all page related variables)
				page_id: page_category_path,
				page_type:page_type,
				page_category_path: page_category_path,
				page_category_id: page_category_path,
				site_brand_id: 'COS',
				site_touchpoint: 'DESKTOP',
				region_market: 'CN',
				region_locale: 'zh-cn',
				region_currency: 'CNY' 
			};
	 
		  return pageData;
	}
	
	public initGaCustomer(){
		var customerData = {
			customer_bpid: this.init.isLogin() ? this.localStorage.getItem('cloud_customer_id') : '' ,
			customer_country: '',
			customer_state: '',
			customer_city: '',
			customer_zip: '',
			customer_logged_in: this.init.isLogin() ? true : false,
		};
		return customerData;
	}
	/**
	 *  Login or register events
	 */
	trackingLoginRegister(pageData:any,funnelStep:any){
	  if (isPlatformBrowser(this.platformId)) {
		this.script.load(this.init.trackingJs).then((_data:any) => {
		var customerData = this.initGaCustomer();
		var  funnel = {
			 funnel_step: funnelStep,
			 funnel_location: pageData.page_id,
			 funnel_option: ''
		};
			 
		var gstCustom = {
			event:'funnel',
			page:pageData,
			customer:customerData,
			funnel:funnel
		};
		const trackObject = new Tracking();
		trackObject.gaTrackingLoginRegister(gstCustom);
	  }).catch(error => console.log(error));
	}
	}
	
	gaTrackingManageFavourites(items:any,eventType:any){
		var currentUrl = this.currentPagePath;
		var pageData = this.initGaPageData(currentUrl);
		var customerData = this.initGaCustomer();
		var gstCustom = {
			event:eventType,
			page:pageData,
			items:items,
			customer:customerData
		};
		
		var pageView = {
			event: 'pageview',
			page:pageData,
			customer:customerData, 
		};
 
		const trackObject = new Tracking();
		trackObject.gaTrackingManageFavourites(pageView,gstCustom);
	}
	
	gaTrackingProductClick(items:any){
		 var currentUrl = this.currentPagePath;
		 var pageData = this.initGaPageData(currentUrl);
		 var customerData = this.initGaCustomer();
		 
		 var gstCustom = {
			event:'product_click',
			page:pageData,
			items:items,
			customer:customerData
		 };
		 
		 const trackObject = new Tracking();
		 trackObject.gaTrackingProductClick(gstCustom);
	}
	
	
	
	gaTrackingLoginRegister(funnelStep:any){
		var currentUrl = this.currentPagePath;
		var initGaPageData = this.initGaPageData(currentUrl);
		this.trackingLoginRegister(initGaPageData,funnelStep);
	}
	
	gaTrackingMenuClick(actionType:any,actionChoice:any){
		var initGaPageData = this.initGaPageData('/home');
		var  actionData = {
			 action_name: 'navigation click',
			 action_type: actionType,
			 action_choice: actionChoice
		};
		
		var customerData = this.initGaCustomer();
		var gstCustom = {
			event:'click',
			page:initGaPageData,
			customer:customerData,
			action:actionData
		};
		
		this.script.load(this.init.trackingJs).then((_data:any) => {
			const trackObject = new Tracking();
			trackObject.gaTrackingMenuClick(gstCustom);
		}).catch(error => console.log(error));
	} 
 
 
	public getCategoryByFrontUrl(frontPathUrl:any){
		//["product","thick-soled-leather-sneakers","0635823001","html"]
		var itemLinkArr = frontPathUrl.split('.');
		var itemLinkStr = itemLinkArr[1].split('-');
		var itemLinkLen = itemLinkStr.length;
		var index = itemLinkLen - 1;
		return itemLinkStr[index];
	}
}
