import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {FavoriteModel} from '../models/favorite.model';
import $ from 'jquery';
const apiConfig = require('../config/config.api.json');

@Injectable()
export class FavoriteService {
  public favoritePageSize:any = 6;
  public favoriteCurrentPage:any = 1;
  public favoriteModel!: FavoriteModel;
  public favoriteList:any = [];
  public currentFavoriteList:any = [];
  public favoriteProductList:any = [];
  public loadLock:any = true;

  constructor(private request: RequestService,
              private init: InitializeService) {
  }

  public addItemFavorite(itemCode:any,itemId:any,valueId:any){
    let paramsJson:any = {frontCode:itemCode,itemId:itemId,valueId:valueId,merchantId:this.init.merchantId,storeId:this.init.cloudStoreId};
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudItemFavorites, paramsJson, false, false, true).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }


  /**
   * 用户收藏列表
   * @param params
   * @returns {Promise<T>}
   */
  favoritesList(pageSize:any,pageNo:any){
    this.currentFavoriteList = [];
    return new Promise((resolve) =>{
      const time = new Date().valueOf();
      let favoritesListUrl = apiConfig.cloudItemFavorites + 'list?merchant_id='+this.init.merchantId+'&page_no='+pageNo+'&page_size='+pageSize+'&store_id='+this.init.cloudStoreId;
      favoritesListUrl += '&' + time;
      this.request.get(favoritesListUrl).toPromise().then(
        (res:any) => {
          // 判断是否最后一页，如果不是设置loadLock = false
          const pageCount = Math.ceil(res['data'].total / this.favoritePageSize);
          if (this.favoriteCurrentPage < pageCount) {
            this.loadLock = false;
          }
          this.favoriteModel = new FavoriteModel( res['data'] );
          for (const number in  res['data'].content) {
            //仅显示有效商品
            if(res['data'].content[number]['inValid'] !== 1){
              const key =  res['data'].content[number]['itemId'] + '-' +  res['data'].content[number]['favoritesId'];
              if (this.favoriteProductList.length > 0) {
                if ($.inArray(key, this.favoriteProductList) == -1) {
                  this.favoriteProductList.push(key);
                  this.favoriteList.push(res['data'].content[number]);
                  this.currentFavoriteList.push(res['data'].content[number]);
                }
              } else {
                this.favoriteProductList.push(key);
                this.favoriteList.push(res['data'].content[number]);
                this.currentFavoriteList.push(res['data'].content[number]);
              }
            }
          }
          resolve(res);
        }
      ).catch(error => error);
    });
  }
 
  
  /**
   *  /customer/item_favorites/
   * 用户收藏IDS
   * @param params
   * @returns {Promise<T>}
   */
  pdpFavorites(itemId:any,valueId:any){
    return new Promise((resolve) =>{
  	let itemfavoriteUrl = apiConfig.cloudItemFavorites + 'pdp_favorites?item_id='+itemId+'&value_id='+valueId+'&merchant_id='+this.init.merchantId+'&store_id='+this.init.cloudStoreId;
  	this.request.get(itemfavoriteUrl).toPromise().then(
  	  res => {
  		resolve(res);
  	  }
  	).catch(error => error);
    });
  }
  
	/**
	 *  /customer/item_favorites/
	 * 用户收藏IDS
	 * @param params
	 * @returns {Promise<T>}
	 */
	getFavoriteIds(){
	  return new Promise((resolve) =>{
		let favoritesListUrl = apiConfig.cloudItemFavorites + 'all_favorites_id?merchant_id='+this.init.merchantId+'&store_id='+this.init.cloudStoreId;
		this.request.get(favoritesListUrl).toPromise().then(
		  res => {
			resolve(res);
		  }
		).catch(error => error);
	  });
	}


  delFavoriteItem(favoriteId:any,params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.put(apiConfig.cloudItemFavorites+favoriteId,params,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  clearFavoriteResult(){
    this.favoriteCurrentPage = 1;
    this.favoriteProductList = [];
    this.favoriteList = [];
  }

}
