import {Component, OnInit, OnDestroy, Inject, PLATFORM_ID, ChangeDetectorRef} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {CallComponentService} from '../../services/callcomponent.service';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import $ from 'jquery';
@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.css']
})
export class SelectCountryComponent implements OnInit, OnDestroy {
  public showBox = false;
  public showLists = true;
  public showConfirm = false;
  public currentItem:any = {};
  public countryLists = [
    // {'region': 'Africa', 'lists':[{'name':'Kenya', 'icon':'USD', path:'https://www.cos.com/en/index.html'}, {'name':'Morocco', 'icon':'USD', path:'https://www.cos.com/en/index.html'}, {'name':'Nigeria', 'icon':'USD', path:'https://www.cos.com/en/index.html'}, {'name':'South Africa', 'icon':'USD', path:'https://www.cos.com/en/index.html'}]},
    {'region': 'Asia', 'lists':[
        // {'name':'Bangladesh', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Brunei', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Bhutan', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Cambodia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'China', 'icon':'CNY', path:'https://www.cos.cn/'},
        // {'name':'Hong Kong SAR', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'India', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Indonesia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Japan', 'icon':'JPY', path:'https://www.cos.com/ja-jp/'},
        // {'name':'Macao SAR', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Malaysia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Maldives', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Mongolia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Philippines', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Korea', 'icon':'KRW', path:'https://www.cos.com/ko-kr/index.html'},
        // {'name':'Taiwan China', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Thailand', 'icon':'THB', path:'https://th.cos.com/'},
        // {'name':'Vietnam', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
      ]
    },
    {'region': 'Europe', 'lists':[
        // {'name':'Andorra', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Austria', 'icon':'EUR', path:'https://www.cos.com/de_at/index.html'},
        {'name':'Belgium', 'icon':'EUR', path:'https://www.cos.com/en-eu'},
        {'name':'Bulgaria', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Czechia', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Croatia', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Cyprus', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Denmark', 'icon':'DKK', path:'https://www.cos.com/en_dkk/index.html'},
        {'name':'Estonia', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        // {'name':'Faroe Islands', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Finland', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'France', 'icon':'EUR', path:'https://www.cos.com/fr_fr/index.html'},
        {'name':'Germany', 'icon':'EUR', path:'https://www.cos.com/de_de/index.html'},
        // {'name':'Georgia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Gibraltar', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Greece', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        // {'name':'Guernsey', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Hungary', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        // {'name':'Iceland', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Ireland', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Italy', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        // {'name':'Jersey', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Latvia', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        // {'name':'Liechtenstein', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Lithuania', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Luxembourg', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Netherlands', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Norway', 'icon':'NOK', path:'https://www.cos.com/en_nok/index.html'},
        {'name':'Poland', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Portugal', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Romania', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        // {'name':'San Marino', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        // {'name':'Serbia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'Slovakia', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Slovenia', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Spain', 'icon':'EUR', path:'https://www.cos.com/en_eur/index.html'},
        {'name':'Sweden', 'icon':'SEK', path:'https://www.cos.com/en_sek/index.html'},
        {'name':'Switzerland', 'icon':'CHF', path:'https://www.cos.com/en_ch/index.html'},
        // {'name':'Türkiye', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
        {'name':'United Kingdom', 'icon':'GBP', path:'https://www.cos.com/en_gbp/index.html'},
      ]},
      {'region': 'Middle East', 'lists':[{'name':'United Arab Emirates', 'icon':'AED', path:'https://ae.cosstores.com/en/'},
          // {'name':'Bahrain', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          {'name':'Saudi Arabia', 'icon':'SAR', path:'https://sa.cosstores.com/ar/'},
          {'name':'Kuwait', 'icon':'KWD', path:'https://kw.cosstores.com/en/'},
      ]},
      {'region': 'North & South America', 'lists':[
          // {'name':'Argentina', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Barbados', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          {'name':'Canada', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          {'name':'Canada', 'icon':'USD', path:'https://www.cosstores.com/ca/en/'},
          // {'name':'Chile', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Costa Rica', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Ecuador', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'El Salvador', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Greenland', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Guatemala', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Honduras', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Jamaica', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Mexico', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Peru', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Puerto Rico', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'St. Kitts & Nevis', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Suriname', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          {'name':'United States', 'icon':'USD', path:'https://www.cos.com/en_usd/index.html'},
          // {'name':'Uruguay', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
      ]},
      {'region': 'Oceania', 'lists':[
          // {'name':'American Samoa', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          {'name':'Australia', 'icon':'AUD', path:'https://www.cos.com/en-au/men'},
          // {'name':'Cook Islands', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Fiji', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Micronesia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'French Polynesia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Guam', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Kiribati', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Marshall Islands', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Northern Mariana Islands', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'New Caledonia', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          {'name':'New Zealand', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Palau', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Samoa', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Solomon Islands', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Tonga', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Tuvalu', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
          // {'name':'Wallis & Futuna', 'icon':'USD', path:'https://www.cos.com/en/index.html'},
      ]},
  ];
  public showCountry = false;
  public currentIndex = -1;
  constructor(public init: InitializeService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              public callComponentService: CallComponentService) {
  }

  ngOnInit() {
    this.callComponentService.selectCountryBox.subscribe((value:any) => {
      this.showBox = true;
      this.showLists = true;
      this.showConfirm = false;
      if (isPlatformBrowser(this.platformId)) {
        // // 获取HTML根元素
        // const htmlElement:  HTMLHtmlElement | null = document.querySelector('html');
        // // 添加行内样式
        // // @ts-ignore
        // htmlElement?.style.overflow = 'hidden'

        $('body').addClass('body_overflow_hidden');
      }
    });
  }

  selectRegion(i:any){
    if(this.currentIndex !=i){
      this.showCountry = true;
    } else {
      this.showCountry = !this.showCountry;
    }
    this.currentIndex = i;
  }

  closeSelect() {
    this.showBox = false;
    this.showCountry = false;
    this.showConfirm = false;
    // // 获取HTML根元素
    // const htmlElement:  HTMLHtmlElement | null = document.querySelector('html');
    // // 添加行内样式
    // // @ts-ignore
    // htmlElement?.removeAttribute('style');
    $('body').removeClass('body_overflow_hidden');
  }

  goto(item:any){
    this.showLists = false;
    this.showConfirm = true;
    this.currentItem = item;
    // window.location.href = item.path;
  }

  confirm(){
    window.location.href = this.currentItem.path;
  }

  ngOnDestroy() {
    this.callComponentService.selectCountryBox.unsubscribe();
  }

}
