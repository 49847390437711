import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductComponent} from './pages/product/product.component';
import {ShoppingCartComponent} from './pages/shopping-cart/shopping-cart.component';
import {ForgetPasswordComponent} from './pages/account-forget-password/forget-password.component';
import {AccountResetPasswordComponent} from './pages/account-reset-password/account-reset-password.component';
import {AccountOrderDetailComponent} from './pages/account-order-detail/account-order-detail.component';
import {SearchComponent} from './pages/search/search.component';
import {AccountEditComponent} from './pages/account-edit/account-edit.component';
import {AccountOrderComponent} from './pages/account-order/account-order.component';
import {AccountComponent} from './pages/account/account.component';
import {AccountLogoutComponent} from './pages/account-logout/account-logout.component';
import {AccountLogoutCheckComponent} from './pages/account-logout-check/account-logout-check.component';
import {AccountLogoutReasonComponent} from './pages/account-logout-reason/account-logout-reason.component';
import {AccountLogoutSuccessComponent} from './pages/account-logout-success/account-logout-success.component';
import {CategoryListComponent} from './pages/category-list/category-list.component';
import {DeliveryAddressComponent} from './components/account/delivery-address/delivery-address.component';
import {CheckoutComponent} from './pages/checkout/checkout.component';
import {RmaApplyComponent} from './pages/rma-apply/rma-apply.component';
import {RmaViewComponent} from './pages/rma-view/rma-view.component';
import {StoreLocatorComponent} from './pages/store-locator/store-locator.component';
import {ChangePasswordComponent} from './components/account/change-password/change-password.component';
import {CategoryUrlMatcher} from './pages/category-list/category-list.url.matcher';
import {ProductUrlMatcher} from './pages/product/product.url.matcher';
import {ShippingToComponent} from './pages/shipping-to/shipping-to.component';
import {CmsAdvancedComponent} from './pages/cms-advanced/cms-advanced.component';
import {VmToolsLoginComponent} from './pages/vm-tools-login/vm-tools-login.component';
import {CanNotGoToNext} from './components/common/sign-in/sign-in.component';
import {LoginGuard} from './components/guard/login.guard';
import {RmaAccountListComponent} from './pages/rma-account-list/rma-account-list.component';
import {RmaReturnComponent} from './pages/rma-return/rma-return.component';
import {PaymentComponent} from './pages/payment/payment.component';
import {PaymentSuccessComponent} from './pages/payment-success/payment-success.component';
import {RmaAccountListDetailComponent} from './pages/rma-account-list-detail/rma-account-list-detail.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {SystermExceptionComponent} from './pages/systerm-exception/systerm-exception.component';
import {AccountLogoutErrorComponent} from './pages/account-logout-error/account-logout-error.component';
import {AccountLogoutProcessingComponent} from './pages/account-logout-processing/account-logout-processing.component';
import {QrCodeComponent} from './pages/qr-code/qr-code.component';
import {AccountCouponComponent} from './pages/account-coupon/account-coupon.component';
import {AccountFavoriteComponent} from './pages/account-favorite/account-favorite.component';
import {AccountPointsComponent} from './pages/account-points/account-points.component';
import {AccountRedeemCouponsComponent} from "./pages/account-redeem-coupons/account-redeem-coupons.component";
import {InvoiceApplyComponent} from './pages/invoice-apply/invoice-apply.component';
import {InvoiceDetailComponent} from './pages/invoice-detail/invoice-detail.component';
import {PresaleCheckoutComponent} from './pages/presale-checkout/presale-checkout.component';
import {AgreementRedirectComponent} from './pages/agreement-redirect/agreement-redirect.component';
import {AgreementAuthorizationComponent} from './pages/agreement-authorization/agreement-authorization.component';
import {LogisticsComponent} from './pages/logistics/logistics.component';
import {CosbyyouImageDetailComponent} from './pages/cosbyyou/image-detail/image-detail.component';
import {UdeskComponent} from './pages/udesk/udesk.component';
import {TompComponent} from './pages/tomp/tomp.component';
import { truncateSync } from 'fs';

const routes: Routes = [
  {
    path: '', component: CmsAdvancedComponent, data: {
    breadcrumb: false,
  }
  },
 {
    path: 'shipping-to', component: ShippingToComponent, data: {
      breadcrumb: false,
  }
  },
  {
    path: 'store-locator', component: StoreLocatorComponent, data: {
    breadcrumb: false,
  }
  },
  {path: 'udesk', component: UdeskComponent},
  {path: 'tomp', component: TompComponent},
  {path: '404', component: NotFoundComponent},
  {path: 'busy', component:  SystermExceptionComponent},

  {
    path: 'cart', component: ShoppingCartComponent, data: {
    breadcrumb: false,
  }
  },
  {
    matcher: ProductUrlMatcher, component: ProductComponent, data: {
      breadcrumb: false,
    }
  },
  {
    matcher: CategoryUrlMatcher, component: CategoryListComponent, data: {
    breadcrumb: false,
  }
  },
  {
    path: 'forget', component: ForgetPasswordComponent, data: {
    breadcrumb: false,
  }, canActivate: [CanNotGoToNext]
  },
  {
    path: 'reset', component: AccountResetPasswordComponent, data: {
    breadcrumb: '修改密码',
  }
  },
  {path: 'rma-view', component: RmaViewComponent},
  {
    path: 'my-account', component: AccountComponent, data: {breadcrumb: false}, children: [
    {path: 'logistics', component: LogisticsComponent},
    {path: 'order', component: AccountOrderComponent},
    {path: 'order/mini', component: AccountOrderComponent},
    {path: 'logout', component: AccountLogoutComponent},
    {path: 'logout-check', component: AccountLogoutCheckComponent},
    {path: 'logout-reason', component: AccountLogoutReasonComponent},
    {path: 'logout-success', component:AccountLogoutSuccessComponent },
    {path: 'logout-error',component:AccountLogoutErrorComponent},
    {path: 'logout-processing',component:AccountLogoutProcessingComponent},
    {path: 'order/orderdetail', component: AccountOrderDetailComponent},
    {path: 'order/mini/orderdetail', component: AccountOrderDetailComponent},
    /*{path: 'address-edit', component: AddressEditComponent},*/
    {path: 'profile', component: AccountEditComponent},
    // {path: 'profile/dev', component: AccountEditComponent},
    {path: 'update-password', component: ChangePasswordComponent},
     // {path: 'change-email', component: ChangeEmailComponent},
    {path: 'address-book', component: DeliveryAddressComponent},
    {path: 'coupon', component: AccountCouponComponent},
    {path:'points',component:AccountPointsComponent},
    {path:'redeem-coupons',component:AccountRedeemCouponsComponent},
    /*{path: 'favorite', component: AccountFavoriteComponent},*/
    {path: 'rma-list', component: RmaAccountListComponent},
    {path: 'rma-detail', component: RmaAccountListDetailComponent},
    {path: 'invoice-apply', component: InvoiceApplyComponent},
    {path: 'invoice-detail', component: InvoiceDetailComponent},
    {path: 'rma-return', component: RmaReturnComponent},
    {path: 'rma/:id', component: RmaApplyComponent},
  ]
  },
  {path: 'my-account/favorite', component: AccountFavoriteComponent,  data: {
      breadcrumb: false,}
  },
  {path: 'search', component: SearchComponent,  data: {
      breadcrumb: false,}
  },
  {
    path: 'checkout', component: CheckoutComponent, data: {
      breadcrumb: false,
    }, canActivate: [LoginGuard]
  },
  {
    path: 'presale-checkout', component: PresaleCheckoutComponent,data: {
      breadcrumb: false,
    }
  },
  {path: 'agreement-redirect', component: AgreementRedirectComponent},
  {path: 'agreement-authorization', component: AgreementAuthorizationComponent},
  {path: 'cosbyyou/image-detail', component: CosbyyouImageDetailComponent},
  {path: 'item/redirect', component: QrCodeComponent},
  {path: 'checkout/payment/:id', component: PaymentComponent, data: {breadcrumb: false}},
  {path: 'payment/success/:id', component: PaymentSuccessComponent, data: {title: '支付成功',  breadcrumb: false,}},

  {path: 'rma-view', component: RmaViewComponent},
/*  {path: 'rma-return', component: RmaReturnComponent},*/
  {
    path: 'vm-tools/login', component: VmToolsLoginComponent , data: {
    breadcrumb: '视觉陈列工具登录',
    title: '视觉陈列工具登录'
  }
  },
  {path: ':id/:iid/:iiid/:iiiid', component: CmsAdvancedComponent,  data: {
      breadcrumb: false,}},
  {path: ':id/:iid/:iiid', component: CmsAdvancedComponent,  data: {
      breadcrumb: false,}},
  {path: ':id/:iid', component: CmsAdvancedComponent,  data: {
      breadcrumb: false,}},
  {path: ':id', component: CmsAdvancedComponent,  data: {
      breadcrumb: false,}},
  {path: '**', component: CmsAdvancedComponent,  data: {
      breadcrumb: false,}}, // 打开的链接不存在时，跳转到首页
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
