import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {CallComponentService} from '../../services/callcomponent.service';
import {StorageService} from '../../services/storage.service';
@Injectable()
export class LoginGuard implements CanActivate {
  constructor(public init: InitializeService,
              private localStorage: StorageService,
              private eventLoginService: CallComponentService) {}
  // 添加路由守卫
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      // 拥有 `admin` 角色
    // let logined: boolean = this.init.userId !== '';
    const path = route.routeConfig?.path;
    let logined: boolean = this.localStorage.getItem('jwt') !== null && path === 'checkout';
    let url = state.url;
      if (!logined) {
        this.eventLoginService.loginRegisterBox.next('1');
        return false;
      } else {
        if (url === '/checkout') {
          this.eventLoginService.loginRegisterBox.next(0);
        }
      }
      return logined;
  }
  // checkouutLogin(url: string): boolean {
  //   if (this.init.userId !== '') {
  //     this.eventLoginService.loginRegisterBox.next('0');
  //     return true;
  //   }
  // }
}
