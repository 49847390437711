import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../services/initialize.service';
import {ProductService} from '../../services/product.service';
import {FavoriteService} from '../../services/favorite.service';
import {MiniCartComponent} from '../mini-cart/mini-cart.component';
import {ProductModel} from '../../models/product.model';
import $ from 'jquery';
import {StorageService} from '../../services/storage.service';
import {AbTrackingService} from '../../services/abtracking.service';
import {CartService} from '../../services/cart.service';
import {Router} from '@angular/router';
import {CallComponentService} from '../../services/callcomponent.service';
import { any } from 'codelyzer/util/function';

@Component({
  selector: 'app-quick-add',
  templateUrl: './quick-add.component.html',
  styleUrls: ['./quick-add.component.css'],
})
export class QuickAddComponent implements OnInit {
  @Input()
  frontCode!:any;
  @Input()
  salePrice!:any;
  @Input() favoriteItemIds = [];
  @ViewChild('mini') miniCart!: MiniCartComponent | undefined;
  @Output() addFavorite = new EventEmitter<object>();
  @Output() delFavorite = new EventEmitter<object>();

  @Output() closeBox = new EventEmitter<any>();
  public favoritesId:any;
  public showBox = false;
  public recProductModel! : ProductModel;
  public productColors : any;
  public productSizeArray:any = [];
  // size集合
  public recProductSizeArray : any = [];
  //显示video
  public showVideo = true;
  public colorImgList : any;
  public includeVideo : boolean = false;//包含视频
  // 隐藏尺码选择 （牛仔裤商品）
  public hideSizeChart : any = false;
  public frontCustomParameters : any;
  public isAdding = false;
  public show_error_box:boolean = false;
  public error_message:any;
  public limitedPurchaseQty = -2;//自定义赋值，表示未调用限购数量查询接口
  public relationLimitSkuIds:any;

  public promotion: boolean= false;
  public paragraph: String = '';

  constructor(
    public init: InitializeService,
	public productService: ProductService,
    public cartService: CartService,
    private localStorage: StorageService,
    private abTracking: AbTrackingService,
    private router: Router,
    public eventSubscribeService: CallComponentService,
	public favoriteService: FavoriteService,

    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit() {
    this.productService.getCloudProductBySku(this.frontCode).then(async (res : any) => {
      if (parseInt(res.code, 0) == 1000) {
        this.recProductModel = new ProductModel(res.data);
        if (this.recProductModel.itemName.indexOf("牛仔裤") != -1 || this.recProductModel.itemName.indexOf("牛仔短裤") != -1) {
          this.hideSizeChart = true;
        }

        // 店铺级商品状态
        this.productColors = this.recProductModel.getColorsCloud(this.frontCode);
        this.productSizeArray = this.recProductModel.sku_list;
        // 获取添加到购物车的ImgURL
        // 获取路由颜色
        if (this.recProductModel.valueId) {
          this.colorImgList = this.recProductModel.getImage(this.recProductModel.valueId);
          this.includeVideo = this.recProductModel.includeVideo;
        }

        // 设置策略价格
        const item = this.recProductModel.productListByValueId[this.recProductModel.valueId];
        this.recProductModel.cloudPrice = item['markingPrice'];
        this.recProductModel.cloudSalePrice = item['price'];

		    this.filterStrategyTag(this.recProductModel.itemId, this.recProductModel.valueId);
		    this.recProductModel.sizeTableImageUrl = this.recProductModel.productListByValueId[this.recProductModel.valueId]['sizeTableImageUrl'];
        //查询限购关联skus
        this.productService.customParameters(this.recProductModel.frontProperties,this.recProductModel.valueId);
        var frontCustomParameters = JSON.parse(this.productService.frontCustomParameters);
        if(!!frontCustomParameters){
			    this.frontCustomParameters = frontCustomParameters;
          if(!!frontCustomParameters.relationLimitSkuIds){
            this.relationLimitSkuIds = frontCustomParameters.relationLimitSkuIds;
          }
        }

        this.showBox = true;
        $('body').addClass('body_overflow_hidden');
      }
    });
  }
	appendFavorites(event:any){
		this.addFavorite.emit(event)
	}

	removeFavorites(event:any){
		this.delFavorite.emit(event);
	}

	async filterStrategyTag(itemId : any, valueId : any) {
		const strategyRes:any = await  this.productService.getProductStrategy(itemId, valueId);
		if (strategyRes['code'] === 1000 && strategyRes['data'].length > 0) {
			//筛选有效期内的策略价标签
			const rulePriceJson = this.productService.strategyTag(strategyRes['data'])
			if (rulePriceJson.hasRule === true) {
				this.recProductModel.cloudSalePrice = rulePriceJson.price;
			}
		}
	}

  changeSize(event: any){
    if(!this.init.isLogin()){
      this.closeLayer();
      this.eventSubscribeService.loginRegisterBox.next('1');
    }else{
      if (Number(event.qty) == 0 || this.isAdding) {
        return;
      }

      this.isAdding = true;
      const quoteId = this.localStorage.getItem('quote_id') || '';
      this.limitPurchaseQty(event.skuId);
      this.productService.getQuoteId(event['skuCode'],event['skuId'],quoteId,'1',event['barcode'],this.recProductModel['itemName']).then(async model => {
        if(model.code == 1000){
          this.cartService.getCartData().then();
          let tempPrice = event.markingPrice;
          let tempSalePrice = event.price;
          let boxPrice = tempPrice;
          let boxSalePrice = tempSalePrice;
          if(tempPrice > tempSalePrice){
            boxPrice = tempPrice;
            boxSalePrice = tempSalePrice;
          }else{
            //策略价
            boxSalePrice = this.recProductModel.cloudSalePrice;
          }
          //查询cloud推荐搭配
          let recommendWhole:any = [];
          const recommends = await this.productService.getProductRecommends(this.recProductModel.itemId, this.recProductModel.valueId);
          if ( recommends.total > 0) {
            recommends.content.forEach((recommendItem:any) => {
              // 搭配推荐
              if(recommendItem['recommendBlock'] == 'whole'){
                recommendWhole.push(recommendItem)
              }
            });
          }
          const pItem = this.recProductModel.productListByValueId[this.recProductModel.valueId];
          const img_url = pItem['hoverUrl'] ? pItem['hoverUrl'] : pItem['skuImageUrl'];
          const cartInfo = {
            'name': this.recProductModel.itemName,
            'price': boxPrice == boxSalePrice ? 0 : boxPrice,
            'salesPrice': boxSalePrice ,
            'img_url': img_url + this.init.imageSize.$4,
            'size': this.productService.sizeConvert(event['valueName']),
            'color': this.recProductModel.valueName,
            'recommends': recommendWhole,
          };
          // this.cartRequest();
          this.abTracking.trackingCart(event['skuId'],this.recProductModel['itemName'],boxSalePrice, event.valueId, this.recProductModel.valueName, event['skuCode'], event.skuImageUrl + this.init.imageSize.$4, {path: this.router.url});
          this.isAdding = false;
          this.closeLayer();
          this.eventSubscribeService.addMimiCartBox.emit(cartInfo);
        } else if(model.code === -1010){
          this.isAdding = false;
          this.promotion = true;
          if(this.limitedPurchaseQty > 0){
            this.paragraph= "该商品每月限购"+this.limitedPurchaseQty+"件";
          }else{
            this.paragraph= "商品超出限购数量";
          }
        }else{
          this.isAdding = false;
          this.promotion = true;
          this.paragraph = model.msg;
        }
      })
    }
  }

  async limitPurchaseQty(skuId:any){
    const val:any = await this.productService.limitPurchase(skuId,this.relationLimitSkuIds);
    if(val.code == 1000){
      this.limitedPurchaseQty = val.data.activityLimitQty;
    }
  }

  closeLayer(){
    $('body').removeClass('body_overflow_hidden');
    this.showBox = false;
    this.closeBox.emit(false);
  }

  closeOverlay() {
    this.promotion = false;
  }
}
