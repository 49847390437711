<!-- 编辑地址替换类：is-closed替换成is-editable -->
<ng-container>
 <!-- [ngClass]="{'is-editable': editable, 'is-closed': !editable-->
  <div data-section-name="o-about-you" class="about-you-section section">
    <div class="flex justify-between font_s_regular section-heading mb-20">
      <span>1.收货信息</span>
      <div class="flex" *ngIf="checkoutAdd.length>0">
        <a href="javascript:void(0);" target="_self" class="a-link change" *ngIf="otherAddress?.length > 0 || defaultAddress?.length > 0"><span class="underline" (click)="changeAddress()">{{otherAddressShow? '取消选择' : '其他地址'}}</span></a>
        <a href="javascript:void(0);" target="_self" class="a-link edit" *ngIf="checkout.checkoutModel?.customerAddressId && checkoutAdd.length > 0 && !editable"><span class="underline" (click)="editaddress()">编辑地址</span></a>
        <a *ngIf="editable" href="javascript:void(0);" target="_self" class="a-link cancel"><span class="underline" (click)="cancelEditAddress()">取消</span></a>
      </div>
    </div>
    <ng-container *ngIf="checkoutAdd.length>0; else addressTemplate;"> <!-- 没有地址直接显示新增表单-->
      <!--默认地址-->
      <div class="section-summary is_optional">
        <ng-container *ngIf="!editable">
          <!--选择的默认地址-->
          <div class="font_s_regular" *ngIf="hideBug">
            <div *ngIf="checkoutLoad" class="customer-data loading" style="text-align: center; margin: 46px auto 0px">
              <div style="width:100%;height: 30px; line-height: 30px; position: relative;">
                <span >加载中......</span>
              </div>
            </div>
            <div *ngIf="!checkoutLoad" class="lg-p-15 md-pl-10">
              <h3 class="font_s_medium a-heading-3 heading-1">{{showSetAddress?.receiverName}}</h3>
              <div class="summary-content customer-data customer-name"><span>手机：{{showSetAddress?.receiverMobile}}</span></div>
              <div class="summary-content customer-data customer-address">
                <span>地址：</span>
                <span class="region">{{showSetAddress?.receiverState}}</span>
                <span class="city">{{showSetAddress?.receiverCity}}</span>
                <span class="area">{{showSetAddress?.receiverDistrict}}</span>
                <span class="street">{{showSetAddress?.receiverAddress}}</span>
              </div>
            </div>
          </div>
        </ng-container>

        <!--其他地址-->
        <ng-container *ngIf="otherAddressShow">
          <div class="font_s_regular mt-20">
            <ng-container *ngFor="let val of otherAddress; let i = index;">
              <div class="flex">
                <div class="radioSection">
                  <input type="radio" id="address_{{val.customerAddressId}}" [checked]="cartAddress?.customerAddressId === val?.customerAddressId" class="a-radio-button" (click)="setshippingAddress('other',val.customerAddressId)" name="delivery-selector">
                  <label class="a-label js-a-label u-no-select custom-radio" for="address_{{val.customerAddressId}}"></label>
                </div>
                <div class="w-full pt-15 pr-15 pb-35 border-gray" style="padding-left: 7.5px; ">
                  <h4 class="a-heading-4 font_s_medium">{{val?.receiverName}}</h4>
                  <div class="summary-content customer-data customer-name"><span>手机：{{val?.receiverMobile}}</span></div>
                  <div class="summary-content customer-data customer-address">
                    <span>地址：</span>
                    <span class="region">{{val?.receiverState}}</span>
                    <span class="city">{{val?.receiverCity}}</span>
                    <span class="area">{{val?.receiverDistrict}}</span>
                    <span class="street">{{val?.receiverAddress}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="flex">
              <div class="radioSection">
                <input type="radio" id="add_address_radio" class="a-radio-button" (click)="addressNew()" name="delivery-selector">
                <label class="a-label js-a-label u-no-select custom-radio" for="add_address_radio"></label>
              </div>
              <div class="w-full a-border p-15" [ngClass]="{'border-b-none': newAddress}">
                <h4 class="a-heading-4 font_s_medium">新增地址</h4>
              </div>
            </div>
            <div class="flex">
              <div class="radioSection" style="padding-right: 33px;">
              </div>
              <div class="w-full">
                <ng-container *ngIf="!newAddress; else addressTemplate;">

                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- 编辑地址 开始 -->
        <div class="customer-data"  *ngIf="editable">
          <ng-container *ngIf="!editable; else addressTemplate;">

          </ng-container>
        </div>
        <!-- 编辑地址 结束 -->
      </div>
    </ng-container>
    <!--新增修改地址 公共表单-->
    <ng-template #addressTemplate>
      <div class="a-border">
        <!--这位置放地址表单-->
        <div class="o-about-you main-area new_address">
          <form>
            <div class="o-form ng-invalid" id="checkoutAddressPartForm1">
              <!-- <div class="m-error js-m-error is-hidden"><label class="a-label js-a-label has-error"></label></div>-->
              <div class="about-you-fields-wrapper">
                <div data-component="MInput" class="m-input entry-item border-b">
                  <ng-container *ngIf="userblock=='block'; else uBlock;">
                    <label class="a-label js-a-label mb-5 tips">{{usernameerrorText}}</label>
                  </ng-container>
                  <ng-template #uBlock>
                    <label class="a-label js-a-label mb-5" *ngIf="formData.usernameVal && formData.usernameVal.length > 0">收货人</label>
                  </ng-template>
                  <input class="p-0 border-none form-input" required placeholder="收货人"  maxlength="150" name="usernameVal"  [(ngModel)]="formData.usernameVal" #formData.usernameVal="ngModel" (focus)="userblock='none'"  (blur)="nameErrorBlur()">
                </div>
                <div data-component="MInput" class="m-input entry-item border-b">
                  <ng-container *ngIf="phoneBlock=='block'; else pBlock;">
                    <label class="a-label js-a-label mb-5 tips">{{phoneValue}}</label>
                  </ng-container>
                  <ng-template #pBlock>
                    <label class="a-label js-a-label mb-5" *ngIf="formData.mobileValue && formData.mobileValue.length > 0">手机号</label>
                  </ng-template>
                  <input placeholder="手机号" class="p-0 border-none" required maxlength="11"  name="mobileValue"   [(ngModel)]="formData.mobileValue" #formData.mobileValue="ngModel" (focus)="phoneBlock='none'" (blur)="MobileValue()" title="手机号">
                </div>
                <div data-component="MInput" class="m_input entry-item border-b" style="padding: 0 !important;">
                  <div class="flex address_details">
                    <div class="no-select-box border-r" [ngClass]="{'select-box': isValidaRegion && formData.province_id==0}">
                      <label class="select-label js-a-label tips" *ngIf="isValidaRegion && formData.province_id==0">请选择</label>
                      <div class="m-dropdown selectBox">
                        <span class="a-icon-close"></span>
                        <select class="addregion" [(ngModel)]="formData.province_id" name="provinceval" (change)="getCities($event.target.value)" required >
                          <option class="a-option" value="0">省份</option>
                          <option class="a-option" *ngFor="let province of provinces" value="{{province.areaCode}}" [selected]="provinces.length === 1 ? 'selected':''">{{province.areaName}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="no-select-box border-r" [ngClass]="{'select-box': isValidaCity && formData.city_id==0}">
                      <label class="select-label js-a-label tips" *ngIf="isValidaCity && formData.city_id==0">请选择</label>
                      <div class="m-dropdown selectBox">
                        <span class="a-icon-close"></span>
                        <select class="addcity" [(ngModel)]="formData.city_id" name="cityval" (change)="getAreas($event.target.value)" required>
                          <option class="a-option" value="0">城市</option>
                          <option *ngFor="let city of cities" value="{{city.areaCode}}" [selected]="cities.length === 1 ? 'selected':''">{{city.areaName}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="no-select-box" [ngClass]="{'select-box': isValidaArea && formData.area_id==0}">
                      <label class="select-label js-a-label tips" *ngIf="isValidaArea && formData.area_id==0">请选择</label>
                      <div class="m-dropdown selectBox">
                        <select class="addarea" [(ngModel)]="formData.area_id" name="areaval" required>
                          <option class="a-option" value="0">区/县</option>
                          <option class="a-option" *ngFor="let area of areas" value="{{area.areaCode}}" [selected]="areas.length === 1 ? 'selected':''">{{area.areaName}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!--<label class="a-label js-a-label label-error" [ngStyle]="{'display':pcaBlock}" data-placeholder="Address1" style="color: #D44511">请选择城市地区（省、市、区/县）</label>-->
                </div>
                <div data-component="MInput" class="m-input entry-item border-b">
                  <ng-container *ngIf="addressBlock=='block'; else aBlock;">
                    <label class="a-label js-a-label mb-5 tips">请填写（街道/号）</label>
                  </ng-container>
                  <ng-template #aBlock>
                    <label class="a-label js-a-label mb-5" *ngIf="formData.streetValue && formData.streetValue.length > 0">地址</label>
                  </ng-template>
                  <input class="p-0 border-none" required placeholder="地址" maxlength="40"  name="streetValue" [(ngModel)]="formData.streetValue" (focus)="addressBlock='none'" (blur)="checkStreetValue()" #formData.streetValue="ngModel" title="地址">
                </div>
              </div>
              <div class="p-15">
                <button type="submit" (click)="confirmSubmit()" class="a-button add-billing-button">
                  <span>继续</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
