<div class="my-account-content u-clearfix ng-scope">
  <div class="o-my-details summary">
    <div class="o-my-account-deletion">
      <div class="deletion-background">
        <div class="Logout-deletion-block text-center">
          <h4 class="a-paragraph">账户删除中...</h4>
          <p class="Logout-deletion-text">正在删除数据</p>
        </div>
      </div>
    </div>
  </div>
</div>

