export class CmsAdvancedPageModel {
  public id!: string;
  public loadTimestamp: any;
  public identifier: any;
  public page_id!: number;
  public title: any;
  public containers: any;
  public preview: any;
  public meta_keywords: any;
  public meta_description: any;
  public is_active!: boolean;

  constructor(data: {}) {
    Object.assign(<CmsAdvancedPageModel>this, data);
  }

  public getHtml(data: any){
    return data[0][0].html;
  }

  public jsonDecodeContainers() {
    let htmlInfo;
    htmlInfo = JSON.parse(this.containers);
    return htmlInfo;
  }
}
