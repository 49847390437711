<div data-section-name="o-delivery" id="checkoutDeliveryPartDiv" class="delivery-section section" *ngIf="showCheckoutRemark && checkoutType !== 'presale'">
  <div class="flex justify-between font_s_regular section-heading mb-20">
    <span>3.订单备注</span>
  </div>
  <div data-component="ODelivery" class="o-delivery main-area has-selected-delivery-option" data-component-id="1b2d9961-3874-44de-a3b1-0fcec1ebad6a">
    <div id="checkoutDeliveryPartForm" class="o-form ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-dirty">
      <div data-component="MRadioButtonSelector" class="m-radio-button-selector">
        <div class="m-radio-button u-clearfix u-float-left w-full">
          <textarea [(ngModel)]="orderRemark.remark" class="w-full" rows="3" cols="40" id="mark" maxlength="100" style="resize:none;border: .5px solid #080808;"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="delivery-section section p-15 md-hidden" style="margin-top: -5px;"  [ngClass]="{'pt-0': !(showCheckoutRemark && checkoutType !== 'presale')}">
    <span class="heading-caption heading-number-02" style="font-size: 11px;line-height: 16px;">温馨提示，即日起订单包裹中将不再附有纸质发货单，如需核实订单详情，可查看“订单详情”或咨询在线客服。</span>
</div>
