import {Component, OnInit, OnDestroy, Inject, PLATFORM_ID} from '@angular/core';
import {RmaService} from '../../services/rma.service';
import {isPlatformBrowser} from '@angular/common';
import $ from 'jquery';
import {InitializeService} from '../../services/initialize.service';
import {OrderService} from '../../services/order.service';

@Component({
  selector: 'app-rma-account-list',
  templateUrl: './rma-account-list.component.html',
  styleUrls: ['./rma-account-list.component.css']
})
export class RmaAccountListComponent implements OnInit, OnDestroy {

  constructor(
    private init: InitializeService,
    public rmaService: RmaService,
    public orderService: OrderService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.init.pageConfiguration('退货订单');
    if (isPlatformBrowser(this.platformId)) {
      this.rmaService.currentPage = 1;
      this.getRefundLst();
    }
  }

  getRefundLst(){
    this.rmaService.getRmaList().then(res => {
      if (res.code * 1 === 1000) {
        this.rmaService.rmaData = res.data;
        this.rmaService.loading = false;
      } else {
      }
    }).catch(error => error);
  }
  /**
   * Handle event from pagination component.
   * @param event
   */
  handlePaginationEvent(event:any, type = null) {
    if (type === 'customize') {
      this.rmaService.currentPage = event;
    } else {
      this.rmaService.currentPage = event.page;
    }
    this.getRefundLst();
    if (isPlatformBrowser(this.platformId)) {
      $('html,body').animate({scrollTop: 0}, 500);
    }
  }

  ngOnDestroy() {
    this.rmaService.loading = true;
    this.rmaService.rmaData = null;
  }

  confirmCancel(){
    this.rmaService.cancelConfirm().then( res => {
      if(res.code === 1000){
        this.rmaService.applyCancel = false;
        this.rmaService.cancelRefundId = '';
        this.getRefundLst();
      }
    }).catch(error => error);
  }
}
