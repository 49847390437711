import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CallComponentService } from '../services/callcomponent.service';

@Injectable({
	providedIn: 'root'
})
export class BreadcrumbService {

	public breadcrumbs : IBreadcrumb[] = [];
	
	public breadcrumbsArray:any = [];
	constructor(public eventSubscribeService : CallComponentService) {
		
	}

	public addBreadcrumb(lable : any, url = '', params = {}) {
		// add breadcrumb
		const breadcrumb : IBreadcrumb = {
			label: lable,
			params: params,
			url: url,
		};
		this.breadcrumbs.push(breadcrumb);
		
		this.breadcrumbsArray = Object.keys(this.breadcrumbs).map(key => {
		  return this.breadcrumbs[key];
		});
		this.eventSubscribeService.renewalCrumbs.next(true); 
	}

	public resetBreadcrumb(_category : any) {
		this.breadcrumbs = [];
		this.breadcrumbsArray = [];
		this.eventSubscribeService.renewalCrumbs.next(true);
		if (!!_category['urlPath']) {
			this.addBreadcrumb(_category['navName'], _category['urlPath']);
		}
	}

	//三级分类导航
	public addChildCateNavBreadcrumb(_category : any) {
		if (!!_category['urlPath']) {
			if (this.breadcrumbs.length == 3) {
				this.breadcrumbs.pop();
			}
			this.addBreadcrumb(_category['navName'], _category['urlPath']);
		}
	}

	//商品详情面包屑
	public addItemBreadcrumb(_item : any) {
		if (!!_item.itemPath) {
			//判断一级面包屑是否为男装，女装
			if(!!this.breadcrumbs && !!this.breadcrumbs[0]){
				if(this.breadcrumbs[0].label == '男装' || this.breadcrumbs[0].label == '女装'){
					this.breadcrumbs.splice(0,1);//移除男装，女装面包屑层级
				}
			}

			this.addBreadcrumb(_item.itemName, _item.itemPath);
		}
	}

	//移除商品详情面包屑
	public removeItemBreadcrumb() {
		this.breadcrumbsArray.pop();
		this.eventSubscribeService.renewalCrumbs.next(true);
	}
	
	public convertBreadcrumbToArray(breadcrumb: IBreadcrumb): IBreadcrumb[] {
	    return [breadcrumb];
	}
}

export interface IBreadcrumb {
	label : string;
	params : Params;
	url : string;
}