import {Component, OnInit, OnDestroy, HostListener, PLATFORM_ID, Inject} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {InitializeService} from '../../services/initialize.service';
import {OrderService} from '../../services/order.service';
import {ProductService} from '../../services/product.service';
import $ from 'jquery';
import {isPlatformBrowser} from '@angular/common';
import {AbTrackingService} from '../../services/abtracking.service';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-account-order',
  templateUrl: './account-order.component.html',
  styleUrls: ['./account-order.component.css']
})
export class AccountOrderComponent implements OnInit, OnDestroy {
  orderStateArr:any = {'未付款': 'pending_payment', '已支付': 'pending', '配送中': 'shipping', '已完成': 'complete', '已关闭': 'closed', '备货中': 'processing', '已取消': 'canceled', '查看全部': 'all'};
  cloudOrderStateArr:any = {'查看全部': 'all','待付款':'WAIT_BUYER_PAY','待发货':'WAIT_SELLER_SEND_GOODS','配送中':'WAIT_BUYER_CONFIRM_GOODS','已完成':'TRADE_FINISHED','已关闭':'TRADE_CLOSED','超时关闭':'TRADE_CLOSED_BY_CLOUD','仓库已收货':'DC_RECEIVED','门店已收货':'RETAIL_STORE_RECEIVED','待付尾款':'BUYER_PART_PAYMENT'}
  monthStateArr:any = {0: '查看全部', 1: '1个月', 3: '3个月', 6: '6个月'};
  modifyPaymentOrderId!: string;
  choicePayMent!: string;
  payType:any = '';
  canceled:any = false;
  show_pc_order:any = true;
  show_miniprogram_order:any = false;
  public channel:string = '官网订单';
  public statusCondition:string = '订单状态';
  public timeCondition:string = '下单时间';
  public newPayMethod!: string;
  public orderListData:any = {total:0,totalPage:1,pageNo:1,pageSize:10,content:{}};
  constructor(
              private router: Router,
              public init: InitializeService,
              public orderService: OrderService,
              private productService:ProductService,
              private localStorage:StorageService,
              public abTracking: AbTrackingService,
              @Inject(PLATFORM_ID) private platformId: Object) {

  }

  ngOnInit() {
    this.init.pageConfiguration('我的订单');
    this.matchPath(this.router.url);
    if (isPlatformBrowser(this.platformId)) {
      this.orderService.currentPage = 1;
      const store_id = this.show_pc_order ? this.init.officialWebsiteStoreId : this.init.miniProgramStoreId;
      this.orderList(store_id);
    }
  }

  orderList(websiteStoreId: number){
    this.orderService.getCloudOrderList(websiteStoreId).then(val=> {
      if(val.code * 1 === 1000){
        this.orderService.loading = false;
        this.orderListData = val.data;

        // // 设置加购商品属性
        // if (typeof item['skuProperties'] == 'string') {
        //   this.items[key]['skuProperties'] = JSON.parse(item['skuProperties']);
        // }
      }
    }).catch(error => error);
  }

  gopay(orderId: string){
    if(this.localStorage.getItem('h5precreate_'+orderId)){
      this.localStorage.removeItem('h5precreate_'+orderId);
    }
    this.router.navigate(['/checkout/payment/' + orderId]);
  }
  changeStatus(val: any) {
    this.orderService.currentPage = 1;
    this.orderService.orderStatus = val;
    this.orderService.showSortFlag = false;
    this.orderService.showMonthFlag = false;
    if (!this.orderStateArr[val]) {
      // 没有此状态时默认是all
      this.orderService.urlType = this.orderStateArr['查看全部'];
    }
    this.statusCondition = val;
    switch (val){
      case '查看全部':
        this.orderService.cloud_order_status = this.cloudOrderStateArr['查看全部'];
        break;
      case '待付款':
        this.orderService.cloud_order_status = this.cloudOrderStateArr['待付款'];
        break;
      case '待发货':
        this.orderService.cloud_order_status = this.cloudOrderStateArr['待发货'];
        break;
     /* case '备货中':
        this.orderService.cloud_order_status = this.cloudOrderStateArr['待发货'];
        break;*/
      case '配送中':
        this.orderService.cloud_order_status = this.cloudOrderStateArr['配送中'];
        break;
      case '已完成':
        this.orderService.cloud_order_status = this.cloudOrderStateArr['已完成'];
        break;
    }
    // 请求接口的type
    if(this.show_miniprogram_order){
      this.orderList(this.init.miniProgramStoreId);
    }else{
      this.orderService.urlType = this.orderStateArr[val];
      this.orderList(this.init.officialWebsiteStoreId);
    }
  }

  changeMonthStatus(month: number) {
    // 更改状态时将页数设置为1
    this.orderService.currentPage = 1;
    this.orderService.showMonthFlag = false;
    this.orderService.showSortFlag = false;

    if (!this.monthStateArr[month]) {
      // 没有此月份时默认一个月
      this.orderService.urlMonth = 1;
    }

    switch (month){
      case 1:
        this.timeCondition = '1个月';
        break;
      case 3:
        this.timeCondition = '3个月';
        break;
      case 6:
        this.timeCondition = '6个月';
        break;
      case 0:
        this.timeCondition = '查看全部';
        break;
    }
    this.orderService.sortMonth = this.monthStateArr[month];
    this.orderService.urlMonth = month;
    // 请求接口的month
    if(this.show_miniprogram_order){
      this.orderList(this.init.miniProgramStoreId);
    }else{
      this.orderList(this.init.officialWebsiteStoreId);
    }
  }

  matchPath(path: string){
    if(path =='/my-account/order/mini'){
      this.show_miniprogram_order = true;
      this.show_pc_order = false;
    } else {
      this.show_pc_order = true;
      this.show_miniprogram_order = false;
    }
  }

  /**
   * 切换官网，小程序订单
   * @param tab
   */
  changeOrderTab(tab: string){
    this.timeCondition = '下单时间';
    //this.statusCondition = '查看全部';
    this.orderService.sortMonth = '1个月';
    this.orderService.orderStatus = '查看全部';
    this.orderService.urlMonth = 1;
    this.orderService.cloud_order_status = 'all';
    this.orderService.showOrderFlag = false;
    if(tab == 'pc'){
      this.show_pc_order = true;
      this.show_miniprogram_order = false;
      this.orderList(this.init.officialWebsiteStoreId);
    }else if(tab == 'mini_program'){
      this.show_miniprogram_order = true;
      this.show_pc_order = false;
      this.orderList(this.init.miniProgramStoreId);
    }
  }

  /**
   * 切换订单状态时关闭下拉列表
   * @param {string} orderType
   * @param {boolean} state
   */
  clickStatus(event:any, orderType: string, state: boolean) {
    // 更改状态时将页数设置为1
    // this.orderService.currentPage = 1;
    if (orderType === 'status') {
      if (!state) {
        // 订单状态和月份下拉列表不同时显示
        this.orderService.showSortFlag = true;
        this.orderService.showMonthFlag = false;

      } else {
        this.orderService.showSortFlag = false;
      }
    } else if (orderType === 'month') {
      if (!state) {
        // 订单状态和月份下拉列表不同时显示
        this.orderService.showMonthFlag = true;
        this.orderService.showSortFlag = false;
      } else {
        this.orderService.showMonthFlag = false;
      }
    }else if(orderType === 'channel'){
      this.orderService.showMonthFlag = false;
      this.orderService.showSortFlag = false;
      this.orderService.showOrderFlag = true;
    }
    if (event.stopPropagation) {
      event.stopPropagation();
    } else {
      event.cancelBubble = true;
    }
    return;
  }

  bak(e:any) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else {
      e.cancelBubble = true;
    }
    return;
  }

  /**
   * Handle event from pagination component.
   * @param event
   */
  handlePaginationEvent(event:any, type = null) {
    if (type === 'customize') {
      this.orderService.currentPage = event;
    } else {
      this.orderService.currentPage = event.page;
    }
    if(this.show_miniprogram_order){
      this.orderList(this.init.miniProgramStoreId);
    }else{
      this.orderList(this.init.officialWebsiteStoreId);
    }

    if (isPlatformBrowser(this.platformId)) {
      $('html,body').animate({scrollTop: 0}, 500);
    }
  }

  /**
   * 打开修改支付方式Box
   * @param orderId
   * @param payType
   */
  changePayment(orderId:any, payType:any) {
    if (!orderId || !payType) {
      return false;
    }
    this.orderService.show_modal = true;
    this.orderService.changePaymentBox = true;
    this.modifyPaymentOrderId = orderId;
    this.orderService.payType = payType;
	return;
  }

  /**
   * 选择支付方式
   * @param payment
   */
  setPaymentMethod(payment: any) {
    if (!payment) {
      return false;
    }
    this.choicePayMent = payment;
	return;
  }

  /**
   * 确认修改支付方式
   * @param value
   */
  modifyPaymentMethod() {
    // 第一次没选择支付方式时候选择默认的
    this.choicePayMent = this.choicePayMent === undefined ? this.orderService.payType : this.choicePayMent;
    this.newPayMethod  = this.choicePayMent;
    switch (this.newPayMethod){
      case 'weixin':
        this.newPayMethod = 'wosai:weixin:wap';
          break;
      case 'alipay':
        this.newPayMethod = 'wosai:alipay:wap';
          break;
      default:
        this.newPayMethod = this.choicePayMent;
            break;
    }
    const params = {'payType':this.newPayMethod};
    if (this.orderService.paymentLock) {
      // 更改支付方式加锁中
      this.orderService.paymentLock = false;
      this.orderService.modifyPaymentMethodServer(this.modifyPaymentOrderId,params).then(val => {
        if (val.code === 1000) {
          this.orderService.show_modal = false;
          this.orderService.changePaymentBox = false;
          if(this.show_pc_order){
            this.orderList(this.init.officialWebsiteStoreId);
          }else{
            this.orderList(this.init.miniProgramStoreId);
          }
        }
      }).catch( error => error);
    }
  }

  /**
   * 订单列表取消订单
   */
  cancelOrder(order_id:any) {
    this.orderService.show_modal = true;
    this.orderService.canceled = true;
    if (!order_id) {
      this.orderService.orderId = '';
    } else {
      this.orderService.orderId = order_id;
    }
  }

  /**
   * 确认取消订单
   */
  confirmCancel() {
    if (this.orderService.canceledLock) {
      // 取消加锁
      this.orderService.canceledLock = false;
      if (this.orderService.orderId != null) {
        const params = new HttpParams()
          .set('order_id', this.orderService.orderId);
        this.orderService.cloudOrderCancelServer(params).then(val => {
          if (val.code !== 1000) {
            this.orderService.show_modal = true;
            this.orderService.showError = true;
            this.orderService.message = '出错了，取消失败~';
          }
          this.orderService.show_modal = false;
          this.orderService.canceled = false;
          if(this.show_pc_order){
            this.orderList(this.init.officialWebsiteStoreId);
          }else{
            this.orderList(this.init.miniProgramStoreId);
          }
        }).catch( error => error);
      }
    }
  }

  productUrl(sku:any){
    this.productService.getProductBySku(sku).then((res: any) => {
      if (parseInt(res.code, 0) !== 200) {
      } else {
        const pdpUrl = '/' + res.data.url_path;
        this.router.navigate([pdpUrl]);
      }
    }).catch(error => error);
  }

  /**
   *  订单确认收货
   * @param orderId
   */
  orderComplate(orderId:any) {
    if (this.orderService.complateLock) {
      // 确认加锁
      this.orderService.complateLock = false;
      var storeId  = this.init.officialWebsiteStoreId;
      if(this.show_miniprogram_order){
        storeId = this.init.miniProgramStoreId;
      }
      const params = new HttpParams()
        .set('order_code', orderId);
      this.orderService.cloudOrderComplateServer(params).then(val => {
        if (val.code !== 1000) {
          this.orderService.show_modal = true;
          this.orderService.showError = true;
          this.orderService.message = val.msg;
        }
        this.orderList(storeId);
      }).catch( error => error);
    }
  }
  @HostListener('document:click', ['$event.target'])
  claerAll() {
    this.orderService.showSortFlag = false;
    this.orderService.showMonthFlag = false;
  }

  ngOnDestroy() {
    this.orderService.showSortFlag = false;
    this.orderService.orderStatus = '查看全部';
    // 用于月份选择显示和在页面判断的参数定义
    this.orderService.showMonthFlag = false;
    this.orderService.sortMonth = '1个月';

    this.orderService.currentPage = 1;
    // 传输到url的参数定义
    this.orderService.urlType = 'all';
    this.orderService.urlMonth = 1;
    this.orderService.loading = true;
  }

  applyJustRefund(order:any){
    this.orderService.show_modal = true;
    this.orderService.applyRefund = true;
    this.orderService.refundOrder = order;
  }

  confirmRefund(){
    this.orderService.show_modal = false;
    this.orderService.applyRefund = false;

    let refundItem : any = [];
    this.orderService.refundOrder.items.forEach((item:any) => {
      refundItem.push({'num':item.qty,'orderItemId':item.orderItemId,'reason':'未发货取消订单'});
    });

    const params = {'description':'','goodStatus':'BUYER_RECEIVED','hasGoodsReturn':'0','orderId':this.orderService.refundOrder.orderId,'reason':'未发货取消订单','items':refundItem,'refundFee':this.orderService.refundOrder.payment}
    this.orderService.justRefund(params).then(val => {
      if (val.code === 1000) {
        this.orderService.show_modal = true;
        this.orderService.refundApplySuccess = true;
        this.orderService.message = '订单已取消，请注意查看退款消息';
      }else{
        this.orderService.show_modal = true;
        this.orderService.showError = true;
        this.orderService.message = '出错了，取消失败~';
        if(val.code === -1004){
            this.orderService.message = val.msg;
        }
      }
    }).catch(error => error);
  }

  refreshOrderList(){
    this.orderService.closeOverlay();
    let storeId = this.init.officialWebsiteStoreId;
    this.orderList(storeId);
  }
}
