<div class="o-page-content cofs-page" style="margin-top:0; padding: 0;">
  <div class="rma_express">
  <h4 class="font_xs_regular m-0">退货</h4>
    <div class="expForm">
      <p>请填写寄回快递信息：</p>
      <div class="m-input">
        <label>快递公司</label>
        <select class="form-control" style="font-size:12px;" (change)="selectExpressName($event.target.value)"  name="express_name">
          <option value="">请选择</option>
          <option *ngFor="let express of rmaService.expressNames" value="{{express.expressCode}}">{{express.expressName}}</option>
        </select>
      </div>
      <div class="m-input">
        <label>快递单号</label>
        <input class="a-input js-a-input" (keyup)="checkExpress()" name="express_number" [(ngModel)]="express_number">
      </div>
    </div>
    <div class="bottom-section text-center">
      <button [disabled]="!canSave" class="a-button is-primary" (click)="saveExpress()">提交</button>
      <a routerLink="/" class="a-link">继续购物</a>
    </div>
    <div class="ramAddress">
      <strong class="is-black">退货地址：</strong>
      <p>江苏省苏州市昆山市新生路718号，江苏富莱德仓储有限公司物流园一期B幢仓库B-1单元  COS项目组（请不要发到付件和平邮，仓库无法收件）</p>
      <p>邮编：215300</p>
      <p>售后热线：400-015-1365</p>
    </div>
  </div>
</div>
