import {Component, Inject, OnInit, Optional} from '@angular/core';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';
import {AbTrackingService} from '../../services/abtracking.service';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  private previousPage = '';
  constructor(
    @Optional() @Inject(RESPONSE) private response: any,
    private localStorage: StorageService,
    private init: InitializeService,
    private abTracking: AbTrackingService
  ) {
  }

  ngOnInit() {
    this.init.setTitle('Not Found');
    if (this.response) {
      this.response.statusCode = 404;
    }
    this.previousPage = this.localStorage.getItem('404_previous_page');
    if(this.previousPage !== '' && this.previousPage !== undefined){
      this.abTracking.trackingPage404('/404',this.previousPage,'');
    }
  }
}
