import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ProductService} from '../../../services/product.service';
import {SearchService} from '../../../services/search.service';
import {StorageService} from '../../../services/storage.service';
import {InitializeService} from '../../../services/initialize.service';

@Component({
  selector: 'app-product-strategy-tag',
  templateUrl: './product-strategy-tag.component.html',
  styleUrls: ['./product-strategy-tag.component.css'],
})
export class ProductStrategyTagComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() strategyRes: any;
  @Input() isLogin: boolean = false;
  @Input() itemId:any;
  @Input() valueId:any;
  @Input() memberLevelId:any;
  @Input() keyword:any;
  @Input() pageName:any;
  
  public customerIsLogin = false;
  public rulePriceJson:any = {price:'',rulesArr:[]};//策略价+预热价
  public currency:any;
  constructor(
    public productService: ProductService,
	public searchService: SearchService,
	public init: InitializeService,
	private localStorage: StorageService) {
		
  }
 
   ngOnChanges(){
		this.customerIsLogin = this.isLogin;
		this.filterStrategyTag(this.itemId,this.valueId)
		
		if(this.pageName == 'search_page'){
			this.refeshProductList(this.keyword);
		}
  }
  ngOnInit() {
	this.currency = this.init.currency;
  }
  ngAfterViewInit() {
  }
  
  refeshProductList(keyword:any){
	   this.searchService.cloudSearchPage(keyword, false, true);
  }
  filterStrategyTag(itemId:any,valueId:any){
	  this.productService.getProductStrategy(itemId,valueId).then((strategyRes:any) => {
	    if (strategyRes['code'] === 1000 && strategyRes['data'].length > 0) {
			this.strategyRes = strategyRes['data'];

			const groupId = this.localStorage.getItem('groupId') ? this.localStorage.getItem('groupId') :0;
			const memberLevelId = this.localStorage.getItem('memberLevelId') ? this.localStorage.getItem('memberLevelId') :0;
			const memberStatus = this.localStorage.getItem('memberIsActive') ? this.localStorage.getItem('memberIsActive') :'Inactive';
 
			this.rulePriceJson = this.productService.priceRulesDeal(groupId,memberLevelId,memberStatus,strategyRes['data'])
	    }
	  });
  }
  
}
