<div class="modal-header">
  <h4 class="modal-title pull-left">页面编辑</h4>
</div>
<div class="modal-body">
  <div *ngIf="!cmsService.showCmsPanel || cmsService.sharedData.isNewPage == false">
    <div class="form-group">
      <button (click)="newPage()">新建页面</button>
    </div>
  </div>

  <div *ngIf="cmsService.showCmsPanel">
    <div class="form-group">
      <label for="inputUrlKey">链接标识</label>
      <input [(ngModel)]="cmsService.sharedData.pageModel.identifier" type="text" class="form-control" id="inputUrlKey"
             placeholder="如 custom_service.html">
    </div>
    <div class="form-group">
      <label for="inputTitle">请填写页面标题</label>
      <input [(ngModel)]="cmsService.sharedData.pageModel.title" type="text" class="form-control" id="inputTitle"
             placeholder="请填写页面标题">
    </div>
    <div class="form-group">
      <label for="inputMetaKeyword">Meta 关键字</label>
      <input [(ngModel)]="cmsService.sharedData.pageModel.meta_keywords" type="text" class="form-control"
             id="inputMetaKeyword"
             placeholder="请填写Meta关键字">
    </div>
    <div class="form-group">
      <label for="inputMetaDescription">Meta 描述</label>
      <textarea class="form-control" rows="3" id="inputMetaDescription" placeholder="请填写Meta 描述"
                [(ngModel)]="cmsService.sharedData.pageModel.meta_description"></textarea>
    </div>
    <div class="checkbox">
      <label>
        <input [(ngModel)]="cmsService.sharedData.pageModel.is_active" type="checkbox">是否立即激活？
      </label>
    </div>
    <hr/>
    <div class="form-group">
      <div class="checkbox">
        <label><input type="checkbox" [(ngModel)]="cmsService.sharedData.previewMode"/>预览</label>
      </div>

      <div class="checkbox">
        <label><input type="checkbox" [(ngModel)]="cmsService.sharedData.pageModel.auto_publish"/>自动发布</label>
      </div>
      <div class="checkbox">
        <label><input type="checkbox" [(ngModel)]="cmsService.sharedData.showData"/>显示数据</label>
      </div>
      <button type="button" class="btn btn-default" (click)="addContainer()">
        <span class="glyphicon glyphicon-plus" aria-hidden="true">新建容器</span>
      </button>
    </div>
    <hr/>
    <button type="button" class="btn btn-lg btn-primary btn-block"
            [disabled]="!cmsService.sharedData.pageModel.identifier" (click)="savePreviewPage()">保存
    </button>
    <button type="button" class="btn btn-default btn-lg btn-block"
            [disabled]="!cmsService.sharedData.pageModel.identifier" (click)="savePreviewPageAndPublish()">保存并发布
    </button>
  </div>
</div>
