import { Router } from '@angular/router';
import {Component, OnInit, HostListener, PLATFORM_ID,ElementRef, Inject} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import $ from 'jquery';
import {isPlatformBrowser} from '@angular/common';
import {ProductService} from '../../services/product.service';
import {FavoriteService} from '../../services/favorite.service';
import {HttpParams} from '@angular/common/http';
import {ProductModel} from '../../models/product.model';
import {StorageService} from '../../services/storage.service';
import {CartService} from '../../services/cart.service';
import {CallComponentService} from '../../services/callcomponent.service';
import {CartModel} from '../../models/cart.model';
import {TrackingcodeService} from '../../services/trackingcode.service';
import {AbTrackingService} from '../../services/abtracking.service';
@Component({
  selector: 'app-account-favorite',
  templateUrl: './account-favorite.component.html',
  styleUrls: ['./account-favorite.component.css']
})
export class AccountFavoriteComponent implements OnInit{
  public productModel!: ProductModel;
  public favoriteItems:any = {total:0,content:''};
  public tempFavoriteItems:any = [];
  showLoading:any = false;
  public productColors: any;
  public timer:any = null;
  public emptyFavoriteText:any = false;
  // size集合
  public productSizeArray:any = [];
  public quoteId!: string;
  // 返回的添加购物车信息
  public shoppingData:any = {code: 1, data: '', msg: ''};

  // 添加到购物车显示数据
  public cartInfo:any = {};
  // 添加到购物车图片url
  public cartImgUrl!: string;

  public favoriteList:any = [];
  public tempFavoriteList:any = {code: 1, data: {total:0,valid_total:0}, msg: ''};
  public favoriteTotal = 0;
  public productDetail:any = {code: 1, data: '', msg: ''};
  public orderResult!: CartModel;
  public cartItemIds:any = [];
  public show_error_box:boolean = false;
  public error_message:any;

  public mb_selected_sizevalue!: string;

  public showHoverImage :boolean = false;

  public remindResult:any = {code:'',msg:'',data:''};
  public remindSuccess:boolean = false;
  public remindError:boolean = false;
  public remindErrorMsg:any;
  public salePriceList:any = [];
  // public arrivalNoticeConfig: any = {code: 0, data: {value: false}};
  // public itemsArrivalNoticeConfigList:any = {};
  public gaFavoriteItems:any = [];
  // public storeArrivalNotice:boolean = false;
  public rulePriceJson:any = {price:'',rulesArr:[]};//策略价+预热价

  public relationLimitSkuIds:any;
  public limitedPurchaseQty = -2;//自定义赋值，表示未调用限购数量查询接口
  public limitedPurchaseRes:any;
  public customerLimitQty = 0;//还可购买数据

  cancelFavoritesId = 0;//取消收藏的id

  public showSizeBox = false;
  currentFavoritesId = 0;
  selectedFavSizeLists:any = {}; //选择过的尺码列表

  //快速加购数据
  public showBox = false;
  public frontCode = '';
  public salePrice:any;
  // 货币
  public currency : any;
  constructor(
    private init: InitializeService,
    private router: Router,
    private productService:ProductService,
    private favoriteService:FavoriteService,
    private localStorage: StorageService,
    public  cartService: CartService,
    public eventSubscribeService: CallComponentService,
    private tracking: TrackingcodeService,
    private abTracking: AbTrackingService,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.init.pageConfiguration('我的收藏');
    this.currency = this.init.currency;
    this.showLoading = true;
    if (isPlatformBrowser(this.platformId)) {
      // //判断店铺级到货提醒开关是否打开
      // this.productService.showArrivalNotice(this.init.merchantId,this.init.cloudStoreId).then(result => {
      //   this.arrivalNoticeConfig = result;
      //   if (this.arrivalNoticeConfig.code === 1000) {
      //     this.storeArrivalNotice = this.arrivalNoticeConfig.data.value;
      //   }
      // }).catch(error => error);

      this.getFavoriteItems();
      this.cartRequest();
    }

    // 订阅尺码label is-disable是否显示
    this.eventSubscribeService.showSizeBoxCommon.subscribe((value:any) => {
      this.showBox = true;
      this.frontCode = value.frontCode;
	  this.salePrice = value.salePrice;
    });
  }

  getCartModel() {
    return this.cartService.cartModel;
  }

  /**
   * 收藏列表
   */
  getFavoriteItems(){
    this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage).then(val=>{
      this.tempFavoriteList = val;
      if(this.tempFavoriteList.code === 1000){
        this.showLoading = false;
        this.favoriteTotal = this.tempFavoriteList.data.valid_total;
		    this.gaFavoriteItems = [];
        if(this.favoriteService.favoriteList.length > 0){
          this.favoriteList = this.favoriteService.favoriteList;
          if(this.favoriteService.currentFavoriteList.length >0){
            this.favoriteService.currentFavoriteList.forEach((item:any) => {
              this.goodsDetail(item);
            });
          } else {
            this.favoriteService.favoriteList.forEach((item:any) => {
              this.goodsDetail(item);
            });
          }
          // 新版Ga Tracking Js
          if (this.init.gaTrackingFlag) {
            this.tracking.gaTrackingFavorite(this.gaFavoriteItems);
          }
        }
      }else{
        if(this.favoriteService.favoriteCurrentPage > 1){
          this.favoriteService.favoriteCurrentPage = this.favoriteService.favoriteCurrentPage - 1;
          this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage).then(val=>{
            this.tempFavoriteList = val;
            if(this.tempFavoriteList.code === 1000){
              this.favoriteTotal = this.tempFavoriteList.data.valid_total;

              this.emptyFavoriteText = false;
              this.showLoading = false;
              this.favoriteList = this.favoriteService.favoriteList;
            }else{
              this.showLoading = false;
              this.emptyFavoriteText = true;
            }
          });
        }else{
          this.favoriteService.favoriteCurrentPage = 1;
        }
        this.showLoading = false;
        this.emptyFavoriteText = true;
      }
    });
  }

  delFavorite(favoritesId:any){
    this.cancelFavoritesId = favoritesId;
  }

  cancelDel(){
    this.cancelFavoritesId = 0;
  }

  delFavoriteItem(favoritesId:any,favoriteItem:any){
    const params = new HttpParams()
      .set('favorites_id',favoritesId);
      this.favoriteService.delFavoriteItem(favoritesId,params).then( val=>{
        if(val.code === 1000){
          this.cancelFavoritesId = 0;
          var result:any = {'code':val.code,'type':'remove'};
          this.eventSubscribeService.changeFavoriteBox.next(result);
          this.favoriteService.clearFavoriteResult();
          this.favoriteTotal = this.favoriteTotal-1;
          this.getFavoriteItems();
          if (this.init.gaTrackingFlag) {
            this.gaTrackingFavorite(favoriteItem,'unsave','remove_from_favourites');
          }
        }
      })
  }

  gaTrackingFavorite(item:any,action="",eventType=""){
		var itemsArray = [];
		var frontUrlPath = item.frontUrlPath;
		var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
		var sizeInfo = this.productSizeArray[item.itemId+'-'+item.valueId][0];
    var itemObject = {
        product_action: action,
        product_id: item.itemCode,//款号
        product_article_id: item.frontCode,//CC
        product_variant_id: sizeInfo.skuCode,//SKU
        product_name: item.itemName,
        product_brand: 'COS',
        product_category:product_category,
        product_color:item.valueName,
        product_price:item.price,
        product_price_original:item.markingPrice,
        product_price_type: item.price < item.markingPrice ? 'sale' : 'full price',
        product_quantity: 1,
        product_size: sizeInfo.valueName,
        product_size_code: sizeInfo.valueId,
    };
    itemsArray.push(itemObject);
    this.tracking.gaTrackingManageFavourites(itemsArray,eventType);
  }
  /**
   * Handle event from pagination component.
   * @param event
   */
  handlePaginationEvent(event:any, type = null) {
    if (type === 'customize') {
      this.favoriteService.favoriteCurrentPage = event;
    } else {
      this.favoriteService.favoriteCurrentPage = event.page;
    }
    this.getFavoriteItems();
    if (isPlatformBrowser(this.platformId)) {
      $('html,body').animate({scrollTop: 0}, 500);
    }
  }

  /**
   * 显示，隐藏尺码列表
   * @param favoritesId
   * @param itemId
   * @param valueId
   */
  showSize(favoritesId:any){
    if(this.currentFavoritesId==favoritesId){
      this.showSizeBox = !this.showSizeBox;
    } else {
      this.showSizeBox = true;
    }
    this.currentFavoritesId = favoritesId;
  }

  goodsDetail(favoriteItem:any){
    const itemId = favoriteItem.itemId;
    const valueId = favoriteItem.valueId;
    const favoriteId = favoriteItem.favoritesId;
    var product_size = '';
    var product_size_code = '';
    var product_category = '';
    var skuCode = '';
    this.productService.getProductByItemId(itemId).then( val=> {
      this.productDetail = val;
      let tempSizeArr: any[] = [];
      if (parseInt(this.productDetail.code, 0) === 1000) {
        this.productModel = new ProductModel(this.productDetail.data);

        //查询限购关联skus
        this.productService.customParameters(this.productModel.frontProperties,valueId);
        var frontCustomParameters = JSON.parse(this.productService.frontCustomParameters);
        if(!!frontCustomParameters){
          if(!!frontCustomParameters.relationLimitSkuIds){
            this.relationLimitSkuIds = frontCustomParameters.relationLimitSkuIds;
          }
        }

        // 获取颜色
        this.productColors = this.productModel.getColorsCloud('');
        this.productService.getProductStrategy(itemId,valueId).then((strategyRes:any) => {
          if (strategyRes['code'] === 1000 && strategyRes['data'].length > 0){
            const groupId = this.localStorage.getItem('groupId') ? this.localStorage.getItem('groupId') :'';
            const memberLevelId = this.localStorage.getItem('memberLevelId') ? this.localStorage.getItem('memberLevelId') :'';
            const memberStatus = this.localStorage.getItem('memberIsActive') ? this.localStorage.getItem('memberIsActive') :'';
            this.rulePriceJson = this.productService.priceRulesDeal(groupId,memberLevelId,memberStatus,strategyRes['data'])
            if(this.rulePriceJson.price !== 0) {
              this.salePriceList[favoriteId] = this.rulePriceJson.price
            }
          }
        });

        // //判断cc级到货提醒开关
        // if(this.storeArrivalNotice){
        //   this.itemsArrivalNoticeConfigList[favoriteId] = this.productModel.arrivalNotice == 1 ? true :false;
        // }else{
        //   this.itemsArrivalNoticeConfigList[favoriteId] = false;
        // }

        // 获取尺码  品方要求不过滤无库存sku
        this.productModel.sku_list[valueId].forEach((sku_item:any) => {
          //if(sku_item.qty > 0){
          tempSizeArr.push(sku_item);
          //}
        });
        this.productSizeArray[itemId+'-'+valueId]  = tempSizeArr;
        this.productSizeArray[itemId+'-'+valueId].sort(function(a:any, b:any) {
          return a['sortOrder'] - b['sortOrder'];
        });
        product_size = tempSizeArr[0].valueName;
        product_size_code = tempSizeArr[0].valueId;
        skuCode = tempSizeArr[0].skuCode;
        //获取GA需要的商品分类
        var frontUrlPath = this.productDetail.data.frontProperties[0].frontUrlPath;
        product_category = this.productService.getCategoryByFrontUrl(frontUrlPath);
      }
    }).then( _res => {
        //组装GA商品数据
        this.gaFavoriteItems.push({
          product_action: 'favourites',
          product_id: favoriteItem.itemCode,//款号
          product_article_id: favoriteItem.frontCode,//CC
          product_variant_id: skuCode,//SKU
          product_name: favoriteItem.itemName,
          product_brand: 'COS',
          product_category:product_category,
          product_color:favoriteItem.colorName,
          product_price:favoriteItem.price,
          product_price_original:favoriteItem.markingPrice,
          product_price_type: favoriteItem.price < favoriteItem.markingPrice ? 'sale' : 'full price',
          product_size: product_size,
          product_size_code: product_size_code,
        });
    })
  }

  limitPurchaseQty(skuId:any){
	  this.productService.limitPurchase(skuId,this.relationLimitSkuIds).then(val=>{
	  		this.limitedPurchaseRes =  val;
	  		if(this.limitedPurchaseRes.code == 1000){
	  			this.limitedPurchaseQty = this.limitedPurchaseRes.data.activityLimitQty;
				this.customerLimitQty =  this.limitedPurchaseRes.data.customerLimitQty;
	  		}
	  });
  }

  select_Size(e: any,item:any,sizeItem:any){
    if(sizeItem.qty < 1){
      e.stopPropagation();
      return;
    }
    let _sizeItem = {...sizeItem};
    _sizeItem['favoritesId'] = item.favoritesId;
    _sizeItem['itemId'] = item['itemId'];
    // _sizeItem['itemName'] = item['itemName'];
    this.selectedFavSizeLists[item['favoritesId']] = _sizeItem;
    this.limitPurchaseQty(sizeItem.skuId);
  }

  // 添加购物车
  async addShoppingCart(item:any,number:any) {
    //解析加购参数
    const favoriteId = item.favoritesId;
    const itemId = item.itemId;
    if(!this.selectedFavSizeLists.hasOwnProperty(favoriteId)){
      this.show_error_box = true;
      this.error_message = '请先选择尺码';
    }else{
      const size_item = this.selectedFavSizeLists[favoriteId];
      this.tracking.favoritesPurchase(size_item['skuCode']+'-'+size_item['valueName']);
      this.quoteId = this.localStorage.getItem('quote_id');
      // TODO this.productId need modify;@skukun
      this.productService.getQuoteId(size_item['skuCode'],size_item['skuId'],this.quoteId,number,size_item['barcode'],item['itemName']).then(async model => {
        this.shoppingData = model;
        if (this.shoppingData.code === 1000) {
          this.cartService.getCartData().then();
          let tempPrice = item.markingPrice;
          let tempSalePrice = item.price;
          let boxPrice = tempPrice;
          let boxSalePrice = tempSalePrice;
          if(tempPrice > tempSalePrice){
            boxPrice = tempPrice;
            boxSalePrice = tempSalePrice;
          }else{
            //策略价
            if(this.salePriceList[item.favoritesId]){
              boxSalePrice = this.salePriceList[item.favoritesId];
            }
          }
          //查询cloud推荐搭配
          let recommendWhole:any = [];
          const recommends = await this.productService.getProductRecommends(itemId, item.valueId);
          if ( recommends.total > 0) {
            recommends.content.forEach((recommendItem:any) => {
              // 搭配推荐
              if(recommendItem['recommendBlock'] == 'whole'){
                recommendWhole.push(recommendItem)
              }

              // // 同类商品
              // if(recommendItem['recommendBlock'] == 'similar'){
              //   recommendSimilar.push(recommendItem)
              // }
            });
          }
          this.cartInfo = {
            'name': item.itemName,
            'price': boxPrice == boxSalePrice ? 0 : boxPrice,
            'salesPrice': boxSalePrice ,
            'img_url': item.hoverImageUrl + this.init.imageSize.$4,
            'size': this.productService.sizeConvert(size_item['valueName']),
            'color': item.colorName,
            'recommends': recommendWhole,
          };
          this.cartRequest();
          this.abTracking.trackingCart(size_item['skuId'],item['itemName'],boxSalePrice, item.valueId, item.colorName, size_item['skuCode'], item.skuImageUrl + this.init.imageSize.$4, {path: this.router.url});
          this.eventSubscribeService.addMimiCartBox.emit(this.cartInfo);
          //移除心愿单
          this.delFavoriteItem(item['favoritesId'], item);
        }else if(this.shoppingData.code === -1010){
			    this.show_error_box = true;
          if(this.limitedPurchaseQty > 0){
            this.error_message= "该商品每月限购"+this.limitedPurchaseQty+"件";
          }else{
            this.error_message= "商品超出限购数量";
          }
		    }else{
          this.show_error_box = true;
          this.error_message = this.shoppingData.msg;
        }
      });
    }
  }

  /**
   * 购物车数据
   */
  cartRequest() {
    //this.cartService.getCartData().then();
    this.cartService.getCartData().then( val => {
      this.orderResult = val;
      if (this.cartService.cartModel.getCartItems().length > 0) {
        let itemLists = this.cartService.cartModel.getCartItems() ? this.cartService.cartModel.getCartItems() : [];
        if (itemLists && itemLists != null  && itemLists.length > 0) {
          const allCartItemIds: any = [];
          itemLists.forEach((item:any) => {
            // if (item.isChecked > 0) {
              allCartItemIds.push(item.itemId);
            // }
          });
          this.cartItemIds = allCartItemIds;
        }
      }
    });
  }

  // 关闭遮罩层和弹框
  closeOverlay() {
    this.show_error_box = false;
    this.remindSuccess = false;
    this.remindError = false;
  }

  switchImageMode(model = 1){
    if(model === 2){
      this.showHoverImage = true;
    }else{
      this.showHoverImage = false;
    }
  }

  setArrivalRemind(item:any){
    if(!this.init.isLogin()){
      this.eventSubscribeService.loginRegisterBox.next('1');
    }else{
      const favoriteId = item.favoritesId;
      if(!this.selectedFavSizeLists.hasOwnProperty(favoriteId)){
        this.show_error_box = true;
        this.error_message = '请先选择尺码';
      }else {
        const size_item = this.selectedFavSizeLists[favoriteId];
        const params = {
          frontCode:item.frontCode,
          frontUrl:'/pages/goods_detail?id='+item.itemId+'&valueId='+item.valueId+'&valueName='+item.colorName,
          itemId:item.itemId,
          itemCode:item.frontCode,
          itemName:item.itemName,
          mobile:this.localStorage.getItem('mobile'),
          storeId:this.init.officialWebsiteStoreId,
          valueId:item.valueId,
          valueName:item.colorName,
          nickName:this.localStorage.getItem('wxNickName'),
          unionId:this.localStorage.getItem('wxUnionId'),
          skuCode:size_item['skuCode'],
        };
        this.productService.customerArrivalRemind(JSON.stringify(params)).then( val => {
          this.remindSuccess = true;
          // this.remindResult = val;
          // if(this.remindResult.code === 200){
          //   this.remindSuccess = true;
          // }else if(this.remindResult.code === -1002){
          //   if(this.remindResult.msg === 'sku already add'){
          //     this.remindSuccess = true;
          //   }else{
          //     this.remindError = true;
          //     this.remindErrorMsg = '提交失败，请重试';
          //   }
          // } else if(this.remindResult.code === -1003){
          //   this.remindSuccess = true;
          // }else{
          //   this.remindError = true;
          //   this.remindErrorMsg = '提交失败去，请重试';
          // }
        }).catch(error=>error);

        }

    }
  }

  closeLayer(){
    this.showBox = false;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const clientHeight = document.documentElement.clientHeight;
      const productBlockDom = this.el.nativeElement.querySelectorAll('.favorites-list-product-block');
      const lastProductBlockDom = productBlockDom[productBlockDom.length - 1];
      if (!lastProductBlockDom) {
        return;
      }
      const domBottom = lastProductBlockDom.getBoundingClientRect().bottom;
      if (domBottom < clientHeight && !this.favoriteService.loadLock) {
        this.showLoading = true;
        this.favoriteService.loadLock = true;
        //  // 加载下一页的loading
        this.favoriteService.favoriteCurrentPage++;
        this.getFavoriteItems();
      }
    }
  }

}
