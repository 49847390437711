<div data-component="OPageContent" class="o-page-content" style="margin-top:0"
	*ngIf="!listLoading; else listLoadingBlock;">
	<div class="content-section"
		*ngIf="categoryModel?.displayMode=='PRODUCTS' || categoryModel?.displayMode=='PRODUCTS_AND_PAGE'; else cetegoryPageBlock">

		<div *ngIf="displayBackTopIcon" class="backto-top sticky top-3/4 z-10 hidden flex-col lg:inline-flex"
			(click)="backToTop()">
			<button
				class="m-0 p-0 appearance-none bg-main-button text-main flex h-10 w-10 items-center justify-center border-0.5"
				type="button" value=""><svg class="inline h-4 w-4 fill-current">
					<title>回到顶部</title>
					<use href="/assets/iconSprite.svg#chevron-up"></use>
				</svg></button>
		</div>

		<!-- 显示面包屑 -->
		<app-breadcrumb [displayMode]="categoryModel?.displayMode"></app-breadcrumb>
		<div class="default-height" [ngClass]="{'default-min-height': cmsBlock}" 
			*ngIf="categoryModel?.notShowChildren == 1 && categoryModel?.displayMode == 'PRODUCTS_AND_PAGE'">
		</div>
		<!-- 新品头部 开始 -->
		<div class="hero parbase section"
			*ngIf="categoryModel?.displayMode=='PRODUCTS' && categoryModel?.notShowChildren==1">
			<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
				<div class="contain">
					<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
						<div style="cursor: default;" class="o-hero">
							<div style="background-color: FFFFFF;" class="bcgr-color">
								<div class="m-teaser align-middle align-center mobile-middle">
									<h1 class="a-heading-1" style="color:444444">{{categoryModel?.navName}}</h1>
									<!-- 当前分类下方banner -->
									<div id="blockUnderCategoryName" class="categoryDescribe"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 新品头部 结束 -->
		<div *ngIf="categoryModel?.displayMode=='PRODUCTS' && categoryModel?.notShowChildren!=1"
			class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-full-width u-no-padding">
			<div data-component="OLocalNavigation" class="o-local-navigation">
				<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 navigation-container u-no-padding noFloat">
					<div class="titleGrid grid grid-cols-12 gap-x-3 md:gap-x-5 px-3 md:px-5">
						<h1 class="a-heading-1 col-span-12 col-start-1 lg:col-span-3 lg:col-start-7">
							{{categoryModel?.navName}}</h1>
					</div>
	
					<!-- 当前分类下方banner -->
					<div id="blockUnderCategoryName" class="categoryDescribe"></div>
					<!--  此处内容需在分类里设置description来实现，当分类（display_model == products）并且description 不为空时显示 -->
					<div *ngIf="categoryModel?.description != ''" [innerHTML]="categoryModel?.description | html"></div>
					<ng-container *ngIf="!!categoryService.categoryTree && categoryService.categoryTree.length > 0">
						<div class="navigation flex">
							<ng-container *ngIf="categoryService.categoryTree.length > 6" >
								<button (click)="scrollToLeft()" id="toleft"
									class="m-left hide-iocn" type="button"><svg class="inline h-4 w-4 fill-current">
										<title>向左滑动</title>
										<use href="/assets/iconSprite.svg#chevron-left"></use>
									</svg></button>
							</ng-container>

							<ul id="nav-container">
								<li *ngFor="let cat of categoryService.categoryTree">
									<a routerLink="/{{cat.urlPath}}" class="a-link"
										[ngClass]="{'selected-sub-category':cat.urlPath==categoryModel?.urlPath}">{{cat.navName}}</a>
								</li>
							</ul>
							<ng-container *ngIf="categoryService.categoryTree.length > 6">
								<button  (click)="scrollToRight()" id="toright"
									class="m-right" type="button"><svg class="inline h-4 w-4 fill-current">
										<title>向右滑动</title>
										<use href="/assets/iconSprite.svg#chevron-right"></use>
									</svg></button>
							</ng-container>
						</div>
					</ng-container>


				</div>
			</div>

			<!-- <div *ngIf="categoryModel?.description != ''" [innerHTML] = "categoryModel?.description | html"></div> -->
		</div>



		<!--keyline start-->
		<!-- PLP页面顶部banner -->
		<div id="categoryTopBlock"></div>
<!-- 	   <div class="keyline parbase section">
		  <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24">
			<hr class="a-keyline">
		  </div>
		</div> -->

		<!--shop by size start-->
		<div class="sizeBox u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width"
			*ngIf="categoryModel.shopBySizeValues">
			<div data-component="OLocalNavigation" class="o-local-navigation navigation-select">
				<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 navigation-container u-no-padding">
					<p class="store">按尺码选购</p>
					<div class="navigation selectSize">
						<ul>
							<li *ngFor="let sizeVals of categoryModel.shopBySizeValues">
								<!--cloud后台设置尺码-->
								<span class="a-link" (click)="shopBySizeFilter(sizeVals)"
									*ngIf="!!categoryService.shopSizeVals && categoryService.shopSizeVals.indexOf(sizeVals) !== -1">{{sizeVals}}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<!-- <div class="keyline parbase section">
        <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24">
          <hr class="a-keyline">
        </div>
      </div> -->
		</div>
		<!--shop by size end-->

		<div style="display: none;" class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width"
			*ngIf="categoryModel?.showFilter==1 || categoryModel?.hideSort != 1 || categoryModel?.showPriceInterval==1">
			<div class="contain height">
				<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 flexBox">
					<div class="nof">
						<div *ngIf="categoryModel?.showFilter==1" data-component="OProductFilter"
							class="o-product-filter">
							<div class="filter-wrapper" [ngClass]="{'is-open':showH5Filter}">
								<a (click)="showH5Filter=true" target="_self" class="a-link filter-link">筛选</a>
								<div class="filter-section">
									<h2 class="a-heading-2 filter-title">筛选</h2>
									<span class="a-icon-close" (click)="showH5Filter=false"></span>
									<div class="filters-content">
										<div class="o-filter"
											*ngIf="categoryService.allColor.length>0 && (!categoryModel.filterType || categoryModel.filterType.indexOf('1') > -1)">
											<!-- 弹出框：加is-open类 -->
											<div data-component="MDropdown"
												class="m-dropdown style-dropdown filter-dropdown"
												[ngClass]="{'is-open' : categoryService.filterFlag=='color'}"
												data-filter-dropdown="true">
												<label class="a-label js-a-label placeholder"
													(click)="showFilter('color', $event)">
													<span>
														颜色 {{categoryService.colorFilterSelected.join('|')}}
													</span>
												</label>
												<span class="a-icon-close"></span>
												<input name="Colour" class="js-input" />
												<span class="a-icon-arrow-down-small"
													(click)="showFilter('color', $event)"></span>
												<ul class="list-wrapper" (click)="bak($event)">
													<li>
														<ul class="options">
															<div class="m-checkbox u-clearfix"
																*ngFor="let color of categoryService.allColor"
																id="color_{{color.id}}">
																<input type="checkbox" class="a-checkbox"
																	id="connected_color_{{color.id}}" />
																<label class="a-label js-a-label custom-box"
																	for="connected_color_{{color.id}}"
																	(click)="filterAttribute('color',color.id,color.color, $event)"></label>
																<label class="a-label js-a-label"
																	for="connected_color_{{color.id}}"
																	(click)="filterAttribute('color',color.id,color.color, $event)">{{color.color}}</label>
															</div>
														</ul>
														<ul class="bottom-option">
															<li>取消</li>
														</ul>
													</li>
												</ul>
												<span class="error-msg"></span>
											</div>
										</div>
										<div class="o-filter"
											*ngIf="categoryService.allSize.length>0 && (!categoryModel.filterType || categoryModel.filterType.indexOf('2') > -1)">
											<div data-component="MDropdown"
												class="m-dropdown style-dropdown filter-dropdown"
												[ngClass]="{'is-open' : categoryService.filterFlag=='size'}"
												data-filter-dropdown="true">
												<label class="a-label js-a-label placeholder"
													(click)="showFilter('size', $event)">
													<span>
														尺码 {{categoryService.sizeFilterSelected.join('|')}}
													</span>
												</label>
												<span class="a-icon-close"></span>
												<input name="Size" class="js-input" />
												<span class="a-icon-arrow-down-small"
													(click)="showFilter('size', $event)"></span>
												<ul class="list-wrapper" (click)="bak($event)">
													<li>
														<ul class="options">
															<div class="m-checkbox u-clearfix"
																*ngFor="let size of categoryService.allSize"
																id="size_{{size.id}}">
																<input type="checkbox" class="a-checkbox"
																	id="connected_size_{{size.id}}" />
																<label class="a-label js-a-label custom-box"
																	for="connected_size_{{size.id}}"
																	(click)="filterAttribute('size',size.id,size.size, $event)"></label>
																<label class="a-label js-a-label"
																	for="connected_size_{{size.id}}"
																	(click)="filterAttribute('size',size.id,size.size, $event)">{{size.size}}</label>
															</div>
														</ul>
														<ul class="bottom-option">
															<li>取消</li>
														</ul>
													</li>
												</ul>
												<span class="error-msg"></span>
											</div>
										</div>
										<div class="o-filter"
											*ngIf="categoryService?.allTheme.length > 0 && (!categoryModel.filterType || categoryModel.filterType.indexOf('3') > -1)">
											<div data-component="MDropdown"
												class="m-dropdown style-dropdown filter-dropdown"
												[ngClass]="{'is-open' : categoryService.filterFlag=='theme'}"
												data-filter-dropdown="true">
												<label class="a-label js-a-label placeholder"
													(click)="showFilter('theme', $event)">
													<span>
														款式 {{categoryService.themeFilterSelected.join('|')}}
													</span>
												</label>
												<span class="a-icon-close"></span>
												<input name="Style" class="js-input" />
												<span class="a-icon-arrow-down-small"
													(click)="showFilter('theme', $event)"></span>
												<ul class="list-wrapper" (click)="bak($event)">
													<li>
														<ul class="options">
															<div class="m-checkbox u-clearfix"
																*ngFor="let theme of categoryService.allTheme"
																id="theme_{{theme}}">
																<input type="checkbox" class="a-checkbox"
																	id="connected_theme_{{theme}}" />
																<label class="a-label js-a-label custom-box"
																	for="connected_theme_{{theme}}"
																	(click)="filterAttribute('theme',theme,theme, $event)"></label>
																<label class="a-label js-a-label"
																	for="connected_theme_{{theme}}"
																	(click)="filterAttribute('theme',theme,theme, $event)">{{theme}}</label>
															</div>
														</ul>
														<ul class="bottom-option">
															<li>取消</li>
														</ul>
													</li>
												</ul>
												<span class="error-msg"></span>
											</div>
										</div>
										<div class="o-filter"
											*ngIf="categoryService.allQuality.length>0 && (!categoryModel.filterType || categoryModel.filterType.indexOf('4') > -1)">
											<div data-component="MDropdown"
												class="m-dropdown style-dropdown filter-dropdown"
												[ngClass]="{'is-open' : categoryService.filterFlag=='quality'}"
												data-filter-dropdown="true">
												<label class="a-label js-a-label placeholder"
													(click)="showFilter('quality', $event)">
													<span>
														材质 {{categoryService.qualityFilterSelected.join('|')}}
													</span>
												</label>
												<span class="a-icon-close"></span>
												<input name="Material" class="js-input" />
												<span class="a-icon-arrow-down-small"
													(click)="showFilter('quality', $event)"></span>
												<ul class="list-wrapper" (click)="bak($event)">
													<li>
														<ul class="options">
															<div class="m-checkbox u-clearfix"
																*ngFor="let quality of categoryService.allQuality"
																id="quality_{{quality}}">
																<input type="checkbox" class="a-checkbox"
																	id="connected_quality_{{quality}}" />
																<label class="a-label js-a-label custom-box"
																	for="connected_quality_{{quality}}"
																	(click)="filterAttribute('quality',quality,quality, $event)"></label>
																<label class="a-label js-a-label"
																	for="connected_quality_{{quality}}"
																	(click)="filterAttribute('quality',quality,quality, $event)">{{quality}}</label>
															</div>
														</ul>
														<ul class="bottom-option">
															<li>取消</li>
														</ul>
													</li>
												</ul>
												<span class="error-msg"></span>
											</div>
										</div>
									</div>
								</div>
								<div class="filter-buttons" id="mobile-filter-buttons">
									<button type="submit" class="a-button is-primary" (click)="showH5Filter=false">
										<span>提交</span>
									</button>
									<button class="a-button is-secondary is-disabled js-clear"
										[disabled]="canClearAll()" (click)="clearAll()">
										<span>重置</span>
									</button>
								</div>
								<div data-component="OFilterBadges" class="o-filter-badges"></div>
								<a href="#" target="_self" class="a-link js-clear">Clear All</a>
							</div>
						</div>
						<div *ngIf="categoryModel?.showPriceInterval==1" data-component="OProductFilter"
							class="o-product-filter screenNouislider">
							<div class="productlisting">
								<div class="priceFilterWidget-outer">
									<span *ngIf="categoryService.showPriceSlider" class="priceText">价格</span>
									<nouislider class="noSlider" [connect]="true" [min]="categoryService.priceMin"
										[max]="categoryService.priceMax" [step]="categoryService.priceStep"
										[tooltips]="[ true, true ]" [config]="categoryService.priceFilterRangeConfigNew"
										[(ngModel)]="categoryService.priceFilterRange" (end)="filterPrice($event)">
									</nouislider>
									<!--<nouislider *ngIf="categoryService.showPriceSlider" #priceSlider [config]="categoryService.priceFilterRangeConfig" [(ngModel)]="categoryService.priceFilterRange"></nouislider>-->
								</div>
							</div>
						</div>
					</div>
					<div data-component="OProductSort" class="o-product-sort" *ngIf="categoryModel?.hideSort != 1">
						<div class="product-sort-wrapper" [ngClass]="{'is-open':categoryService.showSortFlag}">
							<a target="_self" class="a-link product-sort-link" (click)="showSort($event)">排序/按
								{{categoryService.sortCode}}</a>
							<div class="product-sort-section">
								<h2 class="a-heading-2 product-sort-title">排序/按</h2>
								<span class="a-icon-close" (click)="categoryService.showSortFlag=false"></span>
								<div class="product-sort-content">
									<div class="o-filter">
										<!-- 弹框：加is-open类 -->
										<div data-component="MDropdown"
											class="m-dropdown style-dropdown filter-dropdown"
											[ngClass]="{'is-open' : categoryService.showSortFlag==true}"
											data-filter-dropdown="true" data-product-sort="true">
											<label class="a-label js-a-label placeholder"
												(click)="showSort($event)"><span>排序/按
													{{categoryService.sortCode}}</span></label>
											<span class="a-icon-close"></span>
											<input name="Sort by" value="best" class="js-input" />
											<span class="a-icon-arrow-down-small" (click)="showSort($event)"></span>
											<ul class="list-wrapper is-open" (click)="bak($event)">
												<li>
													<ul class="options">
														<li class="a-option"
															[ngClass]="{'is-selected' : categoryService.sortCode=='最佳匹配'}"
															(click)="changeSort('最佳匹配')">
															<span class="option-name">最佳匹配</span>
														</li>
														<li class="a-option"
															[ngClass]="{'is-selected' : categoryService.sortCode=='最新上架'}"
															(click)="changeSort('最新上架')">
															<span class="option-name">最新上架</span>
														</li>
														<li class="a-option"
															[ngClass]="{'is-selected' : categoryService.sortCode=='价格低至高'}"
															(click)="changeSort('价格低至高')">
															<span class="option-name">价格低至高</span>
														</li>
														<li class="a-option"
															[ngClass]="{'is-selected' : categoryService.sortCode=='价格高至低'}"
															(click)="changeSort('价格高至低')">
															<span class="option-name">价格高至低</span>
														</li>
													</ul>
													<ul class="bottom-option">
														<li>取消</li>
													</ul>
												</li>
											</ul>
											<span class="error-msg"></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


				</div>
			</div>
		</div>


		<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row">
			<div data-component="ProductContainer" class="o-category-listing">
				<div *ngIf="!isVMLogin">
					<div id='products' class="goodsGrid" *ngIf="categoryService.productList; else noProductBlock">
						<!-- [ngStyle]="{'margin-top':categoryModel?.showFilter !==1?'26px':''}" -->
						<ng-container *ngIf="categoryModel?.perLineShowColumn == 4 && isPc; else generalClass">
							
							<div [id]="key" class="column col-span-12 lg:col-span-3 category-list-product-block fourCols"
								*ngFor="let product of categoryService.productList;let key=index;">
								<div *ngIf=" isVMLogin === 1 "
									class="merchClose m-button-icon a-icon-close js-close-button"></div>
								<div class="a-link no-styling">
									<app-product-list (toProductDetail)="toProductDetail($event)" [itemIndex]="key"
										[categoryUrl]="categoryUrl"
										[productModel]="categoryService.productItemList[product]"
										[categoryModel]="categoryModel"
										[favoriteItemIds]="favoriteItemIds"></app-product-list>
								</div>
							</div>
						</ng-container>
						<ng-template #generalClass>
							<div class="column col-span-12 lg:col-span-3 category-list-product-block"
								*ngFor="let product of categoryService.productList;let key=index;">
								<div *ngIf=" isVMLogin === 1 "
									class="merchClose m-button-icon a-icon-close js-close-button"></div>
								<div class="a-link no-styling">
									<app-product-list (toProductDetail)="toProductDetail($event)" [itemIndex]="key"
										[categoryUrl]="categoryUrl"
										[productModel]="categoryService.productItemList[product]"
										[categoryModel]="categoryModel"
										[favoriteItemIds]="favoriteItemIds"></app-product-list>
								</div>
							</div>
						</ng-template>
					</div>
					

					
					<!-- <p *ngIf="categoryService.loading" style="text-align: center">加载中……</p> -->
					<!-- <p *ngIf="categoryService.noData" class="list-bottom-text">已到页面底部</p> -->
					
					<p class="list-bottom-text btn-top" *ngIf="!categoryService.noData">
						<button class="more-btn" type="button" (click)="loadMore()">
							<div class="more-btn-bg" [ngStyle]="{'background': categoryService.moreBackground}">
								<span class="more-text">
									<ng-contaier *ngIf="categoryService.loading;else moreTpl">加载中</ng-contaier>
									<ng-template #moreTpl>
										更多商品
									</ng-template>
									<span id="productsLoaded"> ({{categoryService.loadedCount}}/{{categoryService.productNum}})</span>
								</span>
							</div>
						</button>
					</p>
					
					
				</div>
				<div *ngIf="isVMLogin === 1">
					<div id='products' [dragula]='"nested-bag"'
						*ngIf="categoryService.productList; else noProductBlock">
						<ng-container *ngIf="categoryModel?.perLineShowColumn == 4 && isPc; else vmgeneralClass">
							<div class="column col-span-12 lg:col-span-3 category-list-product-block"
								*ngFor="let product of categoryService.productList;let key=index;">
								<div *ngIf=" isVMLogin === 1 "
									class="merchClose m-button-icon a-icon-close js-close-button"></div>
								<div class="a-link no-styling">
									<app-product-list (toProductDetail)="toProductDetail($event)" [itemIndex]="key"
										[categoryUrl]="categoryUrl"
										[productModel]="categoryService.productItemList[product]"
										[categoryModel]="categoryModel"
										[favoriteItemIds]="favoriteItemIds"></app-product-list>
								</div>
							</div>
						</ng-container>
						<ng-template #vmgeneralClass>
							<div class="column col-span-12 lg:col-span-3 category-list-product-block"
								*ngFor="let product of categoryService.productList;let key=index;">
								<div class="a-link no-styling">
									<app-product-list (toProductDetail)="toProductDetail($event)" [itemIndex]="key"
										[categoryUrl]="categoryUrl"
										[productModel]="categoryService.productItemList[product]"
										[categoryModel]="categoryModel"
										[favoriteItemIds]="favoriteItemIds"></app-product-list>
								</div>
							</div>
						</ng-template>
						<p *ngIf="categoryService.loading" style="text-align: center">加载中……</p>
					</div>
				</div>
			</div>
			<ng-template #noProductBlock>
				<div>无相关商品</div>
			</ng-template>
		</div>
		<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width"
			*ngIf="categoryModel?.metaDescription!=''">
			<div class="contain" [innerHTML]="categoryModel?.metaDescription | html"></div>
		</div>
	</div>



	<ng-template #cetegoryPageBlock>
		<app-cms-advanced *ngIf="categoryModel?.cmsPageId" [is_category]="true" [page_id]="page_id"></app-cms-advanced>
	</ng-template>

</div>
<ng-template class="section-summary shopaddress" #listLoadingBlock>
	<div class="customer-data setAddress pageLoading">
		加载中......
	</div>
</ng-template>
<div *ngIf=" isVMLogin === 1 " class="topZoom">
	<span>{{vmZoom}}%</span>
	<a (click)="vmZoomToggle(1)" href="javascript:void(0)" class="a-icon-close"></a>
	<a (click)="vmZoomToggle(2)" href="javascript:void(0)" class="a-icon-arrow-down-small"></a>
	<button class="btnBox" (click)="vmZoomToggle(3)">重置</button>
	<a *ngIf="categoryService.isVmPreview == 0" class="btnBox" (click)="vmToPreview(true)"
		routerLink="/{{categoryUrl}}.html" target="_blank">预览</a>
	<button *ngIf="categoryService.isVmPreview == 1" class="btnBox" (click)="vmToPreview(false)">清除预览</button>
</div>

<ng-container *ngIf="vmService.validate === true">
	<div data-component="OLightbox" class="o-lightbox is-remove-confirm is-open">
		<div class="lightbox-header"></div>
		<div class="lightbox-content js-content u-clearfix">
			<div class="o-remove-confirm">
				<h2 class="a-heading-2">确定要保存Banner吗？</h2>
				<p class="a-paragraph"></p>
				<a id="removeAddressLink" class="a-button is-primary" href="javascript:;"
					(click)="vmMerchSure(1)"><span>Yes</span></a>
				<button class="a-button is-secondary js-close-button" (click)="vmMerchSure(0)"><span>No</span></button>
			</div>
		</div>
		<div class="m-button-icon a-icon-close js-close-button" (click)="vmMerchSure(0)"></div>
	</div>
</ng-container>