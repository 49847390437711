/*config引入*/
import { RemoteServer } from './config/config.remoteServer';
/*Module引入*/
import { BrowserModule, BrowserTransferStateModule, Meta } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
//import { Location } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { Http, HttpModule} from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingCache } from './app-routing.cache';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Angulartics2Module } from 'angulartics2';
import { LazyLoadImageModule } from 'ng-lazyload-image';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime/date-time';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
/*Service引入*/
import { InitializeService } from './services/initialize.service';
import { AddressService } from './services/address.service';
import { CheckoutService } from './services/checkout.service';
import { CartService } from './services/cart.service';
import { CmsService } from './services/cms.service';
import { ResetpasswordService } from './services/resetpassword.service';
import { ScriptService } from './services/script.service';
import { PagingService } from './services/paging.service';
import { CategoryService } from './services/category.service';
import { SessionStorageService } from 'angular-web-storage';
import { StorageService } from './services/storage.service';
import { CustomerService } from './services/customer.service';
import { RmaService } from './services/rma.service';
import { OrderService } from './services/order.service';
import { PaymentService } from './services/payment.service';
import { TrackingcodeService } from './services/trackingcode.service';
import { VmService } from './services/vm.service';
import { NavigationService } from './services/navigation.service';
import { FavoriteService } from './services/favorite.service';
import { UdeskService } from './services/udesk.service';
import { AgreementService } from './services/agreement.service';
import { AbTrackingService } from './services/abtracking.service';
import { SensorsTrackingService } from './services/sensors.service';
import { CosbyyouService } from './services/cosbyyou.service';
import { CryptoService } from './services/crypto.service';
import { BreadcrumbService } from './services/breadcrumb.service';




/*Component引入*/
import { AppComponent } from './app.component';
import { ProductComponent } from './pages/product/product.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { AccountComponent } from './pages/account/account.component';
import { AccountOrderDetailComponent } from './pages/account-order-detail/account-order-detail.component';
import { AccountOrderComponent } from './pages/account-order/account-order.component';
import { AccountLoginComponent } from './pages/account-login/account-login.component';
import { AccountRegisterComponent } from './pages/account-register/account-register.component';
import { ForgetPasswordComponent } from './pages/account-forget-password/forget-password.component';
import { AccountEditComponent } from './pages/account-edit/account-edit.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { ShoppingCartItemComponent } from './components/shopping-cart/shopping-cart-item/shopping-cart-item.component';
import { ForgetPasswordFormComponent } from './components/account/forget-password/forget-password-form/forget-password-form.component';
import { AccountResetPasswordComponent } from './pages/account-reset-password/account-reset-password.component';
import { ResetPasswordFormComponent } from './components/account/forget-password/reset-password-form/reset-password-form.component';
import { CategoryListComponent } from './pages/category-list/category-list.component';
import { DeliveryAddressComponent } from './components/account/delivery-address/delivery-address.component';
import { CheckoutAddressComponent } from './components/checkout/checkout-address/checkout-address.component';
import { CheckoutItemComponent } from './components/checkout/checkout-item/checkout-item.component';
import { CheckoutPaymentComponent } from './components/checkout/checkout-payment/checkout-payment.component';
// import {AccountCouponComponent} from './pages/account-coupon/account-coupon.component';
import { ProductMainImageSlideComponent } from './components/product/product-main-image-slide/product-main-image-slide.component';
import { CheckoutShippingComponent } from './components/checkout/checkout-shipping/checkout-shipping.component';
import { RmaApplyComponent } from './pages/rma-apply/rma-apply.component';
import { ProductService } from './services/product.service';
import { RmaViewComponent } from './pages/rma-view/rma-view.component';
import { StoreLocatorComponent } from './pages/store-locator/store-locator.component';
import { ChangePasswordComponent } from './components/account/change-password/change-password.component';
import { MiniCartComponent } from './components/mini-cart/mini-cart.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { RequestService } from './services/request.service';
import { BreadcrumbComponent } from './components/common/breadcrumb/breadcrumb.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { FavoriteBoxComponent } from './components/common/favorite-box/favorite-box.component';
import { ShippingToComponent } from './pages/shipping-to/shipping-to.component';
import { CanNotGoToNext, SignInComponent } from './components/common/sign-in/sign-in.component';
import { LoginGuard } from './components/guard/login.guard';
import { CallComponentService } from './services/callcomponent.service';
import { StoreLocatorService } from './services/storelocator.service';
import { CmsBlockEditorComponent } from './components/cms/advanced/cms-block-editor/cms-block-editor.component';
import { CmsBlockReusableEditorComponent } from './components/cms/advanced/cms-block-reusable-editor/cms-block-reusable-editor.component';
import { CmsBlockReusableComponent } from './components/cms/advanced/cms-block-reusable/cms-block-reusable.component';
import { VmPanelComponent } from './components/vm-panel/vm-panel.component';
import { VmPanelSearchComponent } from './components/vm-panel/vm-panel-search/vm-panel-search.component';
import { VmPanelMerchComponent } from './components/vm-panel/vm-panel-merch/vm-panel-merch.component';
import { VmPanelCmsComponent } from './components/vm-panel/vm-panel-cms/vm-panel-cms.component';
import { VmToolsLoginComponent } from './pages/vm-tools-login/vm-tools-login.component';
import { CmsBlockComponent } from './components/cms/advanced/cms-block/cms-block.component';
import { CmsBlockSingleProductEditorComponent } from './components/cms/advanced/cms-block-single-product-editor/cms-block-single-product-editor.component';
import { CmsBlockSingleProductComponent } from './components/cms/advanced/cms-block-single-product/cms-block-single-product.component';
import { CmsBlockMultiProductEditorComponent } from './components/cms/advanced/cms-block-multi-product-editor/cms-block-multi-product-editor.component';
import { CmsBlockMultiProductComponent } from './components/cms/advanced/cms-block-multi-product/cms-block-multi-product.component';
import { CmsBlockSwiperComponent } from './components/cms/advanced/cms-block-swiper/cms-block-swiper.component';
import { CmsBlockSwiperEditorComponent } from './components/cms/advanced/cms-block-swiper-editor/cms-block-swiper-editor.component';
import { CmsBlockLpComponent } from './components/cms/advanced/cms-block-lp/cms-block-lp.component';
import { CmsBlockLpEditorComponent } from './components/cms/advanced/cms-block-lp-editor/cms-block-lp-editor.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { DragulaModule } from 'ng2-dragula';
import { CmsAdvancedComponent } from './pages/cms-advanced/cms-advanced.component';
import { ProductColorDropdownComponent } from './components/product/product-color-dropdown/product-color-dropdown.component';
import { ProductSizeBarComponent } from './components/product/product-size-bar/product-size-bar.component';
import { ProductSizeBoxComponent } from './components/product/product-size-box/product-size-box.component';
import { ProductPromotionTagsComponent } from './components/product/product-promotion-tags/product-promotion-tags.component';
import { ProductDescriptionComponent } from './components/product/product-description/product-description.component';


import { ProductSwiperComponent } from './components/product/product-swiper/product-swiper.component';
import { ProductListComponent } from './components/product/product-list/product-list.component';
import { RmaAccountListComponent } from './pages/rma-account-list/rma-account-list.component';
import { RmaReturnComponent } from './pages/rma-return/rma-return.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { RmaAccountListDetailComponent } from './pages/rma-account-list-detail/rma-account-list-detail.component';
import { SearchComponent } from './pages/search/search.component';
import { SearchService } from './services/search.service';
import { RecommendStyleComponent } from './components/recommend-style/recommend-style.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { TextTrimmingPipe } from './pipes/text-trimming.pipe';
import { DragulaService } from '../server_mocks/ng2-dragula-stub/dragula.service';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SystermExceptionComponent } from './pages/systerm-exception/systerm-exception.component';
import { WeixinShareComponent } from './components/share/weixin-share/weixin-share.component';
import { ProductMainImageComponent } from './components/product/product-main-image/product-main-image.component';
import { AccountLogoutComponent } from './pages/account-logout/account-logout.component';
import { AccountLogoutCheckComponent } from './pages/account-logout-check/account-logout-check.component';
import { AccountLogoutReasonComponent } from './pages/account-logout-reason/account-logout-reason.component';
import { AccountLogoutSuccessComponent } from './pages/account-logout-success/account-logout-success.component';
import { AccountLogoutErrorComponent } from './pages/account-logout-error/account-logout-error.component';
import { AccountLogoutProcessingComponent } from './pages/account-logout-processing/account-logout-processing.component';
import { QrCodeComponent } from './pages/qr-code/qr-code.component';
import { AccountCouponComponent } from './pages/account-coupon/account-coupon.component';
import { AccountPointsComponent } from './pages/account-points/account-points.component';
import { AccountRedeemCouponsComponent } from './pages/account-redeem-coupons/account-redeem-coupons.component';
import { AccountFavoriteComponent } from './pages/account-favorite/account-favorite.component';
import { ProductArrivalRemindComponent } from './components/product/product-arrival-remind/product-arrival-remind.component';
import { CmsBlockArrivalReminderProductComponent } from './components/cms/advanced/cms-block-arrival-reminder-product/cms-block-arrival-reminder-product.component';
import { CmsBlockArrivalReminderProductEditorComponent } from './components/cms/advanced/cms-block-arrival-reminder-product-editor/cms-block-arrival-reminder-product-editor.component';
import { CmsBlockCategoryLpEditorComponent } from './components/cms/advanced/cms-block-category-lp-editor/cms-block-category-lp-editor.component';
import { CmsBlockCategoryLpComponent } from './components/cms/advanced/cms-block-category-lp/cms-block-category-lp.component';
import { NouisliderModule } from 'ng2-nouislider';
import { InvoiceApplyComponent } from './pages/invoice-apply/invoice-apply.component';
import { InvoiceDetailComponent } from './pages/invoice-detail/invoice-detail.component';
import { VjsPlayerComponent } from './components/vjs-player/vjs-player.component';
import { PresaleCheckoutComponent } from './pages/presale-checkout/presale-checkout.component';
import { PresaleNoticeComponent } from './components/checkout/presale-notice/presale-notice.component';
import { AgreementRedirectComponent } from './pages/agreement-redirect/agreement-redirect.component';
import { AgreementAuthorizationComponent } from './pages/agreement-authorization/agreement-authorization.component';
import { LogisticsComponent } from './pages/logistics/logistics.component';
import { CmsBlockTabEditorComponent } from './components/cms/advanced/cms-block-tab-editor/cms-block-tab-editor.component';
import { CmsBlockTabLpComponent } from './components/cms/advanced/cms-block-tab-lp/cms-block-tab-lp.component';
import { CosbyyouImageDetailComponent } from './pages/cosbyyou/image-detail/image-detail.component';
import { ProductCosbyyouComponent } from './components/product/product-cosbyyou/product-cosbyyou.component';
import { ProductStrategyTagComponent } from './components/product/product-strategy-tag/product-strategy-tag.component';
import { UdeskComponent } from './pages/udesk/udesk.component';
import { TompComponent } from './pages/tomp/tomp.component';
import {CosHeaderComponent} from './components/common/cos-header/cos-header.component';
import {PrivacyPolicyComponent} from './components/common/privacy-policy/privacy-policy.component';
import {CosFooterComponent} from './components/common/cos-footer/cos-footer.component';
import {CheckoutOrderComponent} from './components/checkout/checkout-order/checkout-order.component';
import {RecommendCommonComponent} from './components/recommend-common/recommend-common.component';
import {QuickAddComponent} from './components/quick-add/quick-add.component';
import {SelectCountryComponent} from './components/country/select-country.component';


export const ROUTES : Routes = [];
export function createTranslateHttpLoader(http : HttpClient) {
	return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const DEFAULT_SWIPER_CONFIG : SwiperConfigInterface = {
	observer: true,
	direction: 'horizontal',
	threshold: 50,
	spaceBetween: 5,
	slidesPerView: 'auto',
	centeredSlides: true
};

const OWL_DATE_TIME_LOCALE_VALUE = 'zh-cn';

@NgModule({

	declarations: [
		AppComponent,
		VmPanelComponent,
		VmPanelSearchComponent,
		VmPanelMerchComponent,
		VmPanelCmsComponent,
		VmToolsLoginComponent,
		CmsAdvancedComponent,
		CmsBlockEditorComponent,
		CmsBlockReusableEditorComponent,
		CmsBlockReusableComponent,
		CmsBlockComponent,
		CmsBlockSingleProductEditorComponent,
		CmsBlockSingleProductComponent,
		CmsBlockMultiProductEditorComponent,
		CmsBlockMultiProductComponent,
		CmsBlockSwiperComponent,
		CmsBlockSwiperEditorComponent,
		CmsBlockLpComponent,
		CmsBlockLpEditorComponent,
		CmsBlockArrivalReminderProductComponent,
		CmsBlockArrivalReminderProductEditorComponent,
		CmsBlockCategoryLpComponent,
		CmsBlockCategoryLpEditorComponent,
		CmsBlockTabEditorComponent,
		CmsBlockTabLpComponent,
		ProductComponent,
		ShoppingCartComponent,
		CheckoutComponent,
		AccountComponent,
		AccountOrderDetailComponent,
		AccountOrderComponent,
		AccountLoginComponent,
		AccountRegisterComponent,
		ForgetPasswordComponent,
		AccountEditComponent,
		CosHeaderComponent,
		PrivacyPolicyComponent,
		FooterComponent,
    CosFooterComponent,
		NavbarComponent,
		CategoryListComponent,
		ShoppingCartItemComponent,
		SearchComponent,
		ForgetPasswordFormComponent,
		AccountResetPasswordComponent,
		ResetPasswordFormComponent,
		DeliveryAddressComponent,
		CheckoutAddressComponent,
		CheckoutComponent,
		CheckoutItemComponent,
		CheckoutPaymentComponent,
		ProductMainImageSlideComponent,
		CheckoutShippingComponent,
	  CheckoutOrderComponent,
		RmaApplyComponent,
		RmaViewComponent,
		StoreLocatorComponent,
		ChangePasswordComponent,
		MiniCartComponent,
    SelectCountryComponent,
		SafeHtmlPipe,
		SafeStylePipe,
		TextTrimmingPipe,
		BreadcrumbComponent,
		LoadingComponent,
		FavoriteBoxComponent,
		ShippingToComponent,
		SignInComponent,
		ProductMainImageSlideComponent,
		ProductColorDropdownComponent,
		ProductSizeBarComponent,
		ProductSizeBoxComponent,
		ProductPromotionTagsComponent,
		ProductDescriptionComponent,
		ProductSwiperComponent,
		ProductListComponent,
		RmaReturnComponent,
		PaymentComponent,
		PaymentSuccessComponent,
		RmaAccountListComponent,
		RmaAccountListDetailComponent,
		RecommendStyleComponent,
    RecommendCommonComponent,
    QuickAddComponent,
		NotFoundComponent,
		SystermExceptionComponent,
		WeixinShareComponent,
		ProductMainImageComponent,
		AccountLogoutComponent,
		AccountLogoutCheckComponent,
		AccountLogoutReasonComponent,
		AccountLogoutSuccessComponent,
		AccountLogoutErrorComponent,
		AccountLogoutProcessingComponent,
		QrCodeComponent,
		AccountCouponComponent,
		AccountFavoriteComponent,
		ProductArrivalRemindComponent,
		AccountPointsComponent,
		AccountRedeemCouponsComponent,
		InvoiceApplyComponent,
		InvoiceDetailComponent,
		VjsPlayerComponent,
		PresaleCheckoutComponent,
		PresaleNoticeComponent,
		AgreementRedirectComponent,
		AgreementAuthorizationComponent,
		LogisticsComponent,
		CosbyyouImageDetailComponent,
		ProductCosbyyouComponent,
		ProductStrategyTagComponent,
		UdeskComponent,
		TompComponent
	],
	imports: [
		// OwlDateTimeModule,
		// OwlNativeDateTimeModule,
		BrowserAnimationsModule,
		SwiperModule,
		HttpModule,
		CKEditorModule,
		DragulaModule,
		NouisliderModule,
		BrowserModule.withServerTransition({ appId: 'official-site' }),
		AppRoutingModule,
		BrowserTransferStateModule,
		RouterModule.forRoot(ROUTES),
		TransferHttpCacheModule,
		HttpClientModule,
		FormsModule,
		//Location,
		ReactiveFormsModule,
		TabsModule.forRoot(),
		ModalModule.forRoot(),
		CarouselModule.forRoot(),
		PaginationModule.forRoot(),
		BsDropdownModule.forRoot(),
		BsDatepickerModule.forRoot(),
		Angulartics2Module.forRoot(),
		LazyLoadImageModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateHttpLoader),
				deps: [Http]
			}
		})
	],
	entryComponents: [],
	exports: [],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
	providers: [
		{
			provide: ViewportScroller,
			useValue: ViewportScroller
		},
		{
			provide: SWIPER_CONFIG,
			useValue: DEFAULT_SWIPER_CONFIG,
		},
		// {
		// 	provide: OWL_DATE_TIME_LOCALE,
		// 	useValue: OWL_DATE_TIME_LOCALE_VALUE,
		// },
		//Location,
	//	Title,
		Meta,
		Angulartics2GoogleGlobalSiteTag,
		BreadcrumbService,
		RequestService,
		SessionStorageService,
		StorageService,
		InitializeService,
		ProductService,
		AddressService,
		CategoryService,
		CheckoutService,
		CmsService,
		CustomerService,
		CartService,
		ScriptService,
		ResetpasswordService,
		PagingService,
		RemoteServer,
		CallComponentService,
		StoreLocatorService,
		RmaService,
		LoginGuard,
		NavigationService,
		CanNotGoToNext,
		OrderService,
		PaymentService,
		SearchService,
		TrackingcodeService,
		VmService,
		DragulaService,
		FavoriteService,
		UdeskService,
		AgreementService,
		AbTrackingService,
		SensorsTrackingService,
		CosbyyouService,
		CryptoService,
		{ provide: RouteReuseStrategy, useClass: AppRoutingCache }
	],
	bootstrap: [AppComponent]
})

export class AppModule {
	constructor() {
	}
}
