
    <div [ngSwitch]="block.type">
      <div *ngSwitchCase="'customHtml'">
        <div *ngIf="block.html !== undefined" [innerHTML]="block.html | html"></div>
      </div>
      <div *ngSwitchCase="'textEditor'">
        <div *ngIf="block.html !== undefined" [innerHTML]="block.html | html"></div>
      </div>
      <app-cms-block-single-product *ngSwitchCase="'singleProduct'" [block]="block"
                                    [editMode]="editMode"></app-cms-block-single-product>
      <app-cms-block-multi-product *ngSwitchCase="'multiProduct'" [block]="block"
                                   [editMode]="editMode"></app-cms-block-multi-product>
      <app-cms-block-arrival-reminder-product *ngSwitchCase="'arrivalReminderProduct'" [block]="block"
                                              [editMode]="editMode"></app-cms-block-arrival-reminder-product>
      <app-cms-block-swiper *ngSwitchCase="'swiper'" [block]="block" [editMode]="editMode"></app-cms-block-swiper>
      <app-cms-block-reusable *ngSwitchCase="'reusable'" [block]="block" [editMode]="editMode"></app-cms-block-reusable>
      <app-cms-block-lp *ngSwitchCase="'lp'" [block]="block" [editMode]="editMode"></app-cms-block-lp>
      <app-cms-block-category-lp *ngSwitchCase="'cloudLp'" [block]="block" [editMode]="editMode"></app-cms-block-category-lp>
      <app-cms-block-tab-lp *ngSwitchCase="'cloudTab'" [block]="block" [editMode]="editMode" [containerIndex] = "containerIndex" [blockIndex] = "blockIndex"></app-cms-block-tab-lp>
    </div>
  