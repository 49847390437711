import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {RmaService} from '../../services/rma.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-rma-view',
  templateUrl: './rma-view.component.html',
  styleUrls: ['./rma-view.component.css']
})
export class RmaViewComponent implements OnInit {
  public rma:any;
  public rma_id:any;
  public rma_increment_id:any;
  public rma_passcode:any;
  constructor(
    private routerInfo: ActivatedRoute,
    public rmaService: RmaService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    this.rma_increment_id = this.rmaService.rma_increment_id;
    this.rma_passcode = this.rma_passcode;
  }

  ngOnInit() {
    this.routerInfo.queryParams.subscribe(parmas => {
      this.rma_id = parmas.id;
      if (!this.rma_increment_id || !this.rma_passcode) {
        if (isPlatformBrowser(this.platformId)) {
          this.rmaService.getRma(this.rma_id).then(result => {
            if (result.code * 1 === 1000) {
              this.rma = result.data;
              this.rmaService.rmaData = result.data;
            } else {
              this.router.navigate(['/']);
            }
          }).catch(error => error);
        }
      }
    });
  }
}
