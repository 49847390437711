import {Component, Input, OnInit} from '@angular/core';
import {InitializeService} from '../../../../services/initialize.service';
import $ from 'jquery';
@Component({
  selector: 'app-cms-block-swiper',
  template: `
    <div class="swiper-container u-cols-sm-12-12 u-cols-md-12-12 u-cols-sm-6-12 u-row" [swiper]="block.data.config" (indexChange)="onIndexChange($event)">
      <div class="swiper-wrapper">
        <div *ngFor="let slide of block.data.slides; let i = index" class="swiper-slide swiper-slide{{i}}" [ngClass]="{'swiper-no-swiping': block.data.swiperstate[i] && init.IsPC()}" [innerHTML]="slide | html">
        </div>
      </div>

      <div class="swiper-scrollbar" [hidden]="block.data.config.scrollbar === false"></div>
      <div class="swiper-pagination" [hidden]="block.data.config.pagination === false"></div>

      <div class="swiper-button-prev" [hidden]="block.data.config.navigation === false"></div>
      <div class="swiper-button-next" [hidden]="block.data.config.navigation === false"></div>
    </div>
  `,
  styles: []
})
export class CmsBlockSwiperComponent implements OnInit {
	@Input() block:any;
	@Input() editMode:any;
	// mySwiperPc:any;
	public isPc:boolean = true;
	constructor(
	 public init: InitializeService) {	
	}

  ngOnInit() {
    if (!this.block.hasOwnProperty('data')) {
      this.block.data = {};
    }

    if (!this.block.data.hasOwnProperty('slides')) {
      this.block.data.slides = [];
    }
	
	if (!this.block.data.hasOwnProperty('swiperstate')) {
	  this.block.data.swiperstate = [];
	}

    if (!this.block.data.hasOwnProperty('config')) {
		this.block.data.config = {
			spaceBetween:0,
			direction: 'horizontal',
			slidesPerView: 1,
			keyboard: true,
			mousewheel: true,
			scrollbar: false,
			pagination: false,
			navigation: true
		};
    }
	
	this.isPc = this.init.IsPC();
	if(!this.isPc){
		this.block.data.config.slidesPerView = this.block.data.config.mobSlidesPerView;
		this.block.data.config.initialSlide =  this.block.data.config.mobInitialSlide;
		var pcSlides = this.block.data.slides;
		var slidersNum = this.block.data.config.mobSlidersNum;
		var mobSlides = pcSlides.filter((_value:any, index:any) => index <= slidersNum - 1);
		this.block.data.slides = mobSlides;	
	}
	
	if(this.isPc){
		 var mySwiperPc = new Swiper('.swiper-container',{
			direction: this.block.data.config.direction,
			slidesPerView: this.block.data.config.slidesPerView,
			initialSlide: this.block.data.config.initialSlide,
			spaceBetween:this.block.data.config.spaceBetween,
			observer: true
		 });
	}else{
		var mySwiper = new Swiper('.swiper-container',{
			direction: this.block.data.config.direction,
			slidesPerView: this.block.data.config.slidesPerView,
			initialSlide: this.block.data.config.initialSlide,
			spaceBetween:this.block.data.config.spaceBetween,
			observer: true,
			onTap: function(_swiperObject) {
				if (mySwiper !== undefined && mySwiper !== null) {
					if(!this.isPc){
						$('#men-title').click(function() {
						  // var elementId = this.id;
						  // console.log(elementId);
						  // console.log('H5点击======men-title====》》》》')
						  // if(mySwiper.activeIndex == 1){
						  // 	mySwiper.slidePrev();
						  // }else{
						  	mySwiper.slideNext();
						  // }
						  
						});
						
						$('#women-title').click(function() {
						  // var elementId = this.id;
						  // console.log(elementId);
						   mySwiper.slidePrev();
						});
					}
				}
			}
		})
	}

  }
  
  onIndexChange(_index: number) {
	  
  }
 
  clickSlide(index:any){
	if(this.isPc){
		var mySwiperPc = new Swiper('.swiper-container',{
			direction: this.block.data.config.direction,
			slidesPerView: this.block.data.config.slidesPerView,
			initialSlide: this.block.data.config.initialSlide,
			spaceBetween:this.block.data.config.spaceBetween,
			observer: true
		});
 
	  	//开始页，PC一屏显示两帧
	  	if(this.block.data.config.initialSlide == 1 && this.block.data.config.slidesPerView == 2){
	  		if(index == 1){
				$('#women-title').hide();
	  			mySwiperPc.slidePrev();
	  		}else if(index == 2){
	  			$('#men-title').hide();
	  			mySwiperPc.slideNext();
	  		}
	  	}
	  }else{
		  // var mySwiper = new Swiper('.swiper-container',{
		  // 	direction: this.block.data.config.direction,
		  // 	slidesPerView: this.block.data.config.slidesPerView,
		  // 	initialSlide: this.block.data.config.initialSlide,
		  // 	spaceBetween:this.block.data.config.spaceBetween,
		  // 	observer: true,
		  // 	// onTap: function(_swiperObject) {
		  		
		  // 	// }
		  // })
		  // if (mySwiper !== undefined && mySwiper !== null) {
		  // 	if(!this.isPc){
		  // 		if(mySwiper.activeIndex == 1){
		  // 			mySwiper.slidePrev();
		  // 		}else{
		  // 			mySwiper.slideNext();
		  // 		}
		  // 	}
		  // }
		  // console.log('111111111111111111111')
	  }
	  
  }
}
