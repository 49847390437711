<div class="myAccount_right">
  <div *ngIf="rmaService.loading;else contentBlock" style="text-align: center"> 获取中... </div>
  <ng-template #contentBlock>
   <!-- <div class="topSection clearfix">
      <h5 class="pull-left">退货订单</h5>
    </div>-->
    <div *ngIf="rmaService.rmaData.total > 0;else notContent" class="hidden-sm hidden-xs">
      <table width="100%" border="0" cellspacing="0" cellpadding="0" class="orderTable" *ngFor="let rma of rmaService.rmaData.content">
        <tr class="font_s_medium font-size-12">
          <th width="30%"><span class="orderNumber">服务单号：</span><a class="a-link">{{rma.refundCode}}</a></th>
          <th colspan="3">申请日期：{{rma.createTime.substr(0,10)}}</th>
          <th width="23%">退货状态</th>
          <th width="20%">操作</th>
        </tr>
        <tr  *ngFor="let rmaItem of rma.items">
          <td colspan="4">
            <div class="gInfo clearfix">
              <div class="pull-left listImg">
                <a routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}" class="a-link"><img src="{{orderService.analysisProductImage(rmaItem.skuImageUrl, rmaItem.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
              </div>
              <div class="pull-left textInfo" style="width: 60%">
                <strong><a routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}" class="a-link">{{rmaItem.itemName}}</a></strong>
                <p>货号：{{rmaItem.skuCode}}</p>
                <p>颜色：{{rmaService.analysisProductColor(rmaItem.skuProperties).valueName}}</p>
                <p>尺码：{{rmaService.sizeConvert(rmaService.analysisProductSize(rmaItem.skuProperties).valueName)}}</p>
              </div>
              <span class="pull-right quantity">共{{rmaItem.num}}件商品</span>
            </div>
          </td>
          <td>
            <p class="text-center">{{rmaService.refundStateTranslateArr[rma.status]}}</p>
          </td>
          <td>
            <p class="a_detailed text-center"><a [routerLink]="['/my-account/rma-detail']" [queryParams]="{id: rma.refundId}" class="a-link">退货详情</a></p>
            <p class="btnCont" ><a *ngIf="(!rma.expressName || !rma.trackingNumber) && rma.status !== 'WAIT_SELLER_AGREE' && rma.status !== 'CLOSED' && rma.status !== 'SUCCESS'" [routerLink]="['/my-account/rma-return']" [queryParams]="{id: rma.refundId}" class="a-button is-primary a_logistics">填写退货物流信息</a></p>
          </td>
        </tr>
      </table>
    </div>
    <div class="orderTable_Mob hidden-lg hidden-md">
      <div class="orderItem" *ngFor="let rma of rmaService.rmaData.content;let i=index;">
        <div class="topBox font_s_medium font-size-12">
          <span class="ordNumber font_si_xs_medium font-size-12">{{rma.refundCode}}</span>
          <span class="time font_si_xs_medium font-size-12"style="margin-left:-11px;">{{rma.createTime.substr(0,10)}}</span>
          <span class="state pull-right">{{rmaService.refundStateTranslateArr[rma.status]}}
        </span>
        </div>

        <div class="gInfo clearfix" *ngFor="let rmaItem of rma.items">
          <div class="pull-left listImg">
            <a class="a-link" routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}"><img src="{{orderService.analysisProductImage(rmaItem.skuImageUrl, rmaItem.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
          </div>
          <div class="pull-left textInfo" style="width: 60%">
            <strong><a routerLink="/{{rmaService.productUrl(rmaItem.skuProperties)}}" class="a-link">{{rmaItem.itemName}}</a></strong>
            <p>货号：{{rmaItem.skuCode}}</p>
            <p>颜色：{{rmaService.analysisProductColor(rmaItem.skuProperties).valueName}}</p>
            <p>尺码：{{rmaService.sizeConvert(rmaService.analysisProductSize(rmaItem.skuProperties).valueName)}}</p>
          </div>
          <div class="pull-right total text-right">
            <span>共{{rmaItem.num}}件</span>
          </div>
        </div>
        <div class="button_section clearfix">
          <a [routerLink]="['/my-account/rma-detail']" [queryParams]="{id: rma.refundId}" class="a-button is-primary logistics">退货详情</a>
          <a *ngIf="(!rma.expressName || !rma.trackingNumber) && rma.status !== 'WAIT_SELLER_AGREE' &&  rma.status !== 'CLOSED' && rma.status !== 'SUCCESS'" [routerLink]="['/my-account/rma-return']" [queryParams]="{id: rma.refundId}" class="a-button is-primary logistics">填写退货物流信息</a>
        </div>
      </div>
    </div>
    <div *ngIf="rmaService.rmaData.total > 1" class="pagination">
      <pagination  [(ngModel)]="rmaService.currentPage" [totalItems]="rmaService.rmaData.total" previousText="上一页" nextText="下一页" [rotate]="false" [maxSize]="5" [itemsPerPage]="rmaService.pageSize" (pageChanged)="handlePaginationEvent($event)"></pagination>
    </div>

    <ng-template #notContent>
      <div class="o-my-orders-empty">
        <p class="a-paragraph orders-empty-text" style="text-align: center">您还没有退货的商品</p>
      </div>
    </ng-template>
  </ng-template>
</div>
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': rmaService.applyCancel}" data-component="AOverlay" (click)="rmaService.closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="rmaService.applyCancel" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">您确认取消订单吗？</h2>
      <p class="a-paragraph">&nbsp;</p>
      <a class="a-button is-primary" (click)="confirmCancel()">确认取消</a>
      <button class="a-button is-secondary js-close-button" (click)="rmaService.closeOverlay()"><span>再考虑下</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>

