import {Component, Input, OnInit} from '@angular/core';

@ Component({
  selector: 'app-cms-block-tab-editor',
  templateUrl: './cms-block-tab-editor.component.html',
  styleUrls: []
})
export class CmsBlockTabEditorComponent implements OnInit {

  @Input() block :  any;

  constructor() {
  }

  ngOnInit() {
  }

  addTab() {
    this.block.data.tabs.push({
      'type' : 'category',
      'cid': '',
      'count': '12',
      'inputItems': '',
      'showType': 'vertical',
      'width': '100',
      'is_customize': 0,
      'html_content': '',
      'isBig': 0,
      isShowCollect: 0,
      showMajorUrl: 1,
      rows: 'threeLines'
    });
  }

  delTab(pointer: any) {
    this.block.data.tabs.splice(pointer, 1);
  }

}
