import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {CmsPageModel} from '../models/cms.page.model';
import {CmsBlockModel} from '../models/cms.block.model';
import {CmsAdvancedPageModel} from '../models/cms.advanced.page.model';

const localConfig = require('../config/config.local.json');
const apiConfig = require('../config/config.api.json');


@Injectable()
export class CmsService {

  public pages:any = [];
  public blocks:any = [];
  public advancedPages:any = [];
  public cms_timestamp: any = {};
  public headerTips = ''; // 顶部公告信息
  public advancedPagePathPrefix = '/';

  public sharedData:any = {
    pageModel: <CmsAdvancedPageModel> {},
    containers: [],
    showData: false,
    previewMode: false,
    isNewPage: false,
    editMode: false
  };

  public showCmsPanel:any = false;

  constructor(private init: InitializeService,
              private request: RequestService) {
  }

  /**
   * Check whether cms is edit mode
   * @return {boolean}
   */
  public isEditMode(): boolean {
    return this.init.showVmPanel();
  }


  /**
   * 从API中获取页面数据
   * @param {number} id
   * @param preview
   * @returns {Promise<any>}
   */
  getPageData(id: string, preview = false): Promise<any> {
    return new Promise((resolve) => {
      this.request.list(apiConfig.pagesApi + '?identifier=' + id + '&preview=' + preview + '&storeId=' + this.init.storeId).toPromise().then(
        (res:any) => {
          const page = new CmsPageModel(res['message']);
          page.id = id;
          page.loadTimestamp = new Date().valueOf();
          this.pages.push(page);
          resolve(page);
        }
      ).catch(error => error);
    });
  }


  /**
   * 获取页面
   * @param {number} id
   * @returns {Promise}
   */
  getPage(id: string): Promise<any> {
    let page:any;
    if (this.pages.length > 1) {
      const tmpItem = this.pages.find((_item:any) => _item.id === id);
      if (tmpItem !== undefined) {
        if (tmpItem.loadTimestamp > (new Date().valueOf() - localConfig.dataLifetime * 1000)) {
          page = tmpItem;
        } else {
          const index = this.pages.indexOf(tmpItem);
          if (index > -1) {
            this.pages.splice(index, 1);
          }
        }
      }
    }
    if (page === undefined) {
      return this.getPageData(id);
    } else {
      const promise = new Promise((resolve) => {
        resolve(page);
      });
      return promise;
    }
  }

  /**
   * 从API中获取块数据
   * @param {number} id
   * @param preview
   * @returns {Promise<any>}
   */
  getBlockData(id: string, preview = false): Promise<any> {
    return new Promise((resolve) => {
      // console.log(apiConfig.pagesApi + '?identifier=' + JSON.stringify({id}) + '&preview=' + preview + '&storeId=' + this.init.storeId);
      this.request.list(apiConfig.pagesApi + '?identifier=' + JSON.stringify({id}) + '&preview=' + preview + '&storeId=' + this.init.storeId).toPromise().then(
        (res:any)=> {
          const block = new CmsBlockModel({content: res['message'][id]});
          block.id = id;
          block.loadTimestamp = new Date().valueOf();
          this.blocks.push(block);
          resolve(block);
        }
      ).catch(error => error);
    });
  }


  /**
   * 获取内容块
   * @param {number} id
   * @returns {Promise}
   */
  getBlock(id: string): Promise<any> {
    let block:any;
    if (this.blocks.length > 1) {
      const tmpItem = this.blocks.find((_item:any) => _item.id === id);
      if (tmpItem !== undefined) {
        if (tmpItem.loadTimestamp > (new Date().valueOf() - localConfig.dataLifetime * 1000)) {
          block = tmpItem;
        } else {
          const index = this.blocks.indexOf(tmpItem);
          if (index > -1) {
            this.blocks.splice(index, 1);
          }
        }
      }
    }
    if (block === undefined) {
      return this.getBlockData(id);
    } else {
      const promise = new Promise((resolve) => {
        resolve(block);
      });
      return promise;
    }
  }

  /**
   * 从API中获取页面数据
   * @param {number} id
   * @param preview
   * @returns {Promise<any>}
   */
  getAdvancedPageData(id: string, preview = false): Promise<any> {
    const versionString = '&version=' + this.cms_timestamp[id];
    return this.getAdvancedPageDataByVersion(id, preview, versionString);
  }

  async getAdvancedPageDataByVersion(id: string, _preview = false, versionString: string) {
    this.init.is_loading = true;
    //const ts = preview ? '&ts=' + new Date().valueOf() : '';
    const ts = '&ts=' + new Date().valueOf();
    const advanceParam = '?store_id=' + this.init.storeId + versionString + ts;
    try {
		  return await new Promise((resolve) => {
			  this.request.get('/cms-service-standard/page/' + id + advanceParam).toPromise().then(
				  (res:any) => {
					  const page = new CmsAdvancedPageModel(res['data']);
					  page.id = id;
					  page.loadTimestamp = new Date().valueOf();
					  this.advancedPages.push(page);
					  this.init.is_loading = false;
					  resolve(page);
				  }
			  );
		  });
	  } catch (error) {
		  return error;
	  }
  }

  /**
   * 获取页面
   * @param {number} id
   * @returns {Promise}
   */
  getAdvancedPage(id: string): Promise<any> {
    let page:any;
    if (this.advancedPages.length > 1) {
      const tmpItem = this.advancedPages.find((_item:any) => _item.id === id);
      if (tmpItem !== undefined) {
        if (tmpItem.loadTimestamp > (new Date().valueOf() - localConfig.dataLifetime * 1000)) {
          page = tmpItem;
        } else {
          const index = this.advancedPages.indexOf(tmpItem);
          if (index > -1) {
            this.advancedPages.splice(index, 1);
          }
        }
      }
    }

    if (page === undefined) {
      return this.getAdvancedPageData(id);
    } else {
      const promise = new Promise((resolve) => {
        resolve(page);
      });
      return promise;
    }
  }

  /**
   * 保存预览页面
   * @param {CmsAdvancedPageModel} page
   * @returns {Promise}
   */
  saveAdvancedPreviewPage(page: CmsAdvancedPageModel): Promise<any> {
    if (page.page_id) {
      return this.request.put(apiConfig.cmsAdvancedPage + '/' + page.page_id + '?action=save_preview', page).toPromise().catch(error => error);
    } else {
      return this.request.post(apiConfig.cmsAdvancedPage, page).toPromise().catch(error => error);
    }
  }

  /**
   * 发布页面
   * @param {CmsAdvancedPageModel} page
   * @return {Promise<any>}
   */
  publishPage(page: CmsAdvancedPageModel): Promise<any> {
    return this.request.put(apiConfig.cmsAdvancedPage + '/' + page.page_id + '?action=publish', page).toPromise().catch(error => error);
  }

  /**
   * 获取CMS时间戳
   * @returns {Promise<any>}
   */
  getCmsTimestamp() {
    return new Promise((resolve, reject) => {
      this.request.put('/cms-service-standard/page/versions/' + this.init.storeId, {}).toPromise().then(
        res => {
          this.cms_timestamp = res;
          resolve(res);
        }
      );
    });
  }


  /**
   * 最顶部的公告信息
   */
  headerBlock(id:any) {
    this.getAdvancedPage(id).then( val => {
      const container = JSON.parse(val.containers);
      for (const j in container) {
        for (const x in container[j]) {
          this.headerTips += container[j][x].html;
        }
      }
	  //开始页，女士首页，男士首页兼容顶部banner显示时的样式
	  setTimeout(() => {
	  	$("#scrollBox").addClass('margin-top');
	  },1000);
    }).catch(error => error);
  }

  /**
   * 清理cms缓存
   * @returns {Promise<any>}
   */
  cleanCmsCache(): Promise<any> {
    return new Promise((resolve) => {
      this.request.put('/cms-service-standard/page/versions/clean/' + this.init.storeId, {}).toPromise().then(
        res => {
          resolve(res);
        }
      );
    });
  }

  /**
   * 获取某个区块内容
   */
  getBlockDataById(id: string, type = false) {
    return new Promise((resolve, reject) => {
      const storeId = this.init.storeId;
      let url = apiConfig.blocksApi + '/' + id + '?store_id=' + storeId;
      if (type) {
        url += '&type=identifier';
      }
      this.request.get(url).toPromise().then(
        (res:any) => {
          resolve(res['message']);
        }
      ).catch(error => error);
    });
  }

  async jumpToWecahtLogin(code: string, state: string): Promise<any> {
    const notifyUrl = apiConfig.cloudCustomerBasic+'weixin_login/'+this.init.wxLoginAppid+'/callback?code='+code+'&state='+state;
    try {
		  return await this.request.get(notifyUrl).toPromise();
	  } catch (error) {
		  return error;
	  }
  }
}

interface CmsSharedData {
  pageModel: CmsAdvancedPageModel;
  containers: any;
  showData: boolean;
  previewMode: boolean;
  isNewPage: boolean;
}
