import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {CategoryModel} from '../models/category.model';
import {VmService} from './vm.service';
import {StorageService} from './storage.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import $ from 'jquery';
import {isPlatformBrowser} from '@angular/common';
import {TrackingcodeService} from './trackingcode.service';

const localConfig = require('../config/config.local.json');
const apiConfig = require('../config/config.api.json');

const Url = '/rest/category';

@Injectable()
export class CategoryService {
  public categoriesNew:any = [];
  public menuId = 0;
  public categories:any = [];
  public product: any = {code: '', data: ''};
  public productId:any;
  public productList:any = [];
  public productItemList:any = {};

  public productChildrenList:any;
  public productChilden:any = [];
  public productNumberst:any;
  // 获得商品总数
  public productNum:any;
  public pageSize = 16;
  // 分类树返回值
  public collectReturn:any;
  // 分类树结果集
  public categoryMessage:any;
  // 分类名称
  public categoryName:any;
  // 分类最终结果集
  public categoryLast:any = [];
  // 无线加载（一页20条）
  public pageNum = 1;
  //已加载商品数量
  public loadedCount = 0;
  // 导航名
  public navbarName:any;
  // 判断是否需要使用blockcontent
  public block = 0;
  public categoryProductList:any = [[]];
  // 分类数组
  public type:any = [[]];
  // 分类结果集
  public typeResult:any;
  // 排序使用变量
  public showSortFlag = false;
  public sortCode = '默认排序';
  public sortFilter = 0;
  // 筛选使用变量
  public filterFlag:any = '';
  public colorFilterSelected:any;
  public colorIdFilterSelected:any;
  public sizeFilterSelected:any;
  public sizeIdFilterSelected:any;

  public shopBySizeIdFilterSelected:any = [];

  public themeFilterSelected:any;
  public themeIdFilterSelected:any;
  public qualityFilterSelected:any;
  public qualityIdFilterSelected:any;
  public allColor:any = [];
  public allColorReset:any = [];
  public validFilterColor:any = [];
  public validFilterSize:any = [];
  public allSize:any = [];
  public allSizeReset:any = [];
  public validFilterTheme:any = [];
  public allTheme:any = [];
  public validFilterQuality:any = [];
  public allQuality:any = [];
  public lockFilterType:any;

  // 价格筛选
  public showPriceSlider = false;
  public priceFilterRange: any = [1, 10];
  public priceFilterSelected:any;
  public priceSelectedEnd = false; // 手动选择价格区间

  public priceMin = 0;
  public priceMax = 2;
  public priceStep = 10;

  public priceFilterRangeConfig: any = {
    range: {
      min: 0,
      max: 20
    },
  };

  public priceFilterRangeConfigNew: any = {
    margin: 10
  }



  // 分类树样式判断
  public categoryStyle = 0;
  public categoryTree:any;
  // 分类id
  public categoryId:any;
  public tree:any;

  // 无限加载锁
  public loadLock = true;
  public noData = false;
  public loading = false;
  // vm预览环境判断
  public isVmPreview:any;

  public shopSizeVals = [];
  public simpleSizeArr = [];
  //当前页面地址
  public categoryUrl:any;
  public afterPage = '';
  public moreBackground : any;
  constructor(
              private request: RequestService,
              private vmService: VmService,
              private init: InitializeService,
			  private trackingService: TrackingcodeService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private httpState: TransferState,
              private localStorage: StorageService) {
  }

  /**
   * 获取cloud商品列表
   * @param id
   * @param q
   */
  getCloudCategoryList(id:any, q:any) {
    this.noData = false;
    this.loadLock = true;
    const storeId = this.init.cloudStoreId;
    const merchantId = this.init.merchantId;
	let url = '/cloud-item-basic/customer/elasticsearch/fast?store_id='+ storeId + '&merchant_id='+merchantId;
	this.afterPage = this.pageNum == 1 ? "" : encodeURIComponent(this.afterPage);
    if (id) {
      url += '&class_id=' + id;
      if (this.sortFilter) {
        url += '&is_sort=' + this.sortFilter;
      }
      if (this.colorIdFilterSelected.length > 0) {
        url += '&color_filter=' + encodeURIComponent(this.colorIdFilterSelected.join('##'));
      }
      if (this.sizeIdFilterSelected.length > 0) {
        url += '&size_filter=' + encodeURIComponent(this.sizeIdFilterSelected.join('##'));
      }

      if (this.shopBySizeIdFilterSelected.length > 0) {
        url += '&size_filter=' + encodeURIComponent(this.shopBySizeIdFilterSelected.join('##'));
      }

      if (this.themeIdFilterSelected.length > 0) {
        url += '&style_filter=' + encodeURIComponent(this.themeIdFilterSelected.join('##'));
      }
      if (this.qualityIdFilterSelected.length > 0) {
        url += '&material_filter=' + encodeURIComponent(this.qualityIdFilterSelected.join('##'));
      }
      if (this.priceFilterSelected && this.priceFilterSelected.length > 0) {
        url += '&price_filter=' + encodeURIComponent(this.priceFilterSelected.join('##'));
      }
    } else {
      this.loadLock = false;
      return;
    }
    if (this.localStorage.getItem('vmLogin')) {
      url  =  url + '&page_size=' + this.pageSize + '&page_no=' + this.pageNum + '&timestamp=' + new Date().getTime();
    } else {
      url = url + '&page_size=' + this.pageSize + '&page_no=' + this.pageNum;
    }
	url = url +'&after_page='+this.afterPage;
    // 建立新数组
    const categoryList:any = [[]];
    let take = 0;
    // 存放数据
    if (!this.sortFilter) {
      for (const key in this.categoryProductList) {
        if (key == id) {
          take = 1;
        }
      }
    }
    take = 2;
    if (take === 1) {
      if (isPlatformBrowser(this.platformId)) {
        this.productListMessage(this.categoryProductList[id]['list']);
      }
      take = 0;
    } else {
      this.request.list(url).subscribe(r => {
        this.product = r;
 
		this.afterPage = this.product.data.afterPage;
        // 判断是否最后一页，如果不是设置loadLock = false
		this.productNum = this.product.data.total;
        const pageCount = Math.ceil(this.product.data.total / this.pageSize);
		
		
		const contentLen =  this.product.data.content.length;
		var loadedCount = this.pageSize;
		if(this.pageNum > 1){
			if(this.pageSize == contentLen){
				loadedCount =  this.pageSize * this.pageNum;
			}else{
				loadedCount +=  contentLen;
			}
		}
		
		this.loadedCount = loadedCount;
		var laodPercent = (this.loadedCount/this.productNum)*100;
		this.moreBackground = "linear-gradient(to right, #080808 "+laodPercent+"%, #fff 0%)"	
        if (this.pageNum < pageCount) {
          this.loadLock = false;
        } else {
          // 没有数据了
          this.noData = true;
        }
        categoryList['list'] = this.product;
        this.categoryProductList[id] = categoryList;
        this.loading = false;
        if (isPlatformBrowser(this.platformId)) {
			this.getGoodsListFilter(id, q)
        }
      });
    }
  }

	getGoodsListFilter(id:any, _q:any){
		const storeId = this.init.cloudStoreId;
		const merchantId = this.init.merchantId;
		let filterApiUrl = '/cloud-item-basic/customer/elasticsearch/filter_results?' + 'store_id=' + storeId + '&merchant_id='+merchantId;
		if (id) {
		  filterApiUrl += '&class_id=' + id;
		  if (this.sortFilter) {
		    filterApiUrl += '&is_sort=' + this.sortFilter;
		  }
		  if (this.colorIdFilterSelected.length > 0) {
		    filterApiUrl += '&color_filter=' + encodeURIComponent(this.colorIdFilterSelected.join('##'));
		  }
		  if (this.sizeIdFilterSelected.length > 0) {
		    filterApiUrl += '&size_filter=' + encodeURIComponent(this.sizeIdFilterSelected.join('##'));
		  }
		
		  if (this.shopBySizeIdFilterSelected.length > 0) {
		    filterApiUrl += '&size_filter=' + encodeURIComponent(this.shopBySizeIdFilterSelected.join('##'));
		  }
		
		  if (this.themeIdFilterSelected.length > 0) {
		    filterApiUrl += '&style_filter=' + encodeURIComponent(this.themeIdFilterSelected.join('##'));
		  }
		  if (this.qualityIdFilterSelected.length > 0) {
		    filterApiUrl += '&material_filter=' + encodeURIComponent(this.qualityIdFilterSelected.join('##'));
		  }
		  if (this.priceFilterSelected && this.priceFilterSelected.length > 0) {
		    filterApiUrl += '&price_filter=' + encodeURIComponent(this.priceFilterSelected.join('##'));
		  }
		}
 
		this.request.list(filterApiUrl).subscribe(filterResult => {
		  if (isPlatformBrowser(this.platformId)) {
		    this.cloudProductListMessage(filterResult);
		  }
		});
		
		
	}
  //  cloud商品列表公共方法
  cloudProductListMessage(filterResult:any) {
    if (this.allColor.length === 0) {
      this.resetColorKey(filterResult.data.color_filter_data);
      this.allColor = this.resetAllColorData(filterResult.data.color_filter_data);
    }
 
    if (filterResult.data.valid_color_filter_data) {
		this.validFilterColor =  this.resetAllColorData(filterResult.data.valid_color_filter_data);
    } else {
      this.validFilterColor =  this.resetAllColorData(filterResult.data.color_filter_data);
    }

    if (this.lockFilterType !== 'color' || this.colorIdFilterSelected.length === 0) {
      for (const i in this.allColor) {
        $('#connected_color_' + this.allColor[i].id).addClass('is-disabled');
        $('#connected_color_' + this.allColor[i].id).attr('disabled', 'disbaled');
        for (const j in this.validFilterColor) {
          if (this.allColor[i].id === this.validFilterColor[j].id) {
            $('#connected_color_' + this.allColor[i].id).removeClass('is-disabled');
            $('#connected_color_' + this.allColor[i].id).removeAttr('disabled');
          }
        }
      }
    }

    if (this.allSize.length === 0) {
      this.resetSizeKey(filterResult.data.size_filter_data);
      this.allSize = this.resetAllSizeData(filterResult.data.size_filter_data);

      let shopSizes:any = [];
      let sizeArr:any = [];
      let sizeItemArr : any;
      let sizeSetsArr:any = [];
      for(var i=0;i<this.allSize.length;i++){
        //160/80A(XS) 格式
        if(this.allSize[i].size.indexOf("(") !== -1){
          //尺码拆分 组合
          let sizeSet = [];
          sizeSet.push(this.allSize[i].size);

          let tempAllSize = this.allSize[i].size.split('(');
          let tempSizeValue = tempAllSize[1].replace(')','');

          sizeSet.push(tempSizeValue);
          if(shopSizes.indexOf(tempSizeValue) == -1){
            shopSizes.push(tempSizeValue);
          }
          if(sizeArr.indexOf(tempAllSize[0]) == -1){
            if(sizeArr[tempSizeValue]){
              let multipleSize = [];
              multipleSize = sizeArr[tempSizeValue];
              multipleSize.push(tempAllSize[0]);
              multipleSize.push(this.allSize[i].size);
              sizeArr[tempSizeValue] = multipleSize;

              sizeItemArr = {key:tempSizeValue,value:JSON.stringify(multipleSize)};
              sizeSetsArr.push(sizeItemArr);
            }else{
              sizeSet.push(tempAllSize[0]);
              sizeArr[tempSizeValue] = sizeSet;

              sizeItemArr = {key:tempSizeValue,value:JSON.stringify(sizeSet)};
              sizeSetsArr.push(sizeItemArr);
            }
          }

        }else if(this.allSize[i].size === '均码'){
          //兼容均码
          let sizeSet = [];
          sizeSet.push(this.allSize[i].size);
          shopSizes.push(this.allSize[i].size);
          sizeArr[this.allSize[i].size] = sizeSet;

          sizeItemArr = {key:this.allSize[i].size,value:JSON.stringify(sizeSet)};
          sizeSetsArr.push(sizeItemArr);
        }
      }

      this.shopSizeVals = shopSizes;
      this.simpleSizeArr = sizeArr;
      this.localStorage.setItem('shopSizeVals', JSON.stringify(this.shopSizeVals));
      this.localStorage.setItem('simpleSizeArr',JSON.stringify(sizeSetsArr));

    }
    if (filterResult.data.valid_size_filter_data) {
      this.validFilterSize = this.resetAllSizeData(filterResult.data.valid_size_filter_data);
    } else {
      this.validFilterSize = this.resetAllSizeData(filterResult.data.size_filter_data);
    }
    if (this.lockFilterType !== 'size' || this.sizeIdFilterSelected.length === 0) {
      for (const i in this.allSize) {
        $('#connected_size_' + this.allSize[i].id).addClass('is-disabled');
        $('#connected_size_' + this.allSize[i].id).attr('disabled', 'disbaled');
        for (const j in this.validFilterSize) {
          if (this.allSize[i].id === this.validFilterSize[j].id) {
            $('#connected_size_' + this.allSize[i].id).removeClass('is-disabled');
            $('#connected_size_' + this.allSize[i].id).removeAttr('disabled');
          }
        }
      }
    }

    if (this.allTheme.length === 0) {
      this.allTheme = !!filterResult.data.style_filter_data ? filterResult.data.style_filter_data : [];
    }
    this.validFilterTheme = filterResult.data.style_filter_data;
    if (filterResult.data.valid_style_filter_data) {
      this.validFilterTheme = filterResult.data.valid_style_filter_data;
    } else {
      this.validFilterTheme = filterResult.data.style_filter_data;
    }
    if (this.lockFilterType !== 'theme' || this.themeIdFilterSelected.length === 0) {
      for (const i in this.allTheme) {
        $('#connected_theme_' + this.allTheme[i]).addClass('is-disabled');
        $('#connected_theme_' + this.allTheme[i]).attr('disabled', 'disbaled');
        for (const j in this.validFilterTheme) {
          if (this.allTheme[i] === this.validFilterTheme[j]) {
            $('#connected_theme_' + this.allTheme[i]).removeClass('is-disabled');
            $('#connected_theme_' + this.allTheme[i]).removeAttr('disabled');
          }
        }
      }
    }
    if (this.allQuality.length === 0) {
      this.allQuality = !!filterResult.data.material_filter_data ? filterResult.data.material_filter_data : [];
    }
    if (filterResult.data.valid_material_filter_data) {
      this.validFilterQuality = filterResult.data.valid_material_filter_data;
    } else {
      this.validFilterQuality = filterResult.data.material_filter_data;
    }
    if (this.lockFilterType !== 'quality' || this.qualityIdFilterSelected.length === 0) {
      for (const i in this.allQuality) {
        $('#connected_quality_' + this.allQuality[i]).addClass('is-disabled');
        $('#connected_quality_' + this.allQuality[i]).attr('disabled', 'disbaled');
        for (const j in this.validFilterQuality) {
          if (this.allQuality[i] === this.validFilterQuality[j]) {
            $('#connected_quality_' + this.allQuality[i]).removeClass('is-disabled');
            $('#connected_quality_' + this.allQuality[i]).removeAttr('disabled');
          }
        }
      }
    }

    // 价格筛选
    if (filterResult.data.hasOwnProperty('price_filter_data')) {
      this.priceMin = Math.floor( filterResult.data.price_filter_data[0] );
      this.priceMax  = Math.ceil( filterResult.data.price_filter_data[1] );
      if (this.priceMax == this.priceMin) {
        this.priceMax += 1;
      }
      this.priceFilterRangeConfig.range.min = this.priceMin;
      this.priceFilterRangeConfig.range.max = this.priceMax;
      this.showPriceSlider = true;
    }
    if (filterResult.data.valid_price_filter_data) {
      this.priceFilterRangeConfig.range.min = Math.floor( filterResult.data.valid_price_filter_data[0] );
      this.priceFilterRangeConfig.range.max = Math.ceil( filterResult.data.valid_price_filter_data[1] );
      if (this.priceFilterRangeConfig.range.min == this.priceFilterRangeConfig.range.max) {
        this.priceFilterRangeConfig.range.max += 1;
      }
    }
    if (!this.priceSelectedEnd) {
      this.priceFilterRange = [this.priceFilterRangeConfig.range.min, this.priceFilterRangeConfig.range.max];
    }
	const gaTrackingProductList = [];
    for (const number in this.product.data.content) {
		const productItem = this.product.data.content[number];
      const key = this.product.data.content[number]['itemId'] + '-' + this.product.data.content[number]['valueId'];
      if (this.productList.length > 0) {
        if ($.inArray(key, this.productList) == -1) {
          this.productList.push(key);
          this.productItemList[key] = this.product.data.content[number];
        }
      } else {
        this.productList.push(key);
        this.productItemList[key] = this.product.data.content[number];
      }
	  if (this.init.gaTrackingFlag) {
		  // 新版Ga Tracking Js
		  	var page_category_path = '';
		  	var urlString = this.categoryUrl.replace('.html','');
		  	var urlArr = urlString.split('/').splice(1);
		  		
		  	for(var i=0;i<urlArr.length;i++){
		  		if(i==0){
		  			page_category_path +=urlArr[i];
		  		}else{
		  			page_category_path +='_'+urlArr[i];
		  		}
		  	}
		  	
		   //  //组装GA商品列表数据
		    gaTrackingProductList.push({
		  		product_action: 'view',
		  		product_id: productItem.itemCode,//款号
		  		product_article_id: productItem.frontCode,//CC
		  		product_name: productItem.itemName,
		  		product_brand: 'COS',
		  		product_category:page_category_path,
		  		product_color:productItem.valueName,
		  		product_price:productItem.price,
		  		product_price_original:productItem.markingPrice,
		  		product_price_type: productItem.price < productItem.markingPrice ? 'sale' : 'full price',
		  		product_list_name: 'PRA1',
		  		product_list_position: Number(number) + 1,
		    });
		  }
		  	this.trackingService.gaTrackingProductListView(gaTrackingProductList,this.categoryUrl);  	
	  }

		this.productNumberst = this.product.data.total;
		// set inital products state
		this.vmService.saveMerchCategoryProducts( this.vmService.findMerchCategory(), this.productList.join(',') );
  }
  
  /**
   * 重置size key
   * @param size_data
   */
  resetSizeKey(size_data:any) {
    this.allSizeReset = [];
    if(size_data){
      if (size_data.length > 0) {
        for (const index in size_data) {
          this.allSizeReset[size_data[index]] = {
            id: index,
            size: size_data[index],
          };
        }
      }
    }
  }

  /**
   * 重置size key
   * @param size_data
   */
  resetColorKey(color_data:any) {
    this.allColorReset = [];
    if(color_data){
      if (color_data.length > 0) {
        for (const index in color_data) {
          this.allColorReset[color_data[index]] = {
            id: index,
            color: color_data[index],
          };
        }
      }
    }
  }

  resetAllSizeData(size_data:any) {
    const list = [];
    if (size_data.length > 0) {
      for (const index in size_data) {
        if ( this.allSizeReset.hasOwnProperty(size_data[index])) {
          list.push(this.allSizeReset[size_data[index]]);
        }
      }
    }
    return list;
  }

  resetAllColorData(color_data:any) {
    const list = [];
    if(color_data){
      if (color_data.length > 0) {
        for (const index in color_data) {
          if ( this.allColorReset.hasOwnProperty(color_data[index])) {
            list.push(this.allColorReset[color_data[index]]);
          }
        }
      }
    }
    return list;
  }

  // 商品列表公共方法
  productListMessage(r:any) {
    this.product = r;
    if (this.allColor.length === 0) {
      this.allColor = this.product.data.filter.filter_color;
    }
    if (this.product.data.filter.valid_filter_color) {
      this.validFilterColor = this.product.data.filter.valid_filter_color;
    } else {
      this.validFilterColor = this.product.data.filter.filter_color;
    }
    if (this.lockFilterType !== 'color' || this.colorIdFilterSelected.length === 0) {
      for (const i in this.allColor) {
        $('#connected_color_' + this.allColor[i].id).addClass('is-disabled');
        $('#connected_color_' + this.allColor[i].id).attr('disabled', 'disbaled');
        for (const j in this.validFilterColor) {
          if (this.allColor[i].id === this.validFilterColor[j].id) {
            $('#connected_color_' + this.allColor[i].id).removeClass('is-disabled');
            $('#connected_color_' + this.allColor[i].id).removeAttr('disabled');
          }
        }
      }
    }
    if (this.allSize.length === 0) {
      this.allSize = this.product.data.filter.filter_size;
    }
    if (this.product.data.filter.valid_filter_size) {
      this.validFilterSize = this.product.data.filter.valid_filter_size;
    } else {
      this.validFilterSize = this.product.data.filter.filter_size;
    }
    if (this.lockFilterType !== 'size' || this.sizeIdFilterSelected.length === 0) {
      for (const i in this.allSize) {
        $('#connected_size_' + this.allSize[i].id).addClass('is-disabled');
        $('#connected_size_' + this.allSize[i].id).attr('disabled', 'disbaled');
        for (const j in this.validFilterSize) {
          if (this.allSize[i].id === this.validFilterSize[j].id) {
            $('#connected_size_' + this.allSize[i].id).removeClass('is-disabled');
            $('#connected_size_' + this.allSize[i].id).removeAttr('disabled');
          }
        }
      }
    }
    if (this.allTheme.length === 0) {
      this.allTheme = this.product.data.filter.filter_theme;
    }
    if (this.product.data.filter.valid_filter_theme) {
      this.validFilterTheme = this.product.data.filter.valid_filter_theme;
    } else {
      this.validFilterTheme = this.product.data.filter.filter_theme;
    }
    if (this.lockFilterType !== 'theme' || this.themeIdFilterSelected.length === 0) {
      for (const i in this.allTheme) {
        $('#connected_theme_' + this.allTheme[i]).addClass('is-disabled');
        $('#connected_theme_' + this.allTheme[i]).attr('disabled', 'disbaled');
        for (const j in this.validFilterTheme) {
          if (this.allTheme[i] === this.validFilterTheme[j]) {
            $('#connected_theme_' + this.allTheme[i]).removeClass('is-disabled');
            $('#connected_theme_' + this.allTheme[i]).removeAttr('disabled');
          }
        }
      }
    }
    if (this.allQuality.length === 0) {
      this.allQuality = !!this.product.data.filter.filter_quality ? this.product.data.filter.filter_quality : [];
    }
    if (this.product.data.filter.valid_filter_quality) {
      this.validFilterQuality = this.product.data.filter.valid_filter_quality;
    } else {
      this.validFilterQuality = this.product.data.filter.filter_quality;
    }
    if (this.lockFilterType !== 'quality' || this.qualityIdFilterSelected.length === 0) {
      for (const i in this.allQuality) {
        $('#connected_quality_' + this.allQuality[i]).addClass('is-disabled');
        $('#connected_quality_' + this.allQuality[i]).attr('disabled', 'disbaled');
        for (const j in this.validFilterQuality) {
          if (this.allQuality[i] === this.validFilterQuality[j]) {
            $('#connected_quality_' + this.allQuality[i]).removeClass('is-disabled');
            $('#connected_quality_' + this.allQuality[i]).removeAttr('disabled');
          }
        }
      }
    }

    for (const number in this.product.data.list) {
      if (this.productList.length > 0) {
        if ($.inArray(this.product.data.list[number], this.productList) == -1) {
          this.productList.push(this.product.data.list[number]);
        }
      } else {
        this.productList.push(this.product.data.list[number]);
      }
    }
    this.productNumberst = this.product.data.totalCount;
    // set inital products state
    this.vmService.saveMerchCategoryProducts( this.vmService.findMerchCategory(), this.productList.join(',') );
  }


  // 分类树
  getCategoryTree(id:any) {
    let url;
    const storeId = '1';
    url = Url + '?action=getTreeList&moreLevel=1&cid=' + id + '&store_id=' + storeId;
    this.request.list(url).subscribe
      (r => {
        this.tree = r;
        if (this.tree.data.childs) {
          for (const i in this.tree.data.childs) {
            this.categoryTree.push(this.tree.data.childs[i]);
          }
        } else {
          this.getCategoryTree(this.tree.data.parent_id);
        }
      });
  }

  // 分类公共调用方法
  typeList(r:any) {
    this.collectReturn = r;
    this.categoryMessage = this.collectReturn.data.categories;
    const categoryNum = [];
    const categoryName = [];
    const categoryList = [];
    let a = 0;
    for (const number in this.categoryMessage) {
      if (this.categoryMessage.hasOwnProperty(number)) {
        categoryNum[a] = this.categoryMessage[number];
        a++;
      }
    }
    for (let b = 0; b < categoryNum.length;) {
      categoryName[b] = categoryNum[b].group_name;
      categoryList[b] = categoryNum[b].childs;
      b++;
    }
    categoryName.reverse();
    categoryList.reverse();
    this.categoryName = categoryName;
    this.categoryLast = categoryList;
  }

  /**
   * 从API中获取数据
   * @param {number} id
   * @returns {Promise<any>}
   */
  getCategoryData(id: number): Promise<any> {
    const CATEGORY_KEY = makeStateKey('category_' + id);
    const categoryInfo = this.httpState.get(CATEGORY_KEY, null as any);
    return new Promise((resolve, _reject) => {
      if (categoryInfo) {
        resolve(categoryInfo);
       } else {
        this.request.list(apiConfig.merchantExtendCloudApi + '/merchant_class/service/' + id).toPromise().then(
          (res:any) => {
            const category = new CategoryModel(res['data']);
            category.id = id;
            category.loadTimestamp = new Date().valueOf();
            this.categories.push(category);
            this.httpState.set(CATEGORY_KEY, category as any);
            resolve(category);
          }
        ).catch(error => error);
      }
    });
  }

  /**
   * 获取分类
   * @param {number} id
   * @returns {Promise}
   */
  getCategory(id: number): Promise<any> {
    let category:any;
    if (this.categories.length > 1) {
      const tmpItem = this.categories.find((item:any) => item.id === id);
      if (tmpItem !== undefined) {
        if (tmpItem.loadTimestamp > (new Date().valueOf() - localConfig.dataLifetime * 1000)) {
          category = tmpItem;
        } else {
          const index = this.categories.indexOf(tmpItem);
          if (index > -1) {
            this.categories.splice(index, 1);
          }
        }
      }
    }
    if (category === undefined) {
      return this.getCategoryData(id);
    } else {
      const promise = new Promise((resolve, _reject) => {
        resolve(category);
      });
      return promise;
    }
  }

  searchProduct(value:any) {
    return new Promise((resolve, _reject) => {
      const url = apiConfig.searchApi;
      const storeId = this.init.storeId;
      this.request.list(url + '&q=' + value + '&store_id=' + storeId).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }


  /**
   * 通过分类ID获取商品列表(用于LandingPage)
   * @param cid
   * @param count
   * @returns {Promise}
   */
  getLpProductListByCid(cid: number, count: number): Promise<any> {
    const Url = apiConfig.productApi;
    return new Promise((resolve, _reject) => {
      this.request.get(Url + '?action=getProductList&cid=' + cid + '&count=' + count).toPromise().then(
        (res:any) => {
          if (res['code'] == 200 && res['data'] && res['data'].productList) {
            resolve(res['data']);
          }
        }
      ).catch(error => error);
    });
  }

  async getCategoryBanner(cid:any) {
    let url = apiConfig.categoryApi + '?action=getCategoryBanner&cid=' + cid;
    if (this.localStorage.getItem('vmLogin')) {
      url += '&timestamp=' + new Date().getTime();
    }
    try {
		  return await new Promise((resolve) => {
			  this.request.get(url).subscribe(res => {
				  resolve(res);
			  });
		  });
	  } catch (error) {
		  return error;
	  }
  }

  /**
   * 获取地址详情
   * @param url_path
   */
  getUrlPathInfo(url_path: string) {
    return new Promise((resolve => {
      this.request.get(apiConfig.merchantExtendCloudApi + '/merchant_url_path/info/service?merchant_id='
        + this.init.merchantId
        + '&store_id=' + this.init.cloudStoreId
        + '&url_path=' + url_path ).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  getNavByCid(cid: any) {
    return new Promise((resolve, _reject) => {
      this.request.list(`${apiConfig.merchantExtendCloudApi}/customer/merchant_navigation/url_path/get?merchant_id=${this.init.merchantId}&store_id=${this.init.cloudStoreId}&foreign_id=${cid}`).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }
}

