import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { InitializeService } from './initialize.service';
import { RequestService } from './request.service';
import { SensorsTrackingService } from './sensors.service';
import { StorageService } from './storage.service';

const platform = require('platform');
const apiConfig = require('../config/config.api.json');





//获取受众规则
class Target {
  targetLists:any = [];
  systemInfo = platform.name;
  //手机品牌 ？？TODO
  getBrand(){
    let TARGET_ID = 5;
    this.targetLists.push(
      {"targetId":TARGET_ID,"targetName":"abTarget","targetValue": platform.product}
    );
  }
  // 操作系统
  getPlatform(){
    let TARGET_ID = 8;
    this.targetLists.push(
      {"targetId":TARGET_ID,"targetName":"abTarget","targetValue": platform.os.family}
    );
  }
  //操作系统版本
  getPlatformVersion(){
    let TARGET_ID = 7;
    this.targetLists.push(
      {"targetId":TARGET_ID,"targetName":"abTarget","targetValue": platform.os.version}
    );
  }
  // // getWechatSDKVersion(){
  // //   let TARGET_ID = 3;
  // //   this.targetLists.push(
  // //    {"targetId":TARGET_ID,"targetName":"abTarget","targetValue":this.systemInfo.SDKVersion}
  // //   );
  // // }
  // // getWechatVersoin(){
  // //   let TARGET_ID = 12;
  // //   this.targetLists.push(
  // //     {"targetId":TARGET_ID,"targetName":"abTarget","targetValue":this.systemInfo.version}
  // //   );
  // // }
  // 屏幕尺寸
  getScreen(){
    let TARGET_ID = 6;
    this.targetLists.push(
      {"targetId":TARGET_ID,"targetName":"abTarget","targetValue": screen.width + "*" + screen.height}
    );
  }
  getIpAddress(){
    let TARGET_ID = 4;
    this.targetLists.push(
      {"targetId":TARGET_ID,"targetName":"abTarget","targetValue": localStorage.getItem( "efs_tracking_ip")}
    );
  }
  // TODO 未实现
  // getNewUserLaunch(){
  //   let TARGET_ID = 10;
  // }
  // getUserCostsTotal(){
  //   let TARGET_ID = 11;
  // }

  // 浏览器名称
  getBrowserName(){
    let TARGET_ID = 20;
    this.targetLists.push(
     {"targetId":TARGET_ID,"targetName":"abTarget","targetValue": platform.name}
    );
  }
  // 浏览器版本
  getBrowserVersion(){
    let TARGET_ID = 21;
    this.targetLists.push(
     {"targetId":TARGET_ID,"targetName":"abTarget","targetValue": platform.version}
    );
  }


  getTarget(){
    this.getBrand();
    this.getPlatform();
    this.getPlatformVersion();
    // this.getWechatSDKVersion();
    // this.getWechatVersoin();
    this.getScreen();
    this.getIpAddress();
    this.getBrowserName();
    this.getBrowserVersion();
    return this.targetLists;
  }
}



@Injectable()
export class AbTrackingService {
  abable:any = true;
  currentUrl:any = ''; //TODO 注意：当前trackingPage已被sdk中自动埋点取代，所有没有在trackingPage中初始化此值了
  clientData : any = { code: 0, message: "", data: "" };
  constructor(
    public init: InitializeService,
    private request: RequestService,
    private localStorage: StorageService,
    public sensorsTracking: SensorsTrackingService,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  //尝试查询ab配置，并缓存
  registerAb() {
    console.info("begin registerAb ...");
    //if (isPlatformBrowser(this.platformId)) {
      // console.info("begin registerAb in browser ...");
      this._initTrackingEnv();
      this._initAb()
    //} else {
     // console.info("begin registerAb but not in browser ...");
   // }
  }

  private _initTrackingEnv() {
    //scene，为了兼容小程序scene值，为web端预设一个默认scene scene
    let scene = this.localStorage.getItem('efs_tracking_scene');
    if(scene == '' || scene == undefined){
      this.localStorage.setItem('efs_tracking_scene', 9801);
    }

    //IP
    let trackingIp = this.localStorage.getItem('efs_tracking_ip');
    if(trackingIp == '' || trackingIp == undefined){
      this.getIp()?.then( val => {
        this.clientData = val;
        if(this.clientData.code == 1000){
          this.localStorage.setItem('efs_tracking_ip',this.clientData.data);
        }
      });
    }

    // //设备信息 无用
    // let deviceId = this.localStorage.getItem('deviceId');
    // if(deviceId == '' || deviceId == undefined){
    //   const browsers = {
    //     versions: function() {
    //       const u = navigator.userAgent, app = navigator.appVersion;
    //       return {
    //         userAgent: u,
    //       }
    //     }(),
    //   };
    //   this.localStorage.setItem('deviceId',browsers.versions.userAgent);
    // }

    // //UUID 已使用sensors distinct_id 取代
    // let unionId = this.localStorage.getItem('efs_tracking_unionId');
    // if(unionId == '' || unionId == undefined){
    //   let uuidRs = this.uuid();
    //   this.localStorage.setItem('efs_tracking_unionId',uuidRs);
    // }

  }

  private _initAb() {
    console.info("begin initAb in browser ...");
    //初始 abtest
    if (this.abable) {
      // console.info("Begin to init abtest env，Targets");
      let target = new Target();
      let targetData = target.getTarget();
      this.localStorage.setItem("ABTargets", JSON.stringify(targetData)); //TODO abtest数据使用session存储后，这个值应也存储到session中？！
      // 应用级存储abtest
      // let abId = this.getStoragedAbId();
      // let abVersion = this.getStoragedAbVersion();
      // if(abVersion == undefined || abVersion == '') { //注释此行：每次会话启动都去获取ab信息
        console.info("Start to req abtest data,try to join ab");
        this.getExperimentABTesting('')?.then( val => {
          let abTestData: any = val;
          console.info("Get abtest data done,abConfig:" + JSON.stringify(abTestData));
          //当成功的请求时，存储abtest数据
          if(abTestData.code == 200){
            // 使用 localStorage
            if (abTestData.data) {
              self.localStorage.setItem('abTestConfig', JSON.stringify(abTestData.data));
              //self.localStorage.setItem('ABVersion_'+data.path, this.abTestData.data ); // TODO 赞未使用，无用
              self.localStorage.setItem('abId', abTestData.data.experimentId);
              self.localStorage.setItem('abVersion', abTestData.data.version);
            } else {
              self.localStorage.removeItem('abTestConfig');
              self.localStorage.removeItem('abId');
              self.localStorage.removeItem('abVersion');
            }
            //  eftrack.registerPage({
            //    "experimentId": this.getStoragedAbId(),
            //    "versionName": this.getStoragedAbVersion(),
            //  });
            console.info("Init ab info into sensors aft got ab:" + this.getStoragedAbId() + "/" + this.getStoragedAbVersion());
            this.sensorsTracking.setPageInfoWithAb(this.getStoragedAbId(), this.getStoragedAbVersion());
            self.localStorage.setItem('abGetDone', "true");
          }
        });
      // } else {
        // console.info("abtest data exists in session, not need to req. abId:" + abId + ",v:" + abVersion);
        // this.sensorsTracking.setPageInfoWithAb(this.getStoragedAbId(), this.getStoragedAbVersion());
      // }
    } //#end this.abable
  }

  //从缓存中去 ab id。待封装
  getStoragedAbId() {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin getStoragedAbId in browser ...");
      return this.localStorage.getItem("abId");
    } else {
      console.info("begin getStoragedAbId but not in browser ...");
      return null;
    }
  }
  //从缓存中去 ab version。待封装
  getStoragedAbVersion() {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin getStoragedAbVersion in browser ...");
      return this.localStorage.getItem("abVersion");
    } else {
      console.info("begin getStoragedAbVersion but not in browser ...");
      return null;
    }
  }

  //abtest实验版本
  getExperimentABTesting(path = '') {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin getExperimentABTesting in browser ...");
      //const target =   [{"targetId":5,"targetName":"abTarget","targetValue":"devtools"},{"targetId":8,"targetName":"abTarget","targetValue":"devtools"},{"targetId":7,"targetName":"abTarget","targetValue":"iOS 10.0.1"},{"targetId":3,"targetName":"abTarget","targetValue":"2.16.0"},{"targetId":12,"targetName":"abTarget","targetValue":"7.0.4"},{"targetId":6,"targetName":"abTarget","targetValue":"414*896"},{"targetId":4,"targetName":"abTarget","targetValue":"58.246.28.14"}] ;
      //const target = JSON.stringify([{"targetId":4,"targetName":"abTarget","targetValue":"58.246.28.14"}]);
      //const user_ssid = '16268652354821972385';
      const url = apiConfig.optimizeService + 'customer/experiment/shuffle' +
      '?merchant_id=100000005&store_id=10&path=' + path +
      // '&targets=%5B%7B%22targetId%22%3A5%2C%22targetName%22%3A%22abTarget%22%2C%22targetValue%22%3A%22devtools%22%7D%2C%7B%22targetId%22%3A8%2C%22targetName%22%3A%22abTarget%22%2C%22targetValue%22%3A%22devtools%22%7D%2C%7B%22targetId%22%3A7%2C%22targetName%22%3A%22abTarget%22%2C%22targetValue%22%3A%22iOS%2010.0.1%22%7D%2C%7B%22targetId%22%3A3%2C%22targetName%22%3A%22abTarget%22%2C%22targetValue%22%3A%222.16.0%22%7D%2C%7B%22targetId%22%3A12%2C%22targetName%22%3A%22abTarget%22%2C%22targetValue%22%3A%227.0.4%22%7D%2C%7B%22targetId%22%3A6%2C%22targetName%22%3A%22abTarget%22%2C%22targetValue%22%3A%22414*896%22%7D%2C%7B%22targetId%22%3A4%2C%22targetName%22%3A%22abTarget%22%2C%22targetValue%22%3A%2258.246.28.14%22%7D%5D' +
      // '&user_ssid=16268652354821972385'
      '&targets=' + this.localStorage.getItem("ABTargets") +
      '&user_ssid=' + this.localStorage.getItem("efs_tracking_ip")
      ;
      return new Promise((resolve, _reject) => {
        //this.request.get(apiConfig.optimizeService + 'customer/experiment/shuffle?merchant_id='+this.init.merchantId+'&store_id='+this.init.cloudStoreId+'&path='+path+'&targets='+target+'&user_ssid='+user_ssid).toPromise().then(
        this.request.get(url).toPromise().then(
          res => {
            resolve(res);
          }
        ).catch(error => error);
      });
    } else {
      console.info("begin getExperimentABTesting but not in browser ...");
	  return;
    }
	
  }

  /**
   * 每个页面onLoad时尝试从已获取的AB测试场景中获取数据填充页面。此方法针对home页面时尝试等待n秒。待优化
   * @param {Object} t
   */
   tryApplyAbConfigForPage(t:any, callback:any) {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin tryApplyAbConfigForPage in browser ...");
      let that = this;
      //为当前页面尝试应用ab测试中配置的数据
      // var abGetDone = that.sessionStorage.get('abGetDone'); //从会话中获取是否已经请求过abtest数据
      var abGetDone = that.localStorage.getItem('abGetDone');
      if (abGetDone == undefined || abGetDone == null) abGetDone = false;
      if (abGetDone) {
        that.applyAbTestForPage(t, callback);
      } else {
        console.info("ab loading，waiting ...")
        setTimeout(function() {
          // var abGetDone = that.sessionStorage.get('abGetDone'); //从会话中获取是否已经请求过abtest数据
          var abGetDone = that.localStorage.getItem('abGetDone');
          if (abGetDone == undefined || abGetDone == null) abGetDone = false;
          // console.info("abGetDone:" + abGetDone)
          if (abGetDone) {
            that.applyAbTestForPage(t, callback);
          } else {
            // 若0.5秒后还没获取到abtest的配置，尝试1秒钟以后再次请求
            setTimeout(function() {
              // var abGetDone = that.sessionStorage.get('abGetDone'); //从会话中获取是否已经请求过abtest数据
              var abGetDone = that.localStorage.getItem('abGetDone');
              if (abGetDone == undefined || abGetDone == null) abGetDone = false;
              // console.info("abGetDone:" + abGetDone)
              if (abGetDone) {
                that.applyAbTestForPage(t, callback);
              } else
                console.info("abGetDone为false时等待获取abtest数据结束")
            }, 1000);
          }
        }, 500); //尝试等待3秒数 FIXME 对home的ab测试不友好，home页面先展示了一个样式，几秒后又变了一个样式。
      }
    } else {
      console.info("begin tryApplyAbConfigForPage but not in browser ...");
    }
  } //#end of  tryApplyAbConfigForPage

  /**
   * 每个页面onLoad时尝试从已获取的AB测试场景数据（暂存在sessionStorage中）注入页面mvvm状态中
   * @param pageComp 页面Component对象
   */
  applyAbTestForPage(pageComp:any, callback:any) {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin applyAbTestForPage in browser ...");
      if (! this.abable) {
        return;
      }

      try {
        let path = pageComp.router.url;
        console.log("Try to apply abtest script for page:" + path);
        var pathWithoutPara = path;
        if (path.indexOf("?") > 0) {
          pathWithoutPara = path.substring(0, path.indexOf("?"))
        }

        // //mock test
        // this.sessionStorage.set('abId', 111);
        // this.sessionStorage.set('abVersion', "A");
        // let ccc : any = {
        //   "pageConfigs": [{
        //     "path": "/women/sale/all-sale2/product.mixed-fabric-shirt-dress.0785180003.html",
        //     "pageScript": "{ \"state\": {\"test\": \"我是abtest注入的\"}}"
        //   }]
        // };
        // // this.sessionStorage.set('abTestConfig', JSON.stringify(ccc));
        // this.abTestConfig = ccc;
        // let abConfig = this.abTestConfig;

        // console.info("path：" + pathWithoutPara);
        // let abConfigStr = this.sessionStorage.get('abTestConfig');
        let abConfigStr = this.localStorage.getItem('abTestConfig');
        // console.log("abConfig:" + abConfigStr);
        if (abConfigStr) {
          console.info("Has abConfig data,try to apply to page");
          let abConfig = JSON.parse(abConfigStr);
          let pageConfigs = abConfig.pageConfigs;
          // console.info("abConfig.pageConfigs:" + pageConfigs);
          if (pageConfigs) {
            // console.info("abConfig.pageConfigs:" + JSON.stringify(pageConfigs));
            for(var i in pageConfigs) {
              var pageConfig = pageConfigs[i];
              if (pageConfig.path === pathWithoutPara ||
                path.match(eval(pageConfig.path))) {
                var script = JSON.parse(pageConfig.pageScript);
                // console.info("abtest data in callbak script:" + script);
                Object.assign(pageComp.abstate, script.state);
                console.info("Page applied abtest script:" + pathWithoutPara + ", now state:" + JSON.stringify(pageComp.abstate));

                if(callback) callback();
              }
            }
          }
        } //end of if(abConfig)

      } catch(err) {
        console.error(err);
      }
    } else {
      console.info("begin applyAbTestForPage but not in browser ...");
    }
  }

  /**
   * 跟踪页面 TODO 此方法已被sdk自动埋点取代
   * @param {String} eventType 事件名称。onShow/onHide/onUnload ...
   * @param {Object} params
   * @param {Number} eventDuration 页面停留时长，秒
   */
  //TODO 当前trackingPage已被sensors sdk中自动埋点取代
  trackingPage(eventType:any, params:any, eventDuration:any) {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin trackingPage in browser ...");
      this.currentUrl = params.url; //当前页面url
      console.info("currentUrl in trackingPage:" + this.currentUrl);
      let titles = '其他页面';
      this.localStorage.setItem("pageUrl", this.currentUrl);
      let data:any = {
        "eventType": eventType,
        "campaign": this.localStorage.getItem("efs_campagin") ? this.localStorage.getItem("efs_campagin") : "",
        "customerId": 0,
        "data": params,
        "ip": this.localStorage.getItem("efs_tracking_ip"),
        "merchantId": this.init.merchantId,
        "model": this.localStorage.getItem('efs_tracking_model').model ? this.localStorage.getItem('efs_tracking_model').model : "***",
        "path": this.currentUrl,
        "duration": eventDuration,
        "scene": this.localStorage.getItem("efs_tracking_scene"),
        // "size": this.localStorage.getItem('efs_tracking_model').screenWidth + "*" + this.localStorage.getItem('efs_tracking_model').screenHeight,
        "size": screen.width + "*" + screen.height,
        "storeId": this.init.cloudStoreId,
        "title": titles[this.currentUrl] || "其他页面",
        "uniqueId": this.localStorage.getItem("efs_tracking_unionId"),
        "experimentId": this.getStoragedAbId(),
        "versionName": this.getStoragedAbVersion(),
      }

      return new Promise((resolve, _reject) => {
        this.request.post(apiConfig.trackingService + 'customer/tracking/page', data).toPromise().then(
          res => {
            resolve(res);
          }
        ).catch(error => error);
      });
    } else {
      console.info("begin trackingPage but not in browser ...");
	  return;
    }
  }

  /**
   * 跟踪事件行为
   * @param {Object} eventType 事件类型
   * @param {Object} value 事件值
   * @param {Object} params
   */
  trackingAction(eventType:any, value:any, params:any) {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin trackingAction in browser ...");
      var path = params.path;
      let data:any = {
        "campaign": this.localStorage.getItem("efs_campagin") ? this.localStorage.getItem("efs_campagin") : "",
        "customerId": 0,
        "data": params == '' ? {} : params,
        "ip": this.localStorage.getItem("efs_tracking_ip"),
        "merchantId": this.init.merchantId,
        "path": path,
        "scene": this.localStorage.getItem("efs_tracking_scene"),
        "storeId": this.init.cloudStoreId,
        "uniqueId": this.localStorage.getItem("efs_tracking_unionId"),
        "value": value,
        "experimentId": this.getStoragedAbId(),
        "versionName": this.getStoragedAbVersion(),
      }

      return new Promise((resolve, _reject) => {
        this.request.post(apiConfig.trackingService + 'customer/tracking/action?eventType=' + eventType, data).toPromise().then(
          res => {
            resolve(res);
          }
        ).catch(error => error);
      });
    } else {
      console.info("begin trackingAction but not in browser ...");
	  return;
    }
  }


  /**
   * 跟踪加购行为
   * @param {Object} itemId
   * @param {Object} itemName
   * @param {Object} itemPrice
   * @param {Object} valueCode
   * @param {Object} valueName
   * @param {Object} skuCode
   * @param {Object} params
   */
  trackingCart(itemId:any, itemName:any, itemPrice:any, valueCode:any, valueName:any, skuCode:any, imageSrc:any, params:any) {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin trackingCart in browser ...");
      var path = params.path;
      let data:any = {
        "path": path,
        "campaign": this.localStorage.getItem("efs_campagin") ? this.localStorage.getItem("efs_campagin") : "",
        "customerId": 0,
        "data": params == '' ? {} : params,
        "itemId": itemId,
        "itemName": itemName,
        "itemPrice": itemPrice * 100,// parseInt( itemPrice * 100)
        "valueCode": valueCode,// parseInt( valueCode )
        "valueName": valueName,
        "skuCode": skuCode,
        "merchantId": this.init.merchantId,
        "scene": this.localStorage.getItem("efs_tracking_scene"),
        "storeId": this.init.cloudStoreId,
        "uniqueId": this.localStorage.getItem("efs_tracking_unionId"),
        "imageSrc": imageSrc,
        "experimentId": this.getStoragedAbId(),
        "versionName": this.getStoragedAbVersion(),
      };

      return new Promise((resolve, _reject) => {
        this.request.post(apiConfig.trackingService + 'customer/tracking/cart', data).toPromise().then(
          res => {
            resolve(res);
          }
        ).catch(error => error);
      });
    } else {
      console.info("begin trackingCart but not in browser ...");
	  return;
    }
  }

  /**
   * 跟踪订单支付
   * @param {Object} orderStatus
   * @param {Object} orderId
   * @param {Object} orderItems
   * @param {Object} orderAmount
   * @param {Object} orderShippingFee
   * @param {Object} orderDiscountAmount
   * @param {Object} orderSubtotal
   */
  trackingOrder(orderStatus:any, orderId:any, orderItems:any, orderAmount:any, orderShippingFee:any, orderDiscountAmount:any, orderSubtotal:any, params:any = {}) {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin trackingOrder in browser ...");
      let items = [];
      for (let index in orderItems) {
        let amount = orderItems[index].amount;
        if (amount == undefined) {
          amount = orderItems[index].price * orderItems[index].qty//parseFloat(orderItems[index].price * orderItems[index].qty)
        }
        let item:any = {
          'itemId': orderItems[index].itemId,
          'itemName': orderItems[index].itemName,
          'categoryId': 100,
          'categoryName': 'default',
          'orderQty': orderItems[index].qty,
          'rowTotal': amount * 100,//parseInt( amount * 100 )
          'unitPrice': orderItems[index].price * 100,//parseInt( orderItems[index].price * 100 )
          'discountAmount': orderItems[index].discountFee * 100 // parseInt( orderItems[index].discountFee * 100 )
        };
        items.push(item);
      }
      var path = params.path;
      let data:any = {
        "path": path,
        "campaign": this.localStorage.getItem("efs_campagin") ? this.localStorage.getItem("efs_campagin") : "",
        "customerId": 0,
        "data": {},
        "ip": this.localStorage.getItem("efs_tracking_ip"),
        "merchantId": this.init.merchantId,
        "scene": this.localStorage.getItem("efs_tracking_scene"),
        "storeId": this.init.cloudStoreId,
        "uniqueId": this.localStorage.getItem("efs_tracking_unionId"),
        "orderAmount": orderAmount * 100,//parseInt( orderAmount * 100 )
        "orderDiscountAmount": orderDiscountAmount * 100,//parseInt( orderDiscountAmount * 100 )
        "orderId": orderId,
        "orderShippingFee": orderShippingFee * 100,//parseInt( orderShippingFee * 100 )
        "orderSubtotal": orderSubtotal * 100,//parseInt( orderSubtotal * 100 )
        "orderItems": items,
        "experimentId": this.getStoragedAbId(),
        "versionName": this.getStoragedAbVersion(),
      };
      return new Promise((resolve, _reject) => {
        this.request.post(apiConfig.trackingService + 'customer/tracking/order?orderStatus=' + orderStatus, data).toPromise().then(
          res => {
            resolve(res);
          }
        ).catch(error => error);
      });
    } else {
      console.info("begin trackingOrder but not in browser ...");
	  return;
    }
  }

  /**
   *
   * @param url 404 页面的路径
   * @param sourceUrl 发生404原始页面的路径
   * @param params {}类型，用于封装发生404的原始页面的参数，具体可以根据场景进行抽象参数。
   *               可为空。
   * 例如：
   *      sourseEvent 发生404原始事件。例如
   *                    load_data 加载数据时
   *                    route_page 请求或路由一个页面时 等，可自行抽象定义
   *      sourseBusiType 发生404原始事件的业务类型。例如 商品、加购 等业务模块，可自行抽象定义
   *      sourceReason 发生404的原因的备注。例如
   *                    页面不存在
   *                    数据加载失败、xx查询失败 等文字
   *      sourceActionInterface 发生404时调用的接口地址
   *      sourceActionParams 发生404时调用的接口地址的参数，可拼接参数
   *      。。。 等等
   *
   * 示例：
   *
   * {
   *    sourseBusiType: "商品",
   *    sourseEvent: "load_data",
   *    sourceReason: "商品不存在"
   *  }
   *
   *  {
   *    sourseBusiType: "商品",
   *    sourseEvent: "load_data",
   *    sourceReason: "数据加载失败",
   *    sourceActionInterface: "/goods/get-by-id",
   *    sourceActionParams: {id: 123, name: "xxx"},
   *  }
   * @returns
   */
  trackingPage404(_url:any, sourceUrl:any, params:any = {}) {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin trackingPage404 in browser ...");
      // let titles = '其他页面';
      // this.localStorage.setItem("pageUrl", url);
      // let data = {
      //   "eventType": '404',
      //   "campaign": this.localStorage.getItem("efs_campagin") ? this.localStorage.getItem("efs_campagin") : "",
      //   "path": url,
      //   "source_path": sourceUrl,
      //   "data": params,
      //   "merchantId": this.init.merchantId,
      //   "storeId": this.init.cloudStoreId,
      //   // "customerId": this.localStorage.getItem('cloud_customer_id'),
      //   // "scene": this.localStorage.getItem("efs_tracking_scene"),
      //   "ip": this.localStorage.getItem("efs_tracking_ip"),
      //   // "title": titles[this.pageUrl] || "其他页面",
      //   // "uniqueId": this.localStorage.getItem("efs_tracking_unionId"),
        // "experimentId": this.getStoragedAbId(),
        // "versionName": this.getStoragedAbVersion(),
      // }

      // return new Promise((resolve, reject) => {
      //   this.request.post(apiConfig.trackingService + 'customer/tracking/page404', data).toPromise().then(
      //     res => {
      //       resolve(res);
      //     }
      //   ).catch(error => error);
      // });
      Object.assign(params, {"sourceUrl": sourceUrl})
      this.sensorsTracking.track("404", params);
    } else {
      console.info("begin trackingPage404 but not in browser ...");
    }
  }

  /**
   * 获取unionId
   */
  getUnionId() {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin getUnionId in browser ...");
      this.localStorage.setItem('efs_tracking_unionId', this.uuid());
    } else {
      console.info("begin getUnionId but not in browser ...");
    }
	  return;
  }
  /**
   * 获取IP
   */
  getIp() {
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin getIp in browser ...");
      return new Promise((resolve, _reject) => {
        this.request.get(apiConfig.clientIpService).toPromise().then(
          res => {
            resolve(res);
          }
        ).catch(error => error);

      })
    } else {
      console.info("begin getIp but not in browser ...");
      return null;
    }
  }

  // /**
  //  * 上报Trakcing数据
  //  * @Deprecated at 2021-07-02,被tracking的onShow代替
  //  */
  // setTrackingData(data) {
  //   let params = {
  //     "merchantId": this.init.merchantId,
  //     "storeId": this.init.cloudStoreId,
  //     "action": "PAGE_VISIT",
  //     "value": data.path,
  //     "customerId": 0,
  //     "data": data,
  //     "experimentId": data.experimentId,
  //     "uniqueId": this.localStorage.getItem('deviceId'),
  //     "versionName": data.version
  //   };

  //   return new Promise((resolve, reject) => {
  //     this.request.post(apiConfig.optimizeService + '/customer/tracking/action/optimize', data).toPromise().then(
  //       res => {
  //         resolve(res);
  //       }
  //     ).catch(error => error);
  //   });
  // }

  /**
   * 生成UUId
   */
  uuid() {
    var s:any = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substring(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substring((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "";

    var uuid = s.join("");
    return uuid;
  }



}
