import {Component, Inject, OnInit, ViewChild, PLATFORM_ID, Renderer2} from '@angular/core';
import {InitializeService} from '../../../services/initialize.service';
import {makeStateKey, TransferState,Title} from '@angular/platform-browser';
import {isPlatformBrowser,ViewportScroller} from '@angular/common';
import {CmsService} from '../../../services/cms.service';
import {StorageService} from '../../../services/storage.service';
import {NavigationEnd, Router} from '@angular/router';
import {NavigationService} from '../../../services/navigation.service';
import {TrackingcodeService} from '../../../services/trackingcode.service';
import {BreadcrumbService} from '../../../services/breadcrumb.service';
import {CallComponentService} from '../../../services/callcomponent.service';
import $ from 'jquery';
import {SignInComponent} from '../sign-in/sign-in.component';
import {FavoriteService} from '../../../services/favorite.service';
import {CartService} from '../../../services/cart.service';
import {UdeskService} from '../../../services/udesk.service';
import {MiniCartComponent} from '../../mini-cart/mini-cart.component';
const localConfig = require('../../../config/config.local.json');
@Component({
  selector: 'app-cos-header',
  templateUrl: './cos-header.component.html',
  styleUrls: ['./cos-header.component.css'],
})
export class CosHeaderComponent implements OnInit {
  public useHeader = true;
  public showBack = false;
  public showNotice = true;
  // public showIndexStyle = true;
  public isIndex = true;
  public showMenu = false;
  public categories: any;
  public menuId = 0;
  public currentMenuId = 0; //下拉导航展示的navid
  public h5currentMenuId = 0; //H5下拉导航展示的navid
  public currentNav:any;
  public currentUrl = '';
  public disableId = 0;
  //搜索相关
  public showSearchBox = false;
  public searchKeys:any = [];
  public hotSearchKeys:any = [{hotword: '大衣'},{hotword: '羊绒'},{hotword: '毛衣'}];
  //登录注册相关
  public hiddenLoginBlock = true;
  @ViewChild('signIn') childComponent!: SignInComponent;
  showLoginRegisterBox = false; // 显示登录注册登录框
  showLoginBox = false;
  showRegisterBox = false;
  public select_country = false;
  emailValid = false; // 邮箱验证
  emailError!: string; // 邮箱错误
  emailVal!: string; // 邮箱
  policeVal!: string; // 隐私协议勾选
  policiesUnChecked = false; // 隐私协议
  gender = '0'; // 性别
  subscribeMessage!: string; // 订阅信息
  showSubscribeBox = false;
  isPdp:any = false;
  needBreadcrumb:any = false;
  public logoPath = '';
  public specificPage = false;

  @ViewChild('mini') miniCart!: MiniCartComponent | undefined;
  // minicartShow() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if(this.init.isLogin()){
  //       if (document.body.clientWidth < 1151) {
  //         this.router.navigate(['/cart']);
  //       } else {
  //         if(this.cartService.cartModel.getCartItems().length == 0){
  //           if(this.cartService.cartModel.getInvalidItems().length > 0){
  //             this.router.navigate(['/cart']);
  //           }else{
  //             this.child.cartShow();
  //           }
  //         }else{
  //           this.child.cartShow();
  //         }
  //       }
  //     }else{
  //       this.showLoginRegister('login');
  //     }
  //   }
  // }

  constructor(public init: InitializeService,
              public cms: CmsService,
              private localStorage: StorageService,
              private httpState: TransferState,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              private render: Renderer2,
              private navigationService: NavigationService,
              public trackingService: TrackingcodeService,
              private breadcrumbService: BreadcrumbService,
              public callComponentService: CallComponentService,
              private favoriteService:FavoriteService,
              public cartService: CartService,
              private udeskService: UdeskService,
              private titleService: Title,
              private viewportScroller: ViewportScroller
            ) {
  }
  ngOnInit() {
    if (this.localStorage.getItem('herderNotice') == 'Close') {
      this.showNotice = false;
      this.init.showNotice = false;
    } else {
      this.showNotice = true;
      this.init.showNotice = true;
    }
    this.useHeader = true;
	  this.showBack = false;
    // 监听路由变化事件
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.showMenu = false;
        // 当路由变化结束时（即URL变化完成），执行你想要的操作
        this.breadcrumbService.resetBreadcrumb('');
        this.isPdp = false;
        const currentUrl = this.router.routerState.snapshot.url;
        if (!this.localStorage.getItem('vmLogin')) {
          if(this.localStorage.getItem('EntrancePage') && this.localStorage.getItem('EntrancePage')!=''){
            const entrance_page = this.localStorage.getItem('EntrancePage');
            if(entrance_page=='w-home'){
              this.logoPath = 'w-home';
              if(!event.url || event.url=='/' || currentUrl=='/startpage'){
                this.router.navigate(['/w-home']);
              }
            } else if(entrance_page=='m-home'){
              this.logoPath = 'm-home';
              if(!event.url || event.url=='/' || currentUrl=='/startpage'){
                this.router.navigate(['/m-home']);
              }
            }
          }
        }
        if(currentUrl=='/aw24-w' || currentUrl=='/aw24-m' || currentUrl=='/aw24-w.html' || currentUrl=='/aw24-m.html' ||
          currentUrl.includes('/autumn-winter-2024-womenswear') || currentUrl.includes('/autumn-winter-2024-menswear')){
          this.specificPage = true;
        } else {
          this.specificPage = false;
        }

        this.currentUrl = currentUrl;
        this.trackingService.currentPagePath = currentUrl;

        //切换页面关闭mini购物袋
        // @ts-ignore
        this.miniCart.cartHide();

        // 结算页面隐藏登录框
        if (currentUrl === '/checkout' || currentUrl === '/presale-checkout') {
          this.hiddenLoginBlock = true;
          this.showBack = true;
        } else {
          this.hiddenLoginBlock = false;
          this.showBack = false;
        }
        if(!event.url || event.url=='/'){ //首页
          this.isIndex = true;
        } else {
          this.isIndex = false;

          // const notUseHeaderList = this.init.notUseHeaderPages;
          // if (notUseHeaderList.indexOf(currentUrl) !== -1) {
          //   this.useHeader = false;
          // }
        }

        this.callComponentService.subscribePdp.subscribe((value:any) => {
          setTimeout(() => {
            if (value) {
              this.isPdp = true;
            }
          },200);
        });
      }
    });

		this.callComponentService.noneBreadcrumb.subscribe((value:any) => {
			if(value == 'PDP') {
				setTimeout(() => {
					this.matchNav();
				},200);

			}else if(value == "PLP"){
				setTimeout(() => {
					this.matchNav();
				},1000);
			}
		});




    // 接收发射过来的数据
    this.callComponentService.loginRegisterBox.subscribe((value: boolean) => {
      if (!this.init.isLogin()) {
        if (value) {
        this.showLoginRegister('login');
        }
      }
    });

    this.callComponentService.subscribeBox.subscribe((value) => {
      if (value) {
        this.showSubscribeBox = true;
      }
    });

    setTimeout(() => {
      // if (this.useHeader) {
        if(this.showNotice){
          this.headerBlock('notice');
        }
        this.hotKeys();
        this.navigation();
      // }
    }, 200);

    /*判断登录是否过期*/
    if (this.localStorage.getItem('jwt') && (!this.localStorage.getItem('loginTime') || this.localStorage.getItem('loginTime') < new Date().valueOf() - localConfig.loginLifetime * 1000)) {
      this.logout();
    }
    //收藏商品数量
    if(this.init.isLogin()) {
      let memberLevelId = this.localStorage.getItem('memberLevelId');
      let isActive = this.localStorage.getItem('memberIsActive');
      let level = 'normal';
      if(isActive == "Active" && (memberLevelId == 2 || memberLevelId == 3)){
        level = 'vip'
      }
      let mobile = this.localStorage.getItem('mobile');
      this.udeskService.setCustomerInfo(mobile,level);

      this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage);

      if (isPlatformBrowser(this.platformId)) {
        this.cartService.getCartInfo().then();
      }
    }

  }

  logout() {
    this.showLoginBox = false;
    this.showRegisterBox = false;
    this.closeLoginRegisterBox();
    this.init.logout();
    // this.cart.restCartModel();
    // this.orderService.orders =  {data: {}, code: '', message: ''};
    // this.address.addressListResult = {code: '', message: '', data: {addressList: []}};
    this.router.navigate(['']);
  }
  hotKeys(){
    this.navigationService.getSearchHotKeys().then((res:any) => {
      if (isPlatformBrowser(this.platformId)) {
        if(res['content'] && res['content'].length > 0){
          this.hotSearchKeys = res['content'];
          if(this.hotSearchKeys.length > 5){
            this.hotSearchKeys = this.hotSearchKeys.slice(0, 5);
          }
        }
      }
    });
  }
  navigation() {
    const TOP_MENU = makeStateKey('top_menu');
    const stateMenu = this.httpState.get(TOP_MENU, null as any);
    let isIndex = true;
    let mPath = "";
    if(this.currentUrl && this.currentUrl!='/') {
      isIndex = false;
      let _path = this.currentUrl;
      if(this.currentUrl.indexOf(".html") != -1){
        _path = _path.slice(0, -5);
      }
      let arr = _path.split('/');
      if(!arr[0]){
        arr = arr.slice(1); // 从索引为1的位置开始截取数组
      }
      mPath = arr[0];
    }
    if (!stateMenu || stateMenu == null) {
      if (isPlatformBrowser(this.platformId)) {
        this.navigationService.getNavigations().then(_data => {
          const categoryArray: any = [];
          for (let i = 0; i < this.navigationService.navigations.length; i++) {
            if(i>3){
              break;
            }
            const navigation = this.navigationService.navigations[i];
            const navigationGroups: any = {};
            for (let ii = 0; ii < navigation.childs.length; ii ++) {
              const subNavigation:any = navigation.childs[ii];
              subNavigation['showNavTag'] = false;
              if (subNavigation.parameters.hasOwnProperty('navTag') && subNavigation.parameters['navTag'] != '') {
                if (subNavigation.parameters.hasOwnProperty('navTagStartTime') && subNavigation.parameters.hasOwnProperty('navTagEndTime') && subNavigation.parameters['navTagStartTime'] != '' && subNavigation.parameters['navTagEndTime'] != '') {
                  const endTimestamp = Date.parse(subNavigation.parameters['navTagEndTime'].replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
                  const startTimestamp = Date.parse(subNavigation.parameters['navTagStartTime'].replace(/-/g, '/'));
                  const timestamp = (new Date()).getTime();
                  if (timestamp >= startTimestamp && timestamp < endTimestamp) {
                    subNavigation['showNavTag'] = true;
                  }
                }
              }
              let _group_id = '';
              if (subNavigation.parameters.hasOwnProperty('categoryGroup') && subNavigation.parameters['categoryGroup'] > 0) {
                const group_id = subNavigation.parameters['categoryGroup'];
                if (!navigationGroups.hasOwnProperty('group_' + group_id)) {
                  navigationGroups['group_' + group_id] = [];
                }
                _group_id = 'group_' + group_id;
                // navigationGroups['group_' + group_id].push(subNavigation);
              } else {
                if (!navigationGroups.hasOwnProperty('group_0')) {
                  navigationGroups['group_0'] = [];
                }
                _group_id = 'group_0';
                // navigationGroups['group_0'].push(subNavigation);
              }
              if (subNavigation.parameters.hasOwnProperty('categoryGroupTitle') && subNavigation.parameters['categoryGroupTitle'] == 1) {
                subNavigation['categoryGroupTitle'] = 1;
                navigationGroups[_group_id].unshift(subNavigation);
              } else {
                subNavigation['categoryGroupTitle'] = 0;
                navigationGroups[_group_id].push(subNavigation);
              }
            }
            categoryArray[i] = navigation;
            categoryArray[i]['groupedChilds'] = navigationGroups;
          }

          this.categories = categoryArray;
          this.httpState.set(TOP_MENU, categoryArray as any);
          this.bindNav(mPath);
          this.matchNav();
          if (isPlatformBrowser(this.platformId)) {
            const bodyDom = document.querySelector('body');
            this.render.removeClass(bodyDom, 'layout-inside');
            this.render.removeClass(bodyDom, 'u-overflow-hidden');
            const loadingDom = document.querySelector('#global-loading');
            this.render.setStyle(loadingDom, 'display', 'none');
          }
        });
      }
    } else {
      if (isPlatformBrowser(this.platformId)) {
        const bodyDom = document.querySelector('body');
        this.render.removeClass(bodyDom, 'layout-inside');
        this.render.removeClass(bodyDom, 'u-overflow-hidden');
        const loadingDom = document.querySelector('#global-loading');
        this.render.setStyle(loadingDom, 'display', 'none');
      }
      const categoryArray = stateMenu;
      this.categories = categoryArray;
      this.bindNav(mPath);
      this.matchNav();
    }
  }
	//匹配导航获取面包屑数据
	matchNav(){
	  let currentUrl = this.router.routerState.snapshot.url;
	  if (currentUrl && currentUrl !== '/') {
      let _currentUrl = currentUrl
      if(currentUrl.indexOf('.html') != -1) {
        _currentUrl = currentUrl.substring(1, this.router.url.length - 5);
      }
      let urlArr = _currentUrl.split('/');
      if(!urlArr[0]){
        urlArr = urlArr.slice(1); // 从索引为1的位置开始截取数组
      }
	  // console.log(this.menuId,urlArr,this.breadcrumbService.breadcrumbsArray,'========this.menuId========')
	    if(this.menuId !=0){
        if(!!this.breadcrumbService.breadcrumbsArray && (this.breadcrumbService.breadcrumbsArray.length < urlArr.length)){
          this.breadcrumbService.addBreadcrumb(this.currentNav.navName,this.currentNav.urlPath);
          if(urlArr.length >=2){
            const sPath = urlArr[0] + '/' +urlArr[1];
            const sfilter = this.currentNav.childs.filter((_item:any) => _item.urlPath == currentUrl || _item.urlPath == sPath || _item.urlPath == sPath+'.html');
            if(sfilter.length > 0){
              this.breadcrumbService.addBreadcrumb(sfilter[0].navName,sfilter[0].urlPath);
              this._matchNav(sfilter, urlArr,currentUrl);
            }
          }else{
            if(this.isPdp){
              if(this.breadcrumbService.breadcrumbsArray.length == 1){
                var title:string = this.titleService.getTitle();
                var titleArr:any = title.split('- COS官网');
                var items : any = { "itemName": titleArr[0], "itemPath": currentUrl };
                this.breadcrumbService.addItemBreadcrumb(items);
              }
            }
          }
        }
    } else {
			if(urlArr.length >=2){
			  const sPath = urlArr[0] + '/' +urlArr[1];
			  if(!!this.categories){
				  for(let i=0; i<this.categories.length;i++){
				    if(this.categories[i].childs.length > 0){
				      const sfilter = this.categories[i].childs.filter((_item:any) => _item.urlPath == currentUrl || _item.urlPath == sPath || _item.urlPath == sPath+'.html');
				      if(sfilter.length > 0){
				        this.menuId = this.currentMenuId = this.h5currentMenuId = this.categories[i]['navId'];
				        this.currentNav = this.categories[i];
				        this.breadcrumbService.addBreadcrumb(this.categories[i].navName,this.categories[i].urlPath);
				        this.breadcrumbService.addBreadcrumb(sfilter[0].navName,sfilter[0].urlPath);
				        this._matchNav(sfilter, urlArr,currentUrl);
				        break;
				      }
				    }
				  }
			  }
			}else{
				//地址栏未带分类信息，需匹配商品详情地址
				// https://www.dev.cos.client.efashionchina.com/product.side-button-merino-cardigan.0781606002.html
				if(this.isPdp){
					if(this.breadcrumbService.breadcrumbsArray.length == 0){
						var title:string = this.titleService.getTitle();
						var titleArr:any = title.split('- COS官网');
						var items : any = { "itemName": titleArr[0], "itemPath": currentUrl };
						this.breadcrumbService.addItemBreadcrumb(items);
					}
				}
			}
     }
		}
	}
	_matchNav(sfilter: any, urlArr: any,currentUrl: string){
		if(urlArr.length >= 3 && sfilter[0].childs.length > 0){
			//需匹配商品详情地址
			if(this.isPdp){
				const tPath = urlArr[0] + '/' + urlArr[1] + '/' + urlArr[2];
				const tfilter = sfilter[0].childs.filter((_item:any) => _item.urlPath == currentUrl || _item.urlPath == tPath || _item.urlPath == tPath+'.html');
				if(tfilter.length > 0){
					this.breadcrumbService.addBreadcrumb(tfilter[0].navName,currentUrl);
				}
				setTimeout(() => {
					var title:string = this.titleService.getTitle();
					var titleArr:any = title.split('- COS官网');
					var items : any = { "itemName": titleArr[0], "itemPath": currentUrl };
					this.breadcrumbService.addItemBreadcrumb(items);
				},200);

			}else{
				const tPath = urlArr[0] + '/' + urlArr[1] + '/' + urlArr[2];
				const tfilter = sfilter[0].childs.filter((_item:any) => _item.urlPath == currentUrl || _item.urlPath == tPath || _item.urlPath == tPath+'.html');
				if(tfilter.length > 0){
					this.breadcrumbService.addBreadcrumb(tfilter[0].navName,currentUrl);
				}
			}
		}
	}

  bindNav(_path: string){
    if (this.currentUrl && this.currentUrl !== '/') {
      const _find = this.categories.find((x: { urlPath: { toString: () => string; }; }) => x.urlPath.toString() == _path || x.urlPath.toString() == _path + '.html' || x.urlPath.toString() == '/'+_path || x.urlPath.toString() == '/'+_path + '.html');
      if(_find){
        this.menuId = this.currentMenuId = this.h5currentMenuId = _find['navId'];
        this.currentNav = _find;
      }
      if(this.currentMenuId ==0){
        this.menuId = 0;
        // this.currentMenuId = this.categories[0]['navId'];
        this.h5currentMenuId = this.categories[0]['navId'];
        this.currentNav = this.categories[0];
      }
    } else {
      this.menuId = 0;
      // this.currentMenuId = this.categories[0]['navId'];
      this.h5currentMenuId = this.categories[0]['navId'];
      this.currentNav = this.categories[0];
    }
  }

  /**
   * 最顶部的公告信息
   */
  headerBlock(id: string) {
    if (this.cms.cms_timestamp[id]) {
      this.cms.headerBlock(id);
    } else {
      this.cms.getCmsTimestamp().then(
        _model => {
          this.cms.headerBlock(id);
        }
      );
    }


  }
  //关闭notice提示
  closeNotice(){
    this.localStorage.setItem('herderNotice', 'Close');
    this.showNotice = false;
    this.init.showNotice = false
    //开始页，女士首页，男士首页兼容顶部banner隐藏时的样式
    $("#scrollBox").removeClass('margin-top');
  }
  menuBtn(){
    this.showMenu = true;
    const bodyDom = document.querySelector('body');
    this.render.addClass(bodyDom, 'layout-inside');
    this.render.addClass(bodyDom, 'c-overflow-hidden');
  }
  closeMenuBtn(){
    this.showMenu = false;
    this.showSubscribeBox = false;
    this.showLoginRegisterBox = false;
    this.select_country = false;
    this.showSearchBox = false;
    const bodyDom = document.querySelector('body');
    this.render.removeClass(bodyDom, 'layout-inside');
    this.render.removeClass(bodyDom, 'c-overflow-hidden');
  }
  gotoIndex(){
    let findNav = false;
    if(this.logoPath){
      const _find = this.categories.find((x: { urlPath: { toString: () => string; }; }) => x.urlPath.toString() == this.logoPath || x.urlPath.toString() == this.logoPath + '.html' || x.urlPath.toString() == '/'+this.logoPath || x.urlPath.toString() == '/'+this.logoPath + '.html');
      if(_find){
        this.menuId = this.currentMenuId = this.h5currentMenuId = _find['navId'];
        this.currentNav = _find;
        findNav = true;
      }
    }
    if(!findNav){
      this.menuId = 0;
      // this.currentMenuId = this.categories[0]['navId'];
      this.h5currentMenuId = this.categories[0]['navId'];
      this.currentNav = this.categories[0];
    }
    this.closeMenuBtn();
  }
  //鼠标经过一级类目
  menuMouseOver(_category: any){
    if(_category['navId'] == this.disableId){
      this.disableId = 0;
      return;
    }
    this.showMenu = true;
    this.currentNav = _category;
    const bodyDom = document.querySelector('body');
    this.render.addClass(bodyDom, 'layout-inside');
    this.render.addClass(bodyDom, 'c-overflow-hidden');
  }
  //点击一级类目
  clickMenu(_category: any){
    this.menuId = _category['navId'];
    this.currentMenuId = this.h5currentMenuId = _category['navId'];
    this.showMenu = false;
	  this.breadcrumbService.resetBreadcrumb(_category);
    if (this.init.gaTrackingFlag) {
      this.trackingService.gaTrackingMenuClick('mega menu', _category['navName']);
    }
    this.currentNav = _category;
    this.disableId = _category['navId'];
    this.localStorage.setItem('EntrancePage', _category['urlPath']);
    const bodyDom = document.querySelector('body');
    this.render.removeClass(bodyDom, 'layout-inside');
    this.render.removeClass(bodyDom, 'c-overflow-hidden');
    this.router.navigate([_category['urlPath']] );
  }
  //点击下拉的一级类目
  clickDropMenu(_category: any){
		this.breadcrumbService.resetBreadcrumb(_category);
		this.currentMenuId = this.h5currentMenuId = _category['navId'];
		this.currentNav = _category;
  }

  //移除一级之后的面包屑数据
  removeElementsAfterIndex(index: number) {
    if (index >= 0 && index < this.breadcrumbService.breadcrumbsArray.length) {
      this.breadcrumbService.breadcrumbsArray.length = index + 1; // 保留指定下标的元素，其余之后的元素被移除
    }
  }

  //点击二级类目
  clickSubMenu(_category: any){
    if(!!_category['urlPath']){
		  this.callComponentService.subscribeClickSubMenu.next(true);

      //当已经有二级导航面包屑时 需先移除历史数据
      if(this.breadcrumbService.breadcrumbsArray.length == 2){
        this.breadcrumbService.breadcrumbsArray.pop();
      }
	  // else if(this.breadcrumbService.breadcrumbs.length > 2){
   //      this.removeElementsAfterIndex(0)
   //    } else if(this.breadcrumbService.breadcrumbs.length == 0){
   //      this.breadcrumbService.addBreadcrumb(this.currentNav['navName'],this.currentNav['urlPath']);
   //    }
	    this.breadcrumbService.resetBreadcrumb('');
	    this.breadcrumbService.addBreadcrumb(this.currentNav['navName'],this.currentNav['urlPath']);
      this.breadcrumbService.addBreadcrumb(_category['navName'],_category['urlPath']);
    }
    if (this.init.gaTrackingFlag) {
      this.trackingService.gaTrackingMenuClick('mega menu',_category['navName']);
    }
    this.currentMenuId = this.h5currentMenuId = this.currentNav['navId'];
    this.menuId = this.currentMenuId;
    this.closeMenuBtn();
  }

  search(){
    const local_keys =  this.localStorage.getItem('keywords');
    if(!local_keys){
      this.searchKeys = [];
    } else {
      this.searchKeys = JSON.parse(local_keys);
      if(this.searchKeys.length > 5){
        this.searchKeys = this.searchKeys.slice(0, 5);
      }
    }
    this.showSearchBox = true;
    if (isPlatformBrowser(this.platformId)) {
      $('body').addClass('layout-inside c-overflow-hidden');
    }
    if (this.init.gaTrackingFlag) {
      this.trackingService.gaTrackingMenuClick('mega menu','search');
    }
  }

  enter(type: string, value: string) {
    if(!value || !value.trim()){
      alert('请输入搜索词');
    }else{
      let _find = false;
      if(this.searchKeys.length > 0){
        for (let i=0; i<this.searchKeys.length;i++){
          if(value == this.searchKeys[i]){
            _find = true;
            break;
          }
        }
      }
      if(!_find){
        this.searchKeys.unshift(value);
      }
      this.localStorage.setItem('keywords', JSON.stringify(this.searchKeys));
      this.showSearchBox = false;
      $('body').removeClass('layout-inside c-overflow-hidden');
      this.router.navigate(['/search'], {queryParams : {keyword: value}} );
    }
  }

  preSearch(value: string){
    this.showSearchBox = false;
    $('body').removeClass('layout-inside c-overflow-hidden');
  }

  account(){
    this.showMenu = false;
    if(this.init.isLogin()){
      $('body').removeClass('layout-inside c-overflow-hidden');
      this.router.navigate(['/my-account/profile']);
    }else{
      this.showLoginRegister('login');
    }
  }

  favorites(){
    this.showMenu = false;
    if(this.init.isLogin()){
      this.router.navigate(['/my-account/favorite']);
    }else{
      this.showLoginRegister('login');
    }
  }

  getFavoriteModel(){
    let num = 0;
    if(this.init.isLogin()){
      if(this.favoriteService.favoriteModel &&  this.favoriteService.favoriteModel.hasOwnProperty('valid_total')){
        num = this.favoriteService.favoriteModel['valid_total']
      }
    }
    return num;
  }

  getCartModel() {
    return this.cartService.cartModel;
  }

  cart(){
    this.showMenu = false;
    if(this.init.isLogin()){
      this.router.navigate(['/cart']);
    }else{
      this.showLoginRegister('login');
    }
  }

  login(){
    if(this.init.isLogin()){

    }else{
      this.showMenu = false;
      this.showLoginRegister('login');
    }
  }

  register(){
    if(this.init.isLogin()){

    }else{
      this.showMenu = false;
      this.showLoginRegister('register');
    }
  }

  showLoginRegister(type: string) {
    this.closeLoginRegisterBox();
    this.showLoginRegisterBox = true;

    if (type  === 'login') {
      this.showLoginBox = true;
      this.showRegisterBox = false;

      if (this.init.gaTrackingFlag) {
        // 向Google推送浏览事件
        this.trackingService.gaTrackingLoginRegister('login_start');
      }
    } else if (type === 'register') {
      this.showLoginBox = false;
      this.showRegisterBox = true;
      if (this.init.gaTrackingFlag) {
        // 向Google推送事件
        this.trackingService.gaTrackingLoginRegister('registration_start');
      }
    }
    $('body').addClass('layout-inside c-overflow-hidden');
  }


  closeLoginRegisterBox() {
    if (this.showLoginRegisterBox) {
      this.showLoginRegisterBox = false;
      this.showLoginBox = false;
      this.showRegisterBox = false;
      this.childComponent.initSignInBox();
      if(this.localStorage.getItem('wxUserSetPassword')){
        this.localStorage.removeItem('wxUserSetPassword')
      }

      if(this.localStorage.getItem('showWxUserRegisterBox')){
        this.localStorage.removeItem('showWxUserRegisterBox')
      }
      if(this.localStorage.getItem('wx_temp_userid')){
        this.localStorage.removeItem('wx_temp_userid');
      }
    } else if (this.select_country) {
      this.select_country = false;
    } else if (this.showSubscribeBox) {
      this.showSubscribeBox = false;
      this.emailValid = false; // 邮箱验证
      this.emailError = ''; // 邮箱错误
      this.emailVal = ''; // 邮箱
      this.policeVal = ''; // 隐私协议勾选
      this.policiesUnChecked  = false; // 隐私协议
      this.gender = '0'; // 性别
      this.subscribeMessage = ''; // 订阅信息
    }
    if (isPlatformBrowser(this.platformId)) {
      $('body').removeClass('layout-inside c-overflow-hidden');
    }
  }

  closeModal(event: boolean): void {
    this.showLoginRegisterBox = event;
    this.select_country = event;
    this.closeLoginRegisterBox();
  }

  linkTo(_event:any): void {

  }
}
