import {Component, Inject, OnInit, OnDestroy, PLATFORM_ID} from '@angular/core';
import {CheckoutService} from '../../../services/checkout.service';
import {InitializeService} from '../../../services/initialize.service';
@Component({
  selector: 'app-presale-notice',
  templateUrl: './presale-notice.component.html',
  styleUrls: ['./presale-notice.component.css']
})
export class PresaleNoticeComponent implements OnInit , OnDestroy {
  constructor( public checkout: CheckoutService,
               public init: InitializeService,
               @Inject(PLATFORM_ID) private platformId: Object) {

  }
  ngOnInit() {
    this.getCartModel();
  }
  getCartModel() {
    return this.checkout.checkoutModel;
  }
  ngOnDestroy() {

  }
}
