export class NavigationModel {

  public navId: any;
  public navName: any;
  public navType: any;
  public parentNavId: any;
  public visible: any;
  public sort: any;
  public level: any;
  public foreignId: any;
  public urlPath: any;
  public description: any;
  public imageUrl: any;
  public metaTitle: any;
  public metaKeywords: any;
  public metaDescription: any;
  public parameters: any;
  public childs: Array<NavigationModel>;
  public groupedChilds: any;

  constructor(data:any) {
    this.childs = new Array<NavigationModel>();
    data['childs'].forEach((item:any, _index:any) => {
      this.childs.push(new NavigationModel(item));
    });
    const params = JSON.parse(data['parameters']);
    this.parameters = params;

    delete data['childs'];
    delete data['parameters'];
    Object.assign(this, data);
  }
}
