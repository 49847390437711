import {Component, OnInit, OnDestroy, Inject, PLATFORM_ID, ChangeDetectorRef} from '@angular/core';
import {CartService} from '../../services/cart.service';
import {InitializeService} from '../../services/initialize.service';
import {CartModel} from '../../models/cart.model';
import {CallComponentService} from '../../services/callcomponent.service';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {CheckoutService} from '../../services/checkout.service';
import $ from 'jquery';
@Component({
  selector: 'app-mini-cart',
  templateUrl: './mini-cart.component.html',
  styleUrls: ['./mini-cart.component.css']
})
export class MiniCartComponent implements OnInit, OnDestroy {
  orderResult!: CartModel;
  public svw =  false;
  columnTop!: number;
  public addCartInfo: any;
  public currency!: string;
  public timer:any;
  public h5_cartShow = false;
  public recommendLists = [];


  constructor(public cart: CartService,
              public checkout: CheckoutService,
              public init: InitializeService,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: Object,
              public callComponentService: CallComponentService) {
  }

  ngOnInit() {
    this.currency = this.init.currency;
    this.getCartModel();
    // 添加购物车显示
    this.callComponentService.addMimiCartBox.subscribe((value:any) => {
      this.addCartInfo = value;
      if(this.addCartInfo.hasOwnProperty('recommends') && this.addCartInfo['recommends'].length > 0){
        this.recommendLists = this.addCartInfo['recommends'];
      }
      this.callComponentService.changeHeaderClass.emit(true);
      this.h5_cartShow = true;
      if (isPlatformBrowser(this.platformId)) {
        $('body').addClass('body_overflow_hidden');
      }
      // const that = this;
      // if (isPlatformBrowser(this.platformId)) {
      //   this.timer = setTimeout(function () {
      //     that.closeAddCart();
      //   }, 3000);
      // }
    });
    // this.cart.getCartInfo().then( value => {
    //   this.orderResult = value;
    //   console.log(this.orderResult);
    // });
    // this.cart.getCartInfo().then( value => {
    //   this.orderResult = value;
    //   console.log(this.orderResult);
    // });
  }
  closeAddCart() {
    this.h5_cartShow = false;
    this.callComponentService.changeHeaderClass.emit(false);
    $('body').removeClass('body_overflow_hidden');
  }
  cartShow() {
    this.svw = !this.svw;
  }

  cartHide(){
    this.svw = false;
    this.closeAddCart();
  }
  getCartModel() {
    return this.cart.cartModel;
  }
  // 关闭minicar，限购50件
  closeMiniCart(page:any) {
    if (page === 'cart') {
      this.svw = false;
      this.closeAddCart();
      this.router.navigate(['/cart']);
    } else if (page === 'checkout') {
      if (this.getCartModel().total_qty_ordered > 50) {
        window.alert('最多加购50件商品！');
      } else if (this.getCartModel().total_qty_ordered > 0) {
        this.svw = false;
        this.closeAddCart();
        if (this.router.routerState.snapshot.url.indexOf('checkout') != -1) {
          this.checkout.getCheckoutData().then();
        }
        this.router.navigate(['/checkout']);
      }
    } else {
      this.svw = false;
      this.closeAddCart();
    }
  }

  ngOnDestroy() {
    this.callComponentService.addMimiCartBox.unsubscribe();
    clearTimeout(this.timer);
  }

  checkedItem(skuId: any, isChecked: number) {
    this.cart.checkedItem(isChecked == 1 ? 0 : 1, skuId).then((res:any) => {
      if (res['code'] === 1000) {
        this.cartServeData();
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  /*请求serve获取购物车数据*/
  cartServeData() {
    this.cart.getCartData().then(model => {
      this.orderResult = model;
    });
  }

  quickAdd(event:any){
    this.h5_cartShow = false;
    this.callComponentService.showSizeBoxCommon.emit(event);
  }
}
