import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InitializeService} from '../../../services/initialize.service';
import {RequestService} from '../../../services/request.service';
import {Router} from '@angular/router';
import {CheckoutService} from '../../../services/checkout.service';
import {TrackingcodeService} from '../../../services/trackingcode.service';
import {AbTrackingService} from '../../../services/abtracking.service';
import {StorageService} from '../../../services/storage.service';
import {CheckoutModel} from '../../../models/checkout.model';
import {CartService} from '../../../services/cart.service';
import {OrderService} from '../../../services/order.service';
import {Angulartics2} from 'angulartics2';
import $ from 'jquery';
const apiConfig  = require('../../../config/config.api.json');

@Component({
  selector: 'app-checkout-shipping',
  templateUrl: './checkout-shipping.component.html',
  styleUrls: ['./checkout-shipping.component.css']
})
export class CheckoutShippingComponent implements OnInit {
  @Input() checkoutType: any;
  @Input() orderRemark: any;
  public orderRemarkConfig: any = {code: 0, data: {value: false}};
  public showCheckoutRemark:boolean = false;

  constructor(public init: InitializeService,
              private request: RequestService,
              public cart: CartService,
              public order: OrderService,
              private router: Router,
              public checkout: CheckoutService,
              public tracking: TrackingcodeService,
              public abTracking: AbTrackingService,
			  private angulartics2: Angulartics2,
              private localStorage: StorageService) {
  }

  ngOnInit() {
    this.checkout.showCheckoutRemark(this.init.merchantId,this.init.cloudStoreId).then(result => {
      this.orderRemarkConfig = result;
      if (this.orderRemarkConfig.code === 1000) {
        this.showCheckoutRemark = this.orderRemarkConfig.data.value;
      }
    }).catch(error => error);
  }
}
