import {Component, Input, OnInit, Output,EventEmitter} from '@angular/core';
import {ProductService} from '../../../services/product.service';
import {SearchService} from '../../../services/search.service';
import {InitializeService} from '../../../services/initialize.service';
import { ProductModel } from '../../../models/product.model';
@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.css'],
})
export class ProductDescriptionComponent implements OnInit {
  @Input() favoriteItemIds = [];
  @Input() hasSizeGuide:boolean = false;
  @Input() finalSizeImageUrl:any;
 
  
  @Input()
	productModel!: ProductModel;
	@Output() addFavorite = new EventEmitter<object>();
	@Output() delFavorite = new EventEmitter<object>();
	@Output() changeTab = new EventEmitter<object>();
  constructor(
    public productService: ProductService,
	public searchService: SearchService,
	public init: InitializeService) {
		
  }
 
   ngOnChanges(){
 
  }
  ngOnInit() {

  }
 
  appendFavorites(itemCode : any, itemId : any, valueId : any){
  	 this.addFavorite.emit({'itemCode':itemCode,'itemId':itemId,'valueId':valueId})
  }
  
  removeFavorites(itemId : any, valueId : any){
  	  this.delFavorite.emit({'itemId':itemId,'valueId':valueId});
  }
  change(tabId : string){
	  this.changeTab.emit({'tabId':tabId});
  }
}
