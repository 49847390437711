<div *ngIf="cmsService.isEditMode() === false || cmsService.sharedData.previewMode === true" class="cms-page">
  <div *ngIf="loading; else contentBlock" class="pageLoading">
    <p>加载中⋯⋯</p>
  </div>
  <ng-template #contentBlock>
  <div class="container" *ngFor="let container of cmsService.sharedData.containers; let i = index">
    <div class="row">
      <div *ngFor="let block of cmsService.sharedData.containers[i]; let blockIndex = index" class="{{block.class}}"
           [ngClass]="{
           'hidden-xs': block.visibility.xs == false,
           'hidden-sm': block.visibility.sm == false,
           'hidden-md': block.visibility.md == false,
           'hidden-lg': block.visibility.lg == false}">
        <app-cms-block [block]="block" [containerIndex] = "i" [blockIndex] = "blockIndex"></app-cms-block>
      </div>
    </div>
  </div>
  </ng-template>
</div>

<div *ngIf="cmsService.isEditMode() === true && cmsService.sharedData.previewMode === false" class="cms-page edit-mode">
  <div class="container container-editor" *ngFor="let container of cmsService.sharedData.containers; let i = index">
    <div class="row" [dragula]="'container-bag'" [dragulaModel]="cmsService.sharedData.containers[i]">
      <div *ngFor="let block of cmsService.sharedData.containers[i]; let blockIndex = index"
           class="block-editor {{block.class}}"
           [ngClass]="{
           'hidden-xs': block.visibility.xs == false,
           'hidden-sm': block.visibility.sm == false,
           'hidden-md': block.visibility.md == false,
           'hidden-lg': block.visibility.lg == false}">
        <div class="editor-panel">
          <span class="glyphicon glyphicon-pencil" aria-hidden="true"
                (click)="openEditor(editorTemplate, block)"></span>
          <span class="glyphicon glyphicon-remove" aria-hidden="true"
                (click)="deleteBlock(deleteConfirmTemplate, cmsService.sharedData.containers[i], blockIndex)"></span>
        </div>
        <app-cms-block [block]="block" [editMode]="cmsService.isEditMode()" [containerIndex] = "i" [blockIndex] = "blockIndex"></app-cms-block>
      </div>
    </div>
    <div class="row">
      <div class="add-block">
        <span class="glyphicon glyphicon-plus" aria-hidden="true"
              (click)="addBlock(cmsService.sharedData.containers[i])">添加一个新区块</span>
      </div>
    </div>
    <hr/>
  </div>

  <div class="container">
    <hr/>
    <br/>
    <div class="panel panel-default" *ngIf="cmsService.sharedData.showData">
      <div class="panel-heading">
        <h3 class="panel-title">内容数据参考</h3>
      </div>
      <div class="panel-body">
        <pre>{{cmsService.sharedData.containers | json }}</pre>
      </div>
    </div>
  </div>

  <ng-template #deleteConfirmTemplate>
    <div class="modal-body text-center">
      <p>确认删除这个内容块？</p>
      <button type="button" class="btn btn-default" (click)="confirmDeleteBlock()">Yes</button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button type="button" class="btn btn-primary" (click)="declineDeleteBlock()">No</button>
    </div>
  </ng-template>

  <ng-template #editorTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">区块编辑器</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <label>选择宽度</label>
        <select class="form-control" [(ngModel)]="editBlock.class">
          <option *ngFor="let col of columns" value="{{col.class}}" [selected]="editBlock.class == col.class">
            {{col.name}}
          </option>
        </select>
      </div>
      <br/>
      <div>
        <label>选择可见性</label>
        <div class="form-group">
          <label class="checkbox-inline">
            <input type="checkbox" id="visibilityXS" [(ngModel)]="editBlock.visibility.xs">手机 <small>(<768px)</small>
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="visibilitySM" [(ngModel)]="editBlock.visibility.sm">平板 <small>(≥768px)</small>
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="visibilityMD" [(ngModel)]="editBlock.visibility.md">桌面 <small>(≥992px)</small>
          </label>
          <label class="checkbox-inline">
            <input type="checkbox" id="visibilityLG" [(ngModel)]="editBlock.visibility.lg">桌面 <small>(≥1200px)</small>
          </label>
        </div>
      </div>
      <hr/>
      <br/>
      <div>
        <label>区块类型</label>
        <select class="form-control" [(ngModel)]="editBlock.type">
          <option *ngFor="let editorType of editorTypes" value="{{editorType.type}}"
                  [selected]="editBlock.type == editorType.type">
            {{editorType.name}}
          </option>
        </select>
      </div>
      <br/>
      <hr/>
      <br/>
      <app-cms-block-editor [block]="editBlock"></app-cms-block-editor>
    </div>
  </ng-template>
  <!--编辑器 结束-->
</div>

<!--微信用户绑定手机号表单 开始-->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light is-visible" [ngStyle]="{'display':wxUserRegisterBox  && init.isLogin() === false?'block':'none'}"></div>
<div class="o-lightbox is-small is-open o-Logout-confirm" [ngStyle]="{'display':wxUserRegisterBox && init.isLogin() === false?'block':'none'}">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix confirm-content">
    <sign-in *ngIf="wxUserRegisterBox == true" (closeBindMobileBox)='closeModal($event)'></sign-in>
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
<!--微信用户绑定手机表单 结束-->
