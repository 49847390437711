<ng-container>
  <div data-section-name="o-presale-notice" class="o-presale-notice font_s_regular">
    <p>订单尾款支付时间为<span class="notice">{{init.changeBalanceTime(getCartModel()?.balanceStartTime)}}</span> 至 <span class="notice">{{init.changeBalanceTime(getCartModel()?.balanceEndTime)}}</span><span>&nbsp;&nbsp;尾款需在订单管理-待付款中支付，尾款支付不能与其他商品合并计算优惠。</span></p>
    <p class="mt-20"><span class="notice">如您未在规定时间内支付尾款，订单将被自动取消，且定金无法返还。</span></p>
  </div>

  <div class="o-order-summary font_s_regular">
    <div class="order-value-section">
      <span class="order-value-label">定金</span>
      <span class="order-value font_small_l_regular font_small_xs_medium">{{init.currency}}{{getCartModel()?.advancePayment | number: '1.2-2'}}</span>
    </div>
    <div class="mt-8 notice">
      <span class="discount-label">优惠</span>
      <span class="discount font_small_l_regular font_small_xs_medium">{{init.currency}}{{getCartModel()?.discountFee | number: '1.2-2'}}</span>
    </div>
    <div class="shipping-section mt-8">
      <span class="shipping-label">尾款</span>
      <span class="shipping font_small_l_regular font_small_xs_medium">{{init.currency}}{{getCartModel()?.balancePayment | number: '1.2-2'}}</span>
    </div>
  </div>
</ng-container>
