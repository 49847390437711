<div data-section-name="o-delivery" id="checkoutDeliveryPartDiv" class="delivery-section section">
  <div class="flex justify-between font_s_regular section-heading mb-20">
    <span>2.支付方式</span>
  </div>
  <div data-component="ODelivery" class="o-delivery main-area has-selected-delivery-option" data-component-id="1b2d9961-3874-44de-a3b1-0fcec1ebad6a">
    <div id="checkoutDeliveryPartForm" class="o-form ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-dirty">
      <div data-component="MRadioButtonSelector" class="m-radio-button-selector a-border">
        <div class="m-radio-button u-clearfix u-float-left" *ngIf="!weChatBrowser">
          <input type="radio" id="deliveryMode-0" [checked]="isAlipay === true && weChat === false || checkout.checkoutModel?.payType === 'wosai:alipay:wap' || aliPayBrowser" class="a-radio-button" name="delivery-selector2" value="alipay_payment"  (click)="setDefaultPaymentMethod('wosai:alipay:wap')">
          <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-0"></label>
          <label class="a-label js-a-label label-text" for="deliveryMode-0">
            <p class="a-paragraph option ng-bindin icoBg i_alipay font_s_regular">支付宝</p>
          </label>
        </div>
        <div class="m-radio-button u-clearfix u-float-left" *ngIf="!aliPayBrowser">
          <input type="radio" id="deliveryMode-1" [checked]="weChat === true && isAlipay === false || checkout.checkoutModel?.payType === 'wosai:weixin:wap' || checkout.checkoutModel?.payType === 'wosai:litepos:purchase'" class="a-radio-button" name="delivery-selector2" value="wxpay_payment" (click)="setDefaultPaymentMethod('wosai:weixin:wap')">
          <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-1"></label>
          <label class="a-label js-a-label label-text" for="deliveryMode-1">
            <p class="a-paragraph option ng-binding icoBg i_weixin font_s_regular">微信</p>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
