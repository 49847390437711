import {EventEmitter, Injectable} from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CallComponentService {
  public subscribeBox:Subject<string> = new Subject<string>();
  public loginRegisterBox: Subject<any> = new Subject<any>();
  public changeFavoriteBox: Subject<any> = new Subject<any>();
  public addMimiCartBox: EventEmitter<any> = new EventEmitter<any>();
  public changeHeaderClass: EventEmitter<boolean> = new EventEmitter<boolean>();
  public changeSizeLabel: EventEmitter<boolean> = new EventEmitter<boolean>();
  public loginAndGetCart: EventEmitter<any> = new EventEmitter<any>();
  public showSizeBoxCommon: EventEmitter<any> = new EventEmitter<any>();
  public selectCountryBox: EventEmitter<any> = new EventEmitter<any>();
  public mobPdp: EventEmitter<boolean> = new EventEmitter<boolean>();
  public subscribePdp: EventEmitter<boolean> = new EventEmitter<boolean>();
  public renewalCrumbs: EventEmitter<boolean> = new EventEmitter<boolean>();
  public subscribeClickSubMenu: EventEmitter<boolean> = new EventEmitter<boolean>();
  public noneBreadcrumb: Subject<any> = new Subject<any>();
  constructor() { }

}
