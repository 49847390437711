import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {OrderService} from '../../services/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../services/payment.service';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';
import {AbTrackingService} from '../../services/abtracking.service';
import {RemoteServer} from '../../config/config.remoteServer';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy {
  public timeStr:any;
  public paymentMethod:any;
  public incrementId:any;
  public passcode:any;
  public grandTotal:any;
  public orderCode:any;
  public paymentMethodMap:any = {alipay: '支付宝支付', weixin: '微信支付','wosai:alipay:wap':'支付宝','wosai:weixin:wap':'微信'};
  public paymentQrcode:any;
  public hasExpire:any = false;
  public timestamp:any = 120; // 二维码有效时间2分钟
  private timer:any;
  private checkPaymentTimer:any;
  public confirmFlag:any = false;
  public canCreatePayment:any = true;
  public startCheckTime:any;
  public isMobilePayment!: Boolean;
  public isWechatBrowser!: Boolean;
  public isAliPayBrowser!: boolean;
  public h5PaymentJumpUrl:any;
  public orderId:any;
  public orderData:any;
  public itemCategory:any = [];
  public itemProducts:any = [];
  public orderTime:any;
  public categoryData:any = {code: '', data: '', msg: ''};
  public paymentClientIp:any;
  public orderType:any;
  public orderStatus:any;
  public advancePayment:any;
  public balancePayment:any;
  constructor(
    public orderService: OrderService,
    public router: Router,
    public paymentService: PaymentService,
    public routerInfo: ActivatedRoute,
    private init: InitializeService,
    private localStorage:StorageService,
    private HOST:RemoteServer,
    public abTracking: AbTrackingService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }
 
  ngOnInit() {
	window.addEventListener('popstate', function(_event) {
		window.location.reload();
	 // alert('我监听到了浏览器的返回按钮事件啦==Location: ' + document.location + ', state: ' + JSON.stringify(event));
	});
 
    this.init.setTitle('结算');
    if (isPlatformBrowser(this.platformId)) {
		this.paymentInit();
    }
  }
	paymentInit(){
		this.isMobilePayment = !this.isPC();
		const ua = navigator.userAgent.toLowerCase();
		this.isWechatBrowser = (/MicroMessenger/i.test(ua));
		this.isAliPayBrowser = (/AlipayClient/i.test(ua));
 	
		this.routerInfo.params.subscribe(params => {
			this.orderId = params.id;
			if(this.localStorage.getItem('h5precreate_'+this.orderId)){
				this.router.navigate(['/payment/success/' + this.orderId]);
			}else{
				if(this.isMobilePayment && !this.isAliPayBrowser){
				  this.localStorage.setItem('h5precreate_'+this.orderId,true);
				}
				this.orderService.getCloudOrder(this.orderId).then(result => {		
				  //区分预售订单
				  if(result.code * 1 === 1000){
					  // alert('paytype===='+ result.data.orderType)
					if(result.data.orderType == 'normal'){
						if(result.data.status === 'WAIT_BUYER_PAY'){
								this.grandTotal = result.data.payment;
								this.convertPayMethod(result).then(_val =>{
									this.createPayment();
								});
						}else{
						  this.router.navigate(['/payment/success/' + this.orderId]);
						}
					}else if(result.data.orderType == 'step'){
							this.convertPayMethod(result).then(_val =>{
								if(this.orderStatus == 'WAIT_BUYER_PAY'){
								  this.grandTotal = result.data.advancePayment;
								  this.createPayment();
								}else if(this.orderStatus == 'BUYER_PART_PAYMENT'){
								  this.grandTotal = result.data.balancePayment;
								  this.createPayment();
								}else{
								  this.router.navigate(['/payment/success/'+this.orderId], {queryParams : {'orderType':result.data.orderType,'paymentStage':'lastPayment'}});
								}
							});
					}
				  }
				}).catch(error => error);
			}
		});
	}
  
	//根据浏览器，渠道 切换至兼容的支付方式
	convertPayMethod(result:any): Promise<any> {
		//官网下支付宝订单，微信浏览器发起支付时 默认将支付方式改为微信
		this.paymentMethod = result.data.payType;
		this.orderCode = result.data.orderCode;
		this.orderType = result.data.orderType;
		this.orderData = result.data;
		this.orderStatus = result.data.status;
		return new Promise( (resolve) => {
			if(this.isWechatBrowser && result.data.payType === 'wosai:alipay:wap'){
			  const params = {'payType':'wosai:litepos:purchase'};
			  this.orderService.modifyPaymentMethodServer(this.orderId,params).then(val => {
				  this.paymentMethod = 'wosai:litepos:purchase';
				   resolve(val);
			  }).catch( error => error);
			}else if(this.isAliPayBrowser && (result.data.payType === 'wosai:weixin:wap' || result.data.payType === 'wosai:litepos:purchase')){
			  const params = {'payType':'wosai:alipay:wap'};
			  this.orderService.modifyPaymentMethodServer(this.orderId,params).then(val => {
				  this.paymentMethod = 'wosai:alipay:wap';
				  resolve(val);
			  }).catch( error => error);
			}else if((this.isMobilePayment || this.isWechatBrowser) && result.data.payType === 'wosai:weixin:wap'){
				const params = {'payType':'wosai:litepos:purchase'};
				this.orderService.modifyPaymentMethodServer(this.orderId,params).then(val => {
					this.paymentMethod = 'wosai:litepos:purchase';
					resolve(val);
				}).catch( error => error);
			}else if(!this.isMobilePayment && result.data.payType === 'wosai:litepos:purchase'){
				const params = {'payType':'wosai:weixin:wap'};
				this.orderService.modifyPaymentMethodServer(this.orderId,params).then(val => {
					this.paymentMethod = 'wosai:weixin:wap';
					resolve(val);
				}).catch( error => error);
			}else{
			
				resolve('');
			}
		});
	}
  ngOnDestroy() {
    clearInterval(this.timer);
    clearInterval(this.checkPaymentTimer);
  }
  refreshQrcode() {
    if (!this.canCreatePayment) {
      return;
    }
    this.canCreatePayment = false;
    this.createPayment();
  }
  
  paymentParams(){
	let params = {}; 
	const subPayway = this.isMobilePayment ? 6 : 2;  //支付模式为整数, 2: QRCODE 3:WAP 4:MINI ,5:APP ,6:H5 默认为2
	const wxBrowser = this.isWechatBrowser ? 1 : 0;
	const subject = 'COS官网订单：'+ this.orderCode;
 
	let callbackUrl = this.HOST.protocol + '//' + this.HOST.domain
	let returnUrl = callbackUrl+'/payment/success/' + this.orderId;
	let paymentStage = '';
	if(this.orderType == 'step'){
		if(this.orderStatus == 'WAIT_BUYER_PAY'){
		  paymentStage = 'advancePayment';
		}else if(this.orderStatus == 'BUYER_PART_PAYMENT'){
		  paymentStage = 'lastPayment';
		}
	}
	
	if(this.paymentMethod == 'wosai:litepos:purchase'){
		params = {'outTradeNo':this.orderCode,'subject':subject,'returnUrl':returnUrl,'scene':2,'enableSubTenderTypes':301,'merchantId':this.init.merchantId,'storeId':this.init.officialWebsiteStoreId,'spbillCreateIp':this.paymentClientIp,'version':1};
		if(this.orderType == 'step'){
			params = {'outTradeNo':this.orderCode,'subject':subject,'returnUrl':returnUrl,'scene':2,'enableSubTenderTypes':301,'merchantId':this.init.merchantId,'storeId':this.init.officialWebsiteStoreId,'spbillCreateIp':this.paymentClientIp,'version':1,'paymentStage':paymentStage};
		}
	}else{
		params = {'merchantId':this.init.merchantId,'openid':'','outTradeNo':this.orderCode,'storeId':this.init.officialWebsiteStoreId,'subPayway':subPayway,'wxBrowser':wxBrowser,'subject':subject,'spbillCreateIp':this.paymentClientIp};
		if(this.orderType == 'step'){
			params = {'merchantId':this.init.merchantId,'openid':'','outTradeNo':this.orderCode,'storeId':this.init.officialWebsiteStoreId,'subPayway':subPayway,'wxBrowser':wxBrowser,'subject':subject,'spbillCreateIp':this.paymentClientIp,'paymentStage':paymentStage};
		}
	}
	return params;
  }
  
  createPayment() {
    if(this.isMobilePayment){
      //获取发起支付客户端ip
      this.paymentService.getPaymentIp().then(ipVal =>{
        if(ipVal.code == 1000){		
          this.paymentClientIp = ipVal.data ;
          let paymentParams = {}; 
		  //区分H5微信支付wosai:litepos:purchase
		  if(this.paymentMethod == 'wosai:litepos:purchase'){
				paymentParams = this.paymentParams();
				this.paymentService.createMobilePayment(paymentParams).then(res => {
				  if (res.code * 1 === 1000) {
				    this.abTracking.trackingOrder( 'ORDER_PAY', this.orderData.orderId,this.orderData.items, this.orderData.payment, this.orderData.postFee, this.orderData.discountFee, this.orderData.itemTotal, {path: this.router.url});
				    //H5
				    if (isPlatformBrowser(this.platformId)) {
				      if (!this.isWechatBrowser && this.paymentMethod === 'wosai:litepos:purchase') {
				        // 执行页面html触发
				        this.h5PaymentJumpUrl = res.data.cashier_url;
				        setTimeout(() => {
				          document.getElementById('h5PaymentJumpForm')?.click();
				        }, 500);
				      } else {
				        window.location.href = res.data.cashier_url;
				      }
				    }
				  } else {
				    this.router.navigate(['/my-account/order']);
				  }
				}).catch(error => error);
		  }else{  
			  paymentParams = this.paymentParams();
			  this.paymentService.createPayment(paymentParams).then(res => {
			    if (res.code * 1 === 1000) {
			      this.abTracking.trackingOrder( 'ORDER_PAY', this.orderData.orderId,this.orderData.items, this.orderData.payment, this.orderData.postFee, this.orderData.discountFee, this.orderData.itemTotal, {path: this.router.url});
			      //H5
			      if (isPlatformBrowser(this.platformId)) {
			        if (!this.isWechatBrowser && this.paymentMethod === 'wosai:weixin:wap') {
			          // 执行页面html触发
			          this.h5PaymentJumpUrl = res.data.wapPayRequest.h5PayRedirectUrl;
			          setTimeout(() => {
			            document.getElementById('h5PaymentJumpForm')?.click();
			          }, 500);
			        } else {
			          window.location.href = res.data.wapPayRequest.h5PayRedirectUrl;
			        }
			      }
			    } else {
			      this.router.navigate(['/my-account/order']);
			    }
			  }).catch(error => error);
		  }
        }
      });
    }else{
		let paymentParams = {};
		paymentParams = this.paymentParams();
      this.paymentService.createPayment(paymentParams).then(res => {
        if (res.code * 1 === 1000) {
          this.abTracking.trackingOrder( 'ORDER_PAY', this.orderData.orderId,this.orderData.items, this.orderData.payment, this.orderData.postFee, this.orderData.discountFee, this.orderData.itemTotal, {path: this.router.url});
          this.paymentQrcode = res.data.qrCodeImageUrl;
          this.hasExpire = false;
          this.startTimer();
          this.canCreatePayment = true;
          // 查询是否支付
          clearInterval(this.checkPaymentTimer);
          this.checkPayment();
        } else {
          this.router.navigate(['/my-account/order']);
        }
      }).catch(error => error);
    }
  }

  startTimer() {
    const that = this;
    that.timer = setInterval(function () {
      that.timestamp--
      that.timeStr = '';
      const minut = Math.floor(that.timestamp / 60);
      const second = that.timestamp % 60;
      if (minut === 0 && second === 0) {
        clearInterval(that.timer);
        that.hasExpire = true;
        that.timestamp = 120;
        that.timeStr = '2分0秒';
        return;
      }
      if (minut > 0) {
        that.timeStr += minut + '分';
      }
      that.timeStr += second + '秒';
      return;
    }, 1000);
  }


  checkPayment() {
    this.startCheckTime = new Date().valueOf();
    const that = this;
    that.checkPaymentTimer = setInterval(function () {
      that.paymentService.getPayment(that.orderId).then( result => {
        if (result.code * 1 === 1000) {
          //预售订单支付完成跳转
          if(result.data.orderType == 'step'){
              //定金支付成功
              if(that.orderStatus == 'WAIT_BUYER_PAY' && result.data.status === 'BUYER_PART_PAYMENT'){
                clearInterval(that.checkPaymentTimer);
                that.router.navigate(['/payment/success/'+that.orderId],{queryParams : {'orderType':result.data.orderType,'paymentStage':'advancePayment'}});
              }
              //尾款支付成功
              if(that.orderStatus == 'BUYER_PART_PAYMENT' && result.data.status === 'WAIT_SELLER_SEND_GOODS'){
                clearInterval(that.checkPaymentTimer);
                that.router.navigate(['/payment/success/'+that.orderId], {queryParams : {'orderType':result.data.orderType,'paymentStage':'lastPayment'}});
              }
          }else{
            if(result.data.status === 'WAIT_SELLER_SEND_GOODS'){
              clearInterval(that.checkPaymentTimer);
              that.router.navigate(['/payment/success/' + that.orderId]);
            }
          }

        } else if (result.code * 1 === 404 || result.code * 1 === -1002) {// -1002 aggregationId不存在

        } else {
          clearInterval(that.checkPaymentTimer);
          that.router.navigate(['/']);
        }
      }).catch(error => error);
      return;
    }, 3000);
  }
  /**
   * 客户端判断 PC or Mobile
   * @returns {boolean}
   * @constructor
   */
  isPC(): boolean {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod', 'iPad'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }
}
