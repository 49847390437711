<div class="grid grid-cols-12 gap-x-15 md:gap-x-5 col-span-12 px-0 u-clearfix ng-scope">
  <!-- 点击编辑后加上：is-hidden -->
  <!--账户信息开始 消费金额保留两位小数-->
  <div id="profileSummary" class="font_small_xs_regular col-span-12 lg-col-span-9 o-about-you" [ngClass]="{'is-hidden':showMemberGrowth || editInfo}">
    <div class="pb-40 info-block">
      <ng-container *ngIf="!editMobileAndEmail">
        <div class="flex justify-between">
          <span>手机</span>
          <button class="m-0 p-0 appearance-none flex items-center" (click)="editEmail()">
            <span>编辑</span>
            <svg style="padding-bottom: 2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-right"></use></svg>
          </button>
        </div>
        <div class="mt-8">
          <span>{{customerData.mobile}}</span>
        </div>
        <div class="mt-30">
          <span>邮箱</span>
        </div>
        <div class="mt-8">
          <span *ngIf="showAddEmail" style="color: #757575;">请添加</span>
          <span *ngIf="!showAddEmail">{{customerData.email}}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="editMobileAndEmail">
        <div class="flex justify-between">
          <span>账户信息</span>
          <button class="m-0 p-0 appearance-none flex items-center" (click)="editEmail()">
            <span>关闭</span>
            <svg style="padding-bottom: 2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#close-small"></use></svg>
          </button>
        </div>
        <div class="edit-container">
          <div class="m-input border-b entry-item mt-30 flex flex-row mobile-block">
            <div class="font_small_xs_regular mobile-left h-full text-center">（+86）</div>
            <div class="pl-15" style="line-height: 60px;"><span>{{phone}}</span></div>
          </div>
          <div class="m-input border-b entry-item bt-none">
            <label *ngIf="valite.email.errorTips && valite.email.is_active; else emailBlock;" class="a-label js-a-label mb-5 tips">{{emailMessage}}</label>
            <ng-template #emailBlock>
              <label class="a-label js-a-label mb-5" *ngIf="accountVals.email && accountVals.email.length > 0">邮箱号</label>
            </ng-template>
            <input placeholder="邮箱" class="p-0 border-none" type="email" name="email" [(ngModel)]="accountVals.email"  (blur)="emailVerify()">
          </div>
          <button (click)="saveEmail()" type="button" class="a-button is-primary mt-50 h-50" [ngClass]="{'btn-disable':!accountVals.email||saveEmailLock}" tabindex="102"><span>保存</span></button>
        </div>
      </ng-container>
    </div>
    <div class="pb-40 pt-20 info-block" *ngIf="memberActive">
      <div class="flex justify-between">
        <span>生日</span>
        <ng-container *ngIf="!memberBirthday">
          <button class="m-0 p-0 appearance-none flex items-center" (click)="editBirth()" *ngIf="!editBirthday">
            <span>编辑</span>
            <svg style="padding-bottom: 2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-right"></use></svg>
          </button>
          <button class="m-0 p-0 appearance-none flex items-center" (click)="closeBirth()" *ngIf="editBirthday">
            <span>关闭</span>
            <svg style="padding-bottom: 2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#close-small"></use></svg>
          </button>
        </ng-container>
      </div>
      <ng-container *ngIf="!editBirthday; else birthdayBlock;">
        <div class="mt-8" *ngIf="memberBirthday !== null">{{memberBirthday}}</div>
      </ng-container>
      <ng-template #birthdayBlock>
        <div class="w-full flex flex-row mt-30">
          <div class="w-full relative">
            <div class="m-input entry-item" (click)="openYear()">
              <div class="flex h-full w-full justify-between">
                <div class="flex flex-col justify-between">
                  <label class="text-left font-size-12">年</label>
                  <span *ngIf="accountVals.year && accountVals.year!=0">{{accountVals.year}}</span>
                </div>
                <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-down"></use></svg>
              </div>
            </div>
            <ul class="select-block overflow-auto a-border absolute  bt-none w-full" [ngStyle]="{'display':showYear?'block':'none'}">
              <li class="w-full" *ngFor="let year of years">
                <button (click)="selectYear(year)" class="w-full px-15 py-16 text-left font_small_xs_regular" type="button">{{year}}</button>
              </li>
            </ul>
          </div>
          <div class="w-full relative">
            <div class="m-input entry-item bl-none" (click)="openMonth()">
              <div class="flex h-full w-full justify-between">
                <div class="flex flex-col justify-between">
                  <label class="text-left font-size-12">月</label>
                  <span *ngIf="accountVals.month && accountVals.month!=0">{{accountVals.month}}</span>
                </div>
                <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-down"></use></svg>
              </div>
            </div>
            <ul class="select-block overflow-auto a-border absolute bt-none w-full" [ngStyle]="{'display':showMonth?'block':'none'}">
              <li class="w-full" *ngFor="let month of months">
                <button (click)="selectMonth(month)" class="w-full px-15 py-16 text-left font_small_xs_regular" type="button">{{month}}</button>
              </li>
            </ul>
          </div>
          <div class="w-full relative">
            <div class="m-input entry-item bl-none" (click)="openDays()">
              <div class="flex h-full w-full justify-between">
                <div class="flex flex-col justify-between">
                  <label class="text-left font-size-12">日</label>
                  <span *ngIf="accountVals.day && accountVals.day!=0">{{accountVals.day}}</span>
                </div>
                <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-down"></use></svg>
              </div>
            </div>
            <ul class="select-block overflow-auto a-border absolute bt-none w-full" [ngStyle]="{'display':showDays?'block':'none'}">
              <li class="w-full" *ngFor="let day of days">
                <button (click)="selectDay(day)" class="w-full px-15 py-16 text-left font_small_xs_regular" type="button">{{day}}</button>
              </li>
            </ul>
          </div>
        </div>
        <button (click)="saveBirthday()" type="button" class="edit-container a-button mt-50 h-50" [ngClass]="{'btn-disable':!(accountVals.year && accountVals.year!=0 && accountVals.month && accountVals.month!=0 && accountVals.day && accountVals.day!=0 && !saveBirthLock)}" tabindex="102"><span>保存</span></button>
      </ng-template>
    </div>
    <div class="pb-40 pt-20 info-block" *ngIf="memberActive">
      <div class="flex justify-between">
        <span style="color: #757575;">会员等级</span>
      </div>
      <div class="mt-8">{{customerInfo.memberLevel.levelName}}</div>
    </div>
    <div class="pb-40 pt-20 info-block" *ngIf="memberActive">
      <div class="flex justify-between">
        <span style="color: #757575;">累计消费金额</span>
      </div>
      <div class="mt-8">{{orderAmount | number: '1.2-2'}}元</div>
    </div>
    <div class="pb-40 pt-20 info-block" *ngIf="memberActive">
      <div class="flex justify-between">
        <span style="color: #757575;">会员积分</span>
      </div>
      <a style="display: block;" class="mt-8" (click)="showQrCode()">{{pointsInfo?.pointsBalance ? (pointsInfo?.pointsBalance | number: 0) : 0}}</a>
    </div>
    <div *ngIf="memberActive && goMiniPrograme" class="success-pop">
      <div class="a-icon-close" (click)="hiddenQrCodBox()"></div>
      <a href="https://weixin.qq.com/r/KzlocCDEq33UrWQT92w5 " style="display: block;"><img src="https://www.cos.cn/img/dm/pageup/200526/mp_home.jpg" width="160" alt="COS"></a>
      <div class="success-text">微信扫码查看</div>
      <div class="qrcode">更多积分详情</div>
    </div>
    <div class="py-20">
      <div class="flex justify-between">
        <span>我的员工号</span>
        <button class="m-0 p-0 appearance-none flex items-center" (click)="editCard()" *ngIf="!showCard">
          <span>编辑</span>
          <svg style="padding-bottom: 2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#chevron-mini-right"></use></svg>
        </button>
        <button class="m-0 p-0 appearance-none flex items-center" (click)="editCard()" *ngIf="showCard">
          <span>关闭</span>
          <svg style="padding-bottom: 2px;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#close-small"></use></svg>
        </button>
      </div>
      <div class="mt-8" *ngIf="!showCard">
        <span>{{customerData.staffCard}}</span>
      </div>
      <div *ngIf="showCard">
        <div class="edit-container mt-30">
          <div class="m-input border-b entry-item">
            <label *ngIf="valite.card.is_active && valite.card.errorTips; else cardBlock;" class="a-label js-a-label mb-5 tips">{{cardText}}</label>
            <ng-template #cardBlock>
              <label class="a-label js-a-label mb-5" *ngIf="accountVals.card && accountVals.card.length > 0">员工卡号</label>
            </ng-template>
            <input placeholder="员工卡号"  class="p-0 border-none" type="text" name="hmStaffCardId" [(ngModel)]="accountVals.card" (keyup)="staffKeyup()" (blur)="staffCard()" maxlength="19" [readonly]="customerData.staffCard">
          </div>
          <button (click)="saveCard()" type="button" class="a-button is-primary mt-50 h-50" [ngClass]="{'btn-disable':!accountVals.card||saveCardLock}" tabindex="102"><span>保存</span></button>
        </div>
      </div>
    </div>




    <!--<div class="userInfo w-full" style="margin-top: 300px;">
      <div class="data-sections" id="accountInfo">
        <div class="data-section phone">
          <div class="phone-label">手机</div>
          <div class="phone-value ng-binding">{{customerData.mobile}}</div>
        </div>
        <div class="data-section birthday" *ngIf="memberActive">
          <div class="birthday-label">生日
            <span style="text-align: right; padding-left: 104px;" *ngIf="memberBirthday !== null;else changeBirthday">{{memberBirthday}}</span>
            <ng-template #changeBirthday>
			   &lt;!&ndash; 过时 &ndash;&gt;
              &lt;!&ndash;<input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" id="birthday" (dateTimeChange)="dateTimeChange()" placeholder="完善信息得积分"&ndash;&gt;
              &lt;!&ndash;backdropClass	字符串/字符串 [ ]	可选	null	选取器背景的自定义类。&ndash;&gt;
              &lt;!&ndash;panelClass	字符串/字符串 [ ]	可选	null	选取器覆盖面板的自定义类。&ndash;&gt;
              &lt;!&ndash;[disabled]="true"&ndash;&gt;
              &lt;!&ndash;<owl-date-time [pickerType]="'calendar'"  panelClass="" backdropClass="" #dt1></owl-date-time&ndash;&gt;
            </ng-template>
          </div>
        </div>
        <div class="data-section email">
          <div class="email-label">Email
            <span style="text-align: right; padding-left: 100px;" *ngIf="showAddEmail">请添加</span>
          </div>
          <div class="email-value ng-binding" style="width: 100%" *ngIf="!showAddEmail">
            <span>{{customerData.email }}</span>
          </div>
        </div>
        <div class="data-section level" *ngIf="memberActive">
          <div class="level-label">会员等级</div>
          <div class="level-value ng-binding" readonly="true">{{customerInfo.memberLevel.levelName}}</div>
        </div>
        <div class="data-section consume" *ngIf="memberActive">
          <div class="consume-label">累计消费金额</div>
          <div class="consume-value ng-binding">{{orderAmount | number: '1.2-2'}}元</div>
        </div>

        <div class="data-section consume" *ngIf="memberActive">
          <div class="consume-label">会员积分：<div class="consume-value ng-binding underLine" (click)="showQrCode()">{{pointsInfo?.pointsBalance ? (pointsInfo?.pointsBalance | number: 0) : 0}}</div></div>
        </div>
        <div class="data-section card" *ngIf="customerData.staffCard">
          <div class="card-label">员工卡号</div>
          <div class="card-value ng-binding">{{customerData.staffCard}}</div>
        </div>
      </div>
      <a class="a-button edit-button" id="editProfile" (click)="editInfo = true;" href="javascript:void(0)">编辑</a>
      <a class="a-button change-password-button" routerLink="/my-account/update-password">修改密码</a>
    </div>
    <div class="QR_code" *ngIf="!memberActive">
      <img class="a-image" src="https://www.cos.cn/img/dm/pageup/190123/300x300.jpg" alt="图片">
      <p>微信扫码或长按图片识别<br>即刻加入<br>“COS摩登衣橱”微信会员计划</p>
    </div>-->
  </div>
  <!--账户信息结束-->

  <!--会员成长开始-->
  <div id="memberGrowth" class="o-my-details summary col-span-12 w-full px-0" [ngClass]="{'is-hidden':showAccountInfo}">
    <div class="data-sections">
      <div class="data-section vipInfoImg">
        <img src="https://100000005-1252208446.file.myqcloud.com/images/cos/20220516-133310.jpg" alt="会员权益">
      </div>
    </div>
  </div>
  <!--会员成长结束-->

  <!-- 默认为隐藏，点击编辑后去掉：is-hidden -->
 <!-- <form data-component="OMyDetails" class="o-form o-my-details"  [ngClass]="{'is-hidden':!editInfo}"  id="myProfileForm">
    <div class="userEdit">
      <h3 class="a-heading-3 title">账户信息</h3>
      <div class="input-wrapper">
        <div class="m-error js-m-error is-hidden">
          <label class="a-label has-error"></label>
          <label id="errorLabel" class="a-label has-error"></label>
        </div>
        &lt;!&ndash; 激活样式：is-active &ndash;&gt;
        &lt;!&ndash; 错误提示：is-filled has-error &ndash;&gt;
        <div data-component="MInput" class="m-input no-description m-input-phone-number" [ngClass]="{'is-active' : mobileActive,'is-filled has-error':mobileValid }" >
          <label class="a-label js-a-label">手机</label>
          <input class="a-input input-country-code" type="tel" name="prefix" value="+86" maxlength="11"  id="user-country-code-number" readonly="readonly">
          <input class="a-input js-a-input input-phone-number" type="tel" name="cellPhone" value="{{phone}}" [(ngModel)]="accountVals.mobile" disabled="disabled"  (blur)="blurMobileCheck()"  (focus)="mobileActiveCheck()">
          <label *ngIf="mobileValid" class="a-label js-a-label" data-placeholder="Mobile phone number">{{mobileError}}</label>
        </div>

        <div data-component="MInput" class="e-mail-input pos_rel" *ngIf="showVerifyBox">
          <div data-component="MInput" class="m-input" [ngClass]="{'is-filled has-error':phoneCaptchaValid==true,'is-active':phoneCaptchaActive==true }">
            <label class="a-label js-a-label">验证码</label>
            <input autocomplete="off" name="phoneCaptcha" class="a-input js-a-input width_70" type="text" [(ngModel)]='phoneCaptchaValue' required (blur)="blurPhoneCaptchaCheck()" (focus)="phoneCaptchaActiveCheck()" placeholder="验证码" title="验证码">
            <button type="button" [disabled]="phoneCaptchaDisabled" (click)="openImageValid()" class="btn_code">{{sendPhoneLabel}}</button>
            <label *ngIf="phoneCaptchaValid===true" class="a-label js-a-label" data-placeholder="">{{phoneCaptchaError}}</label>
          </div>
          <div class="codeBox" [ngClass]="{'is-hidden':hiddenImageValidBox}">
            <h6>验证图形码</h6>
            <div class="iptText">
              <input autocomplete="off" type="text" name ='imageValidValue' [(ngModel)]='imageValidValue' title="图形验证码">
              <img [id]="imageValidSrc" [src]="imageValidSrc" alt="图片">
              <p><a href="javascript:void(0)" (click)="createImage()">换一张</a></p>
            </div>
            <label *ngIf="imageValidError" class="a-label js-a-label codeError">{{imageValidErrorLabel}}</label>
            <button type="button" (click)="imageValueCheck()" class="a-button is-primary">确定</button>
            <button type="button" (click)="closeCodeBox()" class="a-button is-primary">取消</button>
          </div>
        </div>

        <div data-component="MInput" class="m-input email-field is-filled" data-validate="email" [ngClass]="{'is-active' : valite.email.is_active,'is-filled has-error':valite.email.errorTips }">
          <label class="a-label js-a-label">E-mail</label>
          <input class="a-input js-a-input" type="email" name="email" [(ngModel)]="accountVals.email"  (blur)="emailVerify()">
          <label *ngIf="valite.email.errorTips && valite.email.is_active" class="a-label js-a-label">{{emailMessage}}</label>
        </div>
        &lt;!&ndash; 激活状态：is-visible &ndash;&gt;
        <div data-component="MLinkToggle" class="m-link-toggle staff-card-toggle" [ngClass]="{'is-visible': extra}">
          <a class="a-link js-a-link" (click)="changeExtra()">附加信息</a>
          <div class="m-link-toggle-block js-m-link-toggle-block">
            <div data-component="MInput" class="m-input no-description staff-card" [ngClass]="{'is-active' : valite.card.is_active,'is-filled has-error':valite.card.errorTips}">
              <label class="a-label js-a-label">员工卡号</label>
              <input class="a-input js-a-input" type="text"  name="hmStaffCardId" [(ngModel)]="accountVals.card" (keyup)="staffKeyup()" (blur)="staffCard()" maxlength="19" [readonly]="customerData.staffCard">
              <label *ngIf="valite.card.is_active && valite.card.errorTips" class="a-label js-a-label" data-placeholder="员工卡号">{{cardText}}</label>
            </div>
            <div data-component="MInput" class="m-input no-description staff-card address_details"  [ngClass]="{'has-error':valite.birthday.errorTips }">
              <label class="a-label js-a-label">生日</label>
               <div class="m-dropdown selectBox">
                <span class="a-icon-close"></span>
                 <select name="yearVal" (change)="getDay()" [(ngModel)]="accountVals.year" [disabled]="customerData.birthday != '' && customerData.birthday != '00-00-00 00:00:00' && customerData.birthday != null && customerData.birthday !== '1940-01-01 00:00:00'">
                   <option class="a-option" value="0" selected>年</option>
                   <option class="a-option" *ngFor="let year of years" [value]="year" [selected]="year===accountVals.year">{{year}}</option>
                 </select>
              </div>
              <div class="m-dropdown selectBox">
                <span class="a-icon-close"></span>
                <select  name="monthVal" (change)="getDay()" [(ngModel)]="accountVals.month" [disabled]="customerData.birthday != '' && customerData.birthday != '00-00-00 00:00:00' && customerData.birthday != null && customerData.birthday !== '1940-01-01 00:00:00'">
                  <option class="a-option" value="0" selected>月</option>
                  <option class="a-option" *ngFor="let month of months" [value]="month" [selected]="month===accountVals.month">{{month}}</option>
                </select>
              </div>
              <div class="m-dropdown selectBox">
                <span class="a-icon-close"></span>
                <select name="dayVal" [(ngModel)]="accountVals.day" [disabled]="customerData.birthday != '' && customerData.birthday != '00-00-00 00:00:00' && customerData.birthday != null && customerData.birthday !== '1940-01-01 00:00:00'">
                  <option class="a-option" value="0" selected>日</option>
                  <option class="a-option" *ngFor="let day of days" [value]="day" [selected]="day===accountVals.day">{{day}}</option>
                </select>
              </div>
              <label *ngIf="valite.birthday.errorTips" class="a-label js-a-label" data-placeholder="员工生日">{{birthday}}</label>
            </div>
          </div>
        </div>
        <label class="a-label js-a-label policy-label">COS将根据 <a href="./privacy-policy" target="_blank" class="a-link privacy-policy"  style="cursor: pointer;display: inline-block;">隐私政策</a> 来使用您个人数据管理您的账号 <a href="javascript:void(0);" (click)="cancelAuthorization()" class="a-link privacy-policy"  style="cursor: pointer">取消协议授权</a>
        </label>
      </div>
      <div class="button-container">
        <button type="submit" (click)="onshitSubmit()" class="a-button is-primary btn-save-details" id="saveProfileDetails" data-default-state-text="保存" [disabled]="(accountVals.email === customerData.email && accountVals.mobile === customerData.mobile && accountVals.card === customerData.staffCard && customerData.birthday !== infoDate) || saveLock">
          <span id="saveButtonLabel">保存</span>
        </button>
        <a id="cancelButton" class="a-button is-primary is-secondary btn-cancel" (click)="editValue()" href="javascript:void(0)">取消</a>
      </div>
    </div>
  </form>-->
  <!--补充生日成功弹层-->
  <div *ngIf="addBirthdaySuccess" class="success-pop" style="display: block;">
    <div class="a-icon-close" (click)="hiddenQrCodBox()"></div>
    <div class="success-text">信息登记成功！恭喜获得1000积分</div>
    <div class="qrcode">微信扫码查看我的积分</div>
    <a href="https://weixin.qq.com/r/KzlocCDEq33UrWQT92w5 " style="display: block;"><img src="https://www.cos.cn/img/dm/pageup/200526/mp_home.jpg" width="160" alt="COS"></a>
  </div>
</div>
<!--模态框-->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': privacyPolicy || showRefuseBox}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-large" [class.is-open]="privacyPolicy">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="paragraph parbase section">
          <span class="richTextSpan">
          <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
            <div class="contain">
              <div class="u-row u-full-width">
                  <div class="content-page page" style="text-align: center" *ngIf="isCheckSaff; else zcAndEamil">
                    <p>验证员工信息中···因验证需跨境,时间较长,请稍候</p>
                  </div>
                  <ng-template #zcAndEamil>
                    <div class="content-page page" style="text-align: center">
                      <h1>抱歉！</h1>
                      <p>{{prompting}}</p>
                    </div>
                  </ng-template>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="showRefuseBox" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">如取消授权，将影响账户正常登录</h2>
      <p class="a-paragraph">&nbsp;</p>
      <a class="a-button is-primary" (click)="refuseConfirm()" style="cursor: pointer">确认取消</a>
      <button class="a-button is-secondary js-close-button" (click)="closeOverlay()"><span>再考虑下</span></button>
    </div>
  </div>
</div>




