import {Component, Input, OnInit} from '@angular/core';
import {ProductService} from '../../../../services/product.service';
import {InitializeService} from '../../../../services/initialize.service';

@Component({
  selector: 'app-cms-block-arrival-reminder-product',
  templateUrl: './cms-block-arrival-reminder-product.component.html',
  styleUrls: ['./cms-block-arrival-reminder-product.component.css']
})
export class CmsBlockArrivalReminderProductComponent implements OnInit {

  @Input() block : any;
  @Input()
	editMode!: boolean;

  public productModel: any = [];

  constructor(public productService: ProductService,
              public init: InitializeService, ) {
  }

  ngOnInit() {
    if (!this.block.hasOwnProperty('data')) {
      this.block.data = {};
    }
    if (!this.block.data.hasOwnProperty('config')) {
      this.block.data.config = {
        rows: 'threeLines',
        showNoStock: '1',
      };
    }
    if (!this.block.data.config.hasOwnProperty('showNoStock')) {
      this.block.data.config.showNoStock = '1';
    }
    if (!this.block.data.hasOwnProperty('inputItems')) {
      this.block.data.inputItems = '';
    }
    if (this.block.data.hasOwnProperty('inputItems')) {
      this.getProduct(this.block.data.inputItems, this.block.data.config.showNoStock);
    }
  }

  refresh() {
    this.getProduct(this.block.data.inputItems, this.block.data.config.showNoStock);
  }

  getProduct(inputItems:any, showNoStock:any) {
    this.productModel = [];
    if (inputItems) {
      const arr = this.block['data']['inputItems'].split(','); // 字符分割
      const page_size = arr.length;
      this.productService.getGoodsItems(this.init.merchantId,this.init.cloudStoreId, 0, page_size, 1, inputItems, showNoStock).then((data:any) => {
        if (data['code'] === 1000) {
			this.productService.afterPage = data['data']['afterPage'];
			this.productModel = data['data']['content'];
        }
      });
    }
  }
}
