import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import {OrderService} from '../../services/order.service';


@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.css']
})

export class InvoiceDetailComponent implements OnInit {
  orderId:any = '';
  cloudOrderResult: any = {data: {items: {}}};
  invoiceItems: any = [];
  public applyCancel: boolean = false;
  public email:any = '';
  public taskSn:any = '';
  taskStatus:any = {
    0: '未提交',
    1: '提交失败',
    2: '提交成功',
    3: '处理中',
    4: '开票失败',
    5: '开票成功'
  };
  public valite:any = { email: { is_active: false, errorTips: false }};
  public emailMessage:any = 'E-mail';
  public saveLock:any = false;
  public emailTimer:any = null;
  public show_modal:any = false;
  public message:any = '';
  public show_error_message:any = false;

  constructor(private routerIonfo: ActivatedRoute,
              public init: InitializeService,
              private router: Router,
              public orderService: OrderService,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }
  ngOnInit() {
    this.init.pageConfiguration('发票详情');
    if (this.routerIonfo.snapshot.queryParams['orderid'] != null) {
      this.orderId = this.routerIonfo.snapshot.queryParams['orderid'];
    }
    if (isPlatformBrowser(this.platformId)) {
      this.init.is_loading = true;
      this.getCloudOrderDetail();
    }
  }

  getCloudOrderDetail() {
    this.orderService.getCloudOrder(this.orderId).then( res => {
      if (res.code === 1000) {
        this.cloudOrderResult = res.data;
        if (this.cloudOrderResult.status == 'TRADE_FINISHED' && this.cloudOrderResult.invoiceStatus == 2 || this.cloudOrderResult.status == 'TRADE_FINISHED' && this.cloudOrderResult.invoiceStatus == 3 || this.cloudOrderResult.invoiceStatus == 5) {
          this.orderService.getInvoiceList(this.orderId).then( (result:any) => {
            if (result['code'] === 1000) {
              this.invoiceItems = result['data'].content;
              for (const i in this.invoiceItems) {
                let s_createTime = '';
                s_createTime = this.invoiceItems[i].createTime;
                this.invoiceItems[i].createTime = s_createTime.substring(0, 10);
              }
            }
          });
        } else {
          this.router.navigate(['/my-account/order/orderdetail'], {queryParams : {'orderid' : this.orderId}});
        }
        // 关闭loading
        this.init.is_loading = false;
      }
    }).catch(error => error);
  }

  saveToEail(taskSn:any) {
    this.closeOverlay();
    this.applyCancel = true;
    this.taskSn = taskSn;
  }

  confirmSaveToEail() {
    if (this.saveLock) {
      return false;
    }
    if (!this.emailVerify()) {
      return false;
    }
    const params = {
      'email': this.email,
      'taskSn': this.taskSn
    };
    this.saveLock = true;
    this.orderService.invoiceSaveToEail(this.orderId, params).then( res => {
      this.saveLock = false;
      this.closeOverlay();
      if (res['code'] == 1000) {
        this.show_modal = true;
        this.message = '申请成功';
      } else {
        this.show_error_message = true;
        this.show_modal = true;
        this.message = res['msg'];
      }
    });
	return;
  }

  // 关闭遮罩层和弹框
  closeOverlay() {
    this.applyCancel = false;
    this.saveLock = false;
    this.valite.email.is_active = false;
    this.valite.email.errorTips = false;
    this.emailMessage = 'E-mail';
    this.taskSn = '';
    this.show_modal = false;
    this.message = '';
    this.show_error_message = false;
  }

  // 判断email
  emailVerify() {
    const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (this.email === undefined || this.email === '') {
      this.emailMessage = '请输入邮箱地址';
      this.valite.email.is_active = true;
      this.valite.email.errorTips = true;
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.emailTimer = setTimeout(function () {
          that.valite.email.is_active = false;
          that.valite.email.errorTips = false;
        }, 3000);
      }
      return false;
    } else {
      this.emailMessage = 'E-mail';
      if (!reg.test(this.email)) {
        this.emailMessage = '请输入有效的电子邮箱地址';
        this.valite.email.is_active = true;
        this.valite.email.errorTips = true;
        const that = this;
        if (isPlatformBrowser(this.platformId)) {
          this.emailTimer = setTimeout(function () {
            that.valite.email.is_active = false;
            that.valite.email.errorTips = false;
          }, 3000);
        }
        return false;
      } else {
        this.emailMessage = 'E-mail';
      }
      this.valite.email.is_active = false;
      this.valite.email.errorTips = false;
    }
    return true;
  }
}
