<div class="myAccount_right">
  <div class="o-my-account-Logout o-my-account-forbid-Logout">
    <div class="warning">
      <img src="../../../assets/images/Logout/forbid-l.png" alt="图片" class="icon">
      <div class="warning-text">
        <h2 class="a-paragraph">将{{mobile}}所绑定的账户注销</h2>
        <div>请注意，注销账户后以下信息将被删除且无法恢复</div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="forbid-Logout-text a-paragraph warning-spacing"><p>• COS官网的多项产品/服务将无法通过此账号使用</p>
      <p>• 身份、账号信息、会员及权益信息</p>
      <p>• 交易记录</p>
    </div>
    <p class="contact-info a-paragraph">请确保所有交易已完结且无纠纷,账户删除后的历史交易可能产生的资金退回权益等将视作自动放弃。</p>
    <div class="button-container"><button class="a-button is-primary " data-default-state-text="下一步" (click)="nextStep()"><span >下一步</span></button></div>
  </div>
  </div>

