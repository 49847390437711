import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {AddressService} from './address.service';
import {CheckoutService} from './checkout.service';

const apiConfig = require('../config/config.api.json');

@Injectable()
export class AgreementService {
  cartItemsResult: any = {code: '', data: {imageList: {0: {productBigImageUrl: ''}}}};
  RecommendResult: any = {code: '', message: '', data: ''};

  constructor(
    public init: InitializeService,
    private request: RequestService,
    public address: AddressService,
    public checkout: CheckoutService) {}

    //协议编号获取手机号
    public getPhoneNumber(agreementCode: string){
      return new Promise((resolve) => {
        const uri = apiConfig.merchantExtendCloudApi + '/customer/merchant_agreement/agreement?agreement_code='+agreementCode;
        this.request.get(uri)
          .subscribe(
            val => {
              resolve(val);
            }
          );
      })

    }

  //协议编号获取短信验证码
  public agreementSmsCode(agreementCode: string | String){
    return new Promise((resolve) => {
      const uri = apiConfig.merchantExtendCloudApi + '/customer/merchant_agreement/sms_check?agreement_code='+agreementCode;
      this.request.get(uri).subscribe(
        val => {
          resolve(val);
        }
      );
    });
  }
//校验协议验证码
  public validateSmsCode(agreementCode: string | String,verifyCode: string){
    return new Promise((resolve) => {
      const uri = apiConfig.merchantExtendCloudApi + '/customer/merchant_agreement/validate_verify_code?agreement_code='+agreementCode+'&verify_code='+verifyCode;
      this.request.get(uri).subscribe(
        val => {
          resolve(val);
        }
      );
    });
  }

//授权
  public agreementAuthorized(param:any,agreement_id:any): Promise<any> {
    const commitUrl = apiConfig.merchantExtendCloudApi + '/customer/merchant_agreement/authorized/'+agreement_id;
    return new Promise((resolve) => {
      this.request.put(commitUrl, param).subscribe(value => {
        resolve(value);
      });
    });
  }
}
