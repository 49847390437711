<div class="u-cols-lg-24-24 u-cols-md-12-12 u-cols-sm-12-12 u-row u-full-width">
	<!-- 推荐搭配 -->
	<div *ngIf="productService.enableWhole && recommendWhole?.length >= 1" data-component="OSliderCofs"
		data-slides-desktop="4" data-slides-tablet="4" data-slides-mobile="2"
		class="o-slider-cofs o-pra-component o-product-slider o-pra-component carousel ng-scope swiper-initialized"
		data-component-id="67799f27-2090-4768-93ad-c14eeac6c2cd">
		<div class="a-heading-2 slider-title u-align-center ng-binding align-center m-r-title">推荐搭配</div>
		<!-- PC推荐搭配 -->
		<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row p-recommend-display">
			<div class="goodsGrid o-category-listing">
				<div *ngFor="let val of recommendWhole let itemIndex = index; "
					class="o-product column col-span-12 lg:col-span-3 category-list-product-block">
					<div class="image-holder">
						<div class="image-if-hover" (mouseenter)="imageMouseover(val.itemId)" (mouseleave)="imageMouseleave()">
							<!-- 此处显示收藏icon -->
							<ng-container *ngIf="showHoverProductId == val.itemId">
								<div class="rightIcon">
									<ng-container *ngIf="favoriteIds.hasOwnProperty(val.itemId+'_'+val.valueId); else notin">
										<div (click)="removeFavorites(val)" class="icon_wishlist">
											<svg>
												<title>收藏</title>
												<use href="/assets/iconSprite.svg#wishlist-active"></use>
											</svg>
										</div>
									</ng-container>
									<ng-template #notin>
										<div class="icon_wishlist"
											(click)="appendFavorites(val)">
											<svg>
												<title>收藏</title>
												<use href="/assets/iconSprite.svg#wishlist"></use>
											</svg>
										</div>
									</ng-template>
									
									
									<!-- 快速加购 -->
									<div class="quick-add">
										<button (click)="selectSizeClick(val, $event)" class="icon-add appearance-none relative flex items-center justify-center" title="加入" type="button">
											<svg class="inline h-16 w-16 fill-current">
												<title>加入</title>
												<use href="/assets/iconSprite.svg#plus"></use>
											</svg>
										</button>
									</div>
								</div>
							</ng-container>
							
							<a target="_parent" class="a-link no-styling" routerLink="/{{val?.frontUrlPath}}"
								[queryParams]="{pk_campaign:'pio-pdp'}">
								<div class="m-product-image pos_rel">
									<img class="a-image default-image"
										src="{{val.hoverUrl	 ? val.hoverUrl : val.majorUrl}}" alt="图片"
										 />
								</div>
							</a>
						</div>
					</div>

					<div class="lg:pl-0 pr-3.75 lg:pr-0 description"
						[ngClass]="{'pl20' : (productService.isMultipleOfFour(itemIndex) && isPC()),'col2':(productService.isMultipleOfTwo(itemIndex) && !isPC())}">
						<div class="mb-1 gap-x-5 goodsTag">
							<div class="goodsTitle">
								<a class="ellipsis" routerLink="/{{val?.frontUrlPath}}"
									[queryParams]="{pk_campaign:'pio-pdp'}">
									<span class="a-label js-a-label product-title"><span>{{val?.itemName}}</span></span>
								</a>

								<div class="flex">
									<div class="o-product-m-heart m-product-price"
										[ngClass]="{'pr20' : (productService.isOddNumber(itemIndex) && itemIndex !== 0)}">
										<div class="flex">
											<ng-container *ngIf="!!val.strategies && filterStrategyPrice(val.strategies) > 0 ; else otherPrice">
												<label class="a-label js-a-label"
													[ngClass]="{'is-reduced product-price':filterStrategyPrice(val.strategies) !== val?.markingPrice}">{{filterStrategyPrice(val.strategies) | currency:currency}}</label>
												<label class="a-label js-a-label"
													[ngClass]="{'is-deprecated':val?.markingPrice}">{{val?.markingPrice | currency:currency}}</label>
												
											</ng-container>
											<ng-template #otherPrice>
												<label *ngIf="val?.markingPrice !== val?.price" class="a-label js-a-label"
													[ngClass]="{'is-deprecated':val?.markingPrice}">{{val?.markingPrice | currency:currency}}</label>
												<label class="a-label js-a-label"
													[ngClass]="{'is-reduced product-price':val?.price !== val?.markingPrice}">{{val?.price | currency:currency}}</label>
											</ng-template>
										</div>
									</div>

								</div>
							</div>

							<div class="m-swatches sm">
								<ng-container *ngFor="let block of val.frontProperties">
									<div class="a-swatch js-swatch">
										<label class="a-label js-a-label colorMore"
											*ngIf="val.valueId == block.valueId">
											<span>
												<div class="a-swatch-foreground"></div>
												<a routerLink="/{{val?.frontUrlPath}}"
													[queryParams]="{pk_campaign:'pio-pdp'}">
													<img class="a-image" :src="{{block.skuImageUrl}}" alt="图片" />
												</a>
											</span>
											<span class="colorNumber"
												*ngIf="val.frontProperties.length - 1 > 0">+{{val.frontProperties.length - 1}}</span>
										</label>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 手机端推荐搭配 -->
		<div class="slider swiper-container swiper-container-horizontal m-recommend-display" [swiper]="config"
			id="praSlider">
			<div class="swiper-wrapper">
				<div *ngFor="let val of recommendWhole" class="o-product ng-scope swiper-slide swiper-slide-active">
					<a target="_parent" class="a-link no-styling" routerLink="/{{val?.frontUrlPath}}"
						[queryParams]="{pk_campaign:'pio-pdp'}">
						<div class="image-holder">
							<div class="image-if-hover">
								<div class="m-product-image pos_rel">
									<img class="a-image default-image"
										src="{{val.hoverUrl	 ? val.hoverUrl : val.majorUrl}}" alt="图片" />
								</div>
							</div>
						</div>
						<div class="description" style="display: none;">
							<label class="a-label js-a-label product-title ng-binding">{{val?.itemName}}</label>
							<div class="m-product-price">
								<label *ngIf="val?.markingPrice !== val?.price" class="a-label js-a-label"
									[ngClass]="{'is-deprecated':val?.markingPrice}">{{init.currency + val?.markingPrice}}</label>
								<label class="a-label js-a-label"
									[ngClass]="{'is-reduced product-price':val?.price !== val?.markingPrice}">{{init.currency}}{{val?.price}}</label>
							</div>
						</div>
					</a>
				</div>
			</div>

<!-- 			<div *ngIf="isPC(); else mobWholeTpl">
				<div *ngIf="recommendWhole.length > 4" class="swiper-arrow swiper-next"></div>
				<div *ngIf="recommendWhole.length > 4" class="swiper-arrow swiper-prev"></div>
			</div>
			<ng-template #mobWholeTpl>
				<div *ngIf="recommendWhole.length > 2" class="swiper-arrow swiper-next"></div>
				<div *ngIf="recommendWhole.length > 2" class="swiper-arrow swiper-prev"></div>
			</ng-template>
 -->

<!-- 			<div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
				<span class="swiper-pagination-bullet swiper-pagination-bullet-active"
					style="background-color: rgb(68, 68, 68)">
				</span>
				<span class="swiper-pagination-bullet" style="background-color: rgb(68, 68, 68)"></span>
				<span class="swiper-pagination-bullet" style="background-color: rgb(68, 68, 68)"></span>
			</div> -->
		</div>
	</div>

	<!-- 同类商品 -->
	<div style="display: none;" *ngIf="productService.enableSimilar && recommendSimilar?.length >= 1" data-component="OSliderCofs"
		data-slides-desktop="4" data-slides-tablet="4" data-slides-mobile="2"
		class="o-slider-cofs o-pra-component o-product-slider o-pra-component carousel ng-scope swiper-initialized"
		data-component-id="67799f27-2090-4768-93ad-c14eeac6c2cd">
		<div class="a-heading-2 slider-title u-align-center ng-binding align-center m-r-title">猜你喜欢</div>
		<span class="nodeName" hidden="">jcr:content</span>
		<span class="propTitle ng-binding" hidden="">猜你喜欢</span>
		<div class="slider swiper-container swiper-container-horizontal" [swiper]="config" id="praSlider">
			<!-- ngRepeat: product in cartPRACtrl.pra1Panel.products -->
			<div class="swiper-wrapper">
				<div *ngFor="let val of recommendSimilar" class="o-product ng-scope swiper-slide swiper-slide-active">
					<a target="_parent" class="a-link no-styling" routerLink="/{{val?.frontUrlPath}}"
						[queryParams]="{pk_campaign:'pio-pdp'}">
						<div class="image-holder">
							<div class="image-if-hover">
								<div class="m-product-image pos_rel">
									<img class="a-image default-image"
										src="{{val.hoverUrl ? val.hoverUrl : val.majorUrl}}" alt="图片" />
								</div>
							</div>
						</div>
						<div class="description">
							<label class="a-label js-a-label product-title ng-binding">{{val?.itemName}}</label>
							<div class="m-product-price">
								<label *ngIf="val?.markingPrice !== val?.price" class="a-label js-a-label"
									[ngClass]="{'is-deprecated':val?.markingPrice}">{{init.currency + val?.markingPrice}}</label>
								<label class="a-label js-a-label"
									[ngClass]="{'is-reduced product-price':val?.price !== val?.markingPrice}">{{init.currency}}{{val?.price}}</label>
							</div>
						</div>
					</a>
				</div>
			</div>

			<div *ngIf="isPC(); else mobSimilarTpl">
				<div *ngIf="recommendSimilar.length > 4" class="swiper-arrow swiper-next"></div>
				<!-- swiper-button-disabled-->
				<div *ngIf="recommendSimilar.length > 4" class="swiper-arrow swiper-prev"></div>
			</div>
			<ng-template #mobSimilarTpl>
				<div *ngIf="recommendSimilar.length > 2" class="swiper-arrow swiper-next"></div>
				<!-- swiper-button-disabled-->
				<div *ngIf="recommendSimilar.length > 2" class="swiper-arrow swiper-prev"></div>
			</ng-template>


			<div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
				<span class="swiper-pagination-bullet swiper-pagination-bullet-active"
					style="background-color: rgb(68, 68, 68)">
				</span>
				<span class="swiper-pagination-bullet" style="background-color: rgb(68, 68, 68)"></span>
				<span class="swiper-pagination-bullet" style="background-color: rgb(68, 68, 68)"></span>
			</div>
		</div>
	</div>

</div>