<div data-component="OPageContent" class="o-page-content cofs-page page-cart" style="margin-top:0">
  <div class="clearfix"></div>
  <nav class="col-span-full relative flex w-full gap-x-20 items-center justify-start lg-justify-center font_s_regular px-15 py36 lg-pt-20 md-px-20">
    <a class="font_small_l_bold">购物袋（ {{getCartModel()?.total_qty_ordered>0?getCartModel()?.total_qty_ordered: 0}} ）</a>
    <a routerLink="/my-account/favorite">心愿单（ {{getFavoriteModel()}} ）</a>
  </nav>

  <!-- 空购物车：加is-empty类 -->
  <div class="o-cart is-empty" *ngIf="cartLoading; else thenBlock" style="text-align: center;">
    <div class="empty-cart" style="width:100%; height: 100%;position: relative;  margin: auto">
      <div style="line-height: 100%;padding-top: 50px;">加载中......</div>
    </div>
  </div>
  <ng-template #thenBlock>
    <div class="o-cart" [ngClass]="{'is-empty': isempty}">
      <ng-container *ngIf="isempty===true; else cartBlock;">  <!--cart.cartModel?.checkedItems()?.length < 1 &&-->
        <div class="empty-cart" >
          <h2 *ngIf="cart.cartModel.getInvalidItems().length > 0;else noInvalidItem" class="a-heading-2 font_s_regular">您的购物袋目前可售商品为空</h2>
          <ng-template #noInvalidItem>
            <h2 class="a-heading-2 font_s_regular">您的购物袋是空的</h2>
          </ng-template>
          <div class="w-full btn-container">
            <a class="a-button continue_btn" routerLink="/">继续购物</a>
          </div>
        </div>
      </ng-container>
      <ng-template #cartBlock>
        <div class="grid grid-cols-12 gap-x-15 md:gap-x-5 px-15 md-px-20 gap-y-20 cartBox">
          <div class="order-1 mb-16 block h-full w-full col-span-full md-col-start-2 md-col-end-12 lg-col-start-2 lg-col-end-7 xl-col-start-3  xxl-col-start-4">
            <div style="height: 15px;" class="mb-18" *ngIf="cart.cartModel?.getCartItems()?.length > 0">
              <input type="checkbox" class="a-checkbox" id="check_all" (click)="checkedAllItem()"  [checked]="cart.cartModel?.total_has_stock_qty == cart.cartModel?.total_has_stock_checked_qty" >
              <label class="a-label js-a-label custom-box" for="check_all"></label>
              <span class="font_small_s_semibold" style="margin-left: 15px;">全选</span>
            </div>
            <ng-container *ngFor="let val of cart.cartModel?.getCartItems(); let i = index">
              <div class="flex">
                <!--失效状态-->
                <div class="checkboxItem check-item">
                 <!-- <div class="checkboxItem info-invalid" >失效</div>-->
                  <ng-container *ngIf="val.inValid != 1 && (val.stockQty * 1) > 0">
                    <input type="checkbox" class="a-checkbox" id="check_{{val.skuId}}" (click)="checkedItem(val.skuId, val.isChecked)" [checked]="val.isChecked == 1 && val.qty > 0 ? true : false" [disabled]="val.qty<=0">
                    <label class="a-label js-a-label custom-box" for="check_{{val.skuId}}"></label>
                  </ng-container>
                </div>
                <section class="w-full grid grid-cols-12 relative grid-rows-40 cart-block" [ngClass]="{'bt-none': i > 0, 'cart-block-0': i == 0}">
                  <a class="relative col-span-4 aspect-product-portrait sm-col-span-3 md-row-span-2" routerLink="/{{val.url_path}}">
                    <figure class="absolute bottom-0 left-0 right-0 top-0">
                      <img class="object-cover transition-opacity duration-300 opacity-100" src="{{val['skuProperties'][0]['hoverUrl'] + init.imageSize.$4}}" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;"/>
                    </figure>
                  </a>
                  <div class="font_xs_regular col-span-8 grid grid-cols-2-auto grid-rows-16 gap-8 sm-col-span-9 px-20 pt-16 pb-12">
                    <a class="col-start-1 overflow-hidden" routerLink="/{{val.url_path}}">
                      <h3 style="line-height: 14px;" class="font_s_regular truncate md-font_s_regular" data-testid="heading" title="{{val.itemName}}">{{val.itemName}}</h3>
                    </a>
                    <div class="col-start-2 row-start-1 row-end-3 flex items-end gap-8 flex-col-reverse md-row-span-1 md-flex-row justify-end price-section font_small_xs_medium font_small_l_regular">
                      <ng-container *ngIf="val.skuProperties[0]?.approveStatus === 'onsale';else instockTpl">
                        <span class="is-deprecated price" *ngIf="val.amountTotal !== val.amount">{{init.currency}}{{val.amountTotal | number: '2.2-2'}}</span>
                        <span [ngClass]="{'is-reduced': val.amountTotal !== val.amount}">{{init.currency}}{{val.amount | number: '1.2-2'}}</span>
                      </ng-container>
                      <!--已下架商品不显示策略价-->
                      <ng-template #instockTpl>
                        <span>{{init.currency}}{{val.amount | number: '2.2-2'}}</span>
                      </ng-template>
                    </div>
                    <span class="row-start-2 color-name" *ngIf="val.skuProperties.hasOwnProperty(0)">{{val.skuProperties[0]?.valueName}}</span>
                    <span *ngIf="(val.stockQty | number: '1.0-0') <= 2" class="relative z-10 row-start-4 self-end md-self-start font_small_xxs_medium is-reduced">库存紧张</span>
                    <ng-container *ngIf="val.activityLimitQty > 0">
                      <span *ngIf="val.stockQty > 0; else limitBlock;" style="color: #757575; text-align: right;" class="relative z-10 row-start-4 col-start-2 self-end md-self-start font_small_xxs_medium">已达限购数量</span>
                      <ng-template #limitBlock>
                        <span style="color: #757575; text-align: right;" class="relative z-10 row-start-4 col-start-2 self-end md-self-start font_small_xxs_medium">每月限购{{val.activityLimitQty}}件</span>
                      </ng-template>
                    </ng-container>
                  </div>
                  <div class="col-start-5 col-end-13 row-start-2 sm-col-start-4 md-col-end-11 relative flex h-full">
                    <div class="grid h-full num-container items-center justify-between xl-gap-x-12">
                      <ng-container *ngIf="val.qty === 1 || val.qty === 0 || val.stockQty * 1 <= 0; else decBlock;">
                        <button class="m-0 p-0 appearance-none num-button-disabled h-full btn-num" type="button" disabled="" value="decrease">
                          <svg class="inline h-16 w-16 fill-current"><title>Minus</title><use href="/assets/iconSprite.svg#minus"></use></svg>
                        </button>
                      </ng-container>
                      <ng-template #decBlock>
                        <button class="m-0 p-0 appearance-none h-full btn-num" type="button" value="decrease" (click)="delUpdateItemsQty(val.skuId, val.qty)">
                          <svg class="inline h-16 w-16 fill-current"><title>Minus</title><use href="/assets/iconSprite.svg#minus"></use></svg>
                        </button>
                      </ng-template>
                      <span style="color: #080808;" class="m-0 w-full appearance-none font_small_xs_medium relative h-full border-none p-0 text-center hide-number-controller font_si_xs_medium item-number">{{val.qty}}</span>
                     <!-- <input style="color: #080808;" disabled class="m-0 w-full appearance-none font_small_xs_medium relative h-full border-none p-0 text-center hide-number-controller font_small_xs_medium item-number" type="number" value="{{val.qty}}">-->
                      <ng-container *ngIf="cart.cartModel['total_qty_ordered'] >= 50 || val.stockQty <= val.qty || (val.qty >= val.limitPurchaseQty && val.limitPurchaseQty > 0); else addBlock;">
                        <button class="m-0 p-0 appearance-none num-button-disabled h-full btn-num" type="button" disabled="" value="increase">
                          <svg class="inline h-16 w-16 fill-current"><title>Plus</title><use href="/assets/iconSprite.svg#plus"></use></svg>
                        </button>
                      </ng-container>
                      <ng-template #addBlock>
                        <button class="m-0 p-0 appearance-none h-full btn-num" type="button" value="increase" (click)="updateItemsQty(val.skuId, val.qty)">
                          <svg class="inline h-16 w-16 fill-current"><title>Plus</title><use href="/assets/iconSprite.svg#plus"></use></svg>
                        </button>
                      </ng-template>
                    </div>
                    <div class="relative flex items-center justify-center h-full size-section">
                      <div data-component="MDropdown" class="m-dropdown pdp-dropdown is-filled m-0" (click)="showSize(val)">
                        <button type="button" class="a-button-nostyle placeholder font_si_xs_medium" id="size" aria-label="filter expand/close" aria-haspopup="true" aria-expanded="false">
                          {{productService.sizeConvert(val.skuProperties[1]?.valueName)}}
                        </button>
                        <button type="button" class="a-button-nostyle a-icon-arrow-down-small a-button-close" role="button" aria-label="expand/close" tabindex="-1">
                          <svg class="inline h-16 w-16 fill-current">
                            <use href="/assets/iconSprite.svg#chevron-down"></use>
                          </svg>
                        </button>
                        <ng-container *ngIf="showSizeBox && val.itemId==currentItemId && val.valueId==currentValueId">
                          <ul class="list-wrapper" style="display: block;">
                            <li>
                              <ul class="options size_box">
                                <!--<li  *ngFor="let sizeItem of productSizeArray[item.itemId+'-'+item.valueId]" class="a-option size-options size-det-list in-stock font_small_xs_medium"  (click)="selectSize(item.favoritesId,item.itemId,item.valueId,sizeItem.valueName,sizeItem.barcode,sizeItem.skuCode,sizeItem.skuId,item.itemName,sizeItem.qty)"> <span class="size-name"> {{productService.sizeConvert(sizeItem.valueName)}} </span><span *ngIf="sizeItem.qty < 1" class="out-stock-size">已售罄</span></li>-->
                                <li  *ngFor="let sizeItem of productSizeArray[val.itemId+'-'+val.valueId]" class="a-option size-options size-det-list in-stock font_si_xs_medium" [ngClass]="{'cursor-not-allowed': sizeItem.qty < 1}"  (click)="select_Size($event, val,sizeItem)"> <span class="size-name"> {{productService.sizeConvert(sizeItem.valueName)}} </span></li>
                              </ul>
                            </li>
                          </ul>
                        </ng-container>
                      </div>
                      <!--<div data-component="MDropdown" class="m-dropdown pdp-dropdown is-filled m-0 hidden block-xs">
                        <button type="button" class="a-button-nostyle placeholder font_small_xs_medium w-full xs-size-btn" aria-label="filter expand/close" aria-haspopup="true" aria-expanded="false">
                          {{productService.sizeConvert(val.skuProperties[1]?.valueName)}}
                        </button>
                      </div>-->
                    </div>
                  </div>

                  <div class="col-start-1 col-end-5 row-start-2 flex sm-col-end-4 md-col-start-11 md-col-end-13">
                    <button style="line-height: 48px;" class="m-0 p-0 appearance-none relative fav-btn hidden block-xs" type="button" value="remove" (click)="delItem(i)">
                     <!-- <span class="a-icon-close" style="font-size: 13px; font-weight: bold;"></span>-->
                      <svg class="inline h-16 w-16 fill-current h5Close"><title>Close</title><use href="/assets/iconSprite.svg#close"></use></svg>
                    </button>
                    <ng-container *ngIf="favoriteItemIds.hasOwnProperty(val.itemId+'_'+val.valueId); else otherBlock;">
                      <button (click)="removeConfirm(val, i)" style="border-right: .5px solid #DADADA;" class="m-0 p-0 appearance-none relative fav-btn" type="button" value="wishlist">
                        <svg class="inline h-16 w-16 fill-current"><title>Wishlist</title><use href="/assets/iconSprite.svg#wishlist-active"></use></svg>
                      </button>
                    </ng-container>
                    <ng-template #otherBlock>
                      <button (click)="addFavorite(val,i)" style="border-right: .5px solid #DADADA;" class="m-0 p-0 appearance-none relative fav-btn" type="button" value="wishlist">
                        <svg class="inline h-16 w-16 fill-current"><title>Wishlist</title><use href="/assets/iconSprite.svg#wishlist"></use></svg>
                      </button>
                    </ng-template>
                    <button style="line-height: 50px;" class="m-0 p-0 appearance-none relative close-btn -hidden-xs" type="button" value="remove" (click)="delItem(i)">
                      <!--<span class="a-icon-close" style="font-size: 13px; font-weight: bold;"></span>-->
                      <svg class="inline h-16 w-16 fill-current"><title>Close</title><use href="/assets/iconSprite.svg#close"></use></svg>
                    </button>
                  </div>
                  <ng-container *ngIf="delIndex == i">
                    <div class="confirm-block col-span-full row-start-2 col-start-4 md-col-end-13 grid font_s_regular">
                      <p class="my-auto flex">确定删除该商品吗？</p>
                      <button (click)="cancelDel()" class="m-0 p-0 appearance-none font_s_regular" type="button">取消</button>
                      <button class="m-0 p-0 appearance-none font_s_regular confirm-btn" type="button" (click)="delConfirm(val['skuId'], false)">确认</button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="addIndex == i">
                    <div class="confirm-block fav-confirm-block col-span-full row-start-2 col-start-4 md-col-end-13 grid font_s_regular">
                      <p class="my-auto flex">已成功添加至心愿单</p>
                      <button class="m-0 p-0 appearance-none font_s_regular" type="button" (click)="cancelDel()">
                        <svg class="inline h-16 w-16 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#close"></use></svg>
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="removeIndex == i">
                    <div class="confirm-block fav-confirm-block col-span-full row-start-2 col-start-4 md-col-end-13 grid font_s_regular">
                      <p class="my-auto flex">已从心愿单中移除</p>
                      <button class="m-0 p-0 appearance-none font_s_regular" type="button" (click)="cancelDel()">
                        <svg class="inline h-16 w-16 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#close"></use></svg>
                      </button>
                    </div>
                  </ng-container>
                  <!--<ng-container *ngIf="addIndex == i">
                    <div class="confirm-block col-span-full row-start-2 md-col-start-4 md-col-end-13 grid font_s_regular">
                      <p class="my-auto flex">是否将此商品移入心愿单？</p>
                      <button (click)="cancelDel()" class="m-0 p-0 appearance-none font_s_regular" type="button">取消</button>
                      <button class="m-0 p-0 appearance-none font_s_regular confirm-btn" type="button" (click)="addFavorite(val)">确认</button>
                    </div>
                  </ng-container>-->
                <!--  <ng-container *ngIf="removeIndex == i">
                    <div class="confirm-block col-span-full row-start-2 md-col-start-4 md-col-end-13 grid font_s_regular">
                      <p class="my-auto flex">确定移除心愿单吗？</p>
                      <button (click)="cancelDel()" class="m-0 p-0 appearance-none font_s_regular" type="button">取消</button>
                      <button class="m-0 p-0 appearance-none font_s_regular confirm-btn" type="button" (click)="removeConfirm(val)">确认</button>
                    </div>
                  </ng-container>-->
                </section>
              </div>
            </ng-container>
            <ng-container *ngIf="init.isLogin() && cart.cartModel.invalidItems && cart.cartModel.invalidItems !== null && cart.cartModel.invalidItems.length > 0">
              <div class="mb-18 font_small_s_semibold invalid-container" [ngClass]="{'mt-25': cart.cartModel?.getCartItems()?.length > 0}">
                <span>失效商品</span>
                <a style="float: right;" (click)="emptyInvaild()">清空失效商品</a>
              </div>
              <ng-container *ngFor="let val of cart.cartModel?.getInvalidItems(); let i = index">
                <div class="flex invalid-container">
                  <section class="w-full grid grid-cols-12 relative grid-rows-40 invalid-block" [ngClass]="{'bt-none': i > 0, 'cart-block-0': i == 0}">
                    <a class="relative col-span-4 aspect-product-portrait sm-col-span-3 md-row-span-2">
                      <figure class="absolute bottom-0 left-0 right-0 top-0" style="opacity: 0.6;">
                        <img class="object-cover transition-opacity duration-300 opacity-100" src="{{val['skuProperties'][0]['hoverUrl'] + init.imageSize.$4}}" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;"/>
                      </figure>
                    </a>
                    <div class="font_xs_regular col-span-8 grid grid-cols-2-auto grid-rows-16 gap-8 sm-col-span-9 px-20 pt-16 pb-12">
                      <a class="col-start-1 overflow-hidden">
                        <h3 style="line-height: 14px;" class="font_s_regular truncate md-font_s_regular invaild_font" title="{{val.itemName}}">{{val.itemName}}</h3>
                      </a>
                      <div class="col-start-2 row-start-1 row-end-3 flex items-end gap-8 flex-col-reverse md-row-span-1 md-flex-row justify-end invaild_font price-section font_small_xs_medium font_small_l_regular">
                        <ng-container *ngIf="val.skuProperties[0]?.approveStatus === 'onsale';else instockTpl">
                          <span class="is-deprecated price" *ngIf="val.amountTotal !== val.amount">{{init.currency}}{{val.amountTotal | number: '2.2-2'}}</span>
                          <span [ngClass]="{'is-reduced': val.amountTotal !== val.amount}">{{init.currency}}{{val.amount | number: '1.2-2'}}</span>
                        </ng-container>
                        <!--已下架商品不显示策略价-->
                        <ng-template #instockTpl>
                          <span>{{init.currency}}{{val.amount | number: '2.2-2'}}</span>
                        </ng-template>
                      </div>
                      <span class="row-start-2 invaild_font color-name" *ngIf="val.skuProperties.hasOwnProperty(0)">{{val.skuProperties[0]?.valueName}}</span>
                      <span *ngIf="val.stockQty < 1" class="relative z-10 row-start-4 self-end md-self-start font_small_xxs_medium is-reduced">已售罄</span>
                    </div>
                    <div class="col-start-5 col-end-13 row-start-2 sm-col-start-4 md-col-end-11 relative flex h-full">
                      <div class="grid h-full num-container items-center justify-between xl-gap-x-12">
                        <button class="m-0 p-0 appearance-none num-button-disabled h-full btn-num" type="button" disabled="" value="decrease">
                          <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#minus"></use></svg>
                        </button>
                        <span class="m-0 w-full appearance-none font_small_xs_medium relative h-full border-none p-0 text-center hide-number-controller font_si_xs_medium invaild_font item-number">{{val.qty}}</span>
                        <button class="m-0 p-0 appearance-none num-button-disabled h-full btn-num" type="button" disabled="" value="increase">
                          <svg class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#plus"></use></svg>
                        </button>
                      </div>
                      <div class="relative flex items-center justify-center h-full size-section">
                        <div data-component="MDropdown" class="m-dropdown pdp-dropdown is-filled m-0 ">
                          <button type="button" class="h-40 a-button-nostyle placeholder font_si_xs_medium invaild_font invalid-size" aria-label="filter expand/close" aria-haspopup="true" aria-expanded="false">
                            {{productService.sizeConvert(val.skuProperties[1]?.valueName)}}
                          </button>
                          <button type="button" class="a-button-nostyle a-icon-arrow-down-small a-button-close invaild_font -hidden-xs" role="button" aria-label="expand/close" tabindex="-1">
                            <svg class="inline h-16 w-16 fill-current">
                              <use href="/assets/iconSprite.svg#chevron-down"></use>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="col-start-1 col-end-5 row-start-2 flex sm-col-end-4 md-col-start-11 md-col-end-13">
                      <button style="line-height: 48px;" class="m-0 p-0 appearance-none relative fav-btn hidden block-xs" type="button" value="remove" (click)="delInvalidItem(i)">
                        <svg class="inline h-16 w-16 fill-current"><title>Close</title><use href="/assets/iconSprite.svg#close"></use></svg>
                      </button>
                      <ng-container *ngIf="favoriteItemIds.hasOwnProperty(val.itemId+'_'+val.valueId); else otherBlock1;">
                        <button (click)="removeConfirm(val,i, true)" style="border-right: .5px solid #DADADA;" class="m-0 p-0 appearance-none relative fav-btn" type="button" value="wishlist">
                          <svg class="inline h-16 w-16 fill-current"><title>Wishlist</title><use href="/assets/iconSprite.svg#wishlist-active"></use></svg>
                        </button>
                      </ng-container>
                      <ng-template #otherBlock1>
                        <button (click)="addFavorite(val, i, true)" style="border-right: .5px solid #DADADA;" class="m-0 p-0 appearance-none relative fav-btn" type="button" value="wishlist">
                          <svg class="inline h-16 w-16 fill-current"><title>Wishlist</title><use href="/assets/iconSprite.svg#wishlist"></use></svg>
                        </button>
                      </ng-template>
                      <button style="line-height: 50px;" class="m-0 p-0 appearance-none relative fav-btn -hidden-xs" type="button" value="remove" (click)="delInvalidItem(i)">
                       <!-- <span class="a-icon-close" style="font-size: 13px; font-weight: bold;"></span>-->
                        <svg class="inline h-16 w-16 fill-current"><title>Close</title><use href="/assets/iconSprite.svg#close"></use></svg>
                      </button>
                    </div>
                    <ng-container *ngIf="invalidDelIndex == i">
                      <div class="confirm-block col-span-full row-start-2 col-start-4 md-col-end-13 grid font_s_regular">
                        <p class="my-auto flex">确定删除该商品吗？</p>
                        <button (click)="cancelDel()" class="m-0 p-0 appearance-none font_s_regular" type="button">取消</button>
                        <button class="m-0 p-0 appearance-none font_s_regular confirm-btn" type="button" (click)="delConfirm(val['skuId'], true)">确认</button>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="invalidAddIndex == i">
                      <div class="confirm-block fav-confirm-block col-span-full row-start-2 col-start-4 md-col-end-13 grid font_s_regular">
                        <p class="my-auto flex">已成功添加至心愿单</p>
                        <button class="m-0 p-0 appearance-none font_s_regular" type="button" (click)="cancelDel()">
                          <svg class="inline h-16 w-16 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#close"></use></svg>
                        </button>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="invalidRemoveIndex == i">
                      <div class="confirm-block fav-confirm-block col-span-full row-start-2 col-start-4 md-col-end-13 grid font_s_regular">
                        <p class="my-auto flex">已从心愿单中移除</p>
                        <button class="m-0 p-0 appearance-none font_s_regular" type="button" (click)="cancelDel()">
                          <svg class="inline h-16 w-16 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#close"></use></svg>
                        </button>
                      </div>
                    </ng-container>
                  <!--  <ng-container *ngIf="invalidAddIndex == i">
                      <div class="confirm-block col-span-full row-start-2 md-col-start-4 md-col-end-13 grid font_s_regular">
                        <p class="my-auto flex">是否将此商品移入心愿单？</p>
                        <button (click)="cancelDel()" class="m-0 p-0 appearance-none font_s_regular" type="button">取消</button>
                        <button class="m-0 p-0 appearance-none font_s_regular confirm-btn" type="button" (click)="addFavorite(val, true)">确认</button>
                      </div>
                    </ng-container>-->
                    <!--<ng-container *ngIf="invalidRemoveIndex == i">
                      <div class="confirm-block col-span-full row-start-2 md-col-start-4 md-col-end-13 grid font_s_regular">
                        <p class="my-auto flex">确定移除心愿单吗？</p>
                        <button (click)="cancelDel()" class="m-0 p-0 appearance-none font_s_regular" type="button">取消</button>
                        <button class="m-0 p-0 appearance-none font_s_regular confirm-btn" type="button" (click)="removeConfirm(val, true)">确认</button>
                      </div>
                    </ng-container>-->
                  </section>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="recommendLists.length > 0">
              <div class="mt-30 recommend-container lg-hidden">
                <app-recommend-common [recommendLists]="recommendLists" [count]="4" (quickAdd)="quickAdd($event)"></app-recommend-common>
              </div>
            </ng-container>
          </div>

          <div style="z-index: 10;" class="order-3 lg-order-2 w-full sticky bottom-0 z-20 lg-bottom-auto lg-h-fit order-container col-span-full md-col-start-2 md-col-end-12 lg:col-start-7 lg-col-end-12 xl-col-start-7 xl-col-end-11 xxl-col-start-7 xxl-col-end-10">
            <div class="hidden lg-block">
              <div class="order-amount-section flex h-auto flex-column">
                <ul class="flex flex-col gap-8 font_s_regular">
                  <li class="flex justify-between">
                    <span>小计</span><span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{cart.cartModel?.itemTotal | number: '1.2-2'}}</span>
                  </li>
                  <li class="flex justify-between">
                    <span>运费</span><span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{cart.cartModel?.postFee | number: '1.2-2'}}</span>
                  </li>
                  <li class="flex justify-between" *ngIf="cart.cartModel?.discountFee > 0">
                    <span>优惠</span><span style="color: #C80000">-<span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{cart.cartModel?.discountFee | number: '1.2-2'}}</span></span>
                  </li>
                  <li class="flex justify-between" *ngIf="cart.cartModel?.couponFee > 0">
                    <span>券减</span>
                    <span style="color: #C80000">-<span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{cart.cartModel?.couponFee | number: '1.2-2'}}</span></span>
                  </li>
                  <li class="flex justify-between" *ngIf="cart.cartModel?.couponCode">
                    <span>优惠券</span><span class="font_small_xs_medium font_small_l_regular">{{cart.cartModel?.couponRuleName}}</span>
                  </li>
                </ul>
                <div class="flex justify-between total-block">
                  <span class="font_s_regular font_small_l_bold">总计</span>
                  <span class="font_si_xs_bold">{{init.currency}}{{cart.cartModel?.payment  | number: '1.2-2'}}</span>
                </div>
              </div>
              <ng-container *ngIf="cart.cartModel?.total_qty_checked <= 0; else elseblocks;">
                <a class="a-button is-primary checkout-button is-disabled" href="javascript:;">结算</a>
              </ng-container>
              <ng-template #elseblocks>
                <a class="a-button is-primary checkout-button" href="javascript:;" (click)="goTocheckout()">结算</a>
              </ng-template>
            </div>
            <ng-container *ngIf="recommendLists.length > 0">
              <div class="mt-25 recommend-container hidden lg-block">
                <app-recommend-common [recommendLists]="recommendLists" [count]="4" (quickAdd)="quickAdd($event)"></app-recommend-common>
              </div>
            </ng-container>
            <div class="h-full p-15 checkout-container lg-hidden">
              <div class="checkout-total">
                <a class="w-full font_small_xs_regular p-0 mb-10 block" type="button" (click)="checkoutDetail()">
                  <span class="flex justify-between font_small_xxs_medium">
                    <span class="font_small_xxs_regular font_small_l_regular">{{cart.cartModel?.total_qty_checked}}件</span>
                    <span *ngIf="!showCheckoutDetail" class="moreBtn"><span>查看详情</span><svg class="inline h-16 w-16 fill-current -mt-0.5 ml-2"><title>Plus</title><use href="/assets/iconSprite.svg#plus-small"></use></svg></span>
                    <span *ngIf="showCheckoutDetail" class="moreBtn"><span>收起详情</span><svg class="inline h-16 w-16 fill-current -mt-0.5 ml-2"><title>Plus</title><use href="/assets/iconSprite.svg#minus-small"></use></svg></span>
                  </span>
                </a>
                <div class="flex justify-between">
                  <span class="font_s_regular font_small_l_bold">总计</span><span class="font_si_xs_bold">{{init.currency}}{{cart.cartModel?.payment  | number: '1.2-2'}}</span>
                </div>
              </div>
              <div class="grid opacity-100 sticky checkout-block" [ngStyle]="{'display':showCheckoutDetail?'block':'none'}">
                <div class="overflow-hidden">
                  <ul class="flex flex-column gap-8 font_small_xxs_medium" style="line-height: 17px;">
                    <li class="flex justify-between"><span>小计</span><span class="font_small_xxs_regular font_small_l_regular">{{init.currency}}{{cart.cartModel?.itemTotal | number: '1.2-2'}}</span></li>
                    <li class="flex justify-between"><span>运费</span><span class="font_small_xxs_regular font_small_l_regular">{{init.currency}}{{cart.cartModel?.postFee | number: '1.2-2'}}</span></li>
                    <li class="flex justify-between" *ngIf="cart.cartModel?.discountFee > 0"><span>优惠</span><span style="color: #C80000">-<span class="font_small_xxs_regular font_small_l_regular">{{init.currency}}{{cart.cartModel?.discountFee | number: '1.2-2'}}</span></span></li>
                    <li class="flex justify-between" *ngIf="cart.cartModel?.couponFee > 0"><span>券减</span><span style="color: #C80000">-<span class="font_small_xxs_regular font_small_l_regular">{{init.currency}}{{cart.cartModel?.couponFee | number: '1.2-2'}}</span></span></li>
                    <li class="flex justify-between" *ngIf="cart.cartModel?.couponCode"><span>优惠券</span><span class="font_small_xxs_regular font_small_l_regular" style="color: #C80000">{{cart.cartModel?.couponRuleName}}</span></li>
                  </ul>
                </div>
              </div>
              <ng-container *ngIf="cart.cartModel?.total_qty_checked <= 0; else elseblocks;">
                <a class="a-button is-primary checkout-button is-disabled" href="javascript:;">结算</a>
              </ng-container>
              <ng-template #elseblocks>
                <a class="a-button is-primary checkout-button" href="javascript:;" (click)="goTocheckout()">结算</a>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>

<!-- 弹层：快速加购 -->
<div class="subBox" id="add-to-buy" *ngIf="showBox">
  <app-quick-add
	[frontCode]="frontCode"
	[favoriteItemIds]="favoriteItemIds"
	[salePrice]="salePrice"
	(addFavorite)="quickAddFavorite($event)"
	(delFavorite)="quickRemoveFavoriteItem($event)"
	(closeBox)="closeLayer()"></app-quick-add>
</div>

<!-- 显示优惠券弹框：加is-select-country is-open -->
<div style="z-index: 1100;" class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': promotion || remindSuccess || remindError || canEmptyInvaild}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div style="z-index: 1100;" data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="promotion">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 优惠券弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{paragraph}}</p>
    </div>
    <!-- 优惠券弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>


<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="canEmptyInvaild" style="text-align: center;z-index: 1100;">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 删除购物车弹窗 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">您确认清空失效商品吗？</h2>
      <p class="a-paragraph">&nbsp;</p>
      <button class="a-button is-secondary-empty js-close-button" (click)="closeOverlay()"><span>再考虑下</span></button>
      <a class="a-button is-primary-empty" (click)="emptyInvaildConfirm()" style="cursor: pointer;">确认清空</a>
    </div>
  </div>
</div>


<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="remindSuccess || remindError" style="z-index: 1100">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="paragraph parbase section">
          <span class="richTextSpan">
          <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
            <div class="contain">
              <div class="u-row u-full-width">
                <div class="content-page page remind" *ngIf="remindSuccess; else zcAndEamil">
                  <h1>扫码关注COS官方微信第一时间获取到货通知</h1>
                  <p><img alt="图片" class="qr-image" src="https://100000005-1252208446.file.myqcloud.com/images/qrCode/2021010897667.jpg"></p>
                </div>
                <ng-template #zcAndEamil>
                  <div class="content-page page remind">
                    <h1>抱歉！</h1>
                    <p>{{remindErrorMsg}}</p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
<!--<app-favorite-box></app-favorite-box>-->
