import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {StorageService} from './storage.service';
import {InitializeService} from './initialize.service';
import {AddressService} from './address.service';
import {CartModel} from '../models/cart.model';
import {CheckoutService} from './checkout.service';

const apiConfig = require('../config/config.api.json');

@Injectable()
export class CartService {
  cartItemsResult: any = {code: '', data: {imageList: {0: {productBigImageUrl: ''}}}};
  RecommendResult: any = {code: '', message: '', data: ''};
  public miniCartShow = 2;
  public orderData:any;
  public orderDataArr:any;
  public cartLoading: Boolean = true;
  public productSize:any;
  public product_id:any = '';
  public default_sku:any = '';
  public item_key:any = '';
  public productCommend:any;
  public sociomanticTracking:any;
  public colorsArr:any;
  public SelectedColor:any;
  public SelectedSize:any;
  public cartModel!: CartModel;
  public quoteData:any;
  public productOptions = [];
  constructor(
    public init: InitializeService,
    private request: RequestService,
    public address: AddressService,
    public checkout: CheckoutService,
    private localStorage: StorageService) {}

  /**
   * 从购物车API中获取数据
   * @returns {Promise<any>}
   */
  getCartData(quoteId:any = ''): Promise<any> {
    this.QuoteId();
    quoteId = this.quoteData;
    if ((quoteId === 'null' || !quoteId) && !this.init.isLogin()) {
      return new Promise((resolve1) => {
        this.cartModel = new CartModel({});
        resolve1(this.cartModel);
      });
    }
    if ((quoteId === 'null' || !quoteId) && this.init.isLogin()) {
      return new Promise((resolve1) => {
        this.cartModel = new CartModel({});
        resolve1(this.cartModel);
      });
    }
    const getCartUrl = apiConfig.cloudCart + '/sales_cart/' + quoteId + '/customer';
    let resultData: any = {code:  '', message: '' ,  data: {}};
    const doLp = new Promise((resolve1) => {
      this.request.get(getCartUrl).toPromise().then((res:any) => {
        resultData = res;
        if (resultData.code === 1000) { // 购物车存在
          const that = this;
          that.cartModel = new CartModel(res['data']);
          if (that.cartModel.address !== undefined) {
            that.address.hasAddress = true;
          }
          let itemLists = $.extend(true, [], this.cartModel.items ? this.cartModel.items : []);
          if (itemLists && itemLists != null  && itemLists.length > 0) {
            const hasStockItems:any = [];
            const noStockItems:any = [];
            itemLists.forEach((item:any) => {
              if (item['stockQty'] > 0) {
                hasStockItems.push(item);
              } else {
                noStockItems.push(item);
              }
            });
            if (hasStockItems.length > 0 && noStockItems.length > 0) {
              itemLists = [];
              itemLists = hasStockItems.concat(noStockItems);
              that.cartModel.items = itemLists;
            }
          }
          this.setPayType();
          this.cartModel.setItemData();
        } else { // 购物车不存在 则创建
          if (resultData.code === -2002 || resultData.code === -2003) { // cloud_jwt 过期
            this.init.logout();
          } else {
            const paramsJson:any = {'customerId': this.localStorage.getItem('cloud_customer_id'), 'merchantId': this.init.merchantId, 'orderSource': this.init.orderSource, 'storeId': this.init.cloudStoreId.toString()};
            this.request.post(apiConfig.cloudCart + '/sales_cart', paramsJson, false, false, true).toPromise().then((cartRes:any) => {
              if (cartRes['code'] === 1000) {
                this.localStorage.setItem('quote_id', cartRes['data']['cartKey'] );
                this.setPayType();
                this.cartModel.setItemData();
              }
            });
          }
          this.cartModel = new CartModel({});
        }
        resolve1(this.cartModel);
      }).catch((error: any) => {
        return error;
      });
    });
    return doLp;
  }

  setPayType() {
    const browsers = {
      versions: function() {
        const u = navigator.userAgent;
        return {     // 移动终端浏览器版本信息
          trident: u.indexOf('Trident') > -1, // IE内核
          presto: u.indexOf('Presto') > -1, // opera内核
          webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
          gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, // 火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/) && u.indexOf('QIHU') && u.indexOf('Chrome') < 0, // 是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android终端或uc浏览器
          iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, // 是否为iPhone或者QQHD浏览器
          iPad: u.indexOf('iPad') > -1, // 是否iPad
          webApp: u.indexOf('Safari') === -1,  // 是否web应该程序，没有头部与底部
        };
      }(),
      // language: (navigator.browserLanguage || navigator.language).toLowerCase()
    };
    // H5端判断
    const ua = navigator.userAgent.toLowerCase();
    const isWx: boolean = (/MicroMessenger/i.test(ua));
    if (browsers.versions.mobile && isWx === true) {
      if (this.cartModel.payType != 'wosai:weixin:wap') {
        this.checkout.setCloudPaymentMethod('wosai:weixin:wap').then();
      }
    } else {
      if (this.cartModel.payType != 'wosai:weixin:wap' && this.cartModel.payType != 'wosai:alipay:wap') {
        if (this.localStorage.getItem('payType') && this.localStorage.getItem('payType') != null
          && (this.localStorage.getItem('payType') == 'wosai:alipay:wap' || this.localStorage.getItem('payType') == 'wosai:weixin:wap')) {
          this.checkout.setCloudPaymentMethod(this.localStorage.getItem('payType')).then();
        } else {
          this.checkout.setCloudPaymentMethod('wosai:alipay:wap').then();
        }
      }
    }
  }


  analysisProductColor(options:any) {
    this.productOptions = JSON.parse(options);
    return this.productOptions[0];
  }

  analysisProductSize(options:any) {
    this.productOptions = JSON.parse(options);
    return this.productOptions[1];
  }
 
  /**
   * 从购物车API中获取数据
   * @returns {Promise<any>}
   */
  getCartInfo(quoteId:any = ''): Promise<any> {
    this.QuoteId();
    quoteId = this.quoteData;
    if (this.cartModel === undefined) {
      return this.getCartData(quoteId);
    } else {
      return new Promise((resolve1, _reject) => {
        resolve1(this.cartModel);
      });
    }
  }

  /**
   * 重置cartModel
   * @returns {Promise<any>}
   */
  restCartModel() {
    this.cartModel = new CartModel([]);
  }

  /**
   * 将商品移除或保存server
   * @param params
   * @returns {Promise<any>}
   */
  settleLaterServer(params:any): Promise<any> {
    this.QuoteId();
    const settleLaterUrl = apiConfig.shoppingCartApi + '/' + this.quoteData + '?action=change_checkout_items';
    return new Promise((resolve1, _reject) => {
      this.request.put(settleLaterUrl, params).toPromise().then(res => {
        resolve1(res);
      }).catch(error => error);
    });
  }

  /**
   * 结算商品删除server
   * @param params
   * @returns {Promise<any>}
   */
  removeItemServer(sku_id:any): Promise<any> {
    this.QuoteId();
    const removeItemUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/item/'+sku_id;
    return new Promise((resolve, _reject) => {
      this.request.post(removeItemUrl, sku_id).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }


  emptyInvaildItems(): Promise<any> {
    this.QuoteId();
    const param:any = {cart_key:this.quoteData};
    const removeItemUrl = apiConfig.cloudCart + '/customer/sales_cart/' + this.quoteData + '/invalid_item';
    return new Promise((resolve, _reject) => {
      this.request.post(removeItemUrl,param).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  /**
   * 使用优惠卷server
   * @param params
   * @returns {Promise<any>}
   */
  useCouponServer(couponCode:any): Promise<any> {
    this.QuoteId();
    const useCouponUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/checkout/coupon_code?coupon_code=' + couponCode;
    return new Promise((resolve, _reject) => {
      this.request.put(useCouponUrl, null).toPromise().then(res => {resolve(res); }).catch(error => error);
    });
  }
  /**
   * 取消使用优惠卷server
   * @param params
   * @returns {Promise<any>}
   */
  cancelCouponServer(): Promise<any> {
    this.QuoteId();
    const useCouponUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/clear/coupon_code';
    return new Promise((resolve, _reject) => {
      this.request.put(useCouponUrl, null).toPromise().then(res => {resolve(res); }).catch(error => error);
    });
  }

  /**
   * 使用积分server
   * @param params
   * @returns {Promise<any>}
   */
  useCreditsServer(params:any): Promise<any> {
    const useintegralUrl = apiConfig.shoppingCartApi + '/' + this.localStorage.getItem('quote_id');
    return new Promise((resolve, _reject) => {
      this.request.update(useintegralUrl, params).toPromise().then(res => {resolve(res); }).catch(error => error);
    });
  }

  /**
   * 添加购物纸袋server
   * @param params
   * @returns {Promise<any>}
   */
  addExchangeServer(params:any): Promise<any> {
    this.QuoteId();
    const addExchangeUrl = apiConfig.shoppingCartApi + '/' + this.quoteData;
    return new Promise((resolve1, _reject) => {
      this.request.put(addExchangeUrl, params).toPromise().then(res => {resolve1(res); }).catch(error => error);
    });
  }

  /**
   * 删除购物纸袋Server
   * @param params
   * @returns {Promise<any>}
   */
  rmExchangeServer(params:any): Promise<any> {
    this.QuoteId();
    const rmExchangeUrl = apiConfig.shoppingCartApi + '/' + this.QuoteId();
    return new Promise((resolve1, _reject) => {
      this.request.put(rmExchangeUrl, params).toPromise().then(res => {resolve1(res); }).catch(error => error);
    });
  }

  /**
   * 修改商品数量server
   * @param params
   * @returns {Promise<any>}
   */
  updateItemsQtyServer(sku_id:any, qty:any): Promise<any> {
    this.QuoteId();
    const updataItemsUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/item_qty?qty=' + qty + '&sku_id=' + sku_id;
    return new Promise((resolve1, _reject) => {
      this.request.put(updataItemsUrl, null).toPromise().then(res => {
        resolve1(res);
      }).catch(error => error);
    });
  }

  /**
   * 修改商品尺码server
   * @param params
   * @returns {Promise<any>}
   */
  updateItemsSizeServer(params: any): Promise<any> {
    this.QuoteId();
    const updataItemsUrl = apiConfig.cloudCart + '/customer/sales_cart/' + this.quoteData + '/change_item';
    return new Promise((resolve, _reject) => {
      this.request.post(updataItemsUrl,params).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  /*quote_id */
  QuoteId() {
    this.quoteData = this.localStorage.getItem('quote_id');
    return this.quoteData;
  }

  getItemList(product_id:any, default_sku:any, item_key:any, ccolor:any, csize:any) {
    const itemInfoUrl = '/rest/productnew?id=' + product_id;
    this.request.list(itemInfoUrl)
      .subscribe(
        val => {
          this.cartItemsResult = val;
          const colorsData:any = [];
          const colorArray:any = [];
          for (const i in this.cartItemsResult.data.colors) {
            if (this.cartItemsResult.data.colors.hasOwnProperty(i)) {
              if (!colorsData.hasOwnProperty(this.cartItemsResult.data.colors[i].salePrice)) {
                colorsData[this.cartItemsResult.data.colors[i].salePrice] = [];
                colorsData[this.cartItemsResult.data.colors[i].salePrice]['price'] = this.cartItemsResult.data.colors[i].salePrice;
                colorsData[this.cartItemsResult.data.colors[i].salePrice]['colors'] = [];
              }
              if (this.cartItemsResult.data.colors[i]['colorName'] === ccolor) {
                this.productSize = this.cartItemsResult.data.colors[i].size;
              }
              this.cartItemsResult.data.colors[i]['cid'] = i;
              colorsData[this.cartItemsResult.data.colors[i].salePrice]['colors'].push(this.cartItemsResult.data.colors[i]);
            }
          }
          for (const i in colorsData) {
            colorArray.push(colorsData[i]);
          }
          this.colorsArr = colorArray;
        }
      );
    this.SelectedColor = ccolor;
    this.SelectedSize = csize;
    this.product_id = product_id;
    this.default_sku = default_sku;
    this.item_key = item_key;
  }

  colorBlockClick(i:any) {
    this.productSize = this.cartItemsResult.data.colors[i].size;
  }

  youMayLike(itemPids:any) {
    /*商品推荐*/
    if (itemPids === undefined || itemPids === '') {
      return;
    }
    const recommendUrl = '/rest/recommender?action=cart_products&item_ids=' + itemPids;
    const recommendArray:any = [];
    this.request.get(recommendUrl).subscribe
    (r => {
      /*this.collections = JSON.stringify(r);*/
      this.RecommendResult = r;
      this.orderData = this.RecommendResult.data;
      for (const i in this.orderData) {
        if (this.orderData.hasOwnProperty(i)) {
          recommendArray.push(this.orderData[i]);
        }
      }
      this.productCommend = recommendArray;
    });
  }
// 获取验证信息
  getQuoteId(quoteId: string): Promise<any> {
    const Url = apiConfig.cloudCart;
    let quoteIdData;
    let quote:any;
    if (this.init.isLogin() && (quoteId === undefined || quoteId == null)) {
      const param:any = {
        'customerId': this.localStorage.getItem('cloud_customer_id'),
        'merchantId': this.init.merchantId,
        'orderSource': this.init.orderSource,
        'storeId': this.init.cloudStoreId.toString()
      };
      return new Promise((resolve1, _reject) => {
        this.request.post(Url + '/sales_cart/exists', param, false , false, true).toPromise().then((res:any) => {
          if (res['code'] === 1000) { // 用户存在cartkey
            quoteIdData = res['data'];
            quote = this.localStorage.setItem('quote_id', quoteIdData );
            resolve1(quote);
          } else {
            this.request.post(Url + '/sales_cart', param, false , false, true).toPromise().then((cartRes:any) => {
              if (cartRes['code'] === 1000) { // 用户存在cartkey
                quote = quoteIdData = cartRes['data']['cartKey'];
              }
              quote = this.localStorage.setItem('quote_id', quote );
              resolve1(quote);
            });
          }
        }).catch(error => error);
      });
    } else {
      quoteIdData = this.localStorage.getItem('quote_id');
      quote = quoteIdData;
      const pormise = new Promise((resolve1, _reject) => {
        resolve1(quote);
      });
      return pormise;
    }
  }

  /**
   *  合并购物车
   */
  mergeCart() {
    const Url = apiConfig.cloudCart;
    const param:any = {
      'customerId': this.localStorage.getItem('cloud_customer_id'),
      'merchantId': this.init.merchantId,
      'orderSource': this.init.orderSource,
      'storeId': this.init.cloudStoreId.toString(),
      'noLoginCartKey': this.localStorage.getItem('quote_id'),
    };
    let quoteIdData;
    let quote:any;
    return new Promise((resolve1, _reject) => {
      this.request.post(Url + '/sales_cart/login_cart', param, false , false, true).toPromise().then((res:any) => {
        if (res['code'] === 1000) { // 用户存在cartkey
          quoteIdData = res['data']['cartKey'];
          quote = this.localStorage.setItem('quote_id', quoteIdData );
        }
        resolve1(quote);
      }).catch(error => error);
    });
  }
  /**
   *
   * 使用循环的方式判断一个元素是否存在于一个数组中
   * @param {Object} arr 数组
   * @param {Object} value 元素值
   */
  public isInArray(arr:any, value:any) {
    for (let i = 0; i < arr.length; i++) {
      if (value === arr[i]) {
        return true;
      }
    }
    return false;
  }
  // public getCartModel(): Promise<any> {
  //   if (this.cartModel.quote_id.length > 0) {
  //     return new Promise((resolve, reject) => {
  //       resolve(this.cartModel);
  //     });
  //   } else {
  //     return this.getCartData();
  //   }
  // }

  public checkedItem(isCheck:any, sku_id:any) {
    this.QuoteId();
    const addExchangeUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/item/checked?is_checked=' + isCheck +  '&sku_id=' + sku_id;
    return new Promise((resolve1, _reject) => {
      this.request.put(addExchangeUrl, null).toPromise().then(res => {resolve1(res); }).catch(error => error);
    });
  }
  public checkedAllItem(isCheck:any) {
    this.QuoteId();
    const addExchangeUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/item/checked/all?is_checked=' + isCheck;
    return new Promise((resolve1, _reject) => {
      this.request.put(addExchangeUrl, null).toPromise().then(res => {resolve1(res); }).catch(error => error);
    });
  }

  /**
   * 设置UTM（Customer调用）
   * @param params
   */
  public setUtm(params:any) {
    this.QuoteId();
    const url = apiConfig.cloudCart + '/customer/sales_cart/' + this.quoteData + '/utm';
    return new Promise((resolve1, _reject) => {
      this.request.put(url, JSON.stringify(params), true).toPromise().then(res => {resolve1(res); }).catch(error => error);
    });
  }

  //预售结算
  public presaleCheckout(skuid: string) {
    let result;
    const url = apiConfig.cloudCart + '/customer/sales_cart/checkout/presale';
    const paramsJson:any = {'customerId': this.init.userId, 'merchantId':this.init.merchantId ,'storeId': this.init.cloudStoreId, 'orderSource': 'mall', 'qty':1,'skuId':skuid};
    return new Promise((resolve, _reject) => {
      this.request.post(url, paramsJson, false, false, true).toPromise().then(
        res => {
          result = res;
          resolve(result);
        }).catch(error => error);
    });
  }
}
