<div>
  <div>
    <div class="box">
      <div>
        <p class="box-p1">“COS by you”条款与细则</p>
        <p class="box-p">用户名：{{realName}}</p>
        <p class="">昵称：{{nickName}}</p>
        <p class="box-p">您通过在我们的品牌渠道（包括但不限于微博、微信、小红书、天猫）上使用#COS by you#或#COS#或@COS官方微博，同时上传您的COS产品穿搭照片参与我们的“COS by you”活动，代表您同意以下条款和条件：</p>
        <p class="box-p">就您参与“COS by you”活动所上传的相关照片和视频（以下称为“照片“）所涉及的版权和肖像权，您授予海恩斯莫里斯（上海）商业有限公司（注册地址:中国上海市黄浦区淮海中路645号-659号B1-4F，统一社会信用代码：91310000794519276H）（以下称为“COS”）非排他性的、免许可费的、全球性的、无限期的许可，即许可COS将照片用于其营销和/或广告宣传之目的，包括但不限于用于cosstore.cn网站、COS天猫旗舰店、COS官方微信小程序店、电子邮件、COS官方社交媒体帐号（包括但不限于微博、微信和小红书）、店铺材料和其他顾客沟通。COS有权仅为上述目的在合理限度内使用、复制、传播、与其他材料结合、修改和/或编辑您的照片。</p>
        <p class="box-p">您在此陈述并保证：</p>
        <p class="box-p">1. 您拥有与您的照片及其中的肖像有关的所有权利，</p>
        <p class="box-p">2. 您从出现在您的照片中的人士处获得了将本条款和条件中的权利授予COS的许可，</p>
        <p class="box-p">3. 尽您所知， COS使用您的照片不会侵犯任何第三方的权利，也不会违反任何法律。就COS使用您的照片，以及其中包含的、和上述使用有关的知识产权和肖像权，您在此免除COS向您支付任何费用的义务。您在此免除COS及其代表，并同意保护COS及其代表就照片的上述使用免受任何索赔、主张和责任。</p>
        <p class="box-p">若您对COS by you活动有任何疑问，或者您希望撤回您的授权并移除您的照片，请通过customer-service-cn@cosstores.com联系我们的顾客服务部门。</p>
        <p class="box-p">关于COS如何处理您的个人数据，请阅读以下<a href="https://www.cos.cn/privacy-policy" class="a-link">隐私政策</a>。</p>
      </div>
      <div class="box-t">
        <p>已选择照片</p>
        <div class="img">
          <div  style=" width: 100%;"  *ngFor="let item of details">
            <div class="mediaBox">
              <img *ngIf="item.resourceType == 'image'" alt="COS" src="{{item.resourceUrl}}" style="width:100%;height: 124px;" (click)="viewBigImage(item.resourceUrl)"/>
              <video *ngIf="item.resourceType == 'video'" webkit-playsinline="true" playsinline="true" x5-video-player-fullscreen="false" x5-video-player-type="h5" controls muted="true" style="width:100%;height:124px;object-fit:fill" src="{{item.resourceUrl}}"></video>
            </div>
          </div>
        </div>
        <div class="button">
          <button class="button-11" *ngIf="authorizedStatus == 'unauthorized'" (click)="doAuthorized()">已阅读相关条款，并同意使用以上信息</button>
          <button class="button-2" *ngIf="authorizedStatus == 'authorized'">已授权</button>
        </div>
      </div>
    </div>

    <div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': showBigImage}"
         data-component="AOverlay" (click)="hideBigImage()" id="productAOverlay"></div>
    <div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="showBigImage" style="text-align: center">
      <div class="lightbox-header"></div>
      <div class="lightbox-content js-content u-clearfix">
        <!-- 显示加入购物车报错 开始 -->
        <div class="m-change-country-lightbox false">
          <img alt="COS" src="{{bigImageUrl}}" style="width: 100%;height: 100%;overflow: hidden;"/>
        </div>
        <!-- 显示加入购物车报错 结束 -->
        <div class="m-button-icon a-icon-close js-close-button" (click)="hideBigImage()"></div>
      </div>
    </div>
  </div>
</div>
