import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {CustomerModel} from '../models/customer.model';
import {StorageService} from '../services/storage.service';

const apiConfig = require('../config/config.api.json');


@Injectable()
export class CustomerService {
  constructor(private init: InitializeService, private request: RequestService,private localStorage: StorageService) {
  }
  public customerInfo:any;
  public customerYmd:any;
  public orderAmount:any;


  /**
   * 获取用户
   * @param {number} id
   * @returns {Promise}
   */
  getCustomer(): Promise<any> {
    return new Promise((resolve) => {
      this.request.list(apiConfig.customerApi + '?action=info').toPromise().then(
        (res:any) => {
          const customer = new CustomerModel(res['message']);
          resolve(customer);
        }
      ).catch(error => error);
    });
  }

  /*
   * 获取用户账户信息
   *  param
   *  return Promise
   * */
  public getCustomerInfo(): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.customerApi + '?action=info').toPromise().then(
        (res:any) => {
          this.customerInfo = res['data']['user_info'];
          resolve(this.customerInfo);
        }
      ).catch(error => error);
    });
  }

  getCrmMemberInfo():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.crmMemberBasic+'customer/member').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);

    })
  }

  async editCustomerEmail(customerId:any,params:any):Promise<any>{
    try {
		  return await new Promise((resolve) => {
			  this.request.put(apiConfig.cloudCustomerBasic + 'customer/customer/' + customerId + '/email', params, true).toPromise().then(
				  res => {
					  resolve(res);
				  }
			  ).catch(error => error);
		  });
	  } catch (error_1) {
		  return error_1;
	  }
  }

  async customerData():Promise<any>{
      return new Promise((resolve) => {
          this.request.get(apiConfig.cloudCustomerBasic+'customer/customer?merchant_id='+this.init.merchantId+'&store_id='+this.init.officialWebsiteStoreId).toPromise().then(
            res => {
              resolve(res);
            }
          )
      }).catch(error => error);
  }

  async bindCard(customerId:any,params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.put(apiConfig.cloudCustomerBasic+'customer/customer/'+customerId+'/bind_card',params,true).toPromise().then(
        res => {
          resolve(res);
        }
      )
    }).catch(error => error);
  }

  /**
   * 取交易金额
   * @returns {any}
     */
  getTransactionAmounts():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.crmTransactionBasic+'customer/transaction/amounts?period=LAST_365_DAYS').toPromise().then(
        res => {
          resolve(res);
    }).catch(error => error);
    })
  }

  getSystermCoupon():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.crmCouponBasic+'customer/coupon/by_rule_ids?coupon_rule_ids=3,4').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  getCrmCoupon():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.crmCouponBasic+'customer/coupon').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  /**
   * 登录
   * @param params
   * @returns {Promise<T>}
     */
  login(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudCustomerBasic + 'customer/login/v2', JSON.stringify(params),false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  /**
   * 手机号是否已注册
   * @param mobile
   * @returns {Promise<T>}
     */
  checkMobile(mobile:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudCustomerBasic + 'customer/check_mobile?merchant_id='+this.init.merchantId+'&mobile='+mobile,false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  /**
   * 获取图形验证码key
   * @returns {Promise<T>}
     */
  imageCaptchaKey():Promise<any>{
    return new Promise((resolve) => {
      var timestamp = new Date().getTime();
      this.request.get(apiConfig.cloudImageCaptcha + 'image_captcha/captcha_key?timestamp='+timestamp).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  /**
   * 获取图形验证码图片
   * @param captcha_key
   * @returns {string}
     */
  imageCaptcha(captcha_key:any): string{
    return apiConfig.cloudImageCaptcha + 'image_captcha?captcha_key='+captcha_key;
  }

  checkImageCaptcha(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudImageCaptcha + 'image_captcha',params,false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  sendVerifyCode(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.smsMaoXin + 'internal/send',params,false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  sendEmailVerifyCode(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.mailService + 'internal/mail/verify_code/send',params,false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  validateVerifyCode(params:any):Promise<any>{
  return new Promise((resolve) => {
    this.request.post(apiConfig.smsMaoXin + 'internal/verify_code/validate',params,false,false,true).toPromise().then(
      res => {
        resolve(res);
      }
    ).catch(error => error);
  })
}

  validateEmailVerifyCode(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.mailService + 'internal/mail/verify_code/validate',params,false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  register(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudCustomerBasic + 'customer/register',params,false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  wxBindMobile(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudCustomerBasic + 'customer/customer/binding_weixin',params,false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  forgotPassword(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.put(apiConfig.cloudCustomerBasic + 'customer/forget_password',params,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  resetPassword(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.put(apiConfig.cloudCustomerBasic + 'customer/customer/v2/password',params,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  /**
   * 同步用户
   * @param params
   * @returns {Promise<T>}
     */
  syncCustomer(params:any){
      return new Promise( (resolve) => {
        const createUrl = apiConfig.cloudCustomerBasic + 'customer/create';
        this.request.post(createUrl, params).toPromise().then(
          res => {
            resolve(res);
          }
        ).catch(error => error);
      });
  }

  /**
   * 积分概况
   * @returns {Promise<T>}
     */
  getCrmPointsOverview():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.crmPointsBasic+'customer/points/balance').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);

    })
  }

  /**
   * 积分明细
   * @returns {Promise<T>}
   */
  getCrmPointsTransaction(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.crmPointsBasic+'customer/points/transaction'+params).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);

    })
  }

  /**
   * 积分类型
   * @returns {Promise<T>}
   */
  getCrmPointsTransactionTypes():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.crmPointsBasic+'customer/points/transaction/types').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);

    })
  }

  /**
   * 补充生日信息
   * @param customerId
   * @param params
   * @returns {any}
     */
  async updateBirthday(customerId:any,params:any):Promise<any>{
    try {
		  return await new Promise((resolve) => {
			  this.request.put(apiConfig.cloudCustomerBasic + 'customer/customer/update_birthday', params, true).toPromise().then(
				  res => {
					  resolve(res);
				  }
			  );
		  });
	  } catch (error) {
		  return error;
	  }
  }

  /**
   * 登录，退出日志
   * @param params
   * @returns {Promise<T>}
   */
  customerLoginLog(params:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudCustomerBasic + 'customer/customer_log/insert',params).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  clientIpService():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.clientIpService).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);

    })
  }

  /**
   * 生成小程序短链接
   * @param params
   * @param appId
   * @returns {Promise<T>}
   */
  schemeGenerate(params:any,appId:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.post(apiConfig.wxScheme + 'wx/scheme/generate/'+appId, JSON.stringify(params),false,false,true).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

	/**
	 * 查询用户是否同意隐私政策
	 */
	agreementState():Promise<any>{
		return new Promise((resolve) => {
		  this.request.get(apiConfig.crmMemberBasic + 'customer/member/agreement?name=privacy&version=20211109').toPromise().then(
			res => {
			  resolve(res);
			}
		  ).catch(error => error);
		})
	}

	/**
	 * 操作隐私协议
	 * "isAgreed": "Yes",
	 * "isAgreed": "No",
	 * "{ \"isAgreed\": \"Yes\", \"name\": \"string\", \"version\": \"string\"}"
	 */
	operateAgreement(params:any):Promise<any>{
		return new Promise((resolve) => {
		  this.request.put(apiConfig.crmMemberBasic + 'customer/member/agreement',params,true).toPromise().then(
			res => {
			  resolve(res);
			}
		  ).catch(error => error);
		})
	}

	// 员工组用户登录后刷下用户组别
	async refreshCustomerGroup(params:any):Promise<any>{
	  try {
			return await new Promise((resolve) => {
				this.request.post(apiConfig.cloudCustomerBasic + 'customer/customer/refresh_staff', params, false).toPromise().then(
					res => {
						resolve(res);
					}
				);
			});
		} catch (error) {
			return error;
		}
	}
}
