import {Component, Inject, OnInit, Optional} from '@angular/core';
import {RESPONSE} from '@nguniversal/express-engine/tokens';
import {InitializeService} from '../../services/initialize.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-systerm-exception',
  templateUrl: './systerm-exception.component.html',
  styleUrls: ['./systerm-exception.component.css']
})
export class SystermExceptionComponent implements OnInit {

  constructor(
    @Optional() @Inject(RESPONSE) private response: any,
    private router: Router,
    private init: InitializeService
  ) {
  }

  ngOnInit() {
    this.init.setTitle('结算');
    if (this.response) {
      this.response.statusCode = 404;
    }
  }

  goCart(){
    this.router.navigate(['/cart']);
  }
}
