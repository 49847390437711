import {Component,Input, Output, OnInit, Inject, PLATFORM_ID, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {ProductService} from '../../../services/product.service';
import {InitializeService} from '../../../services/initialize.service';
import {StorageService} from '../../../services/storage.service';
import {VmService} from '../../../services/vm.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {FavoriteService} from '../../../services/favorite.service';
import {ProductModel} from '../../../models/product.model';
import {CartService} from '../../../services/cart.service';
import {CallComponentService} from '../../../services/callcomponent.service';
import {CartModel} from '../../../models/cart.model';
import {isPlatformBrowser} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {TrackingcodeService} from '../../../services/trackingcode.service';
import {AbTrackingService} from '../../../services/abtracking.service';


import $ from 'jquery';

import {
  SwiperConfigInterface,
  SwiperNavigationInterface
} from 'ngx-swiper-wrapper';
 
const navigationConfig: SwiperNavigationInterface = {
  nextEl: '.swiper-next',
  prevEl: '.swiper-prev',
  hideOnClick: true,
};
@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(1000, style({opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(1000, style({opacity: 0}))
      ])
    ])
  ],
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  // 商品id
  public productId: any;
  public quickPruchaseModel!: ProductModel;
  public tempProductModel!: ProductModel;
  // 图片集合
  @Input() productModel: any;
  @Input() categoryModel: any;
  @Input()
	qty!: string;
  @Input()
	categoryUrl!: string;
  @Input() favoriteItemIds = [];
  @Output() toProductDetail: EventEmitter<number> = new EventEmitter();
 
  @Input() itemIndex :any;
  @Input() searchPage: any;

  public imageBlock = [];
  public showHoverProductId!: string;
  public isPcFlag!: boolean;
  public isVmLogin = 0;
  public offset = -100;
  public ccImages = [];
  public panoramaImage: any;
  public defaultImagePath: any;
  public out_stock_rate: any;
  public show_promotional_label: any;
  public double_eleven_presale_label: any;
  public double_eleven_presale_active = false;
  public show_promotional_label_active = false;

  public valueId: any;
  url_path = '';
  is_new = false;
  is_exclusively = false;
  promotionTag = ''; // 促销标签
  promotionTagList: any = []; // 促销标签列表
  forwardPurchaseTagList: any = [];
  customPromotionTagList: any = []; // 自定义促销标签列表
  public colorQty = [];
  public showForwardPurchase:any = false;

  public cloudSalePrice!: string; // 促销价
  public cloudPrice!: string; // 吊牌价
  public frontMaterialTag!: string; // 材质标签
  public isNewArrivalCategory:any = false; // 是否为新品分类
  frontProperties:any = [];
  specialTagStart:any = '2020-05-07 00:00:00';
  specialTagEnd:any = '2020-05-15 00:00:00';
  tagListStyle:any = 'tagList';
  tagColor:any = '#080808';
  customTagColor:any = '#080808';
  public productDetail: any = {code: 1, data: '', msg: ''};
  public productColors: any;
  // size集合
  public productSizeArray:any = [];
  public quoteId!: string;
  // 返回的添加购物车信息
  public shoppingData:any = {code: 1, data: '', msg: ''};

  // 添加到购物车显示数据
  public cartInfo:any = {};

  public cartResult!: CartModel;
  public itemColorValue!: '';
  public show_error_box:boolean = false;
  public error_message:any;
  public floatSizeLength:any;
  public timer:any = null;
  public itemsArr:any = [];

  public favoriteList:any = {code: 1, data: '', msg: ''};
  public childFavoriteItemIds:any = [];
  public showCarousel:any = false; // 是否显示轮播
  public rulePriceJson:any = {price:'',rulesArr:[]};//策略价+预热价
  public rulesArr:any = [];
  public show_favorite_modal:boolean = false;
  //private keyboardSubscription: Subscription;

  // 商品状态
  public ccProductStatus: any;
  public currentSwiperIndex = 0;
  public favoriteData:any = {code: 1, data: '', msg: ''};
  
  public relationLimitSkuIds:any;
  public limitedPurchaseQty = -2;//自定义赋值，表示未调用限购数量查询接口
  public limitedPurchaseRes:any;
  public customerLimitQty:any = 0;//还可购买数据
  public favoriteIcon:boolean = false;
  // 货币
  public currency : any;
  constructor(public init: InitializeService,
              private storageService: StorageService,
              public productService: ProductService,
              private router: Router,
              private vmService: VmService,
              private favoriteService:FavoriteService,
              public  cartService: CartService,
              private tracking: TrackingcodeService,
              private abTracking: AbTrackingService,
              public  eventSubscribeService: CallComponentService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private localStorage: StorageService) {
  }
  config!: SwiperConfigInterface;
  ngOnInit() {
    //this.listenKeyboard(); // 注册键盘监听
    this.config = {
      speed: 1000,
      direction: 'horizontal',
      loop: true,
      keyboard: true,
      scrollbar: false,
      centeredSlides: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      autoHeight: true,
      roundLengths: true,
      navigation: navigationConfig,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
      //autoplay: {
      //  delay: 4500,
      //  disableOnInteraction: false,
      //},
    };
 
    this.cloudPrice = this.productModel['markingPrice']; // 吊牌价
    this.cloudSalePrice = this.productModel['price']; // 促销价
    this.itemColorValue = this.productModel['valueName'];
	this.currency = this.init.currency;
    this.promotionTagList = [];
    this.forwardPurchaseTagList = [];
    this.customPromotionTagList = [];
    if (this.productModel.promotionTagList != null && this.productModel.promotionTagList != '' && this.productModel.promotionTagList.length > 0) {
      this.productModel.promotionTagList.forEach((item:any) => {
		  //促销标签有效时间判断
			if(item['beginTime'] !== null && item['endTime'] !== null && item['beginTime'] !== '' && item['endTime'] !== '' ){
				  if(this.productService.promotionTime(item['beginTime'],item['endTime'])){
						if (item['promotionType'] != 'custom_tag') {
						    if(this.checkCustomerPromotionTag(item['groupIds'])){
                                if(item['promotionType'] == 'forward_purchase'){
                                    if(this.checkForwardPurchaseLabel(item.memberLevelIds)){
                                      this.forwardPurchaseTagList.push(item);
                                    }
                                } else {
                                    this.promotionTagList.push(item);
                                }
                            }
						} else if (item['promotionType'] == 'custom_tag') {
						  this.customPromotionTagList.push(item);
						}
					}
			}
      });
      if (this.customPromotionTagList.length > 0) {
        this.customPromotionTagList.sort(function(a:any, b:any) {
          return a['sortOrder'] - b['sortOrder'];
        });
      }
      // this.promotionTagList = this.productModel.promotionTagList;
    }
    this.url_path = this.productModel.frontUrlPath;
    this.valueId = this.productModel['valueId'];

    this.isNewArrivalCategory = this.searchPage ? false : (this.categoryModel.newArrival == 1 ? true : false);
    this.showCarousel = this.searchPage ? false : (this.categoryModel.showCarousel == 1 ? true : false);

    if (this.productModel['frontProperties'] != '' && this.productModel['frontProperties'] != null) {
      this.frontProperties = this.productModel['frontProperties'];
    }



    this.goodsDetail(this.productModel['itemId'], this.productModel['valueId'], this.productModel['frontCode']);

    if (this.favoriteItemIds) {
		this.childFavoriteItemIds = this.favoriteItemIds;
    }

    // 设置特殊时间段促销标签样式
    const specialTagStartTime = Date.parse(this.specialTagStart.replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
    const specialTagEndTime = Date.parse(this.specialTagEnd.replace(/-/g, '/'));
    const specialTimestamp = (new Date()).getTime();
    if (specialTimestamp >= specialTagStartTime && specialTimestamp < specialTagEndTime) {
      this.tagListStyle = 'specialTagList';
      this.tagColor = '#99b8ae';
    }

    this.checkIsNew(this.productModel);
    this.checkIsExclusively(this.productModel);
    this.getMaterialTag(this.productModel);
    // this.inFavorites(this.productModel);
    if (this.productModel['strategies'] && this.productModel['strategies'] != null && this.productModel['strategies'].length > 0) {

		this.tempProductModel = new ProductModel(this.productModel);
		//筛选有效期内的策略价标签
		this.rulePriceJson = this.productService.strategyTag(this.productModel['strategies'])
		//显示划线价
		if(this.rulePriceJson.price !== 0 && this.rulePriceJson.hasRule) {
			this.cloudSalePrice = this.rulePriceJson.price
		}
    }
    if (this.isPC() && this.isVm()) {
      this.isPcFlag = true;
    } else {
      this.isPcFlag = false;
    }
    this.doubleElevenPresaleRange();
    this.panoramaImage = this.productModel.hoverUrl;

    this.defaultImagePath = this.productModel.majorUrl;
    // 如果是配饰分类，并且两张图片都有，则互换两张图片 ,搜索结果页不做处理
	if(!this.searchPage){
		if (!!this.categoryModel.isSpecial && this.categoryModel.hasOwnProperty('isSpecial') && this.categoryModel.isSpecial.toString() === '1' && this.productModel.hoverUrl) {
		  const transition = this.panoramaImage;
		  this.panoramaImage = this.defaultImagePath;
		  this.defaultImagePath = transition;
		}
	}

  }

  //private listenKeyboard() {
  //  this.keyboardSubscription && this.keyboardSubscription.unsubscribe();
  //  this.keyboardSubscription = fromEvent(window, 'keydown').subscribe((event: any) => {
  //    console.log(event.key);
  //  });
  //}

  doubleElevenPresaleRange() {
    const now = this.getRangeDate(0);
    if (now > '2019-11-07 23:59:58' && now < '2019-11-10 22:00:00') {
      this.double_eleven_presale_active = true;
    }

    if (now > '2019-11-07 00:00:00' && now < '2019-11-10 22:00:00') {
      this.show_promotional_label_active = true;
    }
  }

  getRangeDate( range: number) {
    const formatDate = ( time: any ) => {
      // 格式化日期，获取今天的日期
      const Dates = new Date( time );
      const year: number = Dates.getFullYear();
      const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
      const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
      const hour: any = Dates.getHours();       // 获取当前小时数(0-23)
      const minutes: any = Dates.getMinutes();     // 获取当前分钟数(0-59)
      const seconds: any = Dates.getSeconds();
      return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
    };

    let changeDate: string;
    if (range > 0 ) {
      changeDate = formatDate( new Date().getTime() - ( 1000 * 3600 * 24 * 30 * range ) );
    } else {
      changeDate = formatDate( new Date().getTime());
    }
    return changeDate;
  }
  /**
   * 客户端判断 PC or Mobile
   * @returns {boolean}
   * @constructor
   */
  isPC(): boolean {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  isVm() {
    return this.storageService.getItem('vmLogin') ? false : true;
  }

  vmImageClick(id: string, image: any, type: number, number: number) {
    // console.log( ' =====  click ======== ' + id + ' - '  +  image + ' - ' + type + ' - ' + number )
    const thumbnails = document.querySelectorAll('#listing_thumbnail_' + id + ' a');
    let flag = 1;
    for (const tmp in thumbnails) {
      if (thumbnails[tmp].className !== undefined) {
        if ((type === 1) && (thumbnails[tmp].className.indexOf('img_1') === 0)) {
          thumbnails[tmp].className = 'img_1';
        }
        if ((type === 2) && (thumbnails[tmp].className.indexOf('img_2') === 0)) {
          thumbnails[tmp].className = 'img_2';
        }
      }
    }
    for (const tmp in thumbnails) {
      if (flag === (2 * number + type)) {
        thumbnails[tmp].className += ' act';
      }
      flag++;
    }
    const entity:any = {'id': id};
    let message_type = '';
    if (type === 1) {
      entity['img_0'] = image;
      message_type = 'img_0';
    } else {
      entity['img_1'] = image;
      message_type = 'img_1';
    }
    this.vmService.setProductImagesList(entity, message_type);
    return false;
  }

  imageMouseover(showHoverProductId: string) {
    this.showHoverProductId = showHoverProductId;
	this.favoriteIcon = true;
  }

  imageMouseout() {
    this.showHoverProductId = '';
	this.favoriteIcon = false;
  }

  toPdp() {
	if (this.init.gaTrackingFlag) {
		this.gaTrackingProductClick();
	}

    this.toProductDetail.emit();
  }

  //加入收藏
  addFavorite(itemCode: any,itemId: string,valueId: string){
     if(!this.init.isLogin()){
		this.eventSubscribeService.loginRegisterBox.next('1');
     }else{
       this.favoriteService.addItemFavorite(itemCode,itemId,valueId).then( val=>{
			 this.favoriteData = val;
			 if(this.favoriteData.code == -1002){
				this.show_favorite_modal = true;
				this.error_message = this.favoriteData.msg;
			 }else{
				var result:any = {'code':this.favoriteData.code,'type':'add'};
				this.eventSubscribeService.changeFavoriteBox.next(result);

				this.resetFavoritesService();
				this.abTracking.trackingAction('ADD_FAVORITE', itemId, { valueId: valueId, fontCode: itemCode, path: this.router.url});
				$('#'+itemId+'_'+valueId+'_after_favorite').css('display','block');
				this.favoriteItems();
				if (this.init.gaTrackingFlag) {
					this.gaTrackingFavorite(itemId,valueId,'save','add_to_favourites');
				}

			}
       })
     }
  }

  //删除收藏
  delFavoriteItem(itemId: any,valueId: any){
    let favoriteId = this.childFavoriteItemIds[itemId+'_'+valueId];
    if(favoriteId){
      const params = new HttpParams()
        .set('favorites_id',favoriteId);
      this.favoriteService.delFavoriteItem(favoriteId,params).then( val=>{
        if(val.code === 1000){
			var result:any = {'code':val.code,'type':'remove'};
			this.eventSubscribeService.changeFavoriteBox.next(result);
          $('#'+itemId+'_'+valueId+'_after_favorite').css('display','none');
          this.resetFavoritesService();
          this.favoriteItems();
		  if (this.init.gaTrackingFlag) {
			  this.gaTrackingFavorite(itemId,valueId,'unsave','remove_from_favourites');
		  }

        }
      })
    }
  }
  gaTrackingProductClick(){
		var itemsArray = [];

		var categoryUrl = this.tracking.currentPagePath;
		var itemLinkArr = categoryUrl.split('.');
		var product_list_name = itemLinkArr[0].substr(1).replaceAll('/','_');

		var frontUrlPath = this.productModel.frontUrlPath;
	    var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
	    var itemObject = {
	    		product_action: 'click',
	    		product_id: this.productModel.itemCode,//款号
	    		product_article_id: this.productModel.frontCode,//CC
	    		product_name: this.productModel.itemName,
	    		product_brand: 'COS',
	    		product_category:product_category,
	    		product_color:this.productModel.valueName,
	    		product_price:this.productModel.price,
	    		product_price_original:this.productModel.markingPrice,
	    		product_price_type: this.productModel.price < this.productModel.markingPrice ? 'sale' : 'full price',
	    		product_list_name: product_list_name,
	    		product_list_position:Number(this.itemIndex) + 1,
	    	}
			/* * Note: The product size is not available in product lists since the user has not selected a size. The size and
			variation variables can therefore be omitted from the object or sent as empty */
	  	itemsArray.push(itemObject);
		this.tracking.gaTrackingProductClick(itemsArray);
  }

  gaTrackingFavorite(itemId: string,valueId: string,action="",eventType=""){
		var itemsArray = [];
		var frontUrlPath = this.productModel.frontUrlPath;
	    var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
		var productSizeArray =  this.productSizeArray[itemId+'-'+valueId];

		var sizeInfo = productSizeArray[0];
	    var itemObject = {
	    		product_action: action,
	    		product_id: this.productModel.itemCode,//款号
	    		product_article_id: this.productModel.frontCode,//CC
	    		product_variant_id: sizeInfo.skuCode,//SKU
	    		product_name: this.productModel.itemName,
	    		product_brand: 'COS',
	    		product_category:product_category,
	    		product_color:this.productModel.valueName,
	    		product_price:this.productModel.price,
	    		product_price_original:this.productModel.markingPrice,
	    		product_price_type: this.productModel.price < this.productModel.markingPrice ? 'sale' : 'full price',
	    		product_quantity: 1,
	    		product_size: sizeInfo.valueName,
	    		product_size_code: sizeInfo.valueId,
	    	}
	  	itemsArray.push(itemObject);
		this.tracking.gaTrackingManageFavourites(itemsArray,eventType);
  }

  favoriteItems() {
    this.favoriteService.getFavoriteIds().then(val=> {
      this.favoriteList = val;
      if(this.favoriteList.code == 1000){
		this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage);
        if(this.favoriteList.data.length > 0){
          this.favoriteList.data.forEach((item:any) => {
            this.childFavoriteItemIds[item.itemId + '_' + item.valueId] = item.favoritesId;
          });
        }
      }
    });
  }


  resetFavoritesService(){
    this.favoriteService.clearFavoriteResult();
    this.childFavoriteItemIds = [];
  }
  /**
   * 校验是否为新品
   */
  public checkIsNew(item:any) {
    if (item['newEndTime'] != '' && item['newStartTime'] != '' && item['newEndTime'] != null && item['newStartTime'] != null) {
      const endTimestamp = Date.parse(item['newEndTime'].replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
      const startTimestamp = Date.parse(item['newStartTime'].replace(/-/g, '/'));
      const timestamp = (new Date()).getTime();
      if (timestamp >= startTimestamp && timestamp < endTimestamp) {
        this.is_new = true;
      } else {
        this.is_new = false;
      }
    }
  }

  /**
   * 校验是否线上专供
   */
  public checkIsExclusively(item:any) {
    this.is_exclusively = false;
    if (item['frontTag'] != '' && item['frontTag'] != null) {
      for (let v = 0; v < item['frontTag'].length; v++) {
        if (item['frontTag'][v] === 'ONLINE EXCLUSIVE') {
          this.is_exclusively = true;
          break;
        }
      }
    }
  }

  /**
   * 获取材质标签
   */
  public getMaterialTag(item:any) {
    if (item['frontMaterialTag'] != '' && item['frontMaterialTag'] != null) {
      this.frontMaterialTag = item['frontMaterialTag'];
    }
  }


  public checkForwardPurchaseLabel(memberLevels: number[]){
    if(memberLevels){
      var currentMemberLevelId = parseInt(this.localStorage.getItem('memberLevelId'),0);
      var memberIsActive = this.localStorage.getItem('memberIsActive');
      if(currentMemberLevelId && memberLevels.indexOf(currentMemberLevelId) !== -1 && memberIsActive === 'Active'){
        return true;
      }else{
        return false;
      }
    }
    return true;
  }

  public checkCustomerPromotionTag(customerGroupIds: number[]){
    if(customerGroupIds){
      var customerGroupId = parseInt(this.localStorage.getItem('groupId'),0);
      if(customerGroupIds && customerGroupIds.indexOf(customerGroupId) !== -1){
        return true;
      }else{
        return false;
      }
    }
    return true;
  }


  public quickPurchase(productModel:any){
    //$('.detail-container').css('display','none');
    if(!this.init.isLogin()){
      this.eventSubscribeService.loginRegisterBox.next('1');
      return;
    }else{
      this.goodsDetail(productModel.itemId,productModel.valueId,productModel.frontCode);
      $('#floatSizeTab_'+productModel.itemId+'_'+productModel.valueId+'_'+productModel.frontCode).show();
        if (isPlatformBrowser(this.platformId)) {
          this.timer = setTimeout(function () {
            $('#floatSizeTab_'+productModel.itemId+'_'+productModel.valueId+'_'+productModel.frontCode).hide();
          }, 3000);
        }
    }
  }


  public goodsDetail(itemId:any,valueId:any,_frontCode:any){
    this.productService.getProductByItemId(itemId).then( val=> {
      this.productDetail = val;
      let tempSizeArr: any[] = [];
      if (parseInt(this.productDetail.code, 0) === 1000) {	  
        this.quickPruchaseModel = new ProductModel(this.productDetail.data);

		//查询限购关联skus
		this.productService.customParameters(this.quickPruchaseModel.frontProperties,valueId);
		
		if(!!this.productService.frontCustomParameters){
			let frontCustomParameters = JSON.parse(this.productService.frontCustomParameters);
			if(!!frontCustomParameters.relationLimitSkuIds){
				this.relationLimitSkuIds = frontCustomParameters.relationLimitSkuIds;
			}
		}
 
        // 获取颜色
        this.productColors = this.quickPruchaseModel.getColorsCloud('');

        // 获取尺码品方要求不过滤无库存sku 无库存尺码后面加 已售罄
        if(this.quickPruchaseModel.sku_list[valueId] !== undefined){
          this.quickPruchaseModel.sku_list[valueId].forEach((sku_item:any) => {
            //if(sku_item.qty > 0){
            tempSizeArr.push(sku_item);
            //}
          });
        }

        this.productSizeArray[itemId+'-'+valueId]  = tempSizeArr;
        this.productSizeArray[itemId+'-'+valueId].sort(function(a:any, b:any) {
          return a['sortOrder'] - b['sortOrder'];
        });
 
        this.floatSizeLength = this.productSizeArray[itemId+'-'+valueId].length + 1;
		

		
      }
    })
  }

  mobileSelectSize(productModel:any,eValue:any){
    if(!this.init.isLogin()){
      this.eventSubscribeService.loginRegisterBox.next('1');
      return;
    }else{
      if(eValue !== '0' && eValue !== '选择尺码'){
        var paramsArr =  eValue.split('_');
        this.addShoppingCart(productModel,paramsArr[1],paramsArr[2],paramsArr[4],1);
        this.quickPurchase(productModel);
      }
      this.productSizeArray = [];
    }
  }


  // 添加购物车
  addShoppingCart(productModel:any,skuCode:any,skuId:any,sizeValueName:any,number:any) {
    let tempCloudPrice = productModel.markingPrice;//吊牌价
    let tempCloudSalePrice = productModel.price;//促销价
    if (productModel['strategies'] && productModel['strategies'] != null && productModel['strategies'].length > 0) {
      for (const i in productModel['strategies']) {
        const item = productModel['strategies'][i];
        if (item['strategyStartTime'] != '' && item['strategyStartTime'] != '' && item['strategyEndTime'] != null && item['strategyEndTime'] != null
          && item['strategyPrice'] != null && item['strategyPrice'] != '' && Number(item['strategyPrice']) > 0) {
          const endTimestamp = Date.parse(item['strategyEndTime'].replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
          const startTimestamp = Date.parse(item['strategyStartTime'].replace(/-/g, '/'));
          const timestamp = (new Date()).getTime();
          if (timestamp >= startTimestamp && timestamp < endTimestamp) {
            tempCloudSalePrice = item['strategyPrice'];
            break;
          }
        }
      }
    }

		
		this.productService.limitPurchase(skuId,this.relationLimitSkuIds).then(val=>{
			this.limitedPurchaseRes =  val;
			if(this.limitedPurchaseRes.code == 1000){
				 this.limitedPurchaseQty = this.limitedPurchaseRes.data.activityLimitQty;
				 this.customerLimitQty =  this.limitedPurchaseRes.data.customerLimitQty;
				 
			}
		});
		
    this.tracking.plpPurchase(skuCode+'-'+sizeValueName);

    //隐藏悬浮尺码
    $('#floatSizeTab_'+productModel.itemId+'_'+productModel.valueId+'_'+productModel.frontCode).hide();
    this.quoteId = this.localStorage.getItem('quote_id');
    //// TODO this.productId need modify;@skukun
    this.productService.getQuoteId(skuCode,skuId,this.quoteId,number,productModel.itemCode,productModel.itemName).then(model => {
      this.shoppingData = model;
      if (this.shoppingData.code === 1000) {
        this.cartService.getCartData(this.quoteId).then();
        this.cartInfo = {
          'name': productModel.itemName,
          'price': Number(tempCloudPrice) > Number(tempCloudSalePrice) ? tempCloudPrice : 0 ,
          'salesPrice': Number(tempCloudPrice) > Number(tempCloudSalePrice) ? tempCloudSalePrice : tempCloudPrice ,
          'img_url': productModel.majorUrl,
          'size': this.productService.sizeConvert(sizeValueName),
          'color': productModel.valueName
        };

        this.abTracking.trackingCart(productModel.itemId, productModel.itemName, tempCloudPrice, productModel.valueId, productModel.valueName, skuCode, productModel.panorama_image, {path: this.router.url});
		//新版Ga Tracking Js
		if (this.init.gaTrackingFlag) {
			this.gaTrackingAddCart(productModel,skuCode,productModel.valueName,sizeValueName,skuId);
		}
		
		this.eventSubscribeService.addMimiCartBox.emit(this.cartInfo);
      } else if(this.shoppingData.code === -1010){
			if(this.limitedPurchaseQty > 0){
				this.error_message = "该商品每月限购"+this.limitedPurchaseQty+"件";
			}else{
				this.error_message = "商品超出限购数量";
			}
			this.show_error_box = true;
		}else{
			this.show_error_box = true;
			this.error_message = this.shoppingData.msg;
		}
    });
  }

	gaTrackingAddCart(productModel:any,skuCode:any,ccValueName:any,sizeValueName:any,skuId:any){
		if (this.init.gaTrackingFlag) {
			var itemsArray = [];
			var frontUrlPath = productModel.frontUrlPath;
			  var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
			  var itemObject = {
			  		product_action: 'add',
			  		product_id: productModel.itemCode,//款号
			  		product_article_id: productModel.frontCode,//CC
			  		product_variant_id: skuCode,//SKU
			  		product_name: productModel.itemName,
			  		product_brand: 'COS',
			  		product_category:product_category,
			  		product_color:ccValueName,
			  		product_price:productModel.price,
			  		product_price_original:productModel.markingPrice,
			  		product_price_type: productModel.price < productModel.markingPrice ? 'sale' : 'full price',
			  		product_quantity: 1,
			  		product_size: sizeValueName,
			  		product_size_code: skuId,
			  	}
				itemsArray.push(itemObject);
				this.tracking.gaTrackingAddCart(itemsArray);
		}
	}
	
  // 关闭遮罩层和弹框
  closeOverlay() {
    this.show_error_box = false;
	this.show_favorite_modal = false;
  }

  public onIndexChange(index: number) {
    this.currentSwiperIndex = index;
  }


  public touchProductImage(e:any,frontCode:any){
    //tracking Carousel
    if($('#bullets-'+e).css('display') === 'none') {
      this.tracking.plpCarousel('Carousel-'+frontCode);
    }

    $('#bullets-'+e).removeClass('hide-bullets');
    $('#bullets-'+e).addClass('show-bullets');

    let tempProduct = this.storageService.getItem('previousProduct');
    if(tempProduct !== undefined && tempProduct !== e){
      $('#bullets-'+tempProduct).removeClass('show-bullets');
      $('#bullets-'+tempProduct).addClass('hide-bullets');
    }
    this.storageService.setItem('previousProduct',e);
  }
}
