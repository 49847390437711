import {Component, OnInit, PLATFORM_ID, Inject} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import {CustomerService} from '../../services/customer.service';
@Component({
  selector: 'app-account-points',
  templateUrl: './account-points.component.html',
  styleUrls: ['./account-points.component.css']
})
export class AccountPointsComponent implements OnInit{
  public pointsInfo = {expiringPoints:null,pointsBalance:'',expiringDate:null};
  public pointsTypes = [];
  public pointsList = [];
  public currentDate!: number;
  public loading:boolean = true;
  public obtainedTab:boolean = true;
  public usedTab:boolean = false;
  public currentPage = 0;
  public pageSize = 20;

  constructor(
    private init: InitializeService,
    private customerService: CustomerService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.init.pageConfiguration('我的积分');
    if (isPlatformBrowser(this.platformId)) {
      this.customerPointsInfo();
      this.customerPointsList(this.currentPage,this.pageSize);
      //this.customerPointsTypes();
      //this.getCurrentDate();
    }
  }

  convertTime(date:any){
    date = new Date(date);
    return date.valueOf();
  }

  changeTab(type:any){
    if(type == 'obtainedPoints'){
      this.obtainedTab = true;
      this.usedTab = false;
    }else{
      this.obtainedTab = false;
      this.usedTab = true;
    }
  }
  getCurrentDate(){
    //const formatDate = ( time: any ) => {
    //  // 格式化日期，获取今天的日期
    //  const Dates = new Date( time );
    //  const year: number = Dates.getFullYear();
    //  const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
    //  const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
    //  const hour: any = Dates.getHours();       //获取当前小时数(0-23)
    //  const minutes: any = Dates.getMinutes();     //获取当前分钟数(0-59)
    //  const seconds: any = Dates.getSeconds();
    //  return year + '-' + month + '-' + day + ' '+ hour + ':'+ minutes + ':' + seconds;
    //};
    this.currentDate =  new Date().getTime(); // 当前时间
  }

  customerPointsInfo(){
    this.customerService.getCrmPointsOverview().then(val =>{
      let response = val;
      this.loading = false;
      if(response.code === 1000){
        this.pointsInfo = response.data;
      }
    }).catch(error => error);
  }


  customerPointsList(currentPage:any,pageSize:any){
    const queryParams = '?page='+currentPage+'&size='+pageSize;
    this.customerService.getCrmPointsTransaction(queryParams).then(val =>{
      let response = val;
      this.loading = false;
      if(response.code === 1000){
        if(response.data.totalElements > 0){
          this.pointsList = response.data.content;
        }
      }
    }).catch(error => error);
  }
 

  ngOnDestroy() {
    this.loading = true;
  }
}
