import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cms-block-single-product-editor',
  template: `
    <div class="form-group">
      <label for="inputProductId">请填写商品ID</label>
      <input [(ngModel)]="block.data.product_id" type="text" class="form-control" id="inputProductId"
             placeholder="Product ID">
    </div>
    <div class="form-group">
      <label for="inputUrl">自定义链接（站内链接请只填写相对地址，如"/category/men.html"）</label>
      <input [(ngModel)]="block.data.url" type="text" class="form-control" id="inputUrl" placeholder="URL">
    </div>
    <div class="checkbox">
      <label>
        <input [(ngModel)]="block.data.isInternalUrl" type="checkbox">是否为站内链接？
      </label>
    </div>
  `,
  styles: []
})
export class CmsBlockSingleProductEditorComponent implements OnInit {

  @Input() block: any;

  constructor() {
  }

  ngOnInit() {
  }
}
