import {UrlSegment} from '@angular/router';

export function ProductUrlMatcher(url: UrlSegment[]): any {
  const reg = /product\..*?.(\.)?.([0-9]*).\.html$/;
  const param = url.join('/');
  if (param.match(reg)) {
    // const match = param.match(reg);
    const data = param.split('.');
    const id = new UrlSegment(data[data.length - 2], {});
    return ({consumed: url, posParams: {'id': id}});
  }
  return ;
}
