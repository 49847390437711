import {Component, Input, OnInit} from '@angular/core';
import {CategoryService} from '../../../../services/category.service';
import {InitializeService} from '../../../../services/initialize.service';
import {ProductService} from '../../../../services/product.service';

@Component({
  selector: 'app-cms-block-tab-lp',
  templateUrl: './cms-block-tab-lp.component.html',
  styleUrls: []
})
export class CmsBlockTabLpComponent implements OnInit {
  @Input() block: any;;
  @Input() containerIndex: any;
  @Input() blockIndex: any;
  @Input()
	editMode!: boolean;

  public productModel: any = [];
  public isPc = true;

  public currentIndex : number = 0;

  constructor(
    public init: InitializeService,
    public categoryService: CategoryService,
    public productService: ProductService) {
  }

  ngOnInit() {
    // 标签示例
    /*<a tabIndex="1" shape="rect" class="pushbox tabCategoryChange" id="new-accessories"   style="font-family: '','MHei PRC W05 Medium'">连衣裙 </a>

      <a tabIndex="2" shape="rect" class="pushbox tabCategoryChange" id="t-shirts"  style="font-family: 'Gill Sans MT Pro','MHei PRC W05 Medium'">衬衫 </a>

      <a tabIndex="3" shape="rect" class="pushbox tabCategoryChange" id="underwear" style="font-family: 'Gill Sans MT Pro','MHei PRC W05 Medium'" >T恤</a>

      <a tabIndex="4" shape="rect" class="pushbox tabCategoryChange" id="underwear" style="font-family: 'Gill Sans MT Pro','MHei PRC W05 Medium'" >裤装</a>*/

    if (!this.block.hasOwnProperty('data')) {
      this.block.data = {};
    }
    if (!this.block.data.hasOwnProperty('config')) {
      this.block.data.config = {};
    }
    if (!this.block.data.config.hasOwnProperty('tabs_html')) {
      this.block.data.config['tabs_html'] = '';
    }
    if (!this.block.data.hasOwnProperty('tabs')) {
      this.block.data.tabs = [
        {
          'type' : 'category',
          'cid': '',
          'count': '12',
          'inputItems': '',
          'showType': 'vertical',
          'width': '100',
          'is_customize': 0,  // 自定义html内容
          'html_content': '',
          'isBig': 0, // 首个宽度100%
          'isShowCollect': 0,
          'showMajorUrl': 1,
          'rows': 'threeLines'
        }
      ];
    } else {
      if (this.block.data.tabs.length > 0) {
        this.block.data.tabs.forEach((item:any) => {
          if (!item.hasOwnProperty('type')) {
            item['type'] = 'category';
          }
          if (!item.hasOwnProperty('showType')) {
            item['showType'] = 'vertical';
          }
          if (!item.hasOwnProperty('width')) {
            item['width'] = '100';
          }
          if (!item.hasOwnProperty('inputItems')) {
            item['inputItems'] = '';
          }
          if (!item.hasOwnProperty('is_customize')) {
            item['is_customize'] = 0;
          }
          if (!item.hasOwnProperty('html_content')) {
            item['is_customize'] = 0;
          }
          if (!item.hasOwnProperty('isBig')) {
            item['isBig'] = 0;
          }
          if (!item.hasOwnProperty('isShowCollect')) {
            item['isShowCollect'] = 0;
          }
          if (!item.hasOwnProperty('showMajorUrl')) {
            item['showMajorUrl'] = 1;
          }
          if (!item.hasOwnProperty('rows')) {
            item['rows'] = 'threeLines';
          }
        });
      }
    }
    this.refresh();
    this.isPc = this.init.IsPC();
  }

  refresh() {
    this.productModel = [];
    if (this.block.data.config.tabs_html && this.block.data.config.tabs_html !== '' && this.block.data.config.tabs_html !== undefined) {
      if (this.block.data.tabs[this.currentIndex].type == 'category') {
        this.getProductListByCid();
      } else if (this.block.data.tabs[this.currentIndex].type == 'goods') {
        this.getProductByItems(this.block.data.tabs[this.currentIndex]['inputItems'], true);
      }
    }
  }

  getProductListByCid() {
    this.productModel = [];
    if (this.block.data.tabs[this.currentIndex].cid) {
      this.productService.getGoodsItems(this.init.merchantId, this.init.cloudStoreId, this.block.data.tabs[this.currentIndex].cid, this.block.data.tabs[this.currentIndex].count).then((data:any) => {
        if (data['code'] === 1000) {
			this.productService.afterPage = data['data']['afterPage'];
			this.productModel = data['data']['content'];
        }
      });
    }
  }

  getProductByItems(inputItems:any, showNoStock:any) {
    this.productModel = [];
    if (inputItems) {
      const arr = this.block.data.tabs[this.currentIndex]['inputItems'].split(','); // 字符分割
      const page_size = arr.length;
      this.productService.getGoodsItems(this.init.merchantId, this.init.cloudStoreId, 0, page_size, 1, inputItems, showNoStock).then((data:any) => {
        if (data['code'] === 1000) {
		  this.productService.afterPage = data['data']['afterPage'];
          this.productModel = data['data']['content'];
        }
      });
    }
  }

  ngAfterViewInit() {
    const id = 'tabCategoryChangeContainer' + this.containerIndex + '_' + this.blockIndex;
    const that = this;
    $('#' + id).on('click', '.tabCategoryChange', function(event) {
      event.stopPropagation();
      const index = $(event.target).attr('tabindex') || '';
      that.currentIndex = (parseInt(index)) - 1;
      that.refresh();
    });
  }
}


