export class CmsAdvancedBlockModel {
  public type = 'textEditor';
  public class = 'col-md-12';
  public visibility = {
    'xs': true,
    'sm': true,
    'md': true,
    'lg': true
  };
  public data = {};
  public html = '';

  constructor(data: {}) {
    Object.assign(<CmsAdvancedBlockModel>this, data);
  }
}
