import { Injectable } from '@angular/core';
import { AES, enc, mode, pad } from 'crypto-js';

@Injectable()
export class CryptoService {
  constructor() {
  }

   // 加密
    encrypt(word:any, keyStr:any, ivStr:any) {
      keyStr = keyStr ? keyStr : "";
      ivStr = ivStr ? ivStr : "";
      let key = enc.Utf8.parse(keyStr);
      let iv = enc.Utf8.parse(ivStr);
      let srcs = enc.Utf8.parse(word);
  
      let encrypted = AES.encrypt(srcs, key, {
        iv,
        mode: mode.CBC,
        padding: pad.Pkcs7
      });
      return encrypted.toString();
    }
	
    // 解密
    decrypt(word:any, keyStr:any, ivStr:any) {
      keyStr = keyStr ? keyStr : "";
      ivStr = ivStr ? ivStr : "";
      var key = enc.Utf8.parse(keyStr);
      let iv = enc.Utf8.parse(ivStr);
  
      var decrypt = AES.decrypt(word, key, {
        iv,
        mode: mode.CBC,
        padding: pad.Pkcs7
      });
      return decrypt.toString(enc.Utf8);
    }
 
}

