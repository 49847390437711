import {Component, Input, OnInit} from '@angular/core';
import {CmsService} from '../../../../services/cms.service';

@Component({
  selector: 'app-cms-block-reusable',
  template: `
    <div class="refresh" *ngIf="editMode">
      <span class="glyphicon glyphicon-refresh" (click)="refresh()"></span>
    </div>
    <div [innerHTML]="cmsBlockModel?.content | html"></div>
  `,
  styles: []
})
export class CmsBlockReusableComponent implements OnInit {

  @Input()
	block!: { data: { hasOwnProperty: (arg0: string) => any; identifier: any; }; };
  @Input() editMode: any;

  public cmsBlockModel: any;

  constructor(public cmsService: CmsService) {
  }

  ngOnInit() {
    if (this.block.data.hasOwnProperty('identifier')) {
      this.getBlock(this.block.data.identifier);
    }
  }

  refresh() {
    this.getBlock(this.block.data.identifier);
  }

  getBlock(id : any) {
    this.cmsService.getBlock(id).then(data => {
      this.cmsBlockModel = data;
      console.log(this.cmsBlockModel);
    }).catch(error => error);
  }
}
