<!--<a href="javascript:;" routerLink="/cart" target="_self" class="a-link edit-cart-link font_s_regular" style="text-decoration: none;">
  <span>< </span> <span style="margin-left: 5px;">返回购物袋</span>
</a>-->
<div class="a-border px-20 py-15 md-hidden" [ngClass]="{'mb-20': !showOrderItems}" style="margin-top: 42px;">
  <button (click)="showOrderItems = !showOrderItems" aria-controls="disclosure-2-0" class="p-0 flex w-full text-left font_small_s_semibold justify-between" type="button" aria-expanded="true">{{showOrderItems? '收起订单详情' : '显示订单详情'}}
    <svg *ngIf="showOrderItems; else otherBlock;" class="inline h-16 w-16 fill-current">
      <use  href="/assets/iconSprite.svg#minus-small"></use>
    </svg>
    <ng-template #otherBlock>
      <svg class="inline h-16 w-16 fill-current">
        <use href="/assets/iconSprite.svg#plus-small"></use>
      </svg>
    </ng-template>
  </button>
</div>
<div class="itemsContainer md-hidden"  [ngStyle]="{'display':showOrderItems?'block':'none'}">
  <ng-container *ngIf="!showOrderItems;else itemTem;"></ng-container>
</div>
<div class="a-border p-20 font_s_regular">
  <ng-container *ngIf="checkoutType !== 'presale'; else presaleTotalTpl1;">
    <div class="flex flex-column gap-8">
      <div class="flex justify-between">
        <span>小计</span><span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{getCartModel()?.itemTotal | number: '1.2-2'}}</span>
      </div>
      <div class="flex justify-between">
        <span>运费</span><span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{checkout.checkoutModel?.postFee}}</span>
      </div>
      <div class="flex justify-between" *ngIf="getCartModel()?.discountFee>0">
        <span>优惠</span><span style="color: #C80000">-<span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{getCartModel()?.discountFee}}</span></span>
      </div>
      <div class="mt-12 mb-12">
        <ng-container *ngIf="checkoutType=='presale'; else couponTem;">
        </ng-container>
      </div>
      <div class="flex justify-between is-reduced mb-12" *ngIf="getCartModel()?.couponFee > 0">
        <span>已优惠</span><span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{getCartModel()?.couponFee | number: '1.2-2'}}</span>
      </div>
      <div class="flex justify-between pt-20 border-t">
        <span class="font_small_l_bold">总计</span><span class="font_si_xs_bold">{{init.currency}}{{getCartModel()?.payment| number: '1.2-2'}}</span>
      </div>
    </div>
  </ng-container>
  <ng-template #presaleTotalTpl1>
    <div class="flex flex-column gap-8">
      <div class="flex justify-between">
        <span>小计（定金）</span><span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{getCartModel()?.advancePayment | number: '1.2-2'}}</span>
      </div>
      <div class="mt-12 mb-12">
        <ng-container *ngIf="checkoutType!=='presale'; else couponTem;">
        </ng-container>
      </div>
      <div class="flex justify-between is-reduced mb-12" *ngIf="getCartModel()?.discountFee > 0 || getCartModel()?.couponFee > 0">
        <span>已优惠</span><span class="font_small_xs_medium font_small_l_regular">{{init.currency}}{{checkout.checkoutModel?.getTotalDiscount() | number: '1.2-2'}}</span>
      </div>
      <div class="flex justify-between pt-20 border-t total-amount">
        <span class="font_small_l_bold">总计（定金）</span><span class="font_si_xs_bold">{{init.currency}}{{getCartModel()?.advancePayment| number: '1.2-2'}}</span>
      </div>
    </div>
  </ng-template>
</div>
<div class="delivery-section section p-15 lg-hidden">
  <span class="heading-caption heading-number-02">温馨提示，即日起订单包裹中将不再附有纸质发货单，如需核实订单详情，可查看“订单详情”或咨询在线客服。</span>
</div>

<div class="lg-hidden mt-20">
  <app-checkout-order [orderRemark]="orderRemark" [getDataParent]="submitBtn"></app-checkout-order>
</div>

<h3 class="font_small_s_semibold items-title lg-hidden">商品明细</h3>
<div class="itemsContainer lg-hidden">
  <ng-container *ngIf="!showOrderItems;else itemTem;"></ng-container>
</div>
<div class="mt-20 p-15 font_small_xxs_medium md-hidden" style="font-size: 11px;line-height: 16px;">
  <p>我们提供14天无理由退货政策。详细政策请参阅我们的 “<a href="javascript:;" (click)="Overlay()" class="a-link">退货条例</a>”。<br><br>
    <a href="javascript:;" (click)="securePayment()" class="a-link"> 支付方式</a><br>
    作为特殊礼遇，您可以享受全场包邮。<br>
    <!--如果您有优惠券，请到您的购物袋里直接输入优惠券号。--></p>
</div>
<ng-template #itemTem>
  <div class="w-full pb-0" *ngFor="let val of checkout.checkoutModel?.checkedItems(); let i = index;">
    <section class="w-full grid grid-cols-12 relative cart-block" [ngClass]="{'i-border-t': i>0}">
      <a class="relative col-span-4 aspect-product-portrait sm-col-span-3 md-row-span-2" routerLink="/{{val.url_path}}">
        <figure class="absolute bottom-0 left-0 right-0 top-0">
          <img class="object-cover transition-opacity duration-300 opacity-100" src="{{val['skuProperties'][0]['hoverUrl'] + init.imageSize.$4}}" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;"/>
        </figure>
      </a>
      <div class="font_small_xxs_medium col-span-8 grid grid-rows-16 gap-8 sm-col-span-9 px-20 py-16">
        <div class="flex flex-column gap-8 w-full" style="overflow: hidden;">
          <a class="overflow-hidden" routerLink="/{{val.url_path}}">
            <h3 style="line-height: 18px;" class="font_s_regular truncate items-name" data-testid="heading" title="{{val.itemName}}">{{val.itemName}}</h3>
          </a>
          <ng-container *ngIf="val.discounts && val.discounts != null && val.discounts.length > 0">
            <div style="margin-left: -4px;">
              <ng-container *ngFor="let dis_item of val.discounts; let dis_i = index;">
                <span *ngIf="dis_i>0" style="color: #757575;height: 13px;display: inline-flex;overflow: hidden;">|</span>
                <span class="items-pro" style="color: #757575; padding:0 4px;">{{dis_item['discountName']}}</span>
              </ng-container>
            </div>
          </ng-container>
          <div class="flex justify-between items-pro">
            <span>价格</span>
            <div class="price-section flex gap-8">
              <span class="font_small_xxs_regular font_small_l_regular is-deprecated price items-pro" *ngIf="val.markingPrice !== val.price">{{init.currency}}{{val.markingPrice | number: '1.2-2'}}</span>
              <span class="font_small_xxs_regular font_small_l_regular items-pro" [ngClass]="{'is-reduced': val.markingPrice !== val.price}">{{init.currency}}{{val.price | number: '1.2-2'}}</span>
            </div>
          </div>
          <div class="flex justify-between items-pro" *ngIf="val.skuProperties.hasOwnProperty(0)">
            <span>颜色</span>
            <span class="font_small_xxs_regular font_small_l_regular items-pro">{{val.skuProperties[0]?.valueName}}</span>
          </div>
          <div class="flex justify-between items-pro" *ngIf="val.skuProperties.hasOwnProperty(1)">
            <span>尺码</span>
            <span class="font_small_xxs_regular font_small_l_regular items-pro">{{checkout.sizeConvert(val.skuProperties[1]?.valueName)}}</span>
          </div>
          <div class="flex justify-between items-pro">
            <span>数量</span>
            <span class="font_small_xxs_regular font_small_l_regular items-pro">{{val.qty}}</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</ng-template>
<ng-template #couponTem>
  <div class="p-16 a-border lg-border-none">
    <button (click)="userCoupon = !userCoupon" aria-controls="disclosure-2-0" class="p-0 flex w-full text-left font_small_s_semibold justify-between" type="button" aria-expanded="true">使用优惠券
      <svg *ngIf="userCoupon; else otherBlock;" class="inline h-16 w-16 fill-current">
        <use  href="/assets/iconSprite.svg#minus-small"></use>
      </svg>
      <ng-template #otherBlock>
        <svg class="inline h-16 w-16 fill-current">
          <use  href="/assets/iconSprite.svg#plus-small"></use>
        </svg>
      </ng-template>
    </button>
  </div>
  <div class="py-20 px-16 a-border border-t-none lg-border-none coupon-block" [ngStyle]="{'display':userCoupon?'block':'none'}">
    <span class="font_s_regular">请输入优惠券编号</span>
    <div class="mt-12 flex flex-row">
      <div class="w-full pr-20">
        <input placeholder="" name="couponCode" class="a-input js-a-input h-40" value="{{getCartModel()?.couponCode}}" [(ngModel)]='couponCode' >
      </div>
      <div class="btn-block h-40">
        <button [ngClass]="{'coupon-btn': couponCode === undefined || !couponCode}" class="a-button add w-full h-40 font_s_regular" *ngIf="!useCoupon && getCartModel()?.couponCode === null || getCartModel()?.couponCode === ''; else couponBlock" (click)="usecoupon()" [disabled]="couponCode === undefined || !couponCode ? true : false">提交</button>
        <ng-template #couponBlock>
          <button (click)="cancelCoupons()" class="a-button add w-full h-40 font_s_regular">提交</button>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': policy || promotion}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-large" [class.is-open]="policy">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 优惠券弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="paragraph parbase section">
          <span class="richTextSpan">
          <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
            <div class="contain">
              <div class="u-row u-full-width">
                <div class="paragraph parbase section"><span class="richTextSpan">
	<div class="contain">
		<div class="u-row u-full-width o-blog-text is-richtext richtextWrapper font_s_regular">
    	 <!-- 内容 -->
			<div><h5>退货和退款</h5>
				<p>COS支持自商品寄达后14天内无理由退货。退货商品须保持干净且未经洗涤或穿戴，并附有完整吊牌、水洗标等标签。若非商品存在瑕疵，内衣裤、袜子、穿刺类首饰及赠品等不予退换；如欲退货泳衣，请勿撕除卫生保护帖。</p>
        <p>在天猫店订购的商品不可在实体门店进行退换。非商品质量问题的退货需由顾客承担所产生的运费。</p>
        <p>如果你有任何其它疑问，请随时联系我们的在线客服或查询在线帮助中心。</p>
      </div>
      <div><h5>退货流程</h5>
      	<p style="margin-bottom:10px">1. 在线申请退货<br />登录官网“我的账户-我的订单”，选择相关订单点击“退货申请”，并填写相关信息。</p>
        <p style="margin-bottom:10px">2. 寄回退货商品，在线填写快递单号<br />在退货明细单勾选需要退货的商品，将此单连同退货商品一起寄回退货地址后，登录官网“我的账户-我的订单”提交退货快递单号。
（请注意：缺漏退货明细单或退货快递单号将导致我们无法正常处理你的退货，影响退货退款的时效。）</p>
				<p style="margin-bottom:10px">3. 质检和退款<br />我们将在收到退货商品后3至5个工作日内完成质检并安排退款。大促或特定节假日期间，以及特例订单的处理可能需要较长时间。</p>
      </div>
      <!-- //内容 -->
		</div>
	</div>
</span>


</div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <!-- 优惠券弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>


<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="promotion">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 优惠券弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{paragraph}}</p>
    </div>
    <!-- 优惠券弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

