import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';

@Component({
	selector: 'app-vjs-player',
	template: `
    <video #videoPlayer class="video-js video-style" [ngClass]="{'mini-video-js': miniVideo}" controls poster="{{posterPath}}" muted playsinline disablePictureInPicture oncontextmenu = "return false" preload="none">
		<source src="{{videoSource}}" type="{{videoType}}">
	</video>
  `,
	styleUrls: [
		'./vjs-player.component.css'
	],
	encapsulation: ViewEncapsulation.None,
}) 
export class VjsPlayerComponent implements OnDestroy ,AfterViewInit {///.///./.,m
	public posterPath! : string;
	public videoSource! : string;
	public videoType! : string;
	@ViewChild('videoPlayer')
	videoPlayer!: ElementRef;
	@Input()
	poster! : string;
	@Input() miniVideo!: boolean;
	@Input()
	options! : {
		fluid : boolean;
		aspectRatio : string;
		autoplay : boolean;
		sources : {
			src : string;
			type : string;
		}[];

	};
	player : videojs.Player | undefined;
	load : any;

	constructor(
	) { }

	ngAfterViewInit() {
		this.posterPath = this.poster;
		this.videoSource = !!this.options.sources ? this.options.sources[0].src : '';
		this.videoType = !!this.options.sources ? this.options.sources[0].type : 'video/mp4';
		this.player = videojs(this.videoPlayer.nativeElement, this.options, function onPlayerReady() {
			// console.log('onPlayerReady', this);
		});
		this.player.on("ended", () => {
			this.load();
		});
	}

	ngOnDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	}
}