import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../services/initialize.service';
import {ProductService} from '../../services/product.service';
import {MiniCartComponent} from '../mini-cart/mini-cart.component';
import {ProductModel} from '../../models/product.model';
import $ from 'jquery';
import {CategoryService} from '../../services/category.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-recommend-common',
  templateUrl: './recommend-common.component.html',
  styleUrls: ['./recommend-common.component.css'],
})
export class RecommendCommonComponent implements OnInit {
  @Input()
  recommendLists!: Array<any>;
  @Input()
  count!: number;
  public currentIndex = 0;
  public currentItem:any = {};
  @Input() ispdp:boolean = false;
  @ViewChild('mini') miniCart!: MiniCartComponent | undefined;
  @Output() quickAdd = new EventEmitter<any>();
  // 货币
  public currency : any;
  constructor(
    public init: InitializeService,
	  public productService: ProductService,
    public categoryService: CategoryService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit() {
    this.currency = this.init.currency;
    this.currentIndex = 0;
    this.currentItem = {};
    if(this.recommendLists.length > 0){
      this.recommendLists.forEach((item:any)=>{
        item['cloudSalePrice'] = item['price'];
        if(item['strategies']){
          const rulePriceJson = this.productService.strategyTag(item['strategies']);
          //显示划线价
          if(rulePriceJson.price !== 0 && rulePriceJson.hasRule) {
            item['cloudSalePrice'] = rulePriceJson.price
          }
        }
      });
    }
  }

  onMouseEnter(i:any){
    this.currentIndex = i+1;
    this.currentItem = this.recommendLists[i];
  }
  onMouseLeave(i:any){
    this.currentIndex = 0;
    this.currentItem = {};
  }
  closeAddCart(item: any){
    this.miniCart?.closeAddCart();
    if(item['classId'] && item['classId'].length > 0){
      const cid = item['classId'][0];
      this.router.navigate([item?.frontUrlPath], {queryParams : {pk_campaign: 'pio-pdp','rec-cid':cid}} );
    } else {
      this.router.navigate([item?.frontUrlPath], {queryParams : {pk_campaign: 'pio-pdp'}} );
    }
  }
  selectSizeClick(item:any, e: Event){
    e.stopPropagation();
    this.quickAdd.emit({'frontCode':item.frontCode,'salePrice':item.cloudSalePrice});
  }

  closeLayer() {
    $("#add-to-buy").hide();
    $('body').removeClass('body_overflow_hidden');
  }
  /**
   * 客户端判断 PC or Mobile
   * @returns {boolean}
   * @constructor
   */
  isPC(): boolean {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

}
