import {Injectable} from '@angular/core';
import {InitializeService} from './initialize.service';
import {RequestService} from './request.service';
import {StorageService} from './storage.service';
import {Observable} from "rxjs";
import {HttpParams} from '@angular/common/http';
import {AddressService} from './address.service';
import {CheckoutModel} from '../models/checkout.model';
const apiConfig = require('../config/config.api.json');

@Injectable()
export class CheckoutService {
  public checkInfoContent: any = {
    code: '',
    message: '',
    data: {
      checkoutItems: {},
      currencySymbol: '',
      discountAmount: 0,
      shipping_amount: '',
      total: '',
      totalQty: 0
    }};
  public checkItems:any;
  public itemsData:any;
  completeOrder:any = '';
  completeMethod:any = '';
  public trackData:any;
  public checkoutModel!: CheckoutModel;
  public presaleCheckoutData:any;
  public quoteData:any;
  public cartLoading: Boolean = true;
  constructor(public init: InitializeService, private request: RequestService, private localStorage: StorageService, public address: AddressService) {

  }


  public setPaymentMethod(method:any) {
    this.address.has_payment = true;
    const setPaymentUrl = apiConfig.shoppingCartApi + '/' + this.localStorage.getItem('quote_id') + '?action=set_payment_method';
    const params = new HttpParams()
      .set('payment_method', method);
    this.request.put(setPaymentUrl, params)
      .subscribe(
        _val => {
          return true;
        },
      );
  }
  /*获取支付方式*/
  /* public getPaymethods() {
     const quote_id = this.localStorage.getItem('quote_id');
     const  url = apiConfig.shoppingCartApi + '?action=payment_method&quote_id=' + quote_id + '&store_id=1';
     this.request.get(url)
       .subscribe(value => {
           this.paymethod = value;
          // console.log(this.paymethod);
       });
   }*/
  public getPayMethods(): Observable<any> {
    const quote_id = this.localStorage.getItem('quote_id');
    const  url = apiConfig.shoppingCartApi + '?action=payment_method&quote_id=' + quote_id + '&store_id=1';
    return this.request.get(url);

  }
  public setComplateOrder(order:any, method:any) {
    this.completeOrder = order;
    this.completeMethod = method;
  }

  /**
   * 设置购物车地址
   * @param addressId
   */
  public setCustomerAddressId(addressId:any,checkoutType = ''): Promise<any> {
    let cartKey = '';
    if(checkoutType == 'presale'){
      cartKey = this.localStorage.getItem('PRESALE_CART_KEY');
    }else{
      cartKey = this.localStorage.getItem('quote_id');
    }
    const url = apiConfig.cloudCart + '/sales_cart/' + cartKey + '/checkout/customer_address?customer_address_id=' + addressId;
    const params = {cart_key: cartKey, customer_address_id: addressId};
    return new Promise((resolve) => {
      this.request.put(url, params).subscribe(value => {
        resolve(value);
      });
    });
  }


  public setCloudPaymentMethod(method:any,checkoutType = ''): Promise<any> {
    this.address.has_payment = true;
    let cartKey = '';
    if(checkoutType == 'presale'){
      cartKey = this.localStorage.getItem('PRESALE_CART_KEY');
    }else{
      cartKey = this.localStorage.getItem('quote_id');
    }
    const setPaymentUrl = apiConfig.cloudCart + '/sales_cart/' + cartKey + '/checkout/pay_type?pay_type=' + method;
    return new Promise((resolve) => {
      this.request.put(setPaymentUrl, null).subscribe(val => {
        resolve(val);
      });
    });
  }

  /**
   * 从购物车API中获取数据
   * @returns {Promise<any>}
   */
  getCheckoutInfo(checkoutType = ''): Promise<any> {
    this.QuoteId(checkoutType);
    if (this.checkoutModel === undefined) {
      return this.getCheckoutData();
    } else {
      return new Promise((resolve1) => {
        resolve1(this.checkoutModel);
      });
    }
  }

  getCheckoutData(checkoutType = ''): Promise<any> {
    this.QuoteId(checkoutType);
    const quoteId = this.quoteData;
    if ((quoteId === 'null' || !quoteId) && !this.init.isLogin()) {
      return new Promise((resolve1) => {
        this.checkoutModel = new CheckoutModel({});
        resolve1(this.checkoutModel);
      });
    }
    if ((quoteId === 'null' || !quoteId) && this.init.isLogin()) {
      return new Promise((resolve1) => {
        this.checkoutModel = new CheckoutModel({});
        resolve1(this.checkoutModel);
      });
    }
    const getCartUrl = apiConfig.cloudCart + '/sales_cart/' + quoteId + '/checkout';
    let resultData: any = {code:  '', message: '' ,  data: {}};
    const doLp = new Promise((resolve1) => {
      this.request.put(getCartUrl, null).toPromise().then(res => {
        resultData = res;
        if (resultData.code === 1000) { // 购物车存在
          this.checkoutModel = new CheckoutModel(resultData['data']);
          if (this.checkoutModel.address !== undefined) {
            this.address.hasAddress = true;
          }
          this.checkoutModel.setItemData();
        } else { // 购物车不存在 则创建
          this.checkoutModel = new CheckoutModel({});
        }
        resolve1(this.checkoutModel);
      }).catch((error: any) => {
        return error;
      });
    });
    return doLp;
  }

  /*quote_id */
  QuoteId(checkoutType = '') {
    if(checkoutType == 'presale'){
      this.quoteData = this.localStorage.getItem('PRESALE_CART_KEY');
    }else{
      this.quoteData = this.localStorage.getItem('quote_id');
    }
    return this.quoteData;
  }


  /**
   * 保存台湾收件人身份证号
   * @param params
   * @returns {Promise<T>}
   */
  public saveCertificateNumber(params:any): Promise<any> {
    this.QuoteId();
    const url = apiConfig.cloudCart + '/customer/sales_cart/' + this.quoteData + '/checkout/certificate';
    return new Promise((resolve1) => {
      this.request.put(url, JSON.stringify(params), true).toPromise().then(res => {resolve1(res); }).catch(error => error);
    });
  }

  public showCheckoutRemark(merchant_id:any,store_id:any){
    let url = `${apiConfig.globalConfigService}/customer/config/value/by_path?config_client_id=efcloud&config_scope=Store&config_path=order/checkout/show_checkout_remark&config_scope_id=${store_id}&merchant_id=${merchant_id}`;
    return new Promise((resolve) => {
      this.request.get(url).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }
  /**
   * 使用优惠卷server
   * @param params
   * @returns {Promise<any>}
   */
  useCouponServer(couponCode:any,checkoutType:any): Promise<any> {
    this.QuoteId(checkoutType);
    const useCouponUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/checkout/coupon_code?coupon_code=' + couponCode;
    return new Promise((resolve) => {
      this.request.put(useCouponUrl, null).toPromise().then(res => {resolve(res); }).catch(error => error);
    });
  }
  /**
   * 取消使用优惠卷server
   * @param params
   * @returns {Promise<any>}
   */
  cancelCouponServer(checkoutType:any): Promise<any> {
    this.QuoteId(checkoutType);
    const useCouponUrl = apiConfig.cloudCart + '/sales_cart/' + this.quoteData + '/clear/coupon_code';
    return new Promise((resolve) => {
      this.request.put(useCouponUrl, null).toPromise().then(res => {resolve(res); }).catch(error => error);
    });
  }

	public sizeConvert(size:any){
		if(!!size && size.indexOf('/P') !== -1){
			return size.replace('/P','');
		}else if(!!size && size.indexOf('P')){
			return size.replace('P','');
		}
		return size;
	}
}
