
    <div class="col-lg-4 col-lg-offset-4">
      <div class="form-group">
        <label for="inputAdminUsername">用户名</label>
        <input type="text" class="form-control" id="inputAdminUsername" placeholder="Username" [(ngModel)]="inputAdminUsername"/>
      </div>
      <div class="form-group">
        <label for="inputAdminPassword">密码</label>
        <input type="password" class="form-control" id="inputAdminPassword" placeholder="Password" [(ngModel)]="inputAdminPassword"/>
      </div>
      <div class="checkbox">
        <button (click)="login()">登录</button>
      </div>
    </div>
  