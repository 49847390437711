<div class="myAccount_right">
  <div class="topSection clearfix"></div>
  <div class="o-my-account-Logout o-my-account-forbid-Logout">
    <div class="warning">
      <img src="../../../assets/images/Logout/forbid-l.png" class="icon" alt="图片">
      <div class="warning-text">
        <h2 class="a-paragraph">抱歉，由于以下原因，注销无法完成：</h2>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="forbid-Logout-text a-paragraph warning-spacing">
      <p>• {{errorMsg}}</p>
    </div>
    <p class="contact-info a-paragraph">如对以上内容有疑问，请联系客服</p>
    <p class="contact-info a-paragraph">电话：400-0151-365</p>
  </div>
  </div>

