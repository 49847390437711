import {ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';
import {ScriptService} from '../../services/script.service';
import {StoreLocatorService} from '../../services/storelocator.service';
import {isPlatformBrowser} from '@angular/common';
declare const AMap: any;
@Component({
  selector: 'app-store-locator',
  templateUrl: './store-locator.component.html',
  styleUrls: ['./store-locator.component.css']
})
export class StoreLocatorComponent implements OnInit {
  showCityName = ''; // 显示的城市名
  showCityList = false; // 显示城市列表
  storeList: any; // 店铺列表
  getCityListInterface = 'https://api.storelocator.hmgroup.tech/v2/brand/cos/locations/locale/zh_CN/country/CN/cities';
  getStoreListInterface = 'https://api.storelocator.hmgroup.tech/v2/brand/cos/stores/locale/zh_CN/country/CN';
  cityList: any;
  showCity = false;

  public storeMap:any;

  constructor(private init: InitializeService, private script: ScriptService,
              private storeServer: StoreLocatorService,
              private cdr: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: Object, ) {

  }
  private pageName = '门店位置';
  ngOnInit() {
    this.init.pageConfiguration(this.pageName);
    if (isPlatformBrowser(this.platformId)) {
      this.script.load('amap').then(() => {
        this.getCityList();
        this.getStoreList();
      }).catch(error => console.log(error));
    }
  }

  initMap(key:any, cp:any) {
    const provinces = this.storeList;
    const map = new AMap.Map('gaodemap-container',
      {
        resizeEnable: true,
        zoom: cp,
        center: [provinces[key].longitude, provinces[key].latitude]
      });
    const markers = [];
    /*province见Demo引用的JS文件*/
    for (let i = 0; i < provinces.length; i += 1) {
      let marker;
      const icon = new AMap.Icon({
        image: 'assets/images/a-icon-store_locator.svg',
        size: new AMap.Size(44, 44)
      });
      marker = new AMap.Marker({
        icon: icon,
        offset: new AMap.Pixel(-22, -36), /*相对于基点的位置*/
        position: [provinces[i].longitude, provinces[i].latitude],
        title: provinces[i].address.streetName1,
        map: map
      });
      let content = '<div style="overflow: auto;">';
      content += '<div class="store-adress">';
      if (provinces[i].address.streetName1) {
        content += '<label class="a-label js-a-label topic">' + provinces[i].address.streetName1 + '</label>';
      } else {
        content += '<label class="a-label js-a-label topic">' + provinces[i].name + '</label>';
      }
      content += '<label class="a-label js-a-label"></label>';
      if (provinces[i].address.streetName2) {
        content += '<label class="a-label js-a-label">' + provinces[i].address.streetName2 + '</label>';
      }
      if (provinces[i].address.postCode || provinces[i].address.postalAddress || provinces[i].address.state) {
        content += '<label class="a-label js-a-label">';
        if (provinces[i].address.postCode) {
          content += provinces[i].address.postCode + ' ';
        }
        if (provinces[i].address.postalAddress) {
          content += provinces[i].address.postalAddress + ' ';
        }
        if (provinces[i].address.state) {
          content += provinces[i].address.state;
        }
        content += '</label>';
      }
      if (provinces[i].phone) {
        content += '<a href="tel://"' + provinces[i].phone + ' target="_self" class="a-link">';
        content += '<label class="a-label js-a-label">' + provinces[i].phone + '</label></a>';
      }
      content +=  '</div>';
      if (provinces[i].openingHours) {
        for (let j = 0; j < provinces[i].openingHours.length; j += 1) {
          content += '<label class="a-label js-a-label">' + provinces[i].openingHours[j].name + ' ' + provinces[i].openingHours[j].opens + ' - ' + provinces[i].openingHours[j].closes + '</label>';
        }
      }
      if (provinces[i].departmentsWithConcepts) {
        for (let j = 0; j < provinces[i].departmentsWithConcepts.length; j += 1) {
          content += '<label class="a-label js-a-label">' + provinces[i].departmentsWithConcepts[j].name + '</label>';
        }
      }
      content += '</div></div>';
      marker.content = content;
      /*给Marker绑定单击事件*/
      marker.on('click', markerClick);
      markers.push(marker);
    }
    const infoWindow = new AMap.InfoWindow({
      offset: new AMap.Pixel(0, -30)
    });

    function markerClick(e:any) {
      infoWindow.setContent(e.target.content);
      infoWindow.open(map, e.target.getPosition());
    }

    /*自定义Marker*/
    const customMarker = new AMap.Marker({
      offset: new AMap.Pixel(-14, -34), /*相对于基点的位置*/
      icon: new AMap.Icon({
        /*复杂图标*/
        size: new AMap.Size(27, 36), /*图标大小*/
        image: '//webapi.amap.com/images/custom_a_j.png', /*大图地址*/
        imageOffset: new AMap.Pixel(-28, 0)/*相对于大图的取图位置*/
      })
    });
    /*加载地图工具条*/
    map.plugin(['AMap.ToolBar'], function () {
      const toolBar = new AMap.ToolBar({locationMarker: customMarker});
      map.addControl(toolBar);
    });

    map.plugin(['AMap.Scale'], function () {
      const scale = new AMap.Scale();
      map.addControl(scale);
    });

    map.plugin(['AMap.MapType'], function () {
      const type = new AMap.MapType();
      map.addControl(type);
    });

    /*加载地图鹰眼*/
    map.plugin(['AMap.OverView'], function () {
      const view = new AMap.OverView();
      view.open();
      map.addControl(view);
    });
    this.storeMap = map;
    return map;
  }

  gotoStore(store:any, isH5=false){
    // 添加点标记
    const icon = new AMap.Icon({
      image: 'assets/images/a-icon-store_locator.svg',
      size: new AMap.Size(44, 44)
    });
    const marker = new AMap.Marker({
      icon: icon,
      offset: new AMap.Pixel(-22, -36), /*相对于基点的位置*/
      map: this.storeMap,
      position: [store.longitude, store.latitude],
      content:"<div></div>"
    });
    marker.on('click', markerClick);
    const infoWindow = new AMap.InfoWindow({
      offset: new AMap.Pixel(0, -30)
    });
    function markerClick(e:any) {
      infoWindow.setContent(e.target.content);
      infoWindow.open(this.storeMap, e.target.getPosition());
    }
    // 将地图视野移到标记点
    this.storeMap.setFitView([marker]);

    if(isH5){
      var element = document.getElementById('gaodemap-container');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth' // 可选，平滑滚动效果
        });
      }
    }
  }

  /*获取城市数据*/
  getCityList() {
    let result: any = {'storeCities': []};
    this.storeServer.get(this.getCityListInterface).subscribe(  val => {
      result = val;
      for (const i in result.storeCities) {
        if (result.storeCities[i].name === 'Changsha') {
          result.storeCities[i].name = '长沙';
        }
      }
      this.cityList = result.storeCities;
    });
  }
  openCityList() {
    this.showCity = !this.showCity;
  }
  getStoreList() {
    let result: any = {'stores': []};
    this.storeServer.get(this.getStoreListInterface + '?openinghours=true&departments=true').subscribe(  val => {
      result = val;
      this.storeList = result.stores;
      for (let i = 0; i < result.stores.length; i++) {
        if (result.stores[i].openingHours) {
          if (this.openTimeIsSeem(result.stores[i].openingHours)) {
            this.storeList[i].openingHours = [{'name': '周一至周日', 'opens': result.stores[i].openingHours[0].opens, 'closes': result.stores[i].openingHours[0].closes}];
          }
        }
      }
      this.initMap(0, 4);
    });
  }
 getDataType(data:any) {
    return Object.prototype.toString.call(data);
 }
  showCityStores(cityId:any, cityName:any) {
    let result: any = {'stores': []};
    this.storeServer.get(this.getStoreListInterface + '/city/' + cityId + '?openinghours=true&departments=true').subscribe(  val => {
      result = val;
      const storeComplete = result.stores;
      if (this.getDataType(storeComplete) !== '[object Array]') {
        this.storeList = [storeComplete];
      } else {
        this.storeList = storeComplete;
      }
      for (let i = 0; i < result.stores.length; i++) {
        if (result.stores[i].openingHours) {
          if (this.openTimeIsSeem(result.stores[i].openingHours)) {
            this.storeList[i].openingHours = [{'name': '周一至周日', 'opens': result.stores[i].openingHours[0].opens, 'closes': result.stores[i].openingHours[0].closes}];
          }
        }
      }
      this.initMap(0, 20);
    });
    this.showCityName = cityName;
    this.showCity = false;
  }

  bak(e:any) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else {
      e.cancelBubble = true;
    }
    return;
  }
  // 判断是否每天的营业时间都一样
  openTimeIsSeem(openHours:any) {
    const start = [], end = [], week = [];
    for (const i in openHours) {
      start.push(openHours[i].opens);
      end.push(openHours[i].closes);
      week.push(openHours[i].name);
    }
    return this.unique(start).length === 1 && this.unique(end).length === 1 && week.length === 7;
  }
  // 数组去重
  unique(arr:any) {
    const hash = [];
    for (let i = 0; i < arr.length; i++) {
      if (hash.indexOf(arr[i]) === -1) {
        hash.push(arr[i]);
      }
    }
    return hash;
  }

  @HostListener('document:click', ['$event.target'])
  claerAll() {
    this.showCityList = false;
  }
}
