import {Injectable} from '@angular/core';
import {InitializeService} from './initialize.service';
import {RequestService} from './request.service';
import {Observable} from 'rxjs';
import {StorageService} from './storage.service';

const apiConfig = require('../config/config.api.json');

@Injectable()
export class AddressService {
  addressListResult: any = {code: '', message: '', data: {content: []}};
  public otherResult!: Array<string>;
  public addressCity:any;
  public addressProvince:any;
  public addressMsg!: Array<string>;
  public addressArea:any;
  public region_id:any;
  public city_id:any;
  public area_id:any;
  public hasAddress:any = false;
  public has_payment:any = false;

  public provinceList: any = {};
  public cityList: any = {};
  public areaList: any = {};
  constructor(public init: InitializeService, private request: RequestService) {
  }

  public getCustomerAdd() {
    const uri = apiConfig.customerAddress + '?curPage=1&pageSize=20';
    this.request.get(uri)
      .subscribe(
        val => {
          this.addressListResult = val;
        }
      );
  }

  /**
   * 获取cloud用户地址
   */
  public getCloudCustomerAdd() {
    return new Promise((resolve) => {
      const uri = apiConfig.cloudCustomerBasic + 'customer/customer_address';
      this.request.get(uri).subscribe(val => {
        resolve(val);
      });
    });
  }

  /*获取省份*/
  public getProvince(): Promise<any> {
    return new Promise((resolve) => {
      const apiProvince = '/rest/directory/region';
      this.request.get(apiProvince + '?country_id=CN')
        .subscribe(
          val => {
            const categoryArray = [];
            let i = 0;
            this.addressProvince = val;
            this.addressMsg = this.addressProvince.data;
            for (const num in this.addressMsg) {
              if (this.addressMsg.hasOwnProperty(i)) {
                const nums = parseInt(num, 0);
                categoryArray[i] = this.addressMsg[nums];
                i++;
              }
            }
            resolve(categoryArray);
          }
        );
    });
  }
  /*获取城市*/
  public getCitys(region:any): Promise<any> {
    return new Promise( (resolve) => {
      const apiCitys = '/rest/directory/city';
      this.request.get(apiCitys + '?region_id=' + region)
        .subscribe(
          val => {
            const categoryArray = [];
            let i = 0;
            this.addressCity = val;
            this.addressMsg = this.addressCity.data;
            for (const num in this.addressMsg) {
              if (this.addressMsg.hasOwnProperty(i)) {
                const nums = parseInt(num, 0);
                categoryArray[i] = this.addressMsg[nums];
                i++;
              }
            }
            resolve(categoryArray);
          }
        );
      if (region) {
        return this.addressCity;
      }
    });

  }
  /*获取县区*/
  public getArea(city:any): Promise<any> {
    return new Promise( resolve => {
      const apiAreas = '/rest/directory/area';
      this.request.get(apiAreas + '?city_id=' + city)
        .subscribe(
          val => {
            const cityAreas = [];
            let i = 0;
            this.addressArea = val;
            this.addressMsg = this.addressArea.data;
            for (const num in this.addressMsg) {
              if (this.addressMsg.hasOwnProperty(i)) {
                const nums = parseInt(num, 0);
                cityAreas[i] = this.addressMsg[nums];
                i++;
              }
            }
            // this.addressArea[city] = resultArray
            resolve(cityAreas);
          }
        );
    });
  }
  /*获取结算页地址*/
  public getQuoteAddress(): Observable<any> {
    const addressQuoteCart = '/customer-service-standard/customer/address?locationCode=CN';
    return this.request.get(addressQuoteCart);
 }
  public setAreaId(area_id:any) {
    if (area_id !== '' || area_id !== undefined) {
      this.area_id = area_id;
    }
  }
  /*
  * @ 提交地址
  * */
  commitAddress(param:any): Promise<any> {
    const commitUrl = apiConfig.shoppingCartApi + '/' + localStorage.getItem('quote_id') + '?action=set_shipping_address';
    return new Promise((resolve) => {
      this.request.put(commitUrl, param).subscribe(value => {
        resolve(value);
      });
    });
  }



  /*获取省份*/
  public getCloudProvince(): Promise<any> {
    return new Promise((resolve) => {
      const apiProvince =  apiConfig.cloudAreaApi + 'basic_china_area';
      this.request.get(apiProvince).subscribe((val:any) => {
        this.addressProvince = val['data']['content'];
        this.addressProvince.forEach((item:any) => {
          this.provinceList[item['areaCode']] = item;
        });
        resolve(this.addressProvince);
      });
    });
  }
  /*获取城市*/
  public getCloudCitys(region:any): Promise<any> {
    return new Promise( (resolve) => {
      const apiCitys = apiConfig.cloudAreaApi + 'basic_china_area/city_code/{city_code}?city_code=' + region;
      this.request.get(apiCitys).subscribe((val:any) => {
        this.addressCity = val['data']['content'];
        this.addressCity.forEach((item:any) => {
          this.cityList[item['areaCode']] = item;
        });
        resolve(this.addressCity);
      });
      // if (region) {
      //   return this.addressCity;
      // }
    });
  }
  /*获取县区*/
  public getCloudArea(city:any): Promise<any> {
    return new Promise( resolve => {
      const apiAreas = apiConfig.cloudAreaApi + 'basic_china_area/district_code/{district_code}?district_code=' + city;
      this.request.get(apiAreas).subscribe((val:any) => {
        this.addressArea = val['data']['content'];
        this.addressArea.forEach((item:any) => {
          this.areaList[item['areaCode']] = item;
        });
        resolve(this.addressArea);
      });
    });
  }
  /*
  * @ 提交地址
  * */
  commitCloudAddress(param:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.post(`${apiConfig.cloudCustomerBasic}customer/customer_address`, JSON.stringify(param), false, false, true).subscribe(value => {
        resolve(value);
      });
    });
  }

  /**
   * 编辑地址
   * @param param
   * @param address_id
   */
  editCloudAddress(param:any, address_id:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.put(`${apiConfig.cloudCustomerBasic}customer/customer_address/v2/${address_id}`, JSON.stringify(param), true).subscribe(value => {
        resolve(value);
      });
    });
  }

  /**
   * 删除地址
   * @param param
   * @param address_id
   * @returns {Promise<T>}
     */
  deleteCloudAddress(address_id:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.put(`${apiConfig.cloudCustomerBasic}customer/customer_address/del/v2/${address_id}`,'',true).subscribe(value => {
        resolve(value);
      });
    });
  }


   //地址栏仅支持()（）- /~ #,，等字符
	verifyReceivingAddr(address:any){
		//特殊字符 +,/,?,%,#,&
			 var reg_mark = /[`!@$%^#&/*+=_""|{}':;｛｝£€•｛｜·￥'\[\].<>?\\！#@%……&*+——|{}【】《》¥‘；：”“’。、？]/;
		//emoji表情符号
		var reg_icon = /[\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/;
		if (reg_mark.test(address) || reg_icon.test(address)) {
			return false;
		}
		return true;
	}

}
