import {UrlSegment} from '@angular/router';

export function CategoryUrlMatcher(url: UrlSegment[]): any {
  const reg = /^(nav_test\/|nav_test\.|women\/|women\.|men\.|men\/|children\.|children\/|kids-and-baby\.|kids-and-baby\/|white-shirt-project\.|white-shirt-project\/|Kids\.|Kids\/|gifts\.|gifts\/|gift-guide\.|gift-guide\/|new-arrivals\.|new-arrivals\/|sale\.|sale\/)/;
  const param = url.join('/');
  if (reg.test(param) && param.match(reg)) {
    const match = param.match(reg);
	if(match && match['input']){
		const path = new UrlSegment(match['input'], {});
		return ({consumed: url, posParams: {'id': path}});
	}
  }
  return ;
}
