import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class AppRoutingCache implements RouteReuseStrategy {
  handlers: { [key: string]: DetachedRouteHandle } = {};

  // 表示对路由允许复用
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reload === false;
  }

  // 当路由离开时会触发。按path作为key存储路由快照&组件当前实例对象
  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
	var routeConfig:any = route.routeConfig;
	if(routeConfig){
		this.handlers[routeConfig.path] = handle;
	}
  }

  // 若path在缓存中有的都认为允许还原路由
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
	  var routeConfig:any = route.routeConfig;
	  if(routeConfig){
	  	return !!route.routeConfig && !!this.handlers[routeConfig.path];
	  }
    return false;
  }

  // 从缓存中获取快照，若无则返回null
  public retrieve(route: ActivatedRouteSnapshot): any {
    if (!route.routeConfig) {
      return null;
    }
	var routeConfig:any = route.routeConfig;
	if(routeConfig){
		return this.handlers[routeConfig.path];
	} 
  }

  // 进入路由触发，判断是否同一路由
  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === current.routeConfig;
  }
}
