import { InitializeService} from '../../../services/initialize.service';
import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import { VmService} from '../../../services/vm.service';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import {isPlatformBrowser} from '@angular/common';
import { any } from 'codelyzer/util/function';

@Component({
  selector: 'app-vm-panel-search',
  templateUrl: 'vm-panel-search.component.html',
  styleUrls: ['./vm-panel-search.component.css']
})
export class VmPanelSearchComponent implements OnInit {
    title = 'app';
    width = 0;
    @Input()
    searchInput: any;
    searchProductList: {} | undefined;
    constructor(public init: InitializeService,
                public vmService: VmService,
                @Inject(PLATFORM_ID) private platformId: Object,
                private dragulaService: DragulaService) {
    }

    ngOnInit() {
      if (isPlatformBrowser(this.platformId)) {
        const bag: any = this.dragulaService.find('search-bag');
        if (bag !== undefined) {
          this.dragulaService.destroy('search-bag');
        }
        this.dragulaService.setOptions('search-bag', {
          copy: true,
          copySortSource: false
        });
        this.dragulaService.drag.subscribe((value: any) => {
          //const elements = value[1];
        });

        this.dragulaService.dragend.subscribe((value: any) => {
          const elements = value[1];
        });
      }
    }

    searchProduct() {
       if ( this.searchInput !== '' ) {
          this.vmService.searchProducts( this.searchInput ).then((result: any) => {
                  const content = result['content'];
                  for ( const listimage in content ) {
                      const listImageArray = content[listimage]['data'];
                      const listImageGalleries = listImageArray['catalogProductEntityMediaGalleries'];
                      const tmp = [ '', '' ];
                      let flag = 2;
                      for ( const image in listImageGalleries ) {
                          if ( listImageGalleries[image]['color_attribute_id'] + '' === listImageArray['color'] + '' ) {
                              if ( listImageGalleries[image]['list_show'] === '1' ) {
                                  tmp[0] = listImageGalleries[image]['value'];
                              } else {
                                  if ( listImageGalleries[image]['value'] === listImageArray['panoramaImage'] ) {
                                      tmp[1] = listImageGalleries[image]['value'];
                                  } else {
                                      tmp[ flag ] = listImageGalleries[image]['value'];
                                      flag++;
                                  }

                              }
                          }
                          content[listimage]['images'] = tmp;
                          content[listimage]['default_image_path'] = tmp[0];
                      }
                  }
                  this.searchProductList = content;
                  localStorage.setItem( 'vmSearchList', JSON.stringify( content ) );
              }
          );
       }
    }

    vmImageClickSearch( id: string , image: any , type: number , number: number ) {
        const thumbnails = document.querySelectorAll( '#listing_thumbnail_' + id + ' a' );
        let flag = 1;
        for ( const tmp in thumbnails ) {
            if ( thumbnails[tmp].className !== undefined ) {
                if ( (type === 1) && (thumbnails[tmp].className.indexOf('img_1') === 0) ) {
                    thumbnails[tmp].className = 'img_1';
                }
                if ( (type === 2) && (thumbnails[tmp].className.indexOf('img_2') === 0) ) {
                    thumbnails[tmp].className = 'img_2';
                }
            }
        }
        for ( const tmp in thumbnails ) {
            if ( flag === (2 * number + type) ) {
                thumbnails[tmp].className += ' act';
            }
            flag++;
        }
        const entity:any = { 'id' : id };
        let message_type = '';
        if ( type === 1 ) {
            entity['img_0'] = image;
            message_type = 'img_0';
        } else {
            entity['img_1'] = image;
            message_type = 'img_1';
        }
        this.vmService.setProductImagesList( entity , message_type );
        return false;
    }
}
