<div class="form-group">
  <label >HTML Tabs 标签</label>
  <textarea style="width: 100%" [(ngModel)]="block.data.config.tabs_html" placeholder="HTML标签"></textarea>
</div>
<ng-container *ngFor="let tab of block.data.tabs;let i = index">
  <div class="form-group">
    <label>Tab{{i+1}}</label>
    <a href="javascript:void(0)" style="float: right;" (click)="delTab(i)">删除</a>
    <div style=" border: 1px solid #e5e5e5;padding: 10px 10px 15px 10px;">
      <div class="form-group">
        <label>HTML 内容</label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" name="isCustomize{{i+1}}" [(ngModel)]="tab.is_customize"  [value]="0">
          否
        </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <label>
          <input type="radio" name="isCustomize{{i+1}}" [(ngModel)]="tab.is_customize" [value]="1">
          是
        </label>
      </div>
      <ng-container *ngIf="tab.is_customize!=1;else elseBlock">
        <div class="radio">
          <label>
            <input type="radio" name="typeRadio{{i+1}}" [(ngModel)]="tab.type" value="category">
            自定义分类
          </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>
            <input type="radio" name="typeRadio{{i+1}}" [(ngModel)]="tab.type" value="goods">
            自定义商品
          </label>
        </div>

        <div class="radio">
          <label>
            <input type="radio" name="showMajorUrl{{i+1}}" [(ngModel)]="tab.showMajorUrl" [value]="0">
            主图
          </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>
            <input type="radio" name="showMajorUrl{{i+1}}" [(ngModel)]="tab.showMajorUrl" [value]="1">
            辅图
          </label>
        </div>

        <div class="radio">
          <label>
            <input type="radio" name="rowsRadio{{i+1}}" [(ngModel)]="tab.rows" [value]="'threeLines'">
            三个一行
          </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label>
            <input type="radio" name="rowsRadio{{i+1}}" [(ngModel)]="tab.rows" [value]="'fourLines'">
            四个一行
          </label>
        </div>

        <ng-container *ngIf="tab.type!='goods'">
          <div class="form-group">
            <label>分类ID</label>
            <input [(ngModel)]="tab.cid" type="text" class="form-control" placeholder="请填写分类ID">
          </div>
          <div class="form-group">
            <label>总商品展示数量（默认12个）</label>
            <input [(ngModel)]="tab.count" type="text" class="form-control" value="12" placeholder="Product Count">
          </div>
        </ng-container>
        <ng-container *ngIf="tab.type=='goods'">
          <div class="form-group">
            <label >请填写商品CC（多个请用,分割）</label>
            <input [(ngModel)]="tab.inputItems" type="text" class="form-control" placeholder="请填写商品CC">
          </div>
        </ng-container>

      </ng-container>
      <ng-template #elseBlock>
        <div class="form-group">
          <textarea style="width: 100%" [(ngModel)]="tab.html_content" placeholder="请填写HTML内容"></textarea>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>

<div class="form-group">
  <a (click)="addTab()" href="javascript:void(0)">添加</a>
</div>
