import { Component, OnInit,Inject,PLATFORM_ID } from '@angular/core';
import {CallComponentService} from '../../../services/callcomponent.service';
import {isPlatformBrowser} from '@angular/common';
@Component({
	selector: 'app-favorite-box',
	templateUrl: './favorite-box.component.html',
	styleUrls: ['./favorite-box.component.css']
})
export class FavoriteBoxComponent implements OnInit {
	public timer:any;
	public showBox:boolean = false;
	public result: any;
	constructor(public callComponentService: CallComponentService, 
	@Inject(PLATFORM_ID) private platformId: Object,) {

	}
	
	ngOnInit() {
		//监听收藏操作
		this.callComponentService.changeFavoriteBox.subscribe((value:any) => {
		  const that = this;
		  if(value == true){
			  that.showBox = false;
		  }else{
			  that.result = value;
			  that.showBox = true;
			  if (isPlatformBrowser(this.platformId)) {
			    this.timer = setTimeout(function () {
			  		that.showBox = false;
			    }, 2000);
			  }
		  }
		});
	}
	
	closePop(){
		this.callComponentService.changeFavoriteBox.next(true);
	}
}