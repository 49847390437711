import {AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnChanges, OnInit, PLATFORM_ID} from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../../services/product.service';
import {ProductModel} from '../../../models/product.model';
import $ from 'jquery';
import {isPlatformBrowser } from '@angular/common';
import {InitializeService} from '../../../services/initialize.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {
  SwiperConfigInterface} from 'ngx-swiper-wrapper';
import { isNumber } from 'ngx-bootstrap/chronos/utils/type-checks';

@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ])
    ])
  ],
  selector: 'app-product-main-image-slide',
  templateUrl: './product-main-image-slide.component.html',
  styleUrls: ['./product-main-image-slide.component.css'],
  providers: [
    {provide: CarouselConfig, useValue: {interval: 4000, noPause: true}},
  ]
})
export class ProductMainImageSlideComponent implements OnInit, AfterViewInit, OnChanges {
  public productModel: ProductModel | undefined;
  // 商品id
  public productId: any;
  // 图片集合
  @Input() ImageArray: any;
  // 颜色
  @Input() colorId: string | undefined;
  //新品上市标签
  @Input() isNewArrival:any | undefined;
  //显示视频
  @Input() showVideo:any;

  //包含视频
  @Input() includeVideo:any;

  // hover
  hover: string | undefined;
  left: Number | undefined;
  top: Number | undefined;
  public selectedImg: any;
  public mySwiperConfig: { direction: string; slidesPerView: string; centeredSlides: boolean; observer: boolean; observeParents: boolean; } | undefined;
  private largeImgSwiper: Swiper | undefined;
  public imageList = [];
  public isPc = true;
  public largeImgSwiperActiveIndex: number | undefined;
  public isNew:any;
  public videoUrl:any;
  public currentSlide = 1;
  public slideTotal = 0;
  public videoPoster:any;
  public mySwiper: any;
  public imageZoomIn:boolean = false;
  public mobileImageScaleUrl: string = "";
  public mobileImageIsScale:boolean = false;
   public mobileImageIsScaleOut:boolean = false;
   
  constructor(
    public init: InitializeService,
    private routerInfo: ActivatedRoute,
    public product: ProductService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef) {
  }
  // mbSwiperConfig: SwiperConfigInterface | undefined;
  ngOnChanges() {
    this.imageList = this.ImageArray;
    this.isNew = this.isNewArrival;
    if(this.includeVideo){
      this.slideTotal = this.imageList.length - 1;
    }else{
      this.slideTotal = this.imageList.length;
    }
  }
  ngOnInit() {
    this.mySwiperConfig = {
      direction : 'vertical',
      slidesPerView: 'auto',
      centeredSlides : false,
      observer: true,
      observeParents: true,
    };

    this.routerInfo.params.subscribe((params : any) => {
        this.productId = params['product_id'];
      }
    );
    this.isPc = this.init.IsPC();
    const isPc = this.init.IsPC();
	$(document).ready(function() {
		var scrollableElement = $('.main-image-wrapper');
		scrollableElement.animate({scrollTop:0}, 500);
		const bigImgDom = document.querySelectorAll('.pdp-big-img');
		const a = bigImgDom[0] as HTMLElement;
		$('#pdp-small-image-' + a.getAttribute('img-id')).addClass('slide-thumbnail-active');
		
		scrollableElement.scroll(function() {
			const visibleTop:any = !!scrollableElement && scrollableElement.scrollTop();
			var scrollTop = visibleTop;
			if(isPc){
				scrollTop = scrollTop - 200; 
			}
			for (const i in bigImgDom) {
				if(Number(i) || (Number(i) == 0)){
					const dom = bigImgDom[i] as HTMLElement;
					if(Number(i) == Math.round((scrollTop) / (dom.offsetHeight))){
						$('#pdp-small-image-' + dom.getAttribute('img-id')).addClass('slide-thumbnail-active')
					}else{
						$('#pdp-small-image-' + dom.getAttribute('img-id')).removeClass('slide-thumbnail-active')
					}
				}
			}
		});
	});
  }

  public onIndexChange(index: number) {
    if(!this.includeVideo){
      this.currentSlide = index + 1;
    }else{
      if(index == 0){
        this.currentSlide = 1;
      }else{
        this.currentSlide = index + 1
      }
    }
  }

  ngAfterViewInit() {
  }
 
  showLargeSwiper( url : string) {
    const that = this;
	if ( !this.isPc ){
		if( url == '' ){
			that.mobileImageIsScaleOut = true
			setTimeout(function(){
				that.mobileImageIsScale = !that.mobileImageIsScale
				that.mobileImageIsScaleOut = false
			},300)
		}else{
			that.mobileImageIsScale = !that.mobileImageIsScale
			that.mobileImageScaleUrl = url == '' ? that.mobileImageScaleUrl : url
		}	
	}else{
		if (isPlatformBrowser(this.platformId)) {
			var smallGallery = $('#productSmallGallery');
			if(that.imageZoomIn){
				smallGallery.addClass('slider-wrappe');
				smallGallery.removeClass('thumbnail-zoom');
				$(".main-image-wrapper").removeClass("big-image-zoomin");
				$(".main-image-wrapper").addClass("big-image-zoomout");
				$(".cursor-zoom-in").removeClass("cursor-zoom-out");
				$(".icon_zoomIn").removeClass("act");
				that.imageZoomIn = false;
			}else{
				smallGallery.removeClass('slider-wrappe');
				smallGallery.addClass('thumbnail-zoom');
	
				$(".main-image-wrapper").removeClass("big-image-zoomout");
				$(".main-image-wrapper").addClass("big-image-zoomin");
				$(".cursor-zoom-in").addClass("cursor-zoom-out");
				$(".icon_zoomIn").addClass("act");
				that.imageZoomIn = true;
			}
		}
	}
  }
 
 
  @HostListener('window:scroll', ['$event'])
  onScroll() {
 
  }
}
