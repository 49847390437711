import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {OrderModel} from '../models/order.model';


const localConfig = require('../config/config.local.json');
const apiConfig = require('../config/config.api.json');

@Injectable()
export class RmaService {
  public rma_id:any;
  public rma_increment_id:any;
  public rma_passcode:any;
  public rmaData: any;
  public loading: boolean = true;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public productOptions:any = [];
  public applyCancel:boolean = false;
  public cancelRefundId:any;
  public expressNames:any;
  constructor(private request: RequestService) {
  }
  public refundStateTranslateArr = {WAIT_SELLER_AGREE: '待审核',WAIT_BUYER_RETURN_GOODS: '待退货',WAIT_SELLER_CONFIRM_GOODS: '待退款',CLOSED: '已关闭',SUCCESS: '退款成功',RETAIL_STORE_RECEIVED: '门店已收货'};
  public reasonText = {8:'不喜欢/不想要',9:'多买/错买',10:'未按约定时间发货',1:'尺码偏小',2:'尺码偏大',3:'款式不满意',4:'材质不满意',5:'错发货',6:'颜色有问题/有色差',7:'质量问题',11:'其他'};
  /**
   * 获取订单
   * @param {number} id
   * @returns {Promise}
   */
  getOrder(id: string): Promise<any> {
    return new Promise((resolve) => {
      this.request.list(apiConfig.orderListApi + '?id=' + id).toPromise().then(
        (res:any) => {
          const order = new OrderModel(res['message']);
          order.id = id;
          order.loadTimestamp = new Date().valueOf();
          resolve(order);
        }
      ).catch(error => error);
    });
  }

  /**
   * 获取cloud订单
   * @param {number} id
   * @returns {Promise}
   */
  cloudOrderDetail(id: string): Promise<any> {
    return new Promise((resolve) => {
      const getOrderInfoUrl = apiConfig.cloudSalesOrder + 'sales_order/' + id + '/customer';
      this.request.get(getOrderInfoUrl).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }

  analysisProductColor(options:any){
    this.productOptions = JSON.parse(options);
    return this.productOptions[0];
  }

  analysisProductSize(options:any){
    this.productOptions = JSON.parse(options);
    return this.productOptions[1];
  }

	public sizeConvert(size:any){
		if(!!size && size.indexOf('/P') !== -1){
			return size.replace('/P','');
		}else if(!!size && size.indexOf('P')){
			return size.replace('P','');
		}
		return size;
	}
	
  productUrl(options:any){
    this.productOptions = JSON.parse(options);
    if(this.productOptions[0].frontUrlPath === undefined || this.productOptions[0].frontUrlPath === null){
      return '/';
    }
    return this.productOptions[0].frontUrlPath;
  }

  /**
   * 退换原因
   * @returns {Promise<T>}
   */
  refundReason():Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.cloudSalesOrder + '/sales_refund/customer/refund/reason').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }

  /**
   * 获取订单
   * @param {number} id
   * @returns {Promise}
   */
  getRmaOrder(id: string, action = 'order'): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.rmaApply + '/' + id + '?action=' + action).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }
  saveRma(_orderId:any, data:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudSalesOrder +"sales_refund/customer/apply/v2", data).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });

    /*    return new Promise((resolve) => {
     this.request.post(apiConfig.rmaReturn, data).toPromise().then(
     res => {
     resolve(res);
     }
     ).catch(error => error);
     });*/
  }
  getRma(rmaId:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.cloudSalesOrder + 'sales_refund/customer/refund/' + rmaId).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }

  /**
   * 补充退货快递单号
   * @param rmaId
   * @param data
   * @returns {Promise<T>}
     */
  updateRma(data:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudSalesOrder + 'sales_refund/customer/return',data).toPromise().then(
          res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }

  getRmaList(): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.cloudSalesOrder + 'customer/sales_refund/customer/refunds?page_no='+this.currentPage + '&page_size=' + this.pageSize+'&store_ids=8,10').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }

  /**
   * 取消退货申请
   * @param rmaId
   * @returns {Promise<T>}
     */
  cancelRefund(rmaId:any){
    this.applyCancel = true;
    this.cancelRefundId = rmaId;
  }

  cancelConfirm():Promise<any>{
    return new Promise((resolve) => {
      const params = {'refund_id':this.cancelRefundId};
      this.request.put(apiConfig.cloudSalesOrder + 'sales_refund/customer/refund/closed/'+this.cancelRefundId,params).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  getExpressNames(_rmaId:any):Promise<any>{
    return new Promise((resolve) => {
      this.request.get(apiConfig.cloudSalesOrder + 'sales_express/customer').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    })
  }

  // 关闭遮罩层和弹框
  closeOverlay() {
    this.applyCancel = false;
  }
}
