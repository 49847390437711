import { Component, OnInit } from '@angular/core';
import { InitializeService } from '../../../services/initialize.service';
import { CallComponentService } from '../../../services/callcomponent.service';
import { NavigationEnd, Router } from '@angular/router';
import { CartService } from '../../../services/cart.service';
import { TrackingcodeService } from '../../../services/trackingcode.service';
import { filter } from 'rxjs/operators';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	public notUseFooter = false;
	public showCookieBlock = false;
	public showPrivacyBlock = false;
	public isVisible : boolean = false;
	public mobPdp : boolean = false;
	public currentUrl! : string;
	public timer : any;
	public showMembership = false;
	constructor(public init : InitializeService,
		public eventSubscribeService : CallComponentService,
		private router : Router,
		public cart : CartService,
		private tracking : TrackingcodeService) {
	}

	ngOnInit() {
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd)
		).subscribe(_event => {
			const currentUrl = this.router.routerState.snapshot.url;
			this.currentUrl = currentUrl;
			if (currentUrl && currentUrl !== '/') {
				const notUseFooterList = this.init.notUseFooterPages;
				if (notUseFooterList.indexOf(currentUrl) !== -1) {
					this.notUseFooter = true;
				}else{
					this.notUseFooter = false;
				}
			} else {
				this.notUseFooter = true;
			}
			this.showMembership = false;

			// 订阅尺码label is-disable是否显示
			this.eventSubscribeService.mobPdp.subscribe((value:any) => {
			   this.mobPdp = value;
			});
		});
	}

	public showSubscribe() : void {
		this.eventSubscribeService.subscribeBox.next('1');
	}

	isVisibleOverlay() {
		this.isVisible = true;
	}
	closeOverlay() {
		this.isVisible = false;
	}


	linkTo(navName : any) {
		if (this.init.gaTrackingFlag) {
			this.tracking.gaTrackingMenuClick('footer', navName);
		}
	}

	selectContry(){
    this.eventSubscribeService.selectCountryBox.emit(true);
  }

  membership(){
	  this.showMembership = true;
  }
  closeMembership(){
    this.showMembership = false;
  }
}
