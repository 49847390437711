 <!-- 通知提示 -->
  <div id="m-cookie-message" class="m-cookie-message is-visible" *ngIf="showCookieBlock && init.agreeAgreement !== 'No'">
    <!-- <p>我们在网站上使用Cookie，为您提供更好的服务。&nbsp;<a routerLink="/privacy-policy">了解更多</a></p> -->
    <div style="content">
      <br>
      <h3 style="font-size: 18px;font-weight: 700;">在您继续购物之前</h3>
      <br>
      <p>为确保您获得最佳的在线购物体验，您可选择授权我们使用Cookie客制化及改进向您提供的内容。</p>
      <br>
      <p>如果您想进一步了解Cookie以及我们使用Cookie的理由，请随时造访 <a href="/privacy-policy">个人信息保护政策</a> 中关于Cookie的章节。</p>
      <br>
      <br>
      <p>点击“接受全部Cookies”表示我方将收集及使用Cookie，并根据您的喜好显示优惠与提醒。</p>
      <br>
      <p>点击“仅收集必要Cookies”，则我方仅在为向您提供服务所必须的情况下使用必要Cookies。此类必要Cookies将不会储存任何个人可识别信息，您可在浏览器中设置关闭此类必要Cookies，但关闭行为有可能导致网站部分功能无法实现。</p>
    </div>
    <div class="actions">
      <button type="button" class="button1" (click)="closeCookie()">接受全部Cookies</button>
      <button type="button" class="button2" (click)="closeCookie()">仅收集必要Cookies</button>
    </div>
  </div>
  
  <!-- 用户隐私协议-->
  <div id="m-cookie-message" class="m-cookie-message is-visible" *ngIf="init.isLogin() && init.agreeAgreement == 'No'">
     <h3 style="font-size: 18px;font-weight: 700;text-align:center;">COS个人信息保护政策提示</h3>
  	<br>
    <div style="content">
      <p>感谢您对COS的支持和信任！<br>为了保护您的个人信息安全，请您务必仔细阅读，并充分理解我们最近更新的《个人信息保护政策》。我们将严格按照您同意的《个人信息保护政策》中的各项条款使用和保护您的个人信息。详细信息请阅读《<a href="/privacy-policy" style="text-decoration: underline;">个人信息保护政策</a>》。</p>
    </div>
    <div class="actions">
      <button type="button" class="button1" (click)="agree()">同意</button>
      <button type="button" class="button2" (click)="disagree()">不同意</button>
    </div>
  </div>
	