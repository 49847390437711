<div class="container-fluid">
  <div id="panel" class="vm-panel col-lg-2 visible-lg" *ngIf="init.showVmPanel()">
    <app-vm-panel></app-vm-panel>
  </div>
  <div id="wrapper" [ngClass]="{'col-lg-10 col-lg-offset-2':init.showVmPanel()}">
    <app-cos-header></app-cos-header>
    <div class='main container'>
      <div class="row">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>
      </div>
    </div>

	<app-footer></app-footer>
  <app-select-country></app-select-country>

    <!--<app-abtest-tracking *ngIf="init.abTestTrackingAble"></app-abtest-tracking>-->
  </div>
</div>
