import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-account-logout',
  templateUrl: './account-logout.component.html',
  styleUrls: ['./account-logout.component.css']
})

export class AccountLogoutComponent implements OnInit {
  public mobile = '';
  public customerInfo: any = {};
  public nextStepUrl = '';
  constructor(public init:InitializeService,
              private router:Router,
              @Inject(PLATFORM_ID) private platformId:Object,
              private localStorage:StorageService) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.init.isLogin()) {
        this.router.navigate(['']);
      }
      this.mobile = this.localStorage.getItem('mobile');
      this.mobile = this.mobile.replace(this.mobile.substring(3,5),'*****');
    }
    this.init.pageConfiguration('注销账号');
  }

  nextStep(){
    this.localStorage.setItem('logout_next_step','yes');
    this.nextStepUrl = './my-account/logout-check';
    this.router.navigate([this.nextStepUrl] );
  }
}
