import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {Injectable} from '@angular/core';
import {ProductModel} from '../models/product.model';
import {ProductService} from './product.service';
import {TrackingcodeService} from './trackingcode.service';

const apiConfig = require('../config/config.api.json');

@Injectable()
export class SearchService {
	public tempProductModel!: ProductModel;
  public searchData: any;
  public searchList: Array<any> = [];
  public countNum:any = 0;
  public productIds:any = [];
  public currentPage:any = 1;
  public size:any = 16;
  public category_id:any = null;
  public showCount:any = false;
  // 无限加载锁
  public loadLock:any = true;
  public noData:any = false;
  public loading:any = false;
  // 分类定义属性
  public categoryTree: any;
  public dataNumber: any;
  public categoryData: any;
  public departmentList:any = [];
  public categoryList:any = [];
  public subCategoryList:any = [];
  public rulePriceJson:any = {price:'',rulesArr:[]};//策略价+预热价
  public afterPage:any;
  public moreBackground : any;
  public productNum:any;
  //已加载商品数量
  public loadedCount = 0;
  constructor(private init: InitializeService,
			  private productService: ProductService,
			  private tracking: TrackingcodeService,
              private request: RequestService) {
  }
  searchPage(keyword:any, category_id: any = false, is_clear: boolean = false) {
    this.noData = false;
    return new Promise((_resolve) => {
      let url = '/search-service-standard/catalog/product?keyword='
        + keyword + '&store_id=' + this.init.storeId + '&page=' + this.currentPage + '&size=' + this.size;
      if (category_id) {
        this.category_id = category_id;
        url += '&category_id=' + this.category_id;
      } else if (this.category_id) {
        url += '&category_id=' + this.category_id;
      }
      if (is_clear) {
        this.searchList = [];
      }
      this.request.get(url)
        .subscribe(
          res => {
            this.searchData = res;
            // 判断是否最后一页，如果不是设置loadLock = false
            if (this.currentPage < this.searchData.totalPages) {
              this.loadLock = false;
            }
            // 已经加载到最后一页
            if (this.currentPage === this.searchData.totalPages) {
              // 没有数据了
              this.noData = true;
              this.loading = false;
            }
            for (const key in this.searchData.content) {
              const product = this.searchData.content[key].data;
              // 获取catalogProductEntityMediaGalleries的默认图片
              for (const i in product.catalogProductEntityMediaGalleries) {
                if (product.catalogProductEntityMediaGalleries[i].list_show == 1 && product.catalogProductEntityMediaGalleries[i].color_attribute_id == product.color) {
                  product.default_image = product.catalogProductEntityMediaGalleries[i].value;
                }
              }
              // 判断当前时间是否在mkd时间内
              let inspecialdate = 0;
              if ((product.hasOwnProperty('specialFromDate') && product.specialFromDate !== null)
                && ((product.hasOwnProperty('specialToDate') && product.specialToDate !== null))) {
                const fromDate = product.specialFromDate.slice(0, 19);
                const ToDate = product.specialToDate.slice(0, 19);
                if (fromDate !== '0000-00-00T00:00:00' && ToDate !== '0000-00-00T00:00:00') {
                  const Start = new Date(fromDate.replace('T', ' '));
                  const End = new Date(ToDate.replace('T', ' '));
                  if (new Date().getTime() >= Start.getTime() && new Date().getTime() <= End.getTime()) {
                    inspecialdate = 1;
                  }
                }
              }
              product.inSpecialDate = inspecialdate;
              this.searchList = this.searchList.concat(product);
            }
            this.countNum = this.searchData.totalElements;
            this.showCount = true;
            this.loading = false;
          }
        );
    });
  }

  cloudSearchPage(keyword:any, category_id: any = false, is_clear: boolean = false) {
    this.noData = false;
    return new Promise((_resolve) => {
		const groupId = '';
		const memberLevelId = '';
		const memberStatus = '';
		this.afterPage = this.currentPage == 1 ? '' : encodeURIComponent(this.afterPage);
      let url = '/cloud-item-basic/customer/elasticsearch/fast?q=' + keyword + '&store_id=' + this.init.cloudStoreId + '&merchant_id=' +this.init.merchantId+ '&page_no=' + this.currentPage + '&page_size=' + this.size+'&group_id='+groupId+'&member_level_id='+memberLevelId+'&member_status='+memberStatus+'&after_page='+this.afterPage;
      if (category_id) {
        this.category_id = category_id;
        url += '&class_id=' + this.category_id;
      } else if (this.category_id) {
        url += '&class_id=' + this.category_id;
      }
      if (is_clear) {
        this.searchList = [];
      }
      this.request.get(url).subscribe((res:any) => {
        this.searchData = res['data'];
		this.afterPage = this.searchData.afterPage; 
		
        // 判断是否最后一页，如果不是设置loadLock = false
		this.productNum = this.searchData.total;
        const pageCount = Math.ceil(this.searchData.total / this.size);
 
		const contentLen =  this.searchData.content.length;
		var loadedCount = this.size;
		if(this.currentPage > 1){
			if(this.size == contentLen){
				loadedCount =  this.size * this.currentPage;
			}else{
				loadedCount +=  contentLen;
			}
		}
		
		this.loadedCount = loadedCount;
		var laodPercent = (this.loadedCount/this.productNum)*100;
		this.moreBackground = "linear-gradient(to right, #080808 "+laodPercent+"%, #fff 0%)"	
		
        if (this.currentPage < pageCount) {
          this.loadLock = false;
        }
        // 已经加载到最后一页
        if (this.currentPage === pageCount || pageCount == 0) {
          // 没有数据了
          this.noData = true;
          this.loading = false;
        }
		
        for (const key in this.searchData.content) {
          const product = this.searchData.content[key];
			product['rulesArr'] = [];
          product.promotionTags = '';
          if (product.promotionTag != null && product.promotionTag != '' && product.promotionTag.length > 0) {
            product.promotionTags = product.promotionTag.join(' ');
          }
          if (product['frontTag'] != '' && product['frontTag'] != null && product['frontTag'].indexOf('ONLINE EXCLUSIVE') > -1) {
            product.is_exclusively = true;
          } else {
            product.is_exclusively = false;
          }
          product['cloudPrice'] = product['markingPrice'];
          product['cloudSalePrice'] = product['price']; 
          if (product['strategies'] && product['strategies'] != null && product['strategies'].length > 0) {						
				this.tempProductModel = new ProductModel(product);
				//筛选有效期内的策略价标签
				 this.rulePriceJson = this.productService.strategyTag(product['strategies'])
				 //显示划线价
				if(this.rulePriceJson.price !== 0 && this.rulePriceJson.hasRule) {
					product['cloudSalePrice'] = this.rulePriceJson.price;
				}
				if (this.rulePriceJson.rulesArr.length > 0) {
					product['rulesArr'] = this.rulePriceJson.rulesArr;
				}  
          }
          product['key'] = product['itemId'] + '-' + product['valueId'];
          this.searchList = this.searchList.concat(product);
        }
        this.countNum = this.searchData.total;
        this.showCount = true;
        this.loading = false;
		// 向Google推送数据
		if (this.init.gaTrackingFlag) {
			// 新版Ga Tracking Js
			this.tracking.gaTrackingInternalSearch(keyword,this.searchData.total);
		}
	    });
    });
  }

	 
  // 分类树
  getCategoryTree(keyword:any) {
    return new Promise((_resolve) => {
       this.request.get('/search-service-standard/catalog/product/category/name?keyword='
        + keyword + '&store_id=' + this.init.storeId).toPromise().then(
        (res:any) => {
          // resolve(res);
          if (res['code'] * 1 === 200) {
            this.categoryTree = res['data'];
            this.dataNumber = res['dataNumber'];
            // 最后显示数据
            this.categoryData = this.iteration(this.categoryTree, this.dataNumber);
            // 获取一级分类
            //  for (let i = 0; i < this.categoryData.length; i ++) {
            //   this.departmentList.push(this.categoryData[i]);
            // }
          }
        }
      ).catch(error => error);
    });
  }

  /**
   * 递归添加将number到各个childs数组中
   * @param data
   * @param dataNumber
   */
  iteration(data:any, dataNumber:any) {
    for (let j = 0; j < data.length; j++) {
      if (dataNumber.hasOwnProperty(data[j].entity_id)) {
        // 取dataNumber下面的key的value
        data[j]['number'] = dataNumber[data[j].entity_id];
        if (data[j].childs !== undefined) {
          this.iteration(data[j].childs, this.dataNumber);
        }
      }
    }
    return data;
  }

  /**
   * 找到对应的子分类
   * @param label
   * @param key
   */
  findThisChilds(label:any, key:any) {
    let childsArr: any;
    if (label === 1) {
      this.categoryData.forEach(function(_value:any, index:any, array:any) {
        if (index === key) {
          childsArr = array[key].childs;
        }
      });
    } else if (label === 2) {
      this.categoryList.forEach(function(_value:any, index:any, array:any) {
        if (index === key) {
          childsArr = array[key].childs;
        }
      });
    }
    return childsArr;
  }

  /**
   * 新增搜索词
   * @param searchTerm
   */
  postSearchTerm(searchTerm: string) {
    const url = apiConfig.merchantExtendCloudApi + '/customer/merchant_store_search_term';
    const paramsJson:any = {'merchantId': this.init.merchantId, 'searchTerm': searchTerm, 'storeId': this.init.cloudStoreId.toString()};
    return new Promise((resolve) => {
      this.request.post(url, paramsJson, false, false, true).toPromise().then(res => {
        resolve(res);
      });
    });
  }
}
