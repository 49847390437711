import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-account-logout-reason',
  templateUrl: './account-logout-reason.component.html',
  styleUrls: ['./account-logout-reason.component.css']
})

export class AccountLogoutReasonComponent implements OnInit {
  public prompt = false;
  public reasonVal!: string;
  public tips!: string;
  public reasonValid:boolean = false;
  public timer:any = null;
  constructor(
              public init:InitializeService,
              private router:Router,
              @Inject(PLATFORM_ID) private platformId:Object,
              private localStorage:StorageService) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.init.isLogin() || this.localStorage.getItem('logout_check_success') === null) {
        this.router.navigate(['']);
      }
    }
    this.init.pageConfiguration('注销原因');
  }

  confirmLogout(){
    this.localStorage.setItem('logout_processing','yes');
    this.localStorage.removeItem('logout_check_success');
    this.router.navigate(['/my-account/logout-processing']);
  }

  setReason(val: string){
    this.reasonValid = false;
    this.tips = '';
    this.reasonVal = val;
    this.localStorage.setItem('logout_reason',val);
  }

  submitLogout(){
    if(this.reasonVal === undefined || this.reasonVal === null){
      this.reasonValid = true;
      this.tips = '请选择注销原因';
      const that =this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.reasonValid = false;
        }, 3000);
      }
      return false;
    }
    this.prompt = true;
	return;
  }

  cancelLogout(){
    this.prompt = false;
  }
}
