<div>
  <div>
    <div class="box-1">
      <p class="p-0">请验证您尾号{{tailNumber}}的手机号码：</p>
      <div class="box-2">
        <input placeholder="请输入您的验证码"  class="demo" name="code" type="number" maxlength="6" [(ngModel)]='phoneCaptchaValue'/>
        <button type="button-1"  [disabled]="phoneCaptchaDisabled" (click)="sendVerifyCode()" class="btn_code">{{sendPhoneLabel}}</button>
        <label *ngIf="verifyCodeError===true" class="a-label js-a-label" data-placeholder="">{{verifyCodeErrorMsg}}</label>
      </div>
      <button class="button-2" (click)="validateCode()">登录</button>
    </div>
  </div>
</div>

