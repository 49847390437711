import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-account-logout-success',
  templateUrl: './account-logout-success.component.html',
  styleUrls: ['./account-logout-success.component.css']
})

export class AccountLogoutSuccessComponent implements OnInit {
  public successMsg = '';
  constructor(public init:InitializeService,
              private router:Router,
              private localStorage:StorageService,
              @Inject(PLATFORM_ID) private platformId:Object) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.successMsg = this.localStorage.getItem('logout_success_msg');
      if (!this.init.isLogin() || this.successMsg === null) {
        this.router.navigate(['']);
      }else{
        this.localStorage.removeItem('logout_success_msg');
        this.init.pageConfiguration('注销成功');
        this.init.logout();
      }
    }
  }
}
