<div class="add_cart_container" *ngIf="showBox" style="transform: translateY(0%) translateZ(0px);">
  <div aria-hidden="true" class="pageTopLayer-bg" (click)="closeSelect()"></div>
  <div class="m-cart-addition" *ngIf="showLists">
    <div class="w-full content-wrapper u-clearfix cart-wrapper" style="transform: translateY(0%) translateZ(0px);">
      <div class="touch-none lg-hidden">
        <button aria-label="CLOSE" class="flex min-h-13 w-full items-center justify-center cursor-pointer focus:outline-none" type="button">
          <div class="touch-icon"></div>
        </button>
      </div>
      <div class="product-section">
        <div class="heading pl-20 py-15 mb-30">
          <h2 class="a-heading-2 font_small_s_semibold">更换地址</h2>
        </div>
        <span class="a-icon-close" (click)="closeSelect()"></span>
        <div class="relative flex flex-wrap pt-30 pl-20 font_small_xs_regular mb-40">
          <div class="sel-title mb-15">选择国家</div>
          <div class="sel-title font_xs_regular select-item mb-15">中国/ RMB</div>
          <div class="sel-title">语言</div>
          <div class="sel-title font_xs_regular select-item">中文</div>
        </div>
        <div class="h-full country-container">
          <ng-container *ngFor="let _country of countryLists; let i = index;">
            <section class="w-full country-block pt-15 px-20 font_small_xs_regular section-block" [ngClass]="{'section-current':showCountry && currentIndex==i}">
              <button class="flex w-full justify-between text-left pb-26 px-0" type="button" (click)="selectRegion(i)">{{_country.region}}
                <svg *ngIf="showCountry && currentIndex==i; else otherBlock;" class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#minus-small"></use></svg>
                <ng-template #otherBlock>
                  <svg  class="inline h-16 w-16 fill-current"><use href="/assets/iconSprite.svg#plus-small"></use></svg>
                </ng-template>
              </button>
              <div class="flex font_small_xs_regular" *ngIf="showCountry && currentIndex==i">
                <div class="w-full pb-35">
                  <ul class="flex flex-col">
                    <li *ngFor="let _lists of _country['lists'];"><button (click)="goto(_lists)" class="flex w-full justify-between font_small_xs_regular mb-15 px-0" type="button"><span>{{_lists['name']}}</span><span>{{_lists['icon']}}</span></button></li>
                  </ul>
                </div>
              </div>
            </section>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showConfirm" class="absolute confirm-container p-15">
    <span style="right: 15px;" class="a-icon-close absolute" (click)="closeSelect()"></span>
    <div class="pb-20 md-pb-15">
      <span class="font_xs_regular c-tips">您目前正在中国网站购物。更换国家时，您的购物袋将被清空。你确定要继续吗?</span>
    </div>
    <div class="py-15">
      <button (click)="confirm()" class="a-button is-primary h-50 btn-disable" type="button"><span class="font_si_xs_medium">继续前往{{currentItem['name']}}</span></button>
    </div>
    <div>
      <button (click)="closeSelect()" class="a-button is-primary h-50" type="button" ><span class="font_si_xs_medium">取消</span></button>
    </div>
  </div>
</div>
