import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cms-block-multi-product',
  template: `
    <div class="refresh" *ngIf="editMode">
      <span class="glyphicon glyphicon-refresh" (click)="refresh()"></span>
    </div>
    <p>
      cms-block-multi-product works!
    </p>
  `,
  styles: []
})
export class CmsBlockMultiProductComponent implements OnInit {

  @Input() block: any;
  @Input()
	editMode!: boolean;

  constructor() {
  }

  ngOnInit() {
  }


  refresh() {
  }
}
