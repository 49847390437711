
    <div class="form-group">
      <label for="inputProductId">是否开启到货提醒(显示无库存商品)</label>
    </div>
    <div class="radio">
      <label>
        <input checked type="radio" name="rows1" [(ngModel)]="block.data.config.showNoStock" id="optionsRadios3" value="1" >
        是
      </label>
    </div>
    <div class="radio">
      <label>
        <input type="radio" name="rows1" [(ngModel)]="block.data.config.showNoStock" id="optionsRadios4" value="0" >
        否
      </label>
    </div>
    <div class="form-group">
      <label for="inputProductId">请填写商品CC（多个请用,分割）</label>
      <input [(ngModel)]="block.data.inputItems" type="text" class="form-control" id="inputItems"
             placeholder="请填写商品CC">
    </div>
    <div class="radio">
      <label>
        <input type="radio" name="rows" [(ngModel)]="block.data.config.rows" id="optionsRadios1"
               value="threeLines" checked>
        三个一行
      </label>
    </div>
    <div class="radio">
      <label>
        <input type="radio" name="rows" [(ngModel)]="block.data.config.rows" id="optionsRadios2"
               value="fourLines">
        四个一行
      </label>
    </div>
  