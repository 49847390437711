<div class="o-page-content search-results" style="margin-top:0">
  <div class="content-section">
    <div class="search1 search parbase">
      <div class="content-section">
        <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
          <div class="contain">
            <div class="u-cols-lg-24-24 u-cols-md-12-12 u-cols-sm-12-12">
              <div class="o-search-suggestions grid grid-cols-12 gap-x-3 md:gap-x-5 px-3 md:px-5">
                <p class="a-paragraph col-span-12 col-start-1 lg:col-span-3 lg:col-start-1" id="filterResults">{{searchService.countNum}} 个结果 "{{keyword}}"</p>
              </div>
            </div>
            <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-no-padding">
              <div id="reloadProducts" class="o-search-results">
                <div class="goodsGrid o-category-listing">
									<ng-container *ngIf="searchService.searchList">
										<div class="column category-list-product-block col-span-12 lg:col-span-3" *ngFor="let product of searchService.searchList;let key=index;">
											<div class="a-link no-styling">
												<app-product-list (favoriteResult)="favoriteResult($event)" (toProductDetail)="toProductDetail($event)" [itemIndex]="key" [productModel]="product" [searchPage]='true'></app-product-list>
											</div>
										</div>
									</ng-container>
                </div>
				
				<p class="list-bottom-text btn-top" *ngIf="!searchService.noData">
					<button class="more-btn" type="button" (click)="loadMore()">
						<div class="more-btn-bg" [ngStyle]="{'background': searchService.moreBackground}">
							<span class="more-text">
								<ng-contaier *ngIf="searchService.loading;else moreTpl">加载中</ng-contaier>
								<ng-template #moreTpl>
									更多商品
								</ng-template>
								<span id="productsLoaded"> ({{searchService.loadedCount}}/{{searchService.productNum}})</span>
							</span>
						</div>
					</button>
				</p>
				
				
              </div>
            </div>
          </div>
          <div class="searchpar parsys"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <p *ngIf="searchService.noData" class="list-bottom-text">已到页面底部</p> -->
<!-- 收藏结果弹窗 -->
<app-favorite-box *ngIf="showFavoriteBox" (closeFavoriteBox)="closeFavoriteBox()" [type]="favoriteType" [code]="favoriteCode"></app-favorite-box>
 