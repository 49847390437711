import {Component, Inject, Optional, PLATFORM_ID} from '@angular/core';
import {InitializeService} from './services/initialize.service';
import {SensorsTrackingService} from './services/sensors.service';
import {AbTrackingService} from './services/abtracking.service';
import $ from 'jquery';
import {isPlatformBrowser, isPlatformServer, Location} from '@angular/common';
import {distinctUntilChanged,filter, map, tap} from 'rxjs/operators';
import {NavigationEnd, Router,RouterEvent} from '@angular/router';
import {ScriptService} from './services/script.service';
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {Angulartics2GoogleGlobalSiteTag} from 'angulartics2/gst';
import {StorageService} from './services/storage.service';
import {Title} from '@angular/platform-browser';
import {BreadcrumbService} from './services/breadcrumb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers:[BreadcrumbService]
})
export class AppComponent {
  title = 'app';
  domain = '';
  visitedRoutes: string[] = [];
  constructor(
    public init: InitializeService,
    private localStorage: StorageService,
    public sensorsTracking: SensorsTrackingService,
    public abtracking: AbTrackingService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private location:Location,
    public script: ScriptService,
    @Optional() @Inject(REQUEST) private request: any,
	private titleService: Title,
    public gtags: Angulartics2GoogleGlobalSiteTag,
	private breadcrumbService: BreadcrumbService,
    ) {
    gtags.startTracking();
    if (isPlatformServer(this.platformId)) {
      this.domain = this.request.headers.host;
      init.storeConfiguration(this.domain);
    }
    if (isPlatformBrowser(this.platformId)) {
      this.domain = this.location['_platformStrategy']['_platformLocation'].location.hostname;
      const protocol = this.location['_platformStrategy']['_platformLocation'].location.protocol;
      const port = this.location['_platformStrategy']['_platformLocation'].location.port;
      init.storeConfiguration(this.domain, protocol, port);

      //会话启动时初始化tracking
      console.info("Init tracking、ab in AppComponent constructor ...");
      this.sensorsTracking.registerSensors();// sensors sdk init
      this.abtracking.registerAb();//abtest init
    }
    // 设置tracking js
    this.router.events
      .pipe(distinctUntilChanged((previous: any, current: any) => {
        if (current instanceof NavigationEnd) {
          //console.log('previous---'+previous.url)
          if(current.url.indexOf('404') !== -1){
            //记录上个页面路径
            this.localStorage.setItem('404_previous_page',previous.url);
          }
          return previous.url === current.url;
        }
        return true;
      }))
      .subscribe(
        (_event) => {
          // this.trackingInit();
        });
  }
	
	  ngOnInit() { 
		 //  this.router.events
			// .pipe(
			//   filter(event => event instanceof NavigationEnd),
			//   map(() => this.router.url),
			//   distinctUntilChanged()
			// )
			// .subscribe(url => {
			// 	this.visitedRoutes.push(url);
			// });
	  }
	  
  // trackingInit() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     this.script.load(this.init.trackingJs).then(data => {
  //       // const trackObject = new Tracking();
  //       // trackObject.trackingStart(location.pathname);
  //     }).catch(error => console.log(error));
  //   }
  // }

  onActivate(_event:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      $('html,body').show();
      $('html,body').animate({scrollTop: 0}, 500);
    }
  }

  onDeactivate(_event:any) {
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      $('html,body').hide();
    }
  }
}
