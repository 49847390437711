import {Component, OnInit} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  private pageName = '找回密码';

  constructor(public init: InitializeService) {
  }

  ngOnInit() {
    this.init.pageConfiguration(this.pageName);
  }

}
