<div class="mypoint">
  <div class="data-section integral">
    <div class="integral-label">
      <span class="pc-total total">积分总额：{{pointsInfo.pointsBalance	 ? pointsInfo.pointsBalance : 0}}</span>
      <span class="sp-total total">积分总额<span>{{pointsInfo.pointsBalance ? pointsInfo.pointsBalance : 0}}</span></span>
      <span class="expired" *ngIf="pointsInfo.expiringPoints !== null">{{pointsInfo.expiringPoints}}积分将在{{pointsInfo.expiringDate}}过期</span>
      <span class="use" *ngIf="pointsInfo.expiringPoints !== null">请尽快兑换使用</span>
      <a class="exchange">即刻兑换</a>
    </div>
  </div>
  <div class="data-section integral tab-title">
    <h3  class="pull-left acquired is-active" [ngClass]="{'is-active' : obtainedTab}" (click)="changeTab('obtainedPoints')"><a>已获得</a></h3>
    <h3 class="pull-left redeemed a-link" [ngClass]="{'is-active' : usedTab}" (click)="changeTab('usedPoints')"><a>已使用</a></h3>
  </div>
  <!--pc已获取积分详情-->
  <table width="680" aria-describedby="points" border="0" class="acquired tab-content pc-table" *ngIf="obtainedTab else usedPointsTpl">
    <tr class="title">
      <th scope="col">积分</th>
      <th scope="col">获取时间</th>
      <th scope="col">获取方式</th>
    </tr>

    <tr *ngFor="let pointsItem of pointsList"  [ngClass]="{'hide-invalid-item': pointsItem.transactionType.operator == 'Dec'}">
      <!--<td>{{pointsItem.afterPoints}}</td>-->
      <td>+{{pointsItem.increasePoints}}</td>

      <td>{{pointsItem.createdAt|date:'yyyy-MM-dd'}}</td>
      <td>{{pointsItem.transactionType.transactionTypeName}}</td>
    </tr>
  </table>
  <!--pc已兑换积分详情-->
  <ng-template #usedPointsTpl>
    <table width="680" border="0" aria-describedby="pc-points" class="redeemed tab-content pc-table" [ngClass]="{'hide-invalid-item': obtainedTab}">
      <tr class="title">
        <!--<td></td>-->
        <th scope="col">-积分</th>
        <th scope="col">兑换时间</th>
        <th scope="col">获取方式</th>
      </tr>
      <tr *ngFor="let pointsItem of pointsList" [ngClass]="{'hide-invalid-item': pointsItem.transactionType.operator == 'Inc'}">
        <!--<td></td>-->
        <td>-{{pointsItem.decreasePoints}}</td>
        <td>{{pointsItem.createdAt|date:'yyyy-MM-dd'}}</td>
        <td>{{pointsItem.transactionType.transactionTypeName}}</td>
      </tr>
    </table>
  </ng-template>

  <!--h5已获取积分详情-->
  <div *ngIf="obtainedTab else mbUsedPointsTpl">
    <table aria-describedby="mobile-points" width="100%" border="0" class="sp-tab-content" *ngFor="let pointsItem of pointsList" [ngClass]="{'hide-invalid-item': pointsItem.transactionType.operator == 'Dec'}">
      <tr>
        <!--<td width="50%" class="font-s">{{pointsItem.paramsSnapshot}}</td>-->
        <th width="50%" class="font-s" scope="col">+{{pointsItem.increasePoints}}</th>
      </tr>
      <tr>
        <td width="50%">{{pointsItem.createdAt|date:'yyyy-MM-dd'}}</td>
        <td width="50%">{{pointsItem.transactionType.transactionTypeName}}</td>
      </tr>
    </table>
  </div>

  <ng-template #mbUsedPointsTpl>
    <!--h5已兑换积分详情-->
    <table width="100%" border="0" aria-describedby="mobile-redeem-points"  class="sp-tab-content" *ngFor="let pointsItem of pointsList" [ngClass]="{'hide-invalid-item': pointsItem.transactionType.operator == 'Inc'}">
      <tr>
        <!--<td width="50%" class="font-s">{{pointsItem.paramsSnapshot}}</td>-->
        <th width="50%" class="font-s" scope="col">+{{pointsItem.decreasePoints}}</th>
      </tr>
      <tr>
        <td width="50%">{{pointsItem.createdAt|date:'yyyy-MM-dd'}}</td>
        <td width="50%">{{pointsItem.transactionType.transactionTypeName}}</td>
      </tr>
    </table>
  </ng-template>

</div>
