<div data-component="OPageContent" id="mobile_navbar" class="mobile_navbar col-span-full relative w-full gap-x-20 items-center justify-start lg-justify-center font_small_s_semibold px-15 pt-24 pb-36 lg-pt-68 md-px-20">
  <nav class="md-nav no-scrollbar mb-28 flex overflow-x-scroll px-1 font_small_s_semibold lg-justify-center lg-overflow-x-hidden lg-px-0">
    <a class="align-center flex shrink-0 p-12" [ngClass]="{'is-active': currentMenu === 'profile'}" routerLink="/my-account/profile">我的账号</a>
    <a class="align-center flex shrink-0 p-12" [ngClass]="{'is-active': currentMenu === 'coupon'}" routerLink="/my-account/coupon">我的优惠</a>
    <a class="align-center flex shrink-0 p-12" [ngClass]="{'is-active': currentMenu === 'order'}" routerLink="/my-account/order">我的订单</a>
    <a class="align-center flex shrink-0 p-12" routerLink="/my-account/favorite">心愿单</a>
  </nav>
  <div data-component="MDropdown" class="m-dropdown pdp-dropdown sp-menu font_s_regular" [ngClass]="{'is-open': showMbMenu}" id="mbNavbarMenu">
    <button type="button" class="a-button-nostyle placeholder menu" (click)="expandNavbar()" id="size" aria-label="filter expand/close" aria-haspopup="true" aria-expanded="false">
      <span id="selname">{{menuLists[currentMenu][currentindex]['name']}}</span>
    </button>
    <button type="button" class="a-button-nostyle a-icon-close" aria-label="close"></button>
    <input name="selectSize" type="text" class="js-input">
    <button type="button" class="a-button-nostyle nostyle2" (click)="expandNavbar()" role="button" aria-label="expand/close" tabindex="-1" style="margin-left: 7px; top: 3px;">
      <svg _ngcontent-official-site-c9="" class="inline h-16 w-16 fill-current"><title _ngcontent-official-site-c9="">Close</title><use _ngcontent-official-site-c9="" href="/assets/iconSprite.svg#chevron-down"></use></svg>
    </button>
    <div>
      <ul class="list-wrapper font_s_regular" style="margin-top: .5px;">
        <ng-container *ngIf="currentMenu">
          <li [ngClass]="{'is-active': currentindex == i}" *ngFor="let childNav of menuLists[currentMenu]; index as i">
            <a (click)="expandNavbar()" routerLink="{{childNav.path}}" target="_self" class="a-link menu-my-details">{{childNav.name}}</a>
          </li>
        </ng-container>
        <li>
          <a (click)="logout()" target="_self" class="a-link menu-my-favorite">退出</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="grid grid-cols-12 gap-x-15 md:gap-x-5 col-span-12 px-0">
    <div class="col-span-12 lg-col-start-2 lg-col-end-12" style="margin-bottom: 70px;">
      <hr class="border-divider">
      <div class="grid gap-x-15 md:gap-x-5 col-span-12 grid-cols-10  px-0" style="padding-top: 36px;">
        <div class="col-span-2 md-hidden">
          <ul style="margin-top: -7px;">
            <ng-container *ngIf="currentMenu">
              <li *ngFor="let childNav of menuLists[currentMenu]; index as i">
                <a class="font_s_regular flex items-center menu-block" [ngClass]="{'menu-bold':currentindex == i}" routerLink="{{childNav.path}}">{{childNav.name}}
                  <svg class="inline h-16 w-16 fill-current pb-[0.125rem]" *ngIf="currentindex == i">
                    <title>Arrow pointing right</title>
                    <use href="/assets/iconSprite.svg#chevron-mini-right"></use>
                  </svg>
                </a>
              </li>
            </ng-container>
            <li style="margin-top: 15px; width: 55px;">
              <a (click)="logout()" class="font_s_regular flex items-center menu-block" >退出
                <svg class="inline h-16 w-16 fill-current pb-[0.125rem]">
                  <title>退出</title>
                  <use href="/assets/iconSprite.svg#chevron-mini-right-end"></use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-span-10 mb-48 lg-col-span-8 lg-mb-0">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
