<!--PDP ADD CART-->
<div class="add_cart_container" *ngIf="h5_cartShow" style="transform: translateY(0%) translateZ(0px);" (click)="closeAddCart()">
  <div class="m-cart-addition">
    <div class="w-full content-wrapper u-clearfix cart-wrapper" style="transform: translateY(0%) translateZ(0px);">
      <div class="touch-none">
        <button aria-label="CLOSE" class="flex min-h-13 w-full items-center justify-center cursor-pointer focus:outline-none" type="button">
          <div class="touch-icon"></div>
        </button>
      </div>

      <div class="product-section">
        <div class="heading pl-20 py-15 mb-30">
          <h2 class="a-heading-2 font_small_s_semibold">已添加到购物袋</h2>
        </div>
        <span class="a-icon-close" (click)="closeAddCart()"></span>
        <section class="grid grid-cols-12">
          <div class="relative aspect-product-portrait col-span-3">
            <figure class="absolute bottom-0 left-0 right-0 top-0">
              <img class="object-cover transition-opacity duration-300 opacity-100" src="{{addCartInfo?.img_url}}" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;"/>
            </figure>
          </div>
          <div class="font_xs_regular flex w-full flex-col p-20 col-span-8 gap-8 sm-col-span-9">
            <div class="flex col-start-1 items-center gap-8 justify-between overflow-hidden" style="height: 18px;">
              <h3 style="line-height: 18px;" class="truncate font_s_regular lg-font_m_regular" data-testid="heading" title="STRAIGHT-LEG UTILITY PANTS">{{addCartInfo?.name}}</h3>
              <div>
                <span class="whitespace-nowrap text-right font_small_xs_regular text-main item-price" [ngClass]="{'is-reduced': addCartInfo?.price > 0}">{{init.formatPrice(addCartInfo?.salesPrice) | currency:currency}}</span>
                <span class="whitespace-nowrap text-right font_small_xs_regular text-main is-deprecated item-price ml-8" *ngIf="addCartInfo?.price != 0">{{init.formatPrice(addCartInfo?.price) | currency:currency}}</span>
              </div>
            </div>
            <span class="row-start-2">{{addCartInfo?.color}}</span>
            <span class="row-start-3 font_s_regular font_small_l_regular">{{checkout.sizeConvert(addCartInfo?.size)}}</span>
          </div>
        </section>
        <div class="btn_cart p-20">
          <a class="a-button is-primary" (click)="closeMiniCart('cart')">去购物袋</a>
        </div>
        <ng-container *ngIf="recommendLists.length > 0">
          <div class="recommend-container">
            <app-recommend-common [recommendLists]="recommendLists" [count]="3" (quickAdd)="quickAdd($event)"></app-recommend-common>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!--PDP ADD CART END-->
