import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-account-logout',
  templateUrl: './account-logout-error.component.html',
  styleUrls: ['./account-logout-error.component.css']
})

export class AccountLogoutErrorComponent implements OnInit {
  public errorMsg = '';
  public customerInfo: any = {};
  public nextStepUrl = '';
  constructor(public init:InitializeService,
              private router:Router,
              @Inject(PLATFORM_ID) private platformId:Object,
              private localStorage:StorageService) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.errorMsg = this.localStorage.getItem('logout_error_msg');
      if (!this.init.isLogin() || this.errorMsg === null) {
        this.router.navigate(['']);
      }
    }
    this.localStorage.removeItem('logout_error_msg');
    this.init.pageConfiguration('注销失败');
  }

}
