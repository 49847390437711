import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {CallComponentService} from '../../../services/callcomponent.service';
import {ProductService} from '../../../services/product.service';
import $ from 'jquery';
export const OUT_OF_SOON = 3;

@Component({
  selector: 'app-product-size-bar',
  templateUrl: './product-size-bar.component.html',
  styleUrls: ['./product-size-bar.component.css']
})
export class ProductSizeBarComponent implements OnInit {
  @Input() list: Size[] = [];
  @Input() frontCustomParameters:any;
  @Input() colorId!: string;
  @Input() comingSoon!:boolean;
  @Input() sizeChartHtml:any;
  @Input() hideSizeChart!: boolean;
  @Input() sizeTableImageUrl:any;
  @Input() arrivalRemindDisplay!:boolean;
  @Input() mAddToBagScrollStopped!:boolean;
  @Output() change = new EventEmitter<object>();
  @Output() changeButtonLock = new EventEmitter<object>();

  size!: Size;
  hover!: string;
  remove:any = false;
  outOfStock!: boolean;
  show_modal:any = false;
  uniqueSize:boolean = false;
  finalSizeImageUrl :any;
  showOldSizeHtml:boolean = false;
  showMeasureMethod:boolean = false;
  measurePic!:string;//测量图
  specialMeasurePic!:string;
  howToMeasurePic!:string;//如何测量图（默认收缩）
  specialHowToMeasurePic!:string;
  sizeChartPic!:string;//尺码表
  specialSizeChartPic!:string;
  tryonPic!:string;//试穿报告、
  specialTryonPic!:string;
  recommendPic!:string;//尺码推荐表
  specialRecommendPic!:any;
  hasSizeGuide:boolean = false;
  
  constructor(public callComponentService: CallComponentService,
	public productService: ProductService,) {

  }
  ngOnInit() {
	if(this.sizeTableImageUrl !== null && this.sizeTableImageUrl !== ''){
		this.finalSizeImageUrl = this.sizeTableImageUrl.replace('http://img.cos.client.efashionchina.com','https://www.cos.cn/img');
	}
    if (this.list.length === 1) {
      this.uniqueSize = true;
      this.onSelect(this.list[0]);
      //this.oneSizeDefault(this.list[0]);
      this.remove = true;
    } else if (this.list.length > 1) {
      this.list.sort(function(a:any, b:any) {
        return a['sortOrder'] - b['sortOrder'];
      });
    }
	if(!!this.frontCustomParameters){
		var option = this.frontCustomParameters;
		// 测量图
		this.measurePic = !!option.measurePic ? option.measurePic : '';
		// 如何测量图（默认收缩）
		this.howToMeasurePic = !!option.howToMeasurePic ? option.howToMeasurePic : '';
		// 尺码表
		this.sizeChartPic = !!option.sizeChartPic ? option.sizeChartPic : '';
		// 试穿报告
		this.tryonPic = !!option.tryonPic ? option.tryonPic : '';
		//尺码推荐表
		// this.recommendPic = option.recommendPic;
		if((!!this.measurePic || !!this.howToMeasurePic || !!this.tryonPic) || (!!this.sizeChartPic && this.getImageHeight(this.sizeChartPic) > 60)){
			this.hasSizeGuide = true;
		}
	}
    // 订阅尺码label is-disable是否显示
    this.callComponentService.changeSizeLabel.subscribe((value:any) => {
      this.remove = value;
    });
  }

	getImageHeight(url:any){
		var img = new Image();
		img.src = url;
		// 如果图片被缓存，则直接返回缓存数据
		if(img.complete){
			return img.height;
		}else{
		  // 完全加载完毕的事件
			img.onload = function(){
				return img.height;
			}
		}
		return 0;
	}
  
	operateMeasureMethod(){ 
		this.showMeasureMethod = !this.showMeasureMethod;
	}
  // Onchenge更改即将售罄为false
  ngOnChanges() {
    this.outOfStock = false;
  }

  closeFloatSize() {
    $('#floatSize').hide();
    $('body').removeClass('u-overflow-hidden');
    $('#productSizeBar').removeClass('m-float-size-container');
    $('#productAOverlay').removeClass('q-opacity-95 q-bg-grey-light is-visible');
    $('#productAOverlay').addClass('q-is-menu-overlay');

    $('html,body').removeClass('ovfHidden');

    if(this.mAddToBagScrollStopped){
      $('#mAddToBagFloat').removeClass('cta-scroll-container');
    }
  }

  onSelect(s: Size) {
    //if (Number(s.qty) !== 0) {
    this.size = s;
    const size_info = this.size;
    this.remove = true;
    this.changeButtonLock.emit();
    this.change.emit(size_info);
    this.outOfStock = this.isSoldOutSoon(this.size);
    this.closeFloatSize();
    //}
  }

  //均码默认不选中
  oneSizeDefault(s: Size){
    const size_info = s;
    this.change.emit(size_info);
    this.outOfStock = this.isSoldOutSoon(size_info);
  }

  isSoldOut(s: Size) {
    return +s.qty <= 0;
  }

  isSoldOutSoon(s: Size) {
    const qty = +s.qty;
    return qty <= OUT_OF_SOON && qty > 0;
  }

  openSizeModal() {
    $('body').addClass('u-overflow-hidden');
	var sizeGuideTemplate:any = $('#size-guide-template-image');
	if(sizeGuideTemplate.height() < 60){
	  this.showOldSizeHtml = true;
	}
    this.show_modal = true;
    this.change.emit({'show_modal': true});
  }

  closeOverlay() {
    this.show_modal = false;
    $('body').removeClass('u-overflow-hidden');
    this.change.emit({'show_modal': false});
  }
}

interface Size {
  skuCode: string;
  valueName: string;
  qty: number;
  skuId: number;
  barcode: string;
  valueId: number;
  propertyName: string;
}
