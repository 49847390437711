import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InitializeService} from '../../../services/initialize.service';
import {RequestService} from '../../../services/request.service';
import {Router} from '@angular/router';
import {CheckoutService} from '../../../services/checkout.service';
import {TrackingcodeService} from '../../../services/trackingcode.service';
import {AbTrackingService} from '../../../services/abtracking.service';
import {StorageService} from '../../../services/storage.service';
import {CheckoutModel} from '../../../models/checkout.model';
import {CartService} from '../../../services/cart.service';
import {OrderService} from '../../../services/order.service';
import {Angulartics2} from 'angulartics2';
const apiConfig  = require('../../../config/config.api.json');

@Component({
  selector: 'app-checkout-order',
  templateUrl: './checkout-order.component.html',
  styleUrls: ['./checkout-order.component.css']
})
export class CheckoutOrderComponent implements OnInit {
  // 得到父组件数据；
  public submitBtn: boolean | undefined;
  @Input() set getDataParent( parentData: boolean) {
    this.submitBtn = parentData;
  }
  @Input() checkoutType: any;
  @Input() orderRemark: any;
  public weChatBrowser: boolean | undefined;
  public flog: Boolean = false;
  public ordersuccess: CheckoutModel | undefined;
  public paragraph: string | undefined;
  public promotion: Boolean = false;
  public cartItems = {orderId: '', products: []};
  public checkTextRs = {orderId: '', products: []};

  constructor(public init: InitializeService,
              private request: RequestService,
              public cart: CartService,
              public order: OrderService,
              private router: Router,
              public checkout: CheckoutService,
              public tracking: TrackingcodeService,
              public abTracking: AbTrackingService,
			        private angulartics2: Angulartics2,
              private localStorage: StorageService) {
  }

  ngOnInit() {
    this.flog = true;
    //判断微信浏览器
    const ua = navigator.userAgent.toLowerCase();
    this.weChatBrowser = (/MicroMessenger/i.test(ua));
    //if(this.weChatBrowser){
    //  this.flog = true;
    //  //this.submitBtn = false;
    //}
  }

  submitOrder() {
    //记录订单备注
    const remarksText = this.orderRemark.remark;
    let setRemarkPromise;
    if(remarksText !== '' && remarksText !== null && remarksText !== undefined){
      //校验敏感字
      //因接口不支持store_id为10 故按小程序店铺id传
      const checkRemark = apiConfig.activationCodeService + 'customer/check/content?content='+remarksText+'&store_id=8';
      const paramsJson:any = {};
      setRemarkPromise =  new Promise((resolve, _reject) => {
        this.request.post(checkRemark, paramsJson, false, false, true).toPromise().then(checkRes => {
          resolve(checkRes);
        }).catch((error:any) => {
          return error;
        });
      })
    }else{
      setRemarkPromise = new Promise((resolve, _reject) => {
        resolve({code: 0, msg: ''});
      });
    }
    let cartKey = '';
    if(this.checkoutType == 'presale'){
      cartKey = this.localStorage.getItem('PRESALE_CART_KEY');
    }else{
      cartKey = this.localStorage.getItem('quote_id');
    }
    let saveRemarkPromise;
    setRemarkPromise.then((res : any) => {
      if (res['code'] === 0) {
        const remarkParams = {'buyerRemark': remarksText};
        const setRemark = apiConfig.cloudCart + '/customer/sales_cart/' + cartKey + '/checkout/buyer_remark';
        saveRemarkPromise = new Promise((resolve, _reject) => {
          this.request.put(setRemark, remarkParams).toPromise().then(remarkRes => {
            resolve(remarkRes);
          }).catch((error:any) => {
            return error;
          });
        });
        saveRemarkPromise.then(_res => {
          let codeResult: any = {code: '', msg: '', data: ''};
          const addOrderUrl = apiConfig.cloudSalesOrder + 'customer/sales_order/place_order';
          this.flog = false;
          const paramsJson:any = {'cartKey': cartKey}
          this.checkout.getCheckoutData(this.checkoutType).then( cartData => {
            const checkoutModule: CheckoutModel = cartData;
            this.ordersuccess = cartData;

            // 记录扫码门店信息
            let setPromise;
            const currentTimeStamp =  new Date().getTime();
            const customer_utm_source_timestamp = this.localStorage.getItem('customer_utm_source_timestamp') ? this.localStorage.getItem('customer_utm_source_timestamp') : 0;
            if (this.localStorage.getItem('customer_utm_source') && this.localStorage.getItem('customer_utm_source') != '' && this.localStorage.getItem('customer_utm_source') != 'undefined' && currentTimeStamp <= (customer_utm_source_timestamp + 86400 * 7 * 1000)) {
              setPromise =  this.cart.setUtm({uid: this.ordersuccess ? this.ordersuccess.customerId : 0, utmSource: this.localStorage.getItem('customer_utm_source'), utmMedium: this.localStorage.getItem('customer_utm_medium')});
            } else {
              setPromise = new Promise((resolve, _reject) => {
                resolve({code: 1000, msg: ''});
              });
            }
            setPromise.then((res : any) => {
              if (res['code'] === 1000) {
                const payType = checkoutModule.payType;
                this.request.post(addOrderUrl, paramsJson, false, false, true).toPromise().then(val => {
                  codeResult = val;
                  if (codeResult.code === 1000) {
                    this.flog = true;
                    this.localStorage.setItem('payType', payType);
                    const check_items = this.checkout.checkoutModel.checkedItems();

                    // 老版Ga Tracking Js
                    // 向google 推送下单数据
                    if (this.init.gaTrackingFlag) {
                      const items = [];
                      if (check_items.length > 0) {
                        for (const i in check_items) {
                          items.push({
                          id: check_items[i].skuCode,
                          name: check_items[i].itemName,
                          brand: 'COS',
                          price: check_items[i].markingPrice,
                          quantity: check_items[i].qty,
                          currency: 'CNY'
                          });
                        }
                      }
                      this.angulartics2.eventTrack.next({
                        action: 'purchase',
                        properties: {
                          gstCustom: {
                          transaction_id: codeResult.data.orderCode,
                          value: checkoutModule.payment,
                          shipping: checkoutModule.postFee,
                          coupon: checkoutModule.couponCode,
                          items: items,
                          currency: 'CNY'
                          }
                        }
                      });
                    }
                    this.abTracking.trackingOrder( 'ORDER_PAY', codeResult.data.orderId,check_items, checkoutModule.payment, checkoutModule.postFee, checkoutModule.discountFee, checkoutModule.itemTotal, {path: this.router.url});
                    this.cart.getCartData().then( _data => {
                      this.router.navigate(['/checkout/payment/' + codeResult.data.orderId]);
                    }).catch(error => error);
                    // this.order.createOrderLog({'userAgent': navigator.userAgent, 'userName': 'COS:PDP'}, codeResult.data.orderId).then(res => {
                    //   this.cart.getCartData().then( data => {
                    //     this.router.navigate(['/checkout/payment/' + codeResult.data.orderId]);
                    //   }).catch(error => error);
                    // });
                    return false;
                  } else {
                    this.flog = false;
                    this.promotion = true;
                    this.paragraph = codeResult.msg === 'The channel is forbidden.' ? '该券不可在官网使用' : codeResult.msg;
                    return false;
                  }
                }).catch(error=>{
                  if(error.status == 500 || error.status == 504){
                    this.router.navigate(['/busy']);
                  }
                });
                return;
              } else {
                this.flog = false;
                this.promotion = true;
                this.paragraph = res['msg'];
                return false;
              }
            });
          });
        });
      }else{
        alert('包含敏感词，请修改');
      }
    });
    return false;
  }
  closeOverlay() {
    this.promotion = false;
    this.flog = true;
    if(this.ordersuccess){
      const items = this.ordersuccess.checkedItems;
      if (items.length <= 0) {
        this.router.navigate(['/cart']);
        return;
      }
    }
  }
}
