<div class="u-cols-lg-11-24 u-cols-md-6-12 u-cols-sm-12-12 u-no-padding" v-if="!isPc">
  <div class="images">
    <div id="productGallery" data-component="OProductGallery" class="o-product-gallery swiper-initialized">
      <div class="slider-wrapper mob_leftImg">
		<div class="text">COS by you</div>
        <div class="o-slider swiper-container" [swiper]="mbSwiperConfig" *ngIf="imageList.length > 1 else otherTpl;">
          <div class="slider swiper-wrapper">
            <ng-container  *ngFor="let img of imageList;index as i;">
				<!-- [queryParams]="{picid:img.picId}" routerLink="/cosbyyou/image-detail" -->
                <a class="a-link swiper-slide">
                  <div class="swiper-zoom-container">
                    <img class="a-image default-image is-selected" src="{{img.picUrl}}" alt="图片">
					<p class="cosByyou-text2">{{img.subjectDesc ? img.subjectDesc : ''}}{{img.nick ?  '@'+img.nick : ''}}</p>
                  </div>
				  
                </a>
            </ng-container>
          </div>
      </div>
	  <ng-template #otherTpl>
			<ng-container  *ngFor="let img of imageList;index as i;">
			  <a class="a-link">
				<div class="swiper-zoom-container">
				  <img class="a-image default-image is-selected" src="{{img.picUrl}}" alt="图片">
				<p class="cosByyou-text2">{{img.subjectDesc ? img.subjectDesc : ''}}{{img.nick ?  '@'+img.nick : ''}}</p>
				</div>
			  </a>
			</ng-container>
	  </ng-template>
      </div>
    </div>
  </div>
</div>
