import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';
import {AgreementService} from '../../services/agreement.service';

@Component({
  selector: 'app-agreement-redirect',
  templateUrl: './agreement-redirect.component.html',
  styleUrls: ['./agreement-redirect.component.css']
})
export class AgreementRedirectComponent implements OnInit{
  public phoneNumber!: number;
  public tailNumber! :number;
  public agreementCode!:String;
  public timer : any;
  sendPhoneLabel = '点击发送';
  timerEffect: any;
  phoneCaptchaDisabled:boolean = false; // 发送验证码禁用
  phoneCaptchaValue!: string;
  verifyCodeErrorMsg!: string;
  verifyCodeError:boolean = false;

  constructor(
    public router: Router,
    public routerInfo: ActivatedRoute,
    private agreementService: AgreementService,
    private init: InitializeService,
    private localStorage:StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.init.setTitle('授权跳转');
    if (isPlatformBrowser(this.platformId)) {
      //if(this.init.IsPC()){
      //  this.router.navigate(['/']);
      //}else{
        let agreementCode =  this.routerInfo.snapshot.queryParams['agreement_code'];
        this.agreementCode = agreementCode;
        const params = {path:'pages/agreement/smsVerification',query:'agreement_code='+agreementCode};
        this.agreementService.getPhoneNumber(agreementCode).then(val => {
          let result: any = {code: '', msg: '',data:''};
          result = val;
          if(result.code == 1000){
            this.phoneNumber = result.data.mobile;
            this.tailNumber = result.data.mobile.substr(7,4);
          }
        });

        //以下为根据agreement_code获取小程序短链接程序
        //let appId = 'wxad2ee6fa2df2c46d';//测试环境使用奕尚体验版appid wxad2ee6fa2df2c46d，正式环境需更换成COS小程序appid wxcc292543eb62b3f4
        //const params = {path:'pages/agreement/smsVerification',query:'agreement_code='+agreementCode};
        //let result: any = {code: '', msg: '',data:''};
        //this.customer.schemeGenerate(params,appId).then(val => {
        //  result = val;
        //  if(result.code == 1000){
        //    console.log(result.data.openlink);
        //    if(result.data.openlink !== '' && result.data.openlink !== null){
        //      location.href = result.data.openlink;
        //    }
        //  }else{
        //    this.router.navigate(['/']);
        //  }
        //})
      //}
    }
  }

  sendVerifyCode(){
    const that = this;
    let int = 59;
    const timerEffect =  setInterval(function () {
      that.sendPhoneLabel = '已发送(' + int-- + 's)';
      if (int <= 1) {
        that.sendPhoneLabel = '点击发送';
        that.phoneCaptchaDisabled = false;
        clearInterval(timerEffect);
      }else{
        that.phoneCaptchaDisabled = true;
      }
    }, 1000);
    that.timerEffect = timerEffect;

    that.agreementService.agreementSmsCode(that.agreementCode).then(val => {
      let result: any = {code: '', msg: '',data:''};
      result = val;
      if(result.code == 1000){

      }else{
        alert('短信发送失败');
      }
    });
  }

  validateCode(){
    const that = this;
    if (that.phoneCaptchaValue) {
      let result: any = {code: '', msg: '',data:''};
      that.agreementService.validateSmsCode(that.agreementCode,that.phoneCaptchaValue).then(val => {
        result = val;
        if(result.code == 1000){
          this.localStorage.setItem('agreement_data', JSON.stringify(result.data));
          this.router.navigate(['/agreement-authorization']);
        }else{
          that.verifyCodeError = true;
          that.verifyCodeErrorMsg = result.msg;
        }
      });
    }

  }

}
