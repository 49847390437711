import {Component, EventEmitter,Inject, Input, Output,OnInit,OnChanges , PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../services/initialize.service';
import {ProductService} from '../../services/product.service';
import {
  SwiperConfigInterface,
  SwiperNavigationInterface
} from 'ngx-swiper-wrapper';

const navigationConfig: SwiperNavigationInterface = {
  nextEl: '.swiper-next',
  prevEl: '.swiper-prev',
  hideOnClick: true,
  // disabledClass?: string;
  // hiddenClass: 'swiper-button-hidden',
};

@Component({
  selector: 'app-recommend-style',
  templateUrl: './recommend-style.component.html',
  styleUrls: ['./recommend-style.component.css'],
})
export class RecommendStyleComponent implements OnInit,OnChanges {
  @Input()
	recommendWhole!: Array<any>;
  @Input()
	recommendSimilar!: Array<any>;
	@Input() favoriteItemIds = [];
	@Output() addFavorite = new EventEmitter<object>();
	@Output() delFavorite = new EventEmitter<object>();
	@Output() quickAdd = new EventEmitter<any>();
	
  config!: SwiperConfigInterface;
  public pioString = "?pk_campaign=pio-pdp";
  public showHoverProductId!: string;
  public hoverProductId!: string;
  public favoriteIds : any = [];
  public currency:any;
  constructor(
    public init: InitializeService,
	public productService: ProductService,
    @Inject(PLATFORM_ID) private platformId: Object) {
  }
	ngOnChanges() {
		this.favoriteIds = this.favoriteItemIds;
	}
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.config = {
        speed: 1000,
        direction: 'horizontal',
		threshold:1,
        // loop: true,
        keyboard: true,
        pagination: false,
        scrollbar: false,
        centeredSlides: false,
        slidesPerView: this.init.IsPC() ? 4 : 2.25,
        slidesPerGroup: this.init.IsPC() ? 4 : 2,
        autoHeight: true,
        roundLengths: true,
        navigation: navigationConfig,
      };

	  if(!!this.favoriteItemIds){
		  this.favoriteIds = this.favoriteItemIds;
	  }
	  this.currency = this.init.currency;
    }
  }
  
  /**
   * 客户端判断 PC or Mobile
   * @returns {boolean}
   * @constructor
   */
  isPC(): boolean {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  imageMouseover(showHoverProductId: string) {
    this.showHoverProductId = showHoverProductId;
	this.hoverProductId = showHoverProductId;
  }

  imageMouseleave() {
    this.showHoverProductId = '';
	this.hoverProductId = '';
  }
  
  appendFavorites(item : any){
		this.addFavorite.emit({'itemCode':item.frontCode,'item':item,'recommend':true});
  }

  removeFavorites(item : any){
  	  this.delFavorite.emit({'item':item,'recommend':true});
  }
  
  selectSizeClick(item:any, e: Event){
	var salePrice:any;
	if(!!item.strategies && this.filterStrategyPrice(item.strategies) > 0){
		salePrice = this.filterStrategyPrice(item.strategies);
	}else if(item.markingPrice !== item.price){
		salePrice = item.price;
	}
 
    e.stopPropagation();
    this.quickAdd.emit({'frontCode':item['frontCode'],'salePrice':salePrice});
  }
  
  //筛选有效期内的策略价标签
  filterStrategyPrice(strategies:any){
	  if(!!strategies){
	  	var rulePriceJson:any = this.productService.strategyTag(strategies)
	  	//显示划线价
		if(rulePriceJson.hasRule){
			return rulePriceJson.price !== 0 ? rulePriceJson.price : 0;
		}else{
			if(!!rulePriceJson.rulesArr && rulePriceJson.rulesArr.length > 0){
				return rulePriceJson.rulesArr[0].strategyPrice;
			}	 
		}
	  }
	  return 0;
  }


}
