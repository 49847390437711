<!-- 提示文字弹层 -->
<div class="textTopup" *ngIf="showBox">
	<h2 *ngIf="result.type == 'add' && result.code == '1000';else remove">已成功添加至心愿单</h2>
	<ng-template #remove>
		<h2>已从心愿单中移除</h2>
	</ng-template>
	<div class="icon_close" (click)="closePop()">
		<svg><title>关闭</title><use href="/assets/iconSprite.svg#close"></use></svg>
	</div>
</div>

