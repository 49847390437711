
    <div class="form-group">
      <label for="inputTitle">自定义分类名称</label>
      <input [(ngModel)]="block.data.categoryTitle" type="text" class="form-control" id="inputTitle"
             placeholder="inputTitle">
    </div>
    <div class="form-group">
      <label for="inputCid">请填写分类ID</label>
      <input [(ngModel)]="block.data.cid" type="text" class="form-control" id="inputCid"
             placeholder="cid">
    </div>
    <div class="radio">
      <label>
        <input type="radio" name="rows" [(ngModel)]="block.data.config.rows" id="optionsRadios1"
               value="threeLines" checked>
        三个一行
      </label>
    </div>
    <div class="radio">
      <label>
        <input type="radio" name="rows" [(ngModel)]="block.data.config.rows" id="optionsRadios2"
               value="fourLines">
        四个一行
      </label>
    </div>
    <div class="form-group">
      <label for="productCount">总商品展示数量</label>
      <input [(ngModel)]="block.data.count" type="text" class="form-control" id="productCount"
             value="12" placeholder="Product Count">
    </div>
  