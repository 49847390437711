<!-- 弹层：快速加购 -->
<ng-container *ngIf="showBox">
	<div class="pageTopLayer">
		<div aria-hidden="true" class="pageTopLayer-bg"  (click)="closeLayer()"></div>
			<app-product-size-box
			[list]="productSizeArray[recProductModel.valueId]"
			[showVideo]="showVideo"
			[includeVideo]="includeVideo"
			[productModel]="recProductModel"
			[showItemInfo]="true"
			(change)="changeSize($event)"
			(closeLayer)="closeLayer()"
			[ImageArray]="colorImgList"
			[hideSizeChart]="hideSizeChart"
			[frontCustomParameters]="frontCustomParameters"
			[sizeChartHtml]="recProductModel?.pdp_sizechart"
			[sizeTableImageUrl]="recProductModel?.sizeTableImageUrl"
			[frontCode]="frontCode"
			[salePrice]="salePrice"
			[favoriteItemIds]="favoriteItemIds"
			(addFavorite)="appendFavorites($event)"
			(delFavorite)="removeFavorites($event)"
		  ></app-product-size-box>
  </div>
</ng-container>


<!-- 显示优惠券弹框：加is-select-country is-open -->
<div style="z-index: 1100;" class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': promotion}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div style="z-index: 1100;" data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="promotion">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 优惠券弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{paragraph}}</p>
    </div>
    <!-- 优惠券弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
