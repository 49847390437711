<div class="">
  <button *ngIf="weChatBrowser" class="a-button is-submit-primary submit-btn" (click)="submitOrder()"><span>提交订单</span></button>
  <button *ngIf="!weChatBrowser" class="a-button is-primary submit-btn" (click)="submitOrder()" [disabled]="flog===false || submitBtn=== true"><span>提交订单</span></button>
</div>
<!-- 显示弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': promotion}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="promotion" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false h5-error-text">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{paragraph}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
