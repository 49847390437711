import {RequestService} from './request.service';
import {Injectable} from '@angular/core';

const apiConfig = require('../config/config.api.json');
@Injectable()
export class PaymentService {
  constructor(
    private request: RequestService) {
  }
  createPayment(params:any): Promise<any> {
    return new Promise( (resolve) => {
      this.request.post(apiConfig.cloudSalesOrder+"wosai_payment/unified_order", params).toPromise().then(result => {
        resolve(result);
      }).catch(error => error);
    });
  }
	//H5端发起微信支付
	createMobilePayment(params:any): Promise<any> {
	  return new Promise( (resolve) => {
			this.request.post(apiConfig.cloudSalesOrder+"customer/aggregation_payment/unified_order", params).toPromise().then(result => {
			  resolve(result);
			}).catch(error => error);
	  });
	}

  getPaymentIp():Promise<any>{
    return new Promise((resolve) => {
      let url =  apiConfig.payemntClientIp;
      this.request.get(url).toPromise().then(result => {
        resolve(result);
      }).catch(error => error);
    });
  }

  getPayment(orderId:any): Promise<any> {
    return new Promise((resolve) => {
      // let url = apiConfig.cloudSalesOrder + 'wosai_payment/' + orderId + '/status/customer'
	  let url = apiConfig.cloudSalesOrder + 'customer/aggregation_payment/' + orderId + '/status/customer'
      this.request.get(url).toPromise().then(result => {
        resolve(result);
      }).catch(error => error);
    });
  }
  checkPayment(incrementId:any): Promise<any> {
    return new Promise((resolve) => {
      const time = new Date().valueOf();
      let url = apiConfig.payment + '?increment_id=' + incrementId;
      url += '&' + time;
      this.request.get(url).toPromise().then(result => {
        resolve(result);
      }).catch(error => error);
    });
  }
  confirmPay(incrementId:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.payment + '?orderId=' + incrementId).toPromise().then(result => {
        resolve(result);
      }).catch(error => error);
    });
  }

  //completeTracking():Promise<any>{
  //  return new Promise((resolve) => {
  //    this.request.get(apiConfig.+'merchant_class_item/class_name?item_id=18576&merchant_id="+')
  //  })
  //
  //}
}


