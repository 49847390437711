<div class="o-page-content cofs-page" style="margin-top:0">
  <div class="rma_success">
    <h4><em class="icoBg"></em>提交成功，请等待审核</h4>
    <p class="orderNumber">退货单号：{{rma_increment_id}}-{{rma_passcode}}&nbsp;&nbsp;<span><a [routerLink]="['/my-account/rma-detail']" [queryParams]="{id: rma_id}" class="a-link">查看详情</a></span></p>

    <div class="ramAddress">
      <strong class="is-black">退货地址：</strong>
      <p>江苏省苏州市昆山市新生路718号，江苏富莱德仓储有限公司物流园一期B幢仓库B-1单元  COS项目组（请不要发到付件和平邮，仓库无法收件）</p>
      <p>邮编：215300</p>
      <p>售后热线：400-015-1365</p>
    </div>
    <div class="bottom-section text-left pull-left">
      <button class="a-button is-primary" [routerLink]="['/my-account/rma-return']" [queryParams]="{id: rma_id}">填写退货快递单号</button>
      <a routerLink="/" class="a-link">继续购物</a>
    </div>
    <div class="bottom-section-tips">请在快递取件后填写</div>
  </div>
</div>
