import {Injectable} from '@angular/core';

@Injectable()
export class ResetpasswordService {
  resetMobile = '';

  constructor() {
  }

}
