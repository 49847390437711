import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from './storage.service';
import {RemoteServer} from '../config/config.remoteServer';
import {InitializeService} from './initialize.service';
import {HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CmsAdvancedPageModel } from '../models/cms.advanced.page.model';

let headers = new HttpHeaders();
@Injectable()
export class RequestService {

  constructor(
    private httpClient: HttpClient,
    private localStorage: StorageService,
    private HOST: RemoteServer,
    private init: InitializeService,
  ) {
  }

  /**
   * Get method
   * @param url
   * @returns {Observable<Object>}
   */
  public get(url: string | string[]): Observable<object> {
    let host = this.HOST.BASIC_HOST;
    const token = this.localStorage.getItem('jwt');
    if (token !== null) {
      headers = headers.set('jwt', token);
    } else {
      headers = headers.delete('jwt');
    }
    if (url.indexOf('recommender') != -1) {
      headers = headers.set('store', this.init.cloudStoreId.toString());
    } else {
      if (this.init.storeId !== '') {
        headers = headers.set('store', this.init.storeId);
      }
    }
    return this.httpClient.get(host + url, {headers});
  }

  /**
   * Get method
   * @param url
   * @returns {Observable<Object>}
   * @deprecated 请使用Get方法，此方法不推荐
   */
  public list(url: string | string[]): Observable<object> {
    return this.get(url);
  }

  /**
   * Post method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   */
  public post(url: string | string[], data: string | boolean | HttpParams | CmsAdvancedPageModel, customize: any = false, basic: any = false, is_json: any = false ): Observable<object> {
    const token = this.localStorage.getItem('jwt');
    if (customize === false) {
      if (token !== null) {
        headers = headers.set('jwt', token);
      } else {
        headers = headers.delete('jwt');
      }

      if (url.indexOf('recommender') != -1) {
        headers = headers.set('store', this.init.cloudStoreId.toString());
      } else {
        if (this.init.storeId !== '') {
          headers = headers.set('store', this.init.storeId);
        }
      }
    } else {
      headers = headers.set('uuid', customize[0].uuid);
    }

    if ( is_json ) {
      headers = headers.set( 'Content-Type', 'application/json' );
    }

    if ( basic === false ) {
      basic = this.HOST.BASIC_HOST;
    }

    return this.httpClient.post(basic + url, data, {headers});
  }

  /**
   * Post method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   * @deprecated 请使用Post方法，此方法不推荐
   */
  public save(url: string, data: HttpParams): Observable<object> {
    return this.post(url, data);
  }

  /**
   * Put method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   */
  public put(url:any, data:any, is_json = false): Observable<object> {
    headers = new HttpHeaders();
    const token = this.localStorage.getItem('jwt');
      if (token !== null) {
        headers = headers.set('jwt', token);
      } else {
        headers = headers.delete('jwt');
      }

    if (url.indexOf('recommender') != -1) {
      headers = headers.set('store', this.init.cloudStoreId.toString());
    } else {
      if (this.init.storeId !== '') {
        headers = headers.set('store', this.init.storeId);
      }
    }

    if ( is_json ) {
      headers = headers.set( 'Content-Type', 'application/json' );
    }
    return this.httpClient.put(this.HOST.BASIC_HOST + url, data, {headers});
  }

  /**
   * Put method
   * @param url
   * @param data
   * @returns {Observable<Object>}
   * @deprecated 请使用Put方法，此方法不推荐
   */
  public update(url:any, data:any): Observable<object> {
    return this.put(url, data);
  }

  /**
   * Delete method
   * @param url
   * @param id
   * @returns {Observable<Object>}
   */
  public delete(url:any, id:any): Observable<object> {
    const token = this.localStorage.getItem('jwt');
    if (token !== null) {
      headers = headers.set('jwt', token);
    }
    if (this.init.storeId !== '') {
      headers = headers.set('store', this.init.storeId);
    }
    return this.httpClient.delete(`${this.HOST.BASIC_HOST + url}/${id}`, {headers});
  }

}
