import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
const localConfig = require('../config/config.local.json');
const apiConfig = require('../config/config.api.json');

@Injectable()
export class OrderService {
  public  currentPage: number = 1;
  public  pageSize: number = 10;
  public  orders: object =  {data: {}, code: '', message: ''};

  // 用于状态显示和在页面判断的参数定义
  public showSortFlag:any = false;
  public orderStatus: string = '查看全部';
  // 用于月份选择显示和在页面判断的参数定义
  public showMonthFlag:any = false;
  public showOrderFlag:any = false;
  public sortMonth: string = '1个月';

  // 传输到url的参数定义
  public urlType: string = 'all';
  public urlMonth: number = 1;
  public getCloudOrderListUrl:any;
  orderStateTranslateArr:any = {pending_payment: '未付款', pending: '已支付', shipping: '配送中', complete: '已完成', closed: '已关闭', processing: '备货中', canceled: '已取消', all: '查看全部',WAIT_BUYER_PAY:'待付款',WAIT_SELLER_SEND_GOODS:'待发货',WAIT_BUYER_CONFIRM_GOODS:'配送中',TRADE_FINISHED:'已完成',TRADE_CLOSED:'已关闭','TRADE_CLOSED_BY_CLOUD':'超时关闭','DC_RECEIVED':'仓库已收货','RETAIL_STORE_RECEIVED':'门店已收货','BUYER_PART_PAYMENT':'待付尾款'};
  orderPaymentTypeArr:any = {alipay_payment: '支付宝', wxpay_payment: '微信支付', wechat_pay: '微信支付',weixin:'微信支付',alipay:'支付宝',unionpay:'银联','wosai:weixin:mini':'微信小程序','wosai:alipay:wap':'支付宝','wosai:weixin:wap':'微信','wosai:weixin:mini:giftcard':'礼品卡','wosai:litepos:purchase':'微信'};
  public refundStateTranslateArr:any = {WAIT_SELLER_AGREE: '待审核',WAIT_BUYER_RETURN_GOODS: '待退货',WAIT_SELLER_CONFIRM_GOODS: '待退款',CLOSED: '已关闭',SUCCESS: '退款成功',RETAIL_STORE_RECEIVED: '门店已收货'};
  payType:any = '';

  public loading: boolean = true;
  public show_modal: boolean = false;
  public message: any;
  public showError: boolean = false;
  public refundApplySuccess :boolean = false;
  public canceled: boolean = false;
  public changePaymentBox:any = false;
  public orderId!: string;
  // 取消订单&&确认收货锁
  public canceledLock: boolean = true;
  public complateLock: boolean = true;
  public paymentLock:any = true;
  public store_id:any = 1;
  public mobile:any;
  public productOptions:any = [];
  public create_time_begin!: string;
  public create_time_end!: string;
  public cloud_order_status = 'all';
  public allow_confirm :boolean = false;
  public allow_applay_refund :boolean = false;
  public showLogistics:boolean = false;
  public applyRefund:boolean = false;
  public refundOrder:any;

  constructor(
    private request: RequestService  ) {
  }

  analysisProductColor(options:any){
    this.productOptions = JSON.parse(options);
    return this.productOptions[0];
  }

	analysisProductSize(options:any){
		this.productOptions = JSON.parse(options);
		return this.productOptions[1];
	}

  analysisProductImage(skuImageUrl: any, options:any){
    const productOptions = JSON.parse(options);
    return productOptions[0].hasOwnProperty('hoverUrl') && productOptions[0]['hoverUrl'] ? productOptions[0]['hoverUrl'] : skuImageUrl;
  }

	public sizeConvert(size:any){
		if(!!size && size.indexOf('/P') !== -1){
			return size.replace('/P','');
		}else if(!!size && size.indexOf('P')){
			return size.replace('P','');
		}
		return size;
	}

  productUrl(options:any){
    this.productOptions = JSON.parse(options);
    return this.productOptions[0].frontUrlPath;
  }
  /**
   * 获取订单
   * @param {number} id
   * @returns {Promise}
   */
  getOrder(id: string): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.orderListApi + '/' + id).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }


  /**
   * 获取订单
   * @param {number} id
   * @returns {Promise}
   */
  getCloudOrder(id: string): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.cloudSalesOrder + 'customer/sales_order/' + id + '/customer').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }


  /**
	 * 获取订单列表
	 * @returns {Promise}
	 */
  getOrderList(): Promise<any> {
    return new Promise( () => {
      const getOrderListUrl = apiConfig.orderListApi + '?action=orderList' + '&type=' + this.urlType + '&month=' + this.urlMonth + '&store_id='+this.store_id;
      this.request.get(getOrderListUrl + '&curPage=' + this.currentPage + '&pageSize=' + this.pageSize)
        .subscribe(
          res => {
            this.orders = res;
            this.loading = false;
          }
        );
    });
  }

  getRangeDate( range: number) {
    const formatDate = ( time: any ) => {
      // 格式化日期，获取今天的日期
      const Dates = new Date( time );
      const year: number = Dates.getFullYear();
      const month: any = ( Dates.getMonth() + 1 ) < 10 ? '0' + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
      const day: any = Dates.getDate() < 10 ? '0' + Dates.getDate() : Dates.getDate();
      const hour: any = Dates.getHours();       //获取当前小时数(0-23)
      const minutes: any = Dates.getMinutes();     //获取当前分钟数(0-59)
      const seconds: any = Dates.getSeconds();
      return year + '-' + month + '-' + day + ' '+ hour + ':'+ minutes + ':' + seconds;
    };

    let changeDate: string;
    if (range > 0 ) {
      changeDate = formatDate( new Date().getTime() - ( 1000 * 3600 * 24 * 30 * range ) );
    }else{
      changeDate = formatDate( new Date().getTime());
    }
    return changeDate;
  }

  /**
   * 订单包裹信息
   * @param orderId
   * @returns {Promise<T>}
   */
  getLogistics(orderId: string): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.cloudSalesOrder+ 'sales_shipment/shipment/'+orderId+'/customer').toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }

  /**
   * 获取快递100物流信息
   * @param orderId
   * @returns {Promise<T>}
   */
  logisticsTracking(express_code : string,tracking_number :string,order_id:string): Promise<any> {
    return new Promise((resolve) => {
      this.request.get(apiConfig.cloudLogistics+ 'customer/kuaidi100/poll/v2?express_code='+express_code+'&tracking_number='+tracking_number+'&order_id='+order_id).toPromise().then(
        res => {
          resolve(res);
        }
      ).catch(error => error);
    });
  }

  getCloudOrderList(storeId:any): Promise<any> {
    return new Promise( (resolve) => {
      this.create_time_begin = this.getRangeDate(<number>this.urlMonth);
      this.create_time_end = this.getRangeDate(<number>0);
      if(this.urlMonth === 0){
        this.getCloudOrderListUrl = apiConfig.cloudSalesOrder+'customer/sales_order/customer?store_id='+storeId+'&status='+this.cloud_order_status;
      }else{
        this.getCloudOrderListUrl = apiConfig.cloudSalesOrder+'customer/sales_order/customer?store_id='+storeId+'&status='+this.cloud_order_status+'&create_time_begin='+this.create_time_begin+'&create_time_end='+this.create_time_end;
      }
      this.request.get(this.getCloudOrderListUrl + '&page_no=' + this.currentPage + '&page_size=' + this.pageSize).toPromise().then(
        res => {
          resolve(res);
        }
      );
    });
  }

  cloudOrderDetail(orderId:any):Promise<any> {
    return new Promise( (resolve) => {
      const getOrderInfoUrl = apiConfig.cloudSalesOrder + 'sales_order/' + orderId + '/official_website';
      this.request.get(getOrderInfoUrl).toPromise().then(
        res => {
          resolve(res);
        }
      );
    });
  }
  /**
   * 是否允许确认收货
   * @param orderId
   * @returns {Promise<T>}
   */
  isAllowConfirm(orderId:any):Promise<any> {
    return new Promise( (resolve) => {
      const allowConfirmUrl = apiConfig.cloudSalesOrder+'sales_order/is_allow_confirm/customer/'+ orderId;
      this.request.get(allowConfirmUrl).toPromise().then(
        res => {
          resolve(res);
        }
      );
    });
  }

  /**
   * 是否允许申请退货
   * @param orderId
   * @returns {Promise<T>}
   */
  isAllowApplyRefund(orderId:any):Promise<any>{
    return new Promise( (resolve) => {
      const allowRefundUrl = apiConfig.cloudSalesOrder+'sales_order/is_allow_apply_refund/customer/'+ orderId;
      this.request.get(allowRefundUrl).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  /**
   * 修改订单支付方式
   * @param parmes
   * @returns {Promise<any>}
   */
  modifyPaymentMethodServer(orderId:any,parmes:any): Promise<any> {
    const modifyPaymentMethodUrl = apiConfig.cloudSalesOrder + 'sales_order/'+orderId+'/pay_type';
    return new Promise((resolve) => {
      this.request.put(modifyPaymentMethodUrl, parmes).toPromise().then(res => {
        // 更改支付方式解锁
        this.paymentLock = true;
        resolve(res);
      }).catch(error => error);
    });
  }

  /**
   * 我的账户订单待付款订单取消接口
   * @param params
   * @returns {Promise<any>}
   */
  orderCancelServer(params:any): Promise<any> {
    const orderCancelUrl = apiConfig.orderListApi + '?action=cancelOrder';
    return new Promise((resolve) => {
      this.request.put(orderCancelUrl, params).toPromise().then(res => {
        // 取消解锁
        this.canceledLock = true;
        resolve(res);

      }).catch(error => error);
    });
  }

  /**
   * 取消小程序订单
   * @param params
   * @returns {Promise<T>}
   */
  cloudOrderCancelServer(params:any): Promise<any> {
    const orderCancelUrl = apiConfig.cloudSalesOrder + 'sales_order/'+ params.get('order_id') + '/cancel';
    return new Promise((resolve) => {
      this.request.put(orderCancelUrl, params).toPromise().then(res => {
        // 取消解锁
        this.canceledLock = true;
        resolve(res);

      }).catch(error => error);
    });
  }

  /**
   * 订单确认收货
   * @param parmes
   * @returns {Promise<any>}
   */
  orderComplateServer(parmes:any): Promise<any> {
    const orderComplateMethodUrl = apiConfig.orderListApi + '?action=OrderComplete';
    return new Promise((resolve) => {
      this.request.put(orderComplateMethodUrl, parmes).toPromise().then(res => {
        // 确认解锁
        this.complateLock = true;
        resolve(res);
      }).catch(error => error);
    });
  }

  /**
   * 小程序订单确认收货
   * @param parmes
   * @returns {Promise<any>}
   */
  cloudOrderComplateServer(parmes:any): Promise<any> {
    const orderComplateMethodUrl = apiConfig.cloudSalesOrder + 'sales_shipment/confirm';
    return new Promise((resolve) => {
      this.request.put(orderComplateMethodUrl, parmes).toPromise().then(res => {
        // 确认解锁
        this.complateLock = true;
        resolve(res);
      }).catch(error => error);
    });
  }

  // 关闭遮罩层和弹框
  closeOverlay() {
    this.show_modal = false;
    this.canceled = false;
    this.showError = false;
    this.changePaymentBox = false;
    this.showLogistics = false;
    this.applyRefund = false;
    this.refundApplySuccess = false;
    // 取消时恢复所选择的radio
    this.payType = '';
  }

  /**
   * 创建订单时，增加记录
   * @param parmes
   * @param orderId
   */
  createOrderLog(parmes:any, orderId: string): Promise<any> {
    const modifyUrl = apiConfig.cloudSalesOrder + 'sales_order/' + orderId + '/user_name/customer';
    return new Promise((resolve) => {
      this.request.put(modifyUrl, parmes).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  //判断是否可仅退款,根据付款时间判断
  allowJustRefund(order:any){
    let finalPaymentTime = '';
    if(order.orderType == 'step'){
      if(order.lastPayTime && order.status === 'WAIT_SELLER_SEND_GOODS'){
        finalPaymentTime = order.lastPayTime;
      }
    }else{
      if(order.payTime && order.status === 'WAIT_SELLER_SEND_GOODS'){
        finalPaymentTime = order.payTime;
      }
    }

    if(finalPaymentTime){
      const time = new Date().getTime().toString().substring(0,10);
      const payTime = Date.parse(order.payTime.replace(/-/g, '/')).toString().substring(0,10);    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
      const limitHours = 60*60;
      const timeDifference = parseInt(time) - parseInt(payTime);
      //支付2小时
      if(timeDifference/limitHours > 2){
        return false;
      }
      return true;
    }
   return false;
  }

  justRefund(data:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudSalesOrder +"sales_refund/customer/apply/v2",data).toPromise().then(res => {
          resolve(res);
        }).catch(error => error);
    });
  }

  getWosaiInvoiceAmount(orderId:any) {
    return new Promise( (resolve) => {
      const url = apiConfig.cloudSalesOrder + 'customer/wosai_invoice/' +  orderId +  '/amount';
      this.request.get(url).toPromise().then(
        res => {
          resolve(res);
        }
      );
    });
  }

  wosaiInvoiceBlue(orderId:any, param:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.post(`${apiConfig.cloudSalesOrder}customer/wosai_invoice/${orderId}/blue`, JSON.stringify(param), false, false, true).subscribe(value => {
        resolve(value);
      });
    });
  }

  /**
   * 获取发票详情
   * @param orderId
   * @param tasksn
   */
  getInvoiceList(orderId:any) {
    return new Promise( (resolve) => {
      const url = apiConfig.cloudSalesOrder + 'customer/wosai_invoice?order_id=' +  orderId;
      this.request.get(url).toPromise().then(
        res => {
          resolve(res);
        }
      );
    });
  }

  invoiceSaveToEail(orderId:any, param:any): Promise<any> {
    return new Promise((resolve) => {
      this.request.post(`${apiConfig.cloudSalesOrder}customer/wosai_invoice/${orderId}/resend_email`, JSON.stringify(param), false, false, true).subscribe(value => {
        resolve(value);
      });
    });
  }
}


