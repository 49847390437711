import {Component, OnInit} from '@angular/core';
import {CmsService} from '../../../services/cms.service';
import {CmsAdvancedBlockModel} from '../../../models/cms.advanced.block.model';
import {Router} from "@angular/router";

@Component({
  selector: 'app-vm-panel-cms',
  templateUrl: './vm-panel-cms.component.html',
  styleUrls: ['./vm-panel-cms-component.css']
})
export class VmPanelCmsComponent implements OnInit {
  constructor(public cmsService: CmsService, private router: Router) {
  }

  ngOnInit() {
  }

  /**
   * Add new container
   */
  addContainer() {
    this.cmsService.sharedData.containers.push([new CmsAdvancedBlockModel({})]);
  }

  /**
   * Create new page
   * @return {Promise<boolean>}
   */
  public newPage(): Promise<boolean> {
    return this.router.navigate(['/' + this.cmsService.advancedPagePathPrefix + 'new']);
  }

  /**
   * 保存预览页面
   */
  public savePreviewPage() {
    this.cmsService.sharedData.pageModel.preview = JSON.stringify(this.cmsService.sharedData.containers);
    this.cmsService.saveAdvancedPreviewPage(this.cmsService.sharedData.pageModel).then(response => {
      if (response.code === '200') {
        this.cmsService.sharedData.pageModel = response.data;
        alert('保存成功！');
        if (this.cmsService.sharedData.isNewPage) {
          this.router.navigate(['/' + this.cmsService.advancedPagePathPrefix + this.cmsService.sharedData.pageModel.identifier]);
        }
      }
      alert(response.message);
    }).catch(error => error);
  }

  /**
   * 保存预览页面并发布页面
   */
  public savePreviewPageAndPublish() {
    this.cmsService.sharedData.pageModel.preview = JSON.stringify(this.cmsService.sharedData.containers);
    this.cmsService.saveAdvancedPreviewPage(this.cmsService.sharedData.pageModel).then(response => {
      if (response.code === '200') {
        this.cmsService.sharedData.pageModel = response.data;
        this.cmsService.publishPage(this.cmsService.sharedData.pageModel).then(response2 => {
          if (response2.code === '200') {
            this.cmsService.cleanCmsCache();
            alert('发布成功！');
            if (this.cmsService.sharedData.isNewPage) {
              this.router.navigate(['/' + this.cmsService.advancedPagePathPrefix + this.cmsService.sharedData.pageModel.identifier]);
            }
          }
        });
      }
    }).catch(error => error);
  }
}
