import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cms-block-multi-product-editor',
  template: `
    <div class="form-group">
      <label for="exampleInputEmail1">Email address</label>
      <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Email">
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Password</label>
      <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password">
    </div>
    <div class="form-group">
      <label for="exampleInputFile">File input</label>
      <input type="file" id="exampleInputFile">
      <p class="help-block">Example block-level help text here.</p>
    </div>
    <div class="checkbox">
      <label>
        <input type="checkbox"> Check me out
      </label>
    </div>
  `,
  styles: []
})
export class CmsBlockMultiProductEditorComponent implements OnInit {

  @Input()
  public block: any;

  constructor() {
  }

  ngOnInit() {
  }

}
