import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {CategoryService} from './category.service';
import {ProductModel} from '../models/product.model';
import {StorageService} from './storage.service';
import {isPlatformBrowser} from '@angular/common';
import {any} from 'codelyzer/util/function';
import {RemoteServer} from '../config/config.remoteServer';

const apiConfig = require('../config/config.api.json');
// recommend URL

@Injectable()
export class ProductService {
  // 商品详情
  public productDetails:any;
  // 判断站点
  public site:any;
  // 赋值
  public productValue: any;
  // 尺寸
  public productSize:any;
  // 图片
  public productImage:any;
  // 输出到页面上的图片组(1-5张)
  public productImg: any;
  // (5-10张)
  public productImgs:any;
  // 1-5张 避免重复
  public productImages:any;
  // 颜色名称
  public colorName:any = '请选择';
  // 颜色价格
  public colorPrice:any;
  // 颜色数组
  public colorArray:any;
  // 根据颜色变化尺寸
  public productColorSize:any;
  public selectColors:any;
  // 折扣价
  public salePrice:any;
  // 商品详情
  public productDetailMessage:any = {productFiber: {}, productCare: {}};
  // 评论
  review: any = {'code': '', 'data': '', 'message' : ''};
  public product:any;
  public showPrice:any;
  // 综合评分
  public scoreArray: any;
  // 页数
  public curPages:any = 1;
  /**
   * 产品集合对象
   * @type {[any]}
   */
  protected products:any = [];

  protected productRecommends:any = {};
  public afterPage:any = '';
  public frontCustomParameters:any;
  public enableWhole : boolean = false;//推荐搭配开关
  public enableSimilar : boolean = false;//同类商品开关

  constructor(private request: RequestService,
              private init: InitializeService,
              public categoryst: CategoryService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private HOST: RemoteServer,
              private localStorage: StorageService) {
  }
  // 以下待调整

  // 向上翻页
  pageUp() {
    this.productImg = this.productImages;
  }

  // 向下翻页
  pageDown() {
    this.productImg = this.productImgs;
  }

  // 选择颜色
  selectColor(colorName:any, colorPrice:any, _i:any) {
    this.colorName = colorName;
    this.colorPrice = colorPrice;
  }

  // 改变尺寸
  changeSize(i:any) {
    this.productColorSize = this.colorArray[i].size;
  }

  /**
   * 从API中获取数据
   * @param {number} id
   * @param {number} cid
   * @returns {Promise<any>}
   */
  getProductData(id: number, cid: number): Promise<any> {
    let url = apiConfig.productApi + '/' + id;
    const symbol = cid ? '&' : '?';
    if (cid !== null) {
      url += '?cid=' + cid;
    }
    if (this.localStorage.getItem('vmLogin')) {
      url += symbol + 'timestamp=' + new Date().getTime();
    }
    return new Promise((resolve) => {
      this.request.get(url).toPromise().then(
        (res:any) => {
          if (isPlatformBrowser(this.platformId)) {
            if (res['data'] && res['data'].panorama_image) {
              const img = new Image();
              img.src = res['data'].panorama_image;
            }
          }
          const product = new ProductModel( res['data'] );
          product.entity_id = id;
          product.loadTimestamp = new Date().valueOf();
          this.products.push(product);
          resolve(product);
        }
      ).catch(error => error);
    });
  }

  /**
   * 获取商品
   * @param {number} id
   * @param {number} cid
   * @returns {Promise}
   */
  getProduct(id: any, cid: any = ''): Promise<any> {
    return this.getProductData(id, cid);
  }

  /**
   * Get product recommends
   * @returns {any}
   */
  getProductRecommends(item_id:any,value_id:any): Promise<any> {
	const groupId = this.localStorage.getItem('groupId') ? this.localStorage.getItem('groupId') :0;
	const memberLevelId = this.localStorage.getItem('memberLevelId') ? this.localStorage.getItem('memberLevelId') :0;
	const memberStatus = this.localStorage.getItem('memberIsActive') ? this.localStorage.getItem('memberIsActive') :'Inactive';
	const url = apiConfig.productCloudApi + '/customer/item_recommend/' + this.init.merchantId + '/' + this.init.cloudStoreId+'/'+item_id + '/' + value_id+'?group_id='+groupId+'&member_level_id='+memberLevelId+'&member_status='+memberStatus

    let recommends = [];
    return new Promise((resolve) => {
      this.request.get(url).subscribe(
        (data:any) => {
          recommends = data['data'];
          resolve(recommends);
        });
    });
  }
  getCartRecommends(front_codes:any): Promise<any> {
    const url = `${apiConfig.similarProductApi}/customer/recommend/get?merchant_id=${this.init.merchantId}&scenes=cart&num=8&store_id=${this.init.cloudStoreId}&sku_codes=${front_codes}&must=FALSE`;
    let recommends = [];
    return new Promise((resolve) => {
      this.request.get(url).subscribe(
        (data:any) => {
          recommends = data;
          resolve(recommends);
        });
    });
  }
  /**
   * post product shopping
   * @param {string}sku {number} id {string} quoteId
   * @param id
   * @param quoteId
   * @param numbers
   * @returns {any}
   */
  // 获取验证信息
  getQuoteId(sku: string, id: string, quoteId: string, numbers: string, itemCode: string, itemName: string): Promise<any> {
    const Url = apiConfig.cloudCart + '/sales_cart';
    let result:any;
    let quoteIdData:any;
    let carUrl;
	if(!!quoteId){
		quoteIdData = this.localStorage.getItem('quote_id');
		carUrl = Url + '/' + quoteIdData + '/item';
		result = this.postShoppingCar(sku, id, carUrl, numbers, itemCode, itemName );
		const pormise = new Promise((resolve) => {
		  resolve(result);
		});
		return pormise;
	}else{
		const paramsJson:any = { 'merchantId': this.init.merchantId, 'orderSource': this.init.orderSource, 'storeId': this.init.cloudStoreId.toString()};
		if (!this.init.isLogin()) { // 游客加购
		  paramsJson['customerId'] = -1;
		  this.localStorage.setItem('cloud_jwt', 'eFashionServiceInnerJwtABC');
		} else {
		  paramsJson['customerId'] = this.localStorage.getItem('cloud_customer_id');
		}
		let cartKeyResult: any = {code: '', msg: '', data: any()};
		return new Promise((resolve) => {
		  this.request.post(Url, paramsJson, false, false, true).toPromise().then(res => {
		    cartKeyResult = res;
		    if (cartKeyResult.code === 1000) {
		      quoteIdData = cartKeyResult.data.cartKey;
		      if (!this.init.isLogin()) { // 游客加购
		        this.localStorage.setItem('cloud_customer_id', cartKeyResult.data.customerId);
		      }
		    }
		    this.localStorage.setItem('quote_id', quoteIdData );
		    carUrl = Url + '/' + quoteIdData + '/item';
		    result = this.postShoppingCar(sku, id, carUrl, numbers, itemCode, itemName );
		    resolve(result);
		  }).catch(error => error);
		});
	}
  }

  postShoppingCar(pSku: string , id: string, carUrl: string , numbers: string, itemCode: string, itemName: string) {
    let codeMessage;
    const paramsJson:any = {'itemCode': itemCode, 'itemName': itemName, 'qty': numbers, 'skuCode': pSku, 'skuId': id};
    return new Promise((resolve) => {
      this.request.post(carUrl, paramsJson, false, false, true).toPromise().then(
        res => {
          codeMessage = res;
          resolve(codeMessage);
        }).catch(error => error);
    });
  }

  /**
   * 获取pdp库存
   * @param {number} id
   * @returns {any}
   */
  getStock(id: number): Promise<any> {
    if (this.init.websiteId === 'CN') {
      this.site = 1;
    }
    if (this.init.websiteId === 'HK') {
      this.site = 3;
    }
    if (this.init.websiteId === 'TW') {
      this.site = 6;
    }
    let stock;
    const stockUrl = apiConfig.productApi + '?action=getStockList&pid=' + id + '&websiteId=' + this.site;
    return new Promise((resolve) => {
      this.request.list(stockUrl).toPromise().then(
        res => {
          stock = res;
          resolve(stock);
        }).catch(error => error);
    });
  }
  /**
   * 获取pdp评论
   * @param {number} id {number}curPage {number}pageSize
   * @returns {any}
   */
  public getReviewList(id: number, curPage: number, pageSize: number): any {
    this.curPages = this.curPages + curPage;
    if ( this.curPages <= 0 ) {
      curPage = 1;
      this.curPages = 1;
    } else {
      curPage = this.curPages;
    }
    const url = apiConfig.reviewApi;
    // 获取参数商品id
    const reviewUrl = url + '&productId=' + id + '&curpage=' + curPage + '&pageSize=' + pageSize;
    this.request.list(reviewUrl).subscribe
    (r => {
      this.review = r;
      if (this.review.data.totalCount <= (curPage - 1) * pageSize) {
        this.curPages = curPage - 2;
      }

      // 整合星星数
      const score = this.review.data.compScore;
      this.review.data.compScore = this.judgeScore(score);
      for (let j = 0; j < this.review.data.reviewList.length;) {
        this.review.data.reviewList[j].score = this.judgeScore(this.review.data.reviewList[j].score);
        j++;
      }
      this.scoreArray = this.review;
    });

  }
  // 星星数判断公共方法
  judgeScore(score:any) {
    let i;
    const scoreArr = [];
    for (i = 1; i < 6;) {
      const a = i - 1;
      if (score >= i) {
        scoreArr[a] = 293;
      } else {
        if (score > i - 1) {
          scoreArr[a] = 294;
        } else {
          scoreArr[a] = 360;
        }
      }
      i++;
    }
    return scoreArr;
  }
  // 综合评分
  public getSumScore() {
    this.scoreArray = this.scoreArray;
  }

  getProductBySku(sku:any, qr_client: string = '') {
    return new Promise((resolve => {
      this.request.get(apiConfig.productApi + '?action=getProductBySku&sku=' + sku + '&qr_client=' + qr_client).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  /**
   * cloud--获取商品pdp信息
   * @param itemId
   */
  getProductByItemId(itemId:any) {
    return new Promise((resolve => {
      this.request.get(apiConfig.productCloudApi + '/elasticsearch/item/' + itemId + '?store_id='+this.init.cloudStoreId+ '&merchant_id=' + this.init.merchantId).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  /**
   * cloud--获取商品pdp信息
   * @param sku
   */
  getCloudProductBySku(sku:any) {
    return new Promise((resolve => {
      this.request.get(apiConfig.productCloudApi + '/elasticsearch/item_all_info?code=' + sku + '&store_id=' + this.init.cloudStoreId + '&merchant_id=' + this.init.merchantId).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  /**
   * 商品店铺级状态
   * @returns {Promise<T>}
   */
  getPdpApproveStatus(itemId:any,valueId:any){
    return new Promise((resolve => {
      this.request.get(apiConfig.cloudItemService + 'customer/store_item_sale_status/pdp_approve_status?merchant_id=' + this.init.merchantId + '&store_id=' + this.init.cloudStoreId + '&item_id=' + itemId + '&value_id=' + valueId ).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }


  /**
   * 商品实时库存
   * @returns {Promise<T>}
   */
  realTimeInventory(skuCode:any){
    return new Promise((resolve => {
      this.request.get(apiConfig.cloudItemInventory + 'customer/item_sku/' + this.init.merchantId+'/'+skuCode).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  /**
   * 获取商品促销标签--cloud
   * @param itemId
   * @param valueId
   */
  getProductPromotionTag(itemId: string, valueId: string, price: string) {
    return new Promise((resolve => {
      this.request.get(apiConfig.productCloudApi + '/promotion_tag/' + this.init.merchantId + '/' + this.init.cloudStoreId + '/' + itemId + '/' + valueId + '?price=' + price).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  /**
   * 校验是否为新品
   */
  public checkIsNew(newStartTime: any, newEndTime: any) {
    if (newEndTime != '' && newStartTime != '' && newEndTime != null && newStartTime != null) {
      const endTimestamp = Date.parse(newEndTime.replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
      const startTimestamp = Date.parse(newStartTime.replace(/-/g, '/'));
      const timestamp = (new Date()).getTime();
      if (timestamp >= startTimestamp && timestamp < endTimestamp) {
        return true;
      } else {
        return false;
      }
    }
	return;
  }

  /**
   * 校验是否线上专供
   */
  public checkIsExclusively(frontTag: any) {
    if (frontTag != '' && frontTag != null && frontTag === 'ONLINE EXCLUSIVE') {
      return true;
    } else {
      return false;
    }
  }

  public getQrcode(item_code: string, value_name: string, front_code: string, utm_source = '', utm_medium = '') {
    let qrcode_data = 'https://www.cos.cn/item/redirect?item_code=' + item_code + '&value_name=' + value_name + '&user_name=COS:PDP&front_code=' + front_code;
    if (utm_source && utm_source != '') {
      qrcode_data += '&utm_source=' + utm_source;
    }
    if (utm_medium && utm_medium != '') {
      qrcode_data += '&utm_medium=' + utm_medium;
    } else {
      qrcode_data += '&utm_medium=pdp';
    }
    const host = this.HOST.BASIC_HOST;
    const url = apiConfig.cloudQrcode + 'qrcode?qrcode_data=' + encodeURIComponent(qrcode_data);
    return host + url;
  }


  public getProductStrategy(item_id: string, value_id: string){
	const groupId = this.localStorage.getItem('groupId') ? this.localStorage.getItem('groupId') :0;
	const memberLevelId = this.localStorage.getItem('memberLevelId') ? this.localStorage.getItem('memberLevelId') :0;
	const memberStatus = this.localStorage.getItem('memberIsActive') ? this.localStorage.getItem('memberIsActive') :'Inactive';

	const url = apiConfig.productCloudApi + '/item_strategy/' + this.init.merchantId + '/' + item_id + '/' + value_id+'?group_id='+groupId+'&member_level_id='+memberLevelId+'&member_status='+memberStatus
	return new Promise((resolve => {
      this.request.get(url).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  public getProductCategory(item_id: string) {
    return new Promise((resolve => {
      this.request.get(apiConfig.productCloudApi + '/merchant_class_item/class_name?item_id=' + item_id + '&merchant_id=' + this.init.merchantId).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    }));
  }

  /**
   * 记录pdp扫码次数
   * @param paramsJson
   */
  public setProductQrcodeLog(paramsJson:any) {
    return new Promise((resolve) => {
      this.request.post(apiConfig.merchantExtendCloudApi + '/merchant_qrcode_scan_log/service', paramsJson, false, false, true).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  public customerArrivalRemind(paramsJson:any){
    return new Promise((resolve) => {
      this.request.post(apiConfig.cloudArrivalRemind + 'customer/arrival_remind', paramsJson, false, false, true).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  /**
   * 获取cloud商品列表(用于LandingPage)
   * @param store_id
   * @param class_id
   * @param page_size
   * @param page_no
   * @param q
   * @param show_no_stock
   */
  public getGoodsItems(merchant_id:any,store_id:any, class_id = 0, page_size = 1, page_no = 1, q: string = '', show_no_stock = '0') {
		this.afterPage = page_no == 1 ? "" : encodeURIComponent(this.afterPage);
		let url = `${apiConfig.productCloudApi}/customer/elasticsearch/fast?store_id=${store_id}&merchant_id=${merchant_id}&page_size=${page_size}&page_no=${page_no}&after_page=${this.afterPage}`;

		if (class_id != 0) {
		  url += `&class_id=${class_id}`;
		}
		if (show_no_stock != '0') {
		  url += `&is_valid=0`;
		}
		if (q) {
		  url += `&q=${q}`;
		}
		return new Promise((resolve) => {
		  this.request.get(url).toPromise().then(res => {
			resolve(res);
		  }).catch(error => error);
		});
  }

  public showPdpVideo(merchant_id:any,store_id:any){
    let url = `${apiConfig.globalConfigService}/customer/config/value/by_path?config_client_id=efcloud&config_scope=Store&config_path=item/pdp/show_pdp_video&config_scope_id=${store_id}&merchant_id=${merchant_id}`;
    return new Promise((resolve) => {
      this.request.get(url).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }



  public showPdpRecommend(merchant_id:any,store_id:any){
    let url = `${apiConfig.globalConfigService}/customer/config/value/by_path?config_client_id=efcloud&config_scope=Store&config_path=recommend/pdp/enable&config_scope_id=${store_id}&merchant_id=${merchant_id}`;
    return new Promise((resolve) => {
      this.request.get(url).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }


  public showRecommendWhole(merchant_id:any,store_id:any){
    let url = `${apiConfig.globalConfigService}/customer/config/value/by_path?config_client_id=efcloud&config_scope=Store&config_path=recommend/pdp/whole_enable&config_scope_id=${store_id}&merchant_id=${merchant_id}`;
    return new Promise((resolve) => {
      this.request.get(url).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  public showRecommendSimilar(merchant_id:any,store_id:any){
    let url = `${apiConfig.globalConfigService}/customer/config/value/by_path?config_client_id=efcloud&config_scope=Store&config_path=recommend/pdp/similar_enable&config_scope_id=${store_id}&merchant_id=${merchant_id}`;
    return new Promise((resolve) => {
      this.request.get(url).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }

  public showArrivalNotice(merchant_id:any,store_id:any){
    let url = `${apiConfig.globalConfigService}/customer/config/value/by_path?config_client_id=efcloud&config_scope=Store&config_path=item/common/show_arrival_notice&config_scope_id=${store_id}&merchant_id=${merchant_id}`;
    return new Promise((resolve) => {
      this.request.get(url).toPromise().then(res => {
        resolve(res);
      }).catch(error => error);
    });
  }


	strategyTag(data:any){
		let priceRulesJson:any = []
		let json = {
			price: 0,
			rulesPrice: 0,
			hasRule: false,
			rulesArr: []
		}

		let yrJson:any = []
		data.forEach(function(item:any, _index:any, _array:any) {
		  if(item.showPreTag == 1) {
			let now = new Date().getTime() / 1000;
			let start = new Date(item.preStrategyStartTime.replace(/-/g, '/')).getTime() / 1000;
			let end = new Date(item.preStrategyEndTime.replace(/-/g, '/')).getTime() / 1000;
			if (now - start >= 0 && end - now > 0) {
			  yrJson.push({
				groupIds: item.groupIds,
				groupVisible: item.groupVisible,
				memberLevels: item.memberLevels,
				priceTag: item.prePriceTag,
				showPreTag: item.showPreTag,
				strategyEndTime: item.preStrategyEndTime,
				strategyId: item.strategyId,
				strategyPrice: item.strategyPrice,
				strategyStartTime: item.preStrategyStartTime,
			  })
			}
		  }
		});

		data.forEach(function(item:any, _index:any, _array:any) {
		  let now = new Date().getTime() / 1000;
		  let start = new Date(item.strategyStartTime.replace(/-/g, '/')).getTime() / 1000;
		  let end = new Date(item.strategyEndTime.replace(/-/g, '/')).getTime() / 1000;
		  if (now - start >= 0 && end - now > 0) {
			priceRulesJson.push(item)
		  }
		});

		priceRulesJson.forEach(function(item:any, _index:any, _array:any) {
			if(!item.groupIds && !item.memberLevels) {
			  if (json.price == 0 && json.rulesPrice == 0) {
				json.price = item.strategyPrice;
				json.rulesPrice = item.strategyPrice;
				json.hasRule = true;
			  }
			}
		});

		json.rulesArr = priceRulesJson.concat(yrJson)
		return json;
	}


	filterStrategyTag(data:any){
		const cgid = this.localStorage.getItem('groupId') ? this.localStorage.getItem('groupId') : 0;
		const clid = this.localStorage.getItem('memberLevelId') ? this.localStorage.getItem('memberLevelId') :0;
		const memberStatus = this.localStorage.getItem('memberIsActive') ? this.localStorage.getItem('memberIsActive') :'Inactive';

		let isActive = memberStatus == 'Active';
		let priceRulesJson:any = []
		let usablePriceRulesJson:any = []
		let priceRulesJson1:any = []
		let priceRulesJson2:any = []

		let json = {
			price: 0,
			rulesPrice: 0,
			hasRule: false,
			rulesArr: []
		}

		data.forEach(function(item:any, _index:any, _array:any) {
		  let now = new Date().getTime() / 1000;
		  let start = new Date(item.strategyStartTime.replace(/-/g, '/')).getTime() / 1000;
		  let end = new Date(item.strategyEndTime.replace(/-/g, '/')).getTime() / 1000;
		  if (now - start >= 0 && end - now > 0) {
			priceRulesJson.push(item)
		  }
		});

		//	筛选相同数据，取最大一条
		priceRulesJson.forEach(function(item:any, index:any, _array:any){
		  for(var i=1; i < priceRulesJson.length; i ++) {
			let levelSplice = JSON.stringify(item.memberLevels) == JSON.stringify(priceRulesJson[i].memberLevels)
			let groupSplice = JSON.stringify(item.groupIds) == JSON.stringify(priceRulesJson[i].groupIds)
			let groupVisibleSplice = JSON.stringify(item.groupVisible) == JSON.stringify(priceRulesJson[i].groupVisible)
			let showPreTagSplice = JSON.stringify(item.showPreTag) == JSON.stringify(priceRulesJson[i].showPreTag)
			let preStrategyStartTimeSplice = JSON.stringify(item.preStrategyStartTime) == JSON.stringify(priceRulesJson[i].preStrategyStartTime)
			let preStrategyEndTimeSplice = JSON.stringify(item.preStrategyEndTime) == JSON.stringify(priceRulesJson[i].preStrategyEndTime)
			let strategyStartTimeSplice = JSON.stringify(item.strategyStartTime) == JSON.stringify(priceRulesJson[i].strategyStartTime)
			let strategyEndTimeSplice = JSON.stringify(item.strategyEndTime) == JSON.stringify(priceRulesJson[i].strategyEndTime)

			if(index!== i && levelSplice && groupSplice && groupVisibleSplice && showPreTagSplice && preStrategyStartTimeSplice
				&& preStrategyEndTimeSplice && strategyStartTimeSplice && strategyEndTimeSplice) {
			  priceRulesJson.splice(i, 1)
			}
		  }
		})

		for(var i=0; i < priceRulesJson.length; i ++) {
			if(priceRulesJson[i].groupVisible == 1){
				if(priceRulesJson[i].memberLevels !== null && priceRulesJson[i].groupIds !== null){
					let matchClid = JSON.stringify(priceRulesJson[i].memberLevels).indexOf(clid) == -1 && priceRulesJson[i].memberLevels !== clid;
					let matchCgid = JSON.stringify(priceRulesJson[i].groupIds).indexOf(cgid) == -1 && priceRulesJson[i].groupIds !== cgid;
					 //用户组和会员等级都不满足
					 if(matchClid && matchCgid ){
						continue;
					 }else{
						 let matchMemberLevel = false;
						 if(priceRulesJson[i].memberLevels !== null){
						 	 if(JSON.stringify(priceRulesJson[i].memberLevels).indexOf(clid) == -1 && priceRulesJson[i].memberLevels !== clid){
						 		continue ;
						 	 }else{
						 		matchMemberLevel = true;
						 	 }
						 }
						 let matchGroupId = false;
						 if(priceRulesJson[i].groupIds !== null){
						 	 if(JSON.stringify(priceRulesJson[i].groupIds).indexOf(cgid) == -1 && priceRulesJson[i].groupIds !== cgid){
						 		continue ;
						 	 }else{
						 		 matchGroupId = true;
						 	 }
						 }

						 if(matchMemberLevel || matchGroupId){
						 	usablePriceRulesJson.push(priceRulesJson[i]);
						 }
					 }
				}else{
					let matchMemberLevel = false;
					if(priceRulesJson[i].memberLevels !== null){
						 if(JSON.stringify(priceRulesJson[i].memberLevels).indexOf(clid) == -1 && priceRulesJson[i].memberLevels !== clid){
							continue ;
						 }else{
							matchMemberLevel = true;
						 }
					}
					let matchGroupId = false;
					if(priceRulesJson[i].groupIds !== null){
						 if(JSON.stringify(priceRulesJson[i].groupIds).indexOf(cgid) == -1 && priceRulesJson[i].groupIds !== cgid){
							continue ;
						 }else{
							 matchGroupId = true;
						 }
					}

					if(matchMemberLevel || matchGroupId){
						usablePriceRulesJson.push(priceRulesJson[i]);
					}
				}
			}else{
				usablePriceRulesJson.push(priceRulesJson[i]);
			}
		}

		if(usablePriceRulesJson.length > 0){
			usablePriceRulesJson.forEach(function(item:any, _index:any, _array:any) {
			  //未指定用户组及会员等级
			  if(!item.groupIds && !item.memberLevels) {
				  priceRulesJson1.push(item)

				  if (json.price == 0 && json.rulesPrice == 0) {
				    json.price = item.strategyPrice;
				    json.rulesPrice = item.strategyPrice;
				    json.hasRule = true;
				  }
			  } else {
				//仅价格生效用户可见
				if(item.groupVisible == 1) {
				  priceRulesJson1.push(item)
				} else {
				  let checkGroup = !item.groupIds || (!!item.groupIds && (item.groupIds.indexOf(cgid) !== -1 || item.groupIds == cgid))
				  let checkMemberLevel = !item.memberLevels || (isActive && !!item.memberLevels && (item.memberLevels.indexOf(clid) !== -1 || item.memberLevels == clid))
				if(checkGroup && checkMemberLevel) {
					priceRulesJson1.push(item)
				  } else {
					priceRulesJson2.push(item)
				  }
				}
			  }
			});

			if(priceRulesJson1.length > 0) {
			  if(priceRulesJson2.length > 0) {
				if(priceRulesJson2[0].strategyId > priceRulesJson1[0].strategyId) {
				  priceRulesJson2.push(priceRulesJson1[0])
				} else {
				  priceRulesJson2.unshift(priceRulesJson1[0])
				}
			  } else {
				priceRulesJson2.push(priceRulesJson1[0])
			  }
			}else{
				if(priceRulesJson2.length > 0){
				// 	//适用人群相同时取最新一条
					priceRulesJson2.forEach(function(item:any, index:any, _array:any){
					  for(var i=1; i < priceRulesJson2.length; i ++) {
						  let levelSplice = JSON.stringify(item.memberLevels) == JSON.stringify(priceRulesJson2[i].memberLevels)
						  let groupSplice = JSON.stringify(item.groupIds) == JSON.stringify(priceRulesJson2[i].groupIds)
						  if(index!== i && levelSplice && groupSplice) {
						    priceRulesJson2.splice(i, 1)
						  }
					  }
					})
				}
			}

			//移除不需要显示策略标签的数组
			priceRulesJson2.forEach(function(item:any, index:any, _array:any){
				let priceTag = item.priceTag;
				if(!priceTag){
					priceRulesJson2.splice(index, 1)
				}
			})
			json.rulesArr = priceRulesJson2;
		}
	    return json
	}

	/**
	 * 新版本策略价格&预热价 2022.1.7
	 *
	 */
	public priceRulesDeal(cgid:any,clid:any,memberStatus:any,data:any){
	  let isActive = memberStatus == 'Active';
	  let priceRulesJson:any = []
	  let priceRulesJson1:any = []
	  let priceRulesJson2:any = []
	  let priceRulesJsonArr:any = [];

	  let json = {
	    price: 0,
	    rulesPrice: 0,
	    hasRule: false,
	    rulesArr: []
	  }
	  let yrJson:any = []
		data.forEach(function(item:any, _index:any, _array:any) {
		  if(item.showPreTag == 1) {
			let now = new Date().getTime() / 1000;
			let start = new Date(item.preStrategyStartTime.replace(/-/g, '/')).getTime() / 1000;
			let end = new Date(item.preStrategyEndTime.replace(/-/g, '/')).getTime() / 1000;
			if (now - start >= 0 && end - now > 0) {
			  // yrJson.push(item)
			  yrJson.push({
				groupIds: item.groupIds,
				groupVisible: item.groupVisible,
				memberLevels: item.memberLevels,
				// prePriceTag: item.prePriceTag,
				// preStrategyEndTime: item.prePriceTag,
				// preStrategyStartTime: item.prePriceTag,
				priceTag: item.prePriceTag,
				showPreTag: item.showPreTag,
				strategyEndTime: item.preStrategyEndTime,
				strategyId: item.strategyId,
				strategyPrice: item.strategyPrice,
				strategyStartTime: item.preStrategyStartTime,
			  })
			}
		  }
		});
		data.forEach(function(item:any, _index:any, _array:any) {
		  let now = new Date().getTime() / 1000;
		  let start = new Date(item.strategyStartTime.replace(/-/g, '/')).getTime() / 1000;
		  let end = new Date(item.strategyEndTime.replace(/-/g, '/')).getTime() / 1000;
		  if (now - start >= 0 && end - now > 0) {
			priceRulesJson.push(item)
		  }
		});
		//预热标签需要校验用户组，用户等级
		priceRulesJsonArr = priceRulesJson.concat(yrJson);

		priceRulesJsonArr.forEach(function(item:any, _index:any, _array:any) {
		  //未指定用户组及会员等级,所有人可见
		  if(!item.groupIds && !item.memberLevels) {
				priceRulesJson1.push(item)
				if (json.price == 0 && json.rulesPrice == 0) {
				  json.price = item.strategyPrice;
				  json.rulesPrice = item.strategyPrice;
				  json.hasRule = true;
				}
		  } else {
			//仅价格生效用户可见
			if(item.groupVisible == 1) {
			  priceRulesJson1.push(item)
			} else {
			  let checkGroup = !item.groupIds || (!!item.groupIds && (item.groupIds.indexOf(cgid) !== -1 || item.groupIds == cgid))
			  let checkMemberLevel = !item.memberLevels || (!!item.memberLevels && isActive && (item.memberLevels.indexOf(clid) !== -1 || item.memberLevels == clid))
			if(checkGroup && checkMemberLevel) {
				priceRulesJson1.push(item)
			  }else{
				priceRulesJson2.push(item)
			  }
			}
		  }
		});

		priceRulesJson2.forEach(function(item:any, index:any, _array:any){
		  for(var i=1; i < priceRulesJson2.length; i ++) {
			let levelSplice = JSON.stringify(item.memberLevels) == JSON.stringify(priceRulesJson2[i].memberLevels)
			let groupSplice = JSON.stringify(item.groupIds) == JSON.stringify(priceRulesJson2[i].groupIds)
			if(index!== i && levelSplice && groupSplice) {
			  priceRulesJson2.splice(i, 1)
			}
		  }
		})

		if(priceRulesJson1.length > 0) {
		  if(priceRulesJson2.length > 0) {
			if(priceRulesJson2[0].strategyId > priceRulesJson1[0].strategyId) {
			  priceRulesJson2.push(priceRulesJson1[0])
			} else {
			  priceRulesJson2.unshift(priceRulesJson1[0])
			}
		  } else {
			//同用户组，会员组取最大的一条，否则全部显示
			priceRulesJson2.push(priceRulesJson1[0])
		  }
		}else{
			if(priceRulesJson2.length > 0){
				//适用人群相同时取最新一条
				priceRulesJson2.forEach(function(item:any, index:any, _array:any){
				  for(var i=1; i < priceRulesJson2.length; i ++) {
					  let levelSplice = JSON.stringify(item.memberLevels) == JSON.stringify(priceRulesJson2[i].memberLevels)
					  let groupSplice = JSON.stringify(item.groupIds) == JSON.stringify(priceRulesJson2[i].groupIds)
					  if(index!== i && levelSplice && groupSplice) {
						priceRulesJson2.splice(i, 1)
					  }
				  }
				})
			}
		}

		//移除不需要显示策略标签的数组
		priceRulesJson2.forEach(function(item:any, index:any, _array:any){
			let priceTag = item.priceTag;
			if(!priceTag){
				priceRulesJson2.splice(index, 1)
			}
		})
		json.rulesArr = priceRulesJson2;

	    return json
	}

	// 时间判断
	public promotionTime(start:any, end:any) {
	  if (start !== null && end !== null) {
		const endTimestamp = Date.parse(end.replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
		const startTimestamp = Date.parse(start.replace(/-/g, '/'));
		const timestamp = (new Date()).getTime();
		if (timestamp >= startTimestamp && timestamp < endTimestamp) {
		  return true
		} else {
		  return false
		}
	  } else {
		return false
	  }
	}

	public getCategoryByFrontUrl(frontPathUrl:any){
		//["product","thick-soled-leather-sneakers","0635823001","html"]
		var itemLinkArr = frontPathUrl.split('.');
		var itemLinkStr = itemLinkArr[1].split('-');
		var itemLinkLen = itemLinkStr.length;
		var index = itemLinkLen - 1;
		return itemLinkStr[index];
	}
	// /**
	//  * 组装pageview
	//  */
	public initGaPageData(routerUrl:any){
		  var urlString = routerUrl;
		  var page_category_path = '';
		  var urlArr = [];
			var page_type = '';
		  urlArr = urlString.split('/').splice(1);
		  if(urlArr.length <= 1){//非列表页进PDP
		  	var frontUrlPath = urlArr[0];
		  	page_category_path = this.getCategoryByFrontUrl(frontUrlPath);

			page_type = page_category_path;
		  }else{//列表页进PDP ["women","tops","knitted-tops","product.cotton-silk-knitted-top.0747550003.html"]
		  	urlArr.pop();
		  	for(var i=0;i<urlArr.length;i++){
		  		if(i==0){
		  			page_category_path +=urlArr[i];
		  		}else{
		  			page_category_path +='_'+urlArr[i];
		  		}
		  	}
		  	var arrLen = urlArr.length;
		  	page_type = urlArr[arrLen-1];
		  }

		  var pageData =  {
		     // (all page related variables)
		     page_id: page_category_path,
		     page_type:page_type,
		     page_category_path: page_category_path,
		     page_category_id: page_category_path,
		     site_brand_id: 'COS',
		     site_touchpoint: 'DESKTOP',
		     region_market: 'CN',
		     region_locale: 'zh-cn',
		     region_currency: 'CNY'
		  };

		  return pageData;
	}
	//cc级自定义参数
	public customParameters(frontProperties:any,valueId:any) {
		return new Promise((resolve) => {
			let result;
			if (frontProperties.length > 0) {
				let frontCustomParameters = '';
				frontProperties.forEach(function(item:any, _index:any, _array:any) {
					if (item.valueId === valueId) {
						frontCustomParameters = item.frontCustomParameters;
					}
				});
				this.frontCustomParameters = frontCustomParameters;
				result = this.frontCustomParameters;
			}
			resolve(result);
		})
	}
	// 获取限购qty信息
	public limitPurchase(skuId:any,relationLimitSkuIds:any='') {
		let url = `${apiConfig.cloudSalesOrder}customer/sales_order/limit_purchase/new/get?store_id=${this.init.cloudStoreId}&merchant_id=${this.init.merchantId}&sku_id=${skuId}&relation_limit_sku_ids=${relationLimitSkuIds}`;
		return new Promise((resolve) => {
		  this.request.get(url).toPromise().then(res => {
		    resolve(res);
		  }).catch(error => error);
		});
	}

	public sizeConvert(size:any){
		if(!!size && size.indexOf('/P') !== -1){
			return size.replace('/P','');
		}else if(!!size && size.indexOf('P')){
			return size.replace('P','');
		}
		return size;
	}
	
	public splitSize(size:any){
		var value = this.sizeConvert(size);
		var sizeArr = value.split("(");
		return sizeArr;
	}
	
	public isMultipleOfFour(number:number) {
	    return number % 4 === 0;
	}

	public isMultipleOfTwo(number:number) {
	    return number % 2 !== 0;
	}


	public isOddNumber(number:number) {
	    return number % 4 === 3;
	}
}
