
    <div class="swiper-container u-cols-sm-12-12 u-cols-md-12-12 u-cols-sm-6-12 u-row" [swiper]="block.data.config" (indexChange)="onIndexChange($event)">
      <div class="swiper-wrapper">
        <div *ngFor="let slide of block.data.slides; let i = index" class="swiper-slide swiper-slide{{i}}" [ngClass]="{'swiper-no-swiping': block.data.swiperstate[i] && init.IsPC()}" [innerHTML]="slide | html">
        </div>
      </div>

      <div class="swiper-scrollbar" [hidden]="block.data.config.scrollbar === false"></div>
      <div class="swiper-pagination" [hidden]="block.data.config.pagination === false"></div>

      <div class="swiper-button-prev" [hidden]="block.data.config.navigation === false"></div>
      <div class="swiper-button-next" [hidden]="block.data.config.navigation === false"></div>
    </div>
  