<div class="refresh" *ngIf="editMode">
  <span class="glyphicon glyphicon-refresh" (click)="refresh()"></span>
</div>
<!--Title Start-->
<!--<div class="paragraph parbase section cq-Editable-dom"><span class="richTextSpan">
	<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
		<div class="contain" style="clear:both">
			<div class="u-row u-full-width o-blog-text is-richtext richtextWrapper" style="width: 100%; clear:both">
				<div style="text-align: center;font-size: 24.0px;letter-spacing: 0.04em;color: rgb(68,68,68);">
        	{{block.data.categoryTitle}}
        </div>
			</div>
		</div>
</div>
</span>
</div>
<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 lineAtt">
  <hr class="a-keyline">
</div>-->
<!--Title End-->

<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row">
  <div class="o-category-listing">
    <div>
      <ng-container *ngIf="block.data.config.rows == 'fourLines' && isPc; else generalClass">
        <div class="column category-list-product-block fourCols" *ngFor="let product of productModel" style="width: 25% !important;">
          <div class="a-link no-styling">
            <app-product-list  [productModel]="product"  [categoryModel]="{'isSpecial': block.data.config.isSpecial}"></app-product-list>
          </div>
        </div>
      </ng-container>
      <ng-template #generalClass>
        <div class="column col-span-12 lg:col-span-3 category-list-product-block" *ngFor="let product of productModel">
          <div class="a-link no-styling">
            <app-product-list [productModel]="product"  [categoryModel]="{'isSpecial': block.data.config.isSpecial}"></app-product-list>
          </div>
        </div>
      </ng-template>
      <p *ngIf="categoryService.loading" style="text-align: center">加载中……</p>
    </div>
  </div>
</div>


<!--
&lt;!&ndash;3rows&ndash;&gt;
<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
  <div *ngIf="block.data.config.rows == 'threeLines'" data-component="ProductContainer" class="o-category-listing">
    <div id='products' [dragula]='"nested-bag"'>
      <div *ngFor="let product of productModel" class="column">
        <div class="a-link no-styling">
          <app-product-list  [productModel]="product"  [categoryModel]="{'isSpecial': block.data.config.isSpecial}"></app-product-list>
        </div>
      </div>
    </div>
    <ng-template #noProductBlock>
      <div>无相关商品</div>
    </ng-template>
  </div>
</div>-->


<!--4rows-->
<!--<div *ngIf="block.data.config.rows == 'fourLines'" class="fourcolumns parbase section cq-Editable-dom">
  <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
    <div class="o-grid-controller is-four-cols" style="background-color: #ffffff">
      <div class="content">
        <div class="scrollable-content">
          <div *ngFor="let product of productModel" class="column">
            <div>
              <div class="freetile parbase section cq-Editable-dom">
                <div class="m-free-tile four-cols" style="background-color: #ffffff">
                  <a routerLink="/{{product?.frontUrlPath}}"  target="_self">
                    <img class="a-image" src="{{product?.majorUrl}}" alt="图片">
                  </a>
                </div>
                <div class="items">
                  <a href="https://author.cos.appeaser.hm.com/content/cos/ch/men.html#" target="_self"
                     class="a-link open-lightbox" data-template="newsletter-signup" data-classes="is-subscribe">
                  </a>
                </div>
              </div>
              <div class="freetile parbase section cq-Editable-dom">
                <div class="m-free-tile four-cols" style="background-color: #ffffff">
                  <div class="cta-wrapper">
                    <a routerLink="/{{product?.frontUrlPath}}"  target="_self"
                       class="a-link cta-link" style="color: rgb(68, 68, 68); text-decoration: none;">
                      <label class="a-label js-a-label product-title nameBox">{{product?.itemName}}</label>
                      <div class="m-product-price priceBox">{{init.currency}}{{product?.price}}</div>
                    </a>
                    <div class="m-swatches sm">
                      <div class="picked-color"></div>
                      <div class="a-swatch js-swatch" *ngFor="let block of product?.frontProperties">
                        <label class="a-label js-a-label">
                          <input type="radio" name="colors" value="Black"/>
                          <span>
                        <div class="a-swatch-foreground"></div>
                        <a routerLink="/{{block?.frontUrlPath}}"><img class="a-image" src="{{block?.skuImageUrl}}" alt="图片"/></a>
                        &lt;!&ndash;<img class="a-image" src="{{block?.skuImageUrl}}" alt="图片"/>&ndash;&gt;
                        <p class="sold-out">Sold out</p>
                      </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="items">
                    <a href="https://author.cos.appeaser.hm.com/content/cos/ch/men.html#" target="_self"
                       class="a-link open-lightbox" data-template="newsletter-signup" data-classes="is-subscribe">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>-->
