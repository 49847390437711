<div class="my-account-content u-clearfix ng-scope">
  <div class="o-my-details summary">
    <form  class="o-form-verification" (submit)="submitLogout()">
      <div class="e-mail-input">
        <div data-component="MInput" class="m-input" [ngClass]="{'is-filled has-error':mobileValid==true,'is-active':mobileActive==true }">
          <label class="a-label js-a-label">手机号</label>
          <input autocomplete="off" maxlength="11" class="a-input js-a-input" type="tel" required title="手机号" name="mobile" [(ngModel)]='mobileVal' (blur)="blurMobileCheck()"  (focus)="mobileActiveCheck()">
          <label *ngIf="mobileValid" class="a-label js-a-label" data-placeholder="" >{{usernameError}}</label>
        </div>
      </div>
      <div class="e-mail-input pos_rel">
        <div data-component="MInput" class="m-input" [ngClass]="{'is-filled has-error':phoneCaptchaValid===true,'is-active':phoneCaptchaActive==true }">
          <label class="a-label js-a-label">验证码</label>
          <input autocomplete="off" name="phoneCaptcha" class="a-input js-a-input width_70" type="text" [(ngModel)]='phoneCaptchaValue' required (blur)="blurPhoneCaptchaCheck()"  (focus)="phoneCaptchaActiveCheck()" title="验证码">
          <button type="button" [disabled]="phoneCaptchaDisabled" (click)="openImageValid()" class="btn_code">{{sendPhoneLabel}}</button>
          <label *ngIf="phoneCaptchaValid === true" class="a-label js-a-label" data-placeholder="">{{phoneCaptchaError}}</label>
        </div>
        <div class="codeBox" [ngClass]="{'is-hidden':hiddenImageValidBox}">
          <h6>验证图形码</h6>
          <div class="iptText">
            <input autocomplete="off" type="text" name ='imageValidValue' [(ngModel)]='imageValidValue'>
            <img [id]="imageValidSrc" [src]="imageValidSrc" alt="图片">
            <p><a href="javascript:void(0)" (click)="createImage()">换一张</a></p>
          </div>
          <label *ngIf="imageValidError === true" class="a-label js-a-label codeError">{{imageValidErrorLabel}}</label>
          <button type="button" (click)="imageValueCheck()" class="a-button is-primary">确定</button>
          <button type="button" (click)="closeCodeBox()" class="a-button is-primary">取消</button>
        </div>
      </div>
      <button class="a-button is-primary" type="submit"><span>提交</span></button>
    </form>
  </div>
</div>

