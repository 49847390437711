import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-cms-block-reusable-editor',
  template: `
    <div class="form-group">
      <label for="inputIdentifier">请填写内容标识符</label>
      <input [(ngModel)]="block.data.identifier" type="text" class="form-control" id="inputIdentifier"
             placeholder="Identifier">
    </div>
  `,
  styles: []
})
export class CmsBlockReusableEditorComponent implements OnInit {

  @Input() block: any;

  constructor() {
  }

  ngOnInit() {
  }

}
