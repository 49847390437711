interface Scripts {
  name: string;
  src: string;
}

export const ScriptStore: Scripts[] = [
  {name: 'amap', src: '//webapi.amap.com/maps?v=1.4.6&key=65509f3efd055a94e8c930842363303c'},
  {name: 'ckeditor', src: '../assets/js/ckeditor/ckeditor.js'},
  {name: 'jqzoom', src: '/assets/js/jqzoom-min.js'},
  {name: 'tracking', src: '../assets/js/trackingcode/tracking.js'},
  {name: 'sensors', src: '../assets/js/sensors/sensorsdata.min.js'}, //sensors sdk js
  {name: 'slick', src: '../assets/js/slick.js'},
  {name: 'slickInit', src: '../assets/js/slick-init.js'},
  {name: 'datepicker', src: '../assets/js/datetimepicker.min.js'},
  {name: 'udesk', src: '../assets/js/udesk.js'}
];
