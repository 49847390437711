<div class="u-no-select snap-start footerBox" *ngIf="!notUseFooter && !mobPdp">
  <footer class="o-footer">
	  <div class="col-span-12 text-center ftLogo">
	  		<svg style="fill: white;color: white;" class="inline fill-current w-auto"><title>COS Logo</title><use href="/assets/iconSprite.svg#logo"></use></svg>
	  </div>
   <!-- <hr class="a-keyline"/>-->
    <div class="footer-container">
    <div class="itemsRight">
      <div style="cursor: default;">
        <div class="hero parbase section cq-Editable-dom" style="background-color: #EEEEEE">
          <div style="position: relative">
            <div class="contain">
              <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
                <div style="cursor: default;">
                  <div style="background-color: f1f1f1;" class="bcgr-color">
                    <div class="m-teaser align-middle  mobile-middle weekendwear">
                      <div class="textBox">
			<!-- 备份 -->
                        <p class="title">“<span style=" ">COS</span>摩登衣橱”微信会员计划</p>
                        <label class="a-paragraph q-alpha4 letter-spacing">￥100新会员专享礼券</label>
			<!-- <p class="title">“COS × 多抓鱼”积分赢福袋活动</p>
			<label class="a-paragraph q-alpha4 letter-spacing">畅玩年度积分  开启心意新年礼</label> -->
                      </div>
                      <div id="dialogss" *ngIf="showMembership">
                        <div id="applets" class="o-lightbox is-open homeTop" data-component="OLightbox" style="height: 255px;">
                          <div class="lightbox-header"></div>
                          <div class="lightbox-content js-content u-clearfix">
                            <div class="m-change-country-lightbox false">
                              <div class="paragraph parbase section">
                                <span class="richTextSpan">
                                  <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
                                    <div class="contain">
                                      <div class="u-row u-full-width">
                                        <div class="content-page page">
                                          <div class="wechat-page" style="max-width: 352px;margin: 0 auto; text-align: center">
						<!-- 备份 -->
                                            <p class="new-pcccc" style="margin:0 0 10px ;text-align: center; "><a href="https://weixin.qq.com/r/KzlocCDEq33UrWQT92w5 " style="display: block;"><img src="https://www.cos.cn/img/dm/pageup/211018/20211018-175100.png" width="160" alt="COS"></a><span style="display:block; margin-top: 5px;font-size: 14px;">微信扫码入会</span></p>
                                            <p class="visible-hhhhh" style="margin: 10px 0;height: 260px;"><a href="https://weixin.qq.com/r/KzlocCDEq33UrWQT92w5 "><img src="https://www.cos.cn/img/dm/pageup/211018/20211018-175100.png" style="display:block; width: 160px;margin-left: 7px;" alt="COS"></a><span style="display: block; margin-top: 10px;">保存图片<br/>微信扫码入会</span></p>
					    <!-- <p class="new-pcccc" style="margin:0 0 10px ;text-align: center; "><a href="https://weixin.qq.com/r/KzlocCDEq33UrWQT92w5 " style="display: block;"><img src="https://www.cos.cn/img/dm/pageup/211221/EC2.png" width="160" alt="COS"></a><span style="display:block; margin-top: 5px;font-size: 14px;">微信扫码<br/>即刻参与</span></p>
					    <p class="visible-hhhhh" style="margin: 10px 0;height: 260px;"><a href="https://weixin.qq.com/r/KzlocCDEq33UrWQT92w5 "><img src="https://www.cos.cn/img/dm/pageup/211221/EC2.png" style="display:block; width: 160px;margin-left: 7px;" alt="COS"></a><span style="display: block; margin-top: 10px;">微信扫码<br/>即刻参与</span></p> -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div class="m-button-icon a-icon-close js-close-button" (click)="closeMembership()">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cta-wrapper margin-left ButtonMagin5" class="addVipBtn">
                        <a (click)="membership()" class="a-link is-primarys CtaWidth1 addVip" style="color:#080808;background-color:#fff;padding:0;margin-right: 0;height: 40px;line-height:42px;text-align: center;cursor: pointer;font-size:13px;text-decoration: none;">即刻参与</a>
                      </div>
                      <a style="padding-left: 4px; margin-top: 20px; color: white;" class="font_small_xs_regular font_small_xs_regular mt-20 hidden xxs-block" (click)="selectContry()"><span>配送至：中国</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





    <div class="itemsLeft">
      <div class="items">
		    <a routerLink="/store-locator" class="a-link" (click)="linkTo('门店位置')">门店位置</a>
        <a routerLink="/contact-info" class="a-link" (click)="linkTo('联系我们')">联系我们</a>
        <a routerLink="/customer-service" class="a-link" (click)="linkTo('顾客服务')">顾客服务</a>
        <a routerLink="/privacy-policy" class="a-link" (click)="linkTo('隐私政策')">隐私政策</a>
        <a routerLink="delivery-methods" class="a-link" (click)="linkTo('配送信息')">配送信息</a>
        <a routerLink="return-and-refund-policy" class="a-link" (click)="linkTo('退货条例')">退货条例</a>
        <a routerLink="/secure-payment" class="a-link" (click)="linkTo('支付方式')">支付方式</a>
      </div>
      <div class="items">
        <a routerLink="/about-us" class="a-link" (click)="linkTo('关于COS')">关于COS</a>
        <a href="https://career.cos.com/coscareer/en_gb/i-d.html" target="_blank"  class="a-link" (click)="linkTo('包容性与多元化')">包容性与多元化</a>
        <a routerLink="/product-care" class="a-link" (click)="linkTo('洗护指南')">洗护指南</a>
        <a href="http://career.cos.com/" class="a-link" target="_blank" rel="noopener noreferrer" (click)="linkTo('招聘')">招聘</a>
        <a href="http://press.cos.com/" class="a-link" target="_blank" rel="noopener noreferrer" (click)="linkTo('媒体')">媒体</a>

      </div>
      <div class="items">
        <a href="https://mp.weixin.qq.com/s?__biz=MzI3MTIxMDIzNw==&mid=2247530718&idx=1&sn=f7e7fc1534a63a896daead3bdae0194f&chksm=eac77b4cddb0f25aa9806bae4f53f4898564323f0a670e0d290cb10a754082dc06e919f35097&token=384419914&lang=zh_CN#rd" target="_blank" class="a-link" (click)="linkTo('微信')">微信</a>
        <a href="https://www.weibo.com/cosstores" class="a-link" target="_blank" rel="noopener noreferrer" (click)="linkTo('微博')">微博</a>
        <a href="https://www.youku.com/profile/index/?spm=a2h0c.8166622.PhoneSokuPgc_46.dportrait&uid=UNDMxMzQ5OTQw" class="a-link" target="_blank" rel="noopener noreferrer" (click)="linkTo('优酷')">优酷</a>
      </div>
    </div>
    <div style="clear: both;"></div>
    </div>
    <div class="items" style="display: none">
      <a href="javascript:void(0)" (click)="showSubscribe()" class="a-link open-lightbox">邮件订阅</a>
    </div>
    <div class="siteIcp flex justify-between">
      <p>
        <strong>海恩斯莫里斯（上海）商业有限公司</strong>
        <strong><a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">沪ICP备09046754号-14</a></strong>
        <strong><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102005067" target="_blank" rel="noopener noreferrer">沪公网安备 31010102005067号</a></strong>
        <strong><a href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020211124140154000008618491-SAIC_SHOW_310000-20220523122819902697&signData=MEUCIFGsPsIeOtPR3zGax1isiRPzHwaL3SLSv3LDx8dplykrAiEA4SsqR0UEBmcemsv041Q7ReBYn6AkkMp6VD+WkqO9P4A=" target="_blank" rel="noopener noreferrer">上海工商</a></strong>
      </p>
      <a style="color: white;" class="font_xs_regular xxs-hidden" (click)="selectContry()"><strong>配送至：中国</strong></a>
    </div>
  </footer>
</div>
<!--模态框-->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': isVisible || (init.isLogin() && init.agreeAgreement == 'No')}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox" [class.is-open]="isVisible">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="paragraph parbase section">
          <span class="richTextSpan">
          <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
            <div class="contain">
              <div class="u-row u-full-width">
               <div class="content-page page">
                 <h1>你好</h1>
                 <div class="wechat-page" style="max-width: 352px;margin: 0 auto; text-align: center">
                   <p class="article-text">关注我们的微信公众号, 第一时间得知新品信息，特别优惠和门店活动资讯......</p>
                   <p style="margin: 10px 0"><a href="http://weixin.qq.com/r/KzlocCDEq33UrWQT92w5 "><img src="../../../../assets/images/COS0309.jpg" width="160" alt="COS"></a></p>
                   <p class="article-text">关注我们即表明你确认同意我们的隐私政策</p>
                 </div>
              </div>
            </div>
          </div>
          </div>
        </span>
      </div>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>





