import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';
import {AgreementService} from '../../services/agreement.service';

@Component({
  selector: 'app-agreement-authorization',
  templateUrl: './agreement-authorization.component.html',
  styleUrls: ['./agreement-authorization.component.css']
})
export class AgreementAuthorizationComponent implements OnInit{
  public agreementObject:any;
  public nickName!: String;
  public realName!: String;
  public details:any;
  public agreementId:any;
  public authorizedStatus!: String;
  public showBigImage!: boolean;
  public bigImageUrl!: String;
  constructor(
    private init: InitializeService,
    private localStorage:StorageService,
    private agreementService: AgreementService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.init.setTitle('协议授权');
    if (isPlatformBrowser(this.platformId)) {
      this.agreementObject= JSON.parse(this.localStorage.getItem('agreement_data'));
      this.nickName = this.agreementObject.nickName;
      this.realName = this.agreementObject.realName;
      this.details = this.agreementObject.details;
      this.authorizedStatus = this.agreementObject.agreementStatus;
      this.agreementId = this.agreementObject.agreementId;
    }
  }

  doAuthorized(){
    let agreementId = this.agreementId;
    const params = {agreementStatus:'authorized',customerIp:''};
    this.agreementService.agreementAuthorized(params,agreementId).then(val => {
      let result:any = {code: '', msg: '', data: ''};
      result = val;
      if(result.code === 1000){
        this.authorizedStatus = 'authorized';
        alert('授权成功');
      }
    });
  }

  viewBigImage(imageUrl: String){
    this.showBigImage = true;
    this.bigImageUrl = imageUrl;
  }

  hideBigImage(){
    this.showBigImage = false;
    this.bigImageUrl = '';
  }
}
