import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';

const apiConfig = require('../config/config.api.json');
@Injectable()
export class CosbyyouService {
  constructor(
    public init: InitializeService,
    private request: RequestService) {}
	/**
	 * @param {Object} colorCode
	 */
	public getPdpWallPics(colorCode:any){
		return new Promise((resolve) => {
		  const uri = apiConfig.appBuyerShow + 'customer/ugc/getPdpWallPics?activityId=2&colorCode='+colorCode;
		  this.request.get(uri).subscribe(
			val => {
			  resolve(val);
			}
		  );
		});
	}
	
	/**
	 * @param {Object} picId
	 */
	public getPicDetail(picId:any){
		return new Promise((resolve) => {
		  const uri = apiConfig.appBuyerShow + 'customer/ugc/getCosByPhotoByPicId?picId='+picId;
		  this.request.get(uri).subscribe(
			val => {
			  resolve(val);
			}
		  );
		});
 }
 
}
