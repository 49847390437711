import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CosbyyouService} from '../../../services/cosbyyou.service';

@Component({
  selector: 'app-cosbyyou-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.css']
})
export class CosbyyouImageDetailComponent implements OnInit{
	public picId = '';
	constructor(
	public router: Router,
	public routerInfo: ActivatedRoute,
	private cosbyyouService: CosbyyouService,
	) { }

  ngOnInit() {
	   this.routerInfo.queryParams.subscribe(params => {
		   this.picId = params.picid;
			this.imageDetail(this.picId);
	   });
  }
  
		imageDetail(picId:any){
			this.cosbyyouService.getPicDetail(picId).then(efsProduct => {
			});
		}
}