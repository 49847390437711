import {Component, OnInit} from '@angular/core';
import {InitializeService} from '../../services/initialize.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './account-reset-password.component.html',
  styleUrls: ['./account-reset-password.component.css']
})
export class AccountResetPasswordComponent implements OnInit {

  private pageName = '重置密码';

  constructor(public init: InitializeService) {
  }

  ngOnInit() {
    this.init.pageConfiguration(this.pageName);
  }
}
