<div *ngIf="!isMobilePayment" class="o-page-content cofs-page">
  <!-- 显示支付宝时：加ico_alipay类，文字为：支付宝支付 -->
  <p class="text-center"><strong class="icoBg payTitle" [ngClass]="{'ico_weixi': paymentMethod=='wosai:weixin:wap', 'ico_alipay': paymentMethod=='wosai:alipay:wap'}">{{paymentMethodMap[paymentMethod]}}</strong></p>
  <p class="text-center price font_s_regular">支付金额 ：<span>¥{{grandTotal}}</span></p>
  <p class="text-center prompt font_s_regular" *ngIf="!hasExpire">距离二维码过期还剩 {{timeStr}}，过期后请点击刷新重新获取二维码。</p>
  <p class="text-center text_red" *ngIf="hasExpire">二维码已过期，<a (click)="refreshQrcode()" href="javascript:void(0)" class="a-link">刷新</a>并获取二维码。</p>
  <div class="QRcode">
    <div class="onDiv" *ngIf="hasExpire">已失效</div>
    <img style="width: 234px;" src="{{paymentQrcode}}" alt="图片">
    <div class="caption" style="display: none">
      <img src="assets/images/weixincaption.jpg" alt="图片">
    </div>
    <p class="text-center order-num">订单号：{{orderCode}}</p>
  </div>
  <div>
    <p class="text_bottom">订单支付状态更新可能有延迟，请以银行卡扣款为准</p>
  </div>
</div>
<div *ngIf="isMobilePayment" class="o-page-content cofs-page">
  <div class="o-page-content cofs-page">
    <div class="payTextPage">
      <h2>我们正在处理您的付款……</h2>
      <p>这可能会花费一点时间，请耐心等待<br/>
        请勿关闭窗口或点击后退键</p>
    </div>
  </div>
  <div  *ngIf="(paymentMethod=='wosai:litepos:purchase') && !isWechatBrowser" style="display: none;">
    <a id="h5PaymentJumpForm" target="_self" href="{{h5PaymentJumpUrl}}"></a>
  </div>
</div>
