 <ng-container>
	 <div class="tagAll">
		<ng-container *ngIf="!!promotionTagList">
			<ng-container *ngFor="let generalPromotionTag of promotionTagList">
				<ng-container *ngIf="generalPromotionTag.promotionType !== 'forward_purchase' && checkGeneralPromotionTag(generalPromotionTag.groupIds)">
					<div class="customTag">
					  <div class="tagBox new">
							<label class="a-label js-a-label {{tagStyle}}" [ngStyle]="{'color': generalPromotionTag?.tagStyle != '' ? generalPromotionTag?.tagStyle : tagColor }">{{generalPromotionTag.promotionTag}}</label>
					  </div>
					</div>
				</ng-container>
			</ng-container>
		</ng-container>
	 <!--自定义标签-->
	 <ng-container *ngIf="!!customPromotionTagList">
		 <ng-container *ngFor="let customGeneralTag of customPromotionTagList">
			 <div class="m-product-marker">
			 		 <div class="tagBox marker-text promo-marker"  [ngStyle]="{'background-color':'#fff', 'border-radius':'0', 'color': customGeneralTag?.tagStyle != '' ? customGeneralTag?.tagStyle : customTagColor }" >
			 		   <label>{{customGeneralTag.promotionTag}}</label>
			 		 </div>
			 </div>
		 </ng-container>
	 </ng-container>
					
	 <!--预售标签-->
	 <ng-container *ngIf="productModel.presaleTag">
		 <div class="m-product-marker">
		   <div class="tagBox marker-text promo-marker">
			 <label style="color: #757575; background-color:#fff; border-radius: 0;">{{productModel.presaleTag}}</label>
		   </div>
		 </div>
	 </ng-container>		 
	 <!-- 会员提前购 -->
	 <ng-container *ngIf="!!promotionTagList && promotionTagList.length > 0">
		<ng-container *ngFor="let forwardPurchaseTag of promotionTagList">
			<ng-container *ngIf="forwardPurchaseTag.promotionType === 'forward_purchase'">
				<ng-container *ngIf="checkForwardPurchaseLabel(forwardPurchaseTag.memberLevelIds) && checkGeneralPromotionTag(forwardPurchaseTag.groupIds)">
					<div class="tagBox marker-label temp-marker">
						<label [ngStyle]="{'color': forwardPurchaseTag?.tagStyle != '' ? forwardPurchaseTag?.tagStyle : tagColor }" class="a-label js-a-label">{{forwardPurchaseTag.promotionTag}}</label>
					</div>
				</ng-container>
			</ng-container>
		</ng-container>
	 </ng-container>
	 
	 <ng-container *ngIf="productModel.is_exclusively">
		 <div class="markers" id="productMarkers">
		   <div class="m-product-marker">
		 		 <div class="tagBox marker-text product-marker" style="color: #757575; background-color:#fff; border-radius: 0;"><label>线上专享</label></div>
		   </div>
		 </div>
	 </ng-container>

	 <!-- 材质标签-->
	 <ng-container *ngIf="!!productModel.frontMaterialTag">
		 <div class="m-product-marker material">
		   <div class="tagBox marker-text promo-marker" style="color:#757575; background-color:#fff; border-radius:0;">
		 		 <label>{{productModel.frontMaterialTag}}</label>
		   </div>
		 </div>
	 </ng-container>

	</div>
 </ng-container>
