import {Inject, PLATFORM_ID,NgModule} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RouterLinks} from '../config/config.routerlink';
import {StorageService} from './storage.service';
import {Title} from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {RemoteServer} from '../config/config.remoteServer';

const localConfig = require('../config/config.local.json');

@NgModule({
  // ...
  //exports: [Title],
})
export class InitializeService {
	agreeAgreement:any = '';
	title:any = '';
	headerType:any = 1;
	historyRecord:any = []; // 历史记录
	storeId:any = '';  // 店铺id
	cloudStoreId:any = 10; // cloud storeid
	currency:any = ''; // 货币符号
	brandCode:any = ''; // 品牌
	websiteId:any = '';
	RouterLinks = RouterLinks;
	userId:any = '';
	mobile:any = '';
	userName:any = '';
	tokenStr:any = '';
	is_loading:any = false;
	domain:any;
	protocol:any;
	port:any;
	referer_url:any = '';
	referer_url_Param: any = {};
	loginTime: any;
	defaultAccountPage:any = '/my-account/profile';
	trackingJs:any = 'tracking';
	notUseHeaderPages:any = ['/shipping-to'];
	notUseFooterPages:any = ['/shipping-to','/startpage','/topkvtest','/w-home','/m-home'];
	gaTrackingFlag:any = false;
	memberLevelId:any = 0;
	imageSize:any = {
	$1: '$100x100',
	$2: '$200x200',
	$3: '$300x300',
	$4: '$400x400',
	$10: '$1000x1000'
	};
	merchantId:any = '100000005';
	cloudAppId:any = 'wx5de8676c6768e93d';
	orderSource:any = 'mall';
	miniProgramStoreId:any = 8;
	officialWebsiteStoreId:any = 10;
	aliyunSmsSign:any = 'COS中国官网';
	registerSmsTplCode:any = 'SMS_147195080';
	registerSmsTplId:any = '1019';

	aliyunSmsHkSign:any = 'COS官方';
	registerSmsHkTplId:any = '1020';

	forgotPasswordSmsTplCode:any = 'SMS_147200067';
	forgotPasswordSmsTplId:any = '1021';
	forgotPasswordSmsHkTplId:any = '1022';
	wxLoginAppid:any = 'wx1817af349af52951';
	smsPlatform:any = 'miaoxin';
	abTestTrackingAble:any = true;
	//登录密码加密参数,需与后端保持一致
	aesKey:any = "QqoJUm6Qyw8W8jua";
	aesIv:any = "0136450405060798";
	showNotice = true;
  constructor(public translateService: TranslateService,
              private localStorage: StorageService,
              private storage: StorageService,
              private HOST: RemoteServer,
			  private titleService: Title,
              @Inject(PLATFORM_ID) private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.updateLoginSession();
    }
    /*判断登录是否过期*/
    if (this.loginTime < new Date().valueOf() - localConfig.loginLifetimes * 1000) {
      this.logout();
    }
  }

  /**
   * Check whether show vm panel, depend on admin jwt token.
   * @return {boolean}
   */
  public showVmPanel(): boolean {
    // TODO check admin jwt token
    const adminJwt = this.localStorage.getItem('adminJwt');
    if (adminJwt !== null) {
      // TODO check whether have vm tools privileges
      if (true) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get admin username
   * @return {string}
   */
  public getAdminUsername(): string {
    // TODO get admin username form admin jwt
    return this.storage.getItem('admin' );
  }

  /**
   * Page Configuration
   */
  public pageConfiguration(title: string, seotitle: string = '', headerType = 1) {
    if (isPlatformBrowser(this.platformId)) {
      this.historyRecord.push(window.location.pathname + window.location.search);
    }
    this.title = title;
    this.headerType = headerType;
    const titleContent = (seotitle !== '' && seotitle !== undefined) ? seotitle : this.title;
    this.setTitle(titleContent);
  }

  /**
   * Store Configuration
   * @param {string} domain
   * @param {string} protocol
   * @param {string} port
   */
  public storeConfiguration(domain: string, protocol?: string, port?: string) {
    if (domain !== '' && domain !== undefined) {
      this.domain = domain;
    } else {
      return;
    }
    this.protocol = ((protocol !== '' && protocol !== undefined)) ? protocol : '';
    this.port = ((port !== '' && port !== undefined)) ? port : '';
    this.storeId = '1';
    this.brandCode = 'cos';
    this.websiteId = 'CN';
    this.currency = '¥ ';

    this.setHost();
  }

  public setHost() {
    if (this.protocol !== undefined && this.protocol !== '') {
      this.HOST.protocol = this.protocol;
    }
    if (this.port !== undefined && this.port !== '') {
      this.HOST.port = this.port;
    }
    if (this.domain !== undefined && this.domain !== '') {
      this.HOST.domain = this.domain;
    }
    this.HOST.apiPrefix = this.indexOf(this.domain);
    this.HOST.BASIC_HOST = this.HOST.protocol + '//' + this.HOST.domain + ':' + this.HOST.port + this.HOST.apiPrefix;
  }

  /**
   * Set login session
   * @param {string} uid
   * @param {string} name
   * @param {string} mobile
   */
  public setLoginSession(uid: string, name: string, mobile: string) {
    if (uid === '' || name === '' || mobile === '') {
      return;
    }
    this.localStorage.setItem('customer_id', uid);
    this.localStorage.setItem('username', name);
    this.localStorage.setItem('mobile', mobile);
    this.loginTime = new Date().valueOf();
    this.updateLoginSession();
    //为eftrack 注册/更新 token 属性，用于将token自动增加到埋点中
    //eftrack.registerPage({
    //  "token": this.tokenStr,// 用户login后，register一次 token
    //});
  }

  /**
   * Update login session
   */
  public updateLoginSession() {
    if (this.localStorage.getItem('customer_id') !== null) {
      this.userId = this.localStorage.getItem('customer_id');
    }
    if (this.localStorage.getItem('mobile') !== null) {
      this.mobile = this.localStorage.getItem('mobile');
      this.userName = this.localStorage.getItem('mobile');
    }
    if (this.localStorage.getItem('username') !== null) {
      this.userName = this.localStorage.getItem('username');
    }
    if ((this.localStorage.getItem('jwt') !== null)) {
      this.tokenStr = this.localStorage.getItem('jwt');
    }
  }

  /**
   * Logout
   */
  public logout() {
    this.localStorage.removeItem('customer_id');
    this.localStorage.removeItem('username');
    this.localStorage.removeItem('mobile');
    this.localStorage.removeItem('jwt');
    this.localStorage.removeItem('loginTime');
    this.localStorage.removeItem('quote_id');
    this.localStorage.removeItem('uuid');
    this.localStorage.removeItem('cloud_jwt');
    this.localStorage.removeItem('cloud_customer_id');
    this.localStorage.removeItem('wxUnionId');
    this.localStorage.removeItem('wxNickName');
    this.localStorage.removeItem('memberLevelId');
    this.localStorage.removeItem('memberIsActive');
    this.localStorage.removeItem('groupId');

    this.userId = '';
    this.userName = '';
    this.mobile = '';
    this.loginTime = '';
  }

  /**
   * Is login
   * @returns {boolean}
   */
  public isLogin(): boolean {
    return (this.localStorage.getItem('jwt') !== null && this.localStorage.getItem('cloud_jwt') !== null);
  }

  /**
   * Set title
   * @param title
   */
  public setTitle(title:any) {
    const storeName = ' - COS官网';
    if (title === undefined || title === '') {
      title = '首页';
    }
    this.titleService.setTitle(title + storeName);
  }

  /**
   *
   * @param {string} title
   * @param {string} seotitle
   * @param {number} headerType
   * @deprecated 请删除
   */
  public navigationControl(title: string,
                           seotitle: string = '',
                           headerType: number = 1) {
    this.historyRecord.push(window.location.pathname + window.location.search);
    this.title = title;
    this.headerType = headerType;
    const titleContent = (seotitle !== '' && seotitle !== undefined) ? seotitle : this.title;
    this.setTitle(titleContent);
  }


  /**
   * 选择语言包方法
   * @param lang string
   */
  public translate(lang: string) {
    // --- set i18n begin ---
    this.translateService.addLangs(['zh_CN']);
    this.translateService.setDefaultLang(lang);
    this.translateService.use('zh_CN');
    // --- set i18n end ---
  }

  /**
   * 客户端判断 PC or Mobile
   * @returns {boolean}
   * @constructor
   */
  //TODO 频繁调用，待优化成会话级（应用级）缓存
  IsPC(): boolean {
    let flag = true;
    // 执行浏览器端代码
    if (isPlatformBrowser(this.platformId)) {
      // console.info("begin IsPC in browser ...");
      const userAgentInfo = navigator.userAgent;
      const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod'];
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
    }
    return flag;
  }

  indexOf(str:any) {
    if (str.indexOf('localhost') !== -1 || str.indexOf('dev') !== -1) {
		this.gaTrackingFlag = true;
      return '/cos-dev';
    } else if (str.indexOf('test') !== -1 ) {
	  // this.gaTrackingFlag = true;
      return '/cos-test';
    } else if (str.indexOf('qazwsx') !== -1 || str.indexOf('cosstores') !== -1 || str.indexOf('cos.cn') !== -1) {
      this.gaTrackingFlag = true;
      return '/cos';
    } else {
      return '';
    }
  }

  changeBalanceTime(timestr:any){
    if(timestr !== '' && timestr !== undefined && timestr !== null){
      var dateArr = timestr.split(" ");
      return dateArr[0].substring(5).replace('-','月')+'日 '+dateArr[1];
    }
    return '';
  }
  getBalanceStartTime(presaleInfo:any) {
    if(presaleInfo !== '' && presaleInfo !== undefined){
      var object =  JSON.parse(presaleInfo);
      var balanceStartTime = object.balanceStartTime;
      var dateArr = balanceStartTime.split(" ");
      return dateArr[0].substring(5).replace('-','月')+'日 '+dateArr[1];
    }
    return '';
}

  getBalanceEndTime(presaleInfo:any) {
    if(presaleInfo !== '' && presaleInfo !== undefined){
      var object =  JSON.parse(presaleInfo);
      var balanceEndTime = object.balanceEndTime;
      var dateArr = balanceEndTime.split(" ");
      return dateArr[0].substring(5).replace('-','月')+'日 '+dateArr[1];
    }
    return '';
  }
  //判断尾款支付是否开始
  balancePaymentStatus(presaleInfo:any) {
  if(presaleInfo !== '' && presaleInfo !== undefined){
    var object =  JSON.parse(presaleInfo);
    var balanceStartTime = object.balanceStartTime;
    var balanceEndTime = object.balanceEndTime;
    if (balanceStartTime !== null && balanceEndTime !== null) {
      var now = new Date().getTime();
      var startTime = Date.parse(balanceStartTime.replace(/-/g, '/'));
      var endTime = Date.parse(balanceEndTime.replace(/-/g, '/'));
      if(now < startTime){
        return '尾款支付尚未开始';
      }
      if(now > endTime){
        return '尾款支付已关闭';
      }

      if(endTime > now && now > startTime){
        return '尾款支付已开始';
      }
    } else {
      return false
    }
  }else{
    return false;
  }
  return;
}

  formatPrice(price: any) {
    // 使用 toFixed(2) 方法保留两位小数
    return parseFloat(price).toFixed(2);
  }

}
