import {Component, Input, PLATFORM_ID, OnInit, Inject,ElementRef,AfterViewInit} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-cms-block',
  template: `
    <div [ngSwitch]="block.type">
      <div *ngSwitchCase="'customHtml'">
        <div *ngIf="block.html !== undefined" [innerHTML]="block.html | html"></div>
      </div>
      <div *ngSwitchCase="'textEditor'">
        <div *ngIf="block.html !== undefined" [innerHTML]="block.html | html"></div>
      </div>
      <app-cms-block-single-product *ngSwitchCase="'singleProduct'" [block]="block"
                                    [editMode]="editMode"></app-cms-block-single-product>
      <app-cms-block-multi-product *ngSwitchCase="'multiProduct'" [block]="block"
                                   [editMode]="editMode"></app-cms-block-multi-product>
      <app-cms-block-arrival-reminder-product *ngSwitchCase="'arrivalReminderProduct'" [block]="block"
                                              [editMode]="editMode"></app-cms-block-arrival-reminder-product>
      <app-cms-block-swiper *ngSwitchCase="'swiper'" [block]="block" [editMode]="editMode"></app-cms-block-swiper>
      <app-cms-block-reusable *ngSwitchCase="'reusable'" [block]="block" [editMode]="editMode"></app-cms-block-reusable>
      <app-cms-block-lp *ngSwitchCase="'lp'" [block]="block" [editMode]="editMode"></app-cms-block-lp>
      <app-cms-block-category-lp *ngSwitchCase="'cloudLp'" [block]="block" [editMode]="editMode"></app-cms-block-category-lp>
      <app-cms-block-tab-lp *ngSwitchCase="'cloudTab'" [block]="block" [editMode]="editMode" [containerIndex] = "containerIndex" [blockIndex] = "blockIndex"></app-cms-block-tab-lp>
    </div>
  `,
  styles: []
})
export class CmsBlockComponent implements OnInit {

  @Input() block: any;
  @Input() containerIndex: any;
  @Input() blockIndex: any;
  @Input()
	editMode!: boolean;
   public htm: any;
   public item!: string;
   public items!: any[];
   public scripts!: any[];
   public str!: string;
   
  constructor(@Inject(PLATFORM_ID) private platformId: Object,private elementRef: ElementRef) {
   // var ck = document.getElementsByTagName('div');
  }

  ngOnInit() {
    // this.appendScript();
    this.htm = this.block.html;
    const reg = /<script[^>]*>(.|\n)*?(?=<\/script>)<\/script>/gi;
    this.scripts = this.htm.match(reg);
    // console.log(this.scripts);
    if (isPlatformBrowser(this.platformId)) {
      if (this.scripts !== null) {
        this.obtainSrc();
      //  this.appendJs();
      }
    }
  }
  
    ngAfterViewInit() {
      // 确保 DOM 完全加载后
      setTimeout(() => {
        $(this.elementRef.nativeElement).find('.fullpage').fullpage();
      }, 0);
    }
	
	ngOnDestroy() {
		if ($('.fullpage').fullPage) {
		  $('.fullpage').fullPage.destroy(); // 清理 fullPage 实例
		}
	}
	  
    public obtainSrc(){
     // const content = document.querySelector('#cms-script');
     // const odiv1 = document.createElement('div');
      const newScript = document.createElement('script');
      const newScript1 = document.createElement('script');
      const cont = document.body;
      const bitArr = [];
      const _that = this;
      let odiv: HTMLDivElement;
      odiv = document.createElement('div');
      odiv.className = 'cms-script';
      if (this.scripts !== null) {
        const rxg = /<script .*?src=\"(.+?)\"/; // 获取src的内容
        for (let i = 0; i < this.scripts.length; i++) {
          bitArr.push(this.scripts[i].concat().toString());
        }
        bitArr.forEach(function(value, _index) {
          let len1 = value.indexOf('>');
          const len2 = value.lastIndexOf('<');
          _that.str = value.substring(len1 + 1, len2);
          if (_that.str !== '') {
            newScript1.innerHTML += _that.str;
            odiv.appendChild(newScript1);
           // console.log(_that.str);
          }
          _that.items = value.match(rxg);
          if (_that.items !== null) {
            // console.log(_that.items[1]);
            newScript.src = _that.items[1];
            odiv.appendChild(newScript);
          }
        });
        cont.appendChild(odiv);
      }
    }
}
