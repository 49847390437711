import { Component, ViewChild, ElementRef, HostListener, Inject, OnInit, AfterViewInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitializeService } from '../../services/initialize.service';
import $ from 'jquery';
import { StorageService } from '../../services/storage.service';
import { CartService } from '../../services/cart.service';
import { CheckoutService } from '../../services/checkout.service';
import { ProductService } from '../../services/product.service';
import { CategoryService } from '../../services/category.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { CmsService } from '../../services/cms.service';
import { CallComponentService } from '../../services/callcomponent.service';
import { CmsAdvancedPageModel } from '../../models/cms.advanced.page.model';
import { ProductModel } from '../../models/product.model';
import { ScriptService } from '../../services/script.service';
import { TrackingcodeService } from '../../services/trackingcode.service';
import { AbTrackingService } from '../../services/abtracking.service';
import { OrderService } from '../../services/order.service';
import { AddressService } from '../../services/address.service';
import { FavoriteService } from '../../services/favorite.service';
import { CosbyyouService } from '../../services/cosbyyou.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpParams } from '@angular/common/http';
import { CheckoutModel } from '../../models/checkout.model';
import { ProductSizeBarComponent } from '../../components/product/product-size-bar/product-size-bar.component';
import { ProductStrategyTagComponent } from '../../components/product/product-strategy-tag/product-strategy-tag.component';
import { Angulartics2 } from 'angulartics2';
import { BreadcrumbService } from '../../services/breadcrumb.service';
export const OUT_OF_SOON = 3;
@Component({
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [   // :enter is alias to 'void => *'
				style({ opacity: 0 }),
				animate(500, style({ opacity: 1 }))
			]),
			transition(':leave', [   // :leave is alias to '* => void'
				animate(500, style({ opacity: 0 }))
			])
		])
	],
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit{
	public ispdp :boolean = true;
	public lowInStock : any;//低库存提示文案
	public presalePrice : any;
	public isSoldOutSoon : any = false;//低库存
	public productModel! : ProductModel;
	public cmsAdvancedModel! : CmsAdvancedPageModel;
	// 货币
	public currency : any;
	public productRecommends! : Array<any>;
	// 从子组件中获取尺寸
	productSizeName : any = '尺码';
	// 从子组件中获取款号
	public name : any;
	/*从子组件中获取sku*/
	public productSku : any;
	// 从子组件中获取放入购物袋按钮是否变暗
	public confirm : any = 0;
	// 计算图片翻页功能(总页数)
	public curPage : any;
	// 图片默认从第一张开始
	public page : any = 1;
	// 商品件数
	public goodsNumber : any = '1';
	// 返回message
	public dataMessage : any;
	public num : any = 1;
	public productId : any; // efs productId
	public itemId : any; // cloud itemId


	// 获取选择的颜色名称
	public selectColorName : any = '请选择';
	// 判断是否选中尺寸
	public modalSize : any = 2;
	// 身份密匙
	public quoteId : any;
	// 返回的添加购物车信息
	public shoppingData : any = { code: 1, 'data': '', 'msg': '' };
	// 添加到购物车图片url
	public cartImgUrl : any;
	// 添加到购物车显示数据
	public cartInfo : any = {};
	// 小图集合
	public smallImg : any = {};
	public productColors : any;
	// 默认的颜色
	public productColorName : any;
	// 总价
	public totalPrice : any;
	// 商品数量
	public numbers : any = '1';
	// 参数 用于判断h5站位图
	public data : any = 1;
	// size集合
	public productSizeArray : any = [];
	// 款级属性
	public styleAttribute : any = [];
	// 商品状态
	public ccProductStatus : any;
	// 颜色key
	public colorKey : any = 0;
	// 颜色id
	public productColorId : any;
	// 颜色已售罄
	public productColorSoldOut : any = true;
	//尺码已售罄
	public sizeSoldOut : any = false;
	// 尺码（s/m/l/xl）
	public productSizeValue : any;
	public productSizeId : any;
	public buttonPop : any = 0;
	// tabs切换
	public tabs : any = { 'tab1': true, 'tab2': false, 'tab3': false, 'tab4': false, 'tab5': false };
	// 支付及配送
	public deliveryMethods : any;
	// 颜色图片
	public colorImg : any = '';
	public colorImgList : any;
	// button text
	public buttonText : any = '加入购物袋';
	qrcodeSrc : any = '';

	// 隐藏尺码选择 （牛仔裤商品）
	public hideSizeChart : any = false;

	// 商品信息button
	public productInfo : any = '商品信息';
	public isPc : any = false;
	public skuId : any;
	public show_modal : any = false;
	public message : any;
	public buttonLock : any = true;
	public where : any = 'pdp';
	public showSizeChart : any = false;
	public colorSoldOut : any;
	public hiddenWaitLoad : any = true;
	public mPdpShow : any = false;
	public promotionTagList : any = []; // 促销标签
	public customPromotionTagList : any = []; // 自定义促销标签
	public arrivalRemindDisplay : boolean = false;
	public purchaseLimit : any = false;
	public memberPurchase : any = '';
	tagColor : any = '#FF571A';
	customTagColor : any = '#1B1B1B';

	public remindSuccess : any = false;
	public remindError : any = false;
	public remindErrorMsg : any;
	public remindBtnText : any = '到货提醒';
	public remindResult : any = { code: '', msg: '', data: '' };
	public specialTagStart : any = '2020-05-07 00:00:00';
	public specialTagEnd : any = '2020-05-15 00:00:00';
	public tagStyle : any = 'tagStyle';

	public favoritePageNo = 1;
	public favoritePageSize = 100;
	public favoriteList : any = { code: 1, data: '', msg: '' };
	public favoriteItemIds : any = [];
	// 商品店铺级上下级状态
	public approveData : any = { code: 0, data: { approveStatus: '', statusId: 0 } };
	public useStoreApproveStatus = false;
	public storeApproveStatus : any;
	public availableQtyRs : any = { code: 0, data: { availableQty: 0 } };

	public pdpVideoConfig : any = { code: 0, data: { value: false } };
	//显示video
	public showVideo = false;
	public includeVideo : boolean = false;//包含视频
	public arrivalNoticeConfig : any = { code: 0, data: { value: false } };
	public similarConfig : any = { code: 0, data: { value: false } };
	public wholeConfig : any = { code: 0, data: { value: false } };
	public pdpRecommendConfig : any = { code: 0, data: { value: false } };

	public showArrivalNotice : boolean = false;
	public onPresale : boolean = false;
	public presaleBtnText : any;
	public displayPresaleClause : boolean = false;
	public presaleCheckoutData : any = { code: 1, data: { cartKey: '' }, 'msg': '' };
	public agreeTerms : boolean = false;
	public checkoutModel! : CheckoutModel;
	public selectedSizeName : string = '选择尺码';
	public oneSizeProduct : boolean = false;
	public oneSizeObject : any = {
		skuCode: '',
		valueName: '',
		qty: 0,
		skuId: '',
		barcode: '',
		valueId: '',
		propertyName: '',
	};
	
	public selectedSize : any = {
		skuCode: '',
		valueName: '',
		qty: 0,
		skuId: '',
		barcode: '',
		valueId: '',
		propertyName: '',
	};
	
	// abtest可操控的状态值定义
	public abstate : any = {
		addCartBtnClass: "btn-light", //示例，加入购物车按钮的样式，在abtest中可配置不同版本所展示的样式来覆盖此值
	}

	public selectFloatSize : boolean = false;
	public initDescOffset : any;
	public isbool : boolean = false;
	public mAddToBagScrollStopped : boolean = false;//H5加购按钮是否平铺
	public cosbyyouObject : any = { code: 1, data: {}, 'msg': '' };
	public cosbyyouArray : any = [];
	public rulePriceJson : any = { price: '', rulesArr: [] };//策略价+预热价
	public strategyRes : any = [];
	public isLogin : boolean = false;
	public memberLevelId : any;
	public show_favorite_modal : boolean = false;
	public favoriteData : any = { code: 1, data: '', msg: '' };
	public relationLimitSkuIds : any;
	public limitedPurchaseQty : any = -2;//自定义赋值，表示未调用限购数量查询接口
	public limitedPurchaseRes : any;
	public customerLimitQty : any = 0;//还可购买数据
	public defaultSkuCode : any;
	public defaultSizeValue : any;
	public defaultSizeCode : any;

  public recCid = 0;
  public recommendWhole : any = [];//推荐搭配
	public recommendSimilar : any = [];//同类商品
	public frontCustomParameters : any;

	public showMeasureMethod : boolean = false;
	public measurePic! : string;//测量图
	public specialMeasurePic! : string;
	public howToMeasurePic! : string;//如何测量图（默认收缩）
	public specialHowToMeasurePic! : string;
	public sizeChartPic! : string;//尺码表
	public specialSizeChartPic! : string;
	public tryonPic! : string;//试穿报告、
	public specialTryonPic! : string;
	public recommendPic! : string;//尺码推荐表
	public specialRecommendPic! : any;
	public hasSizeGuide : boolean = false;
	public hasBreadcrumb : boolean = false;
	public recommendFrontCode : any;
	public showQuickAddBox : boolean = false;
	public tabId! : string;
	
	public backRectY: number = 0;
	public finalSizeImageUrl : any;
	public recommendSalePrice:any;
	
	
	public switchColors : boolean = false;
	public clickSubMenu : boolean = false;
	// 获取子组件的实例；
	@ViewChild('sizeBarComponent')
	sizeBarComponent! : ProductSizeBarComponent;
	@ViewChild('productStrategyTagComponent')
	productStrategyTagComponent! : ProductStrategyTagComponent;


	constructor(private angulartics2 : Angulartics2,
		private init : InitializeService,
		private routerInfo : ActivatedRoute,
		public productService : ProductService,
		public cartService : CartService,
		public checkoutService : CheckoutService,
		private router : Router,
		public category : CategoryService,
		public cmsService : CmsService,
		private el : ElementRef,
		private render : Renderer2,
		private script : ScriptService,
		public eventSubscribeService : CallComponentService,
		@Inject(PLATFORM_ID) private platformId : Object,
		private tracking : TrackingcodeService,
		private abTracking : AbTrackingService,
		private orderService : OrderService,
		private address : AddressService,
		private favoriteService : FavoriteService,
		private sanitizer : DomSanitizer,
		private cosbyyouService : CosbyyouService,
		private localStorage : StorageService,
		private breadcrumbService : BreadcrumbService,) {
		this.script.load('jqzoom').then(_data => { }).catch(error => error);
	}

	ngOnInit() {
		//尝试应用abtest数据
		// this.abTracking.applyAbTestForPage(this);
		this.memberLevelId = this.localStorage.getItem('memberLevelId');
		this.currency = this.init.currency;
		this.num = 0;
		this.isPc = this.init.IsPC();
		var mobPdp = this.isPc ? false : true;
		this.eventSubscribeService.mobPdp.emit(mobPdp);
		
		
		// 订阅尺码label is-disable是否显示
		this.eventSubscribeService.showSizeBoxCommon.subscribe((value:any) => {
		  this.showQuickAddBox = true;
		  this.recommendFrontCode = value.frontCode;
		  this.recommendSalePrice = value.salePrice;
		});
		
		this.eventSubscribeService.subscribeClickSubMenu.subscribe((value:any) => {
		  if (value) {
			this.clickSubMenu = true;
		  }
		});
				
		this.isLogin = this.init.isLogin();
		const currentUrl = this.router.url;
		// 到货提醒button
		this.productService.showArrivalNotice(this.init.merchantId, this.init.cloudStoreId).then(result => {
			this.arrivalNoticeConfig = result;
			if (this.arrivalNoticeConfig.code === 1000) {
				this.showArrivalNotice = this.arrivalNoticeConfig.data.value;
			}
		}).catch(error => error);

		//读取推荐开关配置
		this.productService.showPdpRecommend(this.init.merchantId, this.init.cloudStoreId).then(result => {
			this.pdpRecommendConfig = result;
			if (this.pdpRecommendConfig.code === 1000) {
				if (this.pdpRecommendConfig.data.value) {
					this.productService.showRecommendSimilar(this.init.merchantId, this.init.cloudStoreId).then(similarResult => {
						this.similarConfig = similarResult;
						if (this.similarConfig.code === 1000) {
							this.productService.enableSimilar = this.similarConfig.data.value;
						}
					}).catch(error => error);

					this.productService.showRecommendWhole(this.init.merchantId, this.init.cloudStoreId).then(wholeResult => {
						this.wholeConfig = wholeResult;
						if (this.wholeConfig.code === 1000) {
							this.productService.enableWhole = this.wholeConfig.data.value;
						}
					}).catch(error => error);
				}
			}
		}).catch(error => error);

		if (currentUrl.indexOf('arrivalRemind') > -1) {
			this.arrivalRemindDisplay = true;
		}
		
		// 设置特殊时间段促销标签样式
		const specialTagStartTime = Date.parse(this.specialTagStart.replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
		const specialTagEndTime = Date.parse(this.specialTagEnd.replace(/-/g, '/'));
		const specialTimestamp = (new Date()).getTime();
		if (specialTimestamp >= specialTagStartTime && specialTimestamp < specialTagEndTime) {
			this.tagStyle = 'specialTagStyle';
			this.tagColor = '#99b8ae';
		}
		// 调用服务
		this.routerInfo.params.subscribe(
			params => {
				this.hiddenWaitLoad = true;
				const sku = params['id'];
				this.recCid = 0;
				if (this.routerInfo.snapshot.queryParams['rec-cid'] != null) {
				  this.recCid = this.routerInfo.snapshot.queryParams['rec-cid'];
				}

				// 推荐数据初始化
				this.productRecommends = [];
				this.productService.getCloudProductBySku(sku).then((res : any) => {
					if (parseInt(res.code, 0) !== 1000) {
						return this.router.navigate(['/404']);
					} else {
						this.eventSubscribeService.subscribePdp.next(true);
						this.itemId = res.data.itemId;
						this.hiddenWaitLoad = false;
						this.productModel = new ProductModel(res.data);
						this.styleAttribute = [res.data.itemName, res.data.itemId];
						//判断店铺级到货提醒开关是否打开
						if (this.showArrivalNotice) {
							this.arrivalRemindDisplay = this.productModel.arrivalNotice == 1 ? true : false;
						}
						if (this.productModel.itemName.indexOf("牛仔裤") != -1 || this.productModel.itemName.indexOf("牛仔短裤") != -1) {
							this.hideSizeChart = true;
						}
						//重置选择尺码
            this.selectedSizeName = '选择尺码';
						this.selectedSize = {};
            this.tabId = '';

						// 店铺级商品状态
						this.productColors = this.productModel.getColorsCloud(sku);
						//设置面包屑,且避免切换cc重复追加
						if(this.recCid != 0){
						  this.breadcrumbService.resetBreadcrumb('');
						  this.category.getNavByCid(this.recCid).then((rec_re:any) => {
							if(rec_re['code']==1000 && rec_re['data'].length > 0) {
							  this.breadcrumbService.addBreadcrumb(rec_re['data'][0]['navName'],rec_re['data'][0]['urlPath']);
							}
							this.addBreadcrumb();
						  });
						} else {
						   this.eventSubscribeService.noneBreadcrumb.next('PDP');
						}

						// 获取促销
						this.promotionTagList = [];
						this.customPromotionTagList = [];
						this.productService.getProductPromotionTag(this.itemId, this.productModel.valueId, this.productModel.productListByValueId[this.productModel.valueId]['price']).then((pd : any) => {
							if (pd['code'] === 1000) {
								if (pd['data'].length > 0) {
									pd['data'].forEach((tag_item : any) => {
										//促销标签有效时间判断
										if (tag_item['beginTime'] !== null && tag_item['endTime'] !== null && tag_item['beginTime'] !== '' && tag_item['endTime'] !== '') {
											if (this.productService.promotionTime(tag_item['beginTime'], tag_item['endTime'])) {
												if (tag_item['promotionType'] != 'custom_tag') {
													this.promotionTagList.push(tag_item);
												} else if (tag_item['promotionType'] == 'custom_tag') {
													this.customPromotionTagList.push(tag_item);
												}
											}
										}
									});
									
									let that = this
									setTimeout(function(){
										// 判断是否为切换颜色操作
										const domElement = document.getElementById('mAddToBagFloat')
										$("#mAddToBagFloat").animate({
											top: (domElement!.getBoundingClientRect().top - ($(".h5PDPTag")[0].clientHeight+20))+"px"
										}, 1, () => {
											var changeColor = this.localStorage.getItem('change-color');
											//区分默认加载与切换颜色
											if(changeColor){
												that.backRectY = that.backRectY + ($(".h5PDPTag")[0].clientHeight+20);
											}else{
												that.backRectY = that.backRectY - ($(".h5PDPTag")[0].clientHeight+20)
											}
										})
									}, 500)
									
									if (this.customPromotionTagList.length > 0) {
										this.customPromotionTagList.sort(function (a : any, b : any) {
											return a['sortOrder'] - b['sortOrder'];
										});
									}
								}
							}
						});

						this.ccProductStatus = this.productModel.getCCstatus();
						this.productSizeArray = this.productModel.sku_list;

						// 颜色下拉时候是否售罄的数据
						this.colorSoldOut = this.productModel.colorQty;

						// 获取路由颜色
						if (this.productModel.valueId) {
							this.changeColor(this.productModel.valueId, true);
						}

						// 获取cc级别的sku
						this.productModel.ccSku = this.productModel.productListByValueId[this.productModel.valueId]['frontCode'];
						// 获取二维码地址
						const utm_source = this.localStorage.getItem('utm_source');
						const utm_medium = this.localStorage.getItem('utm_medium');
						this.qrcodeSrc = this.productService.getQrcode(this.productModel.itemCode, this.productColorName, this.productModel.ccSku, utm_source, utm_medium);

						// 设置策略价格
						const item = this.productModel.productListByValueId[this.productModel.valueId];

						this.productModel.cloudPrice = item['markingPrice'];
						this.productModel.cloudSalePrice = item['price'];


						this.productModel.is_new = this.productService.checkIsNew(this.productModel.productListByValueId[this.productModel.valueId]['newStartTime'], this.productModel.productListByValueId[this.productModel.valueId]['newEndTime']);
						this.productModel.is_exclusively = this.productService.checkIsExclusively(this.productModel.productListByValueId[this.productModel.valueId]['frontTag']);
						this.productModel.frontMaterialTag = this.productModel.productListByValueId[this.productModel.valueId]['frontMaterialTag'];

						this.productModel.frontDescription = this.sanitizer.bypassSecurityTrustHtml(this.productModel.productListByValueId[this.productModel.valueId]['frontDescription']);
						this.productModel.frontFeature = this.productModel.productListByValueId[this.productModel.valueId]['frontFeature'];
						this.productModel.frontWash = this.productModel.productListByValueId[this.productModel.valueId]['frontWash'];
						this.productModel.sizeTableImageUrl = this.productModel.productListByValueId[this.productModel.valueId]['sizeTableImageUrl'];

						if (!!this.productModel.sizeTableImageUrl) {
							this.finalSizeImageUrl = this.productModel.sizeTableImageUrl.replace('http://img.cos.client.efashionchina.com', 'https://www.cos.cn/img');
						}

						this.cosbyyouService.getPdpWallPics(this.productModel.ccSku).then(result => {
							this.cosbyyouObject = result;
							if (this.cosbyyouObject.code === 1000) {
								let photos = this.cosbyyouObject.data.photos;
								this.cosbyyouArray = photos;
							}
						}).catch(error => error);

						//组装自定义参数
						this.getCustomParameters();

						//查询cloud推荐搭配
						this.productService.getProductRecommends(this.itemId, this.productModel.valueId).then(recommends => {
							const recommendWhole : any = [];
							const recommendSimilar : any = [];
							this.recommendWhole = recommendWhole;
							this.recommendSimilar = recommendSimilar;
							if (recommends.total > 0) {
								recommends.content.forEach((recommendItem : any) => {
									// 搭配推荐
									if (recommendItem['recommendBlock'] == 'whole') {
										recommendWhole.push(recommendItem)
									}

									// 同类商品
									if (recommendItem['recommendBlock'] == 'similar') {
										recommendSimilar.push(recommendItem)
									}
								});

								this.recommendWhole = recommendWhole;
								this.recommendSimilar = recommendSimilar;
							}
						}).catch(error => error);
						// 写入推荐信息
						this.productService.getProductCategory(this.itemId).then((catData : any) => {
							if (catData['code'] == 1000) {
								const ids : any = [];
								const id_names : any = [];
								if (catData['data'].length > 0) {
									catData['data'].forEach((cat_item : any) => {
										ids.push(cat_item['classId']);
										id_names.push(cat_item['className']);
									});
								}
								const productItem : any = this.productModel;
								productItem['category_ids'] = ids;
								productItem['category_names'] = id_names;
								// matomo trakcing
								this.tracking.productview(productItem);
							}
						}).catch(error => error);


						//查询策略价标签
						this.filterStrategyTag(this.itemId, this.productModel.valueId);

						this.init.setTitle(this.productModel.itemName);
						this.init.pageConfiguration(this.productModel.itemName);

						this.gaTrackingProductDetail();

						// 页面返回顶部
						if (isPlatformBrowser(this.platformId)) {
							this.init.pageConfiguration(this.productModel.itemName);
						}
						return;
					}
				}).catch(error => error);
			}
		);

		this.productService.showPdpVideo(this.init.merchantId, this.init.cloudStoreId).then(result => {
			this.pdpVideoConfig = result;
			if (this.pdpVideoConfig.code === 1000) {
				this.showVideo = this.pdpVideoConfig.data.value;
			}
		}).catch(error => error);
		
		//监听登录状态，登录后查收藏数据
		this.eventSubscribeService.loginRegisterBox.subscribe((value: boolean) => {
			if (this.init.isLogin()) {
				this.resetFavoritesService();
				this.favoriteItems();
			}
		});
		
		// 仅登录状态查收藏
		if (this.init.isLogin()) {
			this.resetFavoritesService();
			this.favoriteItems();
		}

		//卡片拖拽
		let that = this
		setTimeout(function(){
			that.cardDragAnimate()
		} , 500)
	}
	
	cardDragAnimate(){
		let that = this
		const domElement = document.getElementById('mAddToBagFloat')
		const rect = domElement!.getBoundingClientRect()
		that.backRectY = rect.top + window.scrollY
		let startX = 0
		let startY = 0
		let endX = 0
		let endY = 0
		let isBlock = false
		let direction = ''
		let currentTop = domElement!.getBoundingClientRect().top
		
		let maxBackOffset = that.backRectY / 2
		$("#mAddToBagFloat").on('touchstart', function(e:object) {
			startX = e["originalEvent"]["changedTouches"][0].pageX
			startY = e["originalEvent"]["changedTouches"][0].pageY
		});
		
		//touchmove 持续位移
		$("#mAddToBagFloat").on('touchmove', function(e:object) {
			if( isBlock ) return
			endX = e["originalEvent"]["changedTouches"][0]["pageX"]
			endY = e["originalEvent"]["changedTouches"][0]["pageY"]
			//获取滑动距离
			let distanceX = endX-startX;
			let distanceY = endY-startY
			console.log('move:', currentTop, that.backRectY, maxBackOffset)
			if( currentTop > 0 ){
				currentTop = domElement!.getBoundingClientRect().top
				//判断滑动方向
				if(Math.abs(distanceX)<Math.abs(distanceY) && distanceY<0 && (currentTop + distanceY) > 90){
					direction = 'up'
					if( currentTop <= maxBackOffset ){ //隐藏图标
						$(".h5heart").animate({ opacity: 0 }, 300, () => {})
					}else{
						$(".h5heart").animate({ opacity: 1 }, 300, () => {})
					}
					if( distanceY < 0 && !isBlock ){
						isBlock = true
						$("#mAddToBagFloat").animate({
							top: "+="+(distanceY)+"px"
						}, 100, () => {
							isBlock = false
						})
					}
				}else if(Math.abs(distanceX)<Math.abs(distanceY) && distanceY>0 && (currentTop + distanceY) < that.backRectY){
					$(".h5heart").animate({ opacity: 1 }, 300, () => {})
					direction = 'down'
					if( distanceY > 0  && !isBlock){
						isBlock = true
						$("#mAddToBagFloat").animate({
							top: "+="+distanceY+"px"
						}, 100, () => {
							isBlock = false
						})
					}
				}
			}
		});
		
		//touch结束 判断是否回弹还是展开
		$("#mAddToBagFloat").on('touchend', function(e:object) {
			if( direction == 'down' ){
				$("#mAddToBagFloat").removeClass("overflow-y");
				console.log('end:', currentTop, maxBackOffset, that.backRectY)
				/* if( currentTop <= maxBackOffset  ){ //回弹
					isBlock = true
					$("#mAddToBagFloat").animate({
					  top: "90px"
					}, 300, () => {
						isBlock = false
					})
				}else{ */
					isBlock = true
					$("#mAddToBagFloat").animate({
					  top: that.backRectY+"px"
					}, 300, () => {
						isBlock = false
					})
				//}
			}
			if( direction == 'up' ){
				/* if( currentTop >= maxBackOffset ){ //向上回弹
					isBlock = true
					$("#mAddToBagFloat").animate({
					  top: that.backRectY+"px"
					}, 300, () => {
						isBlock = false
					})
					
				}else{ */
					$("#mAddToBagFloat").addClass("overflow-y");
					
					isBlock = true
					$(".h5heart").animate({ opacity: 1 }, 300, () => {})
					$("#mAddToBagFloat").animate({
					  top: "90px"
					}, 300, () => {
						isBlock = false
					})
				//}
			}
			direction = ''
			currentTop = 1
		});
	}
	
	cardClickAnimate(){
		let that = this
		const domElement = document.getElementById('mAddToBagFloat')
		const rect = domElement!.getBoundingClientRect()
		
		if( rect.top !=  that.backRectY ){
			$(".h5heart").animate({ opacity: 1 }, 300, () => {})
			$("#mAddToBagFloat").animate({ scrollTop: 0 }, 300, () => {
				$("#mAddToBagFloat").removeClass("overflow-y");
			})
			if(!!this.localStorage.getItem("change-color")){
				//切换颜色后第一次展开
				$(".h5heart").animate({ opacity: 0 }, 300, () => {})
				$("#mAddToBagFloat").addClass("overflow-y");
				$("#mAddToBagFloat").animate({ top: "90px" }, 300, () => {})
				that.backRectY = rect.top;
				this.localStorage.removeItem("change-color");
			}else{
				$("#mAddToBagFloat").animate({ top: that.backRectY+"px"}, 300, () => {})
			}
			
		}else{
			$(".h5heart").animate({ opacity: 0 }, 300, () => {})
			$("#mAddToBagFloat").addClass("overflow-y");
			$("#mAddToBagFloat").animate({ top: "90px" }, 300, () => {})
		}
	}
	
	addToBuy() {
		//售罄禁止加购
		if(!this.productColorSoldOut){
			var number:string = '1';
			this.addShoppingCar(this.productSku,number);
		}
	}

	gaTrackingProductDetail() {
		if (this.init.gaTrackingFlag) {
			this.tracking.gaTrackingProductDetail(this.productModel);
		}
	}

	favoriteItems() {
		this.favoriteService.getFavoriteIds().then(val => {
			this.favoriteList = val;
			if (this.favoriteList.code == 1000) {
				if (this.favoriteList.data.length > 0) {
					this.favoriteList.data.forEach((item : any) => {
						this.favoriteItemIds[item.itemId + '_' + item.valueId] = item.favoritesId;
					});
				}
			}
		});
	}

	//删除收藏
	delFavoriteItem(itemId : any, valueId : any) {
		let favoriteId = this.favoriteItemIds[itemId + '_' + valueId];
		if (favoriteId) {
			const params = new HttpParams()
				.set('favorites_id', favoriteId);
			this.favoriteService.delFavoriteItem(favoriteId, params).then(val => {
				if (val.code === 1000) {
					var result : any = { 'code': val.code, 'type': 'remove' };
					this.eventSubscribeService.changeFavoriteBox.next(result);
					delete (this.favoriteItemIds[itemId + '_' + valueId]);
					$('#' + itemId + '_' + valueId + '_after_favorite').css('display', 'none');
					this.resetFavoritesService();
					this.favoriteItems();
					this.gaTrackingFavorite('unsave', 'remove_from_favourites');
				}
			})
		}
	}
	
	quickAdd(event:any){
		this.recommendFrontCode = event.frontCode;
		this.recommendSalePrice = event.salePrice;
		this.showQuickAddBox = true;
		//标记选择尺码即自动加购
		this.tabId = 'add-to-buy';
	}
	
	//标记会员提前购
	public checkForwardPurchaseLabel(memberLevelIds : any) {
		if (memberLevelIds) {
			this.memberPurchase = 'yes';
		}
		return true;
	}

	getCustomParameters() {
		this.productService.customParameters(this.productModel.frontProperties, this.productModel.valueId).then(
			res => {
				if (!!res) {
					var frontCustomParameters = JSON.parse(this.productService.frontCustomParameters);
					if (!!frontCustomParameters) {
						if (!!frontCustomParameters.relationLimitSkuIds) {
							this.relationLimitSkuIds = frontCustomParameters.relationLimitSkuIds;
						}
						this.frontCustomParameters = frontCustomParameters;
						if (!!this.frontCustomParameters) {
							
							var option = this.frontCustomParameters;
							// 测量图
							this.measurePic = !!option.measurePic ? option.measurePic : '';
							// 如何测量图（默认收缩）
							this.howToMeasurePic = !!option.howToMeasurePic ? option.howToMeasurePic : '';
							// 尺码表
							this.sizeChartPic = !!option.sizeChartPic ? option.sizeChartPic : '';
							// 试穿报告
							this.tryonPic = !!option.tryonPic ? option.tryonPic : '';
							//尺码推荐表
							// this.recommendPic = option.recommendPic;
							if ((!!this.measurePic || !!this.howToMeasurePic || !!this.tryonPic) || (!!this.sizeChartPic && this.getImageHeight(this.sizeChartPic) > 60)) {
								this.hasSizeGuide = true;
							}
						}
					}
				}
			}
		)
	}
	operateMeasureMethod() {
		this.showMeasureMethod = !this.showMeasureMethod;
	}
	getImageHeight(url : any) {
		var img = new Image();
		img.src = url;
		// 如果图片被缓存，则直接返回缓存数据
		if (img.complete) {
			return img.height;
		} else {
			// 完全加载完毕的事件
			img.onload = function () {
				return img.height;
			}
		}
		return 0;
	}
	public checkGeneralPromotionTag(customerGroupIds : any) {
		if (customerGroupIds) {
			var customerGroupId = this.localStorage.getItem('groupId');
			if (customerGroupId && customerGroupIds.indexOf(parseInt(customerGroupId, 0)) !== -1) {
				return true;
			} else {
				return false;
			}
		}
		return true;
	}


	changeColor(colorId : string, isInit = false) {
		if (colorId) {
			this.promotionTagList = [];
			this.customPromotionTagList = [];
			$('#productSizeBar').removeClass('m-float-size-container');
				
			if (!isInit) {
				this.switchColors = true;
				this.localStorage.setItem('change-color',true);

				const currentUrl = this.router.url;
				const urlArr = currentUrl.split('/');
				urlArr.pop();
				for (const i in this.productColors) {
					if (this.productColors[i].color_id.toString() === colorId.toString()) {
						urlArr.push(this.productColors[i].url_path);
					}
				}
				const targetUrl = urlArr.join('/');
				this.router.navigate([targetUrl]);
				return;
			}
			
			this.productColorId = colorId;
			if (this.productSizeArray[colorId].length == 1) {
				this.oneSizeProduct = true;
			}
			this.defaultSkuCode = this.productSizeArray[colorId][0].skuCode;
			this.defaultSizeValue = this.productSizeArray[colorId][0].valueName;
			this.defaultSizeCode = this.productSizeArray[colorId][0].valueId;
			this.productColorSoldOut = this.productSizeArray[colorId].map((item : any) => parseInt(item.qty, 10)).every((it : any) => it === 0);

			// 颜色没有下的产品都没货的时候按钮显示已售罄
			if (this.productColorSoldOut || this.ccProductStatus.toString() === '1' || this.productModel.productListByValueId[colorId]['approveStatus'] === 'instock') {
				this.productColorSoldOut = true;
				this.buttonText = '已 售 罄';
			} else {
				this.buttonText = '加入购物袋';
			}

			// 获取添加到购物车的颜色名称
			this.productColorName = this.productModel.productListByValueId[colorId]['valueName'];
			// 获取添加到购物车的ImgURL
			this.colorImgList = this.productModel.getImage(colorId);

			this.includeVideo = this.productModel.includeVideo;
			// this.cartImgUrl = this.colorImgList.length > 0 ? (this.colorImgList[0]['url'].indexOf('.mp4') > 0 ? this.colorImgList[1]['url'] : this.colorImgList[0]['url']) : '';
			// 改变颜色时候发射
			this.eventSubscribeService.changeSizeLabel.emit(false);

			this.getCustomParameters();
			return;
		} else {
			return false;
		}
	}
	
	addBreadcrumb(){
		this.hasBreadcrumb = true;
		var breadcrumbItems : any = { "itemName": this.productModel.itemName, "itemPath": this.router.url };
		this.breadcrumbService.addItemBreadcrumb(breadcrumbItems);
	}

	
	changeButtonLock() {
		this.buttonLock = true;
	}
	
	glideSize(size : any) {
		const Size = size;
		if (Number(Size.qty) == 0 && Size.skuCode) {
			this.sizeSoldOut = true;
		} else {
			this.sizeSoldOut = false;
		}
		
		//低库存判断
		if((Number(Size.qty) <= OUT_OF_SOON && Number(Size.qty) > 0)){
			this.isSoldOutSoon = true;
		}
	}
		
	changeSize(size : any) {
		this.lowInStock = '';
		const Size = size;
		if (!!Size.valueName) {
			if (this.rulePriceJson.hasRule === true) {
			} else {
				this.productModel.cloudPrice = size.markingPrice;
				this.productModel.cloudSalePrice = size.price;
			}
			this.selectedSizeName = Size.valueName;
			this.selectedSize = Size;
		}

		if (!this.isPc && this.oneSizeProduct) {
			this.oneSizeObject = Size;
		}
		
		if (Number(Size.qty) == 0 && Size.skuCode) {
			this.sizeSoldOut = true;
		} else {
			this.sizeSoldOut = false;
		}
		
		//低库存判断
		if((Number(Size.qty) <= OUT_OF_SOON && Number(Size.qty) > 0)){
			this.isSoldOutSoon = true;
			this.lowInStock = '库存少于3件';
		}		
 
		this.productSizeValue = Size.valueName;
		this.productSizeId = Size.valueId;
		this.productSku = Size.skuCode;
		this.skuId = Size.skuId;

		//预售开启
		if (Size.onPresale == 1 && Size.payDepositStart) {
			this.onPresale = true;
			if (this.sizeSoldOut) {
				this.presaleBtnText = '已售罄';
			} else {
				if (Size.presaleType == 'advance_payment') {
					this.presaleBtnText = '立即支付定金';
					this.presalePrice = Size.presalePrice;
				} else {
					this.presaleBtnText = '立即支付定金';
				}
			}
		} else {
			this.onPresale = false;
			if (this.sizeSoldOut) {
				this.buttonText = '已售罄';
			}else{
				this.buttonText = '加入购物袋';
			}
		}
		
		//查询限购关联skus 
		this.productService.customParameters(this.productModel.frontProperties, this.productModel.valueId);

		var frontCustomParameters = JSON.parse(this.productService.frontCustomParameters);

		if (!!frontCustomParameters) {
			if (!!frontCustomParameters.relationLimitSkuIds) {
				this.relationLimitSkuIds = frontCustomParameters.relationLimitSkuIds;
			}
			this.frontCustomParameters = frontCustomParameters;
		}

		//点击选择，选中尺码后关闭浮层，不自动加购
		if(this.tabId == 'select-size'){
			this.closeLayer();
		}else{
			this.closeLayer();
			//均码商品默认显示尺码
			if(!this.oneSizeProduct){
				var number:string = '1';
				this.addShoppingCar(this.productSku,number);
			}
		}

		//登录用户选中尺码即查限购数量，非登录用户，登录后加购时调用
		if (this.init.isLogin()) {
			this.productService.limitPurchase(this.skuId, this.relationLimitSkuIds).then(val => {
				this.limitedPurchaseRes = val;
				if (this.limitedPurchaseRes.code == 1000) {
					this.limitedPurchaseQty = this.limitedPurchaseRes.data.activityLimitQty;
					this.customerLimitQty = this.limitedPurchaseRes.data.customerLimitQty;
				}
			});
		}
	}

	changeBtnText() {
		if (!this.productColorSoldOut) {
			this.buttonText = '尺码';
		}
	}

	recoverBtnText() {
		if (!this.productColorSoldOut) {
			this.buttonText = '加入购物袋';
		}
	}

	closeLayer() {
		$(".pageTopLayer").hide();
		$("#add-to-buy").hide();
		$("#add-to-buy").removeClass('scrollUp');
		if(this.tabId == 'select-size' || this.tabId == 'add-to-buy'){
			$("#add-to-buy").hide();
			$('body').removeClass('body_overflow_hidden');
			if(this.tabId == 'select-size' && !this.productSku){
				this.tabId = '';
			}
		}else{
			$("#"+this.tabId).hide();
		}
		$('body').removeClass('body_overflow_hidden');
		if(!this.init.IsPC()){
			$("#"+this.tabId).removeClass('scrollUp');
		}
		this.showQuickAddBox = false;
	}
 
	
	// 切换页面tabsClass
	changeTab(tab : any) {
		this.tabId = tab.tabId;	
		if (this.tabId == 'payment-delivery') {
			// 获取cmsPage的支付及配送方式html
			this.cmsAdvancedModel = new CmsAdvancedPageModel({});
			this.cmsService.getAdvancedPage('payment-delivery').then(model => {
				this.cmsAdvancedModel = model;
				let jsonInfo;
				jsonInfo = this.cmsAdvancedModel.jsonDecodeContainers();
				this.deliveryMethods = jsonInfo[0][0].html;
				$(".pageTopLayer").show();
				$("#" + this.tabId).show();
				if(!this.init.IsPC()){
					$("#" + this.tabId).addClass('scrollUp');
				}
			}).catch(error => error);
		} else {
			if(this.tabId == 'select-size' && this.oneSizeProduct){
				return;
			}
			$(".pageTopLayer").show();
			if(this.tabId == 'select-size' || this.tabId == 'add-to-buy'){
				$("#add-to-buy").show();
				if(!this.init.IsPC()){
					$("#add-to-buy").addClass('scrollUp');
				}
				// $('body').addClass('body_overflow_hidden');
			}else{
				$("#"+this.tabId).show();

				if(!this.init.IsPC()){
					$("#"+this.tabId).addClass('scrollUp');
				}else{
					if(this.tabId == "item-details"){
						$("#item-describe").slideDown("slow");
						$("#describe-plus-svg").addClass("block-hidden");
						$("#describe-minus-svg").removeClass("block-hidden");
					}
					// $('body').addClass('body_overflow_hidden');
				}
			}
		}
	}


	addItemToCart(pSku : string, skuId : string, numbers : string, itemCode : string, itemName : string) {
		if (!this.init.isLogin()) {
			this.eventSubscribeService.loginRegisterBox.next('1');
		} else {
			this.productService.limitPurchase(skuId, this.relationLimitSkuIds).then(val => {
				this.limitedPurchaseRes = val;
				if (this.limitedPurchaseRes.code == 1000) {
					this.limitedPurchaseQty = this.limitedPurchaseRes.data.activityLimitQty;
					this.customerLimitQty = this.limitedPurchaseRes.data.customerLimitQty;
				}
			});
			//非会员购买会员提前购商品需先入会
			if (this.memberPurchase === 'yes' && this.localStorage.getItem('memberIsActive') != 'Active') {
				this.purchaseLimit = true;
				return;
			}

			if (this.buttonLock) {
				if (this.init.IsPC()) {
					this.buttonLock = false;
				}
				this.quoteId = this.localStorage.getItem('quote_id') || '';
				// TODO this.productId need modify;@skukun
				this.productService.getQuoteId(pSku, skuId, this.quoteId, numbers, itemCode, itemName).then(model => {
					this.shoppingData = model;
					if (this.shoppingData.code === 1000) {
						this.cartService.getCartData(this.quoteId).then();

            const pItem = this.productModel.productListByValueId[this.productModel.valueId];
            const img_url = pItem['hoverUrl'] ? pItem['hoverUrl'] : pItem['skuImageUrl'];
						//mini购物车显示策略价
						this.cartInfo = {
							'name': this.productModel.itemName,
							'price': this.productModel.getPrice() ? this.productModel.cloudPrice : 0,
							'salesPrice': this.productModel.getSalesPrice(),
							'img_url': img_url + this.init.imageSize.$4,
							'size': this.productService.sizeConvert(this.productSizeValue),
							'color': this.productColorName,
							'recommends': this.recommendWhole,
						};
 
						//筛选有效期内的策略价标签
						if(!!this.strategyRes){
							this.rulePriceJson = this.productService.strategyTag(this.strategyRes)
							//显示划线价
							if(this.rulePriceJson.price !== 0 && this.rulePriceJson.hasRule) {
								this.productModel.cloudSalePrice = this.rulePriceJson.price;
							}else{
								if(!!this.rulePriceJson.rulesArr && this.rulePriceJson.rulesArr.length > 0){
									this.cartInfo.price = this.productModel.cloudPrice;
									this.cartInfo.salesPrice = this.rulePriceJson.rulesArr[0].strategyPrice;
								}
							}
						}
						
						if (!this.init.IsPC()) {
							this.productSku = null;
							this.selectFloatSize = false;
							if (!this.oneSizeProduct) {
								this.selectedSizeName = '选择尺码';
								this.eventSubscribeService.changeSizeLabel.emit(false);
							} else {
								this.eventSubscribeService.changeSizeLabel.emit(true);
							}
						}
						this.eventSubscribeService.addMimiCartBox.emit(this.cartInfo);
						
						this.buttonLock = true;
	
						// 向Google推送加购物车事件
						//老版本Ga Tracking Js
						if (this.init.gaTrackingFlag) {
							this.angulartics2.eventTrack.next({
								action: 'add_to_cart',
								properties: {
									gstCustom: {
										items: [{
											id: pSku,
											name: this.productModel.itemName,
											brand: 'COS',
											price: this.productModel.cloudSalePrice,
											quantity: 1,
											currency: 'CNY'
										}],
										currency: 'CNY'
									}
								}
							});
						}

						// 新版Ga Tracking Js
						this.gaTrackingAddCart(pSku);

						this.tracking.pdpPurchase(pSku + '-' + this.productSizeValue);
						this.abTracking.trackingCart(this.productModel.itemId, this.productModel.itemName, this.productModel.cloudSalePrice, this.productModel.valueId, this.productModel.valueName, pSku, this.productModel.panorama_image, { path: this.router.url });
					} else {
						if (this.shoppingData.code === -2002 || this.shoppingData.code === -2003) { // cloud_jwt 过期
							this.buttonLock = true;
							this.init.logout();
							this.cartService.restCartModel();
							this.orderService.orders = { data: {}, code: '', message: '' };
							this.address.addressListResult = { code: '', message: '', data: { addressList: [] } };
							this.eventSubscribeService.loginRegisterBox.next('1');
						} else if (this.shoppingData.code === -1010) {
							this.show_modal = true;
							if (this.limitedPurchaseQty > 0) {
								this.message = "该商品每月限购" + this.limitedPurchaseQty + "件";
							} else {
								this.message = "商品超出限购数量";
							}
							this.buttonLock = true;
						} else {
							// alert
							this.show_modal = true;
							this.message = this.shoppingData.msg;
							this.buttonLock = true;
						}
					}
				});
			} else {
				console.log('add carting');
			}
		}
	}

	gaTrackingAddCart(pSku : any) {
		if (this.init.gaTrackingFlag) {
			var itemsArray = [];
			var frontUrlPath = this.productModel.frontUrlPath;
			var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
			var itemObject = {
				product_action: 'add',
				product_id: this.productModel.itemCode,//款号
				product_article_id: this.productModel.ccSku,//CC
				product_variant_id: pSku,//SKU
				product_name: this.productModel.itemName,
				product_brand: 'COS',
				product_category: product_category,
				product_color: this.productModel.valueName,
				product_price: this.productModel.cloudSalePrice,
				product_price_original: this.productModel.cloudPrice,
				product_price_type: this.productModel.cloudSalePrice < this.productModel.cloudPrice ? 'sale' : 'full price',
				product_quantity: 1,
				product_size: this.productSizeValue,
				product_size_code: this.productSizeId,
			}
			itemsArray.push(itemObject);
			this.tracking.gaTrackingAddCart(itemsArray);
		}
	}
	
	// 添加购物车
	addShoppingCar(pSku : string, numbers : string) {
		if (!this.init.IsPC()) {
			if (!pSku) {
				return;
			} else {
				//itemCode  0655155 款号
				//itemName 七分袖纯棉上衣 商品名
				//qty 1
				//skuCode 0655155002002 简单商品sku
				//skuid 118291 简单商品id
				this.addItemToCart(pSku, this.skuId, numbers, this.productModel.itemCode, this.productModel.itemName);
			}
		} else {
			// $('body').removeClass('u-overflow-hidden');
			// 防止重复添加到购物车
			this.addItemToCart(pSku, this.skuId, numbers, this.productModel.itemCode, this.productModel.itemName);
		}
	}

	payDeposit() {
		if (!this.init.isLogin()) {
			this.eventSubscribeService.loginRegisterBox.next('1');
		} else {
			if (!this.init.IsPC()) {

			}
			this.displayPresaleClause = true;
		}

	}

	cancelDeposit() {
		this.displayPresaleClause = false;
		this.agreeTerms = false;

	}
	agreeClause() {
		this.agreeTerms = true;
	}

	submitPresale() {
		if (!this.agreeTerms) {
			alert('请阅读预售条款');
			return false;
		} else {
			this.cartService.presaleCheckout(this.skuId).then((val : any) => {
				this.presaleCheckoutData = val;
				if (this.presaleCheckoutData.code == 1000) {
					this.checkoutService.presaleCheckoutData = new CheckoutModel(val['data']);
					this.localStorage.setItem('PRESALE_CART_KEY', this.presaleCheckoutData.data.cartKey);
					this.router.navigate(['/presale-checkout']);
				}
			});
			return;
		}
	}

	// 关闭遮罩层和弹框
	closeOverlay() {
		this.show_modal = false;
		this.purchaseLimit = false;
		this.remindSuccess = false;
		this.remindError = false;
		this.displayPresaleClause = false;
		this.show_favorite_modal = false;
	}
 
	
	@HostListener('window:popstate', ['$event'])
	onPopState(event: Event) {
		if (isPlatformBrowser(this.platformId)) {
			this.breadcrumbService.breadcrumbsArray.pop();
			this.eventSubscribeService.renewalCrumbs.next(true);
			// this.breadcrumbService.removeItemBreadcrumb();
		}
	}

	setArrivalRemind() {
		if (!this.init.isLogin()) {
			this.eventSubscribeService.loginRegisterBox.next('1');
		} else {
			const params = {
				frontCode: this.productModel.ccSku,
				frontUrl: '/pages/goods_detail?id=' + this.productModel.itemId + '&valueId=' + this.productModel.valueId + '&valueName=' + this.productModel.valueName,
				itemId: this.productModel.itemId,
				itemCode: this.productModel.itemCode,
				itemName: this.productModel.itemName,
				mobile: this.localStorage.getItem('mobile'),
				storeId: this.init.officialWebsiteStoreId,
				valueId: this.productModel.valueId,
				valueName: this.productModel.valueName,
				nickName: this.localStorage.getItem('wxNickName'),
				unionId: this.localStorage.getItem('wxUnionId'),
				skuCode: this.productSku
			};
			this.productService.customerArrivalRemind(JSON.stringify(params)).then(val => {
				this.remindResult = val;
				if (this.remindResult.code === 200) {
					this.remindSuccess = true;
				} else if (this.remindResult.code === -1002) {
					if (this.remindResult.msg === 'sku already add') {
						this.remindSuccess = true;
					} else {
						this.remindError = true;
						this.remindErrorMsg = '提交失败，请重试';
					}
				} else {
					this.remindError = true;
					this.remindErrorMsg = '提交失败去，请重试';
				}
			}).catch(error => error);
		}
	}
	
	quickRemoveFavoriteItem(data:any){
		let item: any = data.item;
		let recommend:boolean =  !!data.recommend ? true : false;
		let favoriteId = this.favoriteItemIds[item.itemId + '_' + item.valueId];
		if (favoriteId) {
			const params = new HttpParams()
				.set('favorites_id', favoriteId);
			this.favoriteService.delFavoriteItem(favoriteId, params).then(val => {
				if (val.code === 1000) {
					var result : any = { 'code': val.code, 'type': 'remove' };
					this.eventSubscribeService.changeFavoriteBox.next(result);
					delete (this.favoriteItemIds[item.itemId + '_' + item.valueId]);
					this.resetFavoritesService();
					this.favoriteItems();
					this.gaTrackingFavorite('unsave', 'remove_from_favourites',data,recommend);
				}
			})
		}
	}
	
	quickAddFavorite(data:any){
		let recommend:boolean =  !!data.recommend ? true : false;
		let frontCode = data.itemCode;
		let item: any = data.item;
		if (!this.init.isLogin()) {
			this.closeLayer();
			this.eventSubscribeService.loginRegisterBox.next('1');
		} else {
			this.favoriteService.addItemFavorite(frontCode, item.itemId, item.valueId).then(val => {
				this.favoriteData = val;
				if (this.favoriteData.code == -1002) {
					this.show_favorite_modal = true;
					this.message = this.favoriteData.msg;
				} else {
					var result : any = { 'code': this.favoriteData.code, 'type': 'add' };
					this.eventSubscribeService.changeFavoriteBox.next(result);
					$('#' + item.itemId + '_' + item.valueId + '_after_favorite').css('display', 'block');
					this.abTracking.trackingAction('ADD_FAVORITE', item.itemId, { valueId: item.valueId, fontCode: frontCode, path: this.router.url });
					this.resetFavoritesService();
					this.favoriteItems();
					
					this.gaTrackingFavorite('save', 'add_to_favourites',data,recommend);
				}
			})
		}
	}
	
	commonAddFavorite(item:any){
		this.addFavorite(item.itemCode,item.itemId,item.valueId)
	}
	
	commonRemoveFavoriteItem(item:any){
		this.delFavoriteItem(item.itemId,item.valueId)
	}
	//加入收藏
	addFavorite(itemCode : any, itemId : any, valueId : any) {
		if (!this.init.isLogin()) {
			if(!!this.tabId){
				this.closeLayer();
			}	
			this.eventSubscribeService.loginRegisterBox.next('1');
		} else {
			this.favoriteService.addItemFavorite(itemCode, itemId, valueId).then(val => {
				this.favoriteData = val;
				if (this.favoriteData.code == -1002) {
					this.show_favorite_modal = true;
					this.message = this.favoriteData.msg;
				} else {
					var result : any = { 'code': this.favoriteData.code, 'type': 'add' };
					this.eventSubscribeService.changeFavoriteBox.next(result);
					$('#' + itemId + '_' + valueId + '_after_favorite').css('display', 'block');
					this.abTracking.trackingAction('ADD_FAVORITE', itemId, { valueId: valueId, fontCode: itemCode, path: this.router.url });
					this.resetFavoritesService();
					this.favoriteItems();
					this.gaTrackingFavorite('save', 'add_to_favourites');
				}
			})
		}
	}

	gaTrackingFavorite(action = "", eventType = "",data:any = '',recommend:any = false) {
		if (this.init.gaTrackingFlag) {
			//收藏推荐商品时
			if(recommend){
				var currentItem : any = !!data ? data.item : this.productModel;
				var ccSku:any = currentItem.frontCode;
				var productSizeArray:any = !!data ? currentItem.sku_list : this.productModel.sku_list;
				var colorId : any = !!data ? currentItem.valueId : this.productModel.valueId;
				var defaultSizeValue:any = currentItem.sizeName[0];
				var defaultSizeCode:any = currentItem.skuCodes[0];
				var defaultSkuCode:any = currentItem.skuCodes[0];
				var price:any = currentItem.price;
				var priceOriginal:any =  currentItem.markingPrice;
			}else{
				var currentItem : any = !!data ? data.item : this.productModel;
				var ccSku:any = !!data ? currentItem.itemCode : this.productModel.ccSku;
				var productSizeArray:any = !!data ? currentItem.sku_list : this.productModel.sku_list;
				var colorId : any = !!data ? currentItem.valueId : this.productModel.valueId;
				var defaultSizeValue:any = productSizeArray[colorId][0].valueName;
				var defaultSizeCode:any = productSizeArray[colorId][0].valueId;
				var defaultSkuCode:any = productSizeArray[colorId][0].skuCode;
				var price:any = currentItem.cloudSalePrice;
				var priceOriginal:any =  currentItem.cloudPrice;
			}

			var itemsArray = [];
			var frontUrlPath = currentItem.frontUrlPath;
			var product_category = this.tracking.getCategoryByFrontUrl(frontUrlPath);
			var itemObject = {
				product_action: action,
				product_id: currentItem.itemCode,//款号
				product_article_id: ccSku,//CC
				product_variant_id: defaultSkuCode,//SKU
				product_name: currentItem.itemName,
				product_brand: 'COS',
				product_category: product_category,
				product_color: currentItem.valueName,
				product_price: price,
				product_price_original: priceOriginal,
				product_price_type: price < priceOriginal ? 'sale' : 'full price',
				product_quantity: 1,
				product_size: defaultSizeValue,
				product_size_code: defaultSizeCode,
			}

			itemsArray.push(itemObject);
			this.tracking.gaTrackingManageFavourites(itemsArray, eventType);
		}
	}

	resetFavoritesService() {
		this.favoriteService.clearFavoriteResult();
		this.favoriteService.favoritesList(this.favoriteService.favoritePageSize,this.favoriteService.favoriteCurrentPage);
	}

	filterStrategyTag(itemId : any, valueId : any) {
		this.productService.getProductStrategy(itemId, valueId).then((strategyRes : any) => {
			if (strategyRes['code'] === 1000 && strategyRes['data'].length > 0) {
				this.strategyRes = strategyRes['data'];
				//筛选有效期内的策略价标签
				this.rulePriceJson = this.productService.strategyTag(strategyRes['data'])
				if (this.rulePriceJson.hasRule === true) {
					this.productModel.cloudSalePrice = this.rulePriceJson.price;
				}
			}
		});
	}
	
	clickDescribe(){
		$("#item-describe").slideToggle("slow");
		$("#describe-plus-svg").toggle();
		$("#describe-minus-svg").toggle();
	}
	
	clickDetail(){
		$("#detail").slideToggle("slow");
		$("#detail-plus-svg").toggle();
		$("#detail-minus-svg").toggle();
	}
	
	ngOnDestroy() {
		//移除H5 pdp标记
		this.eventSubscribeService.mobPdp.emit(false);
		if(!this.clickSubMenu){
			this.breadcrumbService.breadcrumbsArray = [];
			this.eventSubscribeService.renewalCrumbs.next(true);
		}
	}

}