<div class="o-page-content mt-30 lg-mt-80">
  <div class="">
    <div class="grid grid-cols-12 gap-x-15 md:gap-x-5 px-15 md-px-20 gap-y-20 contentBox">
      <div _ngcontent-official-site-c8="" class="md-mb-0 block h-full w-full col-span-full md-col-start-2 md-col-end-12 lg-col-start-2 lg-col-end-7 xl-col-start-3  xxl-col-start-4">
        <h4 class="c-title m-0 font_s_medium">结算</h4>
        <div class="mt-30 flex flex-column mb-20">
          <app-checkout-address #checkoutadd (grayBtn)="getDataCheckoutPage($event)" [checkoutType]="checkoutType"></app-checkout-address>
        </div>
        <div class="mt-30 a-border p-15 lg-mb-30">
          <app-presale-notice></app-presale-notice>
        </div>
        <div class="md-mt-30 lg-pt-30 flex flex-column mb-20 lg-mb-30 item-block">
          <app-checkout-payment [weChat]="weChat" [isAlipay]="aliPay" [checkoutType]="checkoutType"></app-checkout-payment>
        </div>
<!--        <div class="mt-30 flex flex-column">
          <app-checkout-shipping [orderRemark]="orderRemark" [checkoutType]="checkoutType"></app-checkout-shipping>
        </div>-->
        <div class="md-hidden mt-35">
          <app-checkout-order [orderRemark]="orderRemark" [getDataParent]="getCheckoutLoad" [checkoutType]="checkoutType"></app-checkout-order>
        </div>
      </div>
      <div style="z-index: 101;" class="order-3 lg-order-2 w-full sticky bottom-0 z-20 lg-bottom-auto lg-h-fit order-container col-span-full md-col-start-2 md-col-end-12 lg:col-start-7 lg-col-end-12 xl-col-start-7 xl-col-end-11 xxl-col-start-7 xxl-col-end-10">
        <app-checkout-item [checkoutType]="checkoutType" [orderRemark]="orderRemark" [getDataParent]="getCheckoutLoad"></app-checkout-item>
      </div>
    </div>
  </div>
</div>

<!--<div class="o-page-content cofs-page is-checkout" style="margin-top:0">
  <div class="o-checkout u-clearfix">
    <h1 class="a-heading-1 checkout-heading">结算</h1>
    <div class="checkout-content-section">
      <app-checkout-address #checkoutadd (grayBtn)="getDataCheckoutPage($event)" [checkoutType]="checkoutType"></app-checkout-address>
      <app-presale-notice></app-presale-notice>
      <app-checkout-payment [weChat]="weChat" [isAlipay]="aliPay" [checkoutType]="checkoutType"></app-checkout-payment>
      <app-checkout-shipping [getDataParent]="getCheckoutLoad" [checkoutType]="checkoutType"></app-checkout-shipping>
    </div>
    <div class="order-summary-section">
      <app-checkout-item [checkoutType]="checkoutType"></app-checkout-item>
    </div>
  </div>
</div>-->
