import {Component, Inject, OnInit, Output,Input, Pipe, ChangeDetectorRef,PLATFORM_ID} from '@angular/core';
import {RequestService} from '../../../services/request.service';
import {CheckoutService} from '../../../services/checkout.service';
import {CartService} from '../../../services/cart.service';
import {CartModel} from '../../../models/cart.model';
import {HttpParams} from '@angular/common/http';
import $ from 'jquery';
import {StorageService} from '../../../services/storage.service';
import {InitializeService} from '../../../services/initialize.service';
import {isPlatformBrowser} from "@angular/common";
import {EventEmitter} from 'selenium-webdriver';
import {Router} from '@angular/router';

const apiConfig = require('../../../config/config.api.json');

@Component({
  selector: 'app-checkout-item',
  templateUrl: './checkout-item.component.html',
  styleUrls: ['./checkout-item.component.css']
})
export class CheckoutItemComponent implements OnInit {
  // 得到父组件数据；
  public submitBtn: boolean | undefined;
  @Input() set getDataParent( parentData: boolean) {
    this.submitBtn = parentData;
  }
  @Input() checkoutType: any;
  @Input() orderRemark: any;

  public removieItemKey: any;
  public shippingMoney: number | undefined;
  public policy: Boolean = false;
  public userCoupon = false; // 使用优惠券
  public couponCode: string | undefined;
  public promotion: boolean= false; // 优惠券输入判断
  public paragraph: String = '您输入的优惠券代码有误，请查正。';
  public useCoupon: Boolean = false;
  public showOrderItems = false;
  constructor(private request: RequestService,
              public init: InitializeService,
              private router: Router,
              public checkout: CheckoutService,
			        public cart: CartService,
              private changeDetectorRef: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: Object,
              private localStorage: StorageService) {
  }

  ngOnInit() {
    this.getCartModel();
	  this.couponCode = this.checkout.checkoutModel ? this.checkout.checkoutModel.couponCode : '';
    if (document.body.clientWidth > 1024) {
      this.showOrderItems = false;
      this.userCoupon = true;
    } else {
      this.showOrderItems = true;
    }
  }

  getCartModel() {
    return this.checkout.checkoutModel;
  }
  /*设置删除商品key值*/
  setRemoveKey(item_key: any) {
    this.removieItemKey = item_key;
  }

  Overlay(): void {
    this.policy = true;
  }

  closeOverlay(): void {
    this.policy = false;
    this.promotion = false;
  }


  /*监听输入优惠券*/
  monitorInput() {
    if (this.couponCode === undefined || this.couponCode === '') {
      (<HTMLInputElement> document.querySelector('.add')).disabled = true;
      this.promotion = false;
    } else {
      (<HTMLInputElement> document.querySelector('.add')).disabled = false;
    }
  }
  /*使用优惠卷*/
  usecoupon() {
    let useCoupon: any = { code: '', message: ''};
    this.checkout.useCouponServer(this.couponCode,this.checkoutType).then(val => {
      useCoupon = val;
      if (useCoupon.code !== 1000) {
        this.promotion = true;
        switch (useCoupon.code){
          case -1011:
          case -1014:
            this.paragraph = '该优惠券使用次数已超限';
            break;
          case -1000:
          case -1001:
          case -1002:
          case -1013:
          case -1015:
          case -1016:
            this.paragraph = '该优惠券不可用';
            break;
          case -1012:
            this.paragraph = '该优惠券已过期';
            break;
          default:
            this.paragraph = '该优惠券不可用';
            break;
        }
      } else {
        this.refreshInfo();
        if (this.couponCode === this.checkout.checkoutModel.couponCode) {
          this.paragraph = '该优惠券已使用！';
          this.promotion = true;
          return;
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.useCoupon = true;
      }
      // 使用优惠卷后页面效果处理
      return false;
    });
  }

  refreshInfo(){
  this.checkout.getCheckoutData(this.checkoutType).then(data => {
	  //更新mini购物车车数据
	  this.cart.cartModel = new CartModel(data);
      this.checkout.checkoutModel = data;
    });
}
  // 取消优惠券
  cancelCoupons() {
    this.couponCode = '';
    this.checkout.cancelCouponServer(this.checkoutType).then(val => {
      if (val.code !== 1000) {
        this.promotion = true;
        this.paragraph = val.msg;
      } else {
        //this.cartServeData();
        this.refreshInfo();
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.useCoupon = false;
      }
      return false;
    });
  }

	securePayment(){
		window.scrollTo(0, 0);
		this.router.navigate(['/secure-payment']);
	}
}
