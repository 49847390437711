import {Injectable} from '@angular/core';
import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {StorageService} from './storage.service';
import {NavigationModel} from '../models/navigation.model';

const apiConfig = require('../config/config.api.json');

@Injectable()
export class NavigationService {

  public navigations: Array<NavigationModel> = new Array<NavigationModel>();

  constructor(
    private request: RequestService,
    private init: InitializeService,
    private localStorage: StorageService) {
  }

  getNavigations() {
    const storeId = this.init.cloudStoreId;
    return new Promise((resolve) => {
      this.request.put(apiConfig.merchantExtendCloudApi + '/customer/merchant_navigation/version/'+ storeId,storeId)
        .toPromise()
        .then((res:any) => {
          if(res['data'].version !== null && res['data'].version !== undefined && res['data'].version !== ''){
            let putVersion = res['data'].version;
            if(this.localStorage.getItem('localVersion') !== putVersion){
              this.request
                .get(apiConfig.merchantExtendCloudApi + '/merchant_navigation/tree?store_id=' + storeId+'&ts=' + new Date().valueOf())
                .toPromise()
                .then((navigationRes:any) => {
                  if(navigationRes['data']['version'] !== null && navigationRes['data']['version'] !== undefined && navigationRes['data']['version'] !== ''){
                    let version = navigationRes['data']['version'];
                    this.localStorage.setItem('localVersion',version);
                  }
                  navigationRes['data']['content'].forEach((item:any) => {
                    this.navigations.push(new NavigationModel(item));
                  });
                  resolve(this.navigations);
                }).catch(error => error);
            }else{
              this.request
                .get(apiConfig.merchantExtendCloudApi + '/merchant_navigation/tree?store_id=' + storeId)
                .toPromise()
                .then((navigationRes:any) => {
                  navigationRes['data']['content'].forEach((item:any) => {
                    this.navigations.push(new NavigationModel(item));
                  });
                  resolve(this.navigations);
                }).catch(error => error);
            }
          }else{
            this.request
              .get(apiConfig.merchantExtendCloudApi + '/merchant_navigation/tree?store_id=' + storeId+'&ts=' + new Date().valueOf())
              .toPromise()
              .then((navigationRes:any) => {
                if(navigationRes['data']['version'] !== null && navigationRes['data']['version'] !== undefined && navigationRes['data']['version'] !== ''){
                  let version = navigationRes['data']['version'];
                  this.localStorage.setItem('localVersion',version);
                }
                navigationRes['data']['content'].forEach((item:any) => {
                  this.navigations.push(new NavigationModel(item));
                });
                resolve(this.navigations);
              }).catch(error => error);
          }
        });
    });
  }


  getNavigationsByParent(parent_nav_id = 0) {
    return new Promise((resolve) => {
      const storeId = this.init.cloudStoreId;
      this.request.get(apiConfig.merchantExtendCloudApi + '/merchant_navigation?parent_nav_id=' + parent_nav_id + '&store_id=' + storeId)
        .toPromise()
        .then((res:any) => {
          resolve(res['data']);
        }).catch(error => error);
    });
  }

  getNavigationByNavId(nav_id:any) {
    return new Promise((resolve) => {
      this.request.get(apiConfig.merchantExtendCloudApi + '/merchant_navigation/' + nav_id ).toPromise().then((res:any) => {
        resolve(res['data']);
      }).catch(error => error);
    });
  }

  getSearchHotKeys(){
    const storeId = this.init.cloudStoreId;
    const merchantId = this.init.merchantId;
    return new Promise((resolve) => {
      this.request.get(apiConfig.merchantExtendCloudApi + '/customer/merchant_store_hotword?visibility=1&merchant_id=' + merchantId+'&store_id='+storeId ).toPromise().then((res:any) => {
        resolve(res['data']);
      }).catch(error => error);
    });
  }
}
