import { Component, OnInit } from '@angular/core';
import { InitializeService } from '../../../services/initialize.service';
import { CallComponentService } from '../../../services/callcomponent.service';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import { CustomerService } from '../../../services/customer.service';
import { CartService } from '../../../services/cart.service';
// import {TrackingcodeService} from '../../../services/trackingcode.service';
import { filter } from 'rxjs/operators';
import $ from 'jquery';
@Component({
	selector: 'app-privacy-policy',
	templateUrl: './privacy-policy.component.html',
	styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
	public notUseFooter = false;
	public showCookieBlock = false;
	public showPrivacyBlock = false;
	public isVisible : boolean = false;
	public currentUrl! : string;
	public timer : any;
	constructor(public init : InitializeService,
		public eventSubscribeService : CallComponentService,
		private router : Router,
		private customerService : CustomerService,
		public cart : CartService,
		private localStorage : StorageService) {
	}

	ngOnInit() {
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd)
		).subscribe(_event => {
			const currentUrl = this.router.routerState.snapshot.url;
			this.currentUrl = currentUrl;
			if (!this.init.isLogin()) {
				this.displayCookieBlock();
			} else {
				let agreeAgreement = this.init.agreeAgreement;
				if (agreeAgreement !== '' && agreeAgreement !== undefined) {
					if (agreeAgreement == 'No') {
						this.showPrivacyBlock = true;
						this.showCookieBlock = false;
					} else {
						this.showPrivacyBlock = false;
						this.displayCookieBlock();
					}
				}
			}
		});
	}

	displayCookieBlock() {
		const time = this.localStorage.getItem('close_cookie_time');
		let currentUrl = this.currentUrl;
		if (!time || time < (new Date().valueOf() - 24 * 3600 * 1000)) {
			if (currentUrl == '/privacy-policy') {
				this.showCookieBlock = false;
			} else {
				this.showCookieBlock = true;
				this.showPrivacyBlock = false;
				this.localStorage.removeItem('close_cookie_time');
			}

			if (!this.init.IsPC()) {
				$(".cta-scroll-container").css("bottom", $('.m-cookie-message').css('height'));
			}
		}
	}

	public closeCookie() {
		this.localStorage.setItem('close_cookie_time', new Date().valueOf());
		this.showCookieBlock = false;
		if (!this.init.IsPC()) {
			$('.cta-scroll-container').css('bottom', '0px');
		}
	}

	agree() {
		let params = { isAgreed: 'Yes', name: 'privacy', version: '20211109' };
		this.customerService.operateAgreement(params).then(val => {
			if (val.code === 1000) {
				this.init.agreeAgreement = 'Yes';
				this.displayCookieBlock();
			}
		});
	}

	disagree() {
		let params = { isAgreed: 'No', name: 'privacy', version: '20211109' };
		this.customerService.operateAgreement(params).then(val => {
			if (val.code === 1000) {
				this.init.agreeAgreement = '';
				this.timer = setInterval(() => {
					this.init.logout();
					this.cart.restCartModel();
					this.router.navigate(['']);
					clearInterval(this.timer);
				}, 500);
			}
		});
	}
}