import {Component, Input, OnInit} from '@angular/core';
import {StorageService} from '../../services/storage.service';
import {Router} from '@angular/router';
import {CmsService} from '../../services/cms.service';
import {VmService} from '../../services/vm.service';

@Component({
  selector: 'app-vm-tools-login',
  template: `
    <div class="col-lg-4 col-lg-offset-4">
      <div class="form-group">
        <label for="inputAdminUsername">用户名</label>
        <input type="text" class="form-control" id="inputAdminUsername" placeholder="Username" [(ngModel)]="inputAdminUsername"/>
      </div>
      <div class="form-group">
        <label for="inputAdminPassword">密码</label>
        <input type="password" class="form-control" id="inputAdminPassword" placeholder="Password" [(ngModel)]="inputAdminPassword"/>
      </div>
      <div class="checkbox">
        <button (click)="login()">登录</button>
      </div>
    </div>
  `,
  styles: []
})
export class VmToolsLoginComponent implements OnInit {

    @Input()
	inputAdminUsername!: string;
    @Input()
	inputAdminPassword!: string;
  constructor(public cmsService: CmsService,
              private vmService: VmService,
              private storage: StorageService,
              private router: Router) {
  }

  ngOnInit() {
  }

  /**
   * Login
   * vm-tools/login
   */
  login(){
    this.vmService.adminLogin( this.inputAdminUsername, this.inputAdminPassword).then((result:any) => {
        if ( result['code'] === 1 ) {
            this.storage.setItem('admin', this.inputAdminUsername);
            this.storage.setItem('adminJwt', result['data']);
            this.cmsService.sharedData.editMode = true;
            this.storage.setItem('vmLogin', 1 );
            this.storage.setItem('vmToPreview', 0);
            history.go( 0 ); // 临时解决一个bug
            return this.router.navigate(['/']);
        } else {
            alert( '登录失败' );
			return;
        }
    }).catch(error => error);
  }
}
