import { InitializeService} from '../../../services/initialize.service';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import { VmService} from '../../../services/vm.service';
import { StorageService} from '../../../services/storage.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-vm-panel-merch',
    templateUrl: 'vm-panel-merch.component.html',
    styleUrls: ['./vm-panel-merch.component.css']
})
export class VmPanelMerchComponent implements OnInit {
    title = 'app';
    width = 0;
    local: any;
    slide = 0;
    category = 0;
    searchProductList: any;
    constructor(public init: InitializeService,
                public vmService: VmService,
                private dragulaService: DragulaService,
                private storage: StorageService,
                @Inject(PLATFORM_ID) private platformId: Object) {

    }

    ngOnInit() {
      if (isPlatformBrowser(this.platformId)) {
        this.category = this.storage.getItem('merch_category');
        const bag: any = this.dragulaService.find('nested-bag');
        if (bag !== undefined) {
          this.dragulaService.destroy('nested-bag');
        }

        this.dragulaService.setOptions('nested-bag', {
          copy: function (_el:any, _source:any) {
            // To copy only elements in left container, the right container can still be sorted
            //return source.id === 'nested' || 'products';
            //return source.id === 'images' || 'nested' ;
          },
          copySortSource: true,
          revertOnSpill: true,
          moves: function (el: any, container: any, handle: any): any {
            const productId = el.querySelector('.o-product').id;
            if (handle.className.indexOf('add_Icon') >= 0) {
              const ccImages = [];
			  var vmProductModelArr = localStorage.getItem('vmProductModelArr');
			  if(vmProductModelArr !== null){
				  const productModelArr = JSON.parse(vmProductModelArr);
				  if (handle.className.indexOf('search_vm') >= 0) {
				    /*const vmSearchList = JSON.parse( localStorage.getItem('vmSearchList') );
				    for ( const i in vmSearchList ) {
				        const images = vmSearchList[i].images;
				        for ( const j in images ) {
				            ccImages.push( images[j] );
				        }
				    }*/
				  } else {
				    for (const i in productModelArr) {
				      const merch = productModelArr[i];
				      if (merch.entity_id === productId) {
				        if (merch.image_list) {
				          if (merch.thumbnail) {
				            ccImages.push(merch.thumbnail);
				          }
				          if (merch.panorama_image) {
				            ccImages.push(merch.panorama_image);
				          }
				  
				          // 过滤等于当前color的img
				          merch.image_list.forEach(function (item: { color_attribute_id: any; value: any; }) {
				            if (!merch.attribute.color.includes(item.color_attribute_id) && (merch.color === item.color_attribute_id)) {
				              ccImages.push(item.value);
				            }
				          });
				          // 去除重复的
				          ccImages.filter(function (element, key, self) {
				            return self.indexOf(element) === key;
				          });
				        }
				      }
				    }
				  }
				  
				  const tmp = [];
				  productModelArr[productId + '_id']['ccimage'] = ccImages;
				  if (localStorage.getItem('vmProductModelArrList')) {
				    tmp.push(productModelArr[productId + '_id']);
					var vmProductModelArrList = localStorage.getItem('vmProductModelArrList');
					if(vmProductModelArrList !== null){
						const productModelArrList = JSON.parse(vmProductModelArrList);
						for (const i in productModelArrList) {
						  tmp.push(productModelArrList[i]);
						}
						localStorage.setItem('vmProductModelArrList', JSON.stringify(tmp));
					}
				  } else {
				    tmp.push(productModelArr[productId + '_id']);
				    localStorage.setItem('vmProductModelArrList', JSON.stringify(tmp));
				  }
			  }
            }

            if (handle.className.indexOf('merchClose') >= 0) {
				var productsElement = document.querySelector('#products');
				if(productsElement !== null){
					const products_tag = productsElement.getElementsByClassName('o-product');
					const products_list = [];
					let flag = 0;
					for (const products in products_tag) {
					  if (products_tag[products].id) {
					    if (products_list.indexOf(products_tag[products].id) === -1) {
					      products_list.push(products_tag[products].id);
					    } else {
					      flag = 1;
					      break;
					    }
					  }
					}
					
					if (flag === 0) {
					  if (localStorage.getItem('vmRemoveProducts') !== '') {
					    localStorage.setItem('vmRemoveProducts', localStorage.getItem('vmRemoveProducts') + ',' + productId);
					  } else {
					    localStorage.setItem('vmRemoveProducts', productId);
					  }
					}
				}

              if (container.id === 'nested') {
				  var vmProductModelArrList = localStorage.getItem('vmProductModelArrList');
				  if (vmProductModelArrList !== null) {
					  const productModelArrList = JSON.parse(vmProductModelArrList);
					  for (const i in productModelArrList) {
					    if (productModelArrList[i].entity_id === productId) {
					      productModelArrList.splice(i, 1);
					      break;
					    }
					  }
					  localStorage.setItem('vmProductModelArrList', JSON.stringify(productModelArrList));
				  }
              }

              el.remove();
            }
            return true;
          }
        });
      }
      if (isPlatformBrowser(this.platformId)) {
		var vmProductModelArrList = localStorage.getItem('vmProductModelArrList');
		if(vmProductModelArrList != null){
		   const productModelArrList = JSON.parse(vmProductModelArrList);
			this.vmService.vmMerchProductList = productModelArrList;
		}

        this.dragulaService.drag.subscribe((_value:any) => {});
        this.dragulaService.dragend.subscribe((_value:any) => {
          const len = document.querySelectorAll('#nested div.m-product-image').length;
          if (len > 0) {
            this.slide = 1;
          } else {
            this.slide = 0;
          }
        });
      }
    }

    updateRanking() {
        const page = this.getQueryString();
        if ( page === 'list' ) {
			var productsElement = document.querySelector('#products');
			if(productsElement){
				const products_tag:any = productsElement.getElementsByClassName('o-product');
				const products_list:any = [];
				const banner_list:any = [];
				const match = /^[0-9]*$/;
				let flag = 0;
				let bannerFlag = 0;
				for (const products in products_tag) {
				    if ( flag < products_tag.length ) {
				      const productIdArr =  products_tag[products].id.split('-');
				      if (match.test(productIdArr[0])) {
				        // const image = products_tag[products].querySelector('img');
				        // const main_imags = products_tag[products].querySelector('.listing_thumbnail').getElementsByTagName('ul');
				        // let flag_image = 0;
				        // const tmp_product = {};
				        // tmp_product['id'] = products_tag[products].id;
				        // tmp_product['image'] = image.src.replace(/200x200/, '1000x1000');
				        // for (const main_image in main_imags) { /* VM列表页切换主图 只需要获取前2张 */
				        //   if (flag_image < 2) {
				        //     if (parseInt(main_image) < main_imags.length) {
				        //       const tmp_main_image = main_imags[main_image].querySelector('li img');
				        //       tmp_product['img_' + main_image] = tmp_main_image['src'];
				        //     }
				        //   }
				        //   flag_image++;
				        // }
				        // products_image_list.push(tmp_product);
				        if (products_list.indexOf(products_tag[products].id) === -1) {
				          products_list.push(products_tag[products].id);
				        }
				        // else {
				        //   alert('提示：检测单类目中，有至少2个以上相同的商品，请移除重复的商品后提交！');
				        //   return false;
				        // }
				      } else {
				        if (banner_list.indexOf(products_tag[products].id) === -1) {
				          const banner = {'id': '', 'position': 0};
				          banner.id = products_tag[products].id;
				          banner.position = flag + 1 - bannerFlag;
				          banner_list.push(banner);
				          bannerFlag++;
				        } else {
				          alert('提示：检测单类目中，有至少2个以上相同的Banner，请移除重复的banner后提交！');
				          return false;
				        }
				      }
				    }
				   flag++;
				}
				
				// this.vmService.saveMerchProducts(products_list.join(','));
				this.vmService.saveBannerList(JSON.stringify(banner_list));
				// this.vmService.mergeProductList();
				this.vmService.validate = true;
				return false;
			}

        } else if ( page === 'pdp' ) {
            //this.recommandRanking();
        }
		return;
    }

    resetRanking() {
        if ( confirm( '请确认是否清空暂存栏？' ) ) {
            localStorage.removeItem('vmProductModelArrList');
            history.go(0);
        }
    }

    getQueryString() {
        const url = document.URL;
        const urlArray = url.split( '/' );
        const urlPrefixArray = urlArray[ urlArray.length - 1 ];

        const urlPrefixAllArray = urlPrefixArray.split('.');
        if ( urlPrefixAllArray.length > 0 ) {
            if ( urlPrefixAllArray[0] === 'product' ) {
                return 'pdp';
            } else {
                return 'list';
            }
        }
        return false;
    }

    vmImageClick( id:any , image:any , type:any , number:any, m:any) {
        const thumbnails = document.querySelectorAll( '#listing_thumbnail_merch_' + id  + '_' + m + ' a' );
        let flag = 1;
        for ( const tmp in thumbnails ) {
            if ( thumbnails[tmp].className !== undefined ) {
                if ( (type === 1) && (thumbnails[tmp].className.indexOf('img_1') === 0) ) {
                    thumbnails[tmp].className = 'img_1';
                }
                if ( (type === 2) && (thumbnails[tmp].className.indexOf('img_2') === 0) ) {
                    thumbnails[tmp].className = 'img_2';
                }
            }
        }
        for ( const tmp in thumbnails ) {
            if ( flag === (2 * number + type) ) {
                thumbnails[tmp].className += ' act';
            }
            flag++;
        }
        const entity:any = { 'id' : id };
        let message_type = '';
        if ( type === 1 ) {
            entity['img_0'] = image;
            message_type = 'img_0';
        } else {
            entity['img_1'] = image;
            message_type = 'img_1';
        }
        this.vmService.setProductImagesList( entity , message_type );
        return false;
    }
}

