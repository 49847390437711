
    <div class="container breadcrumb-container" *ngIf="showBreadcrumbs">
      <ol class="breadcrumb">
        <li><a routerLink="" class="breadcrumb">{{ '首页' | translate }}</a></li>
        <li *ngFor="let breadcrumb of breadcrumbs">
          <a href="{{breadcrumb.url}}">{{ breadcrumb.label | translate }}</a>
        </li>
      </ol>
    </div>
   <ul class="mobile_breadcrumb hidden-md hidden-lg" *ngIf="this.init.title !== ''" style="display:none;">
      <li class="breadcrumb-arrow" style="z-index: 999" (click)="this.goBack()">
        <h3><span class="glyphicon glyphicon-chevron-left"></span></h3>
      </li>
      <li class="col-xs-12 breadcrumb-title"><h3><span>{{ this.init.title }}</span></h3></li>
    </ul>
  