import {Component, Inject,OnInit, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {RequestService} from '../../services/request.service';
import {StorageService} from '../../services/storage.service';
import {CustomerService} from '../../services/customer.service';
import {isPlatformBrowser} from '@angular/common';
import {CartService} from '../../services/cart.service';
import $ from "jquery";

const apiConfig = require('../../config/config.api.json');

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.css']
})

export class AccountEditComponent implements OnInit{
  public showRefuseBox :boolean = false;
  public logoutLogRs:any = {code:'',message:'',data:''};
  public phone = '';
  public customerInfo: any = {memberLevel:{levelName:''},isActive:''};
  public customerApiResponse:any = {code:'',msg:'',data:{customerId:'',mobile:'',groupName:'',memberId:'',email:'',dob:''}}
  public customerData:any = {customerId:'',mobile:'',groupName:'',memberId:'',email:'',birthday:'',staffCard:'',groupId:''}
  public editInfo: boolean = false;
  public extra: boolean = false;
  public years:any = [];
  public months:any = [];
  public days:any = [];
  public birthday = '员工生日';
  public valite:any = { email: { is_active: false, errorTips: false }, card: {is_active: false, errorTips: false}, birthday: {errorTips: false}};
  public emailMessage = 'E-mail';
  public cardText = '员工卡号';
  public accountVals:any = {email : '', card : '', year: 0 , month: 0 , day: 0,mobile:'',passwordHash:''};
  public privacyPolicy: Boolean = false;
  //用户数据
  public day!: number;
  public infoDate = '0000-00-00 00:00:00';
  public prompt!: boolean;
  public prompting: any = '';
  public saveLock = false;
  public isCheckSaff = false;
  public phoneCaptchaValue!: string;
  public phoneCaptchaActive = false; // 聚焦验证码框
  public mobileActive = false; // 聚焦手机号框
  public phoneCaptchaValid = false;
  public phoneCaptchaDisabled = false; // 发送验证码禁用
  public mobileVal!: string; // 手机号（注册）
  public mobileError!: string; // 手机号验证错误提示
  public phoneCaptchaError!: string; // 手机验证错误提示（注册）
  public mobileValid = false; // 手机号验证
  public sendPhoneLabel = '发送验证码';
  public imageValidId!: string; // 图片验证码id
  public imageValidSrc!: string; // 图片验证码地址
  public imageValidValue!: string; // 图像验证码输入值
  public imageValidError = false; // 图形验证码错误
  public imageValidErrorLabel!: string; // 图形验证码提示
  public hiddenImageValidBox = true; // 图片验证框显示(注册)
  public timerEffect:any;
  public timer :any;
  public showUpdateMobileForm = false;
  public showVerifyBox = false;
  public canSubmit = true;
  public showMemberGrowth:boolean = false;
  public showAccountInfo:boolean = true;
  public memberActive:boolean = true;
  public transactionAmounts = {code:'',msg:'',data:{orderAmount:0,refundAmount:0}};
  public orderAmount = 0;
  public showAddEmail:boolean = false;
  public editEmailResult = {code:0,msg:'',data:''};
  public memberBirthday:any = null;
  public addBirthdaySuccess:boolean = false;
  public pointsInfo = {expiringPoints:null,pointsBalance:'',expiringDate:null};
  public goMiniPrograme :boolean =  false;


  //编辑信息
  public editMobileAndEmail = false;
  public saveEmailLock = false;
  public editBirthday = false;
  public showYear = false;
  public showMonth = false;
  public showDays = false;
  public showCard = false;
  public saveCardLock = false;
  public saveBirthLock = false;
  constructor(public init: InitializeService,
              private request: RequestService,
			  public cart: CartService,
              private customerService: CustomerService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              private localStorage: StorageService) {
  }
  ngOnInit() {
    this.init.pageConfiguration('账户信息');
    this.matchPath(this.router.url);
    // 监听路由变化事件
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // 当路由变化结束时（即URL变化完成），执行你想要的操作
        const currentUrl = this.router.routerState.snapshot.url;
        this.matchPath(currentUrl);
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.initialization();
      this.cloudCustomerInfo();
      this.customerPointsInfo();
      this.getTransactionAmount();
      this.getYear();
      this.getMonth();
      this.getDay();
    }
  }

  matchPath(path: string){
    if(path =='/my-account/profile'){
      this.showAccountInfo = true;
      this.showMemberGrowth = false;
    } else {
      this.showAccountInfo = false;
      this.showMemberGrowth = true;
    }
  }

  dateTimeChange(){
    this.memberBirthday = $('#birthday').val();
    this.addBirthday(this.memberBirthday);
  }

  addBirthday(birthday:any){
    let birthdayParam = birthday.replace('/', '-');
    birthdayParam = birthdayParam.replace('/', '-');
    let params = {birthday:birthdayParam};
    this.customerService.updateBirthday(this.localStorage.getItem('cloud_customer_id'),JSON.stringify(params)).then(val => {
      if(val.code === 1000){
        this.addBirthdaySuccess = true;
      }
    }).catch(error => error);
  }
  /**
   * 获取交易金额
   */
  getTransactionAmount(){
    this.customerService.getTransactionAmounts().then(val => {
      this.transactionAmounts = val;
      if(parseInt(this.transactionAmounts.code) === 1000){
        this.orderAmount = this.transactionAmounts.data.orderAmount - this.transactionAmounts.data.refundAmount;
      }
    }).catch(error => error);
  }

 cloudCustomerInfo(){
   this.customerService.customerData().then( val => {
      this.customerApiResponse = val;
      if(this.customerApiResponse.code == 1000){
        this.customerData = this.customerApiResponse.data;

        this.localStorage.setItem('groupId',this.customerData.groupId);

        this.showAddEmail = this.customerData.email ? false : true;
        this.accountVals.email = this.customerData.email;
        this.accountVals.mobile = this.customerData.mobile;
        this.accountVals.card = this.customerData.staffCard;

        // if (this.customerData.birthday !== '' && this.customerData.birthday !== this.infoDate) {
        //   const birthDay = this.customerData.birthday.split('-');
        //   this.accountVals.year = birthDay[0]; // 获得年份
        //   this.accountVals.month = Number(birthDay[1]);
        //
        //   const tempDay = birthDay[2].split(' ');// 获得日期
        //   this.accountVals.day = Number(tempDay[0]);
        // } else {
        //   this.accountVals.year = '1985';
        //   this.accountVals.month = '0';
        //   this.accountVals.day = '0';
        // }
      }
   }).catch(error => error);
 }

  initialization(){
    this.customerService.getCrmMemberInfo().then(value => {
      this.customerInfo = value.data;
      this.phone = this.mobileVal = this.customerInfo.mobile;
      this.memberBirthday = this.customerInfo.birthDay;

      if (this.customerInfo.birthDay && this.customerInfo.birthDay != '' && this.customerInfo.birthDay !== this.infoDate) {
        const birthDay = this.customerInfo.birthDay.split('-');
        this.accountVals.year = birthDay[0]; // 获得年份
        this.accountVals.month = Number(birthDay[1]);

        const tempDay = birthDay[2].split(' ');// 获得日期
        this.accountVals.day = Number(tempDay[0]);
      } else {
        this.accountVals.year = '1985';
        this.accountVals.month = '0';
        this.accountVals.day = '0';
      }
      this.localStorage.setItem('memberLevelId',this.customerInfo.memberLevelId);
      this.localStorage.setItem('memberIsActive',this.customerInfo.isActive);
      this.memberActive = this.customerInfo.isActive === 'Active' ? true : false;
    }).catch(error => error);
  }
  /*遍历*/
  // promptValue(index) {
  //   for(let val of this.emailMessage) {
  //     return val;
  //   }
  // }
  // 判断email
  emailVerify() {
    const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (this.accountVals.email === undefined || this.accountVals.email === '') {
      // if ( this.accountVals.card || this.accountVals.mobile) {
      //   return true;
      // }
      this.emailMessage = '请输入电子邮件';
      this.valite.email.is_active = true;
      this.valite.email.errorTips = true;
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.valite.email.is_active = false;
          that.valite.email.errorTips = false;
        }, 3000);
      }
      return false;
    } else {
       this.emailMessage = 'E-mail';
       if (!reg.test(this.accountVals.email)) {
         this.emailMessage = '请输入有效的电子邮件';
         this.valite.email.is_active = true;
         this.valite.email.errorTips = true;
         const that = this;
         if (isPlatformBrowser(this.platformId)) {
           this.timer = setTimeout(function () {
             that.valite.email.is_active = false;
             that.valite.email.errorTips = false;
           }, 3000);
         }
         return false;
       } else {
         this.cardText = '';
         this.emailMessage = 'E-mail';
         this.valite.card.is_active = false;
         this.valite.card.errorTips = false;
       }
      this.valite.email.is_active = false;
      this.valite.email.errorTips = false;
    }
    return true;
  }
  editEmail(){
    this.editMobileAndEmail = !this.editMobileAndEmail;
    this.saveEmailLock = false;
    this.closeBirth();
  }
  saveEmail(){
    if(this.saveEmailLock){
      return;
    }
    if(!this.emailVerify()){
      alert(this.emailMessage);
      return;
    }
    this.saveEmailLock = true;
    const emailParams = {email:this.accountVals.email}
    this.customerService.editCustomerEmail(this.localStorage.getItem('cloud_customer_id'),JSON.stringify(emailParams)).then( val => {
      this.editEmailResult = val;
      this.saveEmailLock = false;
      if(this.editEmailResult.code === 1000) {
        alert('保存成功');
        this.cloudCustomerInfo();
        this.editMobileAndEmail = false;
      } else {
        this.privacyPolicy = true;
        this.prompting = this.editEmailResult.msg;
      }
    })
  }
  editBirth(){
    this.editMobileAndEmail = false;
    this.showCard = false;
    this.editBirthday = !this.editBirthday;
  }
  closeBirth(){
    this.editBirthday = false;
    this.showYear = false;
    this.showMonth = false;
    this.showDays = false;
  }
  saveBirthday(){
    if (this.accountVals.year === undefined || this.accountVals.year === 0 || this.accountVals.month === undefined || this.accountVals.month === 0 || this.accountVals.day === undefined || this.accountVals.day === 0 || this.accountVals.year === '0' || this.accountVals.month === '0' || this.accountVals.day === '0') {
      return;
    }
    this.saveBirthLock = true;
    const monthVal = this.accountVals.month.toString().length < 2 ? '0'+this.accountVals.month :this.accountVals.month;
    const dayVal = this.accountVals.day.toString().length < 2 ? '0'+this.accountVals.day : this.accountVals.day;
    const birthdayParam = this.accountVals.year+'-'+monthVal+'-'+dayVal;
    const staffCardParams = {birthday:birthdayParam};
    this.customerService.updateBirthday(this.localStorage.getItem('cloud_customer_id'),staffCardParams).then( (val:any) => {
      if(val.code === 1000){
        alert('保存成功');
        this.editBirthday = false;
        this.initialization();
        this.cloudCustomerInfo();
      }else{
        this.privacyPolicy = true;
        this.prompting = val.msg;
        return;
      }
    }).catch(error => error);
  }
  /*非空判断员工卡号*/
  staffKeyup() {
    if (this.accountVals.card === undefined || this.accountVals.card === null || this.accountVals.card === '') {
      // if ( this.accountVals.email ) {
      //   return true;
      // }
      this.cardText = '请输入员工卡号';
      this.valite.card.is_active = true;
      this.valite.card.errorTips = true;
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.valite.card.is_active = false;
          that.valite.card.errorTips = false;
        }, 3000);
      }
      return false;
    } else {
        this.emailMessage = '';
        this.valite.email.is_active = false;
        this.valite.email.errorTips = false;
        this.valite.card.is_active = false;
        this.valite.card.errorTips = false;
        this.cardText = '员工卡号';
      if (isPlatformBrowser(this.platformId)) {
        $('#saveProfileDetails').attr({disabled: false});
      }
    }
    return true;
  }
  staffCard() {
    const reg = /^[0-9]{19}$/;
    if (this.accountVals.card === undefined || this.accountVals.card === null || this.accountVals.card === '') {
      // if ( this.accountVals.email ) {
      //   return false;
      // }
      this.cardText = '请输入员工卡号';
      this.valite.card.is_active = true;
      this.valite.card.errorTips = true;

      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.valite.card.is_active = false;
          that.valite.card.errorTips = false;
        }, 3000);
      }

      return false;
    } else {
      this.cardText = '员工卡号';
       if (!reg.test(this.accountVals.card)){
         this.cardText = '员工卡号无效';
         this.valite.card.is_active = true;
         this.valite.card.errorTips = true;

         const that = this;
         if (isPlatformBrowser(this.platformId)) {
           this.timer = setTimeout(function () {
             that.valite.card.is_active = false;
             that.valite.card.errorTips = false;
           }, 3000);
         }

         return false;
       } else {
         this.cardText = '员工卡号';
         this.valite.card.is_active = false;
         this.valite.card.errorTips = false;
       }
    }
    return true;
  }
  changeExtra(){
    this.extra = !this.extra;
  }
  // 获取年份
  public getYear() {
    // 创建年，从1900年到现在
    const minYear = 1950;
    const maxYear = new Date().getFullYear();
    for (let i = minYear; i <= maxYear; i++) {
      this.years.push(i);
    }
  }
  // 获取月份
  public getMonth() {
    // 创建option
    for (let i = 1; i <= 12; i++) {
      // 设置option标签体
      this.months.push(i);
    }
  }
  openYear(){
    this.showYear = !this.showYear;
    this.showMonth = false;
    this.showDays = false;
  }
  openMonth(){
    this.showMonth = !this.showMonth;
    this.showYear = false;
    this.showDays = false;
  }
  openDays(){
    this.showDays = !this.showDays;
    this.showYear = false;
    this.showMonth = false;
  }
  selectYear(year: any){
    this.accountVals.year = year;
    this.showYear = false;
    this.getDay()
  }
  selectMonth(month: any){
    this.accountVals.month = month;
    this.showMonth = false;
    this.getDay()
  }
  selectDay(day: any){
    this.accountVals.day = day;
    this.showDays = false;
  }
  public getDay() {
    if (isPlatformBrowser(this.platformId)) {
      const year = this.accountVals.year;
      const month = this.accountVals.month;
      // 判断
      this.day = this.getDaysInMonths(month, year);
      if (this.days = []) {
        for (let i = 1; i <= this.day; i++) {
          this.days.push(i);
        }
      }
    }
  }
  public getDaysInMonths(month:any, year:any) {
    if (isPlatformBrowser(this.platformId)) {
      month = parseInt(month);
    }
    let monthDay = 31;
    if (month === 4 || month === 6 || month === 9 || month === 11) {
      monthDay = 30; // 固定30
    } else if(month === 2) {
      // 排除百年，每四年一闰；每四百年一闰；
      if ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)) {
        monthDay = 29; // 闰年29
      } else {
        monthDay = 28; // 平年28
      }
    }
    return monthDay; // 返回该年月的日数
  }

  editCard(){
    this.showCard = !this.showCard;
    this.editMobileAndEmail = false;
    this.closeBirth();
  }
  /*校验shit*/
  birthdayshit() {
    if (this.accountVals.year === undefined || this.accountVals.year === 0 || this.accountVals.month === undefined || this.accountVals.month === 0 || this.accountVals.day === undefined || this.accountVals.day === 0 || this.accountVals.year === '0' || this.accountVals.month === '0' || this.accountVals.day === '0') {
      // alert('请填写完整的生日');
      if ( this.accountVals.card ) {
        this.valite.birthday.errorTips = true;
        this.birthday = '请选择完整的生日';
        const that = this;
        if (isPlatformBrowser(this.platformId)) {
          this.timer = setTimeout(function () {
            that.valite.birthday.errorTips = false;
          }, 3000);
        }
      }
      return false;
    } else {
      this.valite.birthday.errorTips = false;
      this.birthday = '员工生日';
      if (this.accountVals.card === undefined || this.accountVals.card === null || this.accountVals.card === '') {
        this.cardText = '请输入员工卡号';
        this.valite.card.is_active = true;
        this.valite.card.errorTips = true;
        const that = this;
        if (isPlatformBrowser(this.platformId)) {
          this.timer = setTimeout(function () {
            that.valite.card.errorTips = false;
            that.valite.card.is_active = false;
          }, 3000);
        }
        return false;
      }
    }
    return true;
  }

  saveCard(){
    if(this.saveCardLock){
      return;
    }
    if(!this.staffCard()){
      return;
    }
    if (this.accountVals.year === undefined || this.accountVals.year === 0 || this.accountVals.month === undefined || this.accountVals.month === 0 || this.accountVals.day === undefined || this.accountVals.day === 0 || this.accountVals.year === '0' || this.accountVals.month === '0' || this.accountVals.day === '0') {
      this.privacyPolicy = true;
      this.prompting = '请先选择完整生日';
      return;
    }
    this.saveCardLock = true;
    if (this.accountVals.card != this.customerData.staffCard) {
      this.privacyPolicy = true;
      this.isCheckSaff = true;
    }
    const monthVal = this.accountVals.month.toString().length < 2 ? '0'+this.accountVals.month :this.accountVals.month;
    const dayVal = this.accountVals.day.toString().length < 2 ? '0'+this.accountVals.day : this.accountVals.day;
    const birthdayParam = this.accountVals.year+'-'+monthVal+'-'+dayVal;
    const staffCardParams = {birthday:birthdayParam,staffCard:this.accountVals.card,email:this.accountVals.email};
    this.customerService.bindCard(this.localStorage.getItem('cloud_customer_id'),staffCardParams).then( val => {
      this.editEmailResult = val;
      if(this.editEmailResult.code === 1000){
        alert('保存成功');
        this.cloudCustomerInfo();
        this.showCard = false;
        this.editBirthday = false;
        this.closeOverlay();
        this.isCheckSaff = false;
      }else{
        this.isCheckSaff = false;
        this.prompt = false;
        this.privacyPolicy = true;
        if(this.editEmailResult.msg === 'Birth date does not match'){
          this.prompting = '生日与员工卡号不匹配';
        }else{
          this.prompting = this.editEmailResult.msg;
        }
        this.emptyValue();
        return;
      }
    }).catch(error => error);
  }

  onshitSubmit() {
     this.saveLock = true;
     if(this.accountVals.mobile != this.phone){
       if(!this.blurMobileCheck() || !this.checkVerificationCode()){
          this.canSubmit = false;
       }
     }else {
       this.canSubmit = true;
     }

      if (this.canSubmit === true && this.emailVerify() && !this.staffCard() && !this.birthdayshit() || (this.staffCard() && this.birthdayshit()) || (this.blurMobileCheck() && this.phoneCaptchaActive) ) {
        if (this.accountVals.card != this.customerData.staffCard) {
          this.privacyPolicy = true;
          this.isCheckSaff = true;
        }

        if(this.emailVerify() && !this.isCheckSaff && this.accountVals.email != this.customerData.email){
          const emailParams = {email:this.accountVals.email}
          this.customerService.editCustomerEmail(this.localStorage.getItem('cloud_customer_id'),JSON.stringify(emailParams)).then( val => {
              this.editEmailResult = val;
            if(this.editEmailResult.code === 1000) {
              this.extra = false;
              this.editInfo = false;
              this.cloudCustomerInfo();
              this.router.navigate(['/my-account/profile']);
            }
          })
        }

        if(this.isCheckSaff && this.saveLock && this.staffCard() && this.birthdayshit()){
            const monthVal = this.accountVals.month.toString().length < 2 ? '0'+this.accountVals.month :this.accountVals.month;
            const dayVal = this.accountVals.day.toString().length < 2 ? '0'+this.accountVals.day : this.accountVals.day;
            const birthdayParam = this.accountVals.year+'-'+monthVal+'-'+dayVal;
            const staffCardParams = {birthday:birthdayParam,staffCard:this.accountVals.card,email:this.accountVals.email};
            this.customerService.bindCard(this.localStorage.getItem('cloud_customer_id'),staffCardParams).then( val => {
                this.editEmailResult = val;
                if(this.editEmailResult.code === 1000){
                  this.extra = false;
                  this.editInfo = false;
                  this.cloudCustomerInfo();
                  this.closeOverlay();
                  this.router.navigate(['/my-account/profile']);
                }else{
                  this.isCheckSaff = false;
                  this.prompt = false;
                  this.privacyPolicy = true;
                  if(this.editEmailResult.msg === 'Birth date does not match'){
                    this.prompting = '生日与员工卡号不匹配';
                  }else{
                    this.prompting = this.editEmailResult.msg;
                  }
                  this.emptyValue();
                  return;
                }
            }).catch(error => error);
        }
      } else {
        this.saveLock = false;
        this.emailVerify();
        this.staffCard();
        this.birthdayshit();
      }
  }
  emptyValue() {
    this.customerService.getCustomerInfo().then(value => {
      if (this.accountVals.email !== value['email']) {
        this.valite.email.is_active = false;
        this.valite.email.errorTips = false;
        this.emailMessage = 'E-mail';
      }
      if (this.accountVals.card !== value['member_id']){
        this.accountVals.card = undefined;
        this.valite.card.is_active = false;
        this.valite.card.errorTips = false;
        this.cardText = '员工卡号';
      }
      if (value['dob'] === this.infoDate || value['dob'] === '') {
        this.accountVals.year = '0';
        this.accountVals.month = '0';
        this.accountVals.day = '0';
        this.birthday = '员工生日';
      }
    }).catch(error => error);
  }
  /*取消Edit*/
  editValue() {
    this.editInfo = false;
    this.emptyValue();
  }
  Policy() {
      this.prompt = true;
      this.privacyPolicy = true;
  }
  closeOverlay() {
    this.privacyPolicy = false;
	  this.showRefuseBox = false;
	  this.prompting = '';
  }

  OnDestroy(){
    this.customerInfo = null;
  }

  blurPhoneCaptchaCheck() {
    this.phoneCaptchaActive = false;
  }

  phoneCaptchaActiveCheck() {
    this.phoneCaptchaValid = false;
    this.phoneCaptchaActive = true;
  }

  createImage() {
    let result: any = {code: '', data: {'captcha_id': '', 'captcha_image': ''}, message: ''};
    const paramsUrl = '?action=createValidationCode';
    this.request.get(apiConfig.customerApi + paramsUrl)
      .subscribe(
        val => {
          result = val;
          if (result.code === '200') {
            this.imageValidId = result.data.captcha_id;
            this.imageValidSrc = result.data.captcha_image;
          }
        }
      );
  }
  openImageValid() {
    this.createImage();
    this.phoneCaptchaValue = '';
    this.imageValidValue = '';
    if (!this.blurMobileCheck()) {
      return false;
    }
    this.hiddenImageValidBox = false;
	return;
  }

  mobileActiveCheck() {
    this.mobileValid = false;
    this.mobileActive = true;
  }

  blurMobileCheck() {
    if (this.mobileVal) {
      const myreg = /^(1[3|4|5|6|7|8|9])\d{9}$/;
      const valid = myreg.test(this.mobileVal);
      this.mobileValid = valid ? false : true;
      this.mobileError = valid ? '' : '手机号格式不正确';
    } else {
      this.mobileValid = true;
      this.mobileError = '请输入手机号';
    }

    if(this.accountVals.mobile != this.phone){
      this.showVerifyBox = true;
    }else{
      this.showVerifyBox = false;
    }

    this.mobileActive = false;
    if (this.mobileValid) {
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.mobileValid = false;
        }, 3000);
        return false;
      }
    }
    return true;
  }

  checkVerificationCode() {
    if (!this.phoneCaptchaValue) {
      this.phoneCaptchaValid = true;
      this.phoneCaptchaError = '验证码必填';
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(() => {
          that.phoneCaptchaValid = false;
        }, 3000);
      }
      return false;
    } else {
      this.phoneCaptchaValid = false;
    }
    let codeResult: any = {code: '', message: ''};
    const paramsUrl = '?action=checkSmsCode' + '&smsType=update_mobile_sms&mobile=' + this.accountVals.mobile + '&verification_code=' + this.phoneCaptchaValue;
    this.request.get(apiConfig.customerApi + paramsUrl)
      .subscribe(
       (val:any) => {
          codeResult = val;
          if (codeResult.code !== '200') {
            this.phoneCaptchaValid = true;
            this.phoneCaptchaError = '验证码错误';
            const that = this;
            if (isPlatformBrowser(this.platformId)) {
              this.timer = setTimeout(() => {
                that.phoneCaptchaValid = false;
              }, 3000);
            }
            this.createImage();
            return false;
          } else {
            this.phoneCaptchaValid = false;
          }
		  return;
        }
      );
    return true;
  }

  /*发送手机验证码*/
  sendPhoneVerification() {
    if (!this.blurMobileCheck()) {
      return false;
    }
    this.phoneCaptchaDisabled = true;
    const that = this;
    let sendResult: any = {code: '', message: ''};
    const paramsUrl = '?action=SendSms' + '&type=update_mobile&mobile=' + this.accountVals.mobile;
    this.request.get(apiConfig.customerApi + paramsUrl)
      .subscribe(
        val => {
          sendResult = val;
          if (sendResult.code * 1 === 200) {
            this.localStorage.setItem('is_send_register_code', new Date().valueOf());
            let int = 59;
            const timerEffect =  setInterval(function () {
              that.sendPhoneLabel = '重新发送(' + int-- + ')';
              if (int <= 1) {
                that.sendPhoneLabel = '发送验证码';
                that.phoneCaptchaDisabled = false;
                clearInterval(timerEffect);
              }
            }, 1000);
            this.timerEffect = timerEffect;
          } else {
            this.phoneCaptchaValid = true;
            this.phoneCaptchaDisabled = false;
            this.phoneCaptchaError = sendResult.message;
            if (isPlatformBrowser(this.platformId)) {
              this.timer = setTimeout(() => {
                that.phoneCaptchaValid = false;
              }, 3000);
            }
          }
        }
      );
	  return;
  }

  editMobile(){
    this.showUpdateMobileForm = true;
  }

  closeEditMobileForm(){
    this.showUpdateMobileForm = false;
  }

  closeCodeBox() {
    this.createImage();
    this.imageValidError = false;
    this.hiddenImageValidBox = true;
  }

  imageValueCheck() {
    if (this.imageValidValue) {
      let result: any = {code: '', message: ''};
      const paramsUrl = '?action=checkCaptcha&captcha_id=' + this.imageValidId + '&catpcha_value=' + this.imageValidValue;
      this.request.get(apiConfig.customerApi + paramsUrl)
        .subscribe(
          val => {
            result = val;
            this.createImage();
            if (result.code === 200) {
              this.closeCodeBox();
              this.sendPhoneVerification();
            } else {
              this.imageValidError = true;
              this.imageValidErrorLabel = '验证码错误';
              const that = this;
              if (isPlatformBrowser(this.platformId)) {
                this.timer = setTimeout(function () {
                  that.imageValidError = false;
                }, 3000);
              }
            }
          }
        );
    } else {
      this.imageValidError = true;
      this.imageValidErrorLabel = '请输入验证码';
      const that = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.imageValidError = false;
        }, 3000);
      }
    }
  }

  accountInfo(){
    this.showAccountInfo = true;
    this.showMemberGrowth = false;
  }

  memberGrowth(){
    this.showAccountInfo = false;
    this.showMemberGrowth = true;
    this.editValue();
  }

  hiddenQrCodBox(){
   this.addBirthdaySuccess = false;
   this.goMiniPrograme = false;
  }

  showQrCode(){
    this.goMiniPrograme = true;
  }

  customerPointsInfo(){
    this.customerService.getCrmPointsOverview().then(val =>{
      let response = val;
      if(response.code === 1000){
        this.pointsInfo = response.data;
      }
    }).catch(error => error);
  }

	// 取消隐私协议并强制退出
	cancelAuthorization(){
	  this.showRefuseBox = true;
	}

	// 确认取消同意隐私协议
	refuseConfirm(){
		this.showRefuseBox = false;
		let params = {isAgreed:'No',name:'privacy',version:'20211109'};
		this.customerService.operateAgreement(params).then(val => {
			if(val.code === 1000){
				this.logout();
				this.router.navigate(['']);
			}
		});
	}

	logout() {
		this.insertLogoutLog();
		this.timer = setInterval(() => {
		this.init.logout();
		this.cart.restCartModel();
	    this.router.navigate(['']);
	    clearInterval(this.timer);
	  }, 500);
	}
	insertLogoutLog(){
	  this.customerService.clientIpService().then(val=>{
	    this.logoutLogRs = val;
	    if(this.logoutLogRs.code === 1000){
	      const params = {'customerId':this.localStorage.getItem('customer_id'),'ip':this.logoutLogRs.data,'merchantId':this.init.merchantId,'logType':'CUSTOMER_LOGOUT'};
	      this.customerService.customerLoginLog(params).then( _val => {
	      })
	    }
	  })
	}

}
