<div class="u-cols-lg-24-24 u-cols-md-12-12 u-cols-sm-12-12 u-row" *ngIf="!hiddenWaitLoad; else pdpImgLoadingBlock">
  <div class="contain pdpBox">
    <app-product-main-image-slide class="productImage" [showVideo]="showVideo" [includeVideo]="includeVideo" [ImageArray]="colorImgList" [isNewArrival] = "productModel.is_new"></app-product-main-image-slide>
    <div class="parbase details" [ngClass]="{'size_chart': showSizeChart}">
      <div class="u-cols-sm-12-12 u-no-padding-mobile pageContain">
        <div data-component="OProductInformation" class="o-product-information" style="top: 96px" *ngIf="!hiddenWaitLoad">
          <form data-component="OForm" class="o-form add-to-bag goodsInfoForm" action="/" method="POST" tabindex="1">
            <div class="topBox">
						<div class="title hidden-xs">
				<app-breadcrumb class="pdpBreadcrumb"></app-breadcrumb>
				<p class="a-paragraph" id="productTitle">{{productModel.itemName}}</p>
            </div>
            <div class="hidden-xs" id="product-price">
              <div class="m-product-price price parbase" *ngIf="productColorSoldOut;else onSalePrice">
				    <label class="a-label js-a-label" id="productPrice">{{init.formatPrice(productModel.cloudPrice) | currency:currency}}</label>
              </div>
              <ng-template #onSalePrice>
                <div class="m-product-price price parbase">
					<label class="a-label js-a-label" [ngClass]="{'is-reduced product-price':productModel?.getPrice()}" id="productPrice">{{init.formatPrice(productModel.getSalesPrice()) | currency:currency}}</label>
					<label class="a-label js-a-label is-deprecated" *ngIf="productModel?.getPrice()">{{productModel.cloudPrice | currency:currency}}</label>
				</div>
              </ng-template>
            </div>
			
			<app-product-strategy-tag #productStrategyTagComponent          
								  [itemId]="productModel.itemId"
								  [valueId]="productModel.valueId"
								  [memberLevelId]="init.memberLevelId"
			                      [isLogin]="init.isLogin()">
			</app-product-strategy-tag>			

			<app-product-promotion-tags #productPromotionTags
				[promotionTagList]="promotionTagList"
				[customPromotionTagList]="customPromotionTagList"
				[tagStyle]="tagStyle"
				(checkForwardPurchase)="checkForwardPurchaseLabel($event)" 
				[productModel]="productModel">
			</app-product-promotion-tags>
			
            <app-product-color-dropdown [colorId]="productModel.valueId"
										[itemName]="productModel.itemName"
                                        [list]="productColors"
                                        [soldOut]="productColorSoldOut"
                                        [colorSoldOut]="colorSoldOut"
                                        (change)="changeColor($event)">
            </app-product-color-dropdown>
		
			<!-- 商品描述 -->
			<app-product-description
				(changeTab)="changeTab($event)"
				[favoriteItemIds]="favoriteItemIds"
				(addFavorite)="commonAddFavorite($event)" 
				(delFavorite)="commonRemoveFavoriteItem($event)"
				[hasSizeGuide]="hasSizeGuide"
				[finalSizeImageUrl]="finalSizeImageUrl"
				[productModel]="productModel">
			>
			</app-product-description>
			</div>
			<!-- <div class="bottomBox"> -->
			<!-- 搭配推荐 -->
			<!-- <ng-container *ngIf="productService.enableWhole || productService.enableSimilar">
			  <div class="hidden-md hidden-sm">
				<app-recommend-common [ispdp]="ispdp" [recommendLists]="recommendWhole" [count]="4" (quickAdd)="quickAdd($event)"></app-recommend-common>
			  </div>
			</ng-container>
			</div> -->
			<!-- COS BY YOU -->
				<app-product-cosbyyou class="hidden-lg" *ngIf="cosbyyouArray !== undefined && cosbyyouArray.length>0" [ImageArray]="cosbyyouArray"></app-product-cosbyyou>
          </form>
        </div>
		
		<!-- 尺码，加购按钮 -->
		<div class="rightBagButton">
			<div class="boxStyle" (click)="changeTab({'tabId':'select-size'})">
				<div class="title">
					<ng-container *ngIf="!!selectedSizeName && selectedSizeName !== '选择尺码';else emptySize">
						<ng-container *ngIf="productService.splitSize(selectedSizeName).length == 3;else normalSize">
							{{productService.splitSize(selectedSizeName)[0]+'('+productService.splitSize(selectedSizeName)[1]}}
							{{'('+productService.splitSize(selectedSizeName)[2]}}
						</ng-container>
						<ng-template #normalSize>
							<ng-container *ngIf="selectedSizeName !== '均码';else uniformSize">
								{{productService.splitSize(selectedSizeName)[0]}}
								{{'('+productService.splitSize(selectedSizeName)[1]}}
							</ng-container>
							<ng-template #uniformSize>
								{{productService.splitSize(selectedSizeName)[0]}}
							</ng-template>
						</ng-template>
					</ng-container>
					<ng-template #emptySize>
						尺码
					</ng-template>
				</div>
				<div class="option">
					选择
					<div><img style="width:5px;" src="https://100000005-1252208446.file.myqcloud.com/images/newMP/right_a2.png"/></div>
				</div>
			</div>

			<!-- 加购，预售 -->
			<div class="boxStyle-presale addToBag" (click)="payDeposit()" *ngIf="onPresale; else generalSaleBtn">
				<div class="title"><img style="width:16px;" src="https://100000005-1252208446.file.myqcloud.com/images/newMP/bag_w@2.png"/></div>
				<div class="option-presale">
					<div class="presale-btn">{{presaleBtnText}}</div>
					<div class="presale-btn">{{presalePrice|currency:currency}}</div>
				</div>
			</div>
			<ng-template #generalSaleBtn>
				<div class="boxStyle addToBag" (click)="(tabId == 'select-size' || oneSizeProduct || (!!selectedSizeName && selectedSizeName !== '选择尺码')) ? addToBuy() : changeTab({'tabId':'add-to-buy'})">
					<div class="title"><img style="width:16px;" src="https://100000005-1252208446.file.myqcloud.com/images/newMP/bag_w@2.png"/></div>
					<div class="option">
						<span>{{buttonText}}</span>
					</div>
				</div>
			</ng-template>
		</div>
				
      </div>

			<div class="bottomBox hidden lg-block">
          <!-- 搭配推荐 -->
          <ng-container *ngIf="productService.enableWhole || productService.enableSimilar">
            <div class="hidden-md hidden-sm" style="padding-right: 6px;">
              <div class="grid w-full grid-cols-6 gap-16 col-end-4">
                <div class="col-start-1 col-end-5">
                  <app-recommend-common [ispdp]="ispdp" [recommendLists]="recommendWhole" [count]="4" (quickAdd)="quickAdd($event)"></app-recommend-common>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
    </div>
  </div>
	<!-- 右侧弹层 -->
	<div class="pageTopLayer" style="display: none;">
		<div aria-hidden="true" class="pageTopLayer-bg"  (click)="closeLayer()"></div>
		<!-- 弹层：细节 -->
		<div class="subBox" id="item-details" style="display: none;">
			<div class="m-addition">
				<div class="iconBox" style="position: absolute;width: 100%;">
					<div class="iconBuoy"></div>
				</div>
				<div class="icon_close" (click)="closeLayer()">
					<svg class="inline h-4 w-4 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
				</div>
				<div class="bTitle">{{productModel.itemName}}</div>
				
				<div class="goodsDescribe describe-border" [ngSwitch]="productModel.sku">
					<div class="describe-detail" (click)="clickDescribe()">
						<div class="gTitle">描述</div>
						<div>
							<svg id="describe-plus-svg" class="plus-minus-svg">
								<use href="/assets/iconSprite.svg#plus-small"></use>
							</svg>
							<svg id="describe-minus-svg" class="plus-minus-svg block-hidden">
								<use href="/assets/iconSprite.svg#minus-small"></use>
							</svg>
						</div>
					</div>
					<div id="item-describe" class="margin-bottom block-hidden">
						<p class="a-paragraph" [innerHTML]="productModel.frontDescription ? productModel.frontDescription : '' | html"></p>
					</div>
				</div>
 
				
				<div class="goodsDescribe">
					<div class="describe-detail" (click)="clickDetail()">
						<div class="gTitle">细节</div>
						<div>
							<svg id="detail-plus-svg" class="plus-minus-svg">
								<use href="/assets/iconSprite.svg#plus-small"></use>
							</svg>
							<svg id="detail-minus-svg" class="plus-minus-svg block-hidden">
								<use href="/assets/iconSprite.svg#minus-small"></use>
							</svg>
						</div>
					</div>
	
				  <p id="detail" class="a-paragraph block-hidden">
					  {{productModel?.frontFeature}}
					  <span id="careInstructions" *ngIf="productModel?.frontWash">/ {{productModel?.frontWash}}</span>
					  款号: <span id="article-number">{{productModel?.ccSku}}</span>
					  <span id="imported"></span>
				  </p>
				</div>
			</div>
		</div>

		<!-- 弹层：描述 -->
		<div class="subBox" id="item-description" style="display: none;">
			<div class="m-addition">
				<div class="iconBox" style="position: absolute;width: 100%;">
					<div class="iconBuoy"></div>
				</div>
				<div class="icon_close" (click)="closeLayer()">
					<svg class="inline h-4 w-4 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
				</div>
				<div class="bTitle">{{productModel.itemName}}</div>
				<div class="goodsDescribe" [ngSwitch]="productModel.sku">
					<div class="gTitle">描述</div>
				  <p class="a-paragraph" [innerHTML]="productModel.frontDescription ? productModel.frontDescription : '' | html"></p>
				</div>
			</div>
		</div>
		
		<!-- 弹层：支付和配送 -->
		<div class="subBox" id="payment-delivery" style="display: none;">
			<div class="m-addition">
				<div class="iconBox" style="position: absolute;width: 100%;">
					<div class="iconBuoy"></div>
				</div>
				<div class="icon_close" (click)="closeLayer()">
					<svg class="inline h-4 w-4 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
				</div>
				<div class="bTitle">{{productModel.itemName}}</div>
				<div class="goodsDescribe" [ngSwitch]="productModel.sku">
					<div class="gTitle">支付 & 配送</div>
					<p class="a-paragraph" [innerHTML]="deliveryMethods? deliveryMethods : '' | html">
				</div>
			</div>
		</div>
		
		<!-- 弹层：二维码 -->
		<div class="subBox" id="item-qrcode" style="display: none;">
			<div class="icon_close" (click)="closeLayer()">
				<svg class="inline h-4 w-4 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
			</div>
			<div class="bTitle">{{productModel.itemName}}</div>
			<div class="QRcodeBox">
				<img src="{{qrcodeSrc}}" style="height: 120px;width: 118px;"/>
			</div>
		</div>
		<!-- 弹层：快速加购 class="subBox" -->
		<div id="add-to-buy" style="display: none;">
			<app-product-size-box
				[list]="productSizeArray[productModel.valueId]"
				[showVideo]="showVideo"
				[includeVideo]="includeVideo"
				[productModel]="productModel"
				(change)="changeSize($event)"
				(closeLayer)="closeLayer()"
				(changeTab)="changeTab($event)"
				[ImageArray]="colorImgList" 
				[hideSizeChart]="hideSizeChart"
				[frontCustomParameters]="frontCustomParameters"
				[sizeChartHtml]="productModel?.pdp_sizechart"
				[sizeTableImageUrl]="productModel?.sizeTableImageUrl"
				[tabId]="tabId"
			></app-product-size-box>
		</div>
		
		<!-- 弹层：尺码指南 -->
		<div class="subBox size-guide-pop" id="size-guide" style="display: none;">
			<div class="m-addition">
				<div class="iconBox" style="position: absolute;width: 100%;">
					<div class="iconBuoy"></div>
				</div>
			<div class="icon_close" (click)="closeLayer()"> 
				<svg class="inline h-4 w-4 fill-current"><title>关闭</title><use href="/assets/iconSprite.svg#nav-close-desktop"></use></svg>
			</div>
			<div class="bTitle">尺码指南</div>
			<div class="drop-down">
				<div class="dnItem">
					<div class="dnTitle">
						商品尺寸表
					</div>
					<div class="sizeTable">
						<!-- 尺码图片 -->
						<div *ngIf="hasSizeGuide;else oldSizeTableImg">
							<img *ngIf="!!measurePic" alt="COS" class="sizeTableImg" src="{{measurePic}}">
							<div class="dnItem" *ngIf="!!howToMeasurePic">
								<div class="dnTitle">
									如何测量
									<div class="rightIcon">
										<div *ngIf="showMeasureMethod;else hideMeasureMethod" (click)="operateMeasureMethod()">
											<svg class="plus-minus-svg"><title></title><use href="/assets/iconSprite.svg#minus-small"></use></svg>
										</div>
										<ng-template #hideMeasureMethod>
											<div (click)="operateMeasureMethod()">
												<svg class="plus-minus-svg"><title></title><use href="/assets/iconSprite.svg#plus-small"></use></svg>
											</div>
										</ng-template>
									</div>
								</div>
								<div class="sizeTable">
									<img *ngIf="showMeasureMethod" alt="COS" class="sizeTableImg" src="{{howToMeasurePic}}">
									<img *ngIf="!!sizeChartPic" alt="COS" class="sizeTableImg" src="{{sizeChartPic}}">
									<img *ngIf="!!tryonPic" alt="COS" class="sizeTableImg" src="{{tryonPic}}">
								</div>
							</div>
						</div>
						<ng-template #oldSizeTableImg>
							<img alt="COS" id="size-guide-template-image" class="sizeTableImg" src="{{finalSizeImageUrl}}">
						</ng-template>
					</div>
				</div>

			</div>
		</div>
		</div>
	</div>
	<!-- /右侧弹层 -->
  <div class="float-on-mobile hidden-lg hidden-md hidden-sm h5BottomBox">

  <div class="cta-container cta-scroll-container" id="mAddToBagFloat">
		<div class="iconBox" (click)="cardClickAnimate();">
			<div class="iconBuoy"></div>
		</div>
		
	  <!--h5 pdp放大 收藏-->
	  <div class="bottomIcon">
	  	<div class="icon_zoomIn">
	  		<svg class="inline h-4 w-4 fill-current"><title>放大</title><use href="/assets/iconSprite.svg#zoom-in"></use></svg>
	  	</div>
 
		<ng-container *ngIf="favoriteItemIds[productModel.itemId+'_'+productModel.valueId]; else notin">
			<div class="pdp-m-heart h5heart activation" id="after_favorite_{{productModel.itemId}}_{{productModel.valueId}}">
				<a   class="a-link" (click)="delFavoriteItem(productModel.itemId,productModel.valueId)"><span class="iconheart  pull-left"></span></a>
			</div>	
		</ng-container>
		<ng-template #notin>
			<div  class="pdp-m-heart h5heart" id="{{productModel.itemId}}_{{productModel.valueId}}_before_favorite">
				<a class="a-link"><span class="iconheart  pull-left" (click)="addFavorite(productModel.ccSku,productModel.itemId,productModel.valueId)"></span></a></div>
		</ng-template>
	  </div>
	  <div class="overflow-y h5_PDP_Info">
		<div id="touchArea" (click)="cardClickAnimate();">
			<div class="title">
			  <p class="a-paragraph">{{productModel.itemName}}</p>
			  <div class="m-product-price" *ngIf="productColorSoldOut;else miniOnSalePrice">
				<span class="is-regular a-label js-a-label">{{productModel.cloudPrice |currency:currency}}</span>
			  </div>
			  <ng-template #miniOnSalePrice>
				<div class="m-product-price" id="mobile-product-price">
				  <span class="is-regular a-label js-a-label" [ngClass]="{'is-reduced product-price':productModel?.getPrice()}" id="productPrice">{{productModel.getSalesPrice() |currency:currency}}</span>
							<span class="is-regular a-label js-a-label is-deprecated"  *ngIf="productModel?.getPrice()">{{productModel.cloudPrice |currency:currency}}</span>
						</div>
			  </ng-template>
			</div>
			
			<app-product-strategy-tag #productStrategyTagComponent
								  [itemId]="productModel.itemId"
								  [valueId]="productModel.valueId"
								  [memberLevelId]="init.memberLevelId"
								  [isLogin]="init.isLogin()">
			</app-product-strategy-tag>
			
			<!-- 标签 -->
			<app-product-promotion-tags #productPromotionTags
				[promotionTagList]="promotionTagList"
				[customPromotionTagList]="customPromotionTagList"
				[tagStyle]="tagStyle"
				(checkForwardPurchase)="checkForwardPurchaseLabel($event)" 
				[productModel]="productModel" class="h5PDPTag">
			</app-product-promotion-tags>
			
			<!-- H5加购尺码 -->
			<div class="h5_bottomBtn">
				<button type="button" class="a-button open-description is-primary" (click)="changeTab({'tabId':'select-size'})">
					<p class="xs-size-btn-p">
						<span>{{selectedSizeName}}</span>
						<span *ngIf="isSoldOutSoon" class="low-in-stock">{{lowInStock}}</span>
					</p>
			
					<img class="icon_h5Size" src="https://100000005-1252208446.file.myqcloud.com/images/newMP/right_a2.png"/></button>
				  <!--预售button-->
				  <div *ngIf="onPresale; else mobGeneralSaleBtn">
					<button id="presale-cart-button" (click)="payDeposit(productSku)" [ngClass]="{'is-disabled':!buttonLock}" tabindex="101" class="a-button is-primary add-to-bag-btn is-disabled">{{presaleBtnText}}</button>
				  </div>
			
				  <ng-template #mobGeneralSaleBtn>
					<button id="add-cart-button" (click)="(tabId == 'select-size' || oneSizeProduct) ? addToBuy() : changeTab({'tabId':'add-to-buy'})" [ngClass]="{'is-disabled':!selectFloatSize}"  class="a-button is-primary is-disabled" tabindex="101">
					  {{buttonText}}
					</button>
				  </ng-template>
			</div>
		</div>



	
	
	<!-- H5商品内容 -->
	<div class="tempBox" id="mob-item-info">
		<!-- 色块 -->
		<app-product-color-dropdown [colorId]="productModel.valueId"
									[itemName]="productModel.itemName"
									[list]="productColors"
									[soldOut]="productColorSoldOut"
									[colorSoldOut]="colorSoldOut"
									(change)="changeColor($event)" class="H5PDPColor">
		</app-product-color-dropdown>
		
		<!-- 商品描述 -->
		<app-product-description
			(changeTab)="changeTab($event)"
			[favoriteItemIds]="favoriteItemIds"
			(addFavorite)="commonAddFavorite($event)" 
			(delFavorite)="commonRemoveFavoriteItem($event)"
			[hasSizeGuide]="hasSizeGuide"
			[finalSizeImageUrl]="finalSizeImageUrl"
			[productModel]="productModel" class="h5PDP_Description">
		>
		</app-product-description>
		
		<!-- 推荐搭配 -->
		<ng-container *ngIf="(productService.enableWhole || productService.enableSimilar) && (recommendWhole.length > 0 || recommendSimilar.length > 0)">
			<div class="u-cols-lg-24-24 u-cols-md-12-12 u-cols-sm-12-12 u-row u-full-width" id="pdp-recommend-collocation" class="m-recommend-collocation hidden-pc PDP_h5Recommend">
			  <app-recommend-style [favoriteItemIds]="favoriteItemIds" [recommendWhole]="recommendWhole" [recommendSimilar]="recommendSimilar" (addFavorite)="quickAddFavorite($event)" (delFavorite)="quickRemoveFavoriteItem($event)" (quickAdd)="quickAdd($event)"></app-recommend-style>
			</div>
		</ng-container>
    <div class="w-full pdp-footer">
		  <app-footer></app-footer>
    </div>
	</div>
	</div>
	
  </div>
</div>
</div>

<ng-template class="section-summary shopaddress" #pdpImgLoadingBlock>
  <div class="customer-data setAddress pageLoading">
    加载中......
  </div>
</ng-template>
<!-- 显示加入购物车报错：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_modal || show_favorite_modal}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_modal || show_favorite_modal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 显示加入购物车报错 开始 -->
	    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2" *ngIf="!show_favorite_modal">抱歉！</h2>
      <p class="a-paragraph">{{message}}</p>
    </div>
    <!-- 显示加入购物车报错 结束 -->

  </div>
</div>

<!--非会员购买提前购商品提示框-->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': purchaseLimit}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="purchaseLimit" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <div class="m-change-country-lightbox false">
      <img  src="https://www.cos.cn/img/dm/pageup/190123/300x300.jpg" alt="图片">
      <p>微信扫码或长按图片识别<br>即刻加入<br>“COS摩登衣橱”微信会员计划</p>
    </div>
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': remindSuccess || remindError}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="remindSuccess || remindError">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="paragraph parbase section">
          <span class="richTextSpan">
          <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
            <div class="contain">
              <div class="u-row u-full-width">
                <div class="content-page page remind" *ngIf="remindSuccess; else zcAndEamil">
                  <h1>扫码关注COS官方微信第一时间获取到货通知</h1>
                  <p><img _ngcontent-c9="" alt="图片" class="qr-image" src="https://100000005-1252208446.file.myqcloud.com/images/qrCode/2021010897667.jpg"></p>
                </div>
                <ng-template #zcAndEamil>
                  <div class="content-page page remind">
                    <h1>抱歉！</h1>
                    <p>{{remindErrorMsg}}</p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>


<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': displayPresaleClause}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="displayPresaleClause" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <div class="m-change-country-lightbox false topLightbox">
      <div class="title">请在阅读并同意以下条款后支付定金<br>COS预售条款</div>
      <p>1. 官网用户通过进入指定商品详情页，按照官网购物流程进行操作，点击“立即付定金”即可提交订单。</p>
      <p>2. 官网用户需在规定时间内完成尾款支付，如未在规定时间内支付尾款，订单则被自动取消，定金将无法返还。</p>
      <p>3. 定金及尾款均需在规定时间内完成支付，否则订单将被自动取消。尾款支付成功后，您可以在官网“我的账户” – “我的订单”页面查看您的订单处理进程。</p>

      <div class="m-checkbox u-clearfix" style="text-align: center;">
        <input class="a-checkbox inputCheckbox" id="presale_rules_box" name="police" type="checkbox" [checked]="agreeTerms" (click)="agreeClause()">
        <label class="a-label js-a-label custom-box" for="presale_rules_box"></label>
        <label class="a-label js-a-label" for="presale_rules_box">
          <span class="newsLetterText">我已阅读以上条款并同意定金不退预售协议</span>
        </label>
      </div>

      <div class="btnGroup"><input type="button" value="取消" (click)="cancelDeposit()" class="btn1"> <input type="button" value="提交" (click)="submitPresale()" class="btn2"></div>
    </div>

    <div class="m-button-icon m-button-icon-h5 a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

<!-- 弹层：快速加购  -->
<ng-container *ngIf="showQuickAddBox">
	<app-quick-add 
		[frontCode]="recommendFrontCode"
		[salePrice]="recommendSalePrice"
		[favoriteItemIds]="favoriteItemIds"
		(addFavorite)="quickAddFavorite($event)"
		(delFavorite)="quickRemoveFavoriteItem($event)"
		(closeBox)="closeLayer()">
	</app-quick-add>
</ng-container>
<!-- 快速收藏结果提示层  -->
<app-favorite-box></app-favorite-box>
