<div class="refresh" *ngIf="editMode">
  <span class="glyphicon glyphicon-refresh" (click)="refresh()"></span>
</div>
<!--Title Start-->
<div class="paragraph parbase section cq-Editable-dom"><span class="richTextSpan">
	<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
		<div class="contain" style="clear:both">
			<div class="u-row u-full-width o-blog-text is-richtext richtextWrapper" style="width: 100%; clear:both">
				<div style="text-align: center;font-size: 24.0px;letter-spacing: 0.04em;color: rgb(68,68,68);">
        	{{block.data.categoryTitle}}
        </div>
			</div>
		</div>
</div>
</span>
</div>
<div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 lineAtt">
  <hr class="a-keyline">
</div>
<!--Title End-->


<!--3rows-->
<div *ngIf="block.data.config.rows == 'threeLines'" data-component="ProductContainer" class="o-category-listing">
  <div id='products' [dragula]='"nested-bag"'>
    <div *ngFor="let product of productModel.productList" class="column">
      <div>
        <!-- 完整 -->
        <div class="o-product">
          <div class="image-holder">
            <a [routerLink]="product?.url_path">
            <img class="a-image default-image ng-star-inserted"
                 src="{{product?.default_image_path}}" alt="图片">
            </a>
          </div>
          <div class="description">
            <a [routerLink]="product?.url_path">
            <label class="a-label js-a-label product-title nameBox">{{product?.productName}}</label>
            <div class="m-product-price priceBox">{{init.currency}}{{product?.salePrice}}</div>
            </a>
           <div class="m-swatches sm">
              <div class="picked-color"></div>
              <div class="a-swatch js-swatch" *ngFor="let block of product?.imageBlock">
                <label class="a-label js-a-label">
                  <input type="radio" name="colors" value="Black"/>
                  <span>
                        <div class="a-swatch-foreground"></div>
                        <img class="a-image" src="{{block?.image}}" alt="图片"/>
                        <p class="sold-out">Sold out</p>
                      </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- //完整 -->
      </div>
    </div>
  </div>
  <ng-template #noProductBlock>
    <div>无相关商品</div>
  </ng-template>
</div>

<!--4rows-->
<div *ngIf="block.data.config.rows == 'fourLines'" class="fourcolumns parbase section cq-Editable-dom">
  <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
    <div class="o-grid-controller is-four-cols" style="background-color: #ffffff">
      <div class="content">
        <div class="scrollable-content">
          <div *ngFor="let product of productModel.productList" class="column">
            <div>
              <div class="freetile parbase section cq-Editable-dom">
                <div class="m-free-tile four-cols" style="background-color: #ffffff">
                  <a [routerLink]="product?.url_path" target="_self">
                    <img class="a-image"
                         src="{{product?.default_image_path}}" alt="图片">
                  </a>
                </div>
                <div class="items">
                  <a href="https://author.cos.appeaser.hm.com/content/cos/ch/men.html#" target="_self"
                     class="a-link open-lightbox" data-template="newsletter-signup" data-classes="is-subscribe">
                  </a>
                </div>
              </div>
              <div class="freetile parbase section cq-Editable-dom">
                <div class="m-free-tile four-cols" style="background-color: #ffffff">
                  <div class="cta-wrapper">
                    <a [routerLink]="product?.url_path" target="_self"
                       class="a-link cta-link" style="color: rgb(68, 68, 68); text-decoration: none;"><span
                      class="underline">{{product?.productName}}</span>
                      <div class="m-product-price priceBox">{{init.currency}}{{product?.salePrice}}</div>
                    </a>
                    <div class="m-swatches sm">
                      <div class="picked-color"></div>
                      <div class="a-swatch js-swatch" *ngFor="let block of product?.imageBlock">
                        <label class="a-label js-a-label">
                          <input type="radio" name="colors" value="Black"/>
                          <span>
                        <div class="a-swatch-foreground"></div>
                        <img class="a-image" src="{{block?.image}}"/>
                        <p class="sold-out">Sold out</p>
                      </span>
                        </label>
                      </div>
                    </div>
                </div>
                <div class="items">
                  <a href="https://author.cos.appeaser.hm.com/content/cos/ch/men.html#" target="_self"
                     class="a-link open-lightbox" data-template="newsletter-signup" data-classes="is-subscribe">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
