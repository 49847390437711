import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {CustomerService} from '../../services/customer.service';

@Component({
  selector: 'app-tomp',
  templateUrl: './tomp.component.html',
  styleUrls: ['./tomp.component.css']
})
export class TompComponent implements OnInit{
  constructor(
    public router: Router,
    public routerInfo: ActivatedRoute,
    private customerService:CustomerService,
	
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }
 
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
		var userAgent = navigator.userAgent.toLowerCase(); //取得浏览器的userAgent字符串
		// console.log('userAgent----'+ userAgent)
		/**
		 * 百度极速版不支持
		 * UC、qq浏览器都支持
		 */
		if(userAgent.indexOf('matrixstyle/0') !== -1 && userAgent.indexOf('baiduboxapp') !== -1 ) {
		  alert("暂不支持该浏览器打开小程序，请使用手机自带浏览器或火狐浏览器进行访问。")
		  return
		}

		let mppath =  this.routerInfo.snapshot.queryParams['path'];
		//以下为根据path获取小程序短链接程序
		let appId = 'wxcc292543eb62b3f4';//测试环境使用奕尚体验版appid wxad2ee6fa2df2c46d，正式环境需更换成COS小程序appid wxcc292543eb62b3f4
		const params = {path:mppath,query:'',expireTime: '',expireType: 1,expireInterval: 30};
		let result: any = {code: '', msg: '',data:''};
		this.customerService.schemeGenerate(params,appId).then(val => {
		 result = val;
		 if(result.code == 1000){
		   console.log(result.data.openlink);
		   if(result.data.openlink !== '' && result.data.openlink !== null){
		     location.href = result.data.openlink;
		   }
		 }else{
		   this.router.navigate(['/']);
		 }
		})

    }
  }
 

}
