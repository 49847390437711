import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {StorageService} from './storage.service';

let headers: any;

@Injectable()
export class StoreLocatorService {

  constructor(private httpClient: HttpClient, private localStorage: StorageService) {
  }

  public get(url:any) {
    const token = this.localStorage.getItem('jwt');
    if (token !== null) {
      headers = new HttpHeaders().set('jwt', token);
    }
    return this.httpClient.get(url, {headers});
  }
}
