import {Component, OnInit} from '@angular/core';
import {InitializeService} from '../../../services/initialize.service';
import {Router} from '@angular/router';
import {CustomerService} from '../../../services/customer.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public oldPasswordVal:any;
  public newPasswordVal:any;
  public rePasswordVal:any;
  public oldPwd: Boolean = false;
  public passWord: Boolean = false;
  public rePassWord: Boolean = false;
  public affirmPassWord:any = '请再次输入新密码';
  public newPassWord:any = '请输入6到16位字符的新密码，且包含字母和数字。';
  public show_modal: boolean = false;
  public showError: boolean = false;
  public message: any;
  constructor(public init: InitializeService, private customer:CustomerService, private router: Router) {
  }

  ngOnInit() {
    this.init.pageConfiguration('修改密码');
  }
  /*校验*/
  inpOldPwd() {
    if (this.oldPasswordVal === undefined || this.oldPasswordVal === ''){
      this.oldPwd = true;
    } else {
      this.oldPwd = false;
    }
  }
  inpPassword() {
    const validateString = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6,16}$/;
    if (this.newPasswordVal === undefined || this.newPasswordVal === '') {
      this.newPassWord = '请输入6到16位字符的新密码，且包含字母和数字。';
      this.passWord = true;
    } else if(this.newPasswordVal === this.oldPasswordVal) {
      this.newPassWord = '新密码不能和旧密码相同!';
      this.passWord = true;
    } else {
      if (!this.newPasswordVal.match(validateString)){
        this.newPassWord = '请输入6到16位字符的新密码，且包含字母和数字。';
        this.passWord = true;
      } else {
        this.newPassWord = '请输入6到16位字符的新密码，且包含字母和数字。';
        this.passWord = false;
      }
    }
  }
  inpConfirm() {
    if (this.rePasswordVal === undefined || this.rePasswordVal === ''){
      this.rePassWord = true;
    } else if(this.newPasswordVal !== this.rePasswordVal) {
      this.affirmPassWord = '两次输入的新密码不匹配';
      this.rePassWord = true;
    } else {
      this.affirmPassWord = '请再次新输入密码';
      this.rePassWord = false;
    }
  }
  onSubmit() {
    const validateString = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%\^&*?]{6,16}$/;
    if (this.oldPasswordVal === undefined || this.oldPasswordVal === '') {
      this.oldPwd = true;
      return;
    } else if (this.newPasswordVal === undefined || this.newPasswordVal === '') {
      this.passWord = true;
      return;
    } else if(this.newPasswordVal === this.oldPasswordVal) {
      this.newPassWord = '新密码不能和旧密码相同!';
      this.passWord = true;
      return;
    } else if (this.rePasswordVal === undefined || this.rePasswordVal === '') {
      this.affirmPassWord = '请再次输入新密码';
      this.rePassWord = true;
      return;
    } else if (!this.newPasswordVal.match(validateString)) {
      this.passWord = true;
      return;
    } else if (this.newPasswordVal !== this.rePasswordVal) {
      this.affirmPassWord = '两次输入的新密码不匹配';
      this.rePassWord = true;
      return;
    }

    let codeResult: any = {code: '', msg: '',data:''};
    const params = {confirmPassword:this.rePasswordVal,newPassword:this.newPasswordVal,oldPassword:this.oldPasswordVal};
    this.customer.resetPassword(params).then(val => {
        codeResult = val;
      if(codeResult.code === 1000){
        this.router.navigate(['/my-account/profile']);
      } else {
        this.show_modal = true;
        this.showError = true;
        this.message = codeResult.msg;
      }
    }).catch(error => error);
  }

  // 关闭遮罩层和弹框
  closeOverlay() {
    this.show_modal = false;
    this.showError = false;
  }

}
