import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {RmaService} from '../../services/rma.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-rma-return',
  templateUrl: './rma-return.component.html',
  styleUrls: ['./rma-return.component.css']
})
export class RmaReturnComponent implements OnInit {
  public rma_id:any;
  public express_name:any = '';
  public express_number:any = '';
  public express_code:any = '';
  public express_name_error:any = false;
  public express_number_error:any = false;
  public canSave:any = false;
  constructor(
    public rmaService: RmaService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public routerInfo: ActivatedRoute
  ) { }

  ngOnInit() {
    this.routerInfo.queryParams.subscribe(params => {
      this.rma_id = params.id;
      if (isPlatformBrowser(this.platformId)) {
        this.rmaService.getExpressNames(this.rma_id).then(result =>{
          if(result.code === 1000){
              this.rmaService.expressNames = result.data;
          }
        }).catch(error => error);
      }
    });
  }

  saveExpress() {
    const re = /^[a-z-A-Z-0-9 ]{6,20}$/;
    if (!re.test(this.express_number)) {
      this.express_number_error = true;
    } else {
      this.express_number_error = false;
    }
    if (!this.express_code.trim() || this.express_code.trim() === '' || this.express_code.trim() === undefined) {
      this.express_name_error = true;
    } else {
      this.express_name_error = false;
    }
    if (this.express_name_error || this.express_number_error) {
      return;
    }

    const params = {'trackingNumber':this.express_number,'expressCode':this.express_code,'refundId':this.rma_id};
    this.rmaService.updateRma(params).then(result => {
      if (result.code * 1 === 1000) {
        this.router.navigate(['/my-account/rma-detail'], {queryParams: {id: this.rma_id}});
      } else {
        alert(result.msg);
      }
    }).catch(error => error);
  }
  selectExpressName(expressCode:any){
    this.express_code = expressCode;
  }

  checkExpress() {
    let flag = true;
    const re = /^[a-z-A-Z-0-9 ]{6,20}$/;
    if (!re.test(this.express_number)) {
      flag = false;
    }
    if (!this.express_code.trim() || this.express_code.trim() === '' || this.express_code.trim() === undefined) {
      flag = false;
    }
    if (!flag) {
      this.canSave = false;
    } else {
      this.canSave = true;
    }
  }
}
