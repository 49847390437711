<h1><img src="assets/images/vm_tool_logo.png" width="80%" alt="EFASHION MERCHTOOL" title="EFASHION MERCHTOOL"/></h1>
<div class="vm-panel-head">你好，{{init.getAdminUsername()}}！<a (click)="logout()" href="javascript:void(0)" class="a-link">退出</a></div>
<div class="vm-panel-body">
  <tabset>
    <tab heading="CMS" *ngIf="true">
      <app-vm-panel-cms></app-vm-panel-cms>
    </tab>
    <tab heading="Merchandising" *ngIf="true">
      <app-vm-panel-merch></app-vm-panel-merch>
    </tab>
    <tab heading="Search" *ngIf="true">
      <app-vm-panel-search></app-vm-panel-search>
    </tab>
  </tabset>
</div>
