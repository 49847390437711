<div class="myAccount_right">
  <div class="topSection clearfix">
    <!--<div class="pc-order" [ngClass]="{'is-open':orderService.showSortFlag}">
      <h3 class="pull-left os-order" [ngClass]="{'is-active':show_pc_order,'a-link':show_miniprogram_order}"><a routerLink="/my-account/order">官网订单</a></h3>
      <h3 class="pull-left mini-program-order" [ngClass]="{'is-active':show_miniprogram_order,'a-link':show_pc_order}"><a routerLink="/my-account/order/mini">小程序订单</a></h3>
    </div>-->
    <!-- mobile官网订单开始-->
    <h3 class="pull-left">
      <div data-component="OProductSort" class="m-order o-product-sort state">
        <!-- mobile官网订单-下拉：is-open -->
        <div class="product-sort-wrapper" (click)="bak($event)" [ngClass]="{'is-open':orderService.showOrderFlag}">
         <!-- <a class="a-link orderlist-channel" [ngClass]="{'is-selected':show_pc_order}" style="font-size: 13px;" routerLink="/my-account/order">官网订单</a>
          <a class="a-link orderlist-channel" [ngClass]="{'is-selected':show_miniprogram_order}"style="font-size: 13px;" routerLink="/my-account/order/mini">小程序订单</a>-->
          <div  class="product-sort-section">
            <h2  class="a-heading-2 product-sort-title">我的订单：</h2>
            <span class="a-icon-close" (click)="orderService.showOrderFlag=false"></span>
            <div  class="product-sort-content">
              <div  class="o-filter">
                <div  class="m-dropdown filter-dropdown is-filled is-open" data-component="MDropdown" data-filter-dropdown="true">
                  <span  class="a-icon-close"></span>
                  <input  class="js-input" name="Sort by" value="best">
                  <span  class="a-icon-arrow-down-small"></span>
                  <ul  class="list-wrapper">
                    <li >
                      <ul class="options list_check" id="">
                        <li  class="a-option" data-tab="tab1">
                          <label  class="radio_btn" for="period01">
                            <span  class="option-name" [ngClass]="{'is-selected':show_pc_order}" (click)="changeOrderTab('pc')">官网订单</span>
                          </label>
                        </li>
                        <li class="a-option" data-tab="tab2">
                          <label class="radio_btn" for="period02">
                            <span  class="option-name" [ngClass]="{'is-selected':show_miniprogram_order}" (click)="changeOrderTab('mini_program')">小程序订单</span></label></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </h3>
    <div class="multi_sort pull-right">
      <div data-component="OProductSort" class="o-product-sort date">
        <!-- 激活状态-手机端下拉：is-open -->
        <div class="product-sort-wrapper" (click)="bak($event)" [ngClass]="{'is-open':orderService.showMonthFlag}">
          <a class="a-link product-sort-link" (click)="orderService.showMonthFlag=true">{{timeCondition}}</a>
          <div class="product-sort-section">
            <h2 class="a-heading-2 product-sort-title">下单时间</h2>
            <span class="a-icon-close" (click)="orderService.showMonthFlag=false"></span>
            <div class="product-sort-content">
              <div class="o-filter">
                <!-- 激活状态-PC下拉：is-open -->
                <div data-component="MDropdown" class="m-dropdown filter-dropdown is-filled" [ngClass]="{'is-open':orderService.showMonthFlag}" data-filter-dropdown="true">
                  <label class="a-label js-a-label placeholder" id="monthSelect"><span>下单时间：</span><span (click)="clickStatus($event, 'month',orderService.showMonthFlag)">{{orderService.sortMonth}}</span></label>
                  <span class="a-icon-close"></span><input name="Sort by" value="best" class="js-input"><span (click)="clickStatus($event, 'month',orderService.showMonthFlag)" class="a-icon-arrow-down-small"></span>
                  <ul class="list-wrapper">
                    <li>
                      <ul class="options list_check" id="list_check_month">
                        <li class="a-option is-selected" data-tab="tab1">
                          <input id="month01" name="period" type="radio">
                          <label class="radio_btn" for="month01" (click)="changeMonthStatus(1)"><span class="option-name" [ngClass]="{'is-selected':orderService.sortMonth == '1个月'}">1个月</span></label>
                        </li>
                        <li class="a-option" data-tab="tab2">
                          <input id="month02" name="period" type="radio">
                          <label class="radio_btn" for="month02" (click)="changeMonthStatus(3)"><span class="option-name" [ngClass]="{'is-selected':orderService.sortMonth == '3个月'}">3个月</span></label>
                        </li>
                        <li class="a-option" data-tab="tab3">
                          <input id="month03" name="period" type="radio">
                          <label class="radio_btn" for="month03" (click)="changeMonthStatus(6)"><span class="option-name" [ngClass]="{'is-selected':orderService.sortMonth == '6个月'}">6个月</span></label>
                        </li>
                        <li class="a-option" data-tab="tab4">
                          <input id="month04" name="period" type="radio">
                          <label class="radio_btn" for="month04" (click)="changeMonthStatus(0)"><span class="option-name" [ngClass]="{'is-selected':orderService.sortMonth == '查看全部'}">查看全部</span></label>
                        </li>
                      </ul>
                      <input type="hidden" title="日期开始日期" name="txtOrdStrtDt" id="txtOrdStrtDt" data-date-format="yyyy.mm.dd" value="2018-06-09">
                      <input type="hidden" title="日期结束日期" name="txtOrdEndDt" id="txtOrdEndDt" data-date-format="yyyy.mm.dd" value="2018-07-09">
                      <button type="button" id="serach" style="display:none"></button>
                      <ul class="bottom-option">
                        <li>取消</li>
                      </ul>
                    </li>
                  </ul>
                  <span class="error-msg"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---------------------------------------------- 状态区分 ------------------------------------------------------>
      <div data-component="OProductSort" class="o-product-sort state">
        <!-- 激活状态-手机端下拉：is-open -->
        <div class="product-sort-wrapper" (click)="bak($event)" [ngClass]="{'is-open':orderService.showSortFlag}">
          <a class="a-link product-sort-link" (click)="orderService.showSortFlag=true" id="m-stateSelect">{{statusCondition}}</a>
          <div class="product-sort-section">
            <h2 class="a-heading-2 product-sort-title">订单状态：</h2>
            <span class="a-icon-close" (click)="orderService.showSortFlag=false"></span>
            <div class="product-sort-content">
              <div class="o-filter">
                <!-- 激活状态：is-open -->
                <div data-component="MDropdown" class="m-dropdown filter-dropdown is-filled" [ngClass]="{'is-open':orderService.showSortFlag}" data-filter-dropdown="true">
                  <label class="a-label js-a-label placeholder" id="stateSelect"><span>订单状态：</span>
                    <span (click)="clickStatus($event, 'status', orderService.showSortFlag)">{{orderService.orderStatus}}</span>
                  </label>
                  <span class="a-icon-close"></span>
                  <input name="Sort by" value="best" class="js-input"><span (click)="clickStatus($event, 'status', orderService.showSortFlag)" class="a-icon-arrow-down-small"></span>
                  <ul class="list-wrapper">
                    <li>
                      <ul class="options list_check" id="list_check_state">
                        <li class="a-option" data-tab="tab1">
                          <input id="period01" name="period" type="radio" class="active">
                          <label class="radio_btn" for="period01"  (click)="changeStatus('查看全部')"><span class="option-name" [ngClass]="{'is-selected' : orderService.orderStatus=='查看全部'}">查看全部</span></label>
                        </li>
                        <li class="a-option" data-tab="tab2">
                          <input id="period02" name="period" type="radio">
                          <label class="radio_btn" for="period02" (click)="changeStatus('待付款')"><span class="option-name"[ngClass]="{'is-selected' : orderService.orderStatus=='待付款'}">待付款</span></label>
                        </li>
                        <li class="a-option" data-tab="tab3">
                          <input id="period03" name="period" type="radio">
                          <label class="radio_btn" for="period03" (click)="changeStatus('待发货')"><span class="option-name"[ngClass]="{'is-selected' : orderService.orderStatus=='待发货'}">待发货</span></label>
                        </li>
                        <li class="a-option" data-tab="tab6">
                          <input id="period06" name="period" type="radio">
                          <label class="radio_btn" for="period06"  (click)="changeStatus('配送中')"><span class="option-name" [ngClass]="{'is-selected' : orderService.orderStatus=='配送中'}">配送中</span></label>
                        </li>
                        <li class="a-option" data-tab="tab7">
                          <input id="period07" name="period" type="radio">
                          <label class="radio_btn" for="period07" (click)="changeStatus('已完成')"><span class="option-name" [ngClass]="{'is-selected' : orderService.orderStatus=='已完成'}">已完成</span></label>
                        </li>
                      </ul>
                      <ul class="bottom-option">
                        <li>取消</li>
                      </ul>
                    </li>
                  </ul>
                  <span class="error-msg"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="orderService.loading else miniOrderBlock" style="text-align: center" class="hidden-lg hidden-md"> 获取中... </div>
  <div *ngIf="orderService.loading else orderBlock" style="text-align: center" class="hidden-xs hidden-sm"> 获取中... </div>
<!-- Mobile Start-->
  <ng-template #miniOrderBlock>
  <div *ngIf="orderListData.total > 0;else elseMiniBlock" class="orderTable_Mob hidden-lg hidden-md">
    <div *ngFor="let val of orderListData.content; let num = index" class="orderItem">
      <div class="topBox font_s_medium font-size-12">
        <span class="ordNumber font_si_xs_medium font-size-12">{{val.tid ? val.tid : val.orderCode}}</span>
        <span class="time font_si_xs_medium font-size-12"style="margin-left:-11px;">{{val.orderTime.substr(0,10)}}</span>
        <span class="state pull-right">{{orderService.orderStateTranslateArr[val.status]}} |
          <a [queryParams]="{orderid:val.orderId}" routerLink="{{show_pc_order ? '/my-account/order/orderdetail' : '/my-account/order/mini/orderdetail'}}" class="a-link">详情</a>
        </span>
      </div>
      <div class="gInfo clearfix" *ngIf="(val.addition !== null && val.addition !== '') && val.addition.shipmentQty > 1">
        <div class="pull-left textInfo">该订单分包裹发送，请注意查收</div>
      </div>
      <div *ngFor="let v of val.items; let n = index" class="gInfo clearfix">
        <div class="pull-left listImg">
          <a *ngIf="v.inValid !== 1" routerLink="/{{orderService.productUrl(v.skuProperties)}}"><img src="{{orderService.analysisProductImage(v.skuImageUrl, v.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
          <a *ngIf="v.inValid == 1"><img src="{{orderService.analysisProductImage(v.skuImageUrl, v.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
        </div>
        <div class="pull-left textInfo">
          <strong style="width: 90%; margin-top: 0;" *ngIf="v.inValid !== 1"><a routerLink="/{{orderService.productUrl(v.skuProperties)}}" class="a-link">{{v.itemName}}</a></strong>
          <strong style="width: 90%; margin-top: 0;" *ngIf="v.inValid == 1"><a class="a-link">{{v.itemName}}</a></strong>
          <p>货号：{{v.skuCode}}</p>
          <p>颜色：{{orderService.analysisProductColor(v.skuProperties).valueName}}</p>
          <p>尺码：{{orderService.sizeConvert(orderService.analysisProductSize(v.skuProperties).valueName)}}</p>
        </div>
        <div class="pull-right total text-right">
          {{init.currency + v.price}}
          <span>{{v.qty}}件</span>
        </div>

        <div *ngIf="val.orderType == 'step'" class="fontColor1"  style="width: 100%;display: flex;padding-left: 80px;">
          <div *ngIf="val.status == 'BUYER_PART_PAYMENT'">
            <div *ngIf="init.balancePaymentStatus(val.presaleJson) == '尾款支付尚未开始'"  style="width: 80%">
              <p>尾款支付尚未开始</p>
              <p>订单尾款支付时间为{{init.getBalanceStartTime(val.presaleJson)}}至{{init.getBalanceEndTime(val.presaleJson)}}</p>
            </div>

            <div *ngIf="init.balancePaymentStatus(val.presaleJson) == '尾款支付已开始'">
              <p>尾款支付已开始</p>
              <p>尾款支付截止时间为{{init.getBalanceEndTime(val.presaleJson)}}</p>
            </div>

            <!--定金支付情况-->
            <div class="amountList">
              <div class="item">
                <div class="sTitle">已付定金：¥&nbsp;{{val.advancePayment}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="val.status == 'WAIT_BUYER_PAY'">
            <p>待支付定金</p>
          </div>
        </div>
      </div>

      <div *ngIf="val.orderType == 'normal';else presalePayTpl">
        <div class="button_section clearfix"  *ngIf="val.status === 'WAIT_BUYER_PAY'">
          <!--routerLink="/checkout/payment/{{val.orderId}}"-->
          <a *ngIf="val.storeId === 10" (click)="gopay(val.orderId)" class="a-button is-primary">付款</a>
          <a (click)="cancelOrder(val.orderId)" class="a-button is-primary cancel">取消订单</a>
        </div>
      </div>


      <ng-template #presalePayTpl>
        <div class="button_section clearfix">
          <!--routerLink="/checkout/payment/{{val.orderId}}"-->
          <a *ngIf="val.status === 'WAIT_BUYER_PAY' && val.storeId === 10" (click)="gopay(val.orderId)" class="a-button is-primary">支付定金</a>
         <a *ngIf="val.status === 'BUYER_PART_PAYMENT' && val.storeId === 10 && init.balancePaymentStatus(val.presaleJson) == '尾款支付已开始'" (click)="gopay(val.orderId)" class="a-button is-primary">支付尾款</a>
        <a *ngIf="val.status === 'WAIT_BUYER_PAY'" (click)="cancelOrder(val.orderId)" href="javascript:void(0)" class="a-button is-primary cancel">取消订单</a>
        </div>
      </ng-template>
      <!--已支付订单2小时内可取消订单-->
      <div class="button_section clearfix" *ngIf="orderService.allowJustRefund(val)">
        <a (click)="applyJustRefund(val)" href="javascript:void(0)" class="a-button is-primary cancel">取消订单</a>
      </div>

    </div>
    <div *ngIf="orderListData.total > 0;" class="pagination">
      <pagination  [(ngModel)]="orderService.currentPage" [totalItems]="orderListData.total" previousText="上一页" nextText="下一页" [rotate]="false" [maxSize]="5" [itemsPerPage]="orderService.pageSize" (pageChanged)="handlePaginationEvent($event)"></pagination>
    </div>
  </div>
  <ng-template #elseMiniBlock>
    <div *ngIf="orderListData.total == 0" class="o-my-orders-empty hidden-lg hidden-md">
      <p class="a-paragraph orders-empty-text">您最近一个月没有订单哦，可以点击"下单时间"查看历史订单<br><a href="/" class="a-link">现在就去购物&nbsp;&gt;</a></p>
    </div>
  </ng-template>
  </ng-template>
  <!-- Mobile End-->
  <ng-template #orderBlock>
    <div  *ngIf="orderListData.total > 0;else elseBlockCloud" class="hidden-sm hidden-xs">
      <table aria-describedby="order" *ngFor="let val of orderListData.content; let num = index" width="100%" border="0" cellspacing="0" cellpadding="0" class="orderTable">
        <tr class="font_s_medium font-size-12">
          <th class="font_si_xs_medium font-size-12" width="21%"><span class="orderNumber"></span>{{val.tid ? val.tid : val.orderCode}}</th>
          <th class="font_si_xs_medium font-size-12" width="16%">{{val.orderTime|date:'yyyy-MM-dd'}} </th>
          <th width="13%">单价</th>
          <th width="9%">数量</th>
          <th width="11%">订单金额</th>
          <th width="11%">支付方式</th>
          <th width="11%">订单状态</th>
          <th width="11%">订单操作</th>
        </tr>
        <tr>
          <td colspan="4">
            <div *ngFor="let v of val.items; let n = index" class="gInfo clearfix">
              <div class="pull-left listImg">
                <a *ngIf="v.inValid !== 1" routerLink="/{{orderService.productUrl(v.skuProperties)}}"><img src="{{orderService.analysisProductImage(v.skuImageUrl, v.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
                <a *ngIf="v.inValid == 1"><img src="{{orderService.analysisProductImage(v.skuImageUrl, v.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
              </div>
              <div class="pull-left textInfo" style="width: 46%">
                <strong style="width: 90%; margin-top: 0;" *ngIf="v.inValid !== 1"><a routerLink="/{{orderService.productUrl(v.skuProperties)}}" class="a-link">{{v.itemName}}</a></strong>
                <strong style="width: 90%; margin-top: 0;" *ngIf="v.inValid == 1"><a class="a-link">{{v.itemName}}</a></strong>
                <p>货号：{{v.skuCode}}</p>
                <p>颜色：{{orderService.analysisProductColor(v.skuProperties).valueName}}</p>
                <p>尺码：{{orderService.sizeConvert(orderService.analysisProductSize(v.skuProperties).valueName)}}</p>
              </div>
              <span class="pull-right quantity">{{v.qty}}</span>
              <span class="pull-right price"><del *ngIf="v.price != v.price">{{init.currency + v.price}}</del>{{init.currency + v.price}}
              </span>

              <div *ngIf="val.orderType == 'step'" class="fontColor1"  style="width: 100%;display: flex;margin-left: 80px;">
                <div *ngIf="val.status == 'BUYER_PART_PAYMENT'">
                  <div *ngIf="init.balancePaymentStatus(val.presaleJson) == '尾款支付尚未开始'">
                    <p>尾款支付尚未开始</p>
                    <p>订单尾款支付时间为{{init.getBalanceStartTime(val.presaleJson)}}至{{init.getBalanceEndTime(val.presaleJson)}}</p>
                  </div>

                  <div *ngIf="init.balancePaymentStatus(val.presaleJson) == '尾款支付已开始'">
                    <p>尾款支付已开始</p>
                    <p>尾款支付截止时间为{{init.getBalanceEndTime(val.presaleJson)}}</p>
                  </div>

                  <!--定金支付情况-->
                  <div class="amountList">
                    <div class="item">
                      <div class="sTitle">已付定金：¥&nbsp;{{val.advancePayment}}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="val.status == 'WAIT_BUYER_PAY'">
                  <p>待支付定金</p>
                </div>
              </div>
            </div>
          </td>
          <td>{{init.currency + val.payment}}</td>
          <td>
            {{orderService.orderPaymentTypeArr[val.payType] }}
           <p class="btnCont" *ngIf="val.status === 'WAIT_BUYER_PAY' && val.storeId === 10">
              <a (click)="changePayment(val.orderId,val.payType)" href="javascript:void(0)" class="a-link">更改</a>
            </p>
          </td>
          <td>
            <p class="text-center">{{orderService.orderStateTranslateArr[val.status]}}</p>
            <p *ngIf="val.status === 'WAIT_BUYER_PAY' && val.storeId === 8">请到小程序支付</p>
          </td>
          <td>
            <p class="text-center">
              <a [queryParams]="{orderid:val.orderId}" routerLink="{{show_pc_order ? '/my-account/order/orderdetail' : '/my-account/order/mini/orderdetail'}}" class="a-link">
                <strong>订单详情</strong>
              </a>
            </p>
            <div class="contBox" *ngIf="val.orderType == 'normal';else presalePayTpl">
              <p class="btnCont" *ngIf="val.status === 'WAIT_BUYER_PAY' && val.storeId === 10"><a routerLink="/checkout/payment/{{val.orderId}}" class="a-button is-primary">付款</a></p>
              <p class="a_cancel text-center" *ngIf="val.status === 'WAIT_BUYER_PAY'"><a (click)="cancelOrder(val.orderId)" href="javascript:void(0)" class="a-link">取消订单</a></p>
              <!--已支付订单2小时内可取消订单-->
              <p class="a_cancel text-center" *ngIf="orderService.allowJustRefund(val)"><a (click)="applyJustRefund(val)" href="javascript:void(0)" class="a-link">取消订单</a>
            </div>

            <ng-template #presalePayTpl>
              <div class="contBox">
              <p style="width: 100%; margin: 0;" class="btnCont" *ngIf="val.status === 'WAIT_BUYER_PAY' && val.storeId === 10"><a style="font-size: 12px;" routerLink="/checkout/payment/{{val.orderId}}" class="a-button is-primary">支付定金</a></p>
              <p class="btnCont" *ngIf="val.status === 'BUYER_PART_PAYMENT' && val.storeId === 10 && init.balancePaymentStatus(val.presaleJson) == '尾款支付已开始'"><a routerLink="/checkout/payment/{{val.orderId}}" class="a-button is-primary">支付尾款</a></p>
              <p class="a_cancel text-center" *ngIf="val.status === 'WAIT_BUYER_PAY'"><a (click)="cancelOrder(val.orderId)" href="javascript:void(0)" class="a-link">取消订单</a></p>
              <!--已支付订单2小时内可取消订单-->
              <p class="a_cancel text-center" *ngIf="orderService.allowJustRefund(val)"><a (click)="applyJustRefund(val)" href="javascript:void(0)" class="a-link">取消订单</a>
              </div>
            </ng-template>
          </td>
        </tr>
        <!--分单发货订单-->
        <tr *ngIf="(val.addition !== null && val.addition !== '') && val.addition.shipmentQty > 1"><td colspan="8"><div class="gInfo pull-left textInfo">该订单分包裹发送，请注意查收</div></td></tr>
      </table>
    </div>
    <div *ngIf="orderListData.total > 0;" class="pagination hidden-sm hidden-xs">
      <pagination  [(ngModel)]="orderService.currentPage" [totalItems]="orderListData.total" previousText="上一页" nextText="下一页" [rotate]="false" [maxSize]="5" [itemsPerPage]="orderService.pageSize" (pageChanged)="handlePaginationEvent($event)"></pagination>
    </div>
    <ng-template #elseBlockCloud>
      <div *ngIf="orderListData.total == 0;" class="o-my-orders-empty hidden-sm hidden-xs" >
        <p class="a-paragraph orders-empty-text">您最近一个月没有订单哦，可以点击"下单时间"查看历史订单<br><a href="/" class="a-link">现在就去购物&nbsp;&gt;</a></p>
      </div>
    </ng-template>
    </ng-template>
</div>
<!-- 弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': orderService.show_modal}" data-component="AOverlay" (click)="orderService.closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.showError || orderService.refundApplySuccess" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix" *ngIf="orderService.showError">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{orderService.message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="orderService.closeOverlay()"></div>
  </div>

  <div class="lightbox-content js-content u-clearfix" *ngIf="orderService.refundApplySuccess">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <p class="a-paragraph refund-mess">{{orderService.message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="refreshOrderList()"></div>
  </div>
</div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.canceled || orderService.applyRefund" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">您确认取消订单吗？</h2>
      <p class="a-paragraph">&nbsp;</p>
      <a *ngIf="orderService.canceled" class="a-button is-primary" (click)="confirmCancel()">确认取消</a>
      <a *ngIf="orderService.applyRefund" class="a-button is-primary" (click)="confirmRefund()">确认取消</a>
      <button class="a-button is-secondary js-close-button" (click)="orderService.closeOverlay()"><span>再考虑下</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>
<!--我的帐户-更改支付方式-h5 -->
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.changePaymentBox" style="text-align: center; display: block">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 取消订单 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">更改支付方式</h2>
      <div data-component="ODelivery" class="o-delivery main-area has-xselected-delivery-option" data-component-id="change_payment">
        <div id="checkoutDeliveryPartForm" class="o-form ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength ng-dirty">
          <div class="m-error js-m-error is-hidden"><label class="a-label js-a-label has-error"></label></div>
          <div data-component="MRadioButtonSelector" class="m-radio-button-selector">
            <div class="m-radio-button u-clearfix u-float-left">
              <input [checked]="orderService.payType == 'alipay' || orderService.payType == 'wosai:alipay:wap'" type="radio" id="deliveryMode-0" class="a-radio-button" name="delivery-selector2" (click)="setPaymentMethod('wosai:alipay:wap')">
              <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-0"></label>
              <label class="a-label js-a-label label-text" for="deliveryMode-0">
                <p class="a-paragraph option ng-bindin icoBg i_alipay">支付宝</p>
              </label>
            </div>
            <div class="m-radio-button u-clearfix u-float-left">
              <input [checked]="orderService.payType == 'weixin' || orderService.payType == 'wosai:weixin:wap'" type="radio" id="deliveryMode-1"  class="a-radio-button" name="delivery-selector2" (click)="setPaymentMethod('wosai:weixin:wap')">
              <label class="a-label js-a-label u-no-select custom-radio" for="deliveryMode-1"></label>
              <label class="a-label js-a-label label-text" for="deliveryMode-1">
                <p class="a-paragraph option ng-binding icoBg i_weixin">微信</p>
              </label>
            </div>
          </div>
        </div>
      </div>
      <p class="a-paragraph">&nbsp;</p>
      <a class="a-button is-primary" (click)="modifyPaymentMethod()">确认</a>
      <button class="a-button is-secondary js-close-button" (click)="orderService.closeOverlay()"><span>取消</span></button>
    </div>
    <!-- 取消订单 结束 -->
  </div>
</div>
