<div class="refresh" *ngIf="editMode">
  <span class="glyphicon glyphicon-refresh" (click)="refresh()"></span>
</div>
<!--HTML Tab Start-->
<div class="tabCategoryChangeContainer" id="tabCategoryChangeContainer{{containerIndex}}_{{blockIndex}}">
  <ng-container *ngIf="block.data.config.tabs_html !== '' && block.data.config.tabs_html !== undefined">
    <div  [innerHTML]="block.data.config.tabs_html | html"></div>

    <ng-container *ngIf="block.data.tabs[currentIndex]['is_customize'] == 1 && block.data.tabs[currentIndex]['html_content'] !== '' && block.data.tabs[currentIndex]['html_content'] !== undefined">
      <div  [innerHTML]="block.data.tabs[currentIndex]['html_content'] | html"></div>
    </ng-container>

    <ng-container *ngIf="block.data.tabs[currentIndex]['is_customize'] == 0">
      <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row">
        <div class="o-category-listing">
          <div>
            <ng-container *ngIf="block.data.tabs[currentIndex].rows == 'fourLines' && isPc; else generalClass">
              <div class="column category-list-product-block fourCols" *ngFor="let product of productModel" style="width: 25% !important;">
                <div class="a-link no-styling">
                  <app-product-list  [productModel]="product"  [categoryModel]="{'isSpecial': block.data.tabs[currentIndex].showMajorUrl}"></app-product-list>
                </div>
              </div>
            </ng-container>
            <ng-template #generalClass>
              <div class="column col-span-12 lg:col-span-3 category-list-product-block" *ngFor="let product of productModel">
                <div class="a-link no-styling">
                  <app-product-list [productModel]="product"  [categoryModel]="{'isSpecial': block.data.tabs[currentIndex].showMajorUrl}"></app-product-list>
                </div>
              </div>
            </ng-template>
            <p *ngIf="categoryService.loading" style="text-align: center">加载中……</p>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<!--HTML Tab End-->
