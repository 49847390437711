import {RequestService} from './request.service';
import {InitializeService} from './initialize.service';
import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class VmService {
    category!: string;
    categoryModel!: {};
    productList: any =  {};
    productListImages: any = {};
    productModel: any;
    validate = false;
    vmMerchProductList: any;
    vmMerchImages: any;
    flag!: 0;

    constructor(private init: InitializeService,
                private storage: StorageService,
                private request: RequestService) {
    }

    saveMerchCategory(category_id:any) {
        this.storage.setItem('merch_category', category_id );
    }

    saveMerchProducts(product_ids:any) {
        this.storage.setItem('merch_products', product_ids);
    }
    saveBannerList(banner_list:any) {
      this.storage.setItem('category_banner_list', banner_list);
    }

    findMerchCategory() {
        return this.storage.getItem('merch_category');
    }

    saveMerchCategoryProducts(category_id:any, products_id:any) {
        this.storage.setItem('merch_category_' + category_id, products_id);
    }

    resetMerchCategoryProducts(category_id:any) {
        this.storage.setItem('merch_products', this.storage.getItem('merch_category_' + category_id ));
    }

    setProductImagesList( entity:any , type:any ) {
        if ( !this.productListImages[ entity['id'] ] ) {
            this.productListImages[ entity['id'] ] = {};
        }
        this.productListImages[ entity['id'] ][ type ] = entity[type];
    }

    getProductImagesList() {
        return this.productListImages;
    }

    mergeProductList() {
        for ( const i in this.productListImages ) {
            for ( const j in this.productList ) {
                if ( this.productList[j]['id'] === i ) {
                    if ( this.productListImages[i]['img_0'] ) {
                        this.productList[j]['img_0'] = this.productListImages[i]['img_0'] + '$200x200';
                    }

                    if ( this.productListImages[i]['img_1'] ) {
                        this.productList[j]['img_1'] = this.productListImages[i]['img_1'] + '$200x200';
                    }
                }
            }
        }
    }

    updateMerchToolsBannerRanking() {
        // let images = '';
        // if ( this.productListImages ) { images = JSON.stringify( this.productListImages ); }
        const category_id = this.category;
        // const product_ids = this.storage.getItem('merch_products');
        const banner_list = this.storage.getItem('category_banner_list');
        const username = this.storage.getItem('admin');
        // const remove_ids = this.storage.getItem('vmRemoveProducts');
        const param = new HttpParams()
            .set('category_id', category_id)
            .set('product_ids', '')
            .set('banner_list', banner_list)
            .set('username', username)
            .set('store_id', this.init.storeId)
            // .set('remove_ids', remove_ids)
            // .set('products_images', '' );
        this.storage.setItem('vmRemoveProducts', '');
        return new Promise((resolve) => {
            this.request.post('/catalog-service-standard/product/updateProductRanking', param, false, false ).toPromise().then(
                res => {
                  this.storage.removeItem('category_banner_list');
                  this.productListImages = {};
                    resolve(res);
                }
            ).catch(error => error);
        });
    }

  updateMerchToolsRanking () {
      let images = '';
      if ( this.productListImages ) { images = JSON.stringify( this.productListImages ); }
      const category_id = this.category;
      const product_ids = this.storage.getItem('merch_products');
      const banner_list = this.storage.getItem('category_banner_list');
      const username = this.storage.getItem('admin');
      const remove_ids = this.storage.getItem('vmRemoveProducts');
      const param = new HttpParams()
        .set('category_id', category_id)
        .set('product_ids', product_ids)
        .set('banner_list', banner_list)
        .set('username', username)
        .set('store_id', this.init.storeId)
        .set('remove_ids', remove_ids)
        .set('products_images', images );
      this.storage.setItem('vmRemoveProducts', '');
      return new Promise((resolve) => {
        this.request.post('/catalog-service-standard/product/updateProductRanking', param, false, false ).toPromise().then(
          res => {
            this.storage.removeItem('category_banner_list');
            this.productListImages = {};
            resolve(res);
          }
        ).catch(error => error);
      });
    }

    adminLogin( userName:any, password:any ) {
        return new Promise((resolve) => {
			var userParams:any = {'username': userName, 'password': password};
            this.request.post('/admin-auth-standard/ldap',userParams, false,false, true ).toPromise().then(
                res => {
                    resolve(res);
                }
            ).catch(error => error);
        });
    }

    searchProducts( keyword:any ) {
        return new Promise((resolve) => {
            this.request.get('/search-service-standard/catalog/product?keyword=' + keyword + '&store_id=' + this.init.storeId).toPromise().then(
                res => {
                    resolve(res);
                }
            ).catch(error => error);
        });
    }

    vmAddClick() {
		var vmProductModelArrListCache:any = localStorage.getItem('vmProductModelArrList');
        const productModelArrList = JSON.parse(vmProductModelArrListCache);
        this.vmMerchProductList = productModelArrList;
    }
}
