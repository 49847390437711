<!--pc-->
<div class="box-pc">
<div class="myAccount_right">
    <div class="hidden-sm hidden-xs">
      <table *ngFor="let val of orderLogistics; let num = index" width="100%" border="0" cellspacing="0" cellpadding="0" class="orderTable" aria-describedby="">
        <tr class="font_s_medium font-size-12">
          <th width="38%" scope="col"><span class="orderNumber">快递公司：</span>{{val.expressName}}</th>
          <th width="16%" scope="col">单价</th>
          <th width="11%" scope="col">数量</th>
          <th width="16%" scope="col">发货单号</th>
        </tr>
        <tr>
          <td colspan="3">
            <div *ngFor="let v of val.shipItems; let n = index" class="gInfo clearfix">
              <div class="pull-left listImg">
                <a *ngIf="v.inValid !== 1" routerLink="/{{orderService.productUrl(v.skuProperties)}}"><img src="{{orderService.analysisProductImage(v.skuImageUrl, v.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
                <a *ngIf="v.inValid == 1"><img src="{{orderService.analysisProductImage(v.skuImageUrl, v.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
              </div>
              <div class="pull-left textInfo" style="width: 45%">
                <strong *ngIf="v.inValid !== 1"><a routerLink="/{{orderService.productUrl(v.skuProperties)}}" class="a-link">{{v.itemName}}</a></strong>
                <strong *ngIf="v.inValid == 1"><a class="a-link">{{v.itemName}}</a></strong>
                <p>货号：{{v.skuCode}}</p>
                <p>颜色：{{orderService.analysisProductColor(v.skuProperties).valueName}}</p>
                <p>尺码：{{orderService.sizeConvert(orderService.analysisProductSize(v.skuProperties).valueName)}}</p>
              </div>
              <span class="pull-right quantity">{{v.qty}}</span>
              <span class="pull-right price"><del *ngIf="v.price != v.price">{{init.currency + v.price}}</del>{{init.currency + v.price}}
              </span>
            </div>
          </td>
          <td>
            <p class="text-center">
                <strong>{{val.trackingNumber}}</strong>
            </p>
            <div class="contBox">
              <p class="btnCont"><a class="a-button is-primary" (click)="showLogistics(val)">查看物流信息</a></p>
            </div>
          </td>
        </tr>
      </table>
    </div>

</div>
<!-- 弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': orderService.show_modal}" data-component="AOverlay" (click)="orderService.closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="orderService.showLogistics" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <div class="expressInfo">
        <div class="expHead" *ngIf="logisticsInfo.tracks.length > 0">
          <span class="name">快递公司：{{expressName}}</span><span>发货单号：{{trackingNumber}}</span>
        </div>
        <div class="expContent">
          <div class="expList" *ngIf="logisticsInfo.tracks.length > 0; else noneBlock">
            <ul>
              <li *ngFor="let logistics of logisticsInfo.tracks">
                <span>{{logistics.time}}</span>
                <p>{{logistics.context}}</p>
              </li>
            </ul>
          </div>
          <ng-template #noneBlock >
            <div class="expList">
              <ul>
                <li>
                  <p>暂无物流信息</p>
                </li>
              </ul>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="orderService.closeOverlay()"></div>
  </div>
</div>
</div>

<!--手机端-->
<div class="box-h5">
  <div class="box1"  *ngFor="let val of orderLogistics; let num = index">
    <div class="box-text">
      <div class="text-1">
        <p>快递公司：{{val.expressName}}</p>
        <p>发货单号：{{val.trackingNumber}}</p>
      </div>
      <div class="text-2">
        <p class="p1" (click)="showLogistics(val,num)">查看物流信息 {{activeId === num ?'-':'+'}}</p>
        <!--<p class="p2" (click)="copyContentH5(val.trackingNumber)">长按复制</p>-->
      </div>
    </div>

    <div id="{{val.trackingNumber}}-content" style="display: none;" >
      <div class="expressInfo">
        <div class="expContent">
          <div class="expList" *ngIf="logisticsInfo.tracks.length > 0; else noneBlock">
            <ul>
              <li *ngFor="let logistics of logisticsInfo.tracks">
                <span>{{logistics.time}}</span>
                <p>{{logistics.context}}</p>
              </li>
            </ul>
          </div>
          <ng-template #noneBlock >
            <div class="expList">
              <ul>
                <li>
                  <p>暂无物流信息</p>
                </li>
              </ul>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="box-i" *ngFor="let item of val.shipItems; let n = index">
      <div class="img">
        <img src="{{orderService.analysisProductImage(item.skuImageUrl, item.skuProperties)}}{{init.imageSize.$4}}" width="100%" alt=""/>
      </div>
      <div class="p-all" style="width: 44%;">
        <strong class="p-b">{{item.itemName}}</strong>
        <p class="p-1">货号：{{item.skuCode}}</p>
        <p class="p-2">颜色：{{orderService.analysisProductColor(item.skuProperties).valueName}}</p>
        <p class="p-2">尺码：{{orderService.sizeConvert(orderService.analysisProductSize(item.skuProperties).valueName)}}</p>
      </div>
      <div class="text-right" style="width:auto;float:right;margin-right:10px;">
        <p style="white-space: nowrap;"><del *ngIf="item.price != item.price">{{init.currency + item.price}}</del>{{init.currency + item.price}}</p>
        <p>{{item.qty}}件</p>
      </div>
    </div>
  </div>
</div>

