<div class="refresh" *ngIf="editMode">
  <span class="glyphicon glyphicon-refresh" (click)="refresh()"></span>
</div>

<!--3rows-->
<div *ngIf="block.data.config.rows == 'threeLines'" data-component="ProductContainer" class="o-category-listing">
  <div id='products' [dragula]='"nested-bag"'>
    <div *ngFor="let product of productModel" class="column">
      <div>
        <!-- 完整 -->
        <div class="o-product">
          <div class="image-holder">
            <ng-container *ngIf="block.data.config.showNoStock=='1';else other_content">
              <a routerLink="/{{product?.frontUrlPath}}" [queryParams]="{arrivalRemind: 1}">
                <img alt="COS" class="a-image default-image ng-star-inserted" src="{{product?.majorUrl}}">
              </a>
            </ng-container>
            <ng-template #other_content>
              <a routerLink="/{{product?.frontUrlPath}}">
                <img alt="COS" class="a-image default-image ng-star-inserted" src="{{product?.majorUrl}}" alt="图片">
              </a>
            </ng-template>
          </div>
          <div class="description">
            <ng-container *ngIf="block.data.config.showNoStock=='1';else other_content1">
              <a routerLink="/{{product?.frontUrlPath}}" [queryParams]="{arrivalRemind: 1}">
                <label class="a-label js-a-label product-title nameBox">{{product?.itemName}}</label>
                <div class="m-product-price priceBox">{{init.currency}}{{product?.price}}</div>
              </a>
            </ng-container>
            <ng-template #other_content1>
              <a routerLink="/{{product?.frontUrlPath}}">
                <label class="a-label js-a-label product-title nameBox">{{product?.itemName}}</label>
                <div class="m-product-price priceBox">{{init.currency}}{{product?.price}}</div>
              </a>
            </ng-template>
           <div class="m-swatches sm">
              <div class="picked-color"></div>
              <div class="a-swatch js-swatch" *ngFor="let block of product?.frontProperties">
                <label class="a-label js-a-label">
                  <input type="radio" name="colors" value="Black"/>
                  <span>
                        <div class="a-swatch-foreground"></div>
                        <img alt="COS" class="a-image" src="{{block?.skuImageUrl}}"/>
                        <p class="sold-out">Sold out</p>
                      </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- //完整 -->
      </div>
    </div>
  </div>
  <ng-template #noProductBlock>
    <div>无相关商品</div>
  </ng-template>
</div>

<div *ngIf="block.data.config.rows == 'fourLines'" class="fourcolumns parbase section cq-Editable-dom">
  <div class="u-cols-sm-12-12 u-cols-md-12-12 u-cols-lg-24-24 u-row u-full-width">
    <div class="o-grid-controller is-four-cols" style="background-color: #ffffff">
      <div class="content">
        <div class="scrollable-content">
          <div *ngFor="let product of productModel" class="column">
            <div>
              <div class="freetile parbase section cq-Editable-dom">
                <div class="m-free-tile four-cols" style="background-color: #ffffff">
                  <ng-container *ngIf="block.data.config.showNoStock=='1';else other_content2">
                    <a routerLink="/{{product?.frontUrlPath}}" [queryParams]="{arrivalRemind: 1}"  target="_self">
                      <img class="a-image" src="{{product?.majorUrl}}" alt="图片">
                    </a>
                  </ng-container>
                  <ng-template #other_content2>
                    <a routerLink="/{{product?.frontUrlPath}}"  target="_self">
                      <img class="a-image" src="{{product?.majorUrl}}" alt="图片">
                    </a>
                  </ng-template>
                </div>
                <div class="items">
                  <a href="https://author.cos.appeaser.hm.com/content/cos/ch/men.html#" target="_self"
                     class="a-link open-lightbox" data-template="newsletter-signup" data-classes="is-subscribe">
                  </a>
                </div>
              </div>
              <div class="freetile parbase section cq-Editable-dom">
                <div class="m-free-tile four-cols" style="background-color: #ffffff">
                  <div class="cta-wrapper">
                    <ng-container *ngIf="block.data.config.showNoStock=='1';else other_content3">
                      <a routerLink="/{{product?.frontUrlPath}}" [queryParams]="{arrivalRemind: 1}" target="_self"
                         class="a-link cta-link" style="color: rgb(68, 68, 68); text-decoration: none;">
                        <label class="a-label js-a-label product-title nameBox">{{product?.itemName}}</label>
                        <div class="m-product-price priceBox">{{init.currency}}{{product?.price}}</div>
                      </a>
                    </ng-container>
                    <ng-template #other_content3>
                      <a routerLink="/{{product?.frontUrlPath}}"target="_self"
                         class="a-link cta-link" style="color: rgb(68, 68, 68); text-decoration: none;">
                        <label class="a-label js-a-label product-title nameBox">{{product?.itemName}}</label>
                        <div class="m-product-price priceBox">{{init.currency}}{{product?.price}}</div>
                      </a>
                    </ng-template>
                    <div class="m-swatches sm">
                      <div class="picked-color"></div>
                      <div class="a-swatch js-swatch" *ngFor="let block of product?.frontProperties">
                        <label class="a-label js-a-label">
                          <input type="radio" name="colors" value="Black"/>
                          <span>
                        <div class="a-swatch-foreground"></div>
                        <img class="a-image" src="{{block?.skuImageUrl}}" alt="图片"/>
                        <p class="sold-out">Sold out</p>
                      </span>
                        </label>
                      </div>
                    </div>
                </div>
                <div class="items">
                  <a href="https://author.cos.appeaser.hm.com/content/cos/ch/men.html#" target="_self"
                     class="a-link open-lightbox" data-template="newsletter-signup" data-classes="is-subscribe">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
