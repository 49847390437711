import {Component, Input, OnInit} from '@angular/core';
import {CmsService} from '../../services/cms.service';
import {Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-vm-panel',
  templateUrl: './vm-panel.component.html',
  styleUrls: ['./vm-panel.component.css']
})
export class VmPanelComponent implements OnInit {
  @Input()
  languageId: any = 'chinese';
  constructor(private localStorage: StorageService,
              public init: InitializeService,
              private router: Router,
              public cmsService: CmsService) {
  }

  ngOnInit() {
  }

  /**
   * Create new page
   * @return {Promise<boolean>}
   */
  newPage(): Promise<boolean> {
    return this.router.navigate(['/' + this.cmsService.advancedPagePathPrefix + 'new']);
  }

  logout() {
    this.localStorage.removeItem('vmLogin');
    this.localStorage.removeItem('adminJwt');
    this.cmsService.sharedData.editMode = false;
  }

  selectLanguage() {
  }
}
