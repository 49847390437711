import {Component, Inject, OnInit, Input, ElementRef, OnDestroy, PLATFORM_ID} from '@angular/core';
import {AddressService} from '../../../services/address.service';
import {RequestService} from '../../../services/request.service';
import {StorageService} from '../../../services/storage.service';
import {CheckoutService} from '../../../services/checkout.service';
import {InitializeService} from '../../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import * as $ from 'jquery';
const apiConfig = require('../../../config/config.api.json');

@Component({
  selector: 'app-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.css']
})
export class CheckoutPaymentComponent implements OnInit , OnDestroy {
  public paymethod: any = {
    code: 200,
    message: '',
    data: {
      list_payment_method: {},
      default_payment_method: ''
    }
  };
  public timer = null;
  @Input() weChat: boolean | undefined;
  @Input() isAlipay: boolean | undefined;
  @Input() checkoutType: any;
  public weChatBrowser: boolean | undefined;
  public aliPayBrowser: boolean | undefined;
  public mobileBrowser: boolean | undefined;
  constructor(public address: AddressService,
              private request: RequestService,
              public init: InitializeService,
              private el: ElementRef,
              private localStorage: StorageService,
              @Inject(PLATFORM_ID) private platformId: Object,
              public checkout: CheckoutService) {
  }

  ngOnInit() { 
		const u = navigator.userAgent, app = navigator.appVersion;
		const ua = navigator.userAgent.toLowerCase();
		this.weChatBrowser = (/MicroMessenger/i.test(ua));
		this.aliPayBrowser = (/AlipayClient/i.test(ua));
		this.mobileBrowser = (!!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/) && u.indexOf('QIHU') && u.indexOf('Chrome') < 0) as boolean | undefined;
    if (this.init.isLogin()) {
      this.address.has_payment = true;
    }
  }
	public setDefaultPaymentMethod(pay : any) {
		if((this.weChatBrowser || this.mobileBrowser) && !this.aliPayBrowser){
			let paymethod = pay == 'wosai:weixin:wap' ? 'wosai:litepos:purchase' : pay;
			this.checkout.setCloudPaymentMethod(paymethod,this.checkoutType).then();
		}else{
			this.checkout.setCloudPaymentMethod(pay,this.checkoutType).then();
		}
	} 

  ngOnDestroy() {
    this.timer = null;
  }
}

