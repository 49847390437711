<div class="o-page-content cofs-page" style="margin-top:0; padding: 0;">
  <div class="rma_list hidden-xs">
    <h4 class="font_xs_regular" style="margin-top: 0;">退货</h4>
    <table *ngIf="canApplyRma; else noRmaCanApply;" width="100%" border="0" cellspacing="0" cellpadding="0" class="tableStyle_1">
      <tr class="font_s_medium font-size-12">
        <th colspan="2">商品信息</th>
        <th width="20%">退货原因</th>
        <th width="11%">可退数量</th>
        <th width="11%">申请数量</th>
        <th width="11%" style="display: none;">需换颜色</th>
        <th width="11%" style="display: none;">需换尺码</th>
      </tr>
      <tr *ngFor="let item of rmaOrder?.items; let num = index;">
        <td width="4%">
          <input class="a-checkbox hidden" id="ram_check_{{item.orderItemId}}"  type="checkbox" (click)="checkedItem(item.orderItemId,item.itemId)"  [disabled]="item.qty - item.refundQty <1">
          <label class="a-label js-a-label custom-box" for="ram_check_{{item.orderItemId}}"></label>
        </td>
        <td width="24%">
          <div class="gInfo clearfix">
            <div class="u-float-left ram_listImg">
              <a routerLink="/{{rmaService.productUrl(item.skuProperties)}}"><img src="{{orderService.analysisProductImage(item.skuImageUrl, item.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
            </div>
            <div class="u-float-left" style="margin-left: 15px; width: 65%;">
              <strong><a routerLink="/{{rmaService.productUrl(item.skuProperties)}}" class="a-link">{{item.itemName}}</a></strong>
              <p>尺码：{{rmaService.sizeConvert(rmaService.analysisProductSize(item.skuProperties).valueName)}}</p>
              <p>颜色：{{rmaService.analysisProductColor(item.skuProperties).valueName}}</p>
              <p>数量：{{item.qty}}</p>
            </div>
          </div>
        </td>
        <td class="text-center">
          <select [ngClass]="{'notice':item.reason_error}" name="ste2" id="ste2" *ngIf="checkedItems.indexOf(item.orderItemId)>-1" (change)="selectReason(item.orderItemId, $event.target.value)">
            <option value="">请选择</option>
            <option *ngFor="let reason of refundReason" value="{{reason.reason}}">{{reason.reason}}</option>
          </select>
        </td>
        <td class="text-center"><span *ngIf="checkedItems.indexOf(item.orderItemId)>-1">{{item.qty - item.refundQty}}</span></td>
        <td class="text-center">
          <input type="text" *ngIf="checkedItems.indexOf(item.orderItemId)>-1"   name="cad1" id="cad1" value="1" class="a-input" [ngClass]="{'notice':item.qty_error}" (change)="qtyChange(item.orderItemId, $event.target.value)" />
        </td>
      </tr>
    </table>
    <ng-template #noRmaCanApply>
      <div class="bottom-section">
        {{noCanApplyRmaReason}}
      </div>
    </ng-template>
    <div class="bottom-section" *ngIf="canApplyRma">
      <button class="a-button is-primary" [disabled]="rma.length<1"  (click)="onSubmit()">提交</button>
    </div>
  </div>
  <div class="rma_listMob hidden-lg hidden-md hidden-sm">
    <h4>退货</h4>
    <div *ngIf="canApplyRma; else noRmaCanApply;">
      <div *ngFor="let item of rmaOrder?.items">
        <div class="gInfo clearfix">
          <div class="u-float-left ram_listImg">
            <a routerLink="/{{rmaService.productUrl(item.skuProperties)}}"><img src="{{orderService.analysisProductImage(item.skuImageUrl, item.skuProperties)}}{{init.imageSize.$4}}" alt="图片"></a>
          </div>
          <div class="u-float-left">
            <strong><a routerLink="/{{rmaService.productUrl(item.skuProperties)}}" class="a-link">{{item.itemName}}</a></strong>
            <p>尺码：{{rmaService.sizeConvert(rmaService.analysisProductSize(item.skuProperties).valueName)}}</p>
            <p>颜色：{{rmaService.analysisProductColor(item.skuProperties).valueName}}</p>
            <p>数量：{{item.qty}}</p>
          </div>
          <button class="a-button is-primary apply-btn" *ngIf="checkedItems.indexOf(item.orderItemId)>-1;else cancleBlock" (click)="checkedItem(item.orderItemId,item.itemId)">取消</button>
          <ng-template #cancleBlock>
            <button class="a-button is-primary apply-btn" (click)="checkedItem(item.orderItemId,item.itemId)" [disabled]="item.qty - item.refundQty <1">申请退货</button>
          </ng-template>
        </div>
        <div class="listMob" *ngIf="checkedItems.indexOf(item.orderItemId)>-1">
          <label for="steMob_2">退货原因：</label>
          <select [ngClass]="{'notice':item.reason_error}" name="ste" id="steMob_2" (change)="selectReason(item.orderItemId, $event.target.value)">
            <option value="">请选择</option>
            <option *ngFor="let reason of refundReason" value="{{reason.reason}}">{{reason.reason}}</option>
          </select>
        </div>
        <div class="listMob" *ngIf="checkedItems.indexOf(item.orderItemId)>-1">
          <label for="steMob_3">申请数量：</label>
          <input type="number" *ngIf="checkedItems.indexOf(item.orderItemId)>-1" max="{{item.qty_to_return}}" min="1" id="refundQty_{{item.orderItemId}}" value="1" class="a-input text-center" [ngClass]="{'notice':item.qty_error}" (change)="qtyChange(item.orderItemId, $event.target.value)" />
        </div>
        <div class="listMob" *ngIf="checkedItems.indexOf(item.orderItemId)>-1 && item.is_exchange==true" style="display: none;">
          <label for="steMob_4">需换颜色：</label>
          <select name="ste" id="steMob_4" (change)="colorChange(item.orderItemId,$event.target.value)" [ngClass]="{'notice':item.color_id_error}">
            <option value="">请选择</option>
            <option *ngFor="let color of item.product?.select_list" value="{{color.id}}">{{color.label}}</option>
          </select>
        </div>
        <div class="listMob" *ngIf="checkedItems.indexOf(item.orderItemId)>-1 && item.is_exchange==true" style="display: none;">
          <label for="steMob_5">需换尺码：</label>
          <select name="ste" id="steMob_5" (change)="sizeChange(item.orderItemId, item.product_id, $event.target.value)" [ngClass]="{'notice':item.product_id_error}">
            <option value="">请选择</option>
            <option *ngFor="let size of item.product_list" value="{{size.product_id}}">{{size.size_value}}</option>
          </select>
        </div>
      </div>
    </div>
    <ng-template #noRmaCanApply>
      <div class="o-my-orders-empty">
        <p class="a-paragraph orders-empty-text">{{noCanApplyRmaReason}}</p>
      </div>
    </ng-template>
    <div class="bottom-section" *ngIf="canApplyRma">
      <button class="a-button is-primary" [disabled]="rma.length<1" (click)="onSubmit()">提交</button>
    </div>
  </div>
</div>

<!-- 弹框：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_modal}" data-component="AOverlay" (click)="closeOverlay()"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_modal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 弹框 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2">抱歉！</h2>
      <p class="a-paragraph">{{message}}</p>
    </div>
    <!-- 弹框 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>
