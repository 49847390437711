import {Component, Input, OnInit} from '@angular/core';
import {ProductService} from '../../../../services/product.service';

@Component({
  selector: 'app-cms-block-single-product',
  template: `
    <div class="refresh" *ngIf="editMode">
      <span class="glyphicon glyphicon-refresh" (click)="refresh()"></span>
    </div>
    <div class="thumbnail" *ngIf="productModel">
      <div *ngIf="!block.data.url">
        <a [routerLink]="['/product/' + block.data.product_id + '.html']">
          <img src="{{productModel?.imageList[0].productImageUrl}}" alt="...">
        </a>
        <div class="caption">
          <h3><a [routerLink]="['/product/' + block.data.product_id + '.html']">{{productModel?.productName}}</a></h3>
          <p>{{productModel?.getSalesPrice()}}</p>
        </div>
      </div>
      <div *ngIf="block.data.url">
        <div *ngIf="block.data.isInternalUrl">
          <a [routerLink]="[block.data.url]">
            <img src="{{productModel?.imageList[0].productImageUrl}}" alt="...">
          </a>
          <div class="caption">
            <h3><a [routerLink]="[block.data.url]">{{productModel?.productName}}</a></h3>
            <p>{{productModel?.getSalesPrice()}}</p>
          </div>
        </div>
        <div *ngIf="!block.data.isInternalUrl">
          <a href="{{block.data.url}}">
            <img src="{{productModel?.imageList[0].productImageUrl}}" alt="...">
          </a>
          <div class="caption">
            <h3><a target="_blank" href="{{block.data.url}}">{{productModel?.productName}}</a></h3>
            <p>{{productModel?.getSalesPrice()}}</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./cms-block-single-product.component.css']
})
export class CmsBlockSingleProductComponent implements OnInit {

  @Input() block:any;
  @Input()
	editMode!: boolean;

  public productModel: any;

  constructor(public productService: ProductService) {
  }

  ngOnInit() {
    if (this.block.data.hasOwnProperty('product_id')) {
      this.getProduct(this.block.data.product_id);
    }
  }

  refresh() {
    this.getProduct(this.block.data.product_id);
  }

  getProduct(product_id: number) {
    this.productService.getProduct(product_id).then(data => {
      this.productModel = data;
    }).catch(error => error);
  }
}
