import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchService} from '../../services/search.service';
import {InitializeService} from '../../services/initialize.service';

import {ProductService} from '../../services/product.service';
import {isPlatformBrowser} from '@angular/common';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ])
    ])
  ],
  selector: 'app-category-list',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy {
  public keyword:any;
  public productModelArr:any = [];
  public productIdTmp:any = [];
  public currency:any;
  public showHoverProductId:any;
  public isPcFlag:any;
  public showH5Select:any = false;
  // select显示隐藏
  selectLabel:any = {label1: false, label2: false, label3: false};
  public selectCategoryText!: string;
  // 子select是否可选择
  public label1Selected:any = false;
  public label2Selected:any = false;
  public label3Selected:any = false;
  // selectText 文字
  public label1Text!: string;
  public label2Text!: string;
  public label3Text!: string;
  public offset = -100;
  
  public favoriteType:any;
  public favoriteCode:any;
  public showFavoriteBox:boolean = false;

  constructor(
    private routerInfo: ActivatedRoute,
    public searchService: SearchService,
    public init: InitializeService,
    private router: Router,
    public productService: ProductService,
		
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit() {
    // 监听浏览器后退事件
    this.currency = this.init.currency;
    this.routerInfo.queryParams.subscribe(params => {
      this.keyword = params.keyword;
      // 初始化数据
      this.initFilter();
      // this.searchService.getCategoryTree(this.keyword);
      if (this.keyword && this.keyword != '') {
        this.searchService.postSearchTerm(this.keyword).then();
      }
      this.searchService.cloudSearchPage(this.keyword, false, true);
    });
    if (isPlatformBrowser(this.platformId)) {
      if (this.isPC()) {
        this.isPcFlag = true;
      } else {
        this.isPcFlag = false;
      }
    }
	//自定义在线客服icon位置样式
	$('body').addClass('plp_udesk');
  }

  /**
   * Handle event from pagination component.
   * @param event
   */
  handlePaginationEvent(event:any, type = null) {
    if (type === 'customize') {
      this.searchService.currentPage = event;
    } else {
      this.searchService.currentPage = event.page;
    }
    this.searchService.cloudSearchPage(this.keyword);
    if (isPlatformBrowser(this.platformId)) {
      $('html,body').animate({scrollTop: 0}, 1000);
    }
  }

  renderPlacehold(productId:any) {
    for (let i = 0; i < this.productIdTmp.length; i++) {
      if (this.productIdTmp[i] === productId) {
        return;
      }
    }
    this.productIdTmp.push(productId);
    this.productService.getProduct(productId).then(val => {
      this.productModelArr[productId] = val;
    }).catch(error => error);
  }
  toProductDetail(value:any) {
    const pdpUrl = '/' + value.url_path;
    this.router.navigate([pdpUrl]);
  }

  /**
   * 客户端判断 PC or Mobile
   * @returns {boolean}
   * @constructor
   */
  isPC(): boolean {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  imageMouseover(showHoverProductId:any) {
    this.showHoverProductId = showHoverProductId;
  }

  imageMouseout() {
    this.showHoverProductId = '';
  }

  // 快捷筛选的开关
  switchH5Select(sure = false) {
    if (sure) {
      this.showH5Select = false;
    } else {
      this.showH5Select = true;
    }
  }

  /**
   * 切换各个下拉列表显示隐藏
   * @param labNumber
   * @param e
   */
  changeSelect(labNumber: string, e:any) {
     if (labNumber === 'label1') {
       if (this.selectLabel.label1) {
         this.selectLabel.label1 = false;
       } else {
         this.selectLabel.label1 = true;
       }
       this.selectLabel.label2 = false;
       this.selectLabel.label3 = false;
     } else if (labNumber === 'label2') {
       if (this.selectLabel.label2) {
         this.selectLabel.label2 = false;
       } else {
         this.selectLabel.label2 = true;
       }
       this.selectLabel.label1 = false;
       this.selectLabel.label3 = false;
     } else if (labNumber === 'label3') {
       if (this.selectLabel.label3) {
         this.selectLabel.label3 = false;
       } else {
         this.selectLabel.label3 = true;
       }
       this.selectLabel.label1 = false;
       this.selectLabel.label2 = false;
     }
    if (e.stopPropagation) {
      e.stopPropagation();
    } else {
      e.cancelBubble = true;
    }
  }

  chengeCatagory(labNumber:any, i:any, name:any, category_id:any, e:any) {
    this.searchService.currentPage = 1;
    if (this.selectLabel[labNumber]) {
      this.selectLabel[labNumber] = false;
    } else {
      this.selectLabel[labNumber] = true;
    }
    // 判断显示分类内容
    if (labNumber === 'label1') {
      this.searchService.categoryList = this.searchService.findThisChilds(1, i);
      this.label1Selected = true;
      this.label1Text = name;
      this.label2Text = '';
      this.label3Text = '';
    } else if (labNumber === 'label2') {
      this.searchService.subCategoryList = this.searchService.findThisChilds(2, i);
      this.label2Selected = true;
      this.label2Text =  name;
      this.label3Text = '';
    } else if (labNumber === 'label3') {
      this.label3Selected = true;
      this.label3Text = name;
    }
    this.searchService.cloudSearchPage(this.keyword, category_id, true);
    if (e.stopPropagation) {
      e.stopPropagation();
    } else {
      e.cancelBubble = true;
    }
    return;
  }

  // categoryText(name) {
  //   if (this.label1Selected) {
  //     this.selectCategoryText += ' ,' + name ;
  //   } else {
  //     this.selectCategoryText += name;
  //   }
  //   return  this.selectCategoryText + '已被选中';
  // }

  initFilter() {
    this.label1Selected = false;
    this.label2Selected = false;
    this.label3Selected = false;
    this.searchService.currentPage = 1;
    this.searchService.category_id = 0;
    this.searchService.categoryList = [];
    this.searchService.subCategoryList = [];
    // 清空上一次的类目
    this.searchService.categoryData = [];
    // 清空上一次的数据
    this.searchService.searchList = [];
  }

  // 清除筛选条件和数据
  clearFilter() {
    this.showH5Select = false; // 主要H5使用
    this.label1Selected = false;
    this.label2Selected = false;
    this.label3Selected = false;
    this.searchService.currentPage = 1;
    this.searchService.categoryList = [];
    this.searchService.subCategoryList = [];
    this.searchService.category_id = 0;
    this.searchService.cloudSearchPage(this.keyword, false , true);

  }

  favoriteResult(data:any){
  	this.favoriteType = data.type;
  	this.favoriteCode = data.code;
  	if(data.code == 1000){
  		this.showFavoriteBox = true;
  	}
  }
  
  closeFavoriteBox(){
  	this.showFavoriteBox = false;
  }
  
  bak(e:any) {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else {
      e.cancelBubble = true;
    }
    return;
  }

  @HostListener('document:click', ['$event.target'])
  claerAll() {
    this.selectLabel.label1 = false;
    this.selectLabel.label2 = false;
    this.selectLabel.label3 = false;

  }
	loadMore(){
		if (!this.searchService.loadLock) {
			  this.searchService.loadLock = true;
			  this.searchService.loading = true;
			  this.searchService.currentPage++;
			  this.searchService.cloudSearchPage(this.keyword);
		}

	}
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (isPlatformBrowser(this.platformId)) {
 
    }
  }

  ngOnDestroy() {
	//离开页面移除在线客服自定义样式
	$('body').removeClass('plp_udesk');
    this.initFilter();
  }
}

