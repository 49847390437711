import { Injectable } from '@angular/core';
// import {InitializeService} from '../services/initialize.service';

@Injectable()
export class RemoteServer {
  protocol = 'https:';
  port = '443';
  domain = 'www.dev.cos.client.efashionchina.com';
  apiPrefix = '/cos-dev';
  BASIC_HOST = this.protocol + '//' + this.domain + ':' + this.port + this.apiPrefix;

  //2021-11-23 注释constructor，让RemoteServer不依赖其他，只作为常量类
  // constructor(@Inject(InitializeService) private init) {
  //   if (this.init.protocol !== undefined && this.init.protocol !== '') {
  //     this.protocol = this.init.protocol;
  //   }
  //   if (this.init.port !== undefined && this.init.port !== '') {
  //     this.port = this.init.port;
  //   }
  //   if (this.init.domain !== undefined && this.init.domain !== '') {
  //     this.domain = this.init.domain;
  //   }
  //   this.apiPrefix = this.init.indexOf(this.domain);
  //   this.BASIC_HOST = this.protocol + '//' + this.domain + ':' + this.port + this.apiPrefix;
  // }
}
