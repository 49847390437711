import {Component, OnInit} from '@angular/core';
 
@Component({
  selector: 'app-udesk',
  templateUrl: './udesk.component.html',
  styleUrls: ['./udesk.component.css']
})
export class UdeskComponent implements OnInit {
  constructor() {}
  ngOnInit() {
	window.location.href = "//efashionchina.udesk.cn/im_client/?web_plugin_id=117161&group_id=257271&language=zh-cn&channel=A";  
  }

  
}
