<div data-component="OPageContent" class="o-page-content cofs-page">
  <div class="o-reset-password">
    <form (submit)="onSubmit()" data-component="OForm" class="o-form" action="#">
      <h3 class="a-heading-3">重置密码</h3>
      <div class="m-error js-m-error is-hidden">
        <label class="a-label js-a-label has-error"></label>
      </div>
      <div class="reset-password-input-container">
        <!-- 文本框：激活加is-active类,错误加has-error类 -->
        <div data-component="MInput" class="m-input password" [ngClass]="{'is-active':currentPasswordActive,'has-error':currentPasswordError}">
          <label class="a-label js-a-label">原密码</label>
          <input class="a-input js-a-input" name="current_password" [(ngModel)]='currentPasswordVal' type="password" (focus)="activeCurrentPassword()" (blur)="currentPasswordValid()" title="原密码" placeholder="原密码">
          <label  *ngIf="currentPasswordError" class="a-label js-a-label">{{currentPasswordErrorLabel}}</label>
        </div>
        <div data-component="MInput" class="m-input password" [ngClass]="{'is-active':passwordActive,'has-error':passwordError}" id="password">
          <label class="a-label js-a-label">新密码</label>
          <input class="a-input js-a-input" name="password" [(ngModel)]='passwordVal' type="password" (focus)="activePassword()" (blur)="passwordValid()" title="新密码" placeholder="新密码">
          <label  *ngIf="passwordError" class="a-label js-a-label">{{passwordErrorLabel}}</label>
        </div>
        <div data-component="MInput" class="m-input repeat-password" [ngClass]="{'is-active':rePasswordActive,'has-error':rePasswordError}" data-validation-match-field="password">
          <label class="a-label js-a-label">确认新密码</label>
          <input class="a-input js-a-input" type="password" name="re_password" [(ngModel)]='rePasswordVal' (focus)="activeRePassword()" (blur)="rePasswordValid()" title="确认新密码" placeholder="确认新密码">
          <label *ngIf="rePasswordError" class="a-label js-a-label">{{rePasswordErrorLabel}}</label>
        </div>
      </div>
      <label class="" *ngIf="resetPassword">{{resetPasswordMessage}}</label>
      <button type="submit" class="a-button is-primary"><span>提交</span></button>
    </form>
    <img class="a-image">
  </div>
</div>
