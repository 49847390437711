
export class ProductModel {
  public entity_id: any;
  public loadTimestamp: any;
  public thumbnail: any;
  public panorama_image: any;
  public categoryId: any;
  public description: any;
  public sku: any;
  public sku_list: any = [];
  public stock_item = [];
  public attribute: any;
  public image_block: any;
  public color_id: any;
  public color: any;
  public default_image: any;
  public freight:any;
  public sizeName:any = '选择尺码';
  public colors:any = [];
  public selectColorId:any;
  public totalPrice:any;
  public style: any;
  public category_ids:any = [];
  public pdp_sizechart:any;
  public colorQty:any = [];
  public is_new:any = false;   // 缺少字段
  public is_exclusively:any = false;  // 缺少字段


  // cloud 数据
  public valueId: any;  // 颜色的valueId
  public valueName:any;
  public skus: any;
  public itemName:any;
  public itemId: any;
  public resources: any;
  public isDeleted:any;
  public itemCode: any;
  public feature: any;
  public cloudSalePrice: any; // 促销价
  public cloudPrice: any; // 吊牌价
  public productListByValueId:any = []; // 按照颜色分组的基本数据
  public ccSku:any = ''; // cc级别的sku
  public approveStatus: any;
  public price: any;
  public frontUrlPath:any;
  public frontMaterialTag:any;
  public frontDescription:any;
  public frontFeature:any;
  public frontWash:any;
  public sizeTableImageUrl:any;
  public arrivalNotice:any;
  public useStoreApproveStatus:boolean = false;//是否启用店铺级商品状态
  public storeApproveStatus:any;//店铺级商品状态
  public includeVideo:boolean = false;//包含视频
  public presaleTag:any;
  public frontProperties:any;
  public frontCustomParameters:any;
  public hoverImage :any;	//平铺图
  public majorImage :any;	//模特图
  constructor(data: {}) {
    Object.assign(this, data);
  }

  // 获取colors
  public getColors() {

  }


  /**
   * 获取当前cc商品的图片
   */
  public getCCimgaes() {

  }

  // 尺码库存合并
  public getProductSize() {

  }

  public ColorSoldOut() {

  }

  // 价格判断
  public getPrice() {
    if (Number(this.cloudPrice) > Number(this.cloudSalePrice)) {
      return true;
    } else {
      return false;
    }
  }

  // 售卖价格
  public getSalesPrice() {
    if (Number(this.cloudPrice) > Number(this.cloudSalePrice)) {
      return this.cloudSalePrice;
    } else {
      return this.cloudPrice;
    }
  }

  // 获取颜色价格
  public getColorPrice() {

  }

  // 判断价格
  public getPriceJudge() {
  }

  // 判断库存是否存在
  public getStockJudge(_stockArray:any) {

  }

  // 从dp路由中获取colorid
  public getDpColorId() {
    if (this.color) {
      return this.color;
    } else if (this.default_image) {
      return this.default_image;
    } else {
      for (const i in this.sku_list) {
        return this.sku_list[i].color;
      }
    }
  }

  // 获取选中的图片
  public getImage(colorId:any) {
	
    const image_list = [];
    if (this.resources.length > 0) {
      for (let i = 0; i < this.resources.length; i++) {
        //this.resources[i]['type'] === 'image' &&
		if(this.resources[i]['valueId'] == 0){
			//平铺图，模特图
			if (this.resources[i]['kind'] === 'hover'){
				this.hoverImage = this.resources[i]['url'];
			}else if(this.resources[i]['kind'] === 'major'){
				this.majorImage = this.resources[i]['url'];
			}
		}else{
			if(this.resources[i]['valueId'].toString() === colorId.toString()){
				if (this.resources[i]['kind'] === 'hover'){
					this.hoverImage = this.resources[i]['url'];
				}else if(this.resources[i]['kind'] === 'major'){
					this.majorImage = this.resources[i]['url'];
				}
				
				if (this.resources[i]['kind'] === 'loop'){
					if(this.resources[i]['type'] === 'video'){
					  this.includeVideo = true;
					}
					image_list.push({
					  'url': this.resources[i]['url'],
					  'resourceId': this.resources[i]['resourceId'],
					  'sort': this.resources[i]['sortOrder'],
					  'type': this.resources[i]['type'],
					});
				}
			}
		}
      }
      image_list.sort(function(a, b) {
        return a.sort - b.sort;
      });
    }

    return image_list;
  }

  // 获取选中的颜色
  public getColorName(colorId:any) {
    for (let i = 0; i < this.sku_list.length; i++) {
      if (this.sku_list[i].color === colorId) {
        return this.sku_list[i]['color_value'];
      }
    }
  }

  // 改变尺码
  public changeProductSizeName(size:any) {
    this.sizeName = size;
  }

  // 获取尺码
  public getProductSizeName() {
    return this.sizeName;
  }

  // 记录选择的颜色id
  public getColorId(id = '') {
    if (id === '') {
      this.selectColorId = this.getDpColorId();
    } else {
      this.selectColorId = id;
    }
    return this.selectColorId;
  }

  // 保存总价
  public getTotalPrice(websiteId:any, number:any) {
    for (let i = 0; i < this.colors.length; i++) {
      if (this.colors[i].colorsId == this.getColorId()) {
        if (websiteId == 'TW') {
          this.totalPrice = this.colors[i].salePrice * number;
        } else {
          this.totalPrice = (this.colors[i].salePrice * number).toFixed(2);
        }
      }
    }

    return this.totalPrice;
  }

  public getCCstatus() {
    return this.isDeleted;
  }

  public getCCValueId(sku:any){
    for (const key in this.skus) {
      for (const j in this.skus[key].skuProperties) {
        if (this.skus[key].skuProperties[j].isFront === 1) {
          if (sku.toString() === this.skus[key].skuProperties[j]['frontCode']) {
            this.valueId = this.skus[key].skuProperties[j].valueId;;
          }
        }
      }
    }
  }

  /**
   * cloud--获取colors
   * @param sku
   * @returns {Array}
   */
  public getColorsCloud(sku:any): Array<any> {
    const new_color_obj:any = {};
    const new_color_arr:any = [];
    for (const key in this.skus) {
      let color_value_id = 0;
      let ccApproveStatus = '';
      const skuInfo = {'skuId': 0, 'skuCode': '', 'barcode': '', 'qty': 0, 'valueId': 0, 'valueName': '', 'propertyName': '', 'sortOrder' : 0,'presaleTag':'','presaleType':'','onPresale':'','presalePrice':'','presaleStartTime':'','presaleEndTime':'','payDepositStart':false,'price':'','markingPrice':'', 'skuImageUrl':''};
      for (const j in this.skus[key].skuProperties) {
        if (this.skus[key].skuProperties[j].isFront === 1) {
          const value_id = this.skus[key].skuProperties[j].valueId;
          const value_name = this.skus[key].skuProperties[j].valueName;
          const frontUrlPath = this.skus[key].skuProperties[j].frontUrlPath;

          color_value_id = value_id;
          ccApproveStatus = this.skus[key].skuProperties[j]['approveStatus'];
          if (!this.colorQty.hasOwnProperty(value_id)) {
            this.colorQty[value_id] = 0;
          }
          if (ccApproveStatus === 'onsale') {
            this.colorQty[value_id] = this.skus[key].qty + this.colorQty[value_id];
          }

          if (!new_color_obj.hasOwnProperty(value_id)) {
            if(ccApproveStatus === 'onsale'){
              const colorArray = {'text': '', 'img_block': '', 'color_id': '', 'url_path': ''};
              colorArray.text = this.skus[key].skuProperties[j].valueName;
              colorArray.img_block = this.skus[key].skuBlockUrl;
              colorArray.color_id = value_id;
              colorArray.url_path = this.skus[key].skuProperties[j].frontUrlPath;
              new_color_obj[value_id] = colorArray;
            }

          }
          if (!this.productListByValueId.hasOwnProperty(value_id)) {
            this.productListByValueId[value_id] = {
              'newStartTime': this.skus[key].skuProperties[j]['newStartTime'],
              'newEndTime': this.skus[key].skuProperties[j]['newEndTime'],
              'valueName': this.skus[key].skuProperties[j]['valueName'],
              'frontCode': this.skus[key].skuProperties[j]['frontCode'],
              'price': this.skus[key]['price'],
              'markingPrice': this.skus[key]['markingPrice'],
              'strategyStartTime': this.skus[key].skuProperties[j]['strategyStartTime'],
              'strategyEndTime': this.skus[key].skuProperties[j]['strategyEndTime'],
              'strategyPrice': this.skus[key].skuProperties[j]['strategyPrice'],
              'frontTag' : this.skus[key].skuProperties[j]['frontTag'],
              'approveStatus': this.skus[key].skuProperties[j]['approveStatus'],
              'frontMaterialTag':this.skus[key].skuProperties[j]['frontMaterialTag'],
              'frontDescription':this.skus[key].skuProperties[j]['frontDescription'],
              'frontFeature':this.skus[key].skuProperties[j]['frontFeature'],
              'frontWash':this.skus[key].skuProperties[j]['frontWash'],
              'sizeTableImageUrl':this.skus[key].skuProperties[j]['sizeTableImageUrl'],
              'skuImageUrl': this.skus[key]['skuImageUrl'],
              'hoverUrl': this.skus[key].skuProperties[j]['hoverUrl']
            };
            if (!this.valueId && sku.toString() === this.skus[key].skuProperties[j]['frontCode']) {
              this.valueId = value_id;
              this.valueName = value_name;
              this.frontUrlPath = frontUrlPath;
			  
			  //预售标签
			   if(this.skus[key].onPresale == 1 && this.skus[key].presaleTag !== '' && this.skus[key].presaleTag !== 'null'){
			     this.presaleTag = this.skus[key].presaleTag;
			   }
            }
          }
        } else {
          skuInfo.skuId = this.skus[key].skuId;
          skuInfo.skuCode = this.skus[key].skuCode;
          skuInfo.barcode = this.skus[key].barcode;
          skuInfo.qty = this.skus[key].qty;
          skuInfo.valueId = this.skus[key].skuProperties[j].valueId;
          skuInfo.valueName = this.skus[key].skuProperties[j].valueName;
          skuInfo.sortOrder = this.skus[key].skuProperties[j].sortOrder;
          skuInfo.onPresale = this.skus[key].onPresale;
          skuInfo.presaleType = this.skus[key].presaleType;
          skuInfo.presaleTag = this.skus[key].presaleTag;
          skuInfo.presalePrice = this.skus[key].presalePrice;
          skuInfo.presaleStartTime = this.skus[key].presaleStartTime;
          skuInfo.presaleEndTime = this.skus[key].presaleEndTime;
          skuInfo.price = this.skus[key].price;
          skuInfo.markingPrice = this.skus[key].markingPrice;
          skuInfo.skuImageUrl = this.skus[key].skuImageUrl;



          //是否到预售时间
          if(this.skus[key].onPresale == 1){
            const startTime = Date.parse(this.skus[key].presaleStartTime.replace(/-/g, '/'));    // 为了兼容IOS，需先将字符串转换为'2018/9/11 9:11:23' 返回'2018-9-12 9:11:23'的时间戳
            const endTime = Date.parse(this.skus[key].presaleEndTime.replace(/-/g, '/'));
            const timestamp = (new Date()).getTime();
            if (timestamp >= startTime && timestamp < endTime) {
              skuInfo.payDepositStart = true;
            }
          }
        }
      }
      if (!this.sku_list.hasOwnProperty(color_value_id)) {
        this.sku_list[color_value_id] = [];
      }
      if (ccApproveStatus === 'instock') {
        skuInfo.qty = 0;
      }

      this.sku_list[color_value_id].push(skuInfo);
    }
    for (const k in new_color_obj) {
        new_color_arr.push(new_color_obj[k]);
    }

    if (!this.valueId) { // 如果sku是款号 默认展示第一个颜色
      for (const kc in new_color_obj) {
        this.valueId = kc;
        break;
      }
    }
    return new_color_arr;
  }
}
