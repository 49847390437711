export class CmsPageModel {

  public id: any;
  public loadTimestamp: any;
  public pcPage!: { content: string, title: string};
  public trackData!: {};


  constructor(data: {}) {
    Object.assign(this, data);
  }
}
