import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	OnChanges
} from '@angular/core';
import { CallComponentService } from '../../../services/callcomponent.service';
import { ProductService } from '../../../services/product.service';
import { InitializeService } from '../../../services/initialize.service';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';

import {
	SwiperNavigationInterface,
	SwiperConfigInterface
} from 'ngx-swiper-wrapper';

const navigationConfig : SwiperNavigationInterface = {
	nextEl: '.arrow_right',
	prevEl: '.arrow_left',
	hideOnClick: true,
};
export const OUT_OF_SOON = 3;

@Component({
	selector: 'app-product-size-box',
	templateUrl: './product-size-box.component.html',
	styleUrls: ['./product-size-box.component.css']
})
export class ProductSizeBoxComponent implements OnInit, OnChanges {
	@Input() list : Size[] = [];
	@Input() frontCustomParameters : any;
	@Input() sizeChartHtml : any;
	@Input() hideSizeChart! : boolean;
	@Input() sizeTableImageUrl : any;
	@Input() productModel! : any;
	@Input() showItemInfo! : boolean;
	@Input() favoriteItemIds = [];
	@Input() frontCode! : any;
	@Input() salePrice! : any;
	@Input() tabId! : any;
		
	@Output() addFavorite = new EventEmitter<object>();
	@Output() delFavorite = new EventEmitter<object>();
	@Output() change = new EventEmitter<object>();
	@Output() changeTab = new EventEmitter<object>();
	@Output() closeLayer = new EventEmitter<object>();

	// 图片集合
	@Input() ImageArray : any;
	//新品上市标签
	@Input() isNewArrival : any | undefined;
	//显示视频
	@Input() showVideo : any;
	//包含视频
	@Input() includeVideo : any;
 
	size! : Size;
	hover! : string;
	remove : any = false;
	outOfStock! : boolean;
	show_modal : any = false;
	finalSizeImageUrl : any;
	showOldSizeHtml : boolean = false;
	showMeasureMethod : boolean = false;
	measurePic! : string;//测量图
	specialMeasurePic! : string;
	howToMeasurePic! : string;//如何测量图（默认收缩）
	specialHowToMeasurePic! : string;
	sizeChartPic! : string;//尺码表
	specialSizeChartPic! : string;
	tryonPic! : string;//试穿报告、
	specialTryonPic! : string;
	recommendPic! : string;//尺码推荐表
	specialRecommendPic! : any;
	hasSizeGuide : boolean = false;
	miniVideo : boolean = true;
	// 货币
	public currency : any;
	//尺码已售罄
	public sizeSoldOut : any = false;
	public isSoldOutSoon : any = false;//低库存


	public imageList = [];
	public isNew : any;
	public videoUrl : any;
	public currentSlide = 1;
	public slideTotal = 0;
	public videoPoster : any;
	constructor(public callComponentService : CallComponentService,
		private router : Router,
		private init : InitializeService,
		public productService : ProductService) {

	}
	mySwiperConfig : SwiperConfigInterface | undefined;
	ngAfterViewInit() {

	}

	ngOnChanges() {
		this.imageList = this.ImageArray;
		this.isNew = this.isNewArrival;
		if (this.includeVideo) {
			this.slideTotal = this.imageList.length - 1;
		} else {
			this.slideTotal = this.imageList.length;
		}
	}
	ngOnInit() {
		this.mySwiperConfig = {
			direction: 'horizontal',
			loop: false,
			slidesPerView: 1.3,
			spaceBetween: 10,
			initialSlide: 0,
			centeredSlides: false,
			slidesPerGroup: 1,
			observer: true,
			observeParents: true,
			threshold: 1,
			navigation: navigationConfig,
			mousewheel: false,
			scrollbar: false
		};
 
		if (!!this.sizeTableImageUrl) {
			this.finalSizeImageUrl = this.sizeTableImageUrl.replace('http://img.cos.client.efashionchina.com', 'https://www.cos.cn/img');
		}

		if (this.list.length === 1) {
			this.onSelect(this.list[0]);
			this.remove = true;
		} else if (this.list.length > 1) {
			this.list.sort(function (a : any, b : any) {
				return a['sortOrder'] - b['sortOrder'];
			});
		}
 
		if (!!this.frontCustomParameters) {
			var option = this.frontCustomParameters;
			// 测量图
			this.measurePic = !!option.measurePic ? option.measurePic : '';
			// 如何测量图（默认收缩）
			this.howToMeasurePic = !!option.howToMeasurePic ? option.howToMeasurePic : '';
			// 尺码表
			this.sizeChartPic = !!option.sizeChartPic ? option.sizeChartPic : '';
			// 试穿报告
			this.tryonPic = !!option.tryonPic ? option.tryonPic : '';
			//尺码推荐表
			// this.recommendPic = option.recommendPic;
			if ((!!this.measurePic || !!this.howToMeasurePic || !!this.tryonPic) || (!!this.sizeChartPic && this.getImageHeight(this.sizeChartPic) > 60)) {
				this.hasSizeGuide = true;
			}
		}
		this.currency = this.init.currency;
		// 订阅尺码label is-disable是否显示
		this.callComponentService.changeSizeLabel.subscribe((value : any) => {
			this.remove = value;
		});
	}

	public onIndexChange(index : number) {
		if (!this.includeVideo) {
			this.currentSlide = index + 1;
		} else {
			if (index == 0) {
				this.currentSlide = 1;
			} else {
				this.currentSlide = index + 1
			}
		}
	}
	
	//关闭尺码弹窗
	closeSizeLayer() {
		this.closeLayer.emit();
	}
	//关闭尺码指南弹窗
	closeSizeGuideLayer() {
		if(this.showItemInfo){
			$(".minus-svg-quick").addClass('hidden');
			$(".plus-svg-quick").show();
		}else{
			$("#minus-svg").addClass('hidden');
			$("#plus-svg").show();
		}
 
		// if(!this.init.IsPC()){
		// 	this.closeLayer.emit();
		// }
	}

	changeSizeGuide() {
		if(this.showItemInfo){
			$(".minus-svg-quick").removeClass('hidden');
			$(".plus-svg-quick").hide();
		}else{
			$("#minus-svg").removeClass('hidden');
			$("#plus-svg").hide();
		}

	}
	
	clickSizeGuide(){
		if(this.showItemInfo){
			if($(".size-guide-mini-quick").is(":hidden")){
					$(".size-guide-mini-quick").addClass("zindex-20");
					$(".size-guide-mini-quick").show();
					this.changeSizeGuide();
			}else{
				$(".size-guide-mini-quick").hide();
				$(".size-guide-mini-quick").removeClass("zindex-20");
				$("#size-item").addClass("zindex-20");
				this.closeSizeGuideLayer();	
			}
		}else{
			if($("#size-guide-mini").is(":hidden")){
				$("#size-guide-mini").addClass("zindex-20");
				$("#size-guide-mini").show();
				this.changeSizeGuide();
			}else{
				$("#size-guide-mini").hide();
				$("#size-guide-mini").removeClass("zindex-20");
				$("#size-item").addClass("zindex-20");
				this.closeSizeGuideLayer();	
			}
		}
	}
	
	glideSize(size : any) {
		const Size = size;
		if (Number(Size.qty) == 0 && Size.skuCode) {
			this.sizeSoldOut = true;
		} else {
			this.sizeSoldOut = false;
		}

		//低库存判断
		if ((Number(Size.qty) <= OUT_OF_SOON && Number(Size.qty) > 0)) {
			this.isSoldOutSoon = true;
		}
	}

	getImageHeight(url : any) {
		var img = new Image();
		img.src = url;
		// 如果图片被缓存，则直接返回缓存数据
		if (img.complete) {
			return img.height;
		} else {
			// 完全加载完毕的事件
			img.onload = function () {
				return img.height;
			}
		}
		return 0;
	}

	operateMeasureMethod() {
		this.showMeasureMethod = !this.showMeasureMethod;
	}

	onSelect(s : any) {
		this.size = s;
		const size_info = this.size;
		this.change.emit(size_info);
	}
	
	appendFavorites(frontCode : any, itemId : any, valueId : any){
		//快速加购弹框需传商品对象，方便tracking
		if(this.showItemInfo){
			this.addFavorite.emit({'itemCode':frontCode,'item':this.productModel});
		}else{
			this.addFavorite.emit({'itemCode':frontCode,'itemId':itemId,'valueId':valueId})
		}
	}
	
	removeFavorites(itemId : any, valueId : any){
		//快速加购弹框需传商品对象，方便tracking
		if(this.showItemInfo){
			this.delFavorite.emit({'item':this.productModel});
		}else{
			this.delFavorite.emit({'itemId':itemId,'valueId':valueId});
		}
	}
	
	itemDetails(){
		this.closeSizeLayer();
		this.router.navigate(['/'+this.productModel.frontUrlPath]);
	}
	
}

interface Size {
	skuCode : string;
	valueName : string;
	qty : number;
	skuId : number;
	barcode : string;
	valueId : number;
	propertyName : string;
}