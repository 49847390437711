import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import {StorageService} from '../../services/storage.service';
import {RequestService} from '../../services/request.service';
import {HttpParams} from '@angular/common/http';
const apiConfig = require('../../config/config.api.json');

@Component({
  selector: 'app-account-logout-processing',
  templateUrl: './account-logout-processing.component.html',
  styleUrls: ['./account-logout-processing.component.css']
})

export class AccountLogoutProcessingComponent implements OnInit {
  public successMsg = '';
  public reasonVal!: string;
  constructor(public init:InitializeService,
              private router:Router,
              private localStorage:StorageService,
              private request: RequestService,
              @Inject(PLATFORM_ID) private platformId:Object) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.init.isLogin() || this.localStorage.getItem('logout_processing') === null) {
        this.router.navigate(['']);
      }else{
        this.init.pageConfiguration('账户注销中');
        this.processing();
      }
    }
  }

  processing(){
    this.reasonVal = this.localStorage.getItem('logout_reason');
    const url = apiConfig.customerApi + '?action=logout';
    const params = new HttpParams()
      .set('store_id', this.init.storeId)
      .set('reason', this.reasonVal)
      .set('mobile', this.localStorage.getItem('mobile'));
    this.request.put(url, params)
      .subscribe(
        (val:any) => {
          if (val['code'] === 200) {
            this.localStorage.removeItem('logout_processing');
            this.localStorage.removeItem('logout_error_msg');
            this.localStorage.setItem('logout_success_msg',val['message']);
            this.router.navigate(['/my-account/logout-success']);
          }else{
            this.localStorage.setItem('logout_error_msg',val['message']);
            this.router.navigate(['/my-account/logout-error']);
            return;
          }
        }
      );
  }
}
