<div class="o-product" data-id="{{productModel.itemId}}-{{productModel.valueId}}">
  <div class="merch"></div>
  <div class="image-holder">
    <!-- 缩略图 开始 -->
    <div class="listing_thumbnail" *ngIf="isVmLogin == 1">
      <div class="add_Icon" (click)="vmService.vmAddClick()"></div>
      <div class="imgList">
        <ul *ngFor="let images of ccImages; let i = index; ">
          <li>
            <a class="a-link"><img class="a-image is-selected" src="{{images}}$200x200" alt="图片"></a>
            <div class="setImg selection" data-id="listing_thumbnail_{{productModel.itemId}}">
              <a *ngIf=" i === 0 " (click)="vmImageClick( productModel.itemId, images, 1, i )"
                 class="img_1 act">主图</a>
              <a *ngIf=" i !== 0 " (click)="vmImageClick( productModel.itemId, images, 1, i )" class="img_1">主图</a>
              <a *ngIf=" i === 1 " (click)="vmImageClick( productModel.itemId, images, 2, i )"
                 class="img_2 act">辅图</a>
              <a *ngIf=" i !== 1 " (click)="vmImageClick( productModel.itemId, images, 2, i )" class="img_2">辅图</a>
            </div>
          </li>
        </ul>
      </div>

    </div>
    <!-- 缩略图 结束 -->
    <div class="image-if-hover">
      <!-- 断码率 显示在vm里 -->
      <div *ngIf="isVmLogin == 1 && productModel.out_stock_rate !== 0" [ngStyle]="{'background-color': out_stock_rate}" class="stock-rate"></div>
        <!-- 此处显示收藏icon -->
        <div class="rightIcon" *ngIf="favoriteIcon">
			<div *ngIf="childFavoriteItemIds[productModel.itemId+'_'+productModel.valueId]; else notin" (click)="delFavoriteItem(productModel.itemId,productModel.valueId)" class="icon_wishlist">
				<svg><title>收藏</title>
					<use href="/assets/iconSprite.svg#wishlist-active"></use>
				</svg>
			</div>

			<ng-template #notin>
				<div class="icon_wishlist" (click)="addFavorite(productModel.frontCode,productModel.itemId,productModel.valueId)">
					<svg><title>收藏</title>
						<use href="/assets/iconSprite.svg#wishlist"></use>
					</svg>
				</div>
			</ng-template>

			</div>

			<!-- H5收藏icon -->
			<div class="rightIcon h5" *ngIf="childFavoriteItemIds[productModel.itemId+'_'+productModel.valueId]; else mobnotin">
				<div class="icon_wishlist" (click)="delFavoriteItem(productModel.itemId,productModel.valueId)">
					<svg><title>收藏</title>
						<use href="/assets/iconSprite.svg#wishlist-active"></use>
					</svg>
				</div>
			</div>
			<ng-template #mobnotin>
				<div class="rightIcon h5">
					<div class="icon_wishlist" (click)="addFavorite(productModel.frontCode,productModel.itemId,productModel.valueId)">
						<svg><title>收藏</title>
							<use href="/assets/iconSprite.svg#wishlist"></use>
						</svg>
					</div>
				</div>
			</ng-template>

				<a routerLink="/{{categoryUrl}}/{{url_path}}" (click)="toPdp()">
          <div *ngIf="isPcFlag || !showCarousel;else mobileTemplate" class="m-product-image pos_rel">
			<img class="a-image default-image"  alt="图片" [ngStyle]="{'display':!isPcFlag?'block':'none'}"
                 [defaultImage]="defaultImagePath" [lazyLoad]="defaultImagePath"
                 [offset]="offset" @fadeInOut/>
            <img class="a-image default-image" alt="图片"
                 [ngStyle]="{'display':((!panoramaImage||productModel.itemId!=showHoverProductId) && isPcFlag)?'block':'none'}"
                 (mouseover)="imageMouseover(productModel.itemId)" [defaultImage]="defaultImagePath"
                 [lazyLoad]="defaultImagePath" [offset]="offset" @fadeInOut/>
            <img class="a-image" alt="图片"
                 [ngStyle]="{'display':((panoramaImage&&productModel.itemId==showHoverProductId) && isPcFlag)?'block':'none'}"
                 (mouseout)="imageMouseout()" src="{{panoramaImage}}"/>
          </div>

          <ng-template #mobileTemplate>
            <!--H5可滑动-->
            <div class="m-product-image pos_rel" (touchstart)="touchProductImage(productModel.itemId+'-'+productModel.valueId,productModel.frontCode)">
              <div class="slider swiper-container swiper-container-horizontal hidden-lg hidden-md" [swiper]="config" (indexChange)="onIndexChange($event)">
                <div class="swiper-wrapper">
                  <div *ngFor="let val of productModel.loopResourceList" class="swiper-slide">
                    <img class="a-image default-image" alt="图片"  src="{{val.url}}" />
                  </div>
                </div>
                <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets hide-bullets" id="bullets-{{productModel.itemId}}-{{productModel.valueId}}">
                <span class="swiper-pagination-bullet" [ngClass]="{'swiper-pagination-bullet-active': key == currentSwiperIndex}" *ngFor="let val of productModel.loopResourceList;let key=index;">
               </span>
                </div>
              </div>
            </div>
          </ng-template>
        </a>
    </div>

    <div class="detail-container" id="floatSizeTab_{{productModel.itemId}}_{{productModel.valueId}}_{{productModel.frontCode}}" style="display: none;" >
      <!--pc尺码选择-->
      <div class="color-section pc-size-section">
        <div data-component="MDropdown" class="m-dropdown pdp-dropdown is-filled is-open">
          <ul class="list-wrapper">
            <li>
              <ul class="options size_box" *ngFor="let sizeItem of productSizeArray[productModel.itemId+'-'+productModel.valueId]">
                <li class="a-option size-options in-stock" (click)="addShoppingCart(productModel,sizeItem.skuCode,sizeItem.skuId,sizeItem.valueName,1)"> <span class="size-name">{{productService.sizeConvert(sizeItem.valueName)}}</span><span *ngIf="sizeItem.qty < 1" class="out-stock-size pull-right">已售罄</span></li>
              </ul>
            </li>
          </ul>
      </div>
    </div>
    </div>
  <div class="description" id="description-{{itemIndex}}" [ngClass]="{'pl20' : (productService.isMultipleOfFour(itemIndex) && isPC()),'col2':(productService.isMultipleOfTwo(itemIndex) && !isPC())}">
    <div class="marker-label temp-marker new-marker" *ngIf="is_new && !isNewArrivalCategory">
      <span >NEW</span>
    </div>

    <div class="lg:pl-0 pr-3.75 lg:pr-0">
		<div class="mb-1 gap-x-5 goodsTag">
			<div class="goodsTitle">
				<a class="ellipsis" routerLink="/{{categoryUrl}}/{{url_path}}" (click)="toPdp()">
					<label class="a-label js-a-label product-title" *ngIf="rulePriceJson.rulesArr.length > 0 || (cloudPrice != cloudSalePrice);else generalName"><span class="rules-ellipsis">{{productModel.itemName}}</span></label>
						<ng-template #generalName>
							<label class="a-label js-a-label product-title"><span>{{productModel.itemName}}</span></label>
						</ng-template>
				</a>
				<div class="text-gray-500 flex">
					<!-- 价格 -->
					<div class="o-product-m-heart m-product-price" [ngClass]="{'pr20' : (productService.isOddNumber(itemIndex) && itemIndex !== 0)}">
						<a class="flex" routerLink="/{{categoryUrl}}/{{url_path}}" (click)="toPdp()">
							<label class="a-label js-a-label" [ngClass]="{'is-reduced': cloudPrice != cloudSalePrice}">{{init.formatPrice(cloudSalePrice) | currency:currency}}</label>
							<label class="a-label js-a-label is-deprecated" *ngIf="cloudPrice != cloudSalePrice">{{cloudPrice | currency:currency}}</label>
						</a>
					</div>

				</div>
			</div>

			<!-- 策略价标签 -->
			<div *ngIf="rulePriceJson.rulesArr.length > 0">
				<div *ngFor="let innerItem of productService.filterStrategyTag(rulePriceJson.rulesArr).rulesArr" class="tagBox rule-price-tag">
					<label class="a-label js-a-label rule-price">{{ innerItem.priceTag}}</label>
					<label class="a-label js-a-label rule-price" [ngClass]="{'pr20' : (productService.isOddNumber(itemIndex) && itemIndex !== 0)}">{{init.formatPrice(innerItem.strategyPrice) | currency:currency}}</label>
				</div>
			</div>

      <div class="goodsTagBox">
        <!-- 促销标签 -->
        <ng-container *ngIf="!!promotionTagList">
          <ng-container *ngFor="let generalPromotionTag of promotionTagList; let i=index;">
            <span *ngIf="i>0" class="tag-interval"></span>
            <span class="items-pro" [ngStyle]="{'color': generalPromotionTag?.tagStyle != '' ? generalPromotionTag?.tagStyle : tagColor }">{{generalPromotionTag.promotionTag}}</span>
          </ng-container>
        </ng-container>
        <!--自定义标签-->
        <ng-container *ngIf="!!customPromotionTagList">
          <ng-container *ngFor="let customGeneralTag of customPromotionTagList; let i=index;">
            <span *ngIf="i>0 || promotionTagList.length>0" class="tag-interval"></span>
            <span class="items-pro" [ngStyle]="{'background-color':'#fff', 'border-radius':'0', 'color': customGeneralTag?.tagStyle != '' ? customGeneralTag?.tagStyle : customTagColor }">{{customGeneralTag.promotionTag}}</span>
          </ng-container>
        </ng-container>
        <!--预售标签-->
        <ng-container *ngIf="productModel.presaleTag">
          <span *ngIf="promotionTagList.length>0 || customPromotionTagList.length>0" class="tag-interval"></span>
          <span class="items-pro">{{productModel.presaleTag}}</span>
        </ng-container>
        <!-- 会员提前购 -->
        <ng-container *ngIf="!!forwardPurchaseTagList && forwardPurchaseTagList.length > 0">
          <ng-container *ngFor="let forwardPurchaseTag of forwardPurchaseTagList; let i = index;">
            <span *ngIf="promotionTagList.length>0 || customPromotionTagList.length>0 || productModel.presaleTag" class="tag-interval"></span>
            <span class="items-pro" [ngStyle]="{'color': forwardPurchaseTag?.tagStyle != '' ? forwardPurchaseTag?.tagStyle : tagColor }">{{forwardPurchaseTag.promotionTag}}</span>
          </ng-container>
        </ng-container>
        <!--线上专享-->
        <ng-container *ngIf="is_exclusively">
          <span *ngIf="promotionTagList.length>0 || customPromotionTagList.length>0 || productModel.presaleTag || forwardPurchaseTagList.length > 0" class="tag-interval"></span>
          <span class="items-pro">线上专享</span>
        </ng-container>
        <!-- 材质标签-->
        <ng-container *ngIf="frontMaterialTag && frontMaterialTag!=' ' ">
          <span *ngIf="promotionTagList.length>0 || customPromotionTagList.length>0 || productModel.presaleTag || forwardPurchaseTagList.length > 0 || is_exclusively" class="tag-interval"></span>
          <a routerLink="/{{categoryUrl}}/{{url_path}}" (click)="toPdp()"><span class="items-pro">{{productModel.frontMaterialTag}}</span></a>
        </ng-container>
      </div>


			<!--<div class="allTag" style="padding-top: 100px;">-->
        <!-- 促销标签 -->
<!--        <ng-container *ngIf="!!promotionTagList">
			<ng-container *ngFor="let generalPromotionTag of promotionTagList">
				  <div class="tagBox">
				    <label class="a-label js-a-label" [ngStyle]="{'color': generalPromotionTag?.tagStyle != '' ? generalPromotionTag?.tagStyle : tagColor }">{{generalPromotionTag.promotionTag}}</label>
				  </div>
			</ng-container>
        </ng-container>-->
        <!--自定义标签-->
<!--        <ng-container *ngIf="!!customPromotionTagList">
			<ng-container *ngFor="let customGeneralTag of customPromotionTagList">
				  <div class="m-product-marker tagBox marker-text"  [ngStyle]="{'background-color':'#fff', 'border-radius':'0', 'color': customGeneralTag?.tagStyle != '' ? customGeneralTag?.tagStyle : customTagColor }" >
				    <label>{{customGeneralTag.promotionTag}}</label>
				  </div>
			</ng-container>
        </ng-container>-->
        <!--预售标签-->
   <!--     <ng-container *ngIf="productModel.presaleTag">
          <div class="m-product-marker tagBox marker-text">
              <label>{{productModel.presaleTag}}</label>
          </div>
        </ng-container>-->
        <!-- 会员提前购 -->
       <!-- <ng-container *ngIf="!!forwardPurchaseTagList && forwardPurchaseTagList.length > 0">
          <ng-container *ngFor="let forwardPurchaseTag of forwardPurchaseTagList">
            <div class="tagBox marker-label" *ngIf="checkForwardPurchaseLabel(forwardPurchaseTag.memberLevelIds)">
              <label [ngStyle]="{'color': forwardPurchaseTag?.tagStyle != '' ? forwardPurchaseTag?.tagStyle : tagColor }" class="a-label js-a-label" >{{forwardPurchaseTag.promotionTag}}</label>
            </div>
          </ng-container>
        </ng-container>-->

     <!--   <ng-container *ngIf="is_exclusively">
          <div class="markers" id="productMarkers">
            <div class="m-product-marker">
              <div class="tagBox marker-text" style="color:#080808; background-color:#fff; border-radius: 0;"><label>线上专享</label></div>
            </div>
          </div>
        </ng-container>-->
        <!-- 材质标签-->
       <!-- <ng-container *ngIf="frontMaterialTag && frontMaterialTag!=' ' ">
          <div class="m-product-marker material">
            <div class="tagBox marker-text promo-marker">
              <a routerLink="/{{categoryUrl}}/{{url_path}}" (click)="toPdp()" style="color:#080808;"><label>{{productModel.frontMaterialTag}}</label></a>
            </div>
          </div>
        </ng-container>-->
		<!--	</div>-->


			<div class="m-swatches sm">
			  <div class="picked-color"></div>
			  <div class="a-swatch js-swatch" *ngFor="let block of frontProperties">
			    <label class="a-label js-a-label colorMore"  *ngIf="productModel.valueId == block.valueId">
			      <input type="radio" name="colors" value="Black"/>
					<span>
			          <div class="a-swatch-foreground"></div>
			          <a routerLink="/{{categoryUrl}}/{{block.frontUrlPath}}" (click)="toPdp()"><img class="a-image" src="{{block.skuImageUrl}}" alt="图片"/></a>
			          <p class="sold-out">Sold out</p>
			        </span>
					<span class="colorNumber" *ngIf="frontProperties.length - 1 > 0">+{{frontProperties.length - 1}}</span>
			    </label>
			  </div>
			</div>
			<label class="a-label js-a-label product-brand">COS</label>
		</div>






    <!--pc h5 列表页收藏-->
    <div style="display: none;" class="o-product-m-heart activation" *ngIf="childFavoriteItemIds[productModel.itemId+'_'+productModel.valueId]; else notin">
      <a  class="a-link" id="a_favorite">
        <span class="favorite active pull-left" (click)="delFavoriteItem(productModel.itemId,productModel.valueId)"></span></a>
    </div>
    <ng-template #notin>
      <div style="display: none;" class="o-product-m-heart">
        <a  class="a-link" id="a_favorite">
          <span class="favorite pull-left" (click)="addFavorite(productModel.frontCode,productModel.itemId,productModel.valueId)"></span></a>
      </div>
    </ng-template>

    <!--pc h5 列表页收藏active选中效果-->
  <!--  <div style="display: none;" class="o-product-m-heart activation" id="{{productModel.itemId}}_{{productModel.valueId}}_after_favorite" style="display: none;">
      <a class="a-link" (click)="delFavoriteItem(productModel.itemId,productModel.valueId)">
        <span class="favorite active pull-left"></span></a>
    </div> -->
    <!--pc h5列表页购物袋 商品下架隐藏购物袋icon-->
    <div style="display: none;" class="o-product-m-bag" *ngIf="productModel.approveStatus === 'onsale' && productModel.frontQty > 0">
      <a  class="a-link hidden-xs hidden-sm">
        <span class="a-icon-bag" (click)="quickPurchase(productModel)"></span>
      </a>
      <!--h5尺码选择下拉-->
      <div class="color-section  sp-size-section hidden-lg hidden-md">
        <div data-component="MDropdown" data-component-id="a539e716-d6a1-42d2-b334-82d08c249b7b">
          <a class="a-link" *ngIf="!init.isLogin();else loginedSelectTpl" (click)="mobileSelectSize(productModel,0)">
            <span class="a-icon-bag a-icon-bagH5"></span>
          </a>
          <ng-template #loginedSelectTpl>
            <span class="a-icon-bag a-icon-bagH5"></span>
            <select class="options size_box fake_box a-icon-bag a-icon-bagH5" name="mbSizeList" id="mbSizeList_{{productModel.itemId}}_{{productModel.valueId}}" (change)="mobileSelectSize(productModel,$event.target.value)">
              <option value="选择尺码" selected="selected">&nbsp;&nbsp;选择尺码</option>
              <option *ngFor="let sizeItem of productSizeArray[productModel.itemId+'-'+productModel.valueId]"  value="{{sizeItem.barcode}}_{{sizeItem.skuCode}}_{{sizeItem.skuId}}_{{productModel.itemName}}_{{sizeItem.valueName}}" class="a-option size-options size-det-list in-stock">{{productService.sizeConvert(sizeItem.valueName)}}&nbsp;&nbsp;&nbsp;&nbsp;<span *ngIf="sizeItem.qty < 1" class="out-stock-size pull-right">已售罄</span></option>
            </select>
          </ng-template>

        </div>
      </div>


    </div>
  </div>
  <div class="inStock">{{qty}}</div>
</div>
</div>

<!-- 显示加入购物车报错：加is-select-country is-open -->
<div class="a-overlay js-a-overlay q-opacity-0 q-opacity-95 q-bg-grey-light" [ngClass]="{'is-visible': show_error_box || show_favorite_modal}"
     data-component="AOverlay" (click)="closeOverlay()" id="productAOverlay"></div>
<div data-component="OLightbox" class="o-lightbox is-select-country" [class.is-open]="show_error_box || show_favorite_modal" style="text-align: center">
  <div class="lightbox-header"></div>
  <div class="lightbox-content js-content u-clearfix">
    <!-- 显示加入购物车报错 开始 -->
    <div class="m-change-country-lightbox false">
      <h2 class="a-heading-2" *ngIf="!show_favorite_modal">抱歉！</h2>
      <p class="a-paragraph">{{error_message}}</p>
    </div>
    <!-- 显示加入购物车报错 结束 -->
    <div class="m-button-icon a-icon-close js-close-button" (click)="closeOverlay()"></div>
  </div>
</div>

<app-favorite-box></app-favorite-box>
