export class CmsBlockModel {

  public id: any;
  public loadTimestamp: any;
  public content: any;


  constructor(data: {}) {
    Object.assign(this, data);
  }
}
