<div class="w-full rec_container" [ngClass]="{'pdp-w-full':ispdp}" *ngIf="recommendLists.length > 0" >
  <div class="mb-26 mt-15 flex justify-between md-hidden">
    <ng-container *ngIf="currentIndex == 0; else otherBlock;">
      <h4 class="font_s_regular " style="margin: 0;">推荐搭配</h4>
    </ng-container>
    <ng-template #otherBlock>
      <h4 class="font_s_regular" style="margin: 0;">{{currentItem.itemName}}</h4>
      <div class="gap-5 font_small_xs_regular flex" *ngIf="!ispdp">
        <label class="a-label js-a-label item-price" [ngClass]="{'is-reduced product-price':currentItem?.cloudSalePrice !== currentItem?.markingPrice}">{{init.formatPrice(currentItem?.cloudSalePrice) | currency:currency}}</label>
        <label *ngIf="currentItem?.markingPrice !== currentItem?.cloudSalePrice" class="a-label js-a-label item-price" [ngClass]="{'is-deprecated':currentItem?.markingPrice}">{{init.formatPrice(currentItem?.markingPrice) | currency:currency}}</label>
      </div>
    </ng-template>
  </div>
  <div class="mb-26 mt-15 flex justify-between lg-hidden">
    <h4 class="font_small_xs_regular" style="margin: 0;">推荐搭配</h4>
  </div>
  <ul class="rec-container no-scrollbar flex w-auto snap-x snap-mandatory gap-5 overflow-x-scroll lg-mx-0 lg-grid lg-gap-0" [ngClass]="{'grid-cols-3': count==3, 'grid-cols-4': count==4}">
    <ng-container  *ngFor="let item of recommendLists; let i = index;">
      <li class="rec-block relative aspect-product-portrait overflow-hidden shrink-0 snap-start lg-w-full" [ngClass]="{'hidden-recommend': (ispdp && (i > 3))}">
        <div class="relative">
          <div class="aImgBox" [ngClass]="{'lg-img-Box':(i+1)==currentIndex}" (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave(i)">
            <a (click)="closeAddCart(item)" >  <!--routerLink="/{{item?.frontUrlPath}}"--> <!-- [queryParams]="{pk_campaign:'pio-pdp'}"-->
              <div class="relative aspect-product-portrait bg-main-product-card-image">
                <figure class="absolute bottom-0 left-0 right-0 top-0 object-contain">
                  <img class="object-cover transition-opacity duration-300 opacity-100" src="{{item?.hoverUrl}}" style="position: absolute;height: 100%;width: 100%;inset: 0px;color: transparent;"/>
                </figure>
              </div>
            </a>
            <div class="addCartBtn hidden lg-block" [ngClass]="{'lg-item_group':(i+1)==currentIndex}">
              <button (click)="selectSizeClick(item, $event)" class="icon-add appearance-none relative flex items-center justify-center" type="button" title="加入">
                <svg class="inline h-16 w-16 fill-current"><title>加入</title><use href="/assets/iconSprite.svg#plus"></use></svg>
              </button>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
