export class CategoryModel {

  public classId:any;
  public id!: number;
  public loadTimestamp: any;
  public parentId: any;
  public description: any;
  public isNavigationDisplay!: string;
  public className: any;
  public urlPath: any;
  public metaDescription: any = '';

  //  暂时用的efs的字段信息
  public displayMode: any;
  public cmsPageId: any;
  //当前分类名下方显示的cms页面名称
  public cmsBlockIdUnderCategoryName: any;
  public efsEntityId: any;
  public categoryPromo: any;
  public perLineShowColumn: any;
  public filterType: any;
  public notShowChildren: any = 0;
  public hideSort: any;
  public isSpecial: any;
  public showFilter: any;
  public navName: any;
  public newArrival:any;
  public shopBySize:any;
  public shopBySizeValues: any = [];
  public showPriceInterval: any; // 展示价格区间

  constructor(data: {}) {
    Object.assign(this, data);
  }
}
