<div class="myCoupon">
  <div *ngIf="loading;else contentTpl" style="text-align: center"> 获取中... </div>
  <ng-template #contentTpl>
    <div *ngIf="isReceive;else emptyTpl;" style="margin: -10px;">
      <div class="couponItem" *ngFor="let coupon of systermCouponArray">
        <h4>{{coupon.couponRule.ruleName}}</h4>
        <h5>券码：{{coupon.couponCode}}</h5>
        <div *ngIf="coupon.expirationDate; else ruleDate"class="stateIcon" [ngClass]="{red : coupon.timesUsed === 0 && currentDate < coupon.expirationDate.substr(0,10)}">
          <span *ngIf="coupon.timesUsed >= coupon.timesLimit && coupon.timesLimit > 0">已使用</span>
          <span *ngIf="coupon.timesUsed === 0 && currentDate < convertTime(coupon.expirationDate)">已领取</span>
          <span *ngIf="currentDate > convertTime(coupon.expirationDate)">已过期</span>
        </div>
        <ng-template #ruleDate>
          <div class="stateIcon" [ngClass]="{red : coupon.timesUsed === 0 && currentDate < convertTime(coupon.couponRule.toDate)}">
            <span *ngIf="coupon.timesUsed >= coupon.timesLimit && coupon.timesLimit > 0">已使用</span>
            <span *ngIf="coupon.timesUsed === 0 && currentDate < convertTime(coupon.couponRule.toDate)">已领取</span>
            <span *ngIf="currentDate > convertTime(coupon.couponRule.toDate)">已过期</span>
          </div>
        </ng-template>
        <div class="copRule">
          <p class="iconfont m-dropdown"><span (click)="viewDescription(coupon.couponCode)">使用规则</span><span class="a-icon-arrow-down-small" (click)="viewDescription(coupon.couponCode)"></span></p>
          <div *ngIf="coupon.couponRule.toDate && !coupon.expirationDate">有效期至{{coupon.couponRule.toDate|date:'yyyy-MM-dd'}} </div>
          <div *ngIf="coupon.expirationDate">有效期至{{coupon.expirationDate|date:'yyyy-MM-dd'}}</div>
        </div>
        <div class="copText" id="{{coupon.couponCode}}_description" style="display: none;">
          <p>{{coupon.couponRule.description}}</p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #emptyTpl>
    <div class="onCoupon">无优惠券</div>
  </ng-template>

</div>
