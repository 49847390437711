<div class="imgLeft u-cols-lg-11-24 u-cols-md-6-12 u-cols-sm-12-12 u-no-padding">
	<div class="images">
		<!-- 放大缩小图标 -->
		<div class="icon_zoomIn" (click)="showLargeSwiper('')">
			<svg *ngIf="imageZoomIn;else zoomOutSvg" class="icon_zoom_svg">
				<title>缩小</title>
				<use href="/assets/iconSprite.svg#zoom-out"></use>
			</svg>
			<ng-template #zoomOutSvg>
				<svg class="icon_zoom_svg">
					<title>放大</title>
					<use href="/assets/iconSprite.svg#zoom-in"></use>
				</svg>
		  	</ng-template>
		</div>
    <div id="productGallery" data-component="OProductGallery" class="o-product-gallery swiper-initialized small-image">
    
	 
	  <!-- 左侧图MOB_隐藏 top-->
      <div class="slider-wrapper" id="productSmallGallery">
        <div class="o-slider swiper-container" [swiper]="mySwiperConfig" (indexChange)="onIndexChange($event)">
          <div class="slider swiper-wrapper">
           <div class="slide-btn" *ngFor="let img of imageList; let i = index;">
				<span class="slide-thumbnail" id="pdp-small-image-{{img.resourceId}}"></span>
			</div>
          </div>
        </div>
      </div>

	  
      <!-- //左侧图MOB_隐藏 -->
      <!-- 左侧图MOB_显示 -->
      <div class="slider-wrapper mob_leftImg hidden-lg hidden-md">
        <!-- MOBILE 新品上市标签 -->
        <div class="description" *ngIf="isNew" style="display: none;">
          <div class="marker-label temp-marker new-marker" >
            <span class="news"></span>
          </div>
        </div>
      </div>
      <!-- //左侧图MOB_显示 -->
      <div class="main-image-wrapper" [ngClass]="{'h90': init.showNotice}">
        <!-- 新品上市标签 -->
        <div class="description" *ngIf="isNew" style="display: none;">
          <div class="marker-label temp-marker new-marker">
            <span class="news"></span>
          </div>
        </div>
        <ul>
          <li *ngFor="let img of imageList;index as i;" style="z-index: 1;" class="prd-main-img pdp-big-img"  [attr.img-id]="img.resourceId" id="pdp-big-img-{{img.resourceId}}"
              [ngStyle]="{'display':img.type == 'video' && !showVideo || includeVideo && i == 1?'none':'block'}" style="width: 100%;">
            <div class="m-product-image" *ngIf="img.type == 'video';else pcDefault" style="width: 100%;">
              <app-vjs-player [options]="{ autoplay: false, controls: true,sources: [{ src:img.url, type: 'video/mp4'}]}" [poster]="imageList[1].url"></app-vjs-player>
            </div>
            <ng-template #pcDefault>
              <div class="m-product-image">
                <div *ngIf="img.type == 'image'" (click)="showLargeSwiper(img.url)" class="cursor-zoom-in" style="transform: none; transform-origin: 50% 50% 0px;">
					<img src="{{img.url}}" class="default-image" alt="图片" @fadeInOut>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
		<div class="mob_placeholder"></div>
      </div>
    </div>
  </div>
  <div class="mobileImageScale" (click)="showLargeSwiper('')" *ngIf="mobileImageIsScale">
		<img [ngClass]="{'big-scale-zoom': mobileImageIsScale,'normal-scale-zoom': mobileImageIsScaleOut}" src="{{mobileImageScaleUrl}}"/>
		<div class="m-zoom-out">
			<button>退出放大模式</button>
		</div>
  </div>
</div>
