<div class="color-section">
	<h2 class="color-text">{{color.text}}</h2>
	<!-- 色块排列 -->
	<div class="color-blocks">
		<ul>
			<li class="p-c-block" *ngFor="let colorBlock of list; index as i" (click)="onColorChange(colorBlock)">
				<span [ngClass]="{'color-block-active':colorBlock.color_id == color.color_id}">
					<img class="a-image option-swatch color-block-img" src="{{colorBlock.img_block}}" title="{{colorBlock.text}}">
				</span>

			</li>
			
			<ng-container *ngFor="let colorBlock of list; index as i">
				<li class="m-c-block" (click)="onColorChange(colorBlock)" [ngClass]="{'hidden': (i > 3 && !showAllColor)}">
					<span [ngClass]="{'color-block-active':colorBlock.color_id == color.color_id}">
						<img class="a-image option-swatch color-block-img" src="{{colorBlock.img_block}}" title="{{colorBlock.text}}">
					</span>
				</li>		
			</ng-container>

			<li class="m-c-block" *ngIf="!showAllColor && !!list && list.length > 4">
				<button class="more-color-btn" type="button" (click)="moreColorBlock()">
					<span class="more-color-num">+ {{list.length  - 4}}</span>
					<svg class="more-color-svg"><title>向右</title><use href="/assets/iconSprite.svg#chevron-mini-right-end"></use></svg>
				</button>
			</li>
		</ul>
	</div>
</div>