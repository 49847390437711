
export class CustomerModel {
  // 用户信息
  // 用户电话
  public mobile: any;
  // 用户邮箱
  public email: any;
  public level: any;
  public points: any;
  public username: any;
  public yearCostAmount: any;
  public couponList: any;
  public pendingSales: any;
  constructor(data: {}) {
    Object.assign(this, data);
  }
  // 判断用户名称
  // // 判断性别id
  // DecideSex() {
  //   if (this.gender === 1) {
  //     return '男';
  //   } else {
  //     return '女';
  //   }
  // }
  // 判断用户消费

}
