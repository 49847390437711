import {Component, Input, OnInit} from '@angular/core';
import {CategoryService} from '../../../../services/category.service';
import {InitializeService} from '../../../../services/initialize.service';
import {ProductService} from '../../../../services/product.service';

@Component({
  selector: 'app-cms-block-category-lp',
  templateUrl: './cms-block-category-lp.component.html',
  styleUrls: []
})
export class CmsBlockCategoryLpComponent implements OnInit {

  @Input() block:any;
  @Input()
	editMode!: boolean;

  public productModel: any = [];
  public isPc:boolean = true;

  constructor(
    public init: InitializeService,
    public categoryService: CategoryService,
    public productService: ProductService) {
  }

  ngOnInit() {
    if (!this.block.hasOwnProperty('data')) {
      this.block.data = {};
    }
    if (!this.block.data.hasOwnProperty('config')) {
      this.block.data.config = {
        rows: 'threeLines',
      };
    }
    if (!this.block.data.config.hasOwnProperty('isSpecial')) {
      this.block.data.config.isSpecial = '0';
    }
    if (this.block.data.hasOwnProperty('cid')) {
      if (!this.block.data.count) {
        this.block.data.count = 12;
      }
      this.getProductList();
    }

    this.isPc = this.init.IsPC();
  }

  refresh() {
    this.getProductList();
  }


  getProductList() {
    this.productModel = [];
    if (this.block.data.cid) {
      this.productService.getGoodsItems(this.init.merchantId, this.init.cloudStoreId, this.block.data.cid, this.block.data.count).then((data:any) => {
        if (data['code'] === 1000) {
			this.productService.afterPage = data['data']['afterPage'];
			this.productModel = data['data']['content'];
        }
      });
    }
  }
}
