import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../../services/payment.service';
import {OrderService} from '../../services/order.service';
import {InitializeService} from '../../services/initialize.service';
import {isPlatformBrowser} from '@angular/common';
import {StorageService} from '../../services/storage.service';
import {TrackingcodeService} from '../../services/trackingcode.service';
import {AbTrackingService} from '../../services/abtracking.service';
import {ProductService} from '../../services/product.service';
@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit, OnDestroy {
  public incrementId:any;
  private checkPaymentTimer:any;
  public startCheckTime:any;
  public isMobilePayment: Boolean = false;
  public isWechatBrowser!: Boolean;
  public paymentStatus = true;
  public paymentFailure = true;
  public collectCouponStart = '2020-10-21 00:00:00';
  public collectCouponEnd = '2020-11-11 00:00:00';
  public showWeixinQr :boolean = false;
  public orderData:any;
  public orderStep:any;
  public paymentStage:any;
  constructor(
    public router: Router,
    public orderService: OrderService,
    public paymentService: PaymentService,
    private tracking: TrackingcodeService,
    public routerInfo: ActivatedRoute,
    private init: InitializeService,
    private localStorage:StorageService,
    public abTracking: AbTrackingService,
	 private productService:ProductService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.init.setTitle('结算');
    if (isPlatformBrowser(this.platformId)) {
      // 判断是否显示关注公众号二维码
      const startTime = Date.parse(this.collectCouponStart.replace(/-/g, '/'));
      const endTime = Date.parse(this.collectCouponEnd.replace(/-/g, '/'));
      const timestamp = (new Date()).getTime();
      if (timestamp >= startTime && timestamp < endTime) {
          this.showWeixinQr =  true;
      }
      if(this.routerInfo.snapshot.queryParams['orderType'] != null){
        this.orderStep = this.routerInfo.snapshot.queryParams['orderType'];
        this.paymentStage = this.routerInfo.snapshot.queryParams['paymentStage'];
      }

      this.isMobilePayment = !this.isPC();
      const ua = navigator.userAgent.toLowerCase();
      this.isWechatBrowser = (/MicroMessenger/i.test(ua));
      this.routerInfo.params.subscribe(params => {
        if(params.id.indexOf('orderType') !== -1){
          this.incrementId = params.id.substr(0,params.id.indexOf('?'));
        }else{
          this.incrementId = params.id;
        }

        if(this.localStorage.getItem('h5precreate_'+this.incrementId)){
          this.localStorage.removeItem('h5precreate_'+this.incrementId);
        }
        if (this.isMobilePayment) {
          this.paymentStatus = false;
          this.getPayment();
        }else{
          this.orderService.getCloudOrder(this.incrementId).then(result => {
            if (result.code * 1 === 1000) {
              if(result.data.status === 'WAIT_SELLER_SEND_GOODS'){
                this.orderData = result.data;
                this.tracking.complete(this.orderData);

                this.abTracking.trackingOrder( 'PAY_SUCCESS', this.orderData.orderId,
                  this.orderData.items, this.orderData.payment, this.orderData.postFee, this.orderData.discountFee, this.orderData.itemTotal,
                  {path: this.router.url});
				// 新版Ga Tracking Js
				this.gaTrackingConfirmOrder();
              }
            }
          }).catch(error => error);
        }
      });
    }
  }

  ngOnDestroy() {
    clearInterval(this.checkPaymentTimer);
  }

  getPayment() {
    this.orderService.getCloudOrder(this.incrementId).then(result => {
      if (result.code * 1 === 1000) {
		if(this.isMobilePayment){
			if(result.data.status === 'WAIT_BUYER_PAY'){
				this.paymentStatus = false;
			}else if(result.data.status === 'WAIT_SELLER_SEND_GOODS'){
				this.paymentStatus = true;
			}
		}
        clearInterval(this.checkPaymentTimer);
      } else {
        clearInterval(this.checkPaymentTimer);
        this.router.navigate(['/my-account/order']);
      }
    }).catch(error => error);
    return;
  }

  checkPayment() {
    this.startCheckTime = new Date().valueOf();
    const that = this;
    that.checkPaymentTimer = setInterval( () => {
      if ((new Date().valueOf() - that.startCheckTime) > 5 * 60 * 1000) {
        clearInterval(that.checkPaymentTimer);
        return;
      }
      this.getPayment();
      return;
    }, 3000);
  }
  isPC() {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod', 'iPad'];
    let flag = true;
    for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false;
        break;
      }
    }
    return flag;
  }

  completePayment() {
    this.paymentService.getPayment(this.incrementId).then( result => {
      if (result.code * 1 === 1000 && result.data.status === 'WAIT_SELLER_SEND_GOODS') {
        this.paymentStatus = true;
        this.orderService.getCloudOrder(this.incrementId).then(result => {
          if (result.code * 1 === 1000) {
            this.orderData = result.data;
            this.tracking.complete(this.orderData);
            this.abTracking.trackingOrder( 'PAY_SUCCESS', this.orderData.orderId,
              this.orderData.items, this.orderData.payment, this.orderData.postFee, this.orderData.discountFee, this.orderData.itemTotal,
              {path: this.router.url});
			
			// 新版Ga Tracking Js
			this.gaTrackingConfirmOrder();
          }
        }).catch(error => error);
      } else {
        this.router.navigate(['/my-account/order']);
      }
    }).catch(error => error);
    return;
  }
  continuePayment() {
    this.paymentService.getPayment(this.incrementId).then( result => {
      if (result.code * 1 === 1000 && result.data.status === 'WAIT_SELLER_SEND_GOODS') {
        this.paymentFailure = false;
      }else {
        this.router.navigate(['/checkout/payment/'+this.incrementId]);
      }
    }).catch(error => error);
    return;
  }
  goHome() {
    this.router.navigate(['']);
    return;
  }
	
	
	gaTrackingConfirmOrder(){
		if (this.init.gaTrackingFlag) {
			// 向google推送支付成功订单信息
			const orderData = this.orderData;
			const itemsArray = [];
			const order_items = orderData.items;
			
			for (const i in order_items) {
				const orderitems = order_items[i];
				const propertiesObject = JSON.parse(orderitems.skuProperties);
				 
				var frontUrlPath = propertiesObject[0].frontUrlPath;
				var product_category = this.productService.getCategoryByFrontUrl(frontUrlPath);
				itemsArray.push({
					product_action: 'purchase',
					product_id: orderitems.itemCode,//款号
					product_article_id: propertiesObject[0].frontCode,//CC
					product_variant_id: orderitems.skuCode,//SKU
					product_name: orderitems.itemName,
					product_brand: 'COS',
					product_category:product_category,
					product_color:propertiesObject[0].valueName,
					product_price:orderitems.price,
					product_price_original:orderitems.markingPrice,
					product_price_type: orderitems.price < orderitems.markingPrice ? 'sale' : 'full price',
					product_size: propertiesObject[1].valueName,
					product_size_code: propertiesObject[1].valueId,
				});
			}
			
			var order = {
				order_id: orderData.orderCode,
				order_total: orderData.payment,
				order_subtotal: orderData.itemTotal,
				order_tax: Number(orderData.payment*0.13),
				order_shipping: orderData.postFee,
				order_payment_type: orderData.payType,
				order_shipping_type: 'SF',//是否默认为顺丰
				order_coupon_id:orderData.couponCode,
				order_coupon_value: orderData.couponFee,
			};
			this.tracking.gaTrackingConfirmOrder(itemsArray,order);
		}
	}
}
