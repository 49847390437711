import {
	Component,
	HostListener,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	Directive,
	ElementRef, AfterViewChecked
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitializeService } from '../../services/initialize.service';
import { CategoryService } from '../../services/category.service';
import { ProductService } from '../../services/product.service';
import { CategoryModel } from '../../models/category.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { VmService } from '../../services/vm.service';
import { StorageService } from '../../services/storage.service';
import { CmsService } from '../../services/cms.service';
import { Meta } from '@angular/platform-browser';
// import {ScriptService} from '../../services/script.service';
import $ from 'jquery';
import { TrackingcodeService } from '../../services/trackingcode.service';
import { NavigationService } from '../../services/navigation.service';
import { FavoriteService } from '../../services/favorite.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { CallComponentService } from '../../services/callcomponent.service';
import { Title } from '@angular/platform-browser';
// import {distinctUntilChanged,filter, map, tap} from 'rxjs/operators';
@Directive({
	selector: '[appTitleListener]'
})
@Component({
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [   // :enter is alias to 'void => *'
				style({ opacity: 0 }),
				animate(500, style({ opacity: 1 }))
			]),
			transition(':leave', [   // :leave is alias to '* => void'
				animate(500, style({ opacity: 0 }))
			])
		])
	],
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.css'],
	providers: [CategoryService]
})
export class CategoryListComponent implements OnInit, OnDestroy, AfterViewChecked {
	// PlaceHolderItems
	public placeHolderItems : any = [];
	public productModelArrres : any = [];
	public productIdTmp : any = [];
	public productModelObj : any = {};
	// 货币
	public currency! : string;
	public categoryId! : string;
	public categoryModel! : CategoryModel;
	public showHoverProductId : any;
	public showH5Filter = false;
	public page_id : any;

	public cmsBlock : any;
	public cmsBlockIdUnderCategoryName : any;
	public cmsBlockUnderCategoryName : any;
	public isVMLogin = 0;
	public listLoading = true;
	public categoryUrl : any;
	public vmZoom = 100;
	public vmProductModel : any = {};
	public bannerList : any = [];
	public viewChecking = false;
	public isPc = true;
	public cloudCategoryId : any;

	public favoriteList : any = { code: 1, data: '', msg: '' };
	public favoriteItemIds : any = [];
	public productModelArr : any;
	public categoryBreadcrumbs = true;
	public displayBackTopIcon : boolean = false;


	public favoriteType : any;
	public favoriteCode : any;
	public showFavoriteBox : boolean = false;

	constructor(
		public categoryService : CategoryService,
		private routerInfo : ActivatedRoute,
		private init : InitializeService,
		private product : ProductService,
		private cms : CmsService,
		@Inject(PLATFORM_ID) private platformId : Object,
		public vmService : VmService,
		private storage : StorageService,
		private router : Router,
		private tracking : TrackingcodeService,
		private el : ElementRef,
		public navigationService : NavigationService,
		private favoriteService : FavoriteService,
		private breadcrumbService : BreadcrumbService,
		private titleService : Title,
		public eventSubscribeService : CallComponentService,
		private metaService : Meta) {

	}
	// @HostListener('document:titleChange', ['$event.target.title'])
	// onTitleChange(title: string) {
	//   console.log('Page title changed to:', title);
	// }

	ngOnInit() {
		this.isPc = this.init.IsPC();
		// vm是否预览状态
		this.categoryService.isVmPreview = this.storage.getItem('vmToPreview');
		// 货币
		this.currency = this.init.currency;
		this.routerInfo.params.subscribe(params => {
			this.eventSubscribeService.noneBreadcrumb.next('PLP');
			this.bannerList = [];
			this.viewChecking = false;
			this.categoryService.categoryUrl = this.router.url;
			this.categoryUrl = this.router.url.substring(1, this.router.url.length - 5);
			this.listLoading = true;
			const path = params['id'];
			if (path.search('women') !== -1) {
				this.metaService.updateTag({ name: 'keywords', content: 'COS女装' });
				this.metaService.updateTag({ name: 'description', content: 'COS官网可以在线购买女装，包括当季新款女装连衣裙、针织衫、外套和夹克、长裤、上衣、T恤、衬衫、牛仔裤等' });
			} else if (path.search('men') !== -1) {
				this.metaService.updateTag({ name: 'keywords', content: 'COS男装' });
				this.metaService.updateTag({ name: 'description', content: 'COS官网可以在线购买男装，包括当季新款男装牛仔裤、针织衫、外套和夹克、长裤、西装、T恤、衬衫等' });
			} else if (path.search('Kids') !== -1) {
				this.metaService.updateTag({ name: 'keywords', content: 'COS童装' });
				this.metaService.updateTag({ name: 'description', content: 'COS官网可以在线购买童装，包括当季新款男童、女童、针织衫、上衣、连衣裙、半身裙、长裤、配饰等' });
			} else if (path.search('kids-and-baby') !== -1) {
				return this.router.navigate(['/']);
			}
			
			
			
			
			this.categoryService.getUrlPathInfo(path).then((pathData : any) => {
				if (pathData['code'] !== 1000 || pathData['data']['urlType'] !== 'navigation') {
					return this.router.navigate(['/404']);
				}
				const nav_id = pathData['data']['foreignId'];
				this.navigationService.getNavigationByNavId(nav_id).then((navData : any) => {
					if (isPlatformBrowser(this.platformId)) {
						this.cmsBlockIdUnderCategoryName = JSON.parse(navData['parameters']).cmsBlockIdUnderCategoryName
						if (navData['navType'] === 'page') {
							this.categoryModel = JSON.parse(navData['parameters']);

							this.page_id = this.categoryModel.cmsPageId;
							this.listLoading = false;
							//const listScrollTop = this.storage.getItem('listScrollTop');
							//setTimeout(() => {
							//  window.scrollTo(0, listScrollTop);
							//}, 500);
							this.tracking.listing(this.categoryModel);
						} else if (navData['navType'] === 'category') {
							const id = navData['foreignId'];
							const cache = this.storage.getItem('productItemList');
							const cacheCatId = this.storage.getItem('categoryId');
							// 返回页面顶部
							if (isPlatformBrowser(this.platformId)) {
								if (!cacheCatId || cacheCatId !== id || !cache) {
									window.scrollTo(0, 0);
								}
							}
							if (cache && cacheCatId && cacheCatId.toString() === id.toString()) {
								const listScrollTop = this.storage.getItem('listScrollTop');
								this.categoryModel = JSON.parse(this.storage.getItem('categoryModel'));
								this.categoryId = this.storage.getItem('categoryId');
								this.cloudCategoryId = this.storage.getItem('categoryId');
								this.categoryService.productList = JSON.parse(this.storage.getItem('productList'));
								this.categoryService.categoryTree = JSON.parse(this.storage.getItem('categoryTree'));
								if (this.storage.getItem('cmsBlock') !== null) {
									this.cmsBlock = this.storage.getItem('cmsBlock');
								} else {
									this.cmsBlock = null;
								}

								if (this.storage.getItem('cmsBlockUnderCategoryName') !== null) {
									this.cmsBlockUnderCategoryName = this.storage.getItem('cmsBlockUnderCategoryName');
								} else {
									this.cmsBlockUnderCategoryName = null;
								}
                this.categoryService.loadedCount = this.storage.getItem('loadedCount');
                this.categoryService.productNum = this.storage.getItem('productNum');
                this.categoryService.moreBackground = this.storage.getItem('moreBackground');

								this.categoryService.sortCode = this.storage.getItem('sortCode');
								if (this.storage.getItem('showSortFlag') === 'true') {
									this.categoryService.showSortFlag = true;
								} else if (this.storage.getItem('showSortFlag') === 'false') {
									this.categoryService.showSortFlag = false;
								}
								this.categoryService.pageNum = this.storage.getItem('pageNum');
                this.categoryService.afterPage = this.storage.getItem('afterPage');
								this.categoryService.sortFilter = this.storage.getItem('sortFilter');
								this.categoryService.filterFlag = this.storage.getItem('filterFlag');
								this.categoryService.allColor = JSON.parse(this.storage.getItem('allColor'));
								this.categoryService.allSize = JSON.parse(this.storage.getItem('allSize'));
								this.categoryService.allTheme = JSON.parse(this.storage.getItem('allTheme'));
								this.categoryService.allQuality = JSON.parse(this.storage.getItem('allQuality'));
								this.categoryService.validFilterColor = JSON.parse(this.storage.getItem('validFilterColor'));
								this.categoryService.validFilterSize = JSON.parse(this.storage.getItem('validFilterSize'));
								this.categoryService.validFilterTheme = JSON.parse(this.storage.getItem('validFilterTheme'));
								this.categoryService.validFilterQuality = JSON.parse(this.storage.getItem('validFilterQuality'));
								this.categoryService.lockFilterType = this.storage.getItem('lockFilterType');
								this.categoryService.colorFilterSelected = JSON.parse(this.storage.getItem('colorFilterSelected'));
								this.categoryService.colorIdFilterSelected = JSON.parse(this.storage.getItem('colorIdFilterSelected'));
								this.categoryService.sizeFilterSelected = JSON.parse(this.storage.getItem('sizeFilterSelected'));
								this.categoryService.sizeIdFilterSelected = JSON.parse(this.storage.getItem('sizeIdFilterSelected'));
								this.categoryService.themeFilterSelected = JSON.parse(this.storage.getItem('themeFilterSelected'));
								this.categoryService.themeIdFilterSelected = JSON.parse(this.storage.getItem('themeIdFilterSelected'));
								this.categoryService.qualityFilterSelected = JSON.parse(this.storage.getItem('qualityFilterSelected'));
								this.categoryService.qualityIdFilterSelected = JSON.parse(this.storage.getItem('qualityIdFilterSelected'));
								this.categoryService.productItemList = JSON.parse(this.storage.getItem('productItemList'));
								this.categoryService.shopSizeVals = JSON.parse(this.storage.getItem('shopSizeVals'));
								this.categoryService.simpleSizeArr = JSON.parse(this.storage.getItem('simpleSizeArr'));

								this.categoryService.priceFilterSelected = JSON.parse(this.storage.getItem('priceFilterSelected'));
								this.categoryService.showPriceSlider = this.storage.getItem('showPriceSlider');
								this.categoryService.priceSelectedEnd = this.storage.getItem('priceSelectedEnd');
								this.categoryService.priceMin = Number(this.storage.getItem('priceMin'));
								this.categoryService.priceMax = Number(this.storage.getItem('priceMax'));
								this.categoryService.priceFilterRange = JSON.parse(this.storage.getItem('priceFilterRange'));

								if (this.storage.getItem('selectedSize')) {
									this.categoryService.shopBySizeIdFilterSelected = JSON.parse(this.storage.getItem('shopBySizeIdFilterSelected'));
								}

								if (this.storage.getItem('loadLock') === 'true') {
									this.categoryService.loadLock = true;
								} else if (this.storage.getItem('loadLock') === 'false') {
									this.categoryService.loadLock = false;
								}
								if (this.storage.getItem('noData') === 'true') {
									this.categoryService.noData = true;
								} else if (this.storage.getItem('noData') === 'false') {
									this.categoryService.noData = false;
								}
								this.removeStorage();
								if (isPlatformBrowser(this.platformId)) {
									setTimeout(() => {
										if (this.cmsBlockUnderCategoryName) {
											$('#blockUnderCategoryName').html(this.cmsBlockUnderCategoryName);
										} else {
											$('#blockUnderCategoryName').html('');
										}


										if (this.cmsBlock) {
											$('#categoryTopBlock').html(this.cmsBlock);
										} else {
											$('#categoryTopBlock').html('');
										}

										for (const i in this.categoryService.colorIdFilterSelected) {
											$('#connected_color_' + this.categoryService.colorIdFilterSelected[i]).attr('checked', 'checked');
										}
										for (const i in this.categoryService.sizeIdFilterSelected) {
											$('#connected_size_' + this.categoryService.sizeIdFilterSelected[i]).attr('checked', 'checked');
										}
										for (const i in this.categoryService.themeIdFilterSelected) {
											$('#connected_theme_' + this.categoryService.themeIdFilterSelected[i]).attr('checked', 'checked');
										}
										for (const i in this.categoryService.qualityIdFilterSelected) {
											$('#connected_quality_' + this.categoryService.qualityIdFilterSelected[i]).attr('checked', 'checked');
										}
										for (const i in this.categoryService.allColor) {
											$('#connected_color_' + this.categoryService.allColor[i].id).addClass('is-disabled');
											$('#connected_color_' + this.categoryService.allColor[i].id).attr('disabled', 'disbaled');
											for (const j in this.categoryService.validFilterColor) {
												if (this.categoryService.allColor[i].id === this.categoryService.validFilterColor[j].id) {
													$('#connected_color_' + this.categoryService.allColor[i].id).removeClass('is-disabled');
													$('#connected_color_' + this.categoryService.allColor[i].id).removeAttr('disabled');
												}
											}
										}
										for (const i in this.categoryService.allSize) {
											$('#connected_size_' + this.categoryService.allSize[i].id).addClass('is-disabled');
											$('#connected_size_' + this.categoryService.allSize[i].id).attr('disabled', 'disbaled');
											for (const j in this.categoryService.validFilterSize) {
												if (this.categoryService.allSize[i].id === this.categoryService.validFilterSize[j].id) {
													$('#connected_size_' + this.categoryService.allSize[i].id).removeClass('is-disabled');
													$('#connected_size_' + this.categoryService.allSize[i].id).removeAttr('disabled');
												}
											}
										}
										for (const i in this.categoryService.allTheme) {
											$('#connected_theme_' + this.categoryService.allTheme[i]).addClass('is-disabled');
											$('#connected_theme_' + this.categoryService.allTheme[i]).attr('disabled', 'disbaled');
											for (const j in this.categoryService.validFilterTheme) {
												if (this.categoryService.allTheme[i] === this.categoryService.validFilterTheme[j]) {
													$('#connected_theme_' + this.categoryService.allTheme[i]).removeClass('is-disabled');
													$('#connected_theme_' + this.categoryService.allTheme[i]).removeAttr('disabled');
												}
											}
										}
										for (const i in this.categoryService.allQuality) {
											$('#connected_quality_' + this.categoryService.allQuality[i]).addClass('is-disabled');
											$('#connected_quality_' + this.categoryService.allQuality[i]).attr('disabled', 'disbaled');
											for (const j in this.categoryService.validFilterQuality) {
												if (this.categoryService.allQuality[i] === this.categoryService.validFilterQuality[j]) {
													$('#connected_quality_' + this.categoryService.allQuality[i]).removeClass('is-disabled');
													$('#connected_quality_' + this.categoryService.allQuality[i]).removeAttr('disabled');
												}
											}
										}
										window.scrollTo(0, listScrollTop);
									}, 500);
								}
								if (this.categoryModel.categoryPromo * 1 === 1) {
									this.categoryService.getCategoryBanner(this.categoryModel.efsEntityId).then((result : any) => {
										if (result['code'] * 1 === 200) {
											this.bannerList = result['data'];
										}
									});
								}
								this.listLoading = false;
								this.tracking.listing(this.categoryModel);
							} else {
								this.initFilter();
								//清除尺码筛选缓存
								this.storage.removeItem('selectedSize');
								this.storage.removeItem('shopBySizeIdFilterSelected');
								this.categoryService.shopBySizeIdFilterSelected = [];

								this.categoryService.getCategory(id).then(model => {
									const category_data : any = navData;
									category_data.id = id;
									Object.assign(category_data, JSON.parse(navData['parameters']));
									this.init.setTitle(navData['navName']);

									//仅三级导航面包屑
									if (navData['level'] == 3) {
										const breadcrumbLen = this.breadcrumbService.breadcrumbsArray.length;
										if (breadcrumbLen > 0) {
											this.breadcrumbService.addChildCateNavBreadcrumb(navData);
										}
									}

									this.categoryModel = category_data;
									if (category_data.shopBySizeValues) {
										this.categoryModel.shopBySizeValues = category_data.shopBySizeValues.split(',');
									}
									// this.categoryModel.metaDescription = navData['metaDescription'];
									if (navData['level'] === 3) {
										this.navigationService.getNavigationByNavId(navData['parentNavId']).then((parNavData : any) => {
											const parParameters = JSON.parse(parNavData['parameters']);
											this.categoryModel.notShowChildren = parParameters.notShowChildren;
										});
									}
									// save for vm tools
									this.vmService.categoryModel = this.categoryModel;




									if (this.categoryModel.displayMode === 'PAGE') {
										this.page_id = this.categoryModel.cmsPageId;
										this.categoryService.loadLock = true;
									} else {
										if (this.categoryModel.displayMode === 'PRODUCTS_AND_PAGE') {
											this.cms.getBlockDataById(this.categoryModel.cmsPageId, true).then((data : any) => {
												this.cmsBlock = data['content'];
												if (isPlatformBrowser(this.platformId)) {
													$('#categoryTopBlock').html(this.cmsBlock);
												}
											});
										} else {
											if (isPlatformBrowser(this.platformId)) {
												$('#categoryTopBlock').html('');
											}

											if (this.cmsBlockIdUnderCategoryName) {
												this.cms.getBlockDataById(this.categoryModel.cmsBlockIdUnderCategoryName, true).then((data : any) => {
													this.cmsBlockUnderCategoryName = data['content'];
													if (isPlatformBrowser(this.platformId)) {
														$('#blockUnderCategoryName').html(this.cmsBlockUnderCategoryName);
													}
												});
											} else {
												if (isPlatformBrowser(this.platformId)) {
													$('#blockUnderCategoryName').html('');
												}
											}
										}
										this.cloudCategoryId = id;
										this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');

										if (this.categoryModel.categoryPromo * 1 === 1) {
											this.categoryService.getCategoryBanner(this.categoryModel.efsEntityId).then((result : any) => {
												if (result['code'] * 1 === 200) {
													this.bannerList = result['data'];
												}
											});
										}
									}

									this.listLoading = false;
									this.tracking.listing(this.categoryModel);
								}).catch(error => error);
								// }

								this.categoryId = id;
								this.categoryService.categoryTree = [];
								const tree_id = navData['level'] === 3 ? navData['parentNavId'] : navData['navId'];
								this.navigationService.getNavigationsByParent(tree_id).then((navTree : any) => {
									for (const i in navTree['content']) {
										if (navTree['content'][i]['visible'] === 1) {
											this.categoryService.categoryTree.push(navTree['content'][i]);
										}
									}
								});
								// save storage for vmtools
								this.vmService.category = this.categoryId;
								// validate is vm login
								if (this.storage.getItem('vmLogin')) {
									this.isVMLogin = 1;
								}
							}
						} else {
							$('#categoryTopBlock').html('');
						}


					}
				}).catch(error => error);
				return;
			}).catch(error => error);

			// console.log(this.bannerList, '===========')
			return;
		}
		);

		//自定义在线客服icon位置样式
		$('body').addClass('plp_udesk');

		//仅登录状态查收藏
		if (this.init.isLogin()) {
			this.favoriteService.clearFavoriteResult();
			this.favoriteItems();
		}
		//监听当前页面登录后用户状态
		this.eventSubscribeService.loginRegisterBox.subscribe((value : boolean) => {
			if (this.init.isLogin()) {
				this.favoriteService.clearFavoriteResult();
				this.favoriteItems();
			}
		});
	}

	// 初始化筛选排序
	initFilter() {
		this.categoryService.sortCode = '最佳匹配';
		this.categoryService.showSortFlag = false;
		this.categoryService.productList = [];
		this.categoryService.pageNum = 1;
    this.categoryService.afterPage = '';
    this.categoryService.loadedCount = 0;
    this.categoryService.productNum = 0;
    this.categoryService.moreBackground = null;
		this.categoryService.sortFilter = 0;
		this.categoryService.filterFlag = '';
		this.categoryService.allColor = [];
		this.categoryService.allSize = [];
		this.categoryService.allTheme = [];
		this.categoryService.allQuality = [];
		this.categoryService.validFilterQuality = [];
		this.categoryService.validFilterColor = [];
		this.categoryService.validFilterSize = [];
		this.categoryService.validFilterTheme = [];
		this.categoryService.lockFilterType = null;
		this.categoryService.colorFilterSelected = [];
		this.categoryService.colorIdFilterSelected = [];
		this.categoryService.sizeFilterSelected = [];
		this.categoryService.sizeIdFilterSelected = [];
		this.categoryService.themeFilterSelected = [];
		this.categoryService.themeIdFilterSelected = [];
		this.categoryService.qualityFilterSelected = [];
		this.categoryService.qualityIdFilterSelected = [];
		this.categoryService.showPriceSlider = false;
		this.categoryService.priceSelectedEnd = false;
		this.categoryService.priceFilterRange = [1, 10];
		this.categoryService.priceFilterSelected = [];
		this.categoryService.productItemList = {};
		this.cmsBlock = null;
		this.productModelArr = [];
		this.productModelObj = {};
		// this.productIdTmp = [];
		this.categoryService.loadLock = true;
		this.bannerList = [];
		this.viewChecking = false;
		// this.categoryService.categoryTree = [];
	}

	renderPlacehold(productId : any) {
		for (let i = 0; i < this.productIdTmp.length; i++) {
			if (this.productIdTmp[i] === productId) {
				return;
			}
		}
		this.productIdTmp.push(productId);
		const productIdArr = productId.split('-');
		this.product.getProductByItemId(productIdArr[0]).then((res : any) => {
			const item = this.categoryService.productItemList[productId];
			const val = res['data'];
			val.pdp_sizechart = null;
			val.childs = null;
			val.promotionTag = item.promotionTag;
			val.promotionTagList = item.promotionTagList;
			val.valueId = item.valueId;
			val.majorUrl = item.majorUrl;
			val.hoverUrl = item.hoverUrl;
			val.price = item.price;
			val.markingPrice = item.markingPrice;
			val.frontUrlPath = item.frontUrlPath;
			val.strategies = item.strategies;
			this.productModelArr[productId] = val;
			this.productModelObj[productId] = val;
			if (this.storage.getItem('vmLogin')) {
				this.vmProductModel[productId + '_id'] = val;
				this.storage.setItem('vmProductModelArr', JSON.stringify(this.vmProductModel));
			}
		}).catch(error => error);
	}

	toProductDetail() {
		this.removeStorage();
		const scrollTop = document.documentElement.scrollTop || window.scrollY || document.body.scrollTop;
		this.storage.setItem('listScrollTop', scrollTop);
		// this.storage.setItem('productModelObj', JSON.stringify(this.productModelObj));
		// this.storage.setItem('productIdTmp', JSON.stringify(this.productIdTmp));
		this.storage.setItem('productList', JSON.stringify(this.categoryService.productList));
		this.storage.setItem('categoryTree', JSON.stringify(this.categoryService.categoryTree));
		this.storage.setItem('categoryModel', JSON.stringify(this.categoryModel));
		this.storage.setItem('categoryId', this.cloudCategoryId);
    this.storage.setItem('loadedCount', this.categoryService.loadedCount);
    this.storage.setItem('productNum', this.categoryService.productNum);
    this.storage.setItem('moreBackground', this.categoryService.moreBackground);
		if (this.cmsBlockUnderCategoryName) {
			this.storage.setItem('cmsBlockUnderCategoryName', this.cmsBlockUnderCategoryName);
		}

		if (this.cmsBlock) {
			this.storage.setItem('cmsBlock', this.cmsBlock);
		}
		this.storage.setItem('sortCode', this.categoryService.sortCode);
		this.storage.setItem('showSortFlag', this.categoryService.showSortFlag);
		this.storage.setItem('pageNum', this.categoryService.pageNum);
    this.storage.setItem('afterPage', this.categoryService.afterPage);
		this.storage.setItem('sortFilter', this.categoryService.sortFilter);
		this.storage.setItem('filterFlag', this.categoryService.filterFlag);
		this.storage.setItem('allColor', JSON.stringify(this.categoryService.allColor));
		this.storage.setItem('allSize', JSON.stringify(this.categoryService.allSize));
		this.storage.setItem('allTheme', JSON.stringify(this.categoryService.allTheme));
		this.storage.setItem('allQuality', JSON.stringify(this.categoryService.allQuality));
		this.storage.setItem('validFilterColor', JSON.stringify(this.categoryService.validFilterColor));
		this.storage.setItem('validFilterSize', JSON.stringify(this.categoryService.validFilterSize));
		this.storage.setItem('validFilterTheme', JSON.stringify(this.categoryService.validFilterTheme));
		this.storage.setItem('validFilterQuality', JSON.stringify(this.categoryService.validFilterQuality));
		this.storage.setItem('lockFilterType', this.categoryService.lockFilterType);
		this.storage.setItem('colorFilterSelected', JSON.stringify(this.categoryService.colorFilterSelected));
		this.storage.setItem('colorIdFilterSelected', JSON.stringify(this.categoryService.colorIdFilterSelected));
		this.storage.setItem('sizeFilterSelected', JSON.stringify(this.categoryService.sizeFilterSelected));
		this.storage.setItem('sizeIdFilterSelected', JSON.stringify(this.categoryService.sizeIdFilterSelected));
		this.storage.setItem('themeFilterSelected', JSON.stringify(this.categoryService.themeFilterSelected));
		this.storage.setItem('themeIdFilterSelected', JSON.stringify(this.categoryService.themeIdFilterSelected));
		this.storage.setItem('qualityFilterSelected', JSON.stringify(this.categoryService.qualityFilterSelected));
		this.storage.setItem('qualityIdFilterSelected', JSON.stringify(this.categoryService.qualityIdFilterSelected));
		this.storage.setItem('productItemList', JSON.stringify(this.categoryService.productItemList));
		this.storage.setItem('loadLock', this.categoryService.loadLock);
		this.storage.setItem('noData', this.categoryService.noData);

		this.storage.setItem('priceFilterRange', JSON.stringify(this.categoryService.priceFilterRange));
		this.storage.setItem('priceFilterSelected', JSON.stringify(this.categoryService.priceFilterSelected));
		this.storage.setItem('showPriceSlider', this.categoryService.showPriceSlider);
		this.storage.setItem('priceSelectedEnd', this.categoryService.priceSelectedEnd);
		this.storage.setItem('priceMin', this.categoryService.priceMin);
		this.storage.setItem('priceMax', this.categoryService.priceMax);

		return;
	}
	removeStorage() {
		this.storage.removeItem('listScrollTop');
		this.storage.removeItem('productModelArr');
		this.storage.removeItem('productModelObj');
		// this.storage.removeItem('productIdTmp');
		this.storage.removeItem('productList');
		this.storage.removeItem('categoryTree');
		this.storage.removeItem('categoryModel');
		this.storage.removeItem('categoryId');
    this.storage.removeItem('loadedCount');
    this.storage.removeItem('productNum');
    this.storage.removeItem('moreBackground');
		this.storage.removeItem('cmsBlock');
		this.storage.removeItem('cmsBlockUnderCategoryName');
		this.storage.removeItem('sortCode');
		this.storage.removeItem('showSortFlag');
		this.storage.removeItem('pageNum');
    this.storage.removeItem('afterPage');
		this.storage.removeItem('sortFilter');
		this.storage.removeItem('filterFlag');
		this.storage.removeItem('allColor');
		this.storage.removeItem('allSize');
		this.storage.removeItem('allTheme');
		this.storage.removeItem('allQuality');
		this.storage.removeItem('validFilterColor');
		this.storage.removeItem('validFilterSize');
		this.storage.removeItem('validFilterTheme');
		this.storage.removeItem('validFilterQuality');
		this.storage.removeItem('lockFilterType');
		this.storage.removeItem('colorFilterSelected');
		this.storage.removeItem('colorIdFilterSelected');
		this.storage.removeItem('sizeFilterSelected');
		this.storage.removeItem('sizeIdFilterSelected');
		this.storage.removeItem('themeFilterSelected');
		this.storage.removeItem('themeIdFilterSelected');
		this.storage.removeItem('qualityFilterSelected');
		this.storage.removeItem('qualityIdFilterSelected');
		this.storage.removeItem('loadLock');
		this.storage.removeItem('noData');
		this.storage.removeItem('priceFilterRange');
		this.storage.removeItem('priceFilterSelected');
		this.storage.removeItem('showPriceSlider');
		this.storage.removeItem('priceSelectedEnd');
		this.storage.removeItem('priceMin');
		this.storage.removeItem('priceMax');
	}
	showSort(e : any) {
		if (this.categoryService.showSortFlag) {
			this.categoryService.showSortFlag = false;
		} else {
			this.categoryService.showSortFlag = true;
		}
		this.categoryService.filterFlag = '';
		if (e.stopPropagation) {
			e.stopPropagation();
		} else {
			e.cancelBubble = true;
		}
		return;
	}
	changeSort(val : any) {
		this.categoryService.sortCode = val;
		this.categoryService.showSortFlag = false;
		this.categoryService.productList = [];
		this.categoryService.pageNum = 1;
		this.categoryService.sortFilter = 1;
		if (val === '最新上架') {
			this.categoryService.sortFilter = 2;
		}
		if (val === '价格低至高') {
			this.categoryService.sortFilter = 3;
		}
		if (val === '价格高至低') {
			this.categoryService.sortFilter = 4;
		}
		this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');
	}
	showFilter(val : any, e : any) {
		this.categoryService.filterFlag = this.categoryService.filterFlag === val ? '' : val;
		this.categoryService.showSortFlag = false;
		if (e.stopPropagation) {
			e.stopPropagation();
		} else {
			e.cancelBubble = true;
		}
		return;
	}


	shopBySizeFilter(code : any) {
		let that = this;
		that.storage.setItem('selectedSize', code);
		that.tracking.clickFilterSize(that.cloudCategoryId + '-' + code);
		let sizeSet : any = this.categoryService.simpleSizeArr[code];
		if (sizeSet === undefined) {
			let sizeSetCache = JSON.parse(this.storage.getItem('simpleSizeArr'));
			for (var i = 0; i < sizeSetCache.length; i++) {
				if (code == sizeSetCache[i].key) {
					sizeSet = JSON.parse(sizeSetCache[i].value);
				}
			}
		}


		this.categoryService.shopBySizeIdFilterSelected = [];
		if (sizeSet.length > 0) {
			for (var j = 0; j < sizeSet.length; j++) {
				this.categoryService.shopBySizeIdFilterSelected.push(sizeSet[j]);
			}
		}
		this.storage.setItem('shopBySizeIdFilterSelected', JSON.stringify(this.categoryService.shopBySizeIdFilterSelected));
		this.categoryService.productList = [];
		this.categoryService.pageNum = 1;
		this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');
	}

	filterAttribute(type : any, id : any, code : any, e : any) {
		if ($('#connected_' + type + '_' + id).hasClass('is-disabled')) {
			return;
		}
		if (isPlatformBrowser(this.platformId)) {
			var mobileFilterButtons = document.getElementById('mobile-filter-buttons');
			if (mobileFilterButtons) {
				mobileFilterButtons.style.display = 'block';
			}

		}
		this.categoryService.lockFilterType = type;
		let flag = true;
		if (type === 'color') {
			for (const i in this.categoryService.colorFilterSelected) {
				if (this.categoryService.colorFilterSelected[i] === code) {
					flag = false;
					this.categoryService.colorFilterSelected.splice(i, 1);
					this.categoryService.colorIdFilterSelected.splice(i, 1);
				}
			}
			if (flag) {
				this.categoryService.colorFilterSelected.push(code);
				this.categoryService.colorIdFilterSelected.push(code);
			}
		}
		if (type === 'size') {
			for (const i in this.categoryService.sizeFilterSelected) {
				if (this.categoryService.sizeFilterSelected[i] === code) {
					flag = false;
					this.categoryService.sizeFilterSelected.splice(i, 1);
					this.categoryService.sizeIdFilterSelected.splice(i, 1);
				}
			}
			if (flag) {
				this.categoryService.sizeFilterSelected.push(code);
				this.categoryService.sizeIdFilterSelected.push(code);
			}
		}
		if (type === 'theme') {
			for (const i in this.categoryService.themeFilterSelected) {
				if (this.categoryService.themeFilterSelected[i] === code) {
					flag = false;
					this.categoryService.themeFilterSelected.splice(i, 1);
					this.categoryService.themeIdFilterSelected.splice(i, 1);
				}
			}
			if (flag) {
				this.categoryService.themeFilterSelected.push(code);
				this.categoryService.themeIdFilterSelected.push(id);
			}
		}
		if (type === 'quality') {
			for (const i in this.categoryService.qualityFilterSelected) {
				if (this.categoryService.qualityFilterSelected[i] === code) {
					flag = false;
					this.categoryService.qualityFilterSelected.splice(i, 1);
					this.categoryService.qualityIdFilterSelected.splice(i, 1);
				}
			}
			if (flag) {
				this.categoryService.qualityFilterSelected.push(code);
				this.categoryService.qualityIdFilterSelected.push(id);
			}
		}
		if (e.stopPropagation) {
			e.stopPropagation();
		} else {
			e.cancelBubble = true;
		}
		this.categoryService.productList = [];
		this.categoryService.pageNum = 1;
		this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');
	}

	filterPrice(value : any) {
		if (!(this.categoryService.priceFilterSelected && this.categoryService.priceFilterSelected.length > 0 && this.categoryService.priceFilterSelected[0] == value[0] && this.categoryService.priceFilterSelected[1] == value[1])) {
			this.categoryService.priceSelectedEnd = true;
			this.categoryService.priceFilterRange = value;
			this.categoryService.priceFilterSelected = value;

			this.categoryService.productList = [];
			this.categoryService.pageNum = 1;
			this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');
		}
	}

	canClearAll() {
		return this.categoryService.colorIdFilterSelected.length === 0 &&
			this.categoryService.sizeIdFilterSelected.length === 0;
	}
	clearAll() {
		this.showH5Filter = false;
		this.categoryService.filterFlag = '';
		this.categoryService.colorFilterSelected = [];
		this.categoryService.colorIdFilterSelected = [];
		this.categoryService.sizeFilterSelected = [];
		this.categoryService.sizeIdFilterSelected = [];
		this.categoryService.themeFilterSelected = [];
		this.categoryService.themeIdFilterSelected = [];
		this.categoryService.qualityFilterSelected = [];
		this.categoryService.qualityIdFilterSelected = [];
		this.categoryService.validFilterQuality = [];
		this.categoryService.validFilterColor = [];
		this.categoryService.validFilterSize = [];
		this.categoryService.validFilterTheme = [];
		this.categoryService.lockFilterType = null;
		this.categoryService.productList = [];
		this.categoryService.pageNum = 1;
		this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');
	}
	bak(e : any) {
		if (e.stopPropagation) {
			e.stopPropagation();
		} else {
			e.cancelBubble = true;
		}
		return;
	}
	backToTop() {
		setTimeout(() => {
			this.displayBackTopIcon = false;
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			}
			);
		}, 500);
	}

	loadMore(){
		if (!this.categoryService.loadLock) {
			this.categoryService.loadLock = true;
			// 加载下一页的loading
			this.categoryService.loading = true;
			this.categoryService.pageNum++;
			this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');
		}

	}
	@HostListener('window:scroll', ['$event'])
	onScroll() {
		if (isPlatformBrowser(this.platformId)) {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			const clientHeight = document.documentElement.clientHeight;
			if (scrollTop > clientHeight) {
				this.displayBackTopIcon = true;
			} else {
				this.displayBackTopIcon = false;
			}

			const productBlockDom = this.el.nativeElement.querySelectorAll('.category-list-product-block');
			const lastProductBlockDom = productBlockDom[productBlockDom.length - 1];
			if (!lastProductBlockDom) {
				return;
			}
			// const domBottom = lastProductBlockDom.getBoundingClientRect().bottom;
			// if (domBottom < clientHeight && !this.categoryService.loadLock) {
			// 	this.categoryService.loadLock = true;
			// 	// 加载下一页的loading
			// 	this.categoryService.loading = true;
			// 	this.categoryService.pageNum++;
			// 	this.categoryService.getCloudCategoryList(this.cloudCategoryId, '');
			// }
		}
	}
	@HostListener('document:click', ['$event'])
	//@HostListener('document:click', ['$event.target'])
	//  onClick(btn) {
	//  const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
	//  this.storage.setItem('listScrollTop', scrollTop);
	//  }
	closeLayer() {
		this.categoryService.filterFlag = '';
		this.categoryService.showSortFlag = false;
	}
	ngOnDestroy() {
		//离开页面移除在线客服自定义样式
		$('body').removeClass('plp_udesk');

		this.initFilter();
		this.metaService.updateTag({ name: 'keywords', content: 'COS官网,COS女装,COS男装,COS童装,COS品牌,' });
		this.metaService.updateTag({ name: 'description', content: 'COS专注于为女士和男士带来现代、实用、精巧的设计。在COS官网可以在线购买女装、男装、童装、鞋包、配饰等。' });
	}
	ngAfterViewChecked() {
		if (this.el.nativeElement.querySelectorAll('.category-list-product-block').length === 0 || this.bannerList.length === 0 || this.viewChecking) {
			return;
		}
		this.viewChecking = true;

		// console.log(this.bannerList,'=======this.bannerList')
		let hasUnappendBanner = 0;
		for (const i in this.bannerList) {
			if (this.bannerList[i].appended) {
				continue;
			}
			let newHtml = '';
			let className = 'categoryBanner';
			const container = JSON.parse(this.bannerList[i].containers);
			for (const j in container) {
				for (const x in container[j]) {
					newHtml += container[j][x].html;
					if (container[j][x]['class'] == 'col-md-4') {
						className = 'categoryBanner3';
					}
				}
			}
			let html = '<div class="column ' + className + '">';
			html += '<div class="o-product" id="' + this.bannerList[i].identifier + '">' + newHtml;
			html += '</div></div>';
			const position = this.bannerList[i].position * 1;
			const productBlockDom = this.el.nativeElement.querySelectorAll('.category-list-product-block');
			if (position < 0) {
				$('.category-list-product-block:last').after(html);
				this.bannerList[i].appended = true;
			} else {
				if (position <= productBlockDom.length) {
					$('.category-list-product-block:eq(' + (position - 1) + ')').before(html);
					this.bannerList[i].appended = true;
				} else {
					hasUnappendBanner++;
				}
			}
		}
		if (this.categoryService.noData) {
			for (const i in this.bannerList) {
				if (this.bannerList[i].appended) {
					continue;
				}
				let newHtml = '';
				let className = 'categoryBanner';
				const container = JSON.parse(this.bannerList[i].containers);
				for (const j in container) {
					for (const x in container[j]) {
						newHtml += container[j][x].html;
						if (container[j][x]['class'] == 'col-md-4') {
							className = 'categoryBanner3';
						}
					}
				}
				let html = '<div class="column ' + className + '">';
				html += '<div class="o-product" id="' + this.bannerList[i].identifier + '">' + newHtml;
				html += '</div></div>';
				$('.category-list-product-block:last').after(html);
				this.bannerList[i].appended = true;
			}
		}
		if (hasUnappendBanner > 0) {
			this.viewChecking = false;
		}
	}

	vmZoomToggle(module : number) {
		this.vmZoomSwitch(module);
		const zoomClass : any = document.querySelector('#products');
		zoomClass.className = '';
		zoomClass.className += 'vmListing_zoom' + this.vmZoom;
		if (this.vmZoom !== 100) {
			zoomClass.className += ' vmListing_zoom';
		}
	}

	vmZoomSwitch(module : number) {
		switch (this.vmZoom) {
			case 15:
				if (module === 1) { this.vmZoom = 15; } else { this.vmZoom = 30; }
				break;
			case 30:
				if (module === 1) { this.vmZoom = 15; } else { this.vmZoom = 100; }
				break;
			case 100:
				if (module === 1) { this.vmZoom = 30; } else { this.vmZoom = 100; }
				break;
			default:
				this.vmZoom = 100;
		}
	}

	vmMerchSure(validate : number) {
		switch (validate) {
			case 0:
				this.vmService.validate = false;
				break;
			case 1:
				this.vmService.category = this.categoryModel.efsEntityId;
				this.vmService.updateMerchToolsBannerRanking().then(
					(result : any) => {
						if (result['message'] === 'ok') {
							alert('更新成功');
							return false;
						} else {
							alert('更新失败，请稍后重新操作');
						}
						return;
					}).catch(error => error);
				this.vmService.validate = false;
				break;
			default:
				this.vmService.validate = false;
		}

	}

	vmToPreview(type : boolean) {
		if (this.isVMLogin) {
			if (type) {
				this.storage.setItem('vmToPreview', 1);
			} else {
				this.storage.setItem('vmToPreview', 0);
				if (isPlatformBrowser(this.platformId)) {
					window.location.reload();
				}
			}
		}
	}

	favoriteItems() {
		this.favoriteService.getFavoriteIds().then(val => {
			this.favoriteList = val;
			if (this.favoriteList.code == 1000) {
				if (this.favoriteList.data.length > 0) {
					this.favoriteList.data.forEach((item : any) => {
						this.favoriteItemIds[item.itemId + '_' + item.valueId] = item.favoritesId;
					});
				}
			}
		});
	}
	scrollToRight() {
		if (!!$('#nav-container')) {
			var scrollLeft = $('#nav-container').scrollLeft();
			$('#toleft').show();
			if (!!scrollLeft && scrollLeft > 0) {
				var scrollWidth = $('#nav-container').width();
				if (!!scrollWidth) {
					if (scrollLeft + 360 >= scrollWidth) {
						$('#toleft').show();
						$('#toright').hide();
					}
				}
				$('#nav-container').animate({ scrollLeft: scrollLeft + 360 }, 'slow');
			} else {
				$('#nav-container').animate({ scrollLeft: 360 }, 'slow');
			}
		}

	}

	scrollToLeft() {
		if (!!$('#nav-container')) {
			var scrollLeft = $('#nav-container').scrollLeft();
			$('#toright').show();
			if (!!scrollLeft && scrollLeft > 0) {
				if (scrollLeft - 360 <= 0) {
					$('#toright').show();
					$('#toleft').hide();
				}
				$('#nav-container').animate({ scrollLeft: scrollLeft - 360 }, 'slow');
			}
		}
	}



}