import {AfterViewInit, Component, ElementRef, HostListener, Inject, Input, OnChanges, OnInit, PLATFORM_ID} from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap/carousel';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../../services/product.service';
import {ProductModel} from '../../../models/product.model';
import {isPlatformBrowser} from '@angular/common';
import {InitializeService} from '../../../services/initialize.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ])
    ])
  ],
  selector: 'app-product-cosbyyou',
  templateUrl: './product-cosbyyou.component.html',
  styleUrls: ['./product-cosbyyou.component.css'],
  providers: [
    {provide: CarouselConfig, useValue: {interval: 4000, noPause: true}},
  ]
})
export class ProductCosbyyouComponent implements OnInit, AfterViewInit, OnChanges {
  public productModel: ProductModel | undefined;
  // 图片集合
  @Input() ImageArray: any;
 

  // hover
  hover: string | undefined;
  left: Number | undefined;
  top: Number | undefined;
  public selectedImg: any;
  public mySwiperConfig: any;
  public imageList = [];
  public isPc = true;
  constructor(
    private init: InitializeService,
    private routerInfo: ActivatedRoute,
    public product: ProductService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private el: ElementRef) {
  }
  mbSwiperConfig: SwiperConfigInterface | undefined;
  ngOnChanges() {
	this.isPc = this.init.IsPC();
    this.imageList = this.ImageArray;
  }
  ngOnInit() {
		this.mbSwiperConfig = {
		  direction: 'horizontal',
		  loop: true,
		  slidesPerView:'auto',
		  spaceBetween:5,
		  initialSlide:0,
		  centeredSlides:false,
		  slidesPerGroup: 1,
		  observer: true,
		  observeParents: true,
		  threshold:1,
		};
  }
  ngAfterViewInit() {
  }
}
