import {Component, OnInit, PLATFORM_ID, Inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {OrderService} from '../../services/order.service';
import $ from 'jquery';
import {isPlatformBrowser} from '@angular/common';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-logistics',
  templateUrl: './logistics.component.html',
  styleUrls: ['./logistics.component.css']
})
export class LogisticsComponent implements OnInit{
  public orderId:any = '';
  public trackingNumber!: string;
  public expressName: any;
  public logisticsInfo:any = {'tracks':''};
  public expressCode:any = '';
  public orderLogistics:any = [];
  public activeId :number = -1;

  public buttonDisabled :boolean = false;
  public timeOutEvent: any;
  public copyContent: any;
  constructor(
    public init: InitializeService,
    public orderService: OrderService,
    private localStorage:StorageService,
    private routerIonfo: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object) {

  }

  ngOnInit() {
    this.init.pageConfiguration('物流查询');
    if (isPlatformBrowser(this.platformId)) {
      if (this.routerIonfo.snapshot.queryParams['orderId'] != null) {
        this.orderId = this.routerIonfo.snapshot.queryParams['orderId'];
      }
      this.getExpress(this.orderId);
    }
  }


  getExpress(orderId: string){
    this.orderService.getLogistics(orderId).then(val => {
      if (val.code === 1000) {
        const logistics = val.data;
        for (const key of Object.keys(logistics)) {
          if (logistics.hasOwnProperty(key) && logistics[key] !== false) {
            this.trackingNumber = logistics[key].trackingNumber;
            this.expressName = logistics[key].expressName;
            this.orderLogistics.push(logistics[key]);
          }
        }
        //console.log(this.orderLogistics);
      }
    }).catch( error => error);
  }

  showLogistics(data:any,index = -1){
      if(this.init.IsPC()){
        this.orderService.showLogistics = true;
        this.orderService.show_modal = true;
      }else{
        if(this.activeId == index){
          this.activeId = -1;
        }else{
          this.activeId = index;
        }
        if($('#'+data.trackingNumber+'-content').css('display') === 'block'){
          $('#'+data.trackingNumber+'-content').css('display','none');
          return ;
        }
      }
      this.expressCode = data.expressCode;
      this.expressName = data.expressName;
      this.trackingNumber = data.trackingNumber;
      if(!!this.localStorage.getItem(data.trackingNumber+'-detail')){
        this.logisticsInfo = JSON.parse(this.localStorage.getItem(data.trackingNumber+'-detail'));
        if(!this.init.IsPC()){
          $('#'+data.trackingNumber+'-content').css('display','block');
        }
      }else{
        //调快递100接口
        if(this.expressCode && this.trackingNumber){
          this.orderService.logisticsTracking(this.expressCode,this.trackingNumber,this.orderId).then(res => {
            if(res.code === 1000 && !!res.data){
              this.logisticsInfo = res.data;
              this.localStorage.setItem(data.trackingNumber+'-detail',JSON.stringify(res.data));
              if(!this.init.IsPC()){
                $('#'+data.trackingNumber+'-content').css('display','block');
              }
            }
          }).catch(error => error);
        }
      }
  }

  copyContentH5(content: string) {
  var copyDom = document.createElement('div');
  copyDom.innerText=content;
  copyDom.style.position='absolute';
  copyDom.style.top='0px';
  copyDom.style.right='-9999px';
  document.body.appendChild(copyDom);
  //创建选中范围
  var range = document.createRange();
  range.selectNode(copyDom);
  //移除剪切板中内容
  window.getSelection()?.removeAllRanges();
  //添加新的内容到剪切板
  window.getSelection()?.addRange(range);
  //复制
  var successful = document.execCommand('copy');
  copyDom.parentNode?.removeChild(copyDom);
  try{
    var msg = successful ? "复制成功" : "failed";
    alert(msg);
    console.log('Copy command was : ' + msg);
  } catch(err){
    console.log('Oops , unable to copy!');
  }
}
}
