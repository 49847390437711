
    <div [ngSwitch]="block.type">
      <div class="editors" *ngSwitchCase="'customHtml'">
        <textarea [(ngModel)]="block.html" placeholder="请填写自定义HTML标签"></textarea>
      </div>
      <div class="editors" *ngSwitchCase="'textEditor'">
        <ckeditor [(ngModel)]="block.html" [config]="ckeConfig" debounce="500"
                  (change)="onChange($event)"></ckeditor>
      </div>
      <div class="editors" *ngSwitchCase="'singleProduct'">
        <app-cms-block-single-product-editor [block]="block"></app-cms-block-single-product-editor>
      </div>
      <div class="editors" *ngSwitchCase="'multiProduct'">
        <app-cms-block-multi-product-editor [block]="block"></app-cms-block-multi-product-editor>
      </div>
      <div class="editors" *ngSwitchCase="'arrivalReminderProduct'">
        <app-cms-block-arrival-reminder-product-editor [block]="block"></app-cms-block-arrival-reminder-product-editor>
      </div>
      <div class="editors" *ngSwitchCase="'swiper'">
        <app-cms-block-swiper-editor [block]="block"></app-cms-block-swiper-editor>
      </div>
      <div class="editors" *ngSwitchCase="'reusable'">
        <app-cms-block-reusable-editor [block]="block"></app-cms-block-reusable-editor>
      </div>
      <div class="editors" *ngSwitchCase="'lp'">
        <app-cms-block-lp-editor [block]="block"></app-cms-block-lp-editor>
      </div>
      <div class="editors" *ngSwitchCase="'cloudLp'">
        <app-cms-block-category-lp-editor [block]="block"></app-cms-block-category-lp-editor>
      </div>
      <div class="editors" *ngSwitchCase="'cloudTab'">
        <app-cms-block-tab-editor [block]="block"></app-cms-block-tab-editor>
      </div>
    </div>
  