import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output
} from '@angular/core';
import { ProductModel } from '../../../models/product.model';
import { StorageService } from '../../../services/storage.service';
@Component({
	selector: 'app-product-color-dropdown',
	templateUrl: './product-color-dropdown.component.html',
	styleUrls: ['./product-color-dropdown.component.css']
})
export class ProductColorDropdownComponent implements OnChanges {
	public productModel! : ProductModel;
	@Input() list : Color[] = [];
	@Input()
	colorId! : string;
	@Input()
	soldOut! : boolean;
	@Input() colorSoldOut : any;
	@Input() itemName : any;
	@Output() change = new EventEmitter<string>();
	color! : Color;
	showAllColor = false;

	constructor(private localStorage : StorageService) { }
	ngOnInit() {
		this.showAllColor = !!this.localStorage.getItem(this.itemName + "-all-color") ? true : false;
	}
	ngOnChanges() {
		if (this.list && this.list.length > 0) {
			if (this.colorId) {
				this.color = this.list.find((item) => {
					return item.color_id.toString() === this.colorId.toString();
				}) as Color;
			}
			if (!this.color) {
				this.onColorChange(this.list[0]);
			}
		}
	}

	onColorChange(c : Color) {
		this.color = c;
		this.change.emit(c.color_id);
	}

	moreColorBlock() {
		this.showAllColor = true;
		this.localStorage.setItem(this.itemName + "-all-color", true);
	}
}

interface Color {
	text : string;
	img_block : string;
	color_id : string;
	url_path : string;
}