<div class="sidebar">
  <div class="sidebarSub_1">
    <div class="gList">
      <ul id='nested' [dragula]='"nested-bag"' *ngFor="let productModel of vmService.vmMerchProductList; let m = index;" >
        <div class="column ng-tns-c10-0 ng-star-inserted">
          <a class="a-link no-styling ng-tns-c10-0 ng-star-inserted" target="_self">
            <div class="o-product" data-id="{{productModel.entity_id}}">
              <div  class="merchClose m-button-icon a-icon-close js-close-button"></div>
              <div class="merch"></div>
              <div class="image-holder">
                <!-- 缩略图 开始 -->
                <div class="listing_thumbnail" dragula="CLICKS">
                  <div class="add_Icon" (click)="vmService.vmAddClick()"></div>
                  <div class="imgList">
                    <ul  *ngFor="let images of productModel.ccimage; let i = index; ">
                      <li >
                        <a class="a-link"><img class="a-image is-selected" src="{{images}}$200x200" alt="图片"></a>
                        <div class="setImg selection" data-id="listing_thumbnail_merch_{{productModel.entity_id}}_{{m}}">
                          <a *ngIf=" i === 0 " (mousedown)="vmImageClick( productModel.entity_id, images, 1, i, m )" class="img_1 act">主图</a>
                          <a *ngIf=" i !== 0 " (mousedown)="vmImageClick( productModel.entity_id, images, 1, i, m )" class="img_1">主图</a>
                          <a *ngIf=" i === 1 " (mousedown)="vmImageClick( productModel.entity_id, images, 2, i, m )" class="img_2 act">辅图</a>
                          <a *ngIf=" i !== 1 " (mousedown)="vmImageClick( productModel.entity_id, images, 2, i, m )" class="img_2">辅图</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- 缩略图 结束 -->
                <div class="image-if-hover">
                  <a routerLink="/{{categoryUrl}}/{{productModel?.url_path}}" (click)="toPdp()">
                    <div class="m-product-image pos_rel">
                      <img class="a-image default-image" [ngStyle]="{'display':!isPcFlag?'block':'none'}" [defaultImage]="'assets/images/loadImg.jpg'" [lazyLoad]="productModel.default_image_path" [offset]="offset" alt="图片"/>
                      <img class="a-image default-image" [ngStyle]="{'display':((!productModel.panorama_image||productModel.entity_id!=showHoverProductId) && isPcFlag)?'block':'none'}" (mouseover)="imageMouseover(productModel.entity_id)" [defaultImage]="'assets/images/loadImg.jpg'" [lazyLoad]="productModel.default_image_path" alt="图片" [offset]="offset"/>
                      <img class="a-image" [ngStyle]="{'display':((productModel.panorama_image&&productModel.entity_id==showHoverProductId) && isPcFlag)?'block':'none'}" (mouseout)="imageMouseout()" src="{{productModel.panorama_image}}" alt="图片"/>
                    </div>
                  </a>
                </div>
              </div>
              <div class="description">
                <div class="marker-label temp-marker" *ngIf="productModel.is_new==1">
                  <label class="a-label js-a-label">NEW</label>
                </div>
                <div class="product-variants">
                  <label class="a-label js-a-label">Colours (3)</label>
                </div>
                <a routerLink="/{{categoryUrl}}/{{productModel?.url_path}}" (click)="toPdp()"><label class="a-label js-a-label product-title">{{productModel.name}}</label></a>
                <div class="m-product-price">
                  <a routerLink="/{{categoryUrl}}/{{productModel?.url_path}}" (click)="toPdp()">
                    <label class="a-label js-a-label is-deprecated" *ngIf="productModel.price != productModel.salePrice">{{init.currency}}{{productModel.price}}</label>
                    <label class="a-label js-a-label" [ngClass]="{'is-reduced': productModel.price != productModel.salePrice}">{{init.currency}}{{productModel.salePrice}}</label>
                  </a>
                </div>
                <div data-component="MProductMarkersCofs" class="m-product-marker m-product-markers" style="display: none">
                  <div class="marker-image product-marker">
                    <img class="a-image" src="" alt="图片"/>
                  </div>
                  <div class="marker-image promo-marker">
                    <img class="a-image" src="" alt="图片"/>
                  </div>
                </div>
                <div class="marker-label temp-marker">
                  <label class="a-label js-a-label"></label>
                </div>
                <div class="m-swatches sm">
                  <div class="picked-color"></div>
                  <div class="a-swatch js-swatch" *ngFor="let block of imageBlock">
                    <label class="a-label js-a-label">
                      <input type="radio" name="colors" value="Black"/>
                      <span>
                        <div class="a-swatch-foreground"></div>
                        <a routerLink="/{{categoryUrl}}/{{block.url}}" (click)="toPdp()"><img class="a-image" src="{{block.image}}" alt="图片"/></a>
                        <p class="sold-out">Sold out</p>
                      </span>
                    </label>
                  </div>
                </div>
                <label class="a-label js-a-label product-brand">COS</label>
              </div>
              <div class="inStock">{{qty}}</div>
            </div>
          </a>
        </div>
      </ul>
    </div>
    <div class="btnBox">
      <button (click)="updateRanking()" class="submit">提交</button>
      <button (click)="resetRanking()" class="reset">清空</button>
      <div class="textBox">
        <span>您可以在商品右下角点击”+“来加入暂存栏</span>
      </div>
    </div>
  </div>
  <div class="sidebarSub_2" style="display: none">
    <div class="searchBox clearfix">
      <input><button id="searchText">搜索</button>
    </div>
  </div>
</div>
