import {Component, Inject,Input, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router} from '@angular/router';
import {InitializeService} from '../../../services/initialize.service';
import {filter} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {BreadcrumbService} from '../../../services/breadcrumb.service';
import {CallComponentService } from '../../../services/callcomponent.service';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  template: `
    <div class="container breadcrumb-container" *ngIf="showBreadcrumbs">
      <ol class="breadcrumb">
        <li><a routerLink="" class="breadcrumb">{{ '首页' | translate }}</a></li>
        <li *ngFor="let breadcrumb of breadcrumbs">
          <a href="{{breadcrumb.url}}">{{ breadcrumb.label | translate }}</a>
        </li>
      </ol>
    </div>
   <ul class="mobile_breadcrumb hidden-md hidden-lg" *ngIf="this.init.title !== ''" style="display:none;">
      <li class="breadcrumb-arrow" style="z-index: 999" (click)="this.goBack()">
        <h3><span class="glyphicon glyphicon-chevron-left"></span></h3>
      </li>
      <li class="col-xs-12 breadcrumb-title"><h3><span>{{ this.init.title }}</span></h3></li>
    </ul>
  `
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[] = [];
  public realbreadcrumbs: any = [];
  public showBreadcrumbs = true;
  
  //控制列表页面包屑
  @Input() categoryBreadcrumbs : any;
  @Input() displayMode : any;
 
  /**
   * @class DetailComponent
   * @constructor
   */
  constructor(private activatedRoute: ActivatedRoute,
              public init: InitializeService,
              private router: Router,
			  private breadcrumbService: BreadcrumbService,
			  public eventSubscribeService : CallComponentService,
              @Inject(PLATFORM_ID) private platformId: Object ) {
  }

  /**
   * Let's go!
   *
   * @class DetailComponent
   * @method ngOnInit
   */
  ngOnInit() {
    this.breadcrumbs = [];
    // subscribe to the NavigationEnd event
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(_event => {
		//判断当前页面是否需显示面包屑
		if((this.displayMode == 'PRODUCTS' || this.displayMode=='PRODUCTS_AND_PAGE')){
			this.showBreadcrumbs = true;
		}else{
		   const root: ActivatedRoute = this.activatedRoute.root;
		   this.getBreadcrumbs(root);  
		} 

    });
	
	this.breadcrumbs = this.breadcrumbService.breadcrumbsArray;
	this.eventSubscribeService.renewalCrumbs.subscribe((value : boolean) => {
		if (value) {
			this.breadcrumbs = this.breadcrumbService.breadcrumbsArray;
		}
	});

	if(!!this.breadcrumbs){
 
	}else{
		this.showBreadcrumbs = false;
	}

  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivateRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
	private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): any {
		const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

		// get the child routes
		const children: ActivatedRoute[] = route.children;
		// console.log(children,'==========children===========')
		// return if there are no more children
		if (children.length === 0) { 
		  return breadcrumbs;
		}

		// iterate over each children
		for (const child of children) {
		  // verify primary route
		  if (child.outlet !== PRIMARY_OUTLET) {
			continue;
		  }

		  // verify the custom data property "breadcrumb" is specified on the route
		  // if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
		  //   return this.getBreadcrumbs(child, url, breadcrumbs);
		  // }

		  if (child.snapshot.data[ROUTE_DATA_BREADCRUMB] === false) {
			this.showBreadcrumbs = false;
			// return this.getBreadcrumbs(child, url, breadcrumbs);
		  } else {
			this.showBreadcrumbs = true;
		  }

		  // // get the route's URL segment
		  // const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

		  // // append route URL to URL
		  // url += `/${routeURL}`;

		  // add breadcrumb
		  // const breadcrumb: IBreadcrumb = {
		  //   label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
		  //   params: child.snapshot.params,
		  //   url: url
		  // };
		  // breadcrumbs.push(breadcrumb);

		  // recursive
		  // return this.getBreadcrumbs(child, url, breadcrumbs);
		  return this.showBreadcrumbs;
		}
	}
	 
  goBack() {
    if (isPlatformBrowser(this.platformId)) {
      window.history.back();
    }
  }
}
