import {Component, Inject, OnInit,  Input,PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {InitializeService} from '../../services/initialize.service';
import {RequestService} from '../../services/request.service';
import {StorageService} from '../../services/storage.service';
import {isPlatformBrowser} from '@angular/common';
const apiConfig = require('../../config/config.api.json');

@Component({
  selector: 'app-account-logout-check',
  templateUrl: './account-logout-check.component.html',
  styleUrls: ['./account-logout-check.component.css']
})

export class AccountLogoutCheckComponent implements OnInit {
  sendPhoneLabel:string ='发送验证码';
  mobileVal!: string;//手机号
  mobileValid:any = false;
  usernameError!: string;
  mobileActive = false;
  public timer:any = null;
  phoneCaptchaDisabled = false;
  phoneCaptchaError!: string;
  phoneCaptchaValid = false;
  hiddenImageValidBox:boolean=true;//图片验证框显示(账户注销)
  phoneCaptchaValue!: string;
  imageValidId!: string; //图片验证码id
  imageValidSrc!:string; //图片验证码地址
  imageValidValue!:string;//图像验证码输入值
  imageValidError:boolean=false;//图形验证码错误
  imageValidErrorLabel!:string;//图形验证码提示
  phoneCaptchaActive:boolean = false;//聚焦验证码框
  logoutError = false;
  logoutErrorLabel!:string;
  @Input() checkCodeMsg: any; // 验证码校验结果
  constructor(public init:InitializeService,
              private request:RequestService,
              private router:Router,
              @Inject(PLATFORM_ID) private platformId:Object,
              private localStorage:StorageService) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.init.isLogin() || this.localStorage.getItem('logout_next_step') === null) {
        this.router.navigate(['']);
      }
    }
    this.init.pageConfiguration('验证中心');
    this.createImage();
  }

  blurPhoneCaptchaCheck(){
    this.phoneCaptchaActive=false;
  }

  mobileActiveCheck(){
    this.mobileValid =null;
    this.mobileActive=true;
  }

  /*发送手机验证码*/
  sendPhoneVerification() {
    if (!this.blurMobileCheck()) {
      return false;
    }
    this.phoneCaptchaDisabled = true;
    const that = this;
    let sendResult: any = {code: '', message: ''};

    const paramsUrl = '?action=SendSms' + '&type=logout&mobile=' + this.mobileVal;
    this.request.get(apiConfig.customerApi + paramsUrl)
      .subscribe(
        val => {
          sendResult = val;
          if (sendResult.code * 1 === 200) {
            let int = 59;
            const timerEffect =  setInterval(function () {
              that.sendPhoneLabel = '重新发送(' + int-- + ')';
              if (int <= 1) {
                that.sendPhoneLabel = '发送验证码';
                that.phoneCaptchaDisabled = false;
                clearInterval(timerEffect);
              }
            }, 1000);
          } else {
            this.phoneCaptchaValid = true;
            this.phoneCaptchaDisabled = false;
            this.phoneCaptchaError = sendResult.message;
            if (isPlatformBrowser(this.platformId)) {
              this.timer = setTimeout(() => {
                that.phoneCaptchaValid = false;
              }, 3000);
            }
          }
        }
      );
	  return;
  }

  //手机号校验
  blurMobileCheck() {
    if(this.mobileVal) {
      const mobilereg = /^(1[3|4|5|6|7|8|9])\d{9}$/;
      let valid = mobilereg.test(this.mobileVal);
      this.mobileValid = valid ? null : true;
      this.usernameError =valid?'':'手机号格式不正确';
      if(this.mobileVal != this.localStorage.getItem('mobile')){
        this.mobileValid = true;
        this.usernameError = '手机号不正确';
      }
    }else{
      this.mobileValid=true;
      this.usernameError ='请输入手机号';
    }
    this.mobileActive=false;
    if(this.mobileValid){
      if (isPlatformBrowser(this.platformId)) {
        const that = this;
        this.timer = setTimeout(function () {
          that.mobileValid = false;
        }, 3000);
      }
      return false;
    }
    return true;
  }
  phoneCaptchaActiveCheck(){
    this.phoneCaptchaValid = false;
    this.phoneCaptchaActive=true;
  }
  openImageValid() {
    this.phoneCaptchaValue = '';
    this.imageValidValue = '';
    if (!this.blurMobileCheck()) {
      return false;
    }
    this.hiddenImageValidBox = false;
	return;
  }
  createImage() {
    let result: any = {code: '', data: {'captcha_id': '', 'captcha_image': ''}, message: ''};
    const paramsUrl = '?action=createValidationCode';
    this.request.get(apiConfig.customerApi + paramsUrl)
      .subscribe(
        val => {
          result = val;
          if (result.code === '200') {
            this.imageValidId = result.data.captcha_id;
            this.imageValidSrc = result.data.captcha_image;
          }
        }
      );
  }
  imageValueCheck() {
    if (this.imageValidValue !== undefined && this.imageValidValue !== '') {
      let result: any = {code: '', message: ''};
      const paramsUrl = '?action=checkCaptcha&captcha_id=' + this.imageValidId + '&catpcha_value=' + this.imageValidValue;
      this.request.get(apiConfig.customerApi + paramsUrl)
        .subscribe(
          val => {
            result = val;
            this.createImage();
            if (result.code === 200) {
              this.closeCodeBox();
              this.sendPhoneVerification();
            } else {
              this.imageValidError = true;
              this.imageValidErrorLabel = '验证码错误';
              const _that = this;
              if (isPlatformBrowser(this.platformId)) {
                this.timer = setTimeout(function () {
                  _that.imageValidError = false;
                  _that.imageValidErrorLabel = '验证码错误';
                }, 3000);
              }
              return false;
            }
			return;
          }
        );
	
    } else {
      this.imageValidError = true;
      this.imageValidErrorLabel = '请输入验证码';
      const these = this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(() => {
          these.imageValidError = false;
        }, 3000);
      }
    }
	return;
  }
  closeCodeBox() {
    this.createImage();
    this.imageValidError = false;
    this.hiddenImageValidBox = true;
  }

  checkVerificationCode() {
    if (!this.phoneCaptchaValue) {
      this.phoneCaptchaValid=true;
      this.phoneCaptchaError = '验证码必填';
      const that =this;
      if (isPlatformBrowser(this.platformId)) {
        this.timer = setTimeout(function () {
          that.phoneCaptchaValid = false;
        }, 3000);
      }
      return false;
    }else{
      this.phoneCaptchaValid=false;
    }
    let codeResult: any = {code: '', message: ''};
    const paramsUrl ='?action=checkSmsCode'+'&smsType=logout_sms&mobile='+this.mobileVal+'&verification_code='+this.phoneCaptchaValue;
    this.request.get(apiConfig.customerApi+paramsUrl)
      .subscribe(
        val => {
          codeResult = val;
          if (codeResult.code !== '200') {
            this.phoneCaptchaValid = true;
            this.phoneCaptchaError = '验证码错误';
            const that =  this;
            if (isPlatformBrowser(this.platformId)) {
              this.timer = setTimeout(function () {
                that.phoneCaptchaValid = false;
              }, 3000);
            }
            this.createImage();
            return false;
          } else {
            this.phoneCaptchaValid = false;
          }
		  	return;
        }
      );
    return true;
  }

  submitLogout(){
    if (this.blurMobileCheck() && this.checkVerificationCode()) {
      this.localStorage.removeItem('logout_next_step');
      this.localStorage.setItem('logout_check_success','yes');
      this.router.navigate(['./my-account/logout-reason']);
    } else {
    }
  }
}
