import {Injectable} from '@angular/core';

@Injectable()
export class PagingService {
  public totalItems = 64;
  public currentPage = 1;
  public smallnumPages = 10;
  public pageCount = 0;
  public url = '/my-account/index';

  constructor() {
  }

  public setTotalItems(num:any) {
    this.totalItems = num;
  }

  public setCurrentPage(num:any) {
    this.currentPage = num;
  }

  public setSmallnumPages(num:any) {
    this.smallnumPages = num;
  }

  public setPageCount(num:any) {
    this.pageCount = num;
  }

  public setUrl(url:any) {
    this.url = url;
  }

}
